import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo-hooks";
import {
  TICKET_CATEGORY_EDIT_QUERY,
  TICKET_CATEGORY_LIST_QUERY,
} from "../../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { commonStatusName } from "../../../../../Components/User/UserNames";
import { toast } from "react-toastify";
import { graphqlUri } from "../../../../../Config/Env";

export default ({
  history,
  match,
  location,
  isModal,
  id,
  ticketConfig,
  commonStatus,
  prevIconImage,
  name,
}) => {
  const modalRef = React.createRef();

  const [iconImage, setIconImage] = useState(
    prevIconImage
      ? graphqlUri + prevIconImage
      : "/assets/img/category-default.png"
  );
  const handleIconImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setIconImage(reader.result);
    };
  };
  const onClickIcon = () => {
    document.getElementById("iconImage").click();
  };

  const [ticketCategoryEditMutation] = useMutation(TICKET_CATEGORY_EDIT_QUERY, {
    refetchQueries: [
      {
        query: TICKET_CATEGORY_LIST_QUERY,
        variables: {
          ticketConfig: match.params.config,
          parentId: "null",
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      commonStatus: commonStatus,
      name: name,
    },
  });
  const onSubmit = async (data) => {
    try {
      let category = {
        id: id,
        ticketConfig: ticketConfig.id,
        commonStatus: data.commonStatus,
        name: data.name,
      };

      if (data.iconImage[0]) {
        category = { ...category, iconImage: data.iconImage[0] };
      }

      const {
        data: { AdminTicketCategoryEdit },
      } = await ticketCategoryEditMutation({
        variables: category,
      });
      if (AdminTicketCategoryEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/ticketConfigs/${match.params.config}/categories${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/ticketConfigs/${match.params.config}/categories${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/ticketConfigs/${match.params.config}/categories${location.search}`
              );
            }}
          >
            분류 수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">상태</label>
                  <div className="col-md-10">
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="commonStatus1"
                        name="commonStatus"
                        value={"S"}
                        ref={register({
                          required: "상태를 선택해주세요.",
                        })}
                      />
                      <label htmlFor="commonStatus1">
                        {commonStatusName("S")}
                      </label>
                    </div>
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="commonStatus2"
                        name="commonStatus"
                        value={"C"}
                        ref={register({
                          required: "상태를 선택해주세요.",
                        })}
                      />
                      <label htmlFor="commonStatus2">
                        {commonStatusName("C")}
                      </label>
                    </div>
                    {errors.commonStatus && (
                      <div>{errors.commonStatus.message}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">아이콘</label>
                  <div className="col-md-10">
                    <img
                      src={iconImage}
                      alt="이미지 업로드"
                      className="height-50"
                      style={{ borderRadius: 10, overflow: "hidden" }}
                      onClick={onClickIcon}
                    />
                    <input
                      type="file"
                      id="iconImage"
                      name="iconImage"
                      className="hide"
                      onChange={handleIconImage}
                      ref={register({})}
                    />
                    <div class="alert alert-primary fade show m-t-10 m-b-0">
                      이미지 클릭시 업로드가 가능합니다.
                      <br />
                      <b>이미지 사이즈 : </b> 넓이 300px X 높이 300px
                    </div>
                    {errors.iconImage && (
                      <span>{errors.iconImage.message}</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요.",
                      })}
                      defaultValue={watch("name")}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
