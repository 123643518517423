import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../../../../Components/Panel/Panel";
import ReactMoment from "react-moment";

const Presenter = ({ history, match, location, loading, data, baseUrl }) => {
  const id = match.params.id;
  return (
    <Fragment>
      <Helmet>
        <title>동 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/delivery/deliveryStores">로컬푸드 구독</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="/delivery/deliveryProducts">상품</Link>
          </li>
          <li className="breadcrumb-item active">
            {baseUrl === "/delivery/deliveryProducts" && "상품"}
            {baseUrl === "/delivery/deliverySets" && "묶음"}
          </li>
        </ol>

        <h1 className="page-header">수정이력</h1>

        <div className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link
              to={`${baseUrl}/${id}/edit${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right"></div>
        </div>
        {!loading ? (
          <Fragment>
            <div className="row">
              <div className="col-xs-12">
                {data.StoreDeliveryEditLogList.count > 0 ? (
                  <div className={"widget-list widget-list-rounded m-b-15"}>
                    {data.StoreDeliveryEditLogList.deliveryEditLogs.map(
                      (item, index) => {
                        return (
                          <div className="widget-list-item" key={index}>
                            <div className="widget-list-content">
                              <h4 className="widget-list-title">
                                {item.description}
                              </h4>
                            </div>
                            <div
                              className="widget-list-action"
                              style={{ width: "50%" }}
                            >
                              <div className="pull-right">
                                <div>
                                  {item.user.name}({item.user.userId})
                                </div>
                                <div>
                                  <ReactMoment format="YYYY.MM.DD HH:mm:ss">
                                    {item.createdAt}
                                  </ReactMoment>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <Panel>
                    <PanelBody>
                      <p className="text-center">등록된 데이터가 없습니다.</p>
                    </PanelBody>
                  </Panel>
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Presenter;
