import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_DELIVER_ASYNC_SELECT_MUTATION,
  ADMIN_DELIVERY_DELIVER_DETAIL_QUERY,
} from "../Queries";

const DeliveryDeliverAsyncSelect = ({ onChange, value }) => {
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const [AdminDeliveryDeliverAsyncSelectMutation] = useMutation(
    ADMIN_DELIVERY_DELIVER_ASYNC_SELECT_MUTATION
  );
  const [AdminDeliveryDeliverDetailMutation] = useMutation(
    ADMIN_DELIVERY_DELIVER_DETAIL_QUERY
  );

  const loadOptions = async (inputValue) => {
    const {
      data: { AdminDeliveryDeliverAsyncSelect },
    } = await AdminDeliveryDeliverAsyncSelectMutation({
      variables: {
        take: 20,
        skip: 0,
        keyword: inputValue,
      },
    });

    if (AdminDeliveryDeliverAsyncSelect.count > 0) {
      return AdminDeliveryDeliverAsyncSelect?.deliveryDelivers.map((item) => ({
        value: item.id,
        label: `${item.user.name}(${item.user.userId})`,
      }));
    }
  };

  useEffect(() => {
    const defaultValue = async () => {
      if (!value) {
        return;
      }
      const {
        data: { AdminDeliveryDeliverDetail },
      } = await AdminDeliveryDeliverDetailMutation({
        variables: {
          id: value,
        },
      });

      console.log({ AdminDeliveryDeliverDetail });

      const inputValue = {
        value: AdminDeliveryDeliverDetail.id,
        label: `${AdminDeliveryDeliverDetail.user.name}(${AdminDeliveryDeliverDetail.user.userId})`,
      };

      setInputValue(inputValue);
    };
    defaultValue();
    setLoading(false);
  }, [value]);

  if (loading) {
    return <></>;
  }

  return (
    <AsyncSelect
      placeholder="사용자(이름)을 검색해주세요."
      noOptionsMessage={() => {
        return "사용자(이름)을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onChange={onChange}
      value={inputValue}
      cacheOptions
    />
  );
};

export default DeliveryDeliverAsyncSelect;
