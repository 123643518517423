import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import Category from "./ListCategory";
import { currencyName } from "../../../../../Components/User/UserNames";

export default ({
  history,
  match,
  location,
  config,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,
  onSync,
}) => {
  return (
    <>
      <Helmet>
        <title>기부 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">기부</li>
        </ol>
        <h1 className="page-header">기부</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminDonationList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <Category history={history} match={match} location={location} />

                <div className="row m-t-15">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(제목) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              {!loading && data.AdminDonationList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <colgroup>
                          <col width="15%" />
                          <col width="*" />
                          <col width="15%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>분류</th>
                            <th>이미지</th>
                            <th>정보</th>
                            <th>기부금</th>
                            <th>작성일</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminDonationList.donations.map((item) => {
                            const donationCoverImg =
                              item.coverImg === "" || item.coverImg === null
                                ? "/assets/img/gallery/gallery-default.jpg"
                                : `${graphqlUri}${item.coverImg}`;
                            return (
                              <tr key={item.id}>
                                <td className="text-nowrap text-center valign-middle">
                                  {item.DonationCategory.name}
                                </td>
                                <td className="hidden-sm">
                                  <div
                                    className="product-img"
                                    style={{
                                      backgroundImage: `url(${donationCoverImg})`,
                                    }}
                                  >
                                    <img
                                      src={donationCoverImg}
                                      alt={item.name}
                                    />
                                  </div>
                                </td>
                                <td className="valign-middle">
                                  <div>
                                    <h4 className="widget-list-title m-t-5">
                                      {item.title}
                                    </h4>
                                    <Moment format="YYYY-MM-DD">
                                      {item.startDate}
                                    </Moment>
                                    ~
                                    <Moment format="YYYY-MM-DD">
                                      {item.endDate}
                                    </Moment>
                                  </div>
                                </td>

                                <td className="valign-middle" align="right">
                                  <div>
                                    <NumberFormat
                                      value={item.totalDRM}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={currencyName("DRM") + " : "}
                                    />
                                  </div>
                                  <div>
                                    <NumberFormat
                                      value={item.totalDNC}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={currencyName("DNT") + " : "}
                                    />
                                  </div>
                                  합계 :
                                  <NumberFormat
                                    value={item.totalDNC + item.totalDRM}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </td>
                                <td className="text-nowrap text-center valign-middle">
                                  <div className="btn-group-vertical">
                                    <Moment format="YYYY-MM-DD HH:mm:ss">
                                      {item.createdAt}
                                    </Moment>
                                  </div>
                                </td>
                                <td className="text-nowrap text-center valign-middle">
                                  <Link
                                    to={`/donations/${item.id}${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    관리
                                  </Link>
                                  {/* <Button
                                    className="btn btn-white btn-xs text-blue"
                                    onClick={() => {
                                      onSync(item.id);
                                    }}
                                  >
                                    합계 동기화
                                  </Button> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminDonationList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/donations/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
