import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  commonStatusName,
  pastPeriod,
} from "../../../../../Components/User/UserNames";
import { graphqlUri } from "../../../../../Config/Env";
import Category from "./ListCategory";
import Moment from "moment";
import NumberFormat from "react-number-format";
import DatePicker from "../../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  page,
  startSearchDate,
  endSearchDate,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  handleSearch,
  DownloadButton,
}) => {
  return (
    <>
      <Helmet>
        <title>가맹점 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="/stores">가맹점</Link>
          </li>
          <li className="breadcrumb-item active">가맹점</li>
        </ol>
        <h1 className="page-header">가맹점</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminStoreList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xl-12">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                    {/* <div>
                      <DownloadButton />
                    </div> */}
                  </div>
                </div>
                <Category
                  history={history}
                  match={match}
                  location={location}
                  startSearchDate={startSearchDate}
                  endSearchDate={endSearchDate}
                  keyword={keyword}
                />

                <div className="row m-t-15">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(가맹점명, 대표자(이름, 이메일 휴대전화), 주소) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              {!loading && data.AdminStoreList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>분류</th>
                            <th>이름(아이디) </th>
                            <th>로고</th>
                            <th>가맹점명</th>
                            <th>대표자</th>
                            <th>주소</th>
                            <th>상품 수</th>
                            <th>연동일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminStoreList.stores.map((item) => {
                            const storeLogoImage =
                              item.storeLogo === "" || item.storeLogo === null
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${item.storeLogo}`;
                            return (
                              <tr key={item.id}>
                                <td>{commonStatusName(item.commonStatus)}</td>
                                <td>
                                  <div>대표 : {item.storeCategory.name}</div>
                                  <div>
                                    {item.storeCategories.map(
                                      (category, index) => {
                                        return (
                                          <div key={index}>
                                            중복 : {category.name}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {/* <a
                                    href={`/users/${item.user.role}/${item.user.id}`}
                                  > */}
                                  {item.user.name}({item.user.userId})
                                  {/* </a> */}
                                </td>
                                <td className="with-img">
                                  <img
                                    src={storeLogoImage}
                                    className="img-rounded height-30"
                                    alt={item.name}
                                  />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.storeCEO}</td>
                                <td>
                                  ({item.storeZipcode}) {item.storeRoadAddress}
                                </td>
                                <td>
                                  <NumberFormat
                                    value={item.storeProductsCount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  건
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {item.createdAt}
                                  </ReactMoment>
                                </td>
                                <td>
                                  <Link
                                    className={`btn btn-white btn-xs text-green m-r-5`}
                                    to={`/stores/${item.id}${location.search}`}
                                  >
                                    상점정보
                                  </Link>
                                  <Link
                                    className={`btn btn-white btn-xs text-green m-r-5`}
                                    to={`/stores/${item.id}/employees${location.search}`}
                                  >
                                    구성원정보
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminStoreList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
