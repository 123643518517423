import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import { deliveryDeliverStateName } from "../../../../../Components/User/UserNames";
import DeliveryDeliverAsyncSelect from "../../../../../Containers/DeliveryDeliver/AsyncSelect";
import ReactMoment from "react-moment";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_DELIVER_EDIT_MUTATION,
  STORE_DELIVERY_DELIVER_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

export default ({
  history,
  match,
  location,
  deliveryDeliver_id,
  page,
  take,
  data,
  baseUrl,
}) => {
  const onDeliveryDeliverChange = (option) => {
    const baseUrl =
      "?" + (option.value ? "deliveryDeliver_id=" + option.value + "&" : "");

    history.push(`${baseUrl}`);
  };

  const [
    StoreDeliveryDeliverEditMutation,
    { loading: StoreDeliveryDeliverEditLoading },
  ] = useMutation(STORE_DELIVERY_DELIVER_EDIT_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_DELIVER_LIST_QUERY,
        variables: {
          take: 15,
          skip: 0,
          deliveryDeliver_id: "",
        },
      },
    ],
  });

  const onStateChange = async (item) => {
    let state = "";
    if (item.state === "Standby") {
      state = "Approval";
    }
    if (item.state === "Approval") {
      state = "Block";
    }
    if (item.state === "Block") {
      state = "Standby";
    }
    try {
      if (StoreDeliveryDeliverEditLoading) {
        return;
      }

      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `${deliveryDeliverStateName(
          item.state
        )}상태에서 ${deliveryDeliverStateName(state)}상태로 변경하시겠습니까?`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "취소",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {
            data: { StoreDeliveryDeliverEdit },
          } = await StoreDeliveryDeliverEditMutation({
            variables: { id: item.id, state: state },
          });
          if (StoreDeliveryDeliverEdit) {
            Swal.fire({
              icon: "info",
              title: "안내",
              text: "처리 되었습니다.",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "확인",
              focusConfirm: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`${baseUrl}${location.search}`);
              }
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const defaultUrl =
    baseUrl +
    "?" +
    (deliveryDeliver_id ? `deliveryDeliver_id=${deliveryDeliver_id}&` : ``);

  return (
    <div>
      <Helmet>
        <title>기사 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/stores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">기사</li>
      </ol>
      <h1 className="page-header">기사</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({data.StoreDeliveryDeliverList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row">
                <div className="col-xs-12">
                  <DeliveryDeliverAsyncSelect
                    onChange={onDeliveryDeliverChange}
                    value={deliveryDeliver_id}
                  />
                </div>
              </div>
            </Alert>
            {data.StoreDeliveryDeliverList.count > 0 ? (
              <>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                      <colgroup>
                        <col width="10%" />
                        <col width="*" />
                        <col width="15%" />
                        <col width="10%" />
                        <col width="15%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="text-nowrap text-center">상태</th>
                          <th className="text-nowrap text-center">기본정보</th>
                          <th className="text-nowrap text-center">가입일시</th>
                          <th className="text-nowrap text-center">배달건수</th>
                          <th className="text-nowrap text-center">기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.StoreDeliveryDeliverList.deliveryDelivers.map(
                          (item, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-nowrap text-center">
                                  {deliveryDeliverStateName(item.state)}
                                </td>
                                <td>
                                  <div>
                                    이름 : {item.user.name}({item.user.userId})
                                  </div>
                                  <div>연락처 : {item.user.phone}</div>
                                </td>
                                <td className="text-nowrap text-center">
                                  <ReactMoment format="YYYY-MM-DD">
                                    {item.createdAt}
                                  </ReactMoment>
                                </td>
                                <td className="text-nowrap text-center">
                                  {item.orderCount}건
                                </td>
                                <td align="center">
                                  <div>
                                    {item.state === "Standby" && (
                                      <button
                                        className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                        onClick={() => onStateChange(item)}
                                      >
                                        승인하기
                                      </button>
                                    )}
                                    {item.state === "Approval" && (
                                      <button
                                        className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                        onClick={() => onStateChange(item)}
                                      >
                                        차단하기
                                      </button>
                                    )}
                                  </div>
                                  <div>
                                    <Link
                                      className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                      to={`/delivery/deliveryOrders?deliveryDeliver_id=${item.id}`}
                                    >
                                      배송내역
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.StoreDeliveryDeliverList.count}
                        blockSize={5}
                        pageSize={take}
                        currentPage={page}
                        baseUrl={defaultUrl}
                      />
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </PanelFooter>
              </>
            ) : (
              <PanelBody>
                <p className="text-center">등록된 데이터가 없습니다.</p>
              </PanelBody>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};
