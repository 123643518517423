import { gql } from "apollo-boost";

/* 가맹점 분류 */
export const DONATION_CATEGORY_LIST_QUERY = gql`
  query AdminDonationCategoryList($keyword: String, $skip: Int, $first: Int) {
    AdminDonationCategoryList(
      keyword: $keyword
      orderBy: "id_ASC"
      skip: $skip
      first: $first
    ) {
      donationCategories {
        id
        donationsCount
        pcIcon
        mobileIcon
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const DONATION_CATEGORY_WRITE_QUERY = gql`
  mutation AdminDonationCategoryWrite(
    $pcIcon: String!
    $mobileIcon: String!
    $name: String!
  ) {
    AdminDonationCategoryWrite(
      pcIcon: $pcIcon
      mobileIcon: $mobileIcon
      name: $name
    )
  }
`;

export const DONATION_CATEGORY_DETAIL_QUERY = gql`
  query AdminDonationCategoryDetail($id: String!) {
    AdminDonationCategoryDetail(id: $id) {
      id
      pcIcon
      mobileIcon
      name
      createdAt
      updatedAt
    }
  }
`;

export const DONATION_CATEGORY_EDIT_QUERY = gql`
  mutation AdminDonationCategoryEdit(
    $id: String
    $pcIcon: String!
    $mobileIcon: String!
    $name: String!
  ) {
    AdminDonationCategoryEdit(
      id: $id
      pcIcon: $pcIcon
      mobileIcon: $mobileIcon
      name: $name
    )
  }
`;

export const DONATION_CATEGORY_DELETE_QUERY = gql`
  mutation AdminDonationCategoryDelete($id: String!) {
    AdminDonationCategoryDelete(id: $id)
  }
`;

/* 기부 */
export const DONATION_LIST_QUERY = gql`
  query AdminDonationList(
    $category: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminDonationList(
      category: $category
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      donations {
        id
        DonationCategory {
          id
          name
        }
        title
        contents
        planner
        plannerTel
        plannerUseSNS
        goalAmount
        startDate
        endDate
        coverImg
        detailImg
        totalDRM
        totalDNC
        commonStatus
        createdAt
        updatedAt
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
      }
      count
    }
  }
`;

export const DONATION_WRITE_QUERY = gql`
  mutation AdminDonationWrite(
    $DonationCategory: String!
    $title: String
    $contents: String
    $planner: String
    $plannerTel: String
    $plannerUseSNS: String
    $plannerSNS: String
    $goalAmount: Int
    $startDate: String
    $endDate: String
    $coverImg: CoverUpload
    $detailImg: DetailUpload
    $commonStatus: String
  ) {
    AdminDonationWrite(
      DonationCategory: $DonationCategory
      title: $title
      contents: $contents
      planner: $planner
      plannerTel: $plannerTel
      plannerUseSNS: $plannerUseSNS
      plannerSNS: $plannerSNS
      goalAmount: $goalAmount
      startDate: $startDate
      endDate: $endDate
      coverImg: $coverImg
      detailImg: $detailImg
      commonStatus: $commonStatus
    )
  }
`;

export const DONATION_DETAIL_QUERY = gql`
  query AdminDonationDetail($id: String!) {
    AdminDonationDetail(id: $id) {
      id
      DonationCategory {
        id
        name
      }
      title
      contents
      planner
      plannerTel
      plannerUseSNS
      plannerSNS
      goalAmount
      startDate
      endDate
      coverImg
      detailImg
      totalDRM
      totalDNC
      commonStatus
      createdAt
      updatedAt
      wallet {
        id
      }
      trustlines {
        id
        commonStatus
        currency
        amount
        createdAt
        updatedAt
      }
    }
  }
`;

export const DONATION_EDIT_QUERY = gql`
  mutation AdminDonationEdit(
    $id: String
    $DonationCategory: String!
    $title: String
    $contents: String
    $planner: String
    $plannerTel: String
    $plannerUseSNS: String
    $plannerSNS: String
    $goalAmount: Int
    $startDate: String
    $endDate: String
    $coverImg: CoverUpload
    $detailImg: DetailUpload
    $commonStatus: String
  ) {
    AdminDonationEdit(
      id: $id
      DonationCategory: $DonationCategory
      title: $title
      contents: $contents
      planner: $planner
      plannerTel: $plannerTel
      plannerUseSNS: $plannerUseSNS
      plannerSNS: $plannerSNS
      goalAmount: $goalAmount
      startDate: $startDate
      endDate: $endDate
      coverImg: $coverImg
      detailImg: $detailImg
      commonStatus: $commonStatus
    )
  }
`;

export const DONATION_DELETE_QUERY = gql`
  mutation AdminDonationDelete($id: String!) {
    AdminDonationDelete(id: $id)
  }
`;

export const DONATION_LEDGER_LIST_QUERY = gql`
  query AdminLedgerList($wallet: String) {
    AdminLedgerList(wallet: $wallet, orderBy: "id_DESC") {
      ledgers {
        id
        commonStatus
        transactionType
        accountWallet {
          id
          userId
        }
        destinationWallet {
          id
          userId
        }
        title
        currency
        value
        amount
        createdAt
        updatedAt
        wallet {
          id
          name
        }
      }
      count
    }
  }
`;
export const LEDGER_DONATIONSYNC_QUERY = gql`
  mutation AdminETCLedgerDonationAmount($donation: String!) {
    AdminETCLedgerDonationAmount(donation: $donation)
  }
`;

export const DONATION_CANCEL_QUERY = gql`
  mutation AdminDonationCancel(
    $donationId: String!
    $currency: String!
    $id: String!
  ) {
    AdminDonationCancel(donationId: $donationId, currency: $currency, id: $id)
  }
`;

/* 기부 프로젝트 이미지 */
export const DONATION_IMAGE_LIST_QUERY = gql`
  query AdminDonationImageList(
    $donation: String!
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminDonationImageList(
      donation: $donation
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      donationImages {
        id
        donationImage
        name
        description
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const DONATION_IMAGE_WRITE_QUERY = gql`
  mutation AdminDonationImageWrite(
    $donation: String!
    $file: FileUpload!
    $name: String!
    $description: String!
  ) {
    AdminDonationImageWrite(
      donation: $donation
      file: $file
      name: $name
      description: $description
    )
  }
`;

export const DONATION_IMAGE_DETAIL_QUERY = gql`
  query AdminDonationImageDetail($id: String!) {
    AdminDonationImageDetail(id: $id) {
      id
      donationImage
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const DONATION_IMAGE_EDIT_QUERY = gql`
  mutation AdminDonationImageEdit(
    $donation: String!
    $id: String!
    $file: FileUpload
    $name: String!
    $description: String!
  ) {
    AdminDonationImageEdit(
      donation: $donation
      id: $id
      file: $file
      name: $name
      description: $description
    )
  }
`;

export const DONATION_IMAGE_DELETE_QUERY = gql`
  mutation AdminDonationImageDelete($id: String!) {
    AdminDonationImageDelete(id: $id)
  }
`;

export const USER_LIST_QUERY = gql`
  query AdminUserList(
    $role: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminUserList(
      role: $role
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          name
        }
        addCurrency
        createdAt
        updatedAt

        isCms
        cmsValue
        cmsDay
      }
      count
    }
  }
`;

export const DONATION_TRUSTLINE_REMITTANCE_QUERY = gql`
  mutation AdminDonationTrustlineRemittance(
    $accountId: String!
    $destinationId: String!
    $currency: String!
    $value: String!
  ) {
    AdminDonationTrustlineRemittance(
      accountId: $accountId
      destinationId: $destinationId
      currency: $currency
      value: $value
    )
  }
`;
