import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { HELP_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(HELP_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminHelpDetail) {
    const {
      AdminHelpDetail: { id, question, answer, helpCategory },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        helpCategory={helpCategory}
        question={question}
        answer={answer}
      />
    );
  }
});
