import { gql } from "apollo-boost";

/* 배달가능 동 시작 */
export const ADMIN_DELIVERY_STORE_DONG_LIST_QUERY = gql`
  query AdminDeliveryStoreDongList($skip: Int!, $take: Int) {
    AdminDeliveryStoreDongList(skip: $skip, take: $take) {
      deliveryStoreDongs {
        id
        createdAt
        updatedAt

        commonStatus
        sido
        sigungu
        upmyundong
        ri
      }
      count
    }
  }
`;
export const ADMIN_DELIVERY_STORE_DONG_WRITE_MUTATION = gql`
  mutation AdminDeliveryStoreDongWrite(
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $ri: String
  ) {
    AdminDeliveryStoreDongWrite(
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      ri: $ri
    )
  }
`;
export const ADMIN_DELIVERY_STORE_DONG_DETAIL_QUERY = gql`
  query AdminDeliveryStoreDongDetail($id: String!) {
    AdminDeliveryStoreDongDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      sido
      sigungu
      upmyundong
      ri
      deliveryStore {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
        # deliveryStoreDongs {
        #   id
        #   createdAt
        #   updatedAt

        #   sido
        #   sigungu
        #   upmyundong
        #   ri
        # }
        # deliveryPlaces {
        #   id
        #   createdAt
        #   updatedAt

        #   name
        # }
      }
    }
  }
`;
export const ADMIN_DELIVERY_STORE_DONG_EDIT_MUTATION = gql`
  mutation AdminDeliveryStoreDongEdit(
    $id: String!
    $commonStatus: String!
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $ri: String
  ) {
    AdminDeliveryStoreDongEdit(
      id: $id
      commonStatus: $commonStatus
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      ri: $ri
    )
  }
`;
export const ADMIN_DELIVERY_STORE_DONG_DELETE_MUTATION = gql`
  mutation AdminDeliveryStoreDongDelete($id: String!) {
    AdminDeliveryStoreDongDelete(id: $id)
  }
`;
/* 배달가능 동 끝 */

/* 매장 시작 */
export const ADMIN_DELIVERY_STORE_LIST_QUERY = gql`
  query AdminDeliveryStoreList(
    $skip: Int!
    $take: Int
    $keyword: String
    $deliveryStoreDong_id: String
  ) {
    AdminDeliveryStoreList(
      skip: $skip
      take: $take
      keyword: $keyword
      deliveryStoreDong_id: $deliveryStoreDong_id
    ) {
      deliveryStores {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
        deliveryStoreDongs {
          id
          createdAt
          updatedAt

          sido
          sigungu
          upmyundong
          ri
        }
        deliveryPlaces {
          id
          createdAt
          updatedAt

          name
        }

        deliveryStoreDongsCount
        deliveryPlacesCount
      }
      count
    }
  }
`;
export const ADMIN_DELIVERY_STORE_WRITE_MUTATION = gql`
  mutation AdminDeliveryStoreWrite(
    $user_id: String!
    $deliveryStoreDong_ids: [String!]
  ) {
    AdminDeliveryStoreWrite(
      user_id: $user_id
      deliveryStoreDong_ids: $deliveryStoreDong_ids
    )
  }
`;
export const ADMIN_DELIVERY_STORE_DETAIL_QUERY = gql`
  query AdminDeliveryStoreDetail($id: String!) {
    AdminDeliveryStoreDetail(id: $id) {
      id
      createdAt
      updatedAt

      store {
        id
        createdAt
        updatedAt

        name
      }
      user {
        id
        createdAt
        updatedAt

        name
      }
      deliveryStoreDongs {
        id
        createdAt
        updatedAt

        commonStatus
        sido
        sigungu
        upmyundong
        ri
      }
      deliveryPlaces {
        id
        createdAt
        updatedAt

        name
      }
    }
  }
`;
export const ADMIN_DELIVERY_STORE_EDIT_MUTATION = gql`
  mutation AdminDeliveryStoreEdit(
    $id: String!
    $deliveryStoreDong_ids: [String!]
  ) {
    AdminDeliveryStoreEdit(
      id: $id
      deliveryStoreDong_ids: $deliveryStoreDong_ids
    )
  }
`;
export const ADMIN_DELIVERY_STORE_DELETE_MUTATION = gql`
  mutation AdminDeliveryStoreDelete($id: String!) {
    AdminDeliveryStoreDelete(id: $id)
  }
`;
/* 매장 끝 */
