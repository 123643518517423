import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import { Collapse, CardHeader, CardBody, Card } from "reactstrap";
import Pagination from "../../../../../../Components/Pagination/QSP";
// import { graphqlUri } from "../../../../../Config/Env";
import Category from "./ListCategory";

export default ({
  history,
  match,
  location,
  config,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,
}) => {
  const [collapse, setCollapse] = useState(null);

  const toggleCollapse = (index) => {
    setCollapse(index);
  };

  return (
    <>
      <Helmet>
        <title>도움말 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">도움말</li>
        </ol>
        <h1 className="page-header">도움말</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminHelpList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <Category
                  history={history}
                  match={match}
                  location={location}
                  config={config}
                />
                <div className="row m-t-15">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(이름) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                          <Link
                            to={`/helpConfigs/${match.params.CommonApp}/${config.AdminHelpConfig.id}/edit${location.search}`}
                            className="btn btn-white"
                          >
                            <i className="fa fa-cog fa-fw"></i>
                          </Link>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>

              {!loading && data.AdminHelpList.count > 0 ? (
                <>
                  <PanelBody>
                    <div id="accordion" className="accordion">
                      {data.AdminHelpList.helps.map((item) => {
                        return (
                          <Card className="bg-white" key={item.id}>
                            <CardHeader
                              className={
                                "card-header bg-white pointer-cursor " +
                                (collapse === item.id ? "collapsed " : "")
                              }
                              onClick={() => toggleCollapse(item.id)}
                            >
                              <i className="fa fa-circle f-s-8 mr-2 text-indigo"></i>{" "}
                              {item.helpCategory.name} > {item.question}
                            </CardHeader>
                            <Collapse isOpen={collapse === item.id}>
                              <CardBody>
                                <Link
                                  to={`/helpConfigs/${match.params.CommonApp}/${config.AdminHelpConfig.id}/helps/${item.id}${location.search}`}
                                >
                                  {item.answer}
                                </Link>
                              </CardBody>
                            </Collapse>
                          </Card>
                        );
                      })}
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminHelpList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/helpConfigs/${match.params.CommonApp}/${config.AdminHelpConfig.id}/helps/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
