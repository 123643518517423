import React from "react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  HELP_CATEGORY_LIST_QUERY,
  HELP_EDIT_QUERY,
  HELP_DETAIL_QUERY,
  HELP_DELETE_QUERY
} from "../../Queries";
import { Button } from "reactstrap";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  Panel,
  PanelHeader,
  PanelBody
} from "../../../../../Components/Panel/Panel";
// import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons";

export default ({
  history,
  match,
  location,
  id,
  helpCategory,
  question,
  answer
}) => {
  const { data } = useQuery(HELP_CATEGORY_LIST_QUERY, {
    variables: {
      helpConfig: match.params.helpConfig,
      commonApp: match.params.CommonApp
    }
  });
  const [helpEditMutation] = useMutation(HELP_EDIT_QUERY, {
    refetchQueries: () => [{ query: HELP_DETAIL_QUERY, variables: { id } }]
  });
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      helpCategory: helpCategory.id,
      question: question,
      answer: answer
    }
  });
  const onSubmit = async data => {
    try {
      const {
        data: { AdminHelpEdit }
      } = await helpEditMutation({
        variables: {
          id: id,
          helpCategory: data.helpCategory,
          question: data.question,
          answer: data.answer
        }
      });
      if (AdminHelpEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const [helpDeleteMutation] = useMutation(HELP_DELETE_QUERY);
  const onDelete = async id => {
    try {
      const {
        data: { AdminHelpDelete }
      } = await helpDeleteMutation({
        variables: {
          id: id
        }
      });
      if (AdminHelpDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  return (
    <>
      <Helmet>
        <title>도움말 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">도움말</li>
        </ol>
        <h1 className="page-header">도움말</h1>
        <form
          className="form-horizontal form-bordered"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">분류</label>
                    <div className="col-md-10">
                      {data &&
                        data.AdminHelpCategoryList.helpCategories.map(
                          (category, index) => {
                            return (
                              <div
                                key={index}
                                className="radio radio-css radio-inline"
                              >
                                <input
                                  key={category.id}
                                  type="radio"
                                  id={`helpCategory${category.id}`}
                                  name="helpCategory"
                                  className="form-control"
                                  value={category.id}
                                  ref={register({
                                    required: "분류를 선택해주세요."
                                  })}
                                />
                                <label htmlFor={`helpCategory${category.id}`}>
                                  {category.name}
                                </label>
                              </div>
                            );
                          }
                        )}
                      {errors.helpCategory && (
                        <span>{errors.helpCategory.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">질문</label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        name="question"
                        className="form-control m-b-5"
                        placeholder="질문을 입력해주세요."
                        ref={register({
                          required: "질문을 입력해주세요."
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xs-12">
                      <textarea
                        name="answer"
                        className="form-control m-b-5"
                        placeholder="답변을 입력해주세요."
                        ref={register({
                          required: "답변을 입력해주세요."
                        })}
                      ></textarea>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} />
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue">
                  확인
                </button>
                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
