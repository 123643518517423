import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import DeliveryStoreSelect from "../../../../Containers/DeliveryStore/Select";
import Chart from "react-google-charts";
import moment from "moment";
import NumberFormat from "react-number-format";
import { dayOfWeekShortName } from "../../../../Components/User/UserNames";

export default ({
  history,
  match,
  location,
  year,
  month,
  deliveryStore_id,
  data,
  baseUrl,
}) => {
  const currentYear = new Date().getFullYear();
  const selectYears = Array.from(
    new Array(currentYear - 2022),
    (val, index) => index + 2023
  ).sort((a, b) => b - a);
  const selectMonths = Array.from(
    new Array(12),
    (val, index) => index + 1
  ).sort((a, b) => b - a);

  const onMonthChange = (e) => {
    const [year, month] = e.target.value.split("-");
    history.push(`/deliveryStatistics/month/${year}/${month}`);
  };

  const startDay = new Date(year, month, 1).getDate();
  const endDay = new Date(year, month, 0).getDate();

  const onStoreChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" + (e.target.value ? "deliveryStore_id=" + e.target.value + "&" : "");
    history.push(`${baseUrl}`);
  };

  const [chartData, setChartData] = useState([
    ["결제일자", "전체", "환불", "매출"],
  ]);
  const [total, setTotal] = useState(0);
  const [refund, setRefund] = useState(0);
  const [pay, setPay] = useState(0);

  useEffect(() => {
    let array = chartData;
    for (var loop = startDay; loop <= endDay; loop++) {
      const date = `${year}-${month < 10 ? `0${month}` : month}-${
        loop < 10 ? `0${loop}` : loop
      }`;
      let dayData = data.AdminDeliveryStatisticsMonth.statisticsDays.find(
        (item) => item.createdAt === date
      );

      if (dayData) {
        chartData.push([
          `${loop < 10 ? `0${loop}` : loop}`,
          dayData.payTotalPrice + dayData.refundTotalPrice,
          dayData.refundTotalPrice,
          dayData.payTotalPrice,
        ]);
        setTotal(
          (total) => total + dayData.payTotalPrice + dayData.refundTotalPrice
        );
        setRefund((refund) => refund + dayData.refundTotalPrice);
        setPay((pay) => pay + dayData.payTotalPrice);
      } else {
        chartData.push([`${loop < 10 ? `0${loop}` : loop}`, 0, 0, 0]);
      }
    }
    setChartData(array);

    // console.log({ chartData });
  }, []);

  return (
    <>
      <Helmet>
        <title>월별 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to="/deliveryStores">로컬푸드 구독</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to={`/deliveryStatistics/month/${year}/${month}`}>통계</Link>
          </li>
          <li className="breadcrumb-item active">월별</li>
        </ol>
        <h1 className="page-header">월별</h1>

        <Panel>
          <PanelHeader noButton={true}>월별</PanelHeader>
          <Alert className="bg-gray-transparent-1">
            <div className="row m-b-15">
              <div className="col-md-12">
                <select
                  className="form-control"
                  defaultValue={`${year}-${month}`}
                  onChange={onMonthChange}
                >
                  {selectYears.map((selectYear) => {
                    return selectMonths.map((selectMonth, index) => {
                      return (
                        <option
                          key={index}
                          value={`${selectYear}-${selectMonth}`}
                        >
                          {selectYear}년{" "}
                          {selectMonth < 10 ? `0${selectMonth}` : selectMonth}월
                        </option>
                      );
                    });
                  })}
                </select>
              </div>
            </div>
            <div className="row m-b-15">
              <div className="col-xs-12">
                <DeliveryStoreSelect
                  onChange={onStoreChange}
                  value={deliveryStore_id}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 rounded-1 p-10 height-430">
                  <Chart
                    style={{ width: "100%" }}
                    height={430}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={{
                      bars: "vertical",
                      height: 400,
                      legend: { position: "top", maxLines: 3 },
                      bar: { groupWidth: "75%" },
                      isStacked: true,
                    }}
                    formatters={[
                      {
                        type: "NumberFormat",
                        column: 1,
                        options: {
                          suffix: " 드림",
                          fractionDigits: 0,
                        },
                      },
                      {
                        type: "NumberFormat",
                        column: 2,
                        options: {
                          suffix: " 드림",
                          fractionDigits: 0,
                        },
                      },
                      {
                        type: "NumberFormat",
                        column: 3,
                        options: {
                          suffix: " 드림",
                          fractionDigits: 0,
                        },
                      },
                    ]}
                    legendToggle
                  />
                </div>
              </div>
            </div>
          </Alert>
          <PanelBody>
            <div className="table-responsive">
              <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-top m-b-0">
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{chartData[0][0]}</th>
                    <th>{chartData[0][1]}</th>
                    <th>{chartData[0][2]}</th>
                    <th>{chartData[0][3]}</th>
                    <th>기능</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>합계</td>
                    <td align="right">
                      <NumberFormat
                        value={total}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림
                    </td>
                    <td align="right">
                      <NumberFormat
                        value={refund}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림
                    </td>
                    <td align="right">
                      <NumberFormat
                        value={pay}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림
                    </td>
                    <td>-</td>
                  </tr>
                  {chartData
                    .filter((item) => item[0] !== `결제일자`)
                    .map((item, index) => {
                      const dayOfWeek = moment(
                        `${year}-${month}-${item[0]}`,
                        "YYYY-MM-DD"
                      ).day();

                      const payDate = moment(`${year}-${month}-${item[0]}`)
                        .add(1, "days")
                        .format("YYYY-MM-DD");

                      return (
                        <tr key={index}>
                          <td>
                            {year}년 {month < 10 ? `0${month}` : month}월{" "}
                            {item[0]}일(
                            {dayOfWeekShortName(dayOfWeek)})
                          </td>
                          <td align="right">
                            <NumberFormat
                              value={item[1]}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            드림
                          </td>
                          <td align="right">
                            <NumberFormat
                              value={item[2]}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            드림
                          </td>
                          <td align="right">
                            <NumberFormat
                              value={item[3]}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            드림
                          </td>
                          <td>
                            <div>
                              <Link
                                className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                to={`/deliveryOrders?startDate=${payDate}&endDate=${payDate}`}
                              >
                                상세정보
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td>합계</td>
                    <td align="right">
                      <NumberFormat
                        value={total}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림
                    </td>
                    <td align="right">
                      <NumberFormat
                        value={refund}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림
                    </td>
                    <td align="right">
                      <NumberFormat
                        value={pay}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림
                    </td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </PanelBody>
        </Panel>
      </div>
    </>
  );
};
