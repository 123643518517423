import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import { dayName } from "../../../../../Components/User/UserNames";
import { graphqlUri } from "../../../../../Config/Env";
import Category from "./ListCategory";

import ReactMoment from "react-moment";

export default ({
  history,
  match,
  location,
  page,
  day,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleDateApplyEvent,
  handleSearch,
}) => {
  return (
    <>
      <Helmet>
        <title>오늘의 할인 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="/stores">가맹점</Link>
          </li>
          <li className="breadcrumb-item active">오늘의 할인</li>
        </ol>
        <h1 className="page-header">오늘의 할인</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminDiscountList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <Category
                  history={history}
                  match={match}
                  location={location}
                  keyword={keyword}
                />
                <form onSubmit={handleSearch}>
                  <InputGroup>
                    <Input
                      type="text"
                      className="input-white"
                      placeholder="검색어(제목, 내용) 입력"
                      value={keywordInput.value}
                      onChange={keywordInput.onChange}
                    />
                    <InputGroupAddon addonType="append">
                      <Button size="sm" color="white">
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </form>
              </Alert>
              {!loading && data.AdminDiscountList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>요일</th>
                            <th>커버 이미지</th>
                            <th>가맹점 분류</th>
                            <th>가맹점명 </th>
                            <th>제목</th>
                            <th>기간</th>
                            <th>등록일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminDiscountList.discounts.map((item) => {
                            const coverImageImage =
                              item.coverImage === "" || item.coverImage === null
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${item.coverImage}`;
                            const date = new Date();
                            const startDate = new Date(item.startDate);
                            const endDate = new Date(item.endDate);
                            return (
                              <tr key={item.id}>
                                <td>
                                  {item.commonStatus === "S" && "비공개"}
                                  {item.commonStatus === "C" &&
                                  date >= startDate &&
                                  date <= endDate
                                    ? "진행"
                                    : "종료"}
                                </td>
                                <td>{dayName(item.day)}</td>
                                <td className="with-img">
                                  <img
                                    src={coverImageImage}
                                    className="img-rounded height-30"
                                    alt={item.title}
                                  />
                                </td>
                                <td>{item.store.storeCategory.name}</td>
                                <td>{item.store.name}</td>
                                <td>{item.title}</td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD">
                                    {item.startDate}
                                  </ReactMoment>{" "}
                                  ~
                                  <ReactMoment format="YYYY-MM-DD">
                                    {item.endDate}
                                  </ReactMoment>
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {item.createdAt}
                                  </ReactMoment>
                                </td>
                                <td>
                                  <Link
                                    className={`btn btn-white btn-xs text-green m-r-5`}
                                    to={`/discounts/${item.id}${location.search}`}
                                  >
                                    할인정보
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminDiscountList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/discounts/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
