import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap"
import Pagination from "../../../../../../Components/Pagination/QSP"
import ReactMoment from "react-moment"
import NumberFormat from "react-number-format"
import { graphqlUri } from "../../../../../../Config/Env"
import { commonStatusName } from "../../../../../../Components/User/UserNames"
import Category from "./ListCategory"
import ConfigWrite from "../../../Write"

export default ({
  history,
  match,
  location,
  configs,
  config,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const toggleModal = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <Helmet>
        <title>상품 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to="/giftConfigs">드림상점</Link>
          </li>
          <li className="breadcrumb-item active">상품</li>
        </ol>
        <h1 className="page-header">상품</h1>
        <div className="row">
          <div className="col-xs-12">
            <ul className="nav nav-tabs nav-tabs-inverse">
              {configs.map((item) => (
                <li key={item.id} className={`nav-item`}>
                  <Link
                    to={`/giftConfigs/${item.id}/products`}
                    className={`nav-link ${item.id === config.id && "active"}`}
                  >
                    <span>
                      {item.name}({item.giftProductsCount}건)
                    </span>
                  </Link>
                </li>
              ))}
              {/* <li className={`nav-item`}>
                <Button
                  onClick={() => {
                    toggleModal();
                  }}
                  className={`btn-inverse active`}
                >
                  <span>
                    <i className="fa fa-plus"></i> 추가
                  </span>
                </Button>
              </li> */}
            </ul>
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="row m-b-15">
                  <div className="col-xs-12">
                    <Category
                      history={history}
                      match={match}
                      location={location}
                      config={config}
                    />
                  </div>
                </div>
                <div className="row m-b-15">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(상품명) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                          <Link
                            to={`/giftConfigs/${config.id}/edit${location.search}`}
                            className="btn btn-white"
                          >
                            <i className="fa fa-cog fa-fw"></i>
                          </Link>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
                {!loading && data.AdminGiftProductList.count > 0 ? (
                  <>
                    <div>
                      <div className="product-list-wrap">
                        <ul>
                          {data.AdminGiftProductList.giftProducts.map(
                            (item, index) => {
                              const Cover =
                                item.coverImage === "" ||
                                item.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.coverImage}`
                              return (
                                <li key={index} className="product-list">
                                  <Link
                                    to={`/giftConfigs/${config.id}/products/${item.id}${location.search}`}
                                  >
                                    <div
                                      className="img"
                                      style={{
                                        backgroundImage: `url(${Cover})`,
                                      }}
                                    >
                                      <img src={Cover} alt={item.name} />
                                      <div className="category">
                                        <span className="label label-yellow">
                                          {commonStatusName(item.commonStatus)}
                                        </span>
                                        {item.isNew && (
                                          <span className="label label-primary">
                                            New
                                          </span>
                                        )}
                                        {item.isBest && (
                                          <span className="label label-success">
                                            Best
                                          </span>
                                        )}
                                        {item.isSoldOut && (
                                          <span className="label label-danger">
                                            품절
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="product-list-cont">
                                      <div className="desc">
                                        {item.store.name}
                                      </div>
                                      <h4 className="tit ">
                                        [{item.giftCategory.name}] {item.name}{" "}
                                      </h4>
                                      <div className="desc">
                                        {item.description}
                                      </div>
                                      <div className="price-info">
                                        {item.isDiscount ? (
                                          <>
                                            <span className="discount">
                                              <NumberFormat
                                                value={item.price}
                                                suffix={"원"}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                              />
                                            </span>
                                            <span className="price">
                                              <NumberFormat
                                                value={
                                                  item.price -
                                                  item.discountPrice
                                                }
                                                prefix={""}
                                                suffix={"원"}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                              />
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="price">
                                              <NumberFormat
                                                value={item.price}
                                                suffix={"원"}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                              />
                                            </span>
                                          </>
                                        )}
                                        <span className="date">
                                          <ReactMoment format="YYYY-MM-DD">
                                            {item.createdAt}
                                          </ReactMoment>
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.AdminGiftProductList.count}
                            blockSize={blockSize}
                            pageSize={first}
                            currentPage={page}
                            baseUrl={baseUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-xs-12 p-40">
                      <p className="text-center">등록된 데이터가 없습니다.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row p-b-15">
          <div className="col-xs-4 text-left">
            <ButtonGroup>
              <Link
                to={`/giftConfigs/${config.id}/products/best${location.search}`}
                className="btn btn-white"
              >
                BEST 정렬
              </Link>
              <Link
                to={`/giftConfigs/${config.id}/products/new${location.search}`}
                className="btn btn-white"
              >
                NEW 정렬
              </Link>
            </ButtonGroup>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right"></div>
        </div> */}
      </div>
      <Link
        to={`/giftConfigs/${config.id}/products/write`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
      <ConfigWrite isVisible={isVisible} toggleModal={toggleModal} />
    </>
  )
}
