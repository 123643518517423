import React, { useState, forwardRef } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { COUPON_CATEGORY_LIST_QUERY, COUPON_WRITE_QUERY } from "../Queries";
import { useForm } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { ButtonGroup } from "reactstrap";
import { toast } from "react-toastify";
import { commonStatusName } from "../../../../Components/User/UserNames";
import StoresSearchModal from "./WriteStores/index";

import DatePicker from "react-datepicker";
import "../../../../Containers/SelectedPeriod/DatePicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

import WriteStore from "./WriteStore";

export default withRouter(({ history, match, location }) => {
  const { data } = useQuery(COUPON_CATEGORY_LIST_QUERY, {
    variables: {
      couponConfig: match.params.couponConfig,
    },
  });
  const [couponWriteMutation] = useMutation(COUPON_WRITE_QUERY);
  const { register, handleSubmit, errors } = useForm();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [store, setStore] = useState("");
  const [stores, setStores] = useState([]);
  const [checkItems, setCheckItems] = useState([]);
  const [couponProductImage, setCouponProductImage] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const handleCouponProductImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setCouponProductImage(reader.result);
    };
  };
  const onClick_product = () => {
    document.getElementById("productImage").click();
  };

  const [storesSearchIsVisible, setStoresSearchIsVisible] = useState(false);
  const storesSearchToggleModal = () => {
    setStoresSearchIsVisible(!storesSearchIsVisible);
  };

  const handleSelectCheck = (checked, item) => {
    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      let itemArray = [];
      stores.forEach((item) => itemArray.push(item));
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };
  const onDelete = () => {
    if (checkItems.length === 0) {
      toast.error("삭제할 수신자를 선택해주세요.");
      return;
    }

    let itemArray = [];
    stores.forEach((item) => {
      !checkItems.includes(item) && itemArray.push(item);
    });
    setStores(itemArray);
  };

  const onSubmit = async (data) => {
    try {
      if (store === "") {
        toast.error("가맹점명을 입력해주세요.");
        return;
      }
      if (stores.length === 0) {
        toast.error("참여 가맹점을 추가해주세요.");
        return;
      }
      const StoreIds = stores.map((item) => {
        return item.id;
      });

      const {
        data: { AdminCouponWrite },
      } = await couponWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          startDate,
          endDate,
          title: data.title,
          contents: data.contents,
          limit: data.limit,
          price: data.price,
          store: store && store.value,
          productName: data.productName,
          productDetail: data.productDetail,
          productImage: data.productImage[0],
          stores: StoreIds,
          isDuplication: data.isDuplication === "true" ? true : false,
          couponConfig: match.params.couponConfig,
          couponCategory: data.couponCategory,
        },
      });
      if (AdminCouponWrite) {
        toast.success("처리 되었습니다.");
        history.push(
          `/couponConfigs/${match.params.couponConfig}/coupons${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-white" onClick={onClick}>
      {value}
      <i className="fa fa-calendar fa-fw text-black-transparent-5 m-l-5"></i>
    </button>
  ));

  return (
    <>
      <Helmet>
        <title>쿠폰 관리 | 한밭페이</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">쿠폰</li>
          </ol>
          <h1 className="page-header">쿠폰</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/couponConfigs/${match.params.couponConfig}/coupons${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
                {/* <a href="" className="btn btn-white text-success">
                <FontAwesomeIcon icon={faCheck} />
                저장
              </a> */}
                {/* <a href="" className="btn btn-white text-danger">
                <FontAwesomeIcon icon={faTrashAlt}/>
                삭제
              </a> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-9">
              <Panel>
                <PanelHeader noButton={true}>기본정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-4">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            className="form-control"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            className="form-control"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div>{errors.commonStatus.message}</div>
                        )}
                      </div>
                      <label className="col-form-label col-md-2">분류</label>
                      <div className="col-md-4">
                        {data ? (
                          data.AdminCouponCategoryList.couponCategories.map(
                            (category, index) => {
                              return (
                                <div
                                  key={index}
                                  className="radio radio-css radio-inline"
                                >
                                  <input
                                    key={category.id}
                                    type="radio"
                                    id={category.id}
                                    name="couponCategory"
                                    className="form-control"
                                    value={category.id}
                                    ref={register({
                                      required: "분류를 선택해주세요.",
                                    })}
                                  />
                                  <label htmlFor={category.id}>
                                    {category.name}
                                  </label>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <span>분류추가 후 이용해주세요.</span>
                        )}
                        {errors.couponCategory && (
                          <div>{errors.couponCategory.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">기간</label>
                      <div className="col-md-10">
                        <div className="input-group">
                          <DatePicker
                            name="startDate"
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day text-white">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsEnd
                          />
                          <span className="custom-react-datepicker__split-span">
                            ~
                          </span>
                          <DatePicker
                            name="endDate"
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                          />
                        </div>
                        {errors.startDate && (
                          <span>{errors.startDate.message}</span>
                        )}
                        {errors.endDate && (
                          <span>{errors.endDate.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        가맹점 중복유무
                      </label>
                      <div className="col-md-10">
                        <div className="checkbox checkbox-css checkbox-inline">
                          <input
                            type="checkbox"
                            id="isDuplication"
                            name="isDuplication"
                            value={true}
                            ref={register()}
                          />
                          <label htmlFor="isDuplication">중복</label>
                          {errors.isDuplication && (
                            <span>{errors.isDuplication.message}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">인원</label>
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="limit"
                          className="form-control"
                          placeholder="인원을 입력해주세요."
                          ref={register({
                            required: "인원을 입력해주세요.",
                          })}
                        />
                        {errors.limit && <span>{errors.limit.message}</span>}
                      </div>
                      <label className="col-form-label col-md-2">금액</label>
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="price"
                          className="form-control"
                          placeholder="금액을 입력해주세요."
                          ref={register({
                            required: "금액을 입력해주세요.",
                          })}
                        />
                        {errors.price && <span>{errors.price.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">가맹점</label>
                      <div className="col-md-10">
                        <WriteStore
                          history={history}
                          match={match}
                          location={location}
                          store={store}
                          setStore={setStore}
                        />
                        {errors.store && <span>{errors.store.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          placeholder="제목을 입력해주세요."
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <span>{errors.title.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">내용</label>
                      <div className="col-md-10">
                        <textarea
                          name="contents"
                          className="form-control"
                          placeholder="내용을 입력해주세요."
                          ref={register({
                            required: "내용을 입력해주세요.",
                          })}
                          rows={10}
                        ></textarea>
                        {errors.contents && (
                          <span>{errors.contents.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>

            <div className="col-md-3">
              <Panel>
                <PanelHeader noButton={true}>상품정보</PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          상품명
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            name="productName"
                            className="form-control"
                            placeholder="상품명을 입력해주세요."
                            ref={register({
                              required: "상품명을 입력해주세요.",
                            })}
                          />
                          {errors.productName && (
                            <span>{errors.productName.message}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          상품설명
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            name="productDetail"
                            className="form-control"
                            placeholder="상품설명을 입력해주세요."
                            ref={register({
                              required: "상품설명을 입력해주세요.",
                            })}
                          />
                          {errors.productDetail && (
                            <span>{errors.productDetail.message}</span>
                          )}
                        </div>
                      </div>
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={couponProductImage}
                            alt="이미지 업로드"
                            className="height-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="productImage"
                          name="productImage"
                          className="hide"
                          onChange={handleCouponProductImage}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_product}
                        >
                          상품 이미지 업로드
                        </button>
                      </div>
                      {errors.productImage && (
                        <span className="text-danger">
                          {errors.productImage.message}
                        </span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b>상품이미지 사이즈 : </b> 넓이 600px X 높이 600px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>

            <div className="col-md-9">
              <Panel>
                <PanelHeader noButton={true}>
                  <ButtonGroup className="pull-right">
                    <button
                      type="button"
                      className=" btn btn-white btn-xs text-primary"
                      onClick={() => {
                        storesSearchToggleModal();
                      }}
                    >
                      <i className="fas fa-plus"></i> 추가
                    </button>
                    <button
                      type="button"
                      className=" btn btn-white btn-xs text-danger"
                      onClick={() => {
                        onDelete();
                        setCheckItems([]);
                      }}
                    >
                      <i className="fas fa-trash-alt"></i> 삭제
                    </button>
                  </ButtonGroup>
                  참여 가맹점 정보({stores.length}건)
                </PanelHeader>
                <PanelBody className="p-0">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>상태</th>
                          <th>가맹점명 - 이름(아이디)</th>
                          <th>주소</th>
                          <th>
                            <input
                              type="checkbox"
                              onChange={(e) => handleAllCheck(e.target.checked)}
                              checked={
                                stores.length > 0 &&
                                checkItems.length === stores.length
                                  ? true
                                  : false
                              }
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stores.length > 0 ? (
                          <>
                            {stores.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{commonStatusName(item.commonStatus)}</td>
                                  <td>
                                    {item.name} - {item.user.name}(
                                    {item.user.userId})
                                  </td>
                                  <td>
                                    ({item.storeZipcode})
                                    {item.storeJibunAddress}{" "}
                                    {item.storeAddressDetail}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleSelectCheck(
                                          e.target.checked,
                                          item
                                        )
                                      }
                                      checked={
                                        checkItems.includes(item) ? true : false
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={4}>
                              <p className="m-40 text-center">
                                참여 가맹점을 추가해주세요.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
              <StoresSearchModal
                isVisible={storesSearchIsVisible}
                toggleModal={storesSearchToggleModal}
                stores={stores}
                setStores={setStores}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/couponConfigs/${match.params.couponConfig}/coupons${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
                {/* <a href="" className="btn btn-white text-success">
                <FontAwesomeIcon icon={faCheck} />
                저장
              </a> */}
                {/* <a href="" className="btn btn-white text-danger">
                <FontAwesomeIcon icon={faTrashAlt}/>
                삭제
              </a> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
