import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { STATISTICS_CHARGEREQUESTNOMAL_QUERY } from "./Queries";
import { useQuery } from "react-apollo-hooks";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import qs from "qs";
import Chart from "react-apexcharts";
import NumberFormat from "react-number-format";

export default withRouter(({ history, match, location, isModal }) => {
  const [lineChart, setLineChart] = useState(null);

  const queryString = qs.parse(location.search.substr(1));
  const startDate = queryString.startDate;
  const endDate = queryString.endDate;

  const { data, loading } = useQuery(STATISTICS_CHARGEREQUESTNOMAL_QUERY, {
    variables: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  const modalRef = React.createRef();
  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });
  useEffect(() => {
    if (!loading) {
      const dateArray = data.AdminStatisticsChargeRequestNomal.list.map(
        (item) => item.date
      );
      const countArray = data.AdminStatisticsChargeRequestNomal.list.map(
        (item) => item.count
      );
      const totalArray = data.AdminStatisticsChargeRequestNomal.list.map(
        (item) => item.total
      );

      setLineChart({
        options: {
          chart: {
            type: "line",
            height: 350,
            shadow: {
              enabled: true,
              color: "#2d353c",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1,
            },
            toolbar: {
              show: false,
            },
          },
          //   title: {
          //     text: `${startDate}~${endDate} 드림P 발행금액(건)`,
          //     align: "center"
          //   },
          colors: ["#348fe2", "#00acac"],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
            width: 3,
          },
          grid: {
            row: {
              colors: ["rgba(182, 194, 201, 0.1)", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 4,
          },
          xaxis: {
            categories: dateArray,
            axisBorder: {
              show: true,
              color: "rgba(182, 194, 201, 0.5)",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: -1,
            },
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#b6c2c9",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            min: 0,
            max: Math.max.apply(null, totalArray),
          },
          legend: {
            show: true,
            position: "top",
            offsetY: -10,
            horizontalAlign: "right",
            floating: true,
          },
        },
        series: [
          {
            name: "건수",
            data: [...countArray],
          },
          {
            name: "금액",
            data: [...totalArray],
          },
        ],
      });
    }
  }, [loading, data]);

  let allCount = 0;
  let allTotal = 0;
  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/statisticsUser?startDate=${startDate}&endDate=${endDate}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/statisticsUser?startDate=${startDate}&endDate=${endDate}`
            );
          }}
        >
          일반 충전금액
        </ModalHeader>
        <ModalBody className="p-0">
          {!loading && lineChart ? (
            <>
              <Chart
                className="m-t-15"
                type="line"
                options={lineChart.options}
                series={lineChart.series}
              />
              <div className="m-15">
                <div className="table-responsive ">
                  <table className="table table-bordered table-striped m-b-0 text-center">
                    <thead>
                      <tr>
                        <th>일자</th>
                        <th>건수</th>
                        <th>금액</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.AdminStatisticsChargeRequestNomal.list.map(
                        (item, index) => {
                          allCount += item.count;
                          allTotal += item.total;
                          return (
                            <tr key={index}>
                              <td>{item.date}</td>
                              <td>{item.count}건</td>
                              <td>
                                <NumberFormat
                                  value={item.total}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />{" "}
                                드림P
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>전체</td>
                        <td>{allCount}건</td>
                        <td>
                          <NumberFormat
                            value={allTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                          />{" "}
                          드림P
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <p className="text-center">등록된 데이터가 없습니다.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  );
});
