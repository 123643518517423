import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CHARGEREQUESTGRU_WRITE_QUERY } from "./Queries";
import { useMutation } from "react-apollo-hooks";

import { toast } from "react-toastify";

import WriteWallet from "./WriteWallet";

export default withRouter(({ history, match, location }) => {
  const [chargeRequestWriteMutation] = useMutation(
    CHARGEREQUESTGRU_WRITE_QUERY
  );
  const { register, handleSubmit, watch, errors } = useForm();

  const [wallet, setWallet] = useState("");

  const onSubmit = async (data) => {
    if (wallet === "") {
      toast.error("사용자 이름을 입력해주세요.");
      return;
    }

    if (data.amount === "etc") {
      if (data.etc_amount === "") {
        toast.error("금액을 입력해주세요.");
        return;
      }
      if (data.etc_amount < 0) {
        toast.error("금액을 양수로 입력해주세요.");
        return;
      }
    }

    try {
      const {
        data: { AdminChargeRequestGRUWrite },
      } = await chargeRequestWriteMutation({
        variables: {
          currency: "GRU",
          amount: data.amount !== "etc" ? data.amount : data.etc_amount,
          wallet: wallet,
        },
      });
      if (AdminChargeRequestGRUWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/chargeRequestGRUs${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  return (
    <>
      <Helmet>
        <title>한밭페이 | GRU 충전내역 관리</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">충전내역</li>
          </ol>
          <h1 className="page-header">충전내역</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/chargeRequestGRUs${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>목록</PanelHeader>
                <PanelBody className="panel-form form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">사용자</label>
                    <div className="col-md-10">
                      <WriteWallet
                        history={history}
                        match={match}
                        location={location}
                        wallet={wallet}
                        setWallet={setWallet}
                      />
                      {errors.wallet && <span>{errors.wallet.message}</span>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-md-2">금액</label>
                    <div className="col-md-10">
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount0"
                          name="amount"
                          value={"5000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount0">5,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount1"
                          name="amount"
                          value={"10000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount1">10,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount2"
                          name="amount"
                          value={"20000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount2">20,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount3"
                          name="amount"
                          value={"50000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount3">50,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount4"
                          name="amount"
                          value={"100000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount4">100,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount5"
                          name="amount"
                          value={"150000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount5">150,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount6"
                          name="amount"
                          value={"200000"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount6">200,000원</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="amount7"
                          name="amount"
                          value={"etc"}
                          ref={register({
                            required: "금액을 선택해주세요.",
                          })}
                        />
                        <label htmlFor="amount7">기타 </label>
                      </div>
                      {watch("amount") === "etc" && (
                        <input
                          type="number"
                          name="etc_amount"
                          className="form-control m-l-15 width-200"
                          placeholder="금액을 입력해주세요."
                          ref={register()}
                          style={{ display: "inline-block" }}
                        />
                      )}
                      {errors.amount && <div>{errors.amount.message}</div>}
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/chargeRequestConfigs/${match.params.chargeRequestConfig}/chargeRequests${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
