import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import {
  transactionTypeName,
  currencyName,
} from "../../../../../Components/User/UserNames";
import ReactMoment from "react-moment";
import qs from "qs";

export default ({
  history,
  match,
  location,
  trustline,
  data,
  year,
  month,
  prevYear,
  prevMonth,
  nextYear,
  nextMonth,
}) => {
  const doExcelDown = async () => {
    try {
      const params = `?trustlineId=${trustline.AdminTrustlineDetail.id}&year=${year}&month=${month}`;
      await fetch(`${graphqlUri}/excels/feeTrustline${params}`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          trustlineId: trustline.AdminTrustlineDetail.id,
          year,
          month,
        }),
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${year}년 ${month}월 납부내역.xlsx`;
          document.body.appendChild(a);
          a.click();
          setTimeout((_) => {
            window.URL.revokeObjectURL(url);
          }, 60000);
          a.remove();
        })
        .catch((err) => {
          console.error("err: ", err);
        });

      // if (res.ok) {
      //   refetch();
      //   return toast.success("커버 이미지가 업로드되었습니다.");
      // }
    } catch (e) {
      console.log(e);
      // toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword, page: queryString.page };

  const {
    AdminLedgerList: { ledgers, count },
  } = data;

  return (
    <>
      <Helmet>
        <title>거래내역 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">회비</li>
          <li className="breadcrumb-item active">거래내역</li>
        </ol>
        <h1 className="page-header">거래내역</h1>

        <div className="row m-b-15">
          <div className="col-xs-6 text-left">
            <Link to={`/fees${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-6 text-right">
            <div className="btn-group">
              {/* <button onClick={doExcelDown} className="btn btn-white">
                다운
              </button> */}
              <Link
                to={`/fees/${match.params.id}/trustlines/${trustline.AdminTrustlineDetail.id}/remittance`}
                className="btn btn-white"
              >
                송금
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>목록({count}건)</PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="widget widget-stats bg-green">
                  <div className="stats-icon">
                    <i className="fa fa-money-bill-alt"></i>
                  </div>
                  <div className="stats-info">
                    <h4>합계</h4>
                    <p>
                      {trustline && (
                        <NumberFormat
                          value={trustline.AdminTrustlineDetail.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      )}
                      {trustline &&
                        currencyName(trustline.AdminTrustlineDetail.currency)}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-4 text-right">
                    <Link
                      to={`/fees/${match.params.id}/trustlines/${
                        match.params.trustline
                      }?${qs.stringify({
                        year: prevYear,
                        month: prevMonth,
                        ...query,
                      })}`}
                      className="btn btn-white btn-xs text-green"
                    >
                      <i className="fa fa-angle-left" />{" "}
                      {prevMonth === 0
                        ? `${prevYear - 1}년 ${12}월`
                        : `${prevYear}년 ${prevMonth}월`}
                    </Link>
                  </div>
                  <div className="col-xs-4 text-center">
                    {year}년 {month}월
                  </div>
                  <div className="col-xs-4 text-left">
                    <Link
                      to={`/fees/${match.params.id}/trustlines/${
                        match.params.trustline
                      }?${qs.stringify({
                        year: nextYear,
                        month: nextMonth,
                        ...query,
                      })}`}
                      className="btn btn-white btn-xs text-green"
                    >
                      {nextMonth === 0
                        ? `${nextYear - 1}년 ${12}월`
                        : `${nextYear}년 ${nextMonth}월`}{" "}
                      <i className="fa fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </Alert>
              <PanelBody className="">
                {count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            {/* <th>id</th> */}
                            <th>형태</th>
                            <th>내용</th>
                            <th>일자</th>
                            <th>금액</th>
                            <th>합계</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ledgers.map((ledger) => {
                            return (
                              <tr key={ledger.id}>
                                {/* <td>{ledger.id}</td> */}
                                <td>
                                  {transactionTypeName(ledger.transactionType)}
                                </td>
                                <td>{ledger.title}</td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {ledger.createdAt}
                                  </ReactMoment>
                                </td>
                                <td align="right">
                                  {ledger.wallet.id ===
                                    ledger.destinationWallet.id &&
                                  ledger.destinationWallet.id
                                    ? ""
                                    : "-"}
                                  <NumberFormat
                                    value={ledger.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(ledger.currency)}
                                </td>
                                <td align="right">
                                  <NumberFormat
                                    value={ledger.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(ledger.currency)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">
                    {year}년 {month}월 등록된 데이터가 없습니다.
                  </p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6 text-left">
            <Link to={`/fees${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-6 text-right">
            <div className="btn-group">
              {/* <button onClick={doExcelDown} className="btn btn-white">
                다운
              </button> */}
              <Link
                to={`/fees/${match.params.id}/trustlines/${trustline.AdminTrustlineDetail.id}/remittance`}
                className="btn btn-white"
              >
                송금
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
