import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import { graphqlUri } from "../../../../Config/Env";
import {
  roleArray,
  roleName,
  currencyArray,
  currencyName,
  pastPeriod,
} from "../../../../Components/User/UserNames";
import Moment from "moment";
import NumberFormat from "react-number-format";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  page,
  startSearchDate,
  endSearchDate,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  handleSearch,
  DownloadButton,
  handleApproval,
  onSync,
}) => {
  const Role = () => {
    const urlConfig = (role) => {
      const ledgersUrl =
        "?" +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (keyword ? "keyword=" + keyword + "&" : "");
      return ledgersUrl;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        {Object.keys(roleArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                match.params.role === item ? "active" : ""
              }
            `}
              to={`/users/${item}${urlConfig(item)}`}
            >
              {roleArray[item]}
            </Link>
          );
        })}
        <Link
          className={`btn btn-white m-b-5 ${
            match.params.role === null ? "active" : ""
          } `}
          to={`/withdrawals`}
        >
          탈퇴자
        </Link>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>{roleName(match.params.role)} 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/users/${match.params.role}`}>사용자</Link>
          </li>
          <li className="breadcrumb-item active">
            {roleName(match.params.role)}
          </li>
        </ol>
        <h1 className="page-header">{roleName(match.params.role)}</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminUserList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xl-12">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                  </div>
                </div>
                <Role />
                <div className="btn-group m-b-15 m-r-5">
                  <DownloadButton />
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(아이디, 이름, 이메일, 휴대전화) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody>
                {!loading && data.AdminUserList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>이미지</th>
                            <th>
                              {match.params.role === "Store" ? (
                                <>상태 상호명 - 이름(아이디)</>
                              ) : (
                                <>상태 이름(아이디)</>
                              )}
                            </th>
                            {match.params.role === "User" && (
                              <>
                                <th>이메일 </th>
                                <th>휴대전화</th>
                              </>
                            )}
                            {match.params.role !== "Issuer" && <th>잔액</th>}
                            {match.params.role === "Issuer" && (
                              <th>관리 화폐</th>
                            )}
                            <th>가입일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminUserList.users.map((user) => {
                            const avatarImage =
                              user.avatar === "" || user.avatar === null
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${user.avatar}`;
                            return (
                              <tr key={user.id}>
                                <td className="with-img text-center">
                                  <img
                                    src={avatarImage}
                                    className="img-rounded width-30 height-30"
                                    alt={user.name}
                                  />
                                </td>
                                <td>
                                  <i
                                    className={
                                      "fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
                                    }
                                    style={{
                                      color:
                                        user.commonStatus === "C" &&
                                        user.confirmed === true
                                          ? "#00acac"
                                          : "#ff5b57",
                                      transition: "all .3s ease",
                                    }}
                                  ></i>
                                  {match.params.role === "Store" ? (
                                    <>
                                      {user.store
                                        ? user.store.name
                                        : "연동안됨"}
                                      {" - "}
                                      {user.name}({user.userId})
                                      {user.blocked ? (
                                        <i className="fa fa-lock f-s-7 fa-fw m-r-10 m-t-5" />
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {user.name}({user.userId})
                                      {user.blocked ? (
                                        <i className="fa fa-lock f-s-7 fa-fw m-r-10 m-t-5" />
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </td>
                                {match.params.role === "User" && (
                                  <>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                  </>
                                )}
                                {match.params.role !== "Issuer" && (
                                  <td align="right">
                                    {user.wallet.trustlines.map((trustline) => {
                                      return (
                                        <span key={trustline.id}>
                                          {currencyName(trustline.currency)} :{" "}
                                          <NumberFormat
                                            value={
                                              trustline.amount -
                                              currencyArray[trustline.currency]
                                                .startAmount
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                          />
                                          <br />
                                        </span>
                                      );
                                    })}
                                  </td>
                                )}
                                {match.params.role === "Issuer" && (
                                  <td align="left">
                                    {currencyName(user.addCurrency)}
                                  </td>
                                )}
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {user.createdAt}
                                  </ReactMoment>
                                </td>
                                <td align="center">
                                  <Link
                                    to={`/users/${match.params.role}/${user.id}${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    관리
                                  </Link>
                                  {user.role === "Store" &&
                                    (user.useStoreApp ? (
                                      <button className="btn btn-white btn-xs text-green disabled">
                                        완료
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-white btn-xs text-red"
                                        onClick={(e) => handleApproval(e, user)}
                                      >
                                        승인
                                      </button>
                                    ))}

                                  {/* <Button
                                    className="btn btn-white btn-xs text-blue"
                                    onClick={() => {
                                      onSync(user.id, "DRM");
                                    }}
                                  >
                                    드림 합계 동기화
                                  </Button>
                                  <Button
                                    className="btn btn-white btn-xs text-blue"
                                    onClick={() => {
                                      onSync(user.id, "DNT");
                                    }}
                                  >
                                    드림P 합계 동기화
                                  </Button> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.AdminUserList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.AdminUserList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
            {match.params.role === "Issuer" && (
              <Link
                to={`/users/Issuer/write`}
                className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
                role={match.params.role}
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
