import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  currencyArray,
  currencyName,
  transactionTypeArray,
  transactionTypeName,
  roleName,
  pastPeriod,
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import Wallet from "./ListWallet";
import DatePicker from "../../../../../Containers/SelectedPeriod/DatePicker";
import { graphqlUri } from "../../../../../Config/Env";

export default ({
  history,
  match,
  location,
  currency,
  transactionType,
  role,
  startSearchDate,
  endSearchDate,
  wallet,
  page,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
}) => {
  const doExcelDown = async () => {
    try {
      const params =
        `?` +
        (currency ? "currency=" + currency + "&" : "") +
        (transactionType ? "transactionType=" + transactionType + "&" : "") +
        (startSearchDate ? "startDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endDate=" + endSearchDate + "&" : "") +
        (wallet ? "walletId=" + wallet + "&" : "");

      await fetch(`${graphqlUri}/excels/ledgers${params}`, {
        method: "POST",
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `납부내역.xlsx`;
          document.body.appendChild(a);
          a.click();
          setTimeout((_) => {
            window.URL.revokeObjectURL(url);
          }, 60000);
          a.remove();
        })
        .catch((err) => {
          console.error("err: ", err);
        });

      // if (res.ok) {
      //   refetch();
      //   return toast.success("커버 이미지가 업로드되었습니다.");
      // }
    } catch (e) {
      console.log(e);
      // toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const Currency = () => {
    const urlConfig = (currency) => {
      const ledgersUrl =
        "?" +
        (transactionType ? "transactionType=" + transactionType + "&" : "") +
        (role ? "role=" + role + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (wallet ? "wallet=" + wallet + "&" : "");
      return ledgersUrl;
    };

    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!currency ? "active" : ""}
      `}
          to={`/ledgers/${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>

        {Object.keys(currencyArray)
          .filter((item) => {
            return currencyArray[item].display === true;
          })
          .map((item) => {
            return (
              <Link
                key={item}
                className={`btn btn-white m-b-5 ${
                  currency === item ? "active" : ""
                }
            `}
                to={`/ledgers/${item}${urlConfig(item)}`}
              >
                {currencyArray[item].name}
              </Link>
            );
          })}
      </div>
    );
  };

  const TransactionType = () => {
    const urlConfig = (transactionType) => {
      const ledgersUrl =
        "?" +
        (transactionType ? "transactionType=" + transactionType + "&" : "") +
        (role ? "role=" + role + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (wallet ? "wallet=" + wallet + "&" : "");
      return ledgersUrl;
    };

    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!transactionType ? "active" : ""}
      `}
          to={`/ledgers/${
            match.params.Currency ? match.params.Currency : ""
          }${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>

        {Object.keys(transactionTypeArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                transactionType === item ? "active" : ""
              }
            `}
              to={`/ledgers/${
                match.params.Currency ? match.params.Currency : ""
              }${urlConfig(item)}`}
            >
              {transactionTypeArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };

  // const Role = () => {
  //   const urlConfig = role => {
  //     const ledgersUrl =
  //       "?" +
  //       (currency ? "currency=" + currency + "&" : "") +
  //       (transactionType ? "transactionType=" + transactionType + "&" : "") +
  //       (role ? "role=" + role + "&" : "") +
  //       (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
  //       (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
  //       (wallet ? "wallet=" + wallet + "&" : "");
  //     return ledgersUrl;
  //   };
  //   return (
  //     <div className="btn-group m-b-15 m-r-5">
  //       <Link
  //         className={`btn btn-white m-b-5 ${!role ? "active" : ""}
  //     `}
  //         to={`/ledgers${urlConfig()}`}
  //       >
  //         <i className={"fas fa-th"} /> 전체
  //       </Link>

  //       {Object.keys(roleArray).map(item => {
  //         return (
  //           <Link
  //             key={item}
  //             className={`btn btn-white m-b-5 ${role === item ? "active" : ""}
  //           `}
  //             to={`/ledgers${urlConfig(item)}`}
  //           >
  //             {roleArray[item]}
  //           </Link>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>거래내역 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/ledgers/DRM">거래내역</Link>
          </li>
          <li className="breadcrumb-item active">
            {!currency ? "전체" : currencyArray[currency].name}
          </li>
        </ol>
        <h1 className="page-header">거래내역</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminLedgerList.count}건)
                <div className="pull-right"></div>
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xl-12">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <Currency />
                    <TransactionType />
                    {/* <Role /> */}
                    <div className="btn-group m-b-15">
                      <button
                        onClick={doExcelDown}
                        className="btn btn-white m-b-5"
                      >
                        다운
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="btn-group m-b-15 m-r-5">
                  <Link
                    className={`btn btn-white m-b-5 ${
                      !transactionType ? "active" : ""
                    }
      `}
                    to={`/ledgers/DRM?transactionType=StoreOrder&startSearchDate=${startSearchDate}&endSearchDate=${endSearchDate}&wallet=ckr7v81hr0tm10898l5jgr03d`}
                  >
                    드림상점
                  </Link>
                </div> */}
                <Wallet
                  history={history}
                  match={match}
                  location={location}
                  currency={currency}
                  transactionType={transactionType}
                  role={role}
                  startSearchDate={startSearchDate}
                  endSearchDate={endSearchDate}
                  wallet={wallet}
                />
              </Alert>
              <PanelBody>
                {!loading && data.AdminLedgerList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>화폐</th>
                            <th>형태</th>
                            <th>권한</th>
                            <th>이름(아이디)</th>
                            <th>내용</th>
                            <th>일자</th>
                            <th>금액</th>
                            <th>합계</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminLedgerList.ledgers.map((ledger) => {
                            return (
                              <tr key={ledger.id}>
                                <td>{currencyName(ledger.currency)}</td>
                                <td>
                                  {transactionTypeName(ledger.transactionType)}
                                </td>
                                <td>
                                  {ledger.user && ledger.user.role
                                    ? roleName(ledger.user.role)
                                    : "-"}
                                </td>
                                <td>
                                  {ledger.wallet.type !== "Donation" &&
                                  ledger.wallet.type !== "Gift" &&
                                  ledger.wallet.type !== "Ticket"
                                    ? `${ledger.wallet.name}(${ledger.wallet.userId})`
                                    : "-"}
                                </td>
                                <td>
                                  상세내용 : {ledger.title}
                                  {ledger.remittanceType === "Order" && (
                                    <>
                                      <br />
                                      거래내용 : {ledger.comment}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {ledger.createdAt}
                                  </ReactMoment>
                                </td>
                                <td align="right">
                                  <NumberFormat
                                    value={ledger.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(ledger.currency)}
                                </td>
                                <td align="right">
                                  <NumberFormat
                                    value={ledger.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(ledger.currency)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
              {!loading && data.AdminLedgerList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.AdminLedgerList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
