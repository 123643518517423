import React, { Fragment, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { commonStatusArray } from "../../../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_PRODUCT_OPTION_DETAIL_EDIT_MUTATION,
  ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
} from "../../../Queries";
import Swal from "sweetalert2";

export default ({
  history,
  match,
  location,
  isModal,

  data,
  baseUrl,
}) => {
  const modalRef = React.createRef();

  const {
    AdminDeliveryProductOptionDetailDetail: {
      id,
      commonStatus,
      isSoldOut,
      price,
      name,
    },
  } = data;

  const { control, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      commonStatus,
      isSoldOut,
      price: `${price}`,
      name,
    },
  });

  const [
    AdminDeliveryProductOptionDetailEditMutation,
    { loading: AdminDeliveryProductOptionDetailEditLoading },
  ] = useMutation(ADMIN_DELIVERY_PRODUCT_OPTION_DETAIL_EDIT_MUTATION, {
    refetchQueries: () => [
      {
        query: ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
        variables: { id: match.params.id },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      Swal.fire({
        icon: "info",
        title: "안내",
        html: "수정하시겠습니까?<br /> 해당 상품을 구독중인 구독박스의 구독이 취소됩니다.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        showCancelButton: true,
        focusConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isDismissed) {
          history.push(`${baseUrl}${location.search}`);
        }
        if (result.isConfirmed) {
          if (AdminDeliveryProductOptionDetailEditLoading) {
            return;
          }

          const {
            data: { AdminDeliveryProductOptionDetailEdit },
          } = await AdminDeliveryProductOptionDetailEditMutation({
            variables: {
              id,
              commonStatus: data.commonStatus,
              price: data.price,
              name: data.name,
            },
          });
          if (AdminDeliveryProductOptionDetailEdit) {
            Swal.fire({
              icon: "info",
              title: "안내",
              text: "처리 되었습니다.",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "확인",
              focusConfirm: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`${baseUrl}${location.search}`);
              }
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`${baseUrl}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`${baseUrl}${location.search}`);
            }}
          >
            상품옵션수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-group">
              <div className=" p-10 ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered tablebasic product_op_table">
                        <caption className="sr-only">
                          옵션값, 옵션가격, 옵션상태, 품절
                        </caption>
                        <thead>
                          <tr>
                            <th
                              abbr="옵션값"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션값
                            </th>
                            <th
                              abbr="옵션가격"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션가격
                            </th>
                            <th
                              abbr="옵션상태"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션상태
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Controller
                                control={control}
                                name="name"
                                rules={{ required: "옵션값을 입력해주세요." }}
                                render={({ onChange, onBlur, value }) => (
                                  <>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="ex) 베이지"
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                    />
                                    {errors.name && (
                                      <span className="text-red errorText">
                                        {errors.name.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name="price"
                                rules={{
                                  required: "옵션가격을 입력해주세요.",
                                }}
                                render={({ onChange, onBlur, value }) => (
                                  <>
                                    <input
                                      className="form-control"
                                      type="number"
                                      placeholder="ex) 10000"
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                    />
                                    {errors.price && (
                                      <span className="text-red errorText">
                                        {errors.price.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name="commonStatus"
                                rules={{
                                  required: "옵션상태를 선택해주세요.",
                                }}
                                render={({ onChange, value }) => (
                                  <>
                                    <select
                                      className="form-control"
                                      onChange={onChange}
                                      defaultValue={value}
                                    >
                                      <option value="">상태</option>
                                      {Object.keys(commonStatusArray).map(
                                        (key, index) => (
                                          <option key={index} value={key}>
                                            {commonStatusArray[key]}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    {errors.commonStatus && (
                                      <span className="text-red errorText">
                                        {errors.commonStatus.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};
