import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import SearchProduct from "./SearchProduct";
import moment from "moment";
import { dayOfWeekName } from "../../../../../Components/User/UserNames";
import DateRangePicker from "react-bootstrap-daterangepicker";

const Presenter = ({
  history,
  match,
  location,
  page,
  ticketProduct,
  ticketProductDate,
  ticketProductDateTime,
  status,
  startSearchDate,
  endSearchDate,
  keyword,
  keywordInput,
  blockSize,
  first,
  skip,
  data,
  loading,
  baseUrl,
  handleDateApplyEvent,
  handleSearch,
}) => {
  return (
    <>
      <Helmet>
        <title>예매내역 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림티켓</li>
          <li className="breadcrumb-item active">예매내역</li>
        </ol>
        <h1 className="page-header">예매내역</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.StoreTicketOrderProductList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row m-b-15">
                  <SearchProduct
                    ticketProduct={ticketProduct}
                    ticketProductDate={ticketProductDate}
                    ticketProductDateTime={ticketProductDateTime}
                  />
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <DateRangePicker
                          startDate={moment(startSearchDate)}
                          endDate={moment(endSearchDate)}
                          onApply={handleDateApplyEvent}
                        >
                          <button className="btn btn-white mr-2 text-truncate">
                            <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                            <span>
                              {startSearchDate} ~ {endSearchDate}
                            </span>
                            <b className="caret"></b>
                          </button>
                        </DateRangePicker>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(구매자, 수신자) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody className="">
                {!loading && data.StoreTicketOrderProductList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "10%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">이미지</th>
                            <th className="text-center">
                              티켓정보 ((정가 - 할인가) * 수량 = 가격)
                            </th>
                            <th className="text-center">수신정보</th>
                            <th className="text-center">사용정보</th>
                            <th className="text-center">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.StoreTicketOrderProductList.ticketOrderProducts.map(
                            (item, index) => {
                              const CoverImage =
                                item.ticketProduct.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.ticketProduct.coverImage}`;

                              const toDay = new Date();
                              const endDateObj = item.isReservation
                                ? new Date(
                                    item.ticketProductDateTime?.ticketDateTime
                                  )
                                : new Date(item.ticketProduct.applyEndDateTime);

                              const gap =
                                endDateObj.getTime() - toDay.getTime();
                              const dDay = Math.ceil(
                                gap / (1000 * 60 * 60 * 24)
                              );

                              const startDate = new Date(
                                item.ticketProductDateTime?.ticketDateTime
                              );
                              const endDate = moment(
                                item.ticketProductDateTime?.ticketDateTime
                              ).add(
                                parseInt(item.ticketProduct.minute),
                                "minutes"
                              );

                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    <div
                                      className="product-img"
                                      style={{
                                        backgroundImage: `url(${CoverImage})`,
                                      }}
                                    >
                                      <img
                                        src={CoverImage}
                                        alt={item.ticketProduct.name}
                                      />
                                      {item.commonStatus === "S" && (
                                        <span className="day badge bg-yellow text-black">
                                          {dDay}일
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      가 맹 점 : {item.ticketProduct.store.name}
                                    </div>
                                    <div>
                                      티 켓 명 :{" "}
                                      <strong>
                                        [
                                        {item.ticketProduct.ticketCategory
                                          .parentCategory
                                          ? `${item.ticketProduct.ticketCategory.parentCategory.name} > ${item.ticketProduct.ticketCategory.name}`
                                          : `${item.ticketProduct.ticketCategory.name}`}
                                        ]{item.ticketProduct.name}
                                      </strong>
                                    </div>
                                    <div>
                                      정 원 : {item.ticketProduct.limit}명
                                    </div>
                                    <div>
                                      장 소 :{" "}
                                      {item.ticketProduct.placeType ===
                                        "OffLine" &&
                                        `(${item.ticketProduct.placeZipcode}) ${item.ticketProduct.placeAddress} ${item.ticketProduct.placeAddressDetail}`}
                                      {item.ticketProduct.placeType ===
                                        "OnLine" && "온라인"}
                                    </div>
                                    <div>
                                      유효기간 :{" "}
                                      <ReactMoment format="YYYY-MM-DD">
                                        {item.ticketProduct.applyStartDateTime}
                                      </ReactMoment>{" "}
                                      ~{" "}
                                      <ReactMoment format="YYYY-MM-DD">
                                        {item.ticketProduct.applyEndDateTime}
                                      </ReactMoment>
                                    </div>
                                    <div>
                                      가격 :{" "}
                                      {item.ticketProduct.isPay ? (
                                        <>
                                          (
                                          <NumberFormat
                                            displayType={"text"}
                                            value={item.price}
                                            thousandSeparator={true}
                                            suffix={"드림"}
                                          />{" "}
                                          -{" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            value={
                                              item.isDiscount
                                                ? item.discountPrice
                                                : 0
                                            }
                                            thousandSeparator={true}
                                            suffix={"드림"}
                                          />
                                          ) * {item.count}개 ={" "}
                                          <b>
                                            <NumberFormat
                                              displayType={"text"}
                                              value={
                                                (item.price -
                                                  (item.isDiscount
                                                    ? item.discountPrice
                                                    : 0)) *
                                                item.count
                                              }
                                              thousandSeparator={true}
                                              suffix={"드림"}
                                            />
                                          </b>
                                        </>
                                      ) : (
                                        "무료"
                                      )}
                                    </div>
                                  </td>
                                  <td className="text-left">
                                    <div>
                                      구 매 자 : {item.ticketOrder.user.name}(
                                      {item.ticketOrder.user.userId})
                                    </div>
                                    <div>
                                      수 신 자 :{" "}
                                      {item.isMember ? (
                                        <>
                                          {item.user.name}({item.user.userId})
                                        </>
                                      ) : (
                                        <>
                                          {item.name}({item.phone})
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      수신일자 :{" "}
                                      <ReactMoment format="YYYY-MM-DD HH:mm">
                                        {item.createdAt}
                                      </ReactMoment>
                                    </div>
                                    <div>
                                      메 시 지 :{" "}
                                      {item.ticketOrder.message
                                        ? item.ticketOrder.message
                                        : "없음"}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      상 태 :{" "}
                                      {item.commonStatus === "S" && (
                                        <>
                                          {toDay <= endDateObj ? (
                                            <>
                                              {item.isReservation ? (
                                                <span className="gray">
                                                  미사용
                                                </span>
                                              ) : (
                                                <span className="gray">
                                                  예매대기
                                                </span>
                                              )}
                                            </>
                                          ) : (
                                            <span className="gray">
                                              기간만료
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {item.commonStatus === "C" && (
                                        <>
                                          {item.isRefund === true && (
                                            <span className="danger">
                                              환불완료
                                            </span>
                                          )}
                                          {item.isRefund === false && (
                                            <span className="primary">
                                              사용완료
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      티켓번호 :{" "}
                                      <ReactMoment format="mmssSSS">
                                        {item.createdAt}
                                      </ReactMoment>
                                    </div>
                                    <div>수량 : {item.count}개</div>
                                    <div>
                                      {item.isReservation && (
                                        <>
                                          예매일시 :{" "}
                                          <ReactMoment format="YYYY.MM.DD">
                                            {startDate}
                                          </ReactMoment>
                                          ({dayOfWeekName(startDate.getDay())}){" "}
                                          <ReactMoment format="HH:mm">
                                            {startDate}
                                          </ReactMoment>{" "}
                                          ~{" "}
                                          <ReactMoment format="HH:mm">
                                            {endDate}
                                          </ReactMoment>
                                        </>
                                      )}
                                    </div>
                                    {item.commonStatus === "C" && (
                                      <>
                                        {item.isRefund === true && "환불일시 "}
                                        {item.isRefund === false && "사용일시 "}
                                        :{" "}
                                        <ReactMoment format="YYYY-MM-DD">
                                          {item.usedAt}
                                        </ReactMoment>
                                        (
                                        {dayOfWeekName(
                                          new Date(item.usedAt).getDay()
                                        )}
                                        ){" "}
                                        <ReactMoment format="HH:mm">
                                          {item.usedAt}
                                        </ReactMoment>
                                      </>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <Link
                                      to={`/ticket/ticketOrderProducts/${item.id}${location.search}`}
                                      className="btn btn-white btn-xs text-primary"
                                    >
                                      상세정보
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.StoreTicketOrderProductList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.StoreTicketOrderProductList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Presenter;
