import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import DeliveryStoreSelect from "../../../../Containers/DeliveryStore/Select";
import DeliveryPlaceSelect from "../../../../Containers/DeliveryPlace/Select";
import Pagination from "../../../../Components/Pagination/QSP";
import {
  deliveryBoxTypeObject,
  deliveryBoxConditionName,
  deliveryBoxStateName,
  dayOfWeekShortName,
  dayOfWeekName,
  deliveryBoxTypeName,
  futurePeriod,
} from "../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactMoment from "react-moment";
import DatePicker from "../../../../Containers/SelectedPeriod/DatePicker";

const Presenter = ({
  history,
  match,
  location,
  keyword,
  type,
  deliveryStore_id,
  deliveryPlace_id,
  startDate,
  endDate,
  page,
  take,
  loading,
  data,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
}) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      keyword,
    },
  });

  const onTypeChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (e.target.value ? "type=" + e.target.value + "&" : "") +
      (e.target.value === "Pickup" && deliveryPlace_id
        ? "deliveryPlace_id=" + deliveryPlace_id + "&"
        : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (keyword ? "keyword=" + keyword + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onDeliveryStoreChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (type ? "type=" + type + "&" : "") +
      (e.target.value ? "deliveryStore_id=" + e.target.value + "&" : "") +
      (deliveryPlace_id ? "deliveryPlace_id=" + deliveryPlace_id + "&" : "") +
      (keyword ? "keyword=" + keyword + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onDeliveryPlaceChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (type ? "type=" + type + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (e.target.value ? "deliveryPlace_id=" + e.target.value + "&" : "") +
      (keyword ? "keyword=" + keyword + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onSubmit = async (data) => {
    const baseUrl = "?" + (data.keyword ? "keyword=" + data.keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const defaultUrl =
    baseUrl +
    "?" +
    (type ? "type=" + type + "&" : "") +
    (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
    (deliveryPlace_id ? "deliveryPlace_id=" + deliveryPlace_id + "&" : "") +
    (keyword ? `keyword=${keyword}&` : ``) +
    (startDate ? "startDate=" + startDate + "&" : "") +
    (endDate ? "endDate=" + endDate + "&" : "");

  return (
    <div>
      <Helmet>
        <title>구독박스 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/stores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">구독박스</li>
      </ol>
      <h1 className="page-header">구독박스</h1>

      <Alert color="primary" className="m-b-15">
        <div>
          <strong className="f-s-15">※ 구독 상태 안내</strong>
        </div>
        <div>
          * 구독 : 3만드림 이상 상품을 구독한 경우로 배송이 가능한 상태입니다.
        </div>
        <div>
          * 조건미달 : 보유드림이 부족하거나, 3만드림 이하로 상품을 구독한
          경우로 배송이 불가능한 상태입니다.
        </div>
        <div>
          <strong className="f-s-15">※ 결제 상태 안내</strong>
        </div>
        <div>* 결제 : 지정한 일정으로 상품을 결제하는 상태입니다.</div>
        <div>
          * 한주미룸 : 지정한 일정을 기준으로 구독을 한주 넘기는 상태입니다.
        </div>
      </Alert>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({!loading && data.AdminDeliveryBoxList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <div className="btn-group">
                    <button
                      className={`btn btn-white ${
                        type === "" ? "active" : ""
                      } `}
                      value=""
                      onClick={onTypeChange}
                    >
                      전체
                    </button>
                    {Object.entries(deliveryBoxTypeObject).map(
                      ([key, value], index) => {
                        return (
                          <button
                            key={index}
                            className={`btn btn-white ${
                              type === key ? "active" : ""
                            } `}
                            value={key}
                            onClick={onTypeChange}
                          >
                            {value}
                          </button>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className={`row m-b-15 ${type !== "" ? "show" : "hide"}`}>
                <div className="col-xs-12">
                  <InputGroup>
                    <DeliveryStoreSelect
                      onChange={onDeliveryStoreChange}
                      value={deliveryStore_id}
                    />
                    <InputGroupAddon addonType="append">
                      <Link className={"btn btn-white"} to={`/deliveryStores`}>
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div
                className={`row m-b-15 ${type === "Pickup" ? "show" : "hide"}`}
              >
                <div className="col-xs-12">
                  <InputGroup>
                    <DeliveryPlaceSelect
                      onChange={onDeliveryPlaceChange}
                      value={deliveryPlace_id}
                    />
                    <InputGroupAddon addonType="append">
                      <Link className={"btn btn-white"} to={`/deliveryPlaces`}>
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="keyword"
                        rules={{ required: "검색어(구독자명) 입력해주세요." }}
                        render={({ onChange, onBlur, value }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어(구독자명) 입력"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <Button type="submit" size="sm" color="white">
                          <i className="fa fa-search fa-fw"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.keyword && (
                      <span className="text-red errorText">
                        {errors.keyword.message}
                      </span>
                    )}
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <DatePicker
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    onStartDateSelect={onStartDateSelect}
                    onEndDateSelect={onEndDateSelect}
                    onPeriodConfig={onPeriodConfig}
                    period={futurePeriod}
                  />
                </div>
              </div>
            </Alert>

            {!loading ? (
              <Fragment>
                {data.AdminDeliveryBoxList.count > 0 ? (
                  <>
                    <PanelBody>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                          <colgroup>
                            <col width="*" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="10%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-nowrap text-center">상태</th>
                              <th className="text-nowrap text-center">
                                구독자 정보
                              </th>
                              <th className="text-nowrap text-center">
                                결제정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송정보
                              </th>
                              <th className="text-nowrap text-center">기능</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.AdminDeliveryBoxList.deliveryBoxes.map(
                              (item, index) => {
                                const date = new Date(
                                  parseInt(item.deliveryDate)
                                );
                                const deliveryDayOfWeek = date.getDay();

                                const payDate = new Date(
                                  moment(date).add(-1, "d")
                                );
                                const dayDayOfWeek = payDate.getDay();

                                const drm = item.user.trustlines.find(
                                  (trustline) => trustline.currency === "DRM"
                                );
                                const amount = item.user.isCms
                                  ? drm.amount - item.user.cmsValue
                                  : drm.amount;

                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="text-nowrap">
                                        구독 :{" "}
                                        {deliveryBoxConditionName(
                                          item.condition
                                        )}
                                      </div>
                                      <div className="text-nowrap">
                                        결제 :{" "}
                                        {deliveryBoxStateName(item.state)}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-nowrap">
                                        이름 : {item.user.name}(
                                        {item.user.userId})
                                      </div>

                                      <div className="text-nowrap">
                                        연락처 : {item.user.phone}
                                      </div>
                                      {item.user.isCms && (
                                        <div className="text-nowrap">
                                          당겨쓰기 :{" "}
                                          <NumberFormat
                                            value={item.user.cmsValue}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                          />
                                          드림(매월 {item.user.cmsDay}일)
                                        </div>
                                      )}
                                      <div className="text-nowrap">
                                        결제일 :{" "}
                                        <ReactMoment format="YYYY-MM-DD">
                                          {payDate}
                                        </ReactMoment>
                                        ({dayOfWeekShortName(dayDayOfWeek)})
                                      </div>
                                      <div className="text-nowrap">
                                        배송일 :{" "}
                                        <ReactMoment format="YYYY-MM-DD">
                                          {date}
                                        </ReactMoment>
                                        ({dayOfWeekShortName(deliveryDayOfWeek)}
                                        )
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-nowrap text-right">
                                        보유드림 :{" "}
                                        <NumberFormat
                                          value={drm.amount}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                        드림
                                      </div>
                                      <div
                                        className={`text-nowrap text-right ${
                                          item.totalPrice < 30000 &&
                                          `text-danger`
                                        }`}
                                      >
                                        결제드림 :{" "}
                                        <NumberFormat
                                          value={item.totalPrice}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                        드림
                                      </div>
                                      <div
                                        className={`text-nowrap text-right ${
                                          item.totalPrice > drm.amount &&
                                          `text-danger`
                                        }`}
                                      >
                                        결제 후 잔액 :{" "}
                                        <b>
                                          <NumberFormat
                                            value={drm.amount - item.totalPrice}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                          />
                                        </b>
                                        드림
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-nowrap">
                                        타입 : {deliveryBoxTypeName(item.type)}
                                      </div>
                                      {item.type === "Pickup" && (
                                        <>
                                          <div className="text-nowrap">
                                            배송매장 :{" "}
                                            {
                                              item.deliveryPlace.deliveryStore
                                                .store.name
                                            }
                                          </div>
                                          <div className="text-nowrap">
                                            도로명 주소 :{" "}
                                            {item.deliveryPlace.roadAddress}
                                          </div>
                                          <div className="text-nowrap">
                                            지번주소 :{" "}
                                            {item.deliveryPlace.jibunAddress}
                                          </div>
                                          <div className="text-nowrap">
                                            상세주소 :{" "}
                                            {item.deliveryPlace.addressDetail}
                                          </div>
                                          <div className="text-nowrap">
                                            픽업요일 :{" "}
                                            {dayOfWeekName(
                                              item.deliveryPlace.dayOfWeek
                                            )}
                                          </div>
                                        </>
                                      )}
                                      {item.type === "Delivery" && (
                                        <>
                                          <div className="text-nowrap">
                                            배송매장 :{" "}
                                            {
                                              item.deliveryAddress.deliveryStore
                                                .store.name
                                            }{" "}
                                            &gt;{" "}
                                            {
                                              item.deliveryAddress.deliveryStore
                                                .user.name
                                            }
                                          </div>
                                          <div className="text-nowrap">
                                            도로명 주소 :{" "}
                                            {item.deliveryAddress.roadAddress}
                                          </div>
                                          <div className="text-nowrap">
                                            지번주소 :{" "}
                                            {item.deliveryAddress.jibunAddress}
                                          </div>
                                          <div className="text-nowrap">
                                            상세주소 :{" "}
                                            {item.deliveryAddress.addressDetail}
                                          </div>
                                          <div className="text-nowrap">
                                            배달요일 :{" "}
                                            {dayOfWeekName(
                                              item.deliveryAddress.dayOfWeek
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </td>
                                    <td className="text-nowrap text-center">
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/deliveryBoxes/${item.id}${location.search}`}
                                        >
                                          상세정보
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/users/${item.user.role}/${item.user.id}`}
                                        >
                                          회원정보
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/deliveryOrders?deliveryBox_id=${item.id}`}
                                        >
                                          배송내역
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                    <PanelFooter>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.AdminDeliveryBoxList.count}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={defaultUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </PanelFooter>
                  </>
                ) : (
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                )}
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Presenter;
