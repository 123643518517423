import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_LIST_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../TicketConfig/Queries";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import ReactMoment from "react-moment";

const DefaultValue = (ticketProduct) => {
  const { data, loading } = useQuery(TICKET_PRODUCT_DETAIL_QUERY, {
    variables: { id: ticketProduct },
  });
  if (ticketProduct) {
    if (!loading) {
      const {
        StoreTicketProductDetail: { id, name },
      } = data;
      return {
        value: id,
        label: name,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default withRouter(
  ({
    history,
    match,
    location,

    ticketProduct,
    ticketProductDate,
    ticketProductDateTime,
  }) => {
    const [keyword, setKeyword] = useState("");

    const [dateOptions, setDateOptions] = useState([]);
    const [dateTimeOptions, setDateTimeOptions] = useState([]);

    const { data, loading } = useQuery(TICKET_PRODUCT_LIST_QUERY, {
      variables: { keyword, skip: 0, first: 10 },
    });

    const loadOptions = (inputValue, callback) => {
      if (!loading) {
        var options = data.StoreTicketProductList.ticketProducts.map((item) => {
          return {
            value: item.id,
            label: `${item.name}`,
          };
        });

        callback(options);
      }
    };
    const handleInputChange = (value) => {
      setTimeout(() => {
        setKeyword(value);
        return value;
      }, 500);
    };

    useEffect(() => {
      if (!loading) {
        if (ticketProduct) {
          const {
            StoreTicketProductList: { ticketProducts },
          } = data;
          const product = ticketProducts.find(
            (item) => item.id === ticketProduct
          );

          const ProductDates = product?.ticketProductDates.map((item) => {
            const ticketDate = new Date(item.ticketDate);
            return {
              value: item.id,
              label: (
                <>
                  <ReactMoment format="YYYY-MM-DD">{ticketDate}</ReactMoment> (
                  회차 : {item.ticketProductDateTimes.length}회 - 참여 :{" "}
                  {item.ticketOrderProductPeoplesCount}명)
                </>
              ),
            };
          });
          setDateOptions(ProductDates);

          if (ticketProductDate) {
            const productDate = product?.ticketProductDates.find(
              (item) => item.id === ticketProductDate
            );

            console.log({ productDate });

            const ProductDateTimes = productDate?.ticketProductDateTimes?.map(
              (item) => {
                const ticketDateTime = new Date(item.ticketDateTime);
                return {
                  value: item.id,
                  label: (
                    <>
                      <ReactMoment format="HH:mm:ss">
                        {ticketDateTime}
                      </ReactMoment>{" "}
                      (참여 : {item.ticketOrderProductPeoplesCount}명)
                    </>
                  ),
                };
              }
            );
            setDateTimeOptions(ProductDateTimes);
          }
        }
      }
    }, [data, loading, ticketProductDate]);

    return (
      <>
        <div className="col-xs-4 select">
          <AsyncSelect
            isClearable
            placeholder="티켓명 입력"
            noOptionsMessage={() => {
              return "티켓명을 검색해주세요.";
            }}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            onChange={(options) => {
              const uri =
                "/ticket/ticketOrderProducts?" +
                (options ? "ticketProduct=" + options.value + "&" : "");
              history.push(`${uri}`);
            }}
            defaultValue={DefaultValue(ticketProduct)}
          />
        </div>
        <div className="col-xs-4 select">
          <Select
            placeholder="일자 선택"
            noOptionsMessage={() => {
              return "티켓명을 먼저 검색해주세요.";
            }}
            options={dateOptions}
            onChange={(options) => {
              const uri =
                "/ticket/ticketOrderProducts?" +
                ("ticketProduct=" + ticketProduct + "&") +
                (options ? "ticketProductDate=" + options.value + "&" : "");
              history.push(`${uri}`);
            }}
          />
        </div>
        <div className="col-xs-4 select">
          <Select
            placeholder="시간 선택"
            noOptionsMessage={() => {
              return "일자를 선택해주세요.";
            }}
            options={dateTimeOptions}
            onChange={(options) => {
              const uri =
                "/ticket/ticketOrderProducts?" +
                ("ticketProduct=" + ticketProduct + "&") +
                ("ticketProductDate=" + ticketProductDate + "&") +
                (options ? "ticketProductDateTime=" + options.value + "&" : "");
              history.push(`${uri}`);
            }}
          />
        </div>
      </>
    );
  }
);
