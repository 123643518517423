import React, { useState, useEffect, forwardRef } from "react"
import { useForm } from "react-hook-form"
import {
  COUPON_CATEGORY_LIST_QUERY,
  COUPON_DETAIL_QUERY,
  COUPON_EDITDEFAULT_QUERY,
} from "../../Queries"
import { useQuery, useMutation } from "react-apollo-hooks"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { toast } from "react-toastify"

// https://yohanpro.com/posts/react/react-datepicker
import DatePicker, { registerLocale } from "react-datepicker"
import "../../../../../Containers/SelectedPeriod/DatePicker.css"
import ko from "date-fns/locale/ko"
import getYear from "date-fns/getYear"
import getMonth from "date-fns/getMonth"

import WriteStore from "../WriteStore"

registerLocale("ko", ko)

const Presenter = ({
  history,
  match,
  location,
  data,

  isModal,
}) => {
  const modalRef = React.createRef()
  const { data: categories } = useQuery(COUPON_CATEGORY_LIST_QUERY, {
    variables: {
      couponConfig: match.params.couponConfig,
    },
  })

  const {
    AdminCouponDetail: {
      id,
      commonStatus,
      startDate: startDateAt,
      endDate: endDateAt,
      title,
      contents,
      limit,
      price,
      store: { id: storeId, name: storeName },
      isDuplication,
      couponCategory,
    },
  } = data

  const [couponEditMutation] = useMutation(COUPON_EDITDEFAULT_QUERY, {
    refetchQueries: () => [{ query: COUPON_DETAIL_QUERY, variables: { id } }],
  })

  const [startDate, setStartDate] = useState(new Date(startDateAt))
  const [endDate, setEndDate] = useState(new Date(endDateAt))
  const [store, setStore] = useState({ label: storeName, value: storeId })

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      commonStatus: commonStatus,
      startDate: startDateAt,
      endDate: endDateAt,
      title: title,
      contents: contents,
      limit: limit,
      price: price,
      ...(store && { store: store.value }),
      isDuplication: isDuplication,
      couponCategory: couponCategory.id,
    },
  })

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminCouponEditDefault },
      } = await couponEditMutation({
        variables: {
          id: id,
          commonStatus: data.commonStatus,
          startDate,
          endDate,
          title: data.title,
          contents: data.contents,
          limit: data.limit,
          price: data.price,
          store: store && store.value,
          isDuplication: data.isDuplication === "true" ? true : false,
          couponCategory: data.couponCategory,
        },
      })
      if (AdminCouponEditDefault) {
        toast.success("처리 되었습니다.")
        history.push(
          `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
        )
      }
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error: GraphQL error:", "")
      toast.error(`${error}`)
    }
  }
  console.log(errors)

  useEffect(() => {
    const element = modalRef.current

    if (isModal) {
      disableBodyScroll(element)
    }

    return () => {
      enableBodyScroll(element)
    }
  })

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-white" onClick={onClick}>
      {value}
      <i className="fa fa-calendar fa-fw text-black-transparent-5 m-l-5"></i>
    </button>
  ))
  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
          )
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
              )
            }}
          >
            수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-2">상태</label>
                <div className="col-md-4">
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="commonStatusC"
                      name="commonStatus"
                      className="form-control"
                      value="C"
                      ref={register({
                        required: "상태를 선택해주세요.",
                      })}
                    />
                    <label htmlFor={`commonStatusC`}>공개</label>
                  </div>
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="commonStatusS"
                      name="commonStatus"
                      className="form-control"
                      value="S"
                      ref={register({
                        required: "상태를 선택해주세요.",
                      })}
                    />
                    <label htmlFor={`commonStatusS`}>비공개</label>
                  </div>
                  {errors.commonStatus && (
                    <div>{errors.commonStatus.message}</div>
                  )}
                </div>
                <label className="col-form-label col-md-2">분류</label>
                <div className="col-md-4">
                  {categories ? (
                    categories.AdminCouponCategoryList.couponCategories.map(
                      (category, index) => {
                        return (
                          <div
                            key={index}
                            className="radio radio-css radio-inline"
                          >
                            <input
                              key={category.id}
                              type="radio"
                              id={category.id}
                              name="couponCategory"
                              className="form-control"
                              value={category.id}
                              ref={register({
                                required: "분류를 선택해주세요.",
                              })}
                            />
                            <label htmlFor={category.id}>{category.name}</label>
                          </div>
                        )
                      }
                    )
                  ) : (
                    <span>분류추가 후 이용해주세요.</span>
                  )}
                  {errors.couponCategory && (
                    <div>{errors.couponCategory.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">기간</label>
                <div className="col-md-10">
                  <div className="input-group">
                    <DatePicker
                      customInput={<CustomInput />}
                      name="startDate"
                      dateFormat="yyyy-MM-dd"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale="ko"
                      renderCustomHeader={({
                        date,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div className="custom-react-datepicker__select-wrapper">
                          <button
                            type="button"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <i className="fa fa-angle-left fa-lg"></i>
                          </button>
                          <div className="month-day text-white">
                            {getYear(date)}년 {getMonth(date) + 1}월
                          </div>
                          <button
                            type="button"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <i className="fa fa-angle-right fa-lg"></i>
                          </button>
                        </div>
                      )}
                    />
                    <span className="custom-react-datepicker__split-span">
                      ~
                    </span>
                    <DatePicker
                      name="endDate"
                      customInput={<CustomInput />}
                      dateFormat="yyyy-MM-dd"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      locale="ko"
                      renderCustomHeader={({
                        date,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div className="custom-react-datepicker__select-wrapper">
                          <button
                            type="button"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <i className="fa fa-angle-left fa-lg"></i>
                          </button>
                          <div className="month-day text-white">
                            {getYear(date)}년 {getMonth(date) + 1}월
                          </div>
                          <button
                            type="button"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <i className="fa fa-angle-right fa-lg"></i>
                          </button>
                        </div>
                      )}
                    />
                  </div>
                  {errors.startDate && <span>{errors.startDate.message}</span>}
                  {errors.endDate && <span>{errors.endDate.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">
                  가맹점 중복유무
                </label>
                <div className="col-md-10">
                  <div className="checkbox checkbox-css checkbox-inline">
                    <input
                      type="checkbox"
                      id="isDuplication"
                      name="isDuplication"
                      value={true}
                      ref={register()}
                    />
                    <label htmlFor="isDuplication">중복</label>
                    {errors.isDuplication && (
                      <span>{errors.isDuplication.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">인원</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="limit"
                    className="form-control"
                    placeholder="인원을 입력해주세요."
                    ref={register({
                      required: "인원을 입력해주세요.",
                    })}
                  />
                  {errors.limit && <span>{errors.limit.message}</span>}
                </div>
                <label className="col-form-label col-md-2">금액</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="price"
                    className="form-control"
                    placeholder="금액을 입력해주세요."
                    ref={register({
                      required: "금액을 입력해주세요.",
                    })}
                  />
                  {errors.price && <span>{errors.price.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">가맹점</label>
                <div className="col-md-10">
                  <WriteStore
                    history={history}
                    match={match}
                    location={location}
                    store={store}
                    setStore={setStore}
                  />
                  {errors.store && <span>{errors.store.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">제목</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="제목을 입력해주세요."
                    ref={register({
                      required: "제목을 입력해주세요.",
                    })}
                  />
                  {errors.title && <span>{errors.title.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">내용</label>
                <div className="col-md-10">
                  <textarea
                    name="contents"
                    className="form-control"
                    placeholder="내용을 입력해주세요."
                    ref={register({
                      required: "내용을 입력해주세요.",
                    })}
                    rows={10}
                  ></textarea>
                  {errors.contents && <span>{errors.contents.message}</span>}
                </div>
              </div>
            </div>{" "}
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default Presenter
