import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default ({
  history,
  match,
  location,
  isModal,
  store,
  data,
  loading,
  onDelete
}) => {
  const modalRef = React.createRef();

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/stores${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/stores${location.search}`);
          }}
        >
          구성원 목록 (
          {!loading && data.AdminStoreEmployeeList.employees.length}
          명)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox bg-light height-sm">
            <div className="vertical-box-column">
              <div className="vertical-box">
                <div className="vertical-box-row bg-white">
                  <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                      {!loading &&
                      data.AdminStoreEmployeeList &&
                      data.AdminStoreEmployeeList.employees.length > 0 ? (
                        <PerfectScrollbar
                          className="height-full"
                          options={{ suppressScrollX: true }}
                        >
                          <ListGroup className="list-group-lg no-radius list-email">
                            {data.AdminStoreEmployeeList.employees.map(
                              employee => {
                                return (
                                  <ListGroupItem
                                    key={employee.id}
                                    className="unread"
                                  >
                                    <div className="email-info p-l-0">
                                      <Link
                                        className="p-l-0"
                                        to={`/stores/${match.params.id}/employees/${employee.id}${location.search}`}
                                      >
                                        <span className="email-title p-l-0">
                                          {employee.name}({employee.userId})
                                        </span>
                                        <span class="email-time">
                                          <i class="fa fa-angle-right fa-fw"></i>
                                        </span>
                                      </Link>
                                    </div>
                                  </ListGroupItem>
                                );
                              }
                            )}
                          </ListGroup>
                        </PerfectScrollbar>
                      ) : (
                        <p className="text-center">등록된 데이터가 없습니다.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-xs-6 text-left"></div>
          <div className="col-xs-6 text-right"></div>
        </ModalFooter>
      </Modal>
    </>
  );
};
