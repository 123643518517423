import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { DASHBOARD_QUERY } from "../Queries";
import Moment from "react-moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";

export default withRouter(({ userData, history, match, location }) => {
  var date = new Date();
  // var currentYear = 2022;
  // var currentMonth = 1;
  var currentYear = date.getFullYear();
  var currentMonth = date.getMonth() + 1;
  currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;

  const { data, loading } = useQuery(DASHBOARD_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      year: currentYear.toString(),
      month: currentMonth.toString(),
    },
  });

  const RenderReviews = ({ ticketProductReviews }) => {
    return (
      <div className="col-md-6">
        <div className="m-b-10 m-t-10 f-s-10">
          <a
            href="/ticket/ticketProductReviews"
            className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
            data-toggle="modal"
          >
            더보기
          </a>
          <b className="text-inverse f-s-18">후기</b>
        </div>
        {ticketProductReviews.length > 0 ? (
          <div className="widget-list widget-list-rounded m-b-30">
            {ticketProductReviews.map((review, index) => (
              <a
                key={index}
                href={`/ticket/ticketProductReviews/${review.id}${location.search}`}
                className="widget-list-item"
              >
                <div className="widget-list-content">
                  <h4 className="widget-list-title">
                    <span className="badge badge-primary">
                      {review.commonStatus === "S" ? "답변대기" : "답변완료"}
                    </span>{" "}
                    {review.comment}
                  </h4>
                  <p className="widget-list-desc">
                    <Moment format="YYYY-MM-DD">{review.createdAt}</Moment>
                  </p>
                </div>
                <div className="widget-list-action text-right">
                  <i className="fa fa-angle-right fa-lg text-muted"></i>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div className="vertical-box p-15 text-center ">
            등록된 데이터가 없습니다.
          </div>
        )}
      </div>
    );
  };
  const RenderInquiries = ({ ticketProductInquiries }) => {
    return (
      <div className="col-md-6">
        <div className="m-b-10 m-t-10 f-s-10">
          <a
            href="/ticket/ticketProductInquiries"
            className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
            data-toggle="modal"
          >
            더보기
          </a>
          <b className="text-inverse f-s-18">문의</b>
        </div>
        {ticketProductInquiries.length > 0 ? (
          <div className="widget-list widget-list-rounded m-b-30">
            {ticketProductInquiries.map((inquiry, index) => (
              <a
                key={index}
                href={`/ticket/ticketProductInquiries/${inquiry.id}${location.search}`}
                className="widget-list-item"
              >
                <div className="widget-list-content">
                  <h4 className="widget-list-title">
                    <span className="badge badge-primary">
                      {inquiry.commonStatus === "S" ? "답변대기" : "답변완료"}
                    </span>{" "}
                    {inquiry.question}
                  </h4>
                  <p className="widget-list-desc">
                    <Moment format="YYYY-MM-DD">{inquiry.createdAt}</Moment>
                  </p>
                </div>
                <div className="widget-list-action text-right">
                  <i className="fa fa-angle-right fa-lg text-muted"></i>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div className="vertical-box p-15 text-center ">
            등록된 데이터가 없습니다.
          </div>
        )}
      </div>
    );
  };

  const headerToolbar = {
    left: "",
    // left: "dayGridMonth,timeGridWeek,timeGridDay",
    center: "title",
    right: "",
    // right: "prev,next today",
  };
  const buttonText = {
    today: "오늘",
    month: "Month",
    week: "Week",
    day: "Day",
  };
  const views = {
    timeGrid: {
      eventLimit: 6,
    },
  };

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  const {
    StoreTicketDashboard: {
      ticketOrders,
      ticketOrderProducts,
      ticketProductReviews,
      ticketProductInquiries,
    },
  } = data;

  let events = [];
  if (ticketOrders.length > 0) {
    for (let ticketOrder of ticketOrders) {
      events.push({
        title: `[구매] ${ticketOrder.ticketProduct.name}`,
        start: ticketOrder.createdAt,
        url: `/ticket/ticketOrders/${ticketOrder.id}`,
        color: "#348fe2",
      });
    }
    for (let ticketOrderProduct of ticketOrderProducts) {
      if (ticketOrderProduct.isReservation === false) {
        continue;
      }
      events.push({
        title: `[예매] ${ticketOrderProduct.ticketProduct.name}`,
        start: ticketOrderProduct.ticketProductDateTime.ticketDateTime,
        url: `/ticket/ticketOrderProducts/${ticketOrderProduct.id}`,
        color: "#fb5597",
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>메인 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">매인</li>
        </ol>
        <h1 className="page-header">Dashboard</h1>

        <div className="row">
          <div className="col-xs-12">
            <div className="note note-light m-t-30 m-b-15">
              <div style={{ marginTop: -45, marginLeft: 30 }}>
                <img
                  style={{ height: 130 }}
                  src="../assets/img/admin/dashboard-img.png"
                  alt=""
                />
              </div>
              <div className="note-content  m-l-15">
                <h4 className="f-s-18 m-t-15">
                  <b>
                    {userData && userData.AdminInfo && userData.AdminInfo.name}
                  </b>
                  님
                </h4>
                <p className="f-s-14">오늘하루도 좋은 하루 되시기바랍니다.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
              locale="ko"
              initialView="dayGridMonth"
              themeSystem="bootstrap"
              headerToolbar={headerToolbar}
              buttonText={buttonText}
              eventClick={(arg) => {
                console.log({ arg });
              }}
              events={events}
              views={views}
            />
          </div>
          <div className="d-none d-lg-block" style={{ width: "100px" }}>
            <div className="fc-event-list">
              <div className="">
                <div className="fc-event-text">
                  <i className="fas fa-circle fa-fw fs-9px text-blue"></i>
                  구매
                </div>
              </div>
              <div>
                <div className="fc-event-text">
                  <i className="fas fa-circle fa-fw fs-9px text-pink"></i>예매
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <RenderReviews ticketProductReviews={ticketProductReviews} />
          <RenderInquiries ticketProductInquiries={ticketProductInquiries} />
        </div>
      </div>
    </>
  );
});
