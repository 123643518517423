import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default ({
  history,
  match,
  location,
  isModal,
  data,
  loading,
  onDelete,
  prevMove,
  nextMove,
}) => {
  const modalRef = React.createRef();

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/stores${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/stores${location.search}`);
          }}
        >
          분류 목록 ({!loading && data.AdminStoreCategoryList.count}건)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox bg-light ">
            <div className="vertical-box-inner-cell overflow-visible height-md">
              {!loading && data.AdminStoreCategoryList.count > 0 ? (
                <PerfectScrollbar
                  className="height-full"
                  options={{ suppressScrollX: true }}
                >
                  <ListGroup className="list-group-lg no-radius list-email">
                    {data.AdminStoreCategoryList.storeCategories.map((item) => {
                      return (
                        <ListGroupItem key={item.id} className="unread">
                          <Link
                            // to={`/stores/categories/${item.id}/edit${location.search}`}
                            className="email-user bg-gradient-blue"
                          >
                            <span className="text-white">
                              <i className={item.pcIcon} />
                            </span>
                          </Link>
                          <div className="email-info">
                            <span className="email-title">
                              <Link
                                to={`/stores/categories/${item.id}/edit${location.search}`}
                              >
                                {item.isGift ? "[드림상점]" : ""}
                                {item.name}
                              </Link>
                              {/*  <Link
                                to={`/stores/categories/${item.id}/edit${location.search}`}
                              > */}
                              ({item.mobileIcon}){/* </Link> */}
                            </span>
                            <span className="email-desc">
                              {/* <Link
                                to={`/stores/categories/${item.id}/edit${location.search}`}
                              > */}
                              {item.storesCount} 점포
                              {/* </Link> */}
                            </span>
                            <span className="email-time">
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="btn btn-white"
                                  onClick={() => prevMove(item.id)}
                                >
                                  <i className="fa fa-arrow-up"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-white"
                                  onClick={() => nextMove(item.id)}
                                >
                                  <i className="fa fa-arrow-down"></i>
                                </button>
                                {/* <Button
                                  type="button"
                                  size="xs"
                                  color="white"
                                  className="text-red"
                                  onClick={() => {
                                    onDelete(item);
                                  }}
                                >
                                  삭제
                                </Button> */}
                              </div>
                            </span>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </PerfectScrollbar>
              ) : (
                <p className="text-center">등록된 데이터가 없습니다.</p>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-xs-12 text-center">
            <Link
              to={`/stores/categories/write${location.search}`}
              className="btn btn-white"
            >
              <i className="fa fa-plus fa-fw"></i>
            </Link>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
