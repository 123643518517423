import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import QRCode from "qrcode.react";

export default ({
  isModal,
  history,
  match,
  location,
  loading,
  data,
  baseUrl,
}) => {
  const modalRef = React.createRef();

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  const {
    StoreDeliveryOrderDetail: { id },
  } = data;

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/delivery/deliveryOrders/${id}${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/delivery/deliveryOrders/${id}${location.search}`);
          }}
        >
          배송 QRCode
        </ModalHeader>
        <ModalBody className=" p-10 bg-grey-transparent-5">
          <div className="col-12 text-center">
            <div
              style={{ display: "inline-block" }}
              className="rounded m-b-10 bg-white text-white p-15 valign-middle"
            >
              <QRCode
                size={512}
                level={"H"}
                style={{ width: 512, height: 512 }}
                value={id}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};
