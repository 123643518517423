import { gql } from "apollo-boost";

/* 배너 */
export const TICKET_BANNER_LIST_QUERY = gql`
  query AdminTicketBannerList($keyword: String, $skip: Int!, $first: Int!) {
    AdminTicketBannerList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketBanners {
        id
        commonStatus
        coverImage
        title
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const TICKET_BANNER_WRITE_QUERY = gql`
  mutation AdminTicketBannerWrite(
    $commonStatus: String!
    $coverImage: CoverUpload!
    $title: String!
    $timestamp: String
  ) {
    AdminTicketBannerWrite(
      commonStatus: $commonStatus
      coverImage: $coverImage
      title: $title
      timestamp: $timestamp
    )
  }
`;

export const TICKET_BANNER_DETAIL_QUERY = gql`
  query AdminTicketBannerDetail($id: String!) {
    AdminTicketBannerDetail(id: $id) {
      id
      commonStatus
      coverImage
      title
      createdAt
      updatedAt
    }
  }
`;

export const TICKET_BANNER_EDIT_QUERY = gql`
  mutation AdminTicketBannerEdit(
    $id: String!
    $commonStatus: String!
    $coverImage: CoverUpload
    $title: String!
  ) {
    AdminTicketBannerEdit(
      id: $id
      commonStatus: $commonStatus
      coverImage: $coverImage
      title: $title
    )
  }
`;

export const TICKET_BANNER_DELETE_QUERY = gql`
  mutation AdminTicketBannerDelete($id: String!) {
    AdminTicketBannerDelete(id: $id)
  }
`;
