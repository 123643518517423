import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { LEDGER_LIST_QUERY } from "../Queries";
import Presenter from "./Presenter/List";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  currencyName,
  transactionTypeName,
  roleName,
  datePlus9,
  datesCounter,
} from "../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substring(1));

  const currency = match.params.Currency;
  const transactionType = queryString.transactionType
    ? queryString.transactionType
    : "";
  const role = queryString.role ? queryString.role : "";
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  const wallet = queryString.wallet ? queryString.wallet : "";
  const page = queryString.page ? queryString.page : 1;

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (currency ? "currency=" + currency + "&" : "") +
    (transactionType ? "transactionType=" + transactionType + "&" : "") +
    (role ? "role=" + role + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (wallet ? "wallet=" + wallet + "&" : "");

  const { data, loading } = useQuery(LEDGER_LIST_QUERY, {
    variables: {
      currency,
      transactionType,
      role,
      startSearchDate,
      endSearchDate,
      wallet,
      orderBy: "id_DESC",
      skip,
      first,
    },
  });
  const [ledgerListMutation] = useMutation(LEDGER_LIST_QUERY);
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (transactionType ? "transactionType=" + transactionType + "&" : "") +
      (role ? "role=" + role + "&" : "") +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
      (wallet ? "wallet=" + wallet + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (transactionType ? "transactionType=" + transactionType + "&" : "") +
      (role ? "role=" + role + "&" : "") +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "") +
      (wallet ? "wallet=" + wallet + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (transactionType ? "transactionType=" + transactionType + "&" : "") +
      (role ? "role=" + role + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (wallet ? "wallet=" + wallet + "&" : "");

    history.push(`${uri}page=1`);
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      currency={currency}
      transactionType={transactionType}
      role={role}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      wallet={wallet}
      page={page}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
    ></Presenter>
  );
});
