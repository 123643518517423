import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { EVENT_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(EVENT_DETAIL_QUERY, {
    variables: { id: match.params.id }
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminEventDetail) {
    const {
      AdminEventDetail: {
        id,
        title,
        contents,
        createdAt,
        updatedAt,
        coverImg,
        detailImg,
        commonStatus,
        startDate,
        endDate
      }
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        title={title}
        contents={contents}
        commonStatus={commonStatus}
        startDateAt={startDate}
        endDateAt={endDate}
        createdAt={createdAt}
        updatedAt={updatedAt}
        coverImg={coverImg}
        detailImg={detailImg}
      />
    );
  }
});
