import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { Link } from "react-router-dom";
import { BANNER_EDIT_QUERY, BANNER_DETAIL_QUERY } from "../Queries";
import { useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { graphqlUri } from "../../../../Config/Env";

const Presenter = ({
  history,
  match,
  location,

  id,
  commonStatus,
  coverImage,
  group,
  title,
  stack,
  screen,
}) => {
  const [bannerEditMutation] = useMutation(BANNER_EDIT_QUERY, {
    refetchQueries: () => [{ query: BANNER_DETAIL_QUERY, variables: { id } }],
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id: id,
      commonStatus: commonStatus,
      group: group,
      title: title,
      stack: stack,
      screen: screen,
    },
  });

  const [bannerCoverImage, setBannerCoverImage] = useState(
    coverImage ? graphqlUri + coverImage : "/assets/img/user/user-default.jpg"
  );
  const handleBannerCoverImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setBannerCoverImage(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImage").click();
  };

  const onSubmit = async (data) => {
    try {
      let banner = {
        id: id,
        commonStatus: data.commonStatus,
        group: data.group,
        title: data.title,
        stack: data.stack,
        screen: data.screen,
      };
      if (data.coverImage[0]) {
        banner = { ...banner, coverImage: data.coverImage[0] };
      }
      console.log({ banner });

      const {
        data: { AdminBannerEdit },
      } = await bannerEditMutation({
        variables: banner,
      });
      if (AdminBannerEdit) {
        toast.success("처리 되었습니다.");
        history.push(`/banners/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <>
      <Helmet>
        <title>이벤트 관리 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">선물</li>
            <li className="breadcrumb-item active">이벤트</li>
          </ol>
          <h1 className="page-header">이벤트</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link to={`/banners${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-success">
                  <FontAwesomeIcon icon={faCheck} />
                  수정
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader>커버 이미지 </PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={bannerCoverImage}
                            alt="이미지 업로드"
                            className="width-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImage"
                          name="coverImage"
                          className="hide"
                          onChange={handleBannerCoverImage}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          상세 이미지
                        </button>
                      </div>
                      {errors.coverImage && (
                        <span className="text-danger">
                          {errors.coverImage.message}
                        </span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <p>
                          <b>배너 사이즈 :</b> 넓이 325px X 높이 120px
                        </p>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-9 col-sm-12">
              <Panel>
                <PanelHeader>수정</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-4">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div className="text-danger">
                            {errors.commonStatus.message}
                          </div>
                        )}
                      </div>
                      <label className="col-form-label col-md-2">그룹</label>
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="group"
                          className="form-control m-b-5"
                          placeholder="그룹입력"
                          ref={register({
                            required: "그룹을 입력해주세요.",
                          })}
                        />
                        {errors.group && <div>{errors.group.message}</div>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="title"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <div>{errors.title.message}</div>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">Stack</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="stack"
                          className="form-control m-b-5"
                          placeholder="Stack 입력"
                          ref={register({})}
                        />
                        {errors.stack && <span>{errors.stack.message}</span>}
                      </div>
                      <label className="col-form-label col-md-2">Screen</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="screen"
                          className="form-control m-b-5"
                          placeholder="Screen 입력"
                          ref={register({})}
                        />
                        {errors.screen && <span>{errors.screen.message}</span>}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link to={`/banners${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-success">
                  <FontAwesomeIcon icon={faCheck} />
                  수정
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Presenter;
