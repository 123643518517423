import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_ADDRESS_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;
  const address = match.params.address;
  const baseUrl = `/deliveryBoxes/${id}/addresses`;

  const { data, loading, error } = useQuery(
    ADMIN_DELIVERY_ADDRESS_DETAIL_QUERY,
    { variables: { id: address } }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
