import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import moment from "moment";

import { useMutation } from "react-apollo-hooks";
import {
  DELIVERY_BANNER_WRITE_MUTATION,
  DELIVERY_BANNER_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

const Write = withRouter(({ history, match, location }) => {
  const baseUrl = "/deliveryBanners";

  const date = new Date();
  var startDate = new Date();
  var endDate = date.setMonth(date.getMonth() + 1);

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      coverImage: null,
      commonStatus: "C",
      isPeriod: "true",
      startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      isLink: "true",
      link: "",
      title: "",
    },
  });

  const [
    AdminDeliveryBannerWriteMutation,
    { loading: AdminDeliveryBannerWriteLoading },
  ] = useMutation(DELIVERY_BANNER_WRITE_MUTATION, {
    refetchQueries: [
      {
        query: DELIVERY_BANNER_LIST_QUERY,
        variables: { take: 15, skip: 0, keyword: "" },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (AdminDeliveryBannerWriteLoading) {
        return;
      }

      const {
        data: { AdminDeliveryBannerWrite },
      } = await AdminDeliveryBannerWriteMutation({
        variables: {
          coverImage: data.coverImage,
          commonStatus: data.commonStatus,
          isPeriod: data.isPeriod,
          startDate: data.startDate,
          endDate: data.endDate,
          isLink: data.isLink,
          link: data.link,
          title: data.title,
        },
      });
      if (AdminDeliveryBannerWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const [cover, setCover] = useState("/assets/img/gallery/gallery-default.jpg");
  const handleCoverImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setCover(reader.result);
    };
  };
  const onClickCoverImage = () => {
    document.getElementById("coverImage").click();
  };

  return (
    <>
      <Helmet>
        <title>배너 | 한밭페이 </title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to="/deliveryStores">로컬푸드 구독</Link>
          </li>
          <li className="breadcrumb-item active">배너</li>
        </ol>
        <h1 className="page-header">배너</h1>

        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-3">
              <Panel>
                <PanelHeader noButton>이미지</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="bg-white rounded">
                      <Controller
                        name="coverImage"
                        control={control}
                        rules={{ required: "이미지를 선택해주세요." }}
                        render={({ onChange }) => (
                          <div className="form-group row">
                            <div className="col-md-12">
                              <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                                <div className="vertical-box-column valign-middle text-center">
                                  <img
                                    src={cover}
                                    alt="이미지 업로드"
                                    className="width-200"
                                  />
                                </div>
                              </div>
                              <div className="m-t-10">
                                <input
                                  type="file"
                                  id="coverImage"
                                  className="hide"
                                  onChange={(e) => {
                                    handleCoverImage(e);
                                    onChange(e.target.files[0]);
                                  }}
                                  accept="image/*"
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  onClick={onClickCoverImage}
                                >
                                  이미지 업로드
                                </button>
                                {errors.coverImage && (
                                  <span className="text-red errorText">
                                    {errors.coverImage.message}
                                  </span>
                                )}
                              </div>
                              <div className="alert alert-primary fade show m-t-10 m-b-0">
                                <b>커버이미지 사이즈 :</b> 넓이 600px X 높이
                                600px
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-9 col-sm-12">
              <Panel>
                <PanelHeader noButton>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <Controller
                      name="commonStatus"
                      control={control}
                      rules={{ required: "상태를 선택해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            상태
                          </label>
                          <div className="col-md-10">
                            <div>
                              <div className="radio radio-css radio-inline">
                                <input
                                  type="radio"
                                  id="commonStatus1"
                                  name="commonStatus"
                                  value={"C"}
                                  onClick={onChange}
                                  onBlur={onBlur}
                                  checked={value === "C"}
                                />
                                <label htmlFor="commonStatus1">공개</label>
                              </div>
                              <div className="radio radio-css radio-inline">
                                <input
                                  type="radio"
                                  id="commonStatus2"
                                  name="commonStatus"
                                  value={"S"}
                                  onClick={onChange}
                                  onBlur={onBlur}
                                  checked={value === "S"}
                                />
                                <label htmlFor="commonStatus2">미공개</label>
                              </div>
                            </div>
                            {errors.commonStatus && (
                              <span className="text-red errorText">
                                {errors.commonStatus.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />

                    <div className="form-group row ">
                      <label className="col-lg-2 form-label col-form-label">
                        게시기간
                      </label>
                      <div className="col-lg-4">
                        <Controller
                          name="isPeriod"
                          control={control}
                          rules={{
                            required: "게시기간 사용유무를 선택하세요.",
                          }}
                          render={({ onChange, onBlur, value }) => (
                            <>
                              <div>
                                <div className="radio radio-css radio-inline">
                                  <input
                                    type="radio"
                                    id="isPeriodTrue"
                                    name="isPeriod"
                                    title="게시기간 사용유무를 선택하세요."
                                    value={"true"}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    checked={value === "true"}
                                  />
                                  <label htmlFor={`isPeriodTrue`}>사용</label>
                                </div>
                                <div className="radio radio-css radio-inline">
                                  <input
                                    type="radio"
                                    id="isPeriodFalse"
                                    name="isPeriod"
                                    title="게시기간 사용유무를 선택하세요."
                                    value={"false"}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    checked={value === "false"}
                                  />
                                  <label htmlFor={`isPeriodFalse`}>
                                    미사용
                                  </label>
                                </div>
                              </div>
                              {errors.isPeriod && (
                                <span className="text-red errorText">
                                  {errors.isPeriod.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div
                        className={`col-lg-3 ${
                          watch("isPeriod") === "true" ? "show" : "hide"
                        }`}
                      >
                        <Controller
                          name="startDate"
                          control={control}
                          rules={{
                            validate: (value) => {
                              return watch("isPeriod") === "true" &&
                                value === ""
                                ? "게시 시작일시를 입력해주세요."
                                : undefined;
                            },
                          }}
                          render={({ onChange, onBlur, value }) => (
                            <input
                              type="text"
                              className="form-control"
                              title="게시 시작일시를 입력하세요."
                              placeholder="게시 시작일시를 입력하세요."
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                        {errors.startDate && (
                          <span className="text-red">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                      <div
                        className={`col-lg-3 ${
                          watch("isPeriod") === "true" ? "show" : "hide"
                        }`}
                      >
                        <Controller
                          name="endDate"
                          control={control}
                          rules={{
                            validate: (value) => {
                              return watch("isPeriod") === "true" &&
                                value === ""
                                ? "게시 종료일시를 입력해주세요."
                                : undefined;
                            },
                          }}
                          render={({ onChange, onBlur, value }) => (
                            <input
                              type="text"
                              className="form-control"
                              title="게시 종료일시를 입력하세요."
                              placeholder="게시 종료일시를 입력하세요."
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                        {errors.endDate && (
                          <span className="text-red">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="form-group row ">
                      <label
                        className="col-lg-2 form-label col-form-label"
                        htmlFor="title"
                      >
                        링크
                      </label>
                      <div className="col-lg-4">
                        <Controller
                          name="isLink"
                          control={control}
                          rules={{
                            required: "상태를 선택하세요.",
                          }}
                          render={({ onChange, value }) => (
                            <div>
                              <div className="radio radio-css radio-inline">
                                <input
                                  type="radio"
                                  id={`isLinkTrue`}
                                  name="isLink"
                                  title="상태를 선택하세요."
                                  value={"true"}
                                  onChange={onChange}
                                  checked={value === "true"}
                                />
                                <label htmlFor={`isLinkTrue`}>있음</label>
                              </div>
                              <div className="radio radio-css radio-inline">
                                <input
                                  type="radio"
                                  id={`isLinkFalse`}
                                  name="isLink"
                                  title="상태를 선택하세요."
                                  value={"false"}
                                  onChange={onChange}
                                  checked={value === "false"}
                                />
                                <label htmlFor={`isLinkFalse`}>없음</label>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <div
                        className={`col-lg-6 ${
                          watch("isLink") === "true" ? "show" : "hide"
                        }`}
                      >
                        <Controller
                          control={control}
                          rules={{
                            validate: (value) => {
                              return watch("isLink") === "true" && value === ""
                                ? "링크를 입력해주세요."
                                : undefined;
                            },
                          }}
                          name="link"
                          render={({ onChange, onBlur, value }) => (
                            <input
                              type="text"
                              className="form-control"
                              title="링크를 입력하세요."
                              placeholder="링크를 입력하세요."
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                        {errors.link && (
                          <span className="text-red">
                            {errors.link.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <Controller
                      control={control}
                      name="title"
                      rules={{ required: "제목을 입력해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            제목
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="제목을 입력해주세요."
                              value={value || ""}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                            {errors.title && (
                              <span className="text-red errorText">
                                {errors.title.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4 text-left"></div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-success">
                확인
              </button>
              <Link
                to={`${baseUrl}${location.search}`}
                className="btn btn-white text-danger m-l-5"
              >
                취소
              </Link>
            </div>
            <div className="col-xs-4 text-right"></div>
          </div>
        </form>
      </div>
    </>
  );
});

export default Write;
