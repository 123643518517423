import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";

import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_ORDER_LIST_QUERY } from "../../DeliveryOrder/Queries";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const deliveryStore_id = queryString.deliveryStore_id
    ? queryString.deliveryStore_id
    : "";
  const startDate = queryString.startDate
    ? queryString.startDate
    : "2023-10-01";
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().add(1, "d").format("YYYY-MM-DD");
  const deliveryDeliver_id = queryString.deliveryDeliver_id
    ? queryString.deliveryDeliver_id
    : "";
  const deliveryBox_id = queryString.deliveryBox_id
    ? queryString.deliveryBox_id
    : "";
  const baseUrl = "/deliveryOrders";

  const { data, loading, error } = useQuery(ADMIN_DELIVERY_ORDER_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take: 300,
      skip: 0,
      startDate,
      endDate,
      deliveryStore_id,
      deliveryDeliver_id,
      deliveryBox_id,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      deliveryStore_id={deliveryStore_id}
      startDate={startDate}
      endDate={endDate}
      deliveryDeliver_id={deliveryDeliver_id}
      deliveryBox_id={deliveryBox_id}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
