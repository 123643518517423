import React, { Fragment, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import {
  dayOfWeekShortName,
  deliveryOrderStateName,
  deliveryBoxTypeName,
  deliveryBoxProductStateName,
} from "../../../../../Components/User/UserNames";
import DeliveryDeliverStoreSelect from "../../../../../Containers/DeliveryDeliver/StoreSelect";
import DeliveryBoxAsyncSelect from "../../../../../Containers/DeliveryBox/AsyncSelect";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { ko } from "date-fns/esm/locale";
import NumberFormat from "react-number-format";
import Moment from "moment";
import ReactMoment from "react-moment";
import Pagination from "../../../../../Components/Pagination/QSP";

export default ({
  history,
  match,
  location,
  // keyword,
  startDate,
  endDate,
  deliveryDeliver_id,
  deliveryBox_id,
  page,
  take,
  loading,
  data,
  baseUrl,
}) => {
  const onApply = (e, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");

    const baseUrl =
      "?" +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onAllDate = () => {
    const startDate = "2023-10-01";
    const endDate = Moment().format("YYYY-MM-DD");

    const baseUrl =
      "?" +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onToDate = () => {
    const startDate = Moment().format("YYYY-MM-DD");
    const endDate = Moment().format("YYYY-MM-DD");

    const baseUrl =
      "?" +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onTomorrowDate = () => {
    const startDate = Moment().add(1, "d").format("YYYY-MM-DD");
    const endDate = Moment().add(1, "d").format("YYYY-MM-DD");

    const baseUrl =
      "?" +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onDeliveryStoreChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onDeliveryDeliverChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (e.target.value ? "deliveryDeliver_id=" + e.target.value + "&" : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onDeliveryBoxChange = (option) => {
    const baseUrl =
      "?" +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (option.value ? "deliveryBox_id=" + option.value + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };

  const defaultUrl =
    baseUrl +
    "?" +
    (deliveryDeliver_id
      ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
      : "") +
    (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
    (startDate ? "startDate=" + startDate + "&" : "") +
    (endDate ? "endDate=" + endDate + "&" : "");

  return (
    <div>
      <Helmet>
        <title>배송 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/delivery/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/delivery/deliveryOrders">배송</Link>
        </li>
        <li className="breadcrumb-item active">배송내역</li>
      </ol>
      <h1 className="page-header">배송</h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({!loading && data.StoreDeliveryOrderList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <DeliveryDeliverStoreSelect
                    onChange={onDeliveryDeliverChange}
                    value={deliveryDeliver_id}
                  />
                </div>
              </div>
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <DeliveryBoxAsyncSelect
                    onChange={onDeliveryBoxChange}
                    value={deliveryBox_id}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <InputGroup>
                    <DateRangePicker
                      startDate={Moment(startDate)}
                      endDate={Moment(endDate)}
                      onApply={onApply}
                    >
                      <button className="btn btn-white mr-2 text-truncate">
                        <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                        <span>
                          {startDate} ~ {endDate}
                        </span>
                        <b className="caret"></b>
                      </button>
                    </DateRangePicker>
                    <button
                      className="btn btn-white mr-2 text-truncate"
                      onClick={onAllDate}
                    >
                      전체
                    </button>
                    <button
                      className="btn btn-white mr-2 text-truncate"
                      onClick={onToDate}
                    >
                      오늘
                    </button>
                    <button
                      className="btn btn-white mr-2 text-truncate"
                      onClick={onTomorrowDate}
                    >
                      내일
                    </button>
                  </InputGroup>
                </div>
                <div className="col-xs-6 text-right">
                  <Link
                    className="btn btn-white mr-2 text-truncate"
                    to={`/delivery/deliveryOrdersProduct/print${location.search}`}
                  >
                    <i className="fa fa-print"></i> 배송상품
                  </Link>
                  <Link
                    className="btn btn-white"
                    to={`/delivery/deliveryOrdersOrder/print${location.search}`}
                  >
                    <i className="fa fa-print"></i> 운송장
                  </Link>
                </div>
              </div>
            </Alert>

            {!loading ? (
              <Fragment>
                {data.StoreDeliveryOrderList.count > 0 ? (
                  <>
                    <PanelBody>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped m-b-0">
                          <colgroup>
                            <col width="6%" />
                            <col width="15%" />
                            <col width="*" />
                            <col width="15%" />
                            <col width="10%" />
                            <col width="10%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-nowrap text-center">상태</th>
                              <th className="text-nowrap text-center">
                                구독박스정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송기사 정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송일
                              </th>
                              <th className="text-nowrap text-center">기능</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.StoreDeliveryOrderList.deliveryOrders.map(
                              (item, index) => {
                                const productString = item.deliveryOrderProducts
                                  .map((product) => {
                                    const option =
                                      (product.deliveryProductOptionDetail1 ||
                                        product.deliveryProductOptionDetail2 ||
                                        product.deliveryProductOptionDetail3) &&
                                      `${
                                        product.deliveryProductOptionDetail1 &&
                                        `${product.deliveryProductOptionDetail1.name}`
                                      }
                                          ${
                                            product.deliveryProductOptionDetail2 &&
                                            `/${product.deliveryProductOptionDetail2.name}`
                                          }
                                          ${
                                            product.deliveryProductOptionDetail3 &&
                                            `/${product.deliveryProductOptionDetail3.name}`
                                          }`;
                                    return `${product.deliveryProduct.name}${
                                      option ? `[${option}]` : ""
                                    }(${product.count}개)`;
                                  })
                                  .join(", ");

                                const date = new Date(item.deliverAt);
                                const deliveryDayOfWeek = date.getDay();

                                return (
                                  <tr key={index}>
                                    <td>
                                      {deliveryOrderStateName(item.state)}
                                    </td>
                                    <td>
                                      <div>
                                        이름 : {item.user.name}(
                                        {item.user.userId})
                                      </div>
                                      <div>연락처 : {item.user.phone}</div>
                                    </td>
                                    <td>
                                      <div>
                                        매장 : {item.deliveryStore.store.name}{" "}
                                        &gt; {item.deliveryStore.user.name}
                                      </div>
                                      <div>
                                        타입 : {deliveryBoxTypeName(item.type)}
                                      </div>
                                      <div>
                                        주소 : {item.roadAddress}{" "}
                                        {item.addressDetail}
                                      </div>
                                      <div>배송상품 : {productString}</div>
                                    </td>
                                    <td>
                                      {item.deliveryDeliver ? (
                                        <Fragment>
                                          <div>
                                            이름 :{" "}
                                            {item.deliveryDeliver.user.name}(
                                            {item.deliveryDeliver.user.userId})
                                          </div>
                                          <div>
                                            연락처 :
                                            {item.deliveryDeliver.user.phone}
                                          </div>
                                        </Fragment>
                                      ) : (
                                        <div className="text-center">
                                          미배정
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <div>
                                        <ReactMoment format="YYYY-MM-DD">
                                          {item.deliverAt}
                                        </ReactMoment>
                                        ({dayOfWeekShortName(deliveryDayOfWeek)}
                                        )
                                      </div>
                                    </td>
                                    <td className="text-nowrap text-center">
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`${baseUrl}/${item.id}${location.search}`}
                                        >
                                          상세정보
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/delivery/deliveryBoxes/${item.deliveryBox.id}`}
                                        >
                                          구독박스정보
                                        </Link>
                                      </div>
                                      {item.deliveryDeliver && (
                                        <div>
                                          <Link
                                            className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                            to={`/delivery/deliveryDelivers?deliveryDeliver_id=${item.deliveryDeliver.id}`}
                                          >
                                            배송기사정보
                                          </Link>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                    <PanelFooter>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.StoreDeliveryOrderList.count}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={defaultUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </PanelFooter>
                  </>
                ) : (
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                )}
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};
