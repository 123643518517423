import { forwardRef, Fragment } from "react"
import "../../assets/css/common/print.css"
import { dayOfWeekName } from "../User/UserNames"

const DayOfWeekPrint = forwardRef(({ data }, ref) => {
  console.log({ data })
  return (
    <div className="page2" ref={ref}>
      <div className="print ">
        {data.StoreDeliveryBoxDayOfWeek ? (
          data.StoreDeliveryBoxDayOfWeek.filter(
            (item) => 0 < item.dayOfWeek && item.dayOfWeek < 6
          ).map((item, index) => {
            return (
              <div className="print-page" key={index}>
                <div>
                  <div className="print-page-header">
                    <strong className="font14">
                      {dayOfWeekName(item.dayOfWeek)} 배송상품
                    </strong>
                  </div>
                  <div className="print-page-body">
                    <table class="table table-invoice week">
                      <tbody>
                        {item.deliveryBoxDayOfWeek.length > 0 ? (
                          <Fragment>
                            {item.deliveryBoxDayOfWeek.map((product, index) => {
                              return (
                                <tr key={index}>
                                  <td class="text-end p-l-0">
                                    {product.deliveryProduct_name}

                                    {(product.deliveryProductOptionDetail1_name ||
                                      product.deliveryProductOptionDetail2_name ||
                                      product.deliveryProductOptionDetail3_name) && (
                                      <span>
                                        (
                                        {product.deliveryProductOptionDetail1_name &&
                                          `${product.deliveryProductOptionDetail1_name}`}
                                        {product.deliveryProductOptionDetail2_name &&
                                          ` / ${product.deliveryProductOptionDetail2_name}`}
                                        {product.deliveryProductOptionDetail3_name &&
                                          ` / ${product.deliveryProductOptionDetail3_name}`}
                                        )
                                      </span>
                                    )}
                                  </td>
                                  <td class="text-center">{product.sum}개</td>
                                </tr>
                              )
                            })}
                          </Fragment>
                        ) : (
                          <tr>
                            <td class="text-center" colSpan={2}>
                              주문내역이 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <Fragment>
            <p className="text-center">주문 데이터가 없습니다.</p>
          </Fragment>
        )}
      </div>
    </div>
  )
})

export default DayOfWeekPrint
