import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo-hooks";
import {
  GIFT_PRODUCT_TICKETOPTION_WRITE_QUERY,
  GIFT_PRODUCT_DETAIL_QUERY,
} from "../../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(
  ({
    history,
    match,
    location,

    isModal,
  }) => {
    const modalRef = React.createRef();

    const [giftProductTicketOptionWriteMutation] = useMutation(
      GIFT_PRODUCT_TICKETOPTION_WRITE_QUERY,
      {
        refetchQueries: () => [
          {
            query: GIFT_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (data) => {
      try {
        const {
          data: { AdminGiftProductTicketOptionWrite },
        } = await giftProductTicketOptionWriteMutation({
          variables: {
            giftProduct: match.params.id,

            ticketDate: data.ticketDate,
            ticketTime: data.ticketTime,
          },
        });
        if (AdminGiftProductTicketOptionWrite) {
          toast.success("처리 되었습니다.");
          history.push(
            `/giftConfigs/${match.params.config}/products/${match.params.id}${location.search}`
          );
        }
      } catch (e) {
        console.log(e);
        var error = e.toString();
        error = error.replace("Error: GraphQL error:", "");
        toast.error(`${error}`);
      }
    };
    // console.log(errors);

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <Fragment>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
                );
              }}
            >
              티켓일정등록
            </ModalHeader>
            <ModalBody className=" p-0">
              <div className="form-group">
                <div className=" p-10 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered tablebasic product_op_table">
                          <caption className="sr-only">
                            티켓일정생성-항목,일자 시간
                          </caption>
                          <thead>
                            <tr>
                              <th
                                abbr="일자"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                일자
                              </th>
                              <th
                                abbr="시간"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                시간
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ticketDate"
                                  placeholder="ex) 2020-08-15"
                                  ref={register({
                                    required: "일자를 입력해주세요.",
                                  })}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ticketTime"
                                  placeholder="ex) 02:00:00"
                                  ref={register({
                                    required: "시간를 입력해주세요.",
                                  })}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {errors.name && (
                        <Fragment>
                          <span>{errors.name.message}</span>
                          <br />
                        </Fragment>
                      )}
                      {errors.commonStatus && (
                        <Fragment>
                          <span>{errors.commonStatus.message}</span>
                          <br />
                        </Fragment>
                      )}
                      {errors.detailName && (
                        <Fragment>
                          <span>{errors.detailName.message}</span>
                          <br />
                        </Fragment>
                      )}
                      {errors.detailPrice && (
                        <Fragment>
                          <span>{errors.detailPrice.message}</span>
                          <br />
                        </Fragment>
                      )}
                      {errors.detailCommonStatus && (
                        <Fragment>
                          <span>{errors.detailCommonStatus.message}</span>
                          <br />
                        </Fragment>
                      )}
                    </div>
                    <div className="col-sm-12">
                      <div className="text-left text-danger">
                        * 옵션명은 최대 5개까지 등록이 가능합니다. 항목을 추가할
                        경우, 옵션명을 입력하는 란이 생성됩니다.
                        <br />
                        (티켓 ex. 옵션명 - 일자(2021-08-01))
                        <br />
                        <br />
                        * 옵션명을 추가할 경우, 옵션값을 입력하는 란이
                        생성됩니다.
                        <br />
                        (티켓 ex. 옵션값 - 시간(14:00 ~ 16:00)
                        <br />
                        <br />* 품절 - 옵션의 품절 여부를 선택합니다.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="col-xs-12 text-center">
                <button type="submit" className="btn btn-white text-blue">
                  확인
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </Fragment>
    );
  }
);
