import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { FEE_DETAIL_QUERY, FEE_DELETE_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../Config/Env";
import { toast } from "react-toastify";
import nl2br from "react-nl2br";

export default withRouter(({ history, match, location }) => {
  const { data: detail, loading } = useQuery(FEE_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  const [feeDeleteMutation] = useMutation(FEE_DELETE_QUERY);
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminFeeDelete },
      } = await feeDeleteMutation({
        variables: { id },
      });
      if (AdminFeeDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/fees${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  const {
    AdminFeeDetail: {
      id,
      title,
      contents,
      planner,
      plannerUseSNS,
      plannerSNS,
      detailImg,
      commonStatus,
      createdAt,
      wallet,
    },
  } = detail;

  const feeDetailImage =
    detailImg === "" || detailImg === null
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUri}${detailImg}`;

  return (
    <>
      <Helmet>
        <title>기부 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">기부</li>
        </ol>
        <h1 className="page-header">기부</h1>

        <div className="row m-b-15">
          <div className="col-xs-6 text-left">
            <Link to={`/fees${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-6 text-right">
            <div className="btn-group">
              <Link
                to={`/fees/${match.params.id}/wallet/${wallet.id}${location.search}`}
                className="btn btn-white text-success"
              >
                내역
              </Link>
              <Link
                className="btn btn-white text-success"
                to={`/fees/${match.params.id}/edit${location.search}`}
              >
                수정
              </Link>
              {/* {totalDNC + totalDRM === 0 && (
                  <Button
                    type="button"
                    color="white"
                    className="text-red"
                    onClick={() => {
                      onDelete(id);
                    }}
                  >
                    삭제
                  </Button>
                )} */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader>상세내용</PanelHeader>
              <PanelBody className="panel-form">
                <div className="p-20">
                  <img
                    src={feeDetailImage}
                    alt="이미지 업로드"
                    className="width-200"
                  />
                </div>
                <div className="wrapper">
                  {commonStatus === "C" ? (
                    <span className="label label-primary">공개</span>
                  ) : (
                    <span className="label label-danger">비공개</span>
                  )}
                  <h3 className="m-t-10 f-w-500">{title}</h3>
                  <p>
                    <b>작성일 :</b>
                    <Moment format="YYYY-MM-DD HH:mm:ss">{createdAt}</Moment>
                  </p>
                </div>
                <div className="">
                  <div className="invoice bg-grey-transparent-5">
                    <div className="info_ul">
                      <ul>
                        <li>
                          <b>프로젝트 관리자 :</b>
                          {planner}
                        </li>
                        {plannerUseSNS === "C" ? (
                          <li>
                            <b>프로젝트 관리자 SNS :</b> {plannerSNS}
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="p-20">
                  <p className="f-s-12 text-inverse">{nl2br(contents)}</p>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 text-left">
            <Link to={`/fees${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-6 text-right">
            <div className="btn-group">
              <Link
                to={`/fees/${id}/wallet/${wallet.id}${location.search}`}
                className="btn btn-white text-success"
              >
                내역
              </Link>
              <Link
                className="btn btn-white text-success"
                to={`/fees/${id}/edit${location.search}`}
              >
                수정
              </Link>
              {/* {totalDNC + totalDRM === 0 && (
                  <Button
                    type="button"
                    color="white"
                    className="text-red"
                    onClick={() => {
                      onDelete(id);
                    }}
                  >
                    삭제
                  </Button>
                )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
