import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { CHARGEREQUESTCMS_LIST_QUERY } from "../Queries";
import Presenter from "./Presenter/List";
import qs from "qs";

const Container = withRouter(({ history, match, location, config }) => {
  const queryString = qs.parse(location.search.substr(1));

  var page = queryString.page ? queryString.page : 1;
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl = "?";

  const { data, loading } = useQuery(CHARGEREQUESTCMS_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      skip,
      first,
    },
  });

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      config={config}
      page={page}
      blockSize={blockSize}
      first={first}
      baseUrl={baseUrl}
      data={data}
      loading={loading}
    ></Presenter>
  );
});

export default Container;
