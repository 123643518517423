import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { TICKET_EXCHANGE_LIST_QUERY } from "../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

export default withRouter(({ history, match, location }) => {
  const date = new Date();
  const year = match.params.year
    ? parseInt(match.params.year)
    : date.getFullYear();

  const { data, loading } = useQuery(TICKET_EXCHANGE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { year: year },
  });
  const [ticketExchangelListMutation] = useMutation(TICKET_EXCHANGE_LIST_QUERY);
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const currentYear = date.getFullYear();
  const selectYears = Array.from(
    new Array(currentYear - 2020),
    (val, index) => index + 2021
  );

  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [["가맹점", "출남원", "판매가", "정산가(97%)", "수수료(3%)"]];
      try {
        const {
          data: { StoreTicketExchangeList },
        } = await ticketExchangelListMutation({
          variables: { year: year },
        });
        StoreTicketExchangeList.ticketExchanges.forEach((item) => {
          data.push([
            item.name,
            item.cashier,
            item.totalPrice,
            item.price,
            item.commission,
          ]);
        });
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    return (
      <>
        <button className="btn btn-white " onClick={handleDownload}>
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`드림티켓 정산내역.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };
  return (
    <>
      <Presenter
        history={history}
        match={match}
        location={location}
        year={year}
        selectYears={selectYears}
        data={data}
        loading={loading}
      ></Presenter>
    </>
  );
});
