import { gql } from "apollo-boost";

export const DELIVERY_BANNER_LIST_QUERY = gql`
  query AdminDeliveryBannerList($keyword: String, $skip: Int!, $take: Int) {
    AdminDeliveryBannerList(keyword: $keyword, skip: $skip, take: $take) {
      deliveryBanners {
        id
        createdAt
        updatedAt

        commonStatus
        isPeriod
        startDate
        endDate
        isLink
        link
        coverImage
        title
      }
      count
    }
  }
`;

export const DELIVERY_BANNER_WRITE_MUTATION = gql`
  mutation AdminDeliveryBannerWrite(
    $coverImage: FileUpload!
    $commonStatus: String!
    $isPeriod: String!
    $startDate: String
    $endDate: String
    $isLink: String!
    $link: String
    $title: String!
  ) {
    AdminDeliveryBannerWrite(
      coverImage: $coverImage
      commonStatus: $commonStatus
      isPeriod: $isPeriod
      startDate: $startDate
      endDate: $endDate
      isLink: $isLink
      link: $link
      title: $title
    )
  }
`;

export const DELIVERY_BANNER_DETAIL_QUERY = gql`
  query AdminDeliveryBannerDetail($id: String!) {
    AdminDeliveryBannerDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      isPeriod
      startDate
      endDate
      isLink
      link
      coverImage
      title
    }
  }
`;

export const DELIVERY_BANNER_EDIT_MUTATION = gql`
  mutation AdminDeliveryBannerEdit(
    $id: String!
    $coverImage: FileUpload
    $commonStatus: String!
    $isPeriod: String!
    $startDate: String
    $endDate: String
    $isLink: String!
    $link: String
    $title: String!
  ) {
    AdminDeliveryBannerEdit(
      id: $id
      coverImage: $coverImage
      commonStatus: $commonStatus
      isPeriod: $isPeriod
      startDate: $startDate
      endDate: $endDate
      isLink: $isLink
      link: $link
      title: $title
    )
  }
`;

export const DELIVERY_BANNER_DELETE_MUTATION = gql`
  mutation AdminDeliveryBannerDelete($id: String!) {
    AdminDeliveryBannerDelete(id: $id)
  }
`;
