import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { FEEHASUSER_DELETE_QUERY, FEEHASUSER_LIST_QUERY } from "../../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../..//../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import ReactMoment from "react-moment";
import { graphqlUri } from "../../../../../Config/Env";
import NumberFormat from "react-number-format";

export default ({ history, match, location, data, loading }) => {
  const [deleteMutation] = useMutation(FEEHASUSER_DELETE_QUERY, {
    refetchQueries: [
      {
        query: FEEHASUSER_LIST_QUERY,
        variables: { feeId: match.params.id, skip: 0, first: 300 },
      },
    ],
  });

  const onDelete = async (id) => {
    if (!window.confirm("선택한 회원을 회비에서 탈퇴시키겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminFeeHasUserDelete },
      } = await deleteMutation({
        variables: { id },
      });

      // if (AdminFeeHasUserDelete) {
      // }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Helmet>
        <title>회비 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/fees/${match.params.id}/users`}>회비</Link>
          </li>
          <li className="breadcrumb-item active">회원</li>
        </ol>
        <h1 className="page-header">회원</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <div className="row m-b-15">
              <div className="col-xs-6 text-left">
                <div className="btn-group">
                  <Link
                    to={`/fees${location.search}`}
                    className="btn btn-white"
                  >
                    목록
                  </Link>
                </div>
              </div>
              <div className="col-xs-6 text-right">
                <div className="btn-group">
                  <Link
                    to={`/fees/${match.params.id}/users/write${location.search}`}
                    className="btn btn-white"
                  >
                    회원 추가
                  </Link>
                </div>
              </div>
            </div>

            <Alert color="primary" className="m-b-15">
              <strong className="f-s-15">※ 주의사항(필독)</strong>
              <br /> * 탈퇴 : 탈퇴 시 회비 회원목록에서 삭제되며, 납부내역 모두
              삭제됩니다. 재 가입시 납부내역이 초기화됩니다.
            </Alert>

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminFeeHasUserList.count}건)
              </PanelHeader>
              <PanelBody>
                {!loading && data.AdminFeeHasUserList.count > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>이미지</th>
                          <th>상태 이름(아이디)</th>
                          <th>출금일</th>
                          <th>출금금액</th>
                          <th>체납금액</th>
                          <th>납부총액</th>
                          <th>가입일시</th>
                          <th>기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.AdminFeeHasUserList.feeHasUsers.map(
                          (feeHasUser, index) => {
                            const avatarImage =
                              feeHasUser.user.avatar === "" ||
                              feeHasUser.user.avatar === null
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${feeHasUser.user.avatar}`;

                            return (
                              <tr key={index}>
                                <td className="with-img text-center">
                                  <img
                                    src={avatarImage}
                                    className="img-rounded width-30 height-30"
                                    alt={feeHasUser.user.name}
                                  />
                                </td>
                                <td>
                                  <i
                                    className={
                                      "fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
                                    }
                                    style={{
                                      color:
                                        feeHasUser.user.commonStatus === "C" &&
                                        feeHasUser.user.confirmed === true
                                          ? "#00acac"
                                          : "#ff5b57",
                                      transition: "all .3s ease",
                                    }}
                                  />
                                  {feeHasUser.user.name}(
                                  {feeHasUser.user.userId})
                                  {feeHasUser.user.blocked ? (
                                    <i className="fa fa-lock f-s-7 fa-fw m-r-10 m-t-5" />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{feeHasUser.feeDay}일</td>
                                <td>
                                  <NumberFormat
                                    value={feeHasUser.feeValue}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림
                                </td>
                                <td>
                                  <NumberFormat
                                    value={feeHasUser.feeDefault}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림
                                </td>
                                <td>
                                  <NumberFormat
                                    value={feeHasUser.feeAmount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {feeHasUser.createdAt}
                                  </ReactMoment>
                                </td>
                                <td align="center">
                                  <Link
                                    to={`/fees/${match.params.id}/users/${feeHasUser.id}${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    납부내역
                                  </Link>{" "}
                                  <Button
                                    type="button"
                                    color="white"
                                    className="btn btn-white btn-xs text-danger"
                                    onClick={() => onDelete(feeHasUser.id)}
                                  >
                                    탈퇴
                                  </Button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
            </Panel>

            <div className="row m-b-15">
              <div className="col-xs-6 text-left">
                <div className="btn-group">
                  <Link
                    to={`/fees${location.search}`}
                    className="btn btn-white"
                  >
                    목록
                  </Link>
                </div>
              </div>
              <div className="col-xs-6 text-right">
                <div className="btn-group">
                  <Link
                    to={`/fees/${match.params.id}/users/write${location.search}`}
                    className="btn btn-white"
                  >
                    회원 추가
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
