import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../../Components/Panel/Panel";
import { Card, CardText, CardBody, CardTitle } from "reactstrap";

const Init = () => {
  return (
    <>
      <Helmet>
        <title>쿠폰 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">쿠폰</li>
        </ol>
        <h1 className="page-header">쿠폰</h1>
        <div className="row">
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>설정</PanelHeader>
              <PanelBody>
                <Card className="border-0 width-full text-center p-t-40 p-b-40">
                  <CardBody>
                    <CardTitle tag="h2" className="m-t-5 m-b-10">
                      <i className="fa fa-cogs fa-fw"></i> 쿠폰 설정
                    </CardTitle>
                    <CardText className="m-b-15">
                      쿠폰에 대한 설정사항을 등록해야 이용할 수 있습니다. <br />
                      설정을 등록해주세요.
                    </CardText>
                    <Link
                      to="/couponConfigs/write"
                      className="btn btn-warning btn-sm"
                    >
                      등록
                    </Link>
                  </CardBody>
                </Card>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Init;
