import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_CATEGORY_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(STORE_CATEGORY_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });

  if (!loading && data && data.AdminStoreCategoryDetail) {
    const {
      AdminStoreCategoryDetail: { id, pcIcon, mobileIcon, name, isGift },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        pcIcon={pcIcon}
        mobileIcon={mobileIcon}
        name={name}
        isGift={isGift}
      ></Presenter>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
