import { gql } from "apollo-boost";

export const STORE_DELIVERY_STATISTICS_MONTH_QUERY = gql`
  query StoreDeliveryStatisticsMonth($year: String!, $month: String!) {
    StoreDeliveryStatisticsMonth(year: $year, month: $month) {
      statisticsDays {
        createdAt
        payTotalPrice
        refundTotalPrice
      }
    }
  }
`;

export const STORE_DELIVERY_STATISTICS_PRODUCT_QUERY = gql`
  query StoreDeliveryStatisticsProduct($year: String!, $month: String!) {
    StoreDeliveryStatisticsProduct(year: $year, month: $month) {
      statisticsProducts {
        name
        payTotalPrice
        payTotalCount
      }
    }
  }
`;
