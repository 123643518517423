import { gql } from "apollo-boost";

/* 할인 */
export const DISCOUNT_LIST_QUERY = gql`
  query AdminDiscountList(
    $day: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminDiscountList(
      day: $day
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      discounts {
        id
        commonStatus
        startDate
        endDate
        day
        coverImage
        detailImage
        title
        contents
        store {
          id
          storeCategory {
            id
            name
          }
          storeLogo
          name
        }

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const DISCOUNT_WRITE_QUERY = gql`
  mutation AdminDiscountWrite(
    $commonStatus: String!
    $startDate: String!
    $endDate: String!
    $day: String!
    $coverImage: CoverUpload
    $detailImage: DetailUpload
    $title: String!
    $contents: String
    $store: String!
  ) {
    AdminDiscountWrite(
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      day: $day

      coverImage: $coverImage
      detailImage: $detailImage
      title: $title
      contents: $contents

      store: $store
    )
  }
`;

export const DISCOUNT_DETAIL_QUERY = gql`
  query AdminDiscountDetail($id: String!) {
    AdminDiscountDetail(id: $id) {
      id
      commonStatus
      startDate
      endDate
      day
      coverImage
      detailImage
      title
      contents
      store {
        id
        storeCategory {
          id
          name
        }
        storeLogo
        name
      }

      createdAt
      updatedAt
    }
  }
`;

export const DISCOUNT_EDIT_QUERY = gql`
  mutation AdminDiscountEdit(
    $id: String
    $commonStatus: String!
    $startDate: String!
    $endDate: String!
    $day: String!
    $coverImage: CoverUpload
    $detailImage: DetailUpload
    $title: String!
    $contents: String
    $store: String!
  ) {
    AdminDiscountEdit(
      id: $id
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      day: $day

      coverImage: $coverImage
      detailImage: $detailImage
      title: $title
      contents: $contents

      store: $store
    )
  }
`;

export const DISCOUNT_DELETE_QUERY = gql`
  mutation AdminDiscountDelete($id: String!) {
    AdminDiscountDelete(id: $id)
  }
`;

export const STORE_LIST_QUERY = gql`
  query AdminStoreList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id
        commonStatus

        storeLogo
        name
        productInfo
        storeInfo

        storeOperationHour
        storePersonalDay
        storeTel
        storeCEO

        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail

        createdAt
        updatedAt
      }
      count
    }
  }
`;
