import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { USER_LIST_QUERY } from "./Queries";
import AsyncSelect from "react-select/async";

const WriteUser = ({ history, match, location, user, setUser }) => {
  const [keyword, setKeyword] = useState("");
  const { data, loading } = useQuery(USER_LIST_QUERY, {
    variables: { keyword, skip: 0, first: 10 },
  });

  const loadOptions = (inputValue, callback) => {
    if (!loading) {
      var options = data.AdminUserList.users.map((item) => {
        return {
          value: item.id,
          label: `${item.name}(${item.userId})`,
        };
      });

      callback(options);
    }
  };
  const handleInputChange = (value) => {
    setTimeout(() => {
      setKeyword(value);
      return value;
    }, 500);
  };

  return (
    <AsyncSelect
      name="manager"
      placeholder="운영자명 입력"
      noOptionsMessage={() => {
        return "운영자명을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={({ label, value }) => {
        setUser({ id: value, name: label });
      }}
      // defaultValue=""
      value={user && { label: `${user.name}`, value: user.id }}
    />
  );
};

export default WriteUser;
