import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import { commonStatusName } from "../../../../../Components/User/UserNames";

export default ({ history, match, location, data, loading }) => {
  const id = match.params.id;

  return (
    <>
      <Helmet>
        <title>회비 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/fees/${match.params.id}/users`}>회비</Link>
          </li>
          <li className="breadcrumb-item active">출금</li>
        </ol>
        <h1 className="page-header">출금</h1>

        <div className="row m-b-15">
          <div className="col-xs-6 text-left">
            <Link to={`/fees${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-6 text-right">
            <div className="btn-group"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록(
                {!loading && data.AdminFeeMonthFileList.count}건)
              </PanelHeader>
              <PanelBody>
                {!loading && data.AdminFeeMonthFileList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>이름</th>
                            <th>연도</th>
                            <th>월</th>
                            <th>파일정보</th>
                            <th>등록일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminFeeMonthFileList.feeMonthFiles.map(
                            (chargeRequestCMS, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {commonStatusName(
                                      chargeRequestCMS.commonStatus
                                    )}
                                  </td>
                                  <td>{chargeRequestCMS.name}</td>
                                  <td>{chargeRequestCMS.year}</td>
                                  <td>{chargeRequestCMS.month}</td>
                                  <td>{chargeRequestCMS.file}</td>
                                  <td>
                                    <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                      {chargeRequestCMS.createdAt}
                                    </ReactMoment>
                                  </td>
                                  <td align={"center"}>
                                    <Link
                                      to={`/fees/${match.params.id}/monthFiles/${chargeRequestCMS.id}${location.search}`}
                                      className="btn btn-white btn-xs text-green"
                                    >
                                      관리
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>

        <div className="row m-b-15">
          <div className="col-xs-6 text-left">
            <Link to={`/fees${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-6 text-right">
            <div className="btn-group"></div>
          </div>
        </div>
      </div>
      <Link
        to={`/fees/${match.params.id}/monthFiles/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-upload"></i>
      </Link>
    </>
  );
};
