import React from "react";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_PLACE_LIST_QUERY } from "../Queries";

const DeliveryPlaceSelect = ({ onChange, value }) => {
  const { data, error, loading } = useQuery(ADMIN_DELIVERY_PLACE_LIST_QUERY, {
    variables: { skip: 0, take: 100 },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <select className="form-control" onChange={onChange} defaultValue={value}>
      <option value={""}>전체픽업장소</option>
      {data.AdminDeliveryPlaceList.deliveryPlaces.map((item, index) => {
        return (
          <option value={item.id} key={index}>
            {item.name}
          </option>
        );
      })}
    </select>
  );
};

export default DeliveryPlaceSelect;
