import { gql } from "apollo-boost";

export const TICKET_STATISTICS_YEAR_QUERY = gql`
  query AdminTicketStatisticsYear($year: Int!) {
    AdminTicketStatisticsYear(year: $year) {
      statisticsMonths {
        Month
        MonthMoney
        MonthProductCount
        MonthCount

        ReadyMoney
        ReadyCount

        ReservationMoney
        ReservationCount

        ExpirationMoney
        ExpirationCount

        CompletionMoney
        CompletionCount

        RefundMoney
        RefundCount
      }
    }
  }
`;

export const TICKET_STATISTICS_YEARORDERRANK_QUERY = gql`
  query AdminTicketStatisticsYearOrderRank($year: Int!) {
    AdminTicketStatisticsYearOrderRank(year: $year) {
      ticketConfig {
        id
      }
      ticketProductYearOrderRanks {
        id
        orderCount
        productName
      }
    }
  }
`;

export const TICKET_STATISTICS_YEARORDERPRODUCTRANK_QUERY = gql`
  query AdminTicketStatisticsYearOrderProductRank($year: Int!) {
    AdminTicketStatisticsYearOrderProductRank(year: $year) {
      ticketConfig {
        id
      }
      ticketProductYearOrderProductRanks {
        id
        orderProductCount
        productName
      }
    }
  }
`;

export const TICKET_STATISTICS_MONTH_QUERY = gql`
  query AdminTicketStatisticsMonth($year: Int!, $month: Int!) {
    AdminTicketStatisticsMonth(year: $year, month: $month) {
      statisticsDays {
        Day
        DayMoney
        DayProductCount
        DayCount

        ReadyMoney
        ReadyCount

        ReservationMoney
        ReservationCount

        ExpirationMoney
        ExpirationCount

        CompletionMoney
        CompletionCount

        RefundMoney
        RefundCount
      }
    }
  }
`;

export const TICKET_STATISTICS_MONTHORDERRANK_QUERY = gql`
  query AdminTicketStatisticsMonthOrderRank($year: Int!, $month: Int!) {
    AdminTicketStatisticsMonthOrderRank(year: $year, month: $month) {
      ticketConfig {
        id
      }
      ticketProductMonthOrderRanks {
        id
        orderCount
        productName
      }
    }
  }
`;

export const TICKET_STATISTICS_MONTHORDERPRODUCTRANK_QUERY = gql`
  query AdminTicketStatisticsMonthOrderProductRank($year: Int!, $month: Int!) {
    AdminTicketStatisticsMonthOrderProductRank(year: $year, month: $month) {
      ticketConfig {
        id
      }
      ticketProductMonthOrderProductRanks {
        id
        orderProductCount
        productName
      }
    }
  }
`;
