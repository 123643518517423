import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  DONATION_DETAIL_QUERY,
  DONATION_LEDGER_LIST_QUERY,
  DONATION_CANCEL_QUERY,
} from "../../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  currencyName,
  transactionTypeName,
  roleName,
  datePlus9,
} from "../../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const { data: detail } = useQuery(DONATION_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });

  const { data, loading } = useQuery(DONATION_LEDGER_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      wallet: match.params.wallet,
    },
  });
  const [donationLedgerListMutation] = useMutation(DONATION_LEDGER_LIST_QUERY);
  const [donationCancelMutation] = useMutation(DONATION_CANCEL_QUERY, {
    refetchQueries: () => [
      {
        query: DONATION_LEDGER_LIST_QUERY,
        variables: {
          wallet: match.params.wallet,
        },
      },
    ],
  });
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [
        ["화폐", "형태", "권한", "이름", "아이디", "내용", "일자", "금액"],
      ];
      try {
        const {
          data: { AdminLedgerList },
        } = await donationLedgerListMutation({
          variables: {
            wallet: match.params.wallet,
          },
        });
        AdminLedgerList.ledgers.forEach((ledger) => {
          data.push([
            `${currencyName(ledger.currency)}`,
            `${transactionTypeName(ledger.transactionType)}`,
            `${
              ledger.user && ledger.user.role ? roleName(ledger.user.role) : "-"
            }`,
            `${
              ledger.transactionType !== "Donation"
                ? `${ledger.wallet.name}`
                : "-"
            }`,
            `${
              ledger.transactionType !== "Donation"
                ? `${ledger.wallet.userId}`
                : "-"
            }`,
            ledger.title,
            `${datePlus9(ledger.createdAt)}`,
            ledger.value,
          ]);
        });
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };
    return (
      <>
        <button className="btn btn-white" onClick={handleDownload}>
          <i className="fa fa-download fa-fw"></i> 다운로드
        </button>
        <CSVLink
          data={csvData}
          filename={`기부내역.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };
  const onDelete = async (donationId, data) => {
    try {
      const {
        data: { AdminDonationCancel },
      } = await donationCancelMutation({
        variables: {
          donationId: donationId,
          currency: data.currency,
          id: data.id,
        },
      });
      if (AdminDonationCancel) {
        toast.success("처리 되었습니다.");
        history.push(
          `/donations/${donationId}/wallet/${data.wallet.id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminLedgerList) {
    return (
      <>
        <Presenter
          history={history}
          match={match}
          location={location}
          detail={detail}
          data={data}
          DownloadButton={DownloadButton}
          onDelete={onDelete}
        ></Presenter>
      </>
    );
  }
});
