import { gql } from "apollo-boost";

export const PUSH_LIST_QUERY = gql`
  query AdminPushList(
    $pushType: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminPushList(
      pushType: $pushType
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      pushes {
        id
        commonStatus
        pushType
        dataStack
        dataScreen
        dataId
        notificationTitle
        notificationBody
        createdAt
        updatedAt

        totalCount
        readCount
      }
      count
    }
  }
`;

export const PUSH_WRITE_QUERY = gql`
  mutation AdminPushWrite(
    $pushType: String!
    $dataStack: String
    $dataScreen: String
    $dataId: String
    $notificationTitle: String!
    $notificationBody: String!
    $UserIds: [String!]
  ) {
    AdminPushWrite(
      pushType: $pushType
      dataStack: $dataStack
      dataScreen: $dataScreen
      dataId: $dataId
      notificationTitle: $notificationTitle
      notificationBody: $notificationBody
      UserIds: $UserIds
    )
  }
`;

export const PUSH_DETAIL_QUERY = gql`
  query AdminPushDetail($id: String!) {
    AdminPushDetail(id: $id) {
      id
      commonStatus
      pushType
      dataStack
      dataScreen
      dataId
      notificationTitle
      notificationBody
      pushDetails {
        id
        commonStatus
        user {
          id
          avatar
          name
          userId
        }
        isRead
        createdAt
        updatedAt
      }
      createdAt
      updatedAt

      totalCount
      readCount
    }
  }
`;

export const USER_LIST_QUERY = gql`
  query AdminUserList(
    $commonStatus: String
    $role: String
    $isDeviceToken: Boolean
    $isPosDeviceToken: Boolean
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminUserList(
      commonStatus: $commonStatus
      role: $role
      isDeviceToken: $isDeviceToken
      isPosDeviceToken: $isPosDeviceToken
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        deviceToken
        posDeviceToken
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          name
        }
        addCurrency
        createdAt
        updatedAt

        isCms
        cmsValue
        cmsDay
      }
      count
    }
  }
`;

export const PUSH_DELETE_QUERY = gql`
  mutation AdminPushDelete($id: String!) {
    AdminPushDelete(id: $id)
  }
`;
