import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_LIST_QUERY } from "../Queries";
import AsyncSelect from "react-select/async";

const WriteStore = ({ history, match, location, store, setStore }) => {
  const [keyword, setKeyword] = useState("");
  const { data, loading } = useQuery(STORE_LIST_QUERY, {
    variables: { keyword, skip: 0, first: 10 },
  });

  const loadOptions = (inputValue, callback) => {
    if (!loading) {
      var options = data.AdminStoreList.stores.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      callback(options);
    }
  };
  const handleInputChange = (value) => {
    setTimeout(() => {
      setKeyword(value);
      return value;
    }, 500);
  };

  return (
    <>
      <AsyncSelect
        name="store"
        placeholder="가맹점명 입력"
        noOptionsMessage={() => {
          return "가맹점명을 검색해주세요.";
        }}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={({ label, value }) => {
          setStore({ label, value });
        }}
        defaultValue={store}
      />
    </>
  );
};

export default WriteStore;
