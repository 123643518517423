import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { DONATION_CATEGORY_LIST_QUERY } from "../../Queries";
import qs from "qs";

export default ({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword };

  const { data, loading } = useQuery(DONATION_CATEGORY_LIST_QUERY);

  return (
    <div>
      <Link
        className={`btn btn-white m-b-5 ${!queryString.category ? "active" : ""}
        `}
        to={`/donations?${qs.stringify({ ...query })}`}
      >
        <i className={"fas fa-th"} /> 전체
      </Link>

      {!loading &&
        data.AdminDonationCategoryList.count > 0 &&
        data.AdminDonationCategoryList.donationCategories.map((category) => {
          return (
            <Link
              key={category.id}
              className={`btn btn-white m-l-5 m-b-5 ${
                category.id === queryString.category ? "active" : ""
              }
              `}
              to={`/donations?${qs.stringify({
                category: category.id,
                ...query,
              })}`}
            >
              <i className={category.pcIcon} />
              {" " + category.name}({category.donationsCount})
            </Link>
          );
        })}

      <Link
        className={"btn btn-white m-l-5 m-b-5 "}
        to={`/donations/categories${location.search}`}
      >
        <i className={"fas fa-cog"} />
      </Link>
    </div>
  );
};
