import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, InputGroupAddon, Button, Alert } from "reactstrap";
import Select from "../../../../Containers/DeliveryProductCategory/Select";
import Pagination from "../../../../Components/Pagination/QSP";
import { commonStatusName } from "../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";

const Presenter = ({
  history,
  match,
  location,
  deliveryProductCategory_id,
  commonStatus,
  keyword,
  page,
  take,
  loading,
  data,
  baseUrl,
}) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      keyword,
    },
  });

  const onSubmit = async (data) => {
    const baseUrl =
      "?" +
      (deliveryProductCategory_id
        ? "deliveryProductCategory_id=" + deliveryProductCategory_id + "&"
        : "") +
      (data.keyword ? "keyword=" + data.keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onChange = (e) => {
    const baseUrl =
      "?" +
      (e.target.value
        ? "deliveryProductCategory_id=" + e.target.value + "&"
        : "") +
      (keyword ? "keyword=" + keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const CommonStatusSelect = () => {
    const commonStatusArray = {
      S: "대기",
      C: "승인",
    };
    const onChange = (event) => {
      const uri =
        "?" +
        (deliveryProductCategory_id
          ? "deliveryProductCategory_id=" + deliveryProductCategory_id + "&"
          : "") +
        (event.target.value ? "commonStatus=" + event.target.value + "&" : "");

      history.push(`/deliveryProducts${uri}page=1`);
    };

    return (
      <select
        name="commonStatus"
        className="form-control"
        onChange={onChange}
        value={commonStatus}
      >
        <option value="">상태 </option>
        {Object.keys(commonStatusArray).map((item, index) => {
          return (
            <option key={index} value={String(item)}>
              {commonStatusArray[item]}
            </option>
          );
        })}
      </select>
    );
  };

  const defaultUrl =
    baseUrl +
    "?" +
    (deliveryProductCategory_id
      ? `deliveryProductCategory_id=${deliveryProductCategory_id}&`
      : ``) +
    (commonStatus ? `commonStatus=${commonStatus}&` : ``) +
    (keyword ? `keyword=${keyword}&` : ``);

  return (
    <div>
      <Helmet>
        <title>상품 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to={`${baseUrl}`}>상품</Link>
        </li>
        <li className="breadcrumb-item active">상품</li>
      </ol>
      <h1 className="page-header">상품</h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({!loading && data.AdminDeliveryProductList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <InputGroup>
                    <Select
                      onChange={onChange}
                      value={deliveryProductCategory_id}
                    />
                    <InputGroupAddon addonType="append">
                      <Link
                        className={"btn btn-white"}
                        to={`${baseUrl}/categories`}
                      >
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <CommonStatusSelect />
                </div>
                <div className="col-sm-9">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="keyword"
                        rules={{ required: "검색어(상품명) 입력해주세요." }}
                        render={({ onChange, onBlur, value }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어(상품명) 입력해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <Button type="submit" size="sm" color="white">
                          <i className="fa fa-search fa-fw"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.keyword && (
                      <span className="text-red errorText">
                        {errors.keyword.message}
                      </span>
                    )}
                  </form>
                </div>
              </div>
            </Alert>
            {!loading ? (
              <Fragment>
                {data.AdminDeliveryProductList.count > 0 ? (
                  <>
                    <PanelBody>
                      <div className="product-list-wrap">
                        <ul>
                          {data.AdminDeliveryProductList.deliveryProducts.map(
                            (item, index) => {
                              const Cover =
                                item.coverImage === "" ||
                                item.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.coverImage}`;
                              return (
                                <li key={index} className="product-list">
                                  <Link
                                    to={`/deliveryProducts/${item.id}/edit${location.search}`}
                                  >
                                    <div className="img">
                                      <img src={Cover} alt={item.name} />
                                      <div className="category">
                                        <span className="label label-yellow">
                                          {commonStatusName(item.commonStatus)}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="product-list-cont">
                                      <h4 className="tit ">
                                        [{item.deliveryProductCategory.name}]{" "}
                                        {item.name}
                                      </h4>
                                      <div className="price-info">
                                        <span className="price">
                                          <NumberFormat
                                            value={item.price}
                                            prefix={""}
                                            suffix={"드림"}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </PanelBody>
                    <PanelFooter>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.AdminDeliveryProductList.count}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={defaultUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </PanelFooter>
                  </>
                ) : (
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                )}{" "}
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Panel>
        </div>
      </div>
      <Link
        to={`/deliveryProducts/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </div>
  );
};

export default Presenter;
