import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "react-moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  transactionTypeName,
  currencyName,
} from "../../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";

export default ({ isModal, history, match, location, data }) => {
  const modalRef = React.createRef();

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/fees/${match.params.id}/users${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/fees/${match.params.id}/users${location.search}`);
          }}
        >
          납부내역
        </ModalHeader>
        <ModalBody>
          {data.AdminFeeHasUserDetailList.count > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-b-0">
                  <thead>
                    <tr>
                      <th>내용</th>
                      <th>출금금액</th>
                      <th>체납금액</th>
                      <th>납부총액</th>
                      <th>등록일시</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.AdminFeeHasUserDetailList.feeHasUserDetails.map(
                      (detail, index) => {
                        return (
                          <tr key={index}>
                            <td>{detail.description}</td>
                            <td>
                              <NumberFormat
                                value={detail.feeValue}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </td>
                            <td>
                              <NumberFormat
                                value={detail.feeDefault}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </td>
                            <td align="right">
                              <NumberFormat
                                value={detail.feeAmount}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </td>
                            <td>
                              <Moment format="YYYY-MM-DD HH:mm">
                                {detail.createdAt}
                              </Moment>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p className="text-center">등록된 데이터가 없습니다.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="btn-group m-r-5">
            <button
              type="button"
              className="btn btn-white text-red"
              onClick={() => {
                history.push(
                  `/fees/${match.params.id}/users${location.search}`
                );
              }}
            >
              닫기
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
