import { gql } from "apollo-boost";

/* 통계 설정 */
export const STATISTICS_STORE_QUERY = gql`
  query AdminStatisticsStore($startDate: String, $endDate: String) {
    AdminStatisticsStore(startDate: $startDate, endDate: $endDate) {
      DRMTotal
      DRMExchangeTotal
      DRMWithdrawalTotal
      DRMUserTotal
      DRMDonationTotal

      PaymentCount
      PaymentTotal
      ExchangeRequestCount
      ExchangeRequestTotal

      PaymentStores {
        id
        wallet
        name
        count
        total
      }
      ExchangeRequestStores {
        id
        wallet
        name
        count
        total
      }
    }
  }
`;
export const STATISTICS_STOREPAYMENT_QUERY = gql`
  query AdminStatisticsStorePayment(
    $id: String!
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsStorePayment(
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_STOREPAYMENTHOUR_QUERY = gql`
  query AdminStatisticsStorePaymentHour(
    $id: String!
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsStorePaymentHour(
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      list {
        hour
        count
        total
      }
    }
  }
`;
