import React from "react";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY } from "../Queries";

const DeliveryProductCategorySelect = ({ onChange, value }) => {
  const { data, error, loading } = useQuery(
    ADMIN_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { skip: 0, take: 100 },
    }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <select className="form-control" onChange={onChange} defaultValue={value}>
      <option value={""}>분류선택</option>
      {data.AdminDeliveryProductCategoryList.deliveryProductCategories.map(
        (item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.name}({item.deliveryProductsCount}건)
            </option>
          );
        }
      )}
    </select>
  );
};

export default DeliveryProductCategorySelect;
