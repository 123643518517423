import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { HELP_CATEGORY_LIST_QUERY } from "../../../Queries";
// import { validate } from "graphql";

export default ({ history, match, location, config }) => {
  const { data, loading, refetch } = useQuery(HELP_CATEGORY_LIST_QUERY, {
    variables: {
      helpConfig: config.AdminHelpConfig.id,
      commonApp: match.params.CommonApp,
    },
  });
  useEffect(() => {
    refetch();
  });

  return (
    <div className="">
      <Link
        className={`btn btn-white m-b-5 ${
          !match.params.helpCategories ? "active" : ""
        }
        `}
        to={`/helpConfigs/${match.params.CommonApp}/${config.AdminHelpConfig.id}/helps/categories${location.search}`}
      >
        <i className={"fas fa-th"} /> 전체
      </Link>

      {!loading &&
        data.AdminHelpCategoryList.count > 0 &&
        data.AdminHelpCategoryList.helpCategories.map((category) => {
          return (
            <Link
              key={category.id}
              className={`btn btn-white m-l-5 m-b-5 ${
                category.id === match.params.helpCategories ? "active" : ""
              }
              `}
              to={`/helpConfigs/${match.params.CommonApp}/${config.AdminHelpConfig.id}/helps/categories/${category.id}${location.search}`}
            >
              <i className={category.pcIcon} />
              {" " + category.name}
            </Link>
          );
        })}

      <Link
        className={"btn btn-white m-l-5 m-b-5 "}
        to={`/helpConfigs/${match.params.CommonApp}/${config.AdminHelpConfig.id}/categories${location.search}`}
      >
        <i className={"fas fa-cogs"} />
      </Link>
    </div>
  );
};
