import React, { useEffect } from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { HELP_DETAIL_QUERY, HELP_DELETE_QUERY } from "../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import nl2br from "react-nl2br";

export default withRouter(({ history, match, location }) => {
  const {
    data: detail,
    loading,
    refetch,
  } = useQuery(HELP_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  const [helpDeleteMutation] = useMutation(HELP_DELETE_QUERY);
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminHelpDelete },
      } = await helpDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminHelpDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    refetch();
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && detail && detail.AdminHelpDetail) {
    const {
      AdminHelpDetail: { id, helpCategory, question, answer, createdAt },
    } = detail;

    return (
      <>
        <Helmet>
          <title>도움말 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">도움말</li>
          </ol>
          <h1 className="page-header">도움말</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>상세내용</PanelHeader>
                <PanelBody>
                  <div className="wrapper">
                    <h3 className="m-t-0 m-b-15 f-w-500">
                      {helpCategory.name} &gt; {question}
                    </h3>
                    <ul className="media-list underline m-b-15 p-b-15">
                      <li className="media media-sm clearfix">
                        <div className="media-body">
                          <div>
                            <i className="far fa-calendar-alt fa-fw"></i>{" "}
                            <Moment format="YYYY-MM-DD HH:mm:ss">
                              {createdAt}
                            </Moment>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <hr />
                    <p className="f-s-12 text-inverse">{nl2br(answer)}</p>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
