import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { EVENT_CONFIG_QUERY, EVENT_CONFIG_EDIT_QUERY } from "../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(
  ({ history, match, isModal, id, name, listCount, pageCount }) => {
    const modalRef = React.createRef();
    const [eventConfigEditMutation] = useMutation(EVENT_CONFIG_EDIT_QUERY, {
      refetchQueries: () => [{ query: EVENT_CONFIG_QUERY }],
    });
    const { register, handleSubmit, watch, errors } = useForm({
      defaultValues: {
        name: name,
        listCount: listCount,
        pageCount: pageCount,
      },
    });

    const onSubmit = async (data) => {
      try {
        const {
          data: { AdminEventConfigEdit },
        } = await eventConfigEditMutation({
          variables: {
            id: id,
            name: data.name,
            listCount: data.listCount,
            pageCount: data.pageCount,
          },
        });
        if (AdminEventConfigEdit) {
          toast.success("설정 되었습니다.");
          history.push(
            `/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events`
          );
        }
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <>
        <Modal
          isOpen={true}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events`
                );
              }}
            >
              설정수정
            </ModalHeader>
            <ModalBody className=" p-0">
              <div className="form-horizontal form-bordered">
                <div className="bg-white rounded">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">이름</label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="이름을 입력해주세요."
                        ref={register({ required: "이름을 입력해주세요." })}
                        defaultValue={watch("name")}
                      />
                      {errors.name && <span>{errors.name.message}</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">게시물 수</label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="listCount"
                        className="form-control"
                        placeholder="게시물 수를 입력해주세요."
                        ref={register({
                          required: "게시물 수를 입력해주세요.",
                        })}
                        defaultValue={watch("listCount")}
                      />
                      {errors.listCount && (
                        <span>{errors.listCount.message}</span>
                      )}
                    </div>
                    <label className="col-form-label col-md-2">페이징 수</label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="pageCount"
                        className="form-control"
                        placeholder="페이징 수를 입력해주세요."
                        ref={register({
                          required: "페이징 수를 입력해주세요.",
                        })}
                        defaultValue={watch("pageCount")}
                      />
                      {errors.pageCount && (
                        <span>{errors.pageCount.message}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="row">
                <div className="col-xs-12 text-right">
                  <button
                    type="submit"
                    className="btn btn-white text-blue m-r-5"
                  >
                    확인
                  </button>
                  <Link
                    to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events`}
                    className="btn btn-white text-red"
                  >
                    취소
                  </Link>
                </div>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
);
