import { gql } from "apollo-boost";

/* 기사 시작 */
export const STORE_DELIVERY_DELIVER_LIST_QUERY = gql`
  query StoreDeliveryDeliverList(
    $skip: Int!
    $take: Int
    $deliveryDeliver_id: String
  ) {
    StoreDeliveryDeliverList(
      skip: $skip
      take: $take
      deliveryDeliver_id: $deliveryDeliver_id
    ) {
      deliveryDelivers {
        id
        createdAt
        updatedAt
        state

        user {
          id
          createdAt
          updatedAt

          commonStatus
          role
          useStoreApp
          confirmed
          blocked
          avatar
          userId
          email
          phone
          name
          sex
          birthDate
          zipcode
          roadAddress
          jibunAddress
          addressDetail
        }

        orderCount
      }
      count
    }
  }
`;
export const STORE_DELIVERY_DELIVER_EDIT_MUTATION = gql`
  mutation StoreDeliveryDeliverEdit($id: String!, $state: String!) {
    StoreDeliveryDeliverEdit(id: $id, state: $state)
  }
`;
/* 기사 끝 */
