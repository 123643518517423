import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// 메인
import Dashboard from "../Routes/Administrator/Dashboard";

// 설정 > 배너
import BannerList from "../Routes/Administrator/Banner/List/index";
import BannerWrite from "../Routes/Administrator/Banner/Write";
import BannerDetail from "../Routes/Administrator/Banner/Detail";
import BannerEdit from "../Routes/Administrator/Banner/Edit/index";

// 사용자
import UserList from "../Routes/Administrator/User/List/index";
import UserWrite from "../Routes/Administrator/User/Write";
import UserDetail from "../Routes/Administrator/User/Detail";
// import UserAddressModal from "../Routes/Administrator/User/AddressModal";
import UserEdit from "../Routes/Administrator/User/Edit/index";
import UserEditPassword from "../Routes/Administrator/User/EditPassword";
import UserEditAuthPassword from "../Routes/Administrator/User/EditAuthPassword";
import UserTrustline from "../Routes/Administrator/User/Trustline/List/index";
import UserTrustlineRemittance from "../Routes/Administrator/User/Trustline/Remittance";

// 사용자 > 탈퇴
import WithdrawalList from "../Routes/Administrator/Withdrawal/List/index";

// 가맹점
import StoreCategoryList from "../Routes/Administrator/Store/Category/List/index";
import StoreCategoryWrite from "../Routes/Administrator/Store/Category/Write";
import StoreCategoryEdit from "../Routes/Administrator/Store/Category/Edit/index";

import StoreList from "../Routes/Administrator/Store/List/index";
import StoreWrite from "../Routes/Administrator/Store/Write";
import StoreDetail from "../Routes/Administrator/Store/Detail";
import StoreEdit from "../Routes/Administrator/Store/Edit/index";
import StoreQrcode from "../Routes/Administrator/Store/Qrcode";
import StoreEmployeeList from "../Routes/Administrator/Store/Employee/List/Index";
import StoreEmployeeDetail from "../Routes/Administrator/Store/Employee/Detail";

import StoreProductWrite from "../Routes/Administrator/Store/Product/Write";
import StoreProductEdit from "../Routes/Administrator/Store/Product/Edit/index";

// 가맹점 > 오늘의 할인
import DiscountList from "../Routes/Administrator/Discount/List/index";
import DiscountWrite from "../Routes/Administrator/Discount/Write";
import DiscountDetail from "../Routes/Administrator/Discount/Detail";
import DiscountEdit from "../Routes/Administrator/Discount/Edit/index";

// 충전내역
import ChargeRequestConfigWrite from "../Routes/Administrator/ChargeRequestConfig/Write";
import ChargeRequestConfigEdit from "../Routes/Administrator/ChargeRequestConfig/Edit/index";

import ChargeRequestList from "../Routes/Administrator/ChargeRequestConfig/ChargeRequest/List/index";
import ChargeRequestWrite from "../Routes/Administrator/ChargeRequestConfig/ChargeRequest/Write";

import ChargeRequestCMSList from "../Routes/Administrator/ChargeRequestCMS/List/index";
import ChargeRequestCMSWrite from "../Routes/Administrator/ChargeRequestCMS/Write";
import ChargeRequestCMSDetail from "../Routes/Administrator/ChargeRequestCMS/Detail";

import ChargeRequestGRUList from "../Routes/Administrator/ChargeRequestGRU/List/index";
import ChargeRequestGRUWrite from "../Routes/Administrator/ChargeRequestGRU/Write";

// 정산내역
import ExchangeRequestConfigWrite from "../Routes/Administrator/ExchangeRequestConfig/Write";
import ExchangeRequestConfigEdit from "../Routes/Administrator/ExchangeRequestConfig/Edit/index";

import ExchangeRequestList from "../Routes/Administrator/ExchangeRequestConfig/ExchangeRequest/List/index";

// 거래내역
import LedgerList from "../Routes/Administrator/Ledger/List/index";

// 상점결제내역
import StoreOrderList from "../Routes/Administrator/StoreOrder/List/index";

// 로컬푸드 구독 > 매장 > 동
import DeliveryStoreDongList from "../Routes/Administrator/DeliveryStore/Dong/List/index";
import DeliveryStoreDongWrite from "../Routes/Administrator/DeliveryStore/Dong/Write/index";
import DeliveryStoreDongEdit from "../Routes/Administrator/DeliveryStore/Dong/Edit/index";

// 로컬푸드 구독 > 매장
import DeliveryStoreList from "../Routes/Administrator/DeliveryStore/List/index";
import DeliveryStoreWrite from "../Routes/Administrator/DeliveryStore/Write/index";
import DeliveryStoreEdit from "../Routes/Administrator/DeliveryStore/Edit/index";

// 로컬푸드 구독 > 픽업
import DeliveryPlaceList from "../Routes/Administrator/DeliveryPlace/List/index";
import DeliveryPlaceWrite from "../Routes/Administrator/DeliveryPlace/Write/index";
import DeliveryPlaceEdit from "../Routes/Administrator/DeliveryPlace/Edit/index";

// 로컬푸드 구독 > 상품 분류
import DeliveryProductCategoryList from "../Routes/Administrator/DeliveryProduct/Category/List/index";
import DeliveryProductCategoryWrite from "../Routes/Administrator/DeliveryProduct/Category/Write/index";
import DeliveryProductCategoryMove from "../Routes/Administrator/DeliveryProduct/Category/Move/index";
import DeliveryProductCategoryEdit from "../Routes/Administrator/DeliveryProduct/Category/Edit/index";

// 로컬푸드 구독 > 상품
import DeliveryProductList from "../Routes/Administrator/DeliveryProduct/List/index";
import DeliveryProductWrite from "../Routes/Administrator/DeliveryProduct/Write/index";
import DeliveryProductEdit from "../Routes/Administrator/DeliveryProduct/Edit/index";

// 로컬푸드 구독 > 상품 > 옵션
import DeliveryProductOptionWrite from "../Routes/Administrator/DeliveryProduct/Option/Write/index";
import DeliveryProductOptionEdit from "../Routes/Administrator/DeliveryProduct/Option/Edit/index";

// 로컬푸드 구독 > 상품 > 옵션 > 상세
import DeliveryProductOptionDetailWrite from "../Routes/Administrator/DeliveryProduct/Option/Detail/Write/index";
import DeliveryProductOptionDetailEdit from "../Routes/Administrator/DeliveryProduct/Option/Detail/Edit/index";

// 로컬푸드 구독 > 묶음
import DeliverySetList from "../Routes/Administrator/DeliverySet/List/index";
import DeliverySetWrite from "../Routes/Administrator/DeliverySet/Write/index";
import DeliverySetEdit from "../Routes/Administrator/DeliverySet/Edit/index";

// 로컬푸드 구독 > 상품, 묶음 수정 로그
import DeliveryEditLogList from "../Routes/Administrator/DeliveryEditLog/List/index";

// 로컬푸드 구독 > 구독박스
import DeliveryBoxList from "../Routes/Administrator/DeliveryBox/List/index";
import DeliveryBoxDetail from "../Routes/Administrator/DeliveryBox/Detail/index";
import DeliveryBoxDayOfWeek from "../Routes/Administrator/DeliveryBox/DayOfWeek/index";
import DeliveryBoxDayOfWeekPrint from "../Routes/Administrator/DeliveryBox/DayOfWeekPrint/index";

// 로컬푸드 구독 > 구독박스 > 주소
import DeliveryAddressList from "../Routes/Administrator/DeliveryBox/Address/List/index";
import DeliveryAddressWrite from "../Routes/Administrator/DeliveryBox/Address/Write/index";
import DeliveryAddressEdit from "../Routes/Administrator/DeliveryBox/Address/Edit/index";

// 로컬푸드 구독 > 배송
import DeliveryOrderList from "../Routes/Administrator/DeliveryOrder/List/index";
import DeliveryOrderDetail from "../Routes/Administrator/DeliveryOrder/Detail/index";
import DeliveryOrderQRCode from "../Routes/Administrator/DeliveryOrder/QRCode/index";
import DeliveryOrderEditState from "../Routes/Administrator/DeliveryOrder/EditState/index";
import DeliveryOrderProductPrint from "../Routes/Administrator/DeliveryOrder/ProductPrint/index";
import DeliveryOrderOrderPrint from "../Routes/Administrator/DeliveryOrder/OrderPrint/index";

// 로컬푸드 구독 > 기사
import DeliveryDeliverList from "../Routes/Administrator/DeliveryDeliver/List/index";

// 로컬푸드 구독 > 배너
import DeliveryBannerList from "../Routes/Administrator/DeliveryBanner/List/index";
import DeliveryBannerWrite from "../Routes/Administrator/DeliveryBanner/Write/index";
import DeliveryBannerEdit from "../Routes/Administrator/DeliveryBanner/Edit/index";

// 로컬푸드 구독 > 통계
import DeliveryStatisticsMonth from "../Routes/Administrator/DeliveryStatistics/Month/index";
import DeliveryStatisticsProduct from "../Routes/Administrator/DeliveryStatistics/Product/index";

// 선물하기 > 선물
import GiftConfigWrite from "../Routes/Administrator/GiftConfig/Write";
import GiftConfigEdit from "../Routes/Administrator/GiftConfig/Edit/index";

import GiftCategoryList from "../Routes/Administrator/GiftConfig/Category/List/index";
import GiftCategoryWrite from "../Routes/Administrator/GiftConfig/Category/Write";
import GiftCategoryMove from "../Routes/Administrator/GiftConfig/Category/Move/index";
import GiftCategoryEdit from "../Routes/Administrator/GiftConfig/Category/Edit/index";

import GiftProductList from "../Routes/Administrator/GiftConfig/Product/List/index";
import GiftProductBest from "../Routes/Administrator/GiftConfig/Product/Best/index";
import GiftProductNew from "../Routes/Administrator/GiftConfig/Product/New/index";
import GiftProductWrite from "../Routes/Administrator/GiftConfig/Product/Write/index";
import GiftProductDetail from "../Routes/Administrator/GiftConfig/Product/Detail/index";
import GiftProductEdit from "../Routes/Administrator/GiftConfig/Product/Edit/index";

import GiftProductProductOptionWrite from "../Routes/Administrator/GiftConfig/Product/ProductOption/Write";
import GiftProductProductOptionEdit from "../Routes/Administrator/GiftConfig/Product/ProductOption/Edit/index";
import GiftProductProductOptionDetailWrite from "../Routes/Administrator/GiftConfig/Product/ProductOptionDetail/Write";
import GiftProductProductOptionDetailEdit from "../Routes/Administrator/GiftConfig/Product/ProductOptionDetail/Edit/index";

import GiftProductTicketOptionWrite from "../Routes/Administrator/GiftConfig/Product/TicketOption/Write";
import GiftProductTicketOptionEdit from "../Routes/Administrator/GiftConfig/Product/TicketOption/Edit/index";
import GiftProductTicketOptionDetailWrite from "../Routes/Administrator/GiftConfig/Product/TicketOptionDetail/Write";
import GiftProductTicketOptionDetailEdit from "../Routes/Administrator/GiftConfig/Product/TicketOptionDetail/Edit/index";

// 선물하기 > 구매내역
import GiftOrderList from "../Routes/Administrator/GiftOrder/List/index";
import GiftOrderDetail from "../Routes/Administrator/GiftOrder/Detail";

// 선물하기 > 사용내역
import GiftOrderProductList from "../Routes/Administrator/GiftOrderProduct/List/index";
import GiftOrderProductDetail from "../Routes/Administrator/GiftOrderProduct/Detail/index";
import GiftOrderProductDetailSend from "../Routes/Administrator/GiftOrderProduct/Detail/Send/index";

// 선물하기 > 정산내역
import GiftExchangeList from "../Routes/Administrator/GiftExchange/List/index";
import GiftExchangeDetail from "../Routes/Administrator/GiftExchange/Detail";

// 선물하기 > 후기
import GiftProductReviewList from "../Routes/Administrator/GiftProductReview/List/index";
import GiftProductReviewDetail from "../Routes/Administrator/GiftProductReview/Detail";

// 선물하기 > 문의
import GiftProductInquiryList from "../Routes/Administrator/GiftProductInquiry/List/index";
import GiftProductInquiryDetail from "../Routes/Administrator/GiftProductInquiry/Detail";

// 선물하기 > 배너
import GiftBannerList from "../Routes/Administrator/GiftBanner/List/index";
import GiftBannerWrite from "../Routes/Administrator/GiftBanner/Write";
import GiftBannerDetail from "../Routes/Administrator/GiftBanner/Detail";
import GiftBannerEdit from "../Routes/Administrator/GiftBanner/Edit/index";

// 선물하기 > 통계
import GiftStatisticsYear from "../Routes/Administrator/GiftStatistics/Year";
import GiftStatisticsYearOrderRank from "../Routes/Administrator/GiftStatistics/YearOrderRankModal";
import GiftStatisticsYearOrderProductRank from "../Routes/Administrator/GiftStatistics/YearOrderProductRankModal";
import GiftStatisticsMonth from "../Routes/Administrator/GiftStatistics/Month";
import GiftStatisticsMonthOrderRank from "../Routes/Administrator/GiftStatistics/MonthOrderRankModal";
import GiftStatisticsMonthOrderProductRank from "../Routes/Administrator/GiftStatistics/MonthOrderProductRankModal";
import GiftStatisticsDay from "../Routes/Administrator/GiftStatistics/Day";

// 선물티켓 > 선물
import TicketConfigWrite from "../Routes/Administrator/TicketConfig/Write";
import TicketConfigEdit from "../Routes/Administrator/TicketConfig/Edit/index";

import TicketCategoryList from "../Routes/Administrator/TicketConfig/Category/List/index";
import TicketCategoryWrite from "../Routes/Administrator/TicketConfig/Category/Write";
import TicketCategoryMove from "../Routes/Administrator/TicketConfig/Category/Move/index";
import TicketCategoryEdit from "../Routes/Administrator/TicketConfig/Category/Edit/index";

import TicketProductList from "../Routes/Administrator/TicketConfig/Product/List/index";
import TicketProductWrite from "../Routes/Administrator/TicketConfig/Product/Write/index";
import TicketProductDetail from "../Routes/Administrator/TicketConfig/Product/Detail/index";
import TicketProductEdit from "../Routes/Administrator/TicketConfig/Product/Edit/index";

import TicketProductTicketDateWrite from "../Routes/Administrator/TicketConfig/Product/TicketDate/Write";
import TicketProductTicketDateEdit from "../Routes/Administrator/TicketConfig/Product/TicketDate/Edit/index";
import TicketProductTicketDateTimeWrite from "../Routes/Administrator/TicketConfig/Product/TicketDateTime/Write";
import TicketProductTicketDateTimeEdit from "../Routes/Administrator/TicketConfig/Product/TicketDateTime/Edit/index";

// 선물티켓 > 구매내역
import TicketOrderList from "../Routes/Administrator/TicketOrder/List/index";
import TicketOrderDetail from "../Routes/Administrator/TicketOrder/Detail";

// 선물티켓 > 사용내역
import TicketOrderProductList from "../Routes/Administrator/TicketOrderProduct/List/index";
import TicketOrderProductDetail from "../Routes/Administrator/TicketOrderProduct/Detail/index";

// 선물티켓 > 정산내역
import TicketExchangeList from "../Routes/Administrator/TicketExchange/List/index";
import TicketExchangeDetail from "../Routes/Administrator/TicketExchange/Detail";

// 선물티켓 > 후기
import TicketProductReviewList from "../Routes/Administrator/TicketProductReview/List/index";
import TicketProductReviewDetail from "../Routes/Administrator/TicketProductReview/Detail";

// 선물티켓 > 문의
import TicketProductInquiryList from "../Routes/Administrator/TicketProductInquiry/List/index";
import TicketProductInquiryDetail from "../Routes/Administrator/TicketProductInquiry/Detail";

// 선물티켓 > 배너
import TicketBannerList from "../Routes/Administrator/TicketBanner/List/index";
import TicketBannerWrite from "../Routes/Administrator/TicketBanner/Write";
import TicketBannerDetail from "../Routes/Administrator/TicketBanner/Detail";
import TicketBannerEdit from "../Routes/Administrator/TicketBanner/Edit/index";

// 선물티켓 > 통계
import TicketStatisticsYear from "../Routes/Administrator/TicketStatistics/Year";
import TicketStatisticsYearOrderRank from "../Routes/Administrator/TicketStatistics/YearOrderRankModal";
import TicketStatisticsYearOrderProductRank from "../Routes/Administrator/TicketStatistics/YearOrderProductRankModal";
import TicketStatisticsMonth from "../Routes/Administrator/TicketStatistics/Month";
import TicketStatisticsMonthOrderRank from "../Routes/Administrator/TicketStatistics/MonthOrderRankModal";
import TicketStatisticsMonthOrderProductRank from "../Routes/Administrator/TicketStatistics/MonthOrderProductRankModal";
import TicketStatisticsDay from "../Routes/Administrator/TicketStatistics/Day";

// 고객센터 > 공지사항
import NoticeConfigWrite from "../Routes/Administrator/NoticeConfig/Write";
import NoticeConfigEdit from "../Routes/Administrator/NoticeConfig/Edit/index";
import NoticeList from "../Routes/Administrator/NoticeConfig/Notice/List/index";
import NoticeWrite from "../Routes/Administrator/NoticeConfig/Notice/Write";
import NoticeDetail from "../Routes/Administrator/NoticeConfig/Notice/Detail";
import NoticeEdit from "../Routes/Administrator/NoticeConfig/Notice/Edit/index";

// 고객센터 > 이벤트
import EventConfigWrite from "../Routes/Administrator/EventConfig/Write";
import EventConfigEdit from "../Routes/Administrator/EventConfig/Edit/index";
import EventList from "../Routes/Administrator/EventConfig/Event/List/index";
import EventWrite from "../Routes/Administrator/EventConfig/Event/Write";
import EventDetail from "../Routes/Administrator/EventConfig/Event/Detail";
import EventEdit from "../Routes/Administrator/EventConfig/Event/Edit/index";

// 고객센터 > 도움말
import HelpConfigWrite from "../Routes/Administrator/HelpConfig/Write";
import HelpConfigEdit from "../Routes/Administrator/HelpConfig/Edit/Index";
import HelpCategoryList from "../Routes/Administrator/HelpConfig/Category/List/Index";
import HelpCategoryWrite from "../Routes/Administrator/HelpConfig/Category/Write";
import HelpCategoryEdit from "../Routes/Administrator/HelpConfig/Category/Edit/Index";
import HelpList from "../Routes/Administrator/HelpConfig/Help/List/index";
import HelpWrite from "../Routes/Administrator/HelpConfig/Help/Write";
import HelpDetail from "../Routes/Administrator/HelpConfig/Help/Detail";
import HelpEdit from "../Routes/Administrator/HelpConfig/Help/Edit/Index";

// 고객센터 > 1:1문의
import InquiryCategoryList from "../Routes/Administrator/InquiryConfig/Category/List/Index";
import InquiryCategoryWrite from "../Routes/Administrator/InquiryConfig/Category/Write";
import InquiryCategoryEdit from "../Routes/Administrator/InquiryConfig/Category/Edit/Index";
import InquiryConfigWrite from "../Routes/Administrator/InquiryConfig/Write";
import InquiryConfigEdit from "../Routes/Administrator/InquiryConfig/Edit/Index";
import InquiryList from "../Routes/Administrator/InquiryConfig/Inquiry/List/index";
import InquiryDetail from "../Routes/Administrator/InquiryConfig/Inquiry/Detail";
import InquiryReply from "../Routes/Administrator/InquiryConfig/Inquiry/Reply/index";

// 기부
import DonationCategoryList from "../Routes/Administrator/Donation/Category/List/index";
import DonationCategoryWrite from "../Routes/Administrator/Donation/Category/Write";
import DonationCategoryEdit from "../Routes/Administrator/Donation/Category/Edit/index";

import DonationList from "../Routes/Administrator/Donation/List/Index";
import DonationWrite from "../Routes/Administrator/Donation/Write";
import DonationDetail from "../Routes/Administrator/Donation/Detail";
import DonationTrustline from "../Routes/Administrator/Donation/Trustline/List/index";
import DonationTrustlineRemittance from "../Routes/Administrator/Donation/Trustline/Remittance";
import DonationEdit from "../Routes/Administrator/Donation/Edit/Index";

import DonationImageWrite from "../Routes/Administrator/Donation/Image/Write";
import DonationImageEdit from "../Routes/Administrator/Donation/Image/Edit/index";

// 회비
import FeeList from "../Routes/Administrator/Fee/List/Index";
import FeeWrite from "../Routes/Administrator/Fee/Write";
import FeeDetail from "../Routes/Administrator/Fee/Detail";
import FeeEdit from "../Routes/Administrator/Fee/Edit/Index";

// 회비 > 회원
import FeeUserList from "../Routes/Administrator/Fee/User/List/index";
import FeeUserWrite from "../Routes/Administrator/Fee/User/Write/index";
import FeeUserDetailList from "../Routes/Administrator/Fee/User/Detail/List/index";

// 회비 > 출금
import FeeMonthFileList from "../Routes/Administrator/Fee/MonthFile/List/index";
import FeeMonthFileWrite from "../Routes/Administrator/Fee/MonthFile/Write";
import FeeMonthFileDetail from "../Routes/Administrator/Fee/MonthFile/Detail/index";
import FeeMonthFilePreview from "../Routes/Administrator/Fee/MonthFile/Preview";

import FeeTrustline from "../Routes/Administrator/Fee/Trustline/List/index";
import FeeTrustlineRemittance from "../Routes/Administrator/Fee/Trustline/Remittance";

// 알림
import PushList from "../Routes/Administrator/Push/List/index";
import PushFcm from "../Routes/Administrator/Push/Fcm";
import PushExpo from "../Routes/Administrator/Push/Expo";
import PushDetail from "../Routes/Administrator/Push/Detail";

//쿠폰
import CouponConfigWrite from "../Routes/Administrator/CouponConfig/Write";
import CouponConfigEdit from "../Routes/Administrator/CouponConfig/Edit/Index";
import CouponCategoryList from "../Routes/Administrator/CouponConfig/Category/List/Index";
import CouponCategoryWrite from "../Routes/Administrator/CouponConfig/Category/Write";
import CouponCategoryEdit from "../Routes/Administrator/CouponConfig/Category/Edit/Index";
import CouponList from "../Routes/Administrator/CouponConfig/Coupon/List/index";
import CouponWrite from "../Routes/Administrator/CouponConfig/Coupon/Write";
import CouponDetail from "../Routes/Administrator/CouponConfig/Coupon/Detail";
import CouponQrcode from "../Routes/Administrator/CouponConfig/Coupon/Qrcode";
import CouponEditDefault from "../Routes/Administrator/CouponConfig/Coupon/EditDefault/index";
import CouponEditProduct from "../Routes/Administrator/CouponConfig/Coupon/EditProduct/index";
import CouponEditStores from "../Routes/Administrator/CouponConfig/Coupon/EditStores/index";
import CouponUserStores from "../Routes/Administrator/CouponConfig/Coupon/UserStores/index";

// 전체통계
import Statistics from "../Routes/Administrator/Statistics/Index";
import StatisticsDNTIssue from "../Routes/Administrator/Statistics/DNTIssueModal";
import StatisticsDNTUse from "../Routes/Administrator/Statistics/DNTUseModal";
import StatisticsChargeRequestUser from "../Routes/Administrator/Statistics/ChargeRequestUserModal";
import StatisticsChargeRequestCMS from "../Routes/Administrator/Statistics/ChargeRequestCMSModal";
import StatisticsChargeRequestNomal from "../Routes/Administrator/Statistics/ChargeRequestNomalModal";
import StatisticsRemittance from "../Routes/Administrator/Statistics/RemittanceModal";
import StatisticsPayment from "../Routes/Administrator/Statistics/PaymentModal";

import StatisticsUsersCharge from "../Routes/Administrator/Statistics/UsersChargeModal";
import StatisticsUsersStoreOrder from "../Routes/Administrator/Statistics/UsersStoreOrderModal";
import StatisticsUsersStoreReturn from "../Routes/Administrator/Statistics/UsersStoreReturnModal";
import StatisticsUsersRemittance from "../Routes/Administrator/Statistics/UsersRemittanceModal";
import StatisticsUsersDonation from "../Routes/Administrator/Statistics/UsersDonationModal";
import StatisticsUsersMinusStoreOrder from "../Routes/Administrator/Statistics/UsersMinusStoreOrderModal";

import StatisticsStorePayment from "../Routes/Administrator/Statistics/StorePaymentModal";
import StatisticsStorePaymentHour from "../Routes/Administrator/Statistics/StorePaymentHourModal";

//통계
import StatisticsMain from "../Routes/Administrator/StatisticsMain/Index";

import StatisticsDNT from "../Routes/Administrator/StatisticsDNT/Index";
import StatisticsDNTIssueModal from "../Routes/Administrator/StatisticsDNT/IssueModal";
import StatisticsDNTUseModal from "../Routes/Administrator/StatisticsDNT/UseModal";

import StatisticsUser from "../Routes/Administrator/StatisticsUser/Index";
import StatisticsUserChargeRequestUser from "../Routes/Administrator/StatisticsUser/ChargeRequestUserModal";
import StatisticsUserChargeRequestCMS from "../Routes/Administrator/StatisticsUser/ChargeRequestCMSModal";
import StatisticsUserChargeRequestNomal from "../Routes/Administrator/StatisticsUser/ChargeRequestNomalModal";
import StatisticsUserRemittanceModal from "../Routes/Administrator/StatisticsUser/RemittanceModal";
import StatisticsUserPaymentModal from "../Routes/Administrator/StatisticsUser/PaymentModal";
import StatisticsUsersChargeModal from "../Routes/Administrator/StatisticsUser/UsersChargeModal";
import StatisticsUsersStoreOrderModal from "../Routes/Administrator/StatisticsUser/UsersStoreOrderModal";
import StatisticsUsersStoreReturnModal from "../Routes/Administrator/StatisticsUser/UsersStoreReturnModal";
import StatisticsUsersRemittanceModal from "../Routes/Administrator/StatisticsUser/UsersRemittanceModal";
import StatisticsUsersDonationModal from "../Routes/Administrator/StatisticsUser/UsersDonationModal";
import StatisticsUsersMinusStoreOrderModal from "../Routes/Administrator/StatisticsUser/UsersMinusStoreOrderModal";

import StatisticsStore from "../Routes/Administrator/StatisticsStore/Index";
import StatisticsStorePaymentModal from "../Routes/Administrator/StatisticsStore/StorePaymentModal";
import StatisticsStorePaymentHourModal from "../Routes/Administrator/StatisticsStore/StorePaymentHourModal";

import StatisticsGJP from "../Routes/Administrator/StatisticsGJP/Index";
import StatisticsTOL from "../Routes/Administrator/StatisticsTOL/Index";
import StatisticsGRU from "../Routes/Administrator/StatisticsGRU/Index";
import StatisticsSTR from "../Routes/Administrator/StatisticsSTR/Index";
import StatisticsNDO from "../Routes/Administrator/StatisticsNDO/Index";

const AdminRoutes = ({
  userData,
  history,
  location,
  match,
  isModal,
  previousLocation,
}) => (
  <Switch userData={userData} location={isModal ? previousLocation : location}>
    {/* 메인 시작 */}
    <Route exact path="/dashboard">
      <Dashboard userData={userData} />
    </Route>
    {/* 메인 끝 */}
    {/* 메인 시작 */}
    {/** 배너 **/}
    <Route exact path={"/banners"} component={BannerList} />
    <Route exact path={"/banners/write"} component={BannerWrite} />
    <Route exact path={"/banners/:id"} component={BannerDetail} />
    <Route exact path={"/banners/:id/edit"} component={BannerEdit} />
    {/* 메인 끝 */}
    {/* 사용자 시작 */}
    <Route exact path={["/users/:role"]} component={UserList} />
    <Route exact path="/users/Issuer/write" component={UserWrite} />
    <Route exact path="/users/:role/:id" component={UserDetail} />
    <Route exact path="/users/:role/:id/edit">
      <UserDetail />
      <UserEdit isModal={isModal} />
    </Route>
    <Route exact path="/users/:role/:id/editPassword">
      <UserDetail />
      <UserEditPassword isModal={isModal} />
    </Route>
    <Route exact path="/users/:role/:id/editAuthPassword">
      <UserDetail />
      <UserEditAuthPassword isModal={isModal} />
    </Route>
    <Route exact path="/users/:role/:id/trustlines/:trustline">
      <UserDetail />
      <UserTrustline isModal={isModal} />
    </Route>
    <Route exact path="/users/:role/:id/trustlines/:trustline/remittance">
      <UserDetail />
      <UserTrustlineRemittance isModal={isModal} />
    </Route>
    {/* 사용자 끝 */}
    {/* 탈퇴자 시작 */}
    <Route exact path={["/withdrawals"]} component={WithdrawalList} />
    {/* 탈퇴자 끝 */}
    {/* 가맹점 시작 */}
    {/* 가맹점 분류 시작 */}
    <Route exact path="/stores/categories">
      <StoreList />
      <StoreCategoryList isModal={isModal} />
    </Route>
    <Route exact path="/stores/categories/write">
      <StoreList />
      <StoreCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/stores/categories/:id/edit">
      <StoreList />
      <StoreCategoryEdit isModal={isModal} />
    </Route>
    {/* 가맹점 분류 끝 */}
    <Route exact path={["/stores"]} component={StoreList} />
    <Route exact path="/stores/write">
      <StoreList />
      <StoreWrite isModal={isModal} />
    </Route>
    <Route exact path="/stores/:id" component={StoreDetail} />
    <Route exact path="/stores/:id/employees">
      <StoreList />
      <StoreEmployeeList isModal={isModal} />
    </Route>
    <Route exact path="/stores/:id/employees/:user">
      <StoreList />
      <StoreEmployeeDetail isModal={isModal} />
    </Route>
    <Route exact path="/stores/:id/edit">
      <StoreDetail />
      <StoreEdit isModal={isModal} />
    </Route>
    <Route exact path="/stores/:id/user/:user/qrcode">
      <StoreDetail />
      <StoreQrcode isModal={isModal} />
    </Route>
    <Route exact path="/stores/:id/user/:UserId/trustlines/:trustline">
      <StoreDetail />
      <UserTrustline isModal={isModal} />
    </Route>
    {/* 가맹점 상품 시작 */}
    <Route exact path="/stores/:id/products/write">
      <StoreDetail />
      <StoreProductWrite isModal={isModal} />
    </Route>
    <Route exact path="/stores/:id/products/:storeProduct/edit">
      <StoreDetail />
      <StoreProductEdit isModal={isModal} />
    </Route>
    {/* 가맹점 상품 끝 */}
    {/* 가맹점 > 오늘의 할인 시작 */}
    <Route exact path={["/discounts"]} component={DiscountList} />
    <Route exact path="/discounts/write" component={DiscountWrite} />
    <Route exact path="/discounts/:id" component={DiscountDetail} />
    <Route exact path="/discounts/:id/edit" component={DiscountEdit} />
    {/* 가맹점 > 오늘의 할인 끝 */}
    {/* 가맹점 끝 */}
    {/* 가맹점 */}
    <Route
      exact
      path={[
        "/storeConfigs/:storeConfig/stores",
        "/storeConfigs/:storeConfig/stores/categories",
        "/storeConfigs/:storeConfig/stores/categories/:storeCategories",
      ]}
      component={StoreList}
    />
    <Route exact path="/storeConfigs/:storeConfig/stores/write">
      <StoreList />
      <StoreWrite />
    </Route>
    <Route
      exact
      path="/storeConfigs/:storeConfig/stores/:id"
      component={StoreDetail}
    />
    <Route exact path="/storeConfigs/:storeConfig/stores/:id/edit">
      <StoreDetail />
      <StoreEdit />
    </Route>
    {/* 가맹점 상품 */}
    <Route exact path="/storeConfigs/:storeConfig/stores/:id/products/write">
      <StoreDetail />
      <StoreProductWrite />
    </Route>
    <Route
      exact
      path="/storeConfigs/:storeConfig/stores/:id/products/:storeProduct/edit"
    >
      <StoreDetail />
      <StoreProductEdit />
    </Route>
    {/* 충전요청 설정 */}
    <Route exact path="/chargeRequestConfigs" component={ChargeRequestList} />
    <Route exact path="/chargeRequestConfigs/write">
      <ChargeRequestList />
      <ChargeRequestConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/chargeRequestConfigs/:chargeRequestConfig/edit">
      <ChargeRequestList />
      <ChargeRequestConfigEdit isModal={isModal} />
    </Route>
    {/* 충전요청 */}
    <Route
      exact
      path={[
        "/chargeRequestConfigs",
        "/chargeRequestConfigs/:chargeRequestConfig",
        "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests",
      ]}
      component={ChargeRequestList}
    />
    <Route
      exact
      path="/chargeRequestConfigs/:chargeRequestConfig/chargeRequests/write"
      component={ChargeRequestWrite}
    />
    {/* CMS충전요청 */}
    <Route
      exact
      path={["/chargeRequestCMses"]}
      component={ChargeRequestCMSList}
    />
    <Route exact path="/chargeRequestCMses/write">
      <ChargeRequestCMSList />
      <ChargeRequestCMSWrite isModal={isModal} />
    </Route>
    <Route
      exact
      path="/chargeRequestCMses/:id"
      component={ChargeRequestCMSDetail}
    />
    {/* GRU 충전요청 */}
    <Route
      exact
      path={["/chargeRequestGRUs"]}
      component={ChargeRequestGRUList}
    />
    <Route
      exact
      path="/chargeRequestGRUs/write"
      component={ChargeRequestGRUWrite}
    />
    {/* 정산내역 */}
    <Route
      exact
      path="/exchangeRequestConfigs"
      component={ExchangeRequestList}
    />
    <Route exact path="/exchangeRequestConfigs/write">
      <ExchangeRequestList />
      <ExchangeRequestConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/exchangeRequestConfigs/:exchangeRequestConfig/edit">
      <ExchangeRequestList />
      <ExchangeRequestConfigEdit isModal={isModal} />
    </Route>
    <Route
      exact
      path={[
        "/exchangeRequestConfigs",
        "/exchangeRequestConfigs/:exchangeRequestConfig",
        "/exchangeRequestConfigs/:exchangeRequestConfig/exchangeRequests",
      ]}
      component={ExchangeRequestList}
    />
    {/* 정산내역 */}
    {/* 거래내역 */}
    <Route
      exact
      path={["/ledgers", "/ledgers/:Currency"]}
      component={LedgerList}
    />
    {/* 거래내역 */}
    {/* 상점결제내역 */}
    <Route exact path={["/storeOrders"]} component={StoreOrderList} />
    {/* 상점결제내역 */}

    {/* 로컬푸드 구독 시작 */}
    {/** 매장 동 **/}
    <Route
      exact
      path="/deliveryStores/dongs"
      component={DeliveryStoreDongList}
    />
    <Route exact path="/deliveryStores/dongs/write">
      <DeliveryStoreDongList />
      <DeliveryStoreDongWrite isModal={isModal} />
    </Route>
    <Route exact path="/deliveryStores/dongs/:id/edit">
      <DeliveryStoreDongList />
      <DeliveryStoreDongEdit isModal={isModal} />
    </Route>

    {/** 매장 **/}
    <Route exact path="/deliveryStores" component={DeliveryStoreList} />
    <Route exact path="/deliveryStores/write" component={DeliveryStoreWrite} />
    <Route
      exact
      path="/deliveryStores/:id/edit"
      component={DeliveryStoreEdit}
    />

    {/** 픽업 **/}
    <Route exact path="/deliveryPlaces" component={DeliveryPlaceList} />
    <Route exact path="/deliveryPlaces/write" component={DeliveryPlaceWrite} />
    <Route
      exact
      path="/deliveryPlaces/:id/edit"
      component={DeliveryPlaceEdit}
    />

    {/** 상품 분류 **/}
    <Route
      exact
      path={["/deliveryProducts/categories"]}
      component={DeliveryProductCategoryList}
    />
    <Route exact path="/deliveryProducts/categories/write">
      <DeliveryProductCategoryList />
      <DeliveryProductCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/deliveryProducts/categories/:id/move">
      <DeliveryProductCategoryList />
      <DeliveryProductCategoryMove isModal={isModal} />
    </Route>
    <Route exact path="/deliveryProducts/categories/:id/edit">
      <DeliveryProductCategoryList />
      <DeliveryProductCategoryEdit isModal={isModal} />
    </Route>

    {/** 상품 **/}
    <Route exact path="/deliveryProducts" component={DeliveryProductList} />
    <Route
      exact
      path="/deliveryProducts/write"
      component={DeliveryProductWrite}
    />
    <Route
      exact
      path="/deliveryProducts/:id/edit"
      component={DeliveryProductEdit}
    />
    <Route exact path="/:editType/:id/logs" component={DeliveryEditLogList} />

    {/** 상품 옵션 **/}
    <Route exact path="/deliveryProducts/:id/options/write">
      <DeliveryProductEdit />
      <DeliveryProductOptionWrite />
    </Route>
    <Route exact path="/deliveryProducts/:id/options/:option/Edit">
      <DeliveryProductEdit />
      <DeliveryProductOptionEdit />
    </Route>
    {/** 상품 상세 옵션 **/}
    <Route exact path="/deliveryProducts/:id/options/:option/details/write">
      <DeliveryProductEdit />
      <DeliveryProductOptionDetailWrite />
    </Route>
    <Route
      exact
      path="/deliveryProducts/:id/options/:option/details/:detail/edit"
    >
      <DeliveryProductEdit />
      <DeliveryProductOptionDetailEdit />
    </Route>

    {/** 묶음 **/}
    <Route exact path="/deliverySets" component={DeliverySetList} />
    <Route exact path="/deliverySets/write" component={DeliverySetWrite} />
    <Route exact path="/deliverySets/:id/edit" component={DeliverySetEdit} />

    {/** 구독박스 **/}
    <Route exact path="/deliveryBoxes" component={DeliveryBoxList} />
    <Route exact path="/deliveryBoxes/:id" component={DeliveryBoxDetail} />
    {/** 구독박스 주소 **/}
    <Route exact path="/deliveryBoxes/:id/addresses">
      <DeliveryBoxDetail />
      <DeliveryAddressList isModal={isModal} />
    </Route>
    <Route exact path="/deliveryBoxes/:id/addresses/write">
      <DeliveryBoxDetail />
      <DeliveryAddressWrite isModal={isModal} />
    </Route>
    <Route exact path="/deliveryBoxes/:id/addresses/:address/edit">
      <DeliveryBoxDetail />
      <DeliveryAddressEdit isModal={isModal} />
    </Route>
    {/** 요일별 주문현황 **/}
    <Route
      exact
      path="/deliveryBoxesDayOfWeek"
      component={DeliveryBoxDayOfWeek}
    />
    <Route exact path="/deliveryBoxesDayOfWeek/print">
      <DeliveryBoxDayOfWeek />
      <DeliveryBoxDayOfWeekPrint isModal={isModal} />
    </Route>

    {/** 배송 **/}
    <Route exact path="/deliveryOrders" component={DeliveryOrderList} />
    <Route exact path="/deliveryOrders/:id" component={DeliveryOrderDetail} />
    <Route exact path="/deliveryOrders/:id/editState">
      <DeliveryOrderDetail />
      <DeliveryOrderEditState isModal={isModal} />
    </Route>
    <Route exact path="/deliveryOrders/:id/qrcode">
      <DeliveryOrderDetail />
      <DeliveryOrderQRCode isModal={isModal} />
    </Route>
    <Route exact path="/deliveryOrdersProduct/print">
      <DeliveryOrderList />
      <DeliveryOrderProductPrint isModal={isModal} />
    </Route>
    <Route exact path="/deliveryOrdersOrder/print">
      <DeliveryOrderList />
      <DeliveryOrderOrderPrint isModal={isModal} />
    </Route>

    {/** 기사 **/}
    <Route exact path="/deliveryDelivers" component={DeliveryDeliverList} />

    {/** 배너 **/}
    <Route exact path={"/deliveryBanners"} component={DeliveryBannerList} />
    <Route
      exact
      path={"/deliveryBanners/write"}
      component={DeliveryBannerWrite}
    />
    <Route
      exact
      path={"/deliveryBanners/:id/edit"}
      component={DeliveryBannerEdit}
    />

    {/** 통계 **/}
    <Route
      exact
      path="/deliveryStatistics/month/:year/:month"
      component={DeliveryStatisticsMonth}
    />
    <Route
      exact
      path="/deliveryStatistics/product/:year/:month"
      component={DeliveryStatisticsProduct}
    />
    {/* 로컬푸드 구독 끝 */}

    {/* 선물하기 시작 */}
    {/** 선물하기 설정 **/}
    <Route exact path="/giftConfigs" component={GiftProductList} />
    <Route exact path={["/giftConfigs/write", "/giftConfigs/:config/write"]}>
      <GiftProductList />
      <GiftConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/giftConfigs/:config/edit">
      <GiftProductList />
      <GiftConfigEdit isModal={isModal} />
    </Route>
    {/** 선물하기 분류 **/}
    <Route
      exact
      path={["/giftConfigs/:config/categories"]}
      component={GiftCategoryList}
    />
    <Route exact path="/giftConfigs/:config/categories/write">
      <GiftCategoryList />
      <GiftCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/giftConfigs/:config/categories/:id/write">
      <GiftCategoryList />
      <GiftCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/giftConfigs/:config/categories/:id/move">
      <GiftCategoryList />
      <GiftCategoryMove isModal={isModal} />
    </Route>
    <Route exact path="/giftConfigs/:config/categories/:id/edit">
      <GiftCategoryList />
      <GiftCategoryEdit isModal={isModal} />
    </Route>
    {/** 선물하기 **/}
    <Route
      exact
      path={["/giftConfigs", "/giftConfigs/:config/products"]}
      component={GiftProductList}
    />
    <Route exact path="/giftConfigs/:config/products/best">
      <GiftProductList />
      <GiftProductBest />
    </Route>
    <Route exact path="/giftConfigs/:config/products/new">
      <GiftProductList />
      <GiftProductNew />
    </Route>
    <Route exact path="/giftConfigs/:config/products/write">
      <GiftProductWrite />
    </Route>
    <Route
      exact
      path="/giftConfigs/:config/products/:id"
      component={GiftProductDetail}
    />
    <Route exact path="/giftConfigs/:config/products/:id/edit">
      <GiftProductEdit />
    </Route>
    {/** 선물하기 상품 옵션 **/}
    <Route exact path="/giftConfigs/:config/products/:id/productOptions/write">
      <GiftProductDetail />
      <GiftProductProductOptionWrite />
    </Route>
    <Route
      exact
      path="/giftConfigs/:config/products/:id/productOptions/:option/Edit"
    >
      <GiftProductDetail />
      <GiftProductProductOptionEdit />
    </Route>
    {/** 선물하기 상품 상세 옵션 **/}
    <Route
      exact
      path="/giftConfigs/:config/products/:id/productOptions/:option/details/write"
    >
      <GiftProductDetail />
      <GiftProductProductOptionDetailWrite />
    </Route>
    <Route
      exact
      path="/giftConfigs/:config/products/:id/productOptions/:option/details/:detail/edit"
    >
      <GiftProductDetail />
      <GiftProductProductOptionDetailEdit />
    </Route>
    {/** 선물하기 티켓 옵션 **/}
    <Route exact path="/giftConfigs/:config/products/:id/ticketOptions/write">
      <GiftProductDetail />
      <GiftProductTicketOptionWrite />
    </Route>
    <Route
      exact
      path="/giftConfigs/:config/products/:id/ticketOptions/:option/Edit"
    >
      <GiftProductDetail />
      <GiftProductTicketOptionEdit />
    </Route>
    {/** 선물하기 티켓 상세 옵션 **/}
    <Route
      exact
      path="/giftConfigs/:config/products/:id/ticketOptions/:option/details/write"
    >
      <GiftProductDetail />
      <GiftProductTicketOptionDetailWrite />
    </Route>
    <Route
      exact
      path="/giftConfigs/:config/products/:id/ticketOptions/:option/details/:detail/edit"
    >
      <GiftProductDetail />
      <GiftProductTicketOptionDetailEdit />
    </Route>
    {/** 선물하기 구매내역 **/}
    <Route exact path={"/giftOrders"} component={GiftOrderList} />
    <Route exact path={"/giftOrders/:id"} component={GiftOrderDetail} />
    {/** 선물하기 사용내역 **/}
    <Route exact path={"/giftOrderProducts"} component={GiftOrderProductList} />
    <Route
      exact
      path={"/giftOrderProducts/:id"}
      component={GiftOrderProductDetail}
    />
    <Route exact path={"/giftOrderProducts/:id/send"}>
      <GiftOrderProductDetail />
      <GiftOrderProductDetailSend />
    </Route>
    {/** 선물하기 정산내역 **/}
    <Route
      exact
      path={["/giftExchanges", "/giftExchanges/year/:year/month/:month"]}
      component={GiftExchangeList}
    />
    <Route
      exact
      path={"/giftExchanges/year/:year/month/:month/:id"}
      component={GiftExchangeDetail}
    />
    {/** 선물하기 리뷰 **/}
    <Route
      exact
      path={"/giftProductReviews"}
      component={GiftProductReviewList}
    />
    <Route
      exact
      path={"/giftProductReviews/:id"}
      component={GiftProductReviewDetail}
    />
    {/** 선물하기 문의 **/}
    <Route
      exact
      path={"/giftProductInquiries"}
      component={GiftProductInquiryList}
    />
    <Route
      exact
      path={"/giftProductInquiries/:id"}
      component={GiftProductInquiryDetail}
    />
    {/** 선물하기 배너 **/}
    <Route exact path={"/giftBanners"} component={GiftBannerList} />
    <Route exact path={"/giftBanners/write"} component={GiftBannerWrite} />
    <Route exact path={"/giftBanners/:id"} component={GiftBannerDetail} />
    <Route exact path={"/giftBanners/:id/edit"} component={GiftBannerEdit} />
    {/** 선물하기 통계 **/}
    <Route exact path={["/giftStatistics", "/giftStatistics/year/:year"]}>
      <GiftStatisticsYear />
    </Route>
    <Route exact path={"/giftStatistics/year/:year/orderRank"}>
      <GiftStatisticsYear />
      <GiftStatisticsYearOrderRank isModal={isModal} />
    </Route>
    <Route exact path={"/giftStatistics/year/:year/orderProductRank"}>
      <GiftStatisticsYear />
      <GiftStatisticsYearOrderProductRank isModal={isModal} />
    </Route>
    <Route exact path={"/giftStatistics/year/:year/month/:month"}>
      <GiftStatisticsMonth />
    </Route>
    <Route exact path={"/giftStatistics/year/:year/month/:month/orderRank"}>
      <GiftStatisticsMonth />
      <GiftStatisticsMonthOrderRank isModal={isModal} />
    </Route>
    <Route
      exact
      path={"/giftStatistics/year/:year/month/:month/orderProductRank"}
    >
      <GiftStatisticsMonth />
      <GiftStatisticsMonthOrderProductRank isModal={isModal} />
    </Route>
    <Route exact path={"/giftStatistics/year/:year/month/:month/day:day"}>
      <GiftStatisticsDay />
    </Route>
    <Route exact path={"/giftStatistics/year/:year/month/:month/day:day/rank"}>
      <GiftStatisticsDay />
      {/* <GiftStatisticsRank isModal={isModal} /> */}
    </Route>
    {/* 선물하기 끝 */}
    {/* 드림티켓 시작 */}
    {/** 드림티켓 설정 **/}
    <Route exact path="/ticketConfigs" component={TicketProductList} />
    <Route
      exact
      path={["/ticketConfigs/write", "/ticketConfigs/:config/write"]}
    >
      <TicketProductList />
      <TicketConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/ticketConfigs/:config/edit">
      <TicketProductList />
      <TicketConfigEdit isModal={isModal} />
    </Route>
    {/** 드림티켓 분류 **/}
    <Route
      exact
      path={["/ticketConfigs/:config/categories"]}
      component={TicketCategoryList}
    />
    <Route exact path="/ticketConfigs/:config/categories/write">
      <TicketCategoryList />
      <TicketCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/ticketConfigs/:config/categories/:id/write">
      <TicketCategoryList />
      <TicketCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/ticketConfigs/:config/categories/:id/move">
      <TicketCategoryList />
      <TicketCategoryMove isModal={isModal} />
    </Route>
    <Route exact path="/ticketConfigs/:config/categories/:id/edit">
      <TicketCategoryList />
      <TicketCategoryEdit isModal={isModal} />
    </Route>
    {/** 드림티켓 배너 **/}
    <Route exact path={"/ticketBanners"} component={TicketBannerList} />
    <Route exact path={"/ticketBanners/write"} component={TicketBannerWrite} />
    <Route exact path={"/ticketBanners/:id"} component={TicketBannerDetail} />
    <Route
      exact
      path={"/ticketBanners/:id/edit"}
      component={TicketBannerEdit}
    />
    {/** 드림티켓 **/}
    <Route
      exact
      path={["/ticketConfigs/:config/products/:commonStatus"]}
      component={TicketProductList}
    />
    <Route exact path="/ticketConfigs/:config/products/:commonStatus/write">
      <TicketProductWrite />
    </Route>
    <Route
      exact
      path="/ticketConfigs/:config/products/:commonStatus/:id"
      component={TicketProductDetail}
    />
    <Route exact path="/ticketConfigs/:config/products/:commonStatus/:id/edit">
      <TicketProductEdit />
    </Route>
    {/** 드림티켓 상품 옵션 **/}
    <Route
      exact
      path="/ticketConfigs/:config/products/:commonStatus/:id/ticketDates/write"
    >
      <TicketProductDetail />
      <TicketProductTicketDateWrite />
    </Route>
    <Route
      exact
      path="/ticketConfigs/:config/products/:commonStatus/:id/ticketDates/:ticketDateId/edit"
    >
      <TicketProductDetail />
      <TicketProductTicketDateEdit />
    </Route>
    {/** 드림티켓 상품 상세 옵션 **/}
    <Route
      exact
      path="/ticketConfigs/:config/products/:commonStatus/:id/ticketDates/:ticketDateId/ticketDateTimes/write"
    >
      <TicketProductDetail />
      <TicketProductTicketDateTimeWrite />
    </Route>
    <Route
      exact
      path="/ticketConfigs/:config/products/:commonStatus/:id/ticketDates/:ticketDateId/ticketDateTimes/:ticketDateTimeId/edit"
    >
      <TicketProductDetail />
      <TicketProductTicketDateTimeEdit />
    </Route>
    {/** 드림티켓 주문내역 **/}
    <Route exact path={"/ticketOrders"} component={TicketOrderList} />
    <Route exact path={"/ticketOrders/:id"} component={TicketOrderDetail} />
    {/** 드림티켓 티켓내역 **/}
    <Route
      exact
      path={"/ticketOrderProducts"}
      component={TicketOrderProductList}
    />
    <Route
      exact
      path={"/ticketOrderProducts/:id"}
      component={TicketOrderProductDetail}
    />
    {/** 선물하기 정산내역 **/}
    <Route
      exact
      path={["/ticketExchanges", "/ticketExchanges/year/:year/month/:month"]}
      component={TicketExchangeList}
    />
    <Route
      exact
      path={"/ticketExchanges/year/:year/month/:month/:id"}
      component={TicketExchangeDetail}
    />
    {/** 선물하기 리뷰 **/}
    <Route
      exact
      path={"/ticketProductReviews"}
      component={TicketProductReviewList}
    />
    <Route
      exact
      path={"/ticketProductReviews/:id"}
      component={TicketProductReviewDetail}
    />
    {/** 선물하기 문의 **/}
    <Route
      exact
      path={"/ticketProductInquiries"}
      component={TicketProductInquiryList}
    />
    <Route
      exact
      path={"/ticketProductInquiries/:id"}
      component={TicketProductInquiryDetail}
    />
    {/** 선물하기 통계 **/}
    <Route exact path={["/ticketStatistics", "/ticketStatistics/year/:year"]}>
      <TicketStatisticsYear />
    </Route>
    <Route exact path={"/ticketStatistics/year/:year/orderRank"}>
      <TicketStatisticsYear />
      <TicketStatisticsYearOrderRank isModal={isModal} />
    </Route>
    <Route exact path={"/ticketStatistics/year/:year/orderProductRank"}>
      <TicketStatisticsYear />
      <TicketStatisticsYearOrderProductRank isModal={isModal} />
    </Route>
    <Route exact path={"/ticketStatistics/year/:year/month/:month"}>
      <TicketStatisticsMonth />
    </Route>
    <Route exact path={"/ticketStatistics/year/:year/month/:month/orderRank"}>
      <TicketStatisticsMonth />
      <TicketStatisticsMonthOrderRank isModal={isModal} />
    </Route>
    <Route
      exact
      path={"/ticketStatistics/year/:year/month/:month/orderProductRank"}
    >
      <TicketStatisticsMonth />
      <TicketStatisticsMonthOrderProductRank isModal={isModal} />
    </Route>
    <Route exact path={"/ticketStatistics/year/:year/month/:month/day:day"}>
      <TicketStatisticsDay />
    </Route>
    <Route
      exact
      path={"/ticketStatistics/year/:year/month/:month/day:day/rank"}
    >
      <TicketStatisticsDay />
      {/* <TicketStatisticsRank isModal={isModal} /> */}
    </Route>
    {/* 드림티켓 끝 */}

    {/* 이벤트 설정 */}
    <Route exact path="/eventConfigs/write">
      <EventList />
      <EventConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/eventConfigs/:CommonApp" component={EventList} />
    <Route exact path="/eventConfigs/:CommonApp/:eventConfig/edit">
      <EventList />
      <EventConfigEdit isModal={isModal} />
    </Route>
    {/* 이벤트 */}
    <Route
      exact
      path={[
        "/eventConfigs/:CommonApp",
        "/eventConfigs/:CommonApp/:eventConfig",
        "/eventConfigs/:CommonApp/:eventConfig/events",
      ]}
      component={EventList}
    />
    <Route
      exact
      path="/eventConfigs/:CommonApp/:eventConfig/events/write"
      component={EventWrite}
    />
    <Route
      exact
      path="/eventConfigs/:CommonApp/:eventConfig/events/:id"
      component={EventDetail}
    />
    <Route
      exact
      path="/eventConfigs/:CommonApp/:eventConfig/events/:id/edit"
      component={EventEdit}
    />
    {/* 공지사항 설정 */}
    <Route exact path="/noticeConfigs/write">
      <NoticeList />
      <NoticeConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/noticeConfigs/:CommonApp" component={NoticeList} />
    <Route exact path="/noticeConfigs/:CommonApp/:noticeConfig/edit">
      <NoticeList />
      <NoticeConfigEdit isModal={isModal} />
    </Route>
    {/* 공지사항 */}
    <Route
      exact
      path={[
        "/noticeConfigs/:CommonApp",
        "/noticeConfigs/:CommonApp/:noticeConfig",
        "/noticeConfigs/:CommonApp/:noticeConfig/notices",
      ]}
      component={NoticeList}
    />
    <Route
      exact
      path="/noticeConfigs/:CommonApp/:noticeConfig/notices/write"
      component={NoticeWrite}
    />
    <Route
      exact
      path="/noticeConfigs/:CommonApp/:noticeConfig/notices/:id"
      component={NoticeDetail}
    />
    <Route
      exact
      path="/noticeConfigs/:CommonApp/:noticeConfig/notices/:id/edit"
      component={NoticeEdit}
    />
    {/* 도움말 설정 */}
    <Route exact path="/helpConfigs/write">
      <HelpList />
      <HelpConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/helpConfigs/:CommonApp" component={HelpList} />
    <Route exact path="/helpConfigs/:CommonApp/:helpConfig/edit">
      <HelpList />
      <HelpConfigEdit isModal={isModal} />
    </Route>
    {/* 도움말 분류 시작 */}
    <Route exact path="/helpConfigs/:CommonApp/:helpConfig/categories">
      <HelpList />
      <HelpCategoryList isModal={isModal} />
    </Route>
    <Route exact path="/helpConfigs/:CommonApp/:helpConfig/categories/write">
      <HelpList />
      <HelpCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/helpConfigs/:CommonApp/:helpConfig/categories/:id/edit">
      <HelpList />
      <HelpCategoryEdit isModal={isModal} />
    </Route>
    {/* 도움말 분류 끝 */}
    {/* 도움말 */}
    <Route
      exact
      path={[
        "/helpConfigs/:CommonApp",
        "/helpConfigs/:CommonApp/:helpConfig",
        "/helpConfigs/:CommonApp/:helpConfig/helps",
        "/helpConfigs/:CommonApp/:helpConfig/helps/categories",
        "/helpConfigs/:CommonApp/:helpConfig/helps/categories/:helpCategories",
      ]}
      component={HelpList}
    />
    <Route
      exact
      path="/helpConfigs/:CommonApp/:helpConfig/helps/write"
      component={HelpWrite}
    />
    <Route
      exact
      path="/helpConfigs/:CommonApp/:helpConfig/helps/:id"
      component={HelpDetail}
    />
    <Route
      exact
      path="/helpConfigs/:CommonApp/:helpConfig/helps/:id/edit"
      component={HelpEdit}
    />
    {/* 문의하기 설정 */}
    <Route exact path="/inquiryConfigs/write">
      <InquiryList />
      <InquiryConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/inquiryConfigs/:CommonApp" component={InquiryList} />
    <Route exact path="/inquiryConfigs/:CommonApp/:inquiryConfig/edit">
      <InquiryList />
      <InquiryConfigEdit isModal={isModal} />
    </Route>
    {/* 문의하기 분류 시작 */}
    <Route exact path="/inquiryConfigs/:CommonApp/:inquiryConfig/categories">
      <InquiryList />
      <InquiryCategoryList isModal={isModal} />
    </Route>
    <Route
      exact
      path="/inquiryConfigs/:CommonApp/:inquiryConfig/categories/write"
    >
      <InquiryList />
      <InquiryCategoryWrite isModal={isModal} />
    </Route>
    <Route
      exact
      path="/inquiryConfigs/:CommonApp/:inquiryConfig/categories/:id/edit"
    >
      <InquiryList />
      <InquiryCategoryEdit isModal={isModal} />
    </Route>
    {/* 문의하기 분류 끝 */}
    {/* 문의하기 */}
    <Route
      exact
      path={[
        "/inquiryConfigs/:CommonApp",
        "/inquiryConfigs/:CommonApp/:inquiryConfig",
        "/inquiryConfigs/:CommonApp/:inquiryConfig/inquiries",
        "/inquiryConfigs/:CommonApp/:inquiryConfig/inquiries/categories",
        "/inquiryConfigs/:CommonApp/:inquiryConfig/inquiries/categories/:inquiryCategories",
      ]}
      component={InquiryList}
    />
    <Route
      exact
      path="/inquiryConfigs/:CommonApp/:inquiryConfig/inquiries/:id"
      component={InquiryDetail}
    />
    <Route
      exact
      path="/inquiryConfigs/:CommonApp/:inquiryConfig/inquiries/:id/reply"
      component={InquiryReply}
    />
    {/* 기부 */}
    {/* 기부 분류 시작 */}
    <Route exact path="/donations/categories">
      <DonationList />
      <DonationCategoryList isModal={isModal} />
    </Route>
    <Route exact path="/donations/categories/write">
      <DonationList />
      <DonationCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/donations/categories/:id/edit">
      <DonationList />
      <DonationCategoryEdit isModal={isModal} />
    </Route>
    {/* 기부 분류 끝 */}
    <Route exact path="/donations" component={DonationList} />
    <Route exact path="/donations/write" component={DonationWrite} />
    <Route exact path="/donations/:id" component={DonationDetail} />
    <Route exact path="/donations/:id/edit" component={DonationEdit} />
    <Route exact path="/donations/:id/wallet/:wallet">
      <DonationDetail />
      <DonationTrustline isModal={isModal} />
    </Route>
    <Route
      exact
      path="/donations/:id/wallet/:wallet/trustlines/:trustline/remittance"
    >
      <DonationDetail />
      <DonationTrustlineRemittance isModal={isModal} />
    </Route>
    {/* 기부 이미지 */}
    <Route exact path="/donations/:id/images/write">
      <DonationDetail />
      <DonationImageWrite isModal={isModal} />
    </Route>
    <Route exact path="/donations/:id/images/:donationImage/edit">
      <DonationDetail />
      <DonationImageEdit isModal={isModal} />
    </Route>
    {/* 기부 끝 */}
    {/* 회비 */}
    <Route exact path="/fees" component={FeeList} />
    <Route exact path="/fees/write" component={FeeWrite} />
    <Route exact path="/fees/:id" component={FeeDetail} />
    <Route exact path="/fees/:id/edit" component={FeeEdit} />
    <Route
      exact
      path="/fees/:id/trustlines/:trustline"
      component={FeeTrustline}
    />
    <Route exact path="/fees/:id/trustlines/:trustline/remittance">
      <FeeTrustline />
      <FeeTrustlineRemittance isModal={isModal} />
    </Route>
    <Route exact path="/fees/:id/users" component={FeeUserList} />
    <Route exact path="/fees/:id/users/write">
      <FeeUserList />
      <FeeUserWrite isModal={isModal} />
    </Route>
    <Route exact path="/fees/:id/users/:detail">
      <FeeUserList />
      <FeeUserDetailList isModal={isModal} />
    </Route>

    <Route exact path="/fees/:id/monthFiles" component={FeeMonthFileList} />
    <Route exact path="/fees/:id/monthFiles/write">
      <FeeMonthFileList />
      <FeeMonthFileWrite isModal={isModal} />
    </Route>
    <Route
      exact
      path="/fees/:id/monthFiles/:feeMonthFileId"
      component={FeeMonthFileDetail}
    />
    <Route exact path="/fees/:id/monthFiles/:feeMonthFileId/preview">
      <FeeMonthFileDetail />
      <FeeMonthFilePreview isModal={isModal} />
    </Route>

    {/* 회비 끝 */}
    {/* 푸시 */}
    <Route exact path="/pushes" component={PushList} />
    <Route exact path="/pushes/fcm" component={PushFcm} />
    <Route exact path="/pushes/expo" component={PushExpo} />
    <Route exact path="/pushes/:id" component={PushDetail} />
    {/* 푸시 */}
    {/* 쿠폰 설정 */}
    <Route exact path="/couponConfigs" component={CouponList} />
    <Route exact path="/couponConfigs/write">
      <CouponList />
      <CouponConfigWrite isModal={isModal} />
    </Route>
    <Route exact path="/couponConfigs/:couponConfig/edit">
      <CouponList />
      <CouponConfigEdit isModal={isModal} />
    </Route>
    {/* 쿠폰 분류 시작 */}
    <Route exact path="/couponConfigs/:couponConfig/categories">
      <CouponList />
      <CouponCategoryList isModal={isModal} />
    </Route>
    <Route exact path="/couponConfigs/:couponConfig/categories/write">
      <CouponList />
      <CouponCategoryWrite isModal={isModal} />
    </Route>
    <Route exact path="/couponConfigs/:couponConfig/categories/:id/edit">
      <CouponList />
      <CouponCategoryEdit isModal={isModal} />
    </Route>
    {/* 쿠폰 분류 끝 */}
    {/* 쿠폰 */}
    <Route
      exact
      path={[
        "/couponConfigs",
        "/couponConfigs/:couponConfig",
        "/couponConfigs/:couponConfig/coupons",
        "/couponConfigs/:couponConfig/coupons/categories",
        "/couponConfigs/:couponConfig/coupons/categories/:couponCategories",
      ]}
      component={CouponList}
    />
    <Route
      exact
      path="/couponConfigs/:couponConfig/coupons/write"
      component={CouponWrite}
    />
    <Route
      exact
      path="/couponConfigs/:couponConfig/coupons/:id"
      component={CouponDetail}
    />
    {/* <Route
      exact
      path="/couponConfigs/:couponConfig/coupons/:id/edit"
      component={CouponEdit}
    /> */}
    <Route exact path="/couponConfigs/:couponConfig/coupons/:id/qrcode">
      <CouponDetail />
      <CouponQrcode isModal={isModal} />
    </Route>
    <Route exact path="/couponConfigs/:couponConfig/coupons/:id/editDefault">
      <CouponDetail />
      <CouponEditDefault isModal={isModal} />
    </Route>
    <Route exact path="/couponConfigs/:couponConfig/coupons/:id/editProduct">
      <CouponDetail />
      <CouponEditProduct isModal={isModal} />
    </Route>
    <Route exact path="/couponConfigs/:couponConfig/coupons/:id/editStores">
      <CouponDetail />
      <CouponEditStores isModal={isModal} />
    </Route>
    <Route
      exact
      path="/couponConfigs/:couponConfig/coupons/:id/user/:user/stores"
    >
      <CouponDetail />
      <CouponUserStores isModal={isModal} />
    </Route>
    {/* 쿠폰 */}
    {/* 통계 */}
    <Route exact path="/statistics" component={Statistics} />
    <Route exact path="/statistics/DNTIssue">
      <Statistics />
      <StatisticsDNTIssue isModal={isModal} />
    </Route>
    <Route exact path="/statistics/DNTUse">
      <Statistics />
      <StatisticsDNTUse isModal={isModal} />
    </Route>
    <Route exact path="/statistics/ChargeRequestUser">
      <Statistics />
      <StatisticsChargeRequestUser isModal={isModal} />
    </Route>
    <Route exact path="/statistics/ChargeRequestCMS">
      <Statistics />
      <StatisticsChargeRequestCMS isModal={isModal} />
    </Route>
    <Route exact path="/statistics/ChargeRequestNomal">
      <Statistics />
      <StatisticsChargeRequestNomal isModal={isModal} />
    </Route>
    <Route exact path="/statistics/Remittance">
      <Statistics />
      <StatisticsRemittance isModal={isModal} />
    </Route>
    <Route exact path="/statistics/UsersCharge">
      <Statistics />
      <StatisticsUsersCharge isModal={isModal} />
    </Route>
    <Route exact path="/statistics/UsersStoreOrder">
      <Statistics />
      <StatisticsUsersStoreOrder isModal={isModal} />
    </Route>
    <Route exact path="/statistics/UsersStoreReturn">
      <Statistics />
      <StatisticsUsersStoreReturn isModal={isModal} />
    </Route>
    <Route exact path="/statistics/UsersRemittance">
      <Statistics />
      <StatisticsUsersRemittance isModal={isModal} />
    </Route>
    <Route exact path="/statistics/UsersDonation">
      <Statistics />
      <StatisticsUsersDonation isModal={isModal} />
    </Route>
    <Route exact path="/statistics/UsersMinusStoreOrder">
      <Statistics />
      <StatisticsUsersMinusStoreOrder isModal={isModal} />
    </Route>
    <Route exact path="/statistics/Payment">
      <Statistics />
      <StatisticsPayment isModal={isModal} />
    </Route>
    <Route exact path="/statistics/StorePayment">
      <Statistics />
      <StatisticsStorePayment isModal={isModal} />
    </Route>
    <Route exact path="/statistics/StorePaymentHour">
      <Statistics />
      <StatisticsStorePaymentHour isModal={isModal} />
    </Route>
    <Route exact path="/statisticsMain" component={StatisticsMain} />
    <Route exact path="/statisticsDNT" component={StatisticsDNT} />
    <Route exact path="/statisticsDNT/IssueModal">
      <StatisticsDNT />
      <StatisticsDNTIssueModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsDNT/UseModal">
      <StatisticsDNT />
      <StatisticsDNTUseModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser" component={StatisticsUser} />
    <Route exact path="/statisticsUser/ChargeRequestUserModal">
      <StatisticsUser />
      <StatisticsUserChargeRequestUser isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/ChargeRequestCMSModal">
      <StatisticsUser />
      <StatisticsUserChargeRequestCMS isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/ChargeRequestNomalModal">
      <StatisticsUser />
      <StatisticsUserChargeRequestNomal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/RemittanceModal">
      <StatisticsUser />
      <StatisticsUserRemittanceModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/PaymentModal">
      <StatisticsUser />
      <StatisticsUserPaymentModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/UsersChargeModal">
      <StatisticsUser />
      <StatisticsUsersChargeModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/UsersStoreOrderModal">
      <StatisticsUser />
      <StatisticsUsersStoreOrderModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/UsersStoreReturnModal">
      <StatisticsUser />
      <StatisticsUsersStoreReturnModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/UsersRemittanceModal">
      <StatisticsUser />
      <StatisticsUsersRemittanceModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/UsersDonationModal">
      <StatisticsUser />
      <StatisticsUsersDonationModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsUser/UsersMinusStoreOrderModal">
      <StatisticsUser />
      <StatisticsUsersMinusStoreOrderModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsStore" component={StatisticsStore} />
    <Route exact path="/statisticsStore/StorePayment">
      <StatisticsStore />
      <StatisticsStorePaymentModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsStore/StorePaymentHour">
      <StatisticsStore />
      <StatisticsStorePaymentHourModal isModal={isModal} />
    </Route>
    <Route exact path="/statisticsGJP" component={StatisticsGJP} />
    <Route exact path="/statisticsTOL" component={StatisticsTOL} />
    <Route exact path="/StatisticsGRU" component={StatisticsGRU} />
    <Route exact path="/statisticsSTR" component={StatisticsSTR} />
    <Route exact path="/statisticsNDO" component={StatisticsNDO} />
    {/* 통계 */}

    <Redirect from="*" to="/dashboard" />
  </Switch>
);

export default AdminRoutes;
