import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_CONFIG_LIST_QUERY } from "../../Queries";
import InitPresenter from "./Presenter/Init";
import Container from "./Container";

export default withRouter(({ history, match }) => {
  const { data, loading } = useQuery(TICKET_CONFIG_LIST_QUERY);

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  if (!loading) {
    if (data.AdminTicketConfigList.count > 0) {
      const configs = data.AdminTicketConfigList.ticketConfigs;
      const config =
        match.params && match.params.config
          ? data.AdminTicketConfigList.ticketConfigs.find(
              (item) => item.id === match.params.config
            )
          : data.AdminTicketConfigList.ticketConfigs[0];
      return (
        <>
          <Container configs={configs} config={config}></Container>
        </>
      );
    } else {
      return (
        <>
          <InitPresenter></InitPresenter>
        </>
      );
    }
  }
});
