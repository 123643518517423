import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { DONATION_IMAGE_LIST_QUERY } from "../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { ButtonGroup, CardImg } from "reactstrap";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";

export default ({ history, match, location }) => {
  const { data, loading } = useQuery(DONATION_IMAGE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { donation: match.params.id },
  });

  return (
    <div key="productList" className="row">
      <div className="col-sm-12">
        {loading && (
          <div className="panel-loader">
            <span className="spinner-small"></span>
          </div>
        )}

        <Panel>
          <PanelHeader noButton={true}>
            <ButtonGroup className="pull-right">
              <Link
                className=" btn btn-primary btn-xs "
                to={`/donations/${match.params.id}/images/write${location.search}`}
              >
                등록
              </Link>
            </ButtonGroup>
            관련사진({!loading && data.AdminDonationImageList.count}건)
          </PanelHeader>
          {!loading && data.AdminDonationImageList.count > 0 ? (
            <PanelBody>
              <div className="product-list-wrap">
                <ul>
                  {data.AdminDonationImageList.donationImages.map(
                    (item, index) => {
                      const donationImage =
                        item.donationImage === ""
                          ? "/assets/img/user/user-default.jpg"
                          : `${graphqlUri}${item.donationImage}`;
                      return (
                        <li key={index} className="product-list">
                          <Link
                            to={`/donations/${match.params.id}/images/${item.id}/edit${location.search}`}
                          >
                            <div className="img">
                              <CardImg
                                top
                                src={donationImage}
                                alt={item.name}
                              />
                            </div>
                            <div className="product-list-cont">
                              <h4 className="tit">{item.name}</h4>
                              <div
                                className="desc"
                                style={{ height: 50, overflow: "hidden" }}
                              >
                                {item.description}
                              </div>
                              <div className="price-info">
                                <NumberFormat
                                  value={item.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={"원"}
                                />
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </PanelBody>
          ) : (
            <PanelBody>
              <p className="text-center">등록된 데이터가 없습니다.</p>
            </PanelBody>
          )}
        </Panel>
      </div>
    </div>
  );
};
