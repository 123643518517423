import React, { useState, useEffect } from "react";
import { COUPON_STORESWRITE_QUERY, COUPON_DETAIL_QUERY } from "../../Queries";
import { useMutation } from "react-apollo-hooks";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";
import { commonStatusName } from "../../../../../Components/User/UserNames";

const Presenter = ({
  history,
  match,
  location,

  id,

  keyword,
  setKeyword,
  handleSearch,

  data,
  loading,

  isModal,
}) => {
  const modalRef = React.createRef();

  const [couponStoresWriteMutation] = useMutation(COUPON_STORESWRITE_QUERY, {
    refetchQueries: () => [{ query: COUPON_DETAIL_QUERY, variables: { id } }],
  });
  const { register, handleSubmit } = useForm();

  const [keywordInput, setKeywordInput] = useState(keyword);
  const [checkItems, setCheckItems] = useState([]);

  const handleSelectCheck = (checked, item) => {
    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      let itemArray = [];
      data.AdminStoreList.stores.forEach((item) => {
        itemArray.push(item);
      });
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };

  const onSubmit = async (data) => {
    console.log({ data });
    try {
      if (checkItems.length === 0) {
        toast.error("추가할 수신자를 선택해주세요.");
        return;
      }
      const StoreIds = checkItems.map((item) => {
        return item.id;
      });

      const {
        data: { AdminCouponStoresWrite },
      } = await couponStoresWriteMutation({
        variables: {
          id,
          stores: StoreIds,
        },
      });
      if (AdminCouponStoresWrite) {
        toast.success("처리 되었습니다.");
        history.push(
          `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
              );
            }}
          >
            가맹점 목록 ({data.AdminStoreList.count}건)
          </ModalHeader>
          <ModalBody className="p-0">
            <div className="vertical-box with-grid inbox ">
              <div className="vertical-box-inner-cell overflow-visible height-md">
                <PerfectScrollbar
                  className="height-full p-15"
                  options={{ suppressScrollX: true }}
                >
                  <InputGroup className="p-b-15">
                    <Input
                      type="text"
                      className="input-white"
                      placeholder="검색어(가맹점명) 입력"
                      value={keywordInput}
                      onChange={(e) => {
                        setKeywordInput(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          setKeyword(keywordInput);
                          handleSearch();
                        }
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="white"
                        onClick={() => {
                          setKeyword(keywordInput);
                          handleSearch();
                        }}
                      >
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>상태</th>
                          <th>가맹점명 - 이름(아이디)</th>
                          <th>주소</th>
                          <th style={{ textAlign: "center" }}>
                            <input
                              type="checkbox"
                              onChange={(e) => handleAllCheck(e.target.checked)}
                              checked={
                                checkItems.length ===
                                data.AdminStoreList.stores.length
                              }
                            />
                          </th>
                        </tr>
                      </thead>
                      {!loading && data.AdminStoreList.count > 0 ? (
                        <tbody>
                          {data.AdminStoreList.stores.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{commonStatusName(item.commonStatus)}</td>
                                <td>
                                  {item.name} - {item.user.name}(
                                  {item.user.userId})
                                </td>
                                <td>
                                  ({item.storeZipcode}){item.storeJibunAddress}{" "}
                                  {item.storeAddressDetail}
                                </td>
                                <td align={"center"}>
                                  <input
                                    type="checkbox"
                                    name="stores"
                                    value={item.id}
                                    onChange={(e) =>
                                      handleSelectCheck(e.target.checked, item)
                                    }
                                    checked={
                                      checkItems.includes(item) ? true : false
                                    }
                                    ref={register()}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <p className="m-40 text-center">
                                등록된 사용자가 없습니다.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className=" btn btn-white text-primary">
              추가
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default Presenter;
