import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../Components/Panel/Panel";

import { commonStatusName, soldOutName } from "../../Components/User/UserNames";

export default withRouter(
  ({ history, location, match, deliveryProductOptions }) => {
    console.log({ deliveryProductOptions });
    const id = match.params.id;

    let optionCount = 0;
    let optionDetailCount = 0;

    return (
      <Panel>
        <PanelHeader noButton={true}>옵션 정보</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-sm-12">
              {deliveryProductOptions.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-bordered tablebasic product_op_table">
                    <caption className="sr-only">
                      상품 옵션-옵션명,옵션값,품절상태,기능
                    </caption>
                    <colgroup>
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th
                          abbr="옵션명"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          옵션명
                        </th>
                        <th
                          abbr="옵션값"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          옵션값
                        </th>
                        <th
                          abbr="옵션가격"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          옵션가격
                        </th>
                        <th
                          abbr="기능"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          기능
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {deliveryProductOptions.map((option) => {
                        optionDetailCount = 0;
                        return option.deliveryProductOptionDetails.map(
                          (optionDetail, index) => {
                            optionCount++;
                            optionDetailCount++;
                            return (
                              <tr key={index}>
                                {optionDetailCount === 1 && (
                                  <td
                                    rowSpan={
                                      option.deliveryProductOptionDetails.length
                                    }
                                    style={{
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                    }}
                                  >
                                    {option.commonStatus !== "C" && (
                                      <span className="badge badge-danger">
                                        {commonStatusName(option.commonStatus)}
                                      </span>
                                    )}{" "}
                                    {option.name}
                                    <br />
                                    <Link
                                      to={`/delivery/deliveryProducts/${id}/options/${option.id}/edit${location.search}`}
                                      className="btn btn-xs btn-white text-success m-r-5"
                                    >
                                      <i className="fa fa-check"></i> 옵션수정
                                    </Link>
                                    <Link
                                      to={`/delivery/deliveryProducts/${id}/options/${option.id}/details/write${location.search}`}
                                      className="btn btn-xs btn-white text-primary"
                                    >
                                      <i className="fa fa-plus"></i> 옵션값 추가
                                    </Link>
                                    {/* <button className="btn btn-xs btn-danger m-r-5">
                                      <i className="fa fa-trash"></i>{" "}
                                      삭제
                                    </button> */}
                                  </td>
                                )}

                                <td>
                                  {optionDetail.commonStatus !== "C" && (
                                    <span className="badge badge-danger">
                                      {commonStatusName(
                                        optionDetail.commonStatus
                                      )}
                                    </span>
                                  )}
                                  {optionDetail.name}{" "}
                                  {optionDetail.isSoldOut && (
                                    <span className="badge badge-danger">
                                      {soldOutName(optionDetail.isSoldOut)}
                                    </span>
                                  )}
                                </td>
                                <td>{optionDetail.price}</td>
                                <td
                                  className="text-center"
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Link
                                    to={`/delivery/deliveryProducts/${match.params.id}/options/${option.id}/details/${optionDetail.id}/edit${location.search}`}
                                    className="btn btn-xs btn-white text-success m-r-5"
                                  >
                                    <i className="fa fa-check"></i> 옵션값 수정
                                  </Link>
                                  {/* <button className="btn btn-xs btn-danger m-r-5">
                                    <i className="fa fa-trash"></i>{" "}
                                    삭제
                                  </button> */}
                                </td>
                              </tr>
                            );
                          }
                        );
                      })}
                    </tbody>
                  </table>

                  {deliveryProductOptions.length < 3 && (
                    <Link
                      to={`/delivery/deliveryProducts/${id}/options/write${location.search}`}
                      className="btn  btn-white text-primary btn-block  m-b-15"
                    >
                      <i className="fas fa-plus"></i> 옵션추가
                    </Link>
                  )}
                </div>
              ) : (
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    <div className="jumbotron nobottommargin text-center m-0">
                      <div className="feature-box fbox-center fbox-light fbox-plain">
                        <h3 style={{ fontSize: "28px" }}>
                          상품 옵션 정보를 입력해주세요.
                        </h3>
                        <p
                          style={{
                            fontSize: "13px",
                            lineHeight: "160%",
                          }}
                        >
                          상품 옵션 정보를 생성하셔야 상품을 등록 판매할 수
                          있습니다.
                          <br />
                          감사합니다.
                        </p>
                        <p>
                          <Link
                            to={`/delivery/deliveryProducts/${id}/options/write${location.search}`}
                            className="btn btn-white"
                          >
                            <i className="fa fa-plus"></i> 옵션 생성
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-12 p-t-10">
              <div className="text-left text-danger">
                <strong>
                  <span className="f-s-15">※ 주의사항(필독)</span>
                  <br />
                  * 옵션별 삭제시 관련 데이터가 삭제되므로 삭제보다는 해당
                  옵션을 [대기]로 상태값 변경을 권장합니다.
                  <br />* 옵션별 삭제시 고객이 해당 상품의 옵션을 구매한 경우
                  데이터 손실이 발생되어 삭제 신중히 하시길 바랍니다.
                </strong>
              </div>
            </div>
          </div>
        </PanelBody>
      </Panel>
    );
  }
);
