import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_CATEGORY_LIST_QUERY } from "../../../Queries";
import { InputGroup, InputGroupAddon } from "reactstrap";
import qs from "qs";

export default ({ history, match, location, config }) => {
  const queryString = qs.parse(location.search.substr(1));
  const keyword = queryString.keyword ? queryString.keyword : "";
  const category = queryString.category ? queryString.category : "";

  const { data, loading } = useQuery(GIFT_CATEGORY_LIST_QUERY, {
    variables: {
      giftConfig: config.id,
      parentId: "null",
    },
  });

  return (
    <InputGroup>
      {!loading && (
        <select
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            const baseUrl =
              "?" +
              (keyword ? "keyword=" + keyword + "&" : "") +
              (e.target.value ? "category=" + e.target.value + "&" : "");

            history.push(`/giftConfigs/${config.id}/products${baseUrl}page=1`);
          }}
          defaultValue={category}
        >
          <option value={""}>전체분류</option>
          {data.AdminGiftCategoryList.giftCategories.map((depth1, index1) => {
            return (
              <Fragment key={index1}>
                {depth1.childrenCount === 0 && (
                  <option value={depth1.id}>
                    {depth1.name}(
                    {depth1.giftProductsCount ? depth1.giftProductsCount : 0}건)
                  </option>
                )}
                {depth1.childrenCount > 0 &&
                  depth1.children.map((depth2, index2) => {
                    return (
                      <Fragment key={index2}>
                        {depth2.childrenCount === 0 && (
                          <option value={depth2.id}>
                            {depth1.name} &gt; {depth2.name}(
                            {depth2.giftProductsCount
                              ? depth2.giftProductsCount
                              : 0}
                            건)
                          </option>
                        )}
                        {depth2.childrenCount > 0 &&
                          depth2.children.map((depth3, index3) => {
                            return (
                              depth3.childrenCount === 0 && (
                                <option key={index3} value={depth3.id}>
                                  {depth1.name} &gt; {depth2.name} &gt;{" "}
                                  {depth3.name}(
                                  {depth3.giftProductsCount
                                    ? depth3.giftProductsCount
                                    : 0}
                                  건)
                                </option>
                              )
                            );
                          })}
                      </Fragment>
                    );
                  })}
              </Fragment>
            );
          })}
        </select>
      )}

      <InputGroupAddon addonType="append">
        <Link
          className={"btn btn-white"}
          to={`/giftConfigs/${config.id}/categories${location.search}`}
        >
          <i className={"fas fa-cogs"} />
        </Link>
      </InputGroupAddon>
    </InputGroup>
  );
};
