import { Fragment, forwardRef, useState } from "react";
import "../../assets/css/common/print.css";
import { dayOfWeekShortName, deliveryBoxTypeName } from "../User/UserNames";
import ReactMoment from "react-moment";
import QRCode from "qrcode.react";
import NumberFormat from "react-number-format";

const OrderPrint = forwardRef(({ data }, ref) => {
  return (
    <div className="page" ref={ref}>
      <div className="print">
        {data.AdminDeliveryOrderList.deliveryOrders
          .sort((a, b) => {
            if (a.type === b.type) {
              return `${a.roadAddress} ${a.addressDetail}`.localeCompare(
                `${b.roadAddress} ${b.addressDetail}`
              );
            } else {
              return a.type.localeCompare(b.type);
            }
          })
          .map((item, index) => {
            const date = new Date(item.deliverAt);
            const deliveryDayOfWeek = date.getDay();

            return (
              <Fragment key={index}>
                <div className="print-page">
                  <div>
                    <div className="print-page-header">
                      <strong className="font14">
                        [{deliveryBoxTypeName(item.type)}]
                        <ReactMoment format="YYYY-MM-DD">
                          {item.deliverAt}
                        </ReactMoment>
                        ({dayOfWeekShortName(deliveryDayOfWeek)})
                      </strong>
                    </div>
                    <div className="print-page-body">
                      <div className="qr-img">
                        <QRCode
                          size={110}
                          level={"H"}
                          style={{ width: 110, height: 110 }}
                          value={item.id}
                        />
                      </div>
                      <div>
                        <div className="font14">
                          {item.user.name}({item.user.userId})
                        </div>
                        <div className="font14">
                          주소 : ({item.zipcode}){item.roadAddress}{" "}
                          {item.addressDetail}
                        </div>
                        <div className="font14">
                          {item.user.role === "User" && (
                            <>전화번호 : {item.user.phone}</>
                          )}
                        </div>
                        <div className="font14">
                          합계 :{" "}
                          <NumberFormat
                            value={item.totalPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          드림
                        </div>
                      </div>
                    </div>
                    <div className="print-page-footer">
                      <ul className="order-product-ul">
                        {item.deliveryOrderProducts.map((product) => {
                          const option =
                            (product.deliveryProductOptionDetail1 ||
                              product.deliveryProductOptionDetail2 ||
                              product.deliveryProductOptionDetail3) &&
                            `${
                              product.deliveryProductOptionDetail1?.name &&
                              `${product.deliveryProductOptionDetail1?.name}`
                            }
                          ${
                            product.deliveryProductOptionDetail2?.name &&
                            `/${product.deliveryProductOptionDetail2?.name}`
                          }
                          ${
                            product.deliveryProductOptionDetail3?.name &&
                            `/${product.deliveryProductOptionDetail3?.name}`
                          }`;

                          const productString = `${
                            product.deliveryProduct.name
                          }${option ? `[${option}]` : ""}`;

                          return (
                            <li key={product.id}>
                              <div>
                                <div className="first">
                                  {productString} * {product.count}개
                                </div>
                                <div className="second">
                                  <NumberFormat
                                    value={product.totalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="pagebreak"></div>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
});

export default OrderPrint;
