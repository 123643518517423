import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "react-moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  transactionTypeName,
  currencyArray,
  currencyName
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
// import { graphqlUri } from "../../../../Config/Env";
// import { toast } from "react-toastify";
import qs from "qs";

export default ({
  history,
  match,
  location,
  user,
  trustline,
  data,
  year,
  month,
  prevYear,
  prevMonth,
  nextYear,
  nextMonth,

  isModal
}) => {
  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword, page: queryString.page };

  const modalRef = React.createRef();

  const {
    IssuerLedgerList: { ledgers, count }
  } = data;

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/users/${match.params.role}/${match.params.id}?${qs.stringify({
              ...query
            })}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/users/${match.params.role}/${match.params.id}?${qs.stringify({
                ...query
              })}`
            );
          }}
        >
          {user && user.IssuerUserDetail.name}님{" "}
          {trustline && currencyName(trustline.IssuerTrustlineDetail.currency)}{" "}
          목록
        </ModalHeader>
        <ModalBody>
          <div className="row m-b-15">
            <div className="col-xs-4 text-right">
              <Link
                to={`/users/${match.params.role}/${
                  match.params.id
                }/trustlines/${match.params.trustline}?${qs.stringify({
                  year: prevYear,
                  month: prevMonth,
                  ...query
                })}`}
                className="btn btn-white btn-xs text-green"
              >
                <i className="fa fa-angle-left" />
              </Link>
            </div>
            <div className="col-xs-4 text-center">
              {year}년 {month}월
            </div>
            <div className="col-xs-4 text-left">
              <Link
                to={`/users/${match.params.role}/${
                  match.params.id
                }/trustlines/${match.params.trustline}?${qs.stringify({
                  year: nextYear,
                  month: nextMonth,
                  ...query
                })}`}
                className="btn btn-white btn-xs text-green"
              >
                <i className="fa fa-angle-right" />
              </Link>
            </div>
          </div>

          <div className="widget widget-stats bg-green">
            <div className="stats-icon">
              <i className="fa fa-money-bill-alt"></i>
            </div>
            <div className="stats-info">
              <h4>합계</h4>
              <p>
                {trustline && (
                  <NumberFormat
                    value={
                      trustline.IssuerTrustlineDetail.amount -
                      currencyArray[trustline.IssuerTrustlineDetail.currency]
                        .startAmount
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                )}
                {trustline &&
                  currencyName(trustline.IssuerTrustlineDetail.currency)}
              </p>
            </div>
          </div>

          {count > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-b-0">
                  <thead>
                    <tr>
                      <th>형태</th>
                      <th>대상자</th>
                      <th>내용</th>
                      <th>일자</th>
                      <th>금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledgers.map(ledger => {
                      return (
                        <tr key={ledger.id}>
                          <td>{transactionTypeName(ledger.transactionType)}</td>
                          <td>{ledger.destinationWallet.userId}</td>
                          <td>{ledger.title}</td>
                          <td>
                            <Moment format="YYYY-MM-DD HH:mm:ss">
                              {ledger.createdAt}
                            </Moment>
                          </td>
                          <td align="right">
                            <NumberFormat
                              value={ledger.value}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            {currencyName(ledger.currency)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p className="text-center">
              {year}년 {month}월 등록된 데이터가 없습니다.
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="col-xs-4 text-left"></div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right p-0">
            <button
              type="button"
              className="btn btn-white text-red"
              onClick={() => {
                history.push(
                  `/users/${match.params.role}/${match.params.id}?${location.search}`
                );
              }}
            >
              닫기
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
