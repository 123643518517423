import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  currencyName,
  pastPeriod,
} from "../../../../../Components/User/UserNames";
import Moment from "moment";
import NumberFormat from "react-number-format";
import Store from "./ListStore";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "../../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  store,
  startSearchDate,
  endSearchDate,
  page,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  DownloadButton,
}) => {
  return (
    <>
      <Helmet>
        <title>거래내역 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/storeOrders">상점결제내역</Link>
          </li>
        </ol>
        <h1 className="page-header">상점결제내역</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminStoreOrderList.count}건)
                <div className="pull-right"></div>
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xl-12">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                  </div>
                </div>
                <Store
                  history={history}
                  match={match}
                  location={location}
                  store={store}
                  startSearchDate={startSearchDate}
                  endSearchDate={endSearchDate}
                />
              </Alert>
              <PanelBody>
                {!loading && data.AdminStoreOrderList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>가맹점</th>
                            <th>캐셔</th>
                            <th>주문번호</th>
                            <th>거래번호</th>
                            <th>금액</th>
                            <th>내역정보</th>
                            <th>거래일자</th>
                            <th>생성자</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminStoreOrderList.storeOrders.map(
                            (storeOrder) => {
                              return (
                                <tr key={storeOrder.id}>
                                  <td>{storeOrder.store.name}</td>
                                  <td>{`${storeOrder.cashier.name}(${storeOrder.cashier.userId})`}</td>
                                  <td>
                                    <ReactMoment format="YYMMDD-HHmmssSSS">
                                      {storeOrder.createdAt}
                                    </ReactMoment>
                                  </td>
                                  <td>
                                    {storeOrder.accountUserLedger ? (
                                      <ReactMoment format="YYMMDD-HHmmssSSS">
                                        {storeOrder.accountUserLedger.createdAt}
                                      </ReactMoment>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    <NumberFormat
                                      value={storeOrder.value}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    {currencyName(storeOrder.currency)}
                                  </td>
                                  <td>
                                    {storeOrder.accountUserLedger ? (
                                      <>{storeOrder.accountUserLedger.title}</>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {storeOrder.accountUserLedger ? (
                                      <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                        {storeOrder.accountUserLedger.createdAt}
                                      </ReactMoment>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {storeOrder.creator
                                      ? `${storeOrder.creator?.name}(
                                    ${storeOrder.creator?.userId})`
                                      : `-`}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
              {!loading && data.AdminStoreOrderList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.AdminStoreOrderList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
