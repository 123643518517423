import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { USER_LIST_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(
  ({
    history,
    match,
    location,
    isVisible,
    toggleModal,
    isDeviceToken,
    isPosDeviceToken,
    users,
    setUsers,
  }) => {
    const [keyword, setKeyword] = useState("");

    const { data, loading, refetch } = useQuery(USER_LIST_QUERY, {
      variables: {
        commonStatus: "C",
        isDeviceToken,
        isPosDeviceToken,
        keyword,
      },
    });

    const handleSearch = () => {
      refetch();
    };

    if (!loading) {
      return (
        <Presenter
          history={history}
          match={match}
          location={location}
          isVisible={isVisible}
          toggleModal={toggleModal}
          keyword={keyword}
          setKeyword={setKeyword}
          handleSearch={handleSearch}
          users={users}
          setUsers={setUsers}
          data={data}
          loading={loading}
        ></Presenter>
      );
    } else {
      return (
        <div className="panel-loader">
          <span className="spinner-small"></span>
        </div>
      );
    }
  }
);
