import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { withRouter, Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useMutation } from "react-apollo-hooks"
import { GIFT_PRODUCT_WRITE_QUERY, COMMONFILE_WRITE_QUERY } from "../../Queries"
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel"
// import { Editor } from "@tinymce/tinymce-react";
import Editor from "../../../../../Components/Editor/Editor"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList } from "@fortawesome/pro-regular-svg-icons"
import { toast } from "react-toastify"

import SearchGiftCategory from "../SearchGiftCategory"
import SearchStore from "../SearchStore"

export default withRouter(({ history, match, location, config }) => {
  const configId = match.params.config
  const timestamp = String(new Date().getTime())

  const [store, setStore] = useState("")
  const [giftCategory, setGiftCategory] = useState("")

  const [giftProductWriteMutation] = useMutation(GIFT_PRODUCT_WRITE_QUERY)
  const [commonFileWriteMutation] = useMutation(COMMONFILE_WRITE_QUERY)

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    defaultValues: {
      discountPrice: 0,
      price: 0,
      limit: 0,
    },
  })

  const onSubmit = async (data) => {
    if (giftCategory === "") {
      return toast.error("분류를 선택해주세요.")
    }
    if (store === "") {
      return toast.error("가맹점명을 입력해주세요.")
    }
    if (data.price === "") {
      return toast.error("가격를 입력해주세요.")
    }
    if (data.isDiscount === "true" && data.discountPrice === "") {
      return toast.error("할인금액를 입력해주세요.")
    }
    if (config.productType === "Ticket" && data.limit === "") {
      return toast.error("정원을 입력해주세요.")
    }
    if (config.productType === "Ticket" && data.schedule === "") {
      return toast.error("일정을 입력해주세요.")
    }
    if (config.productType === "Ticket" && data.place === "") {
      return toast.error("장소을 입력해주세요.")
    }
    if (data.name === "") {
      return toast.error("상품명을 입력해주세요.")
    }
    if (data.contents === "") {
      return toast.error("상세 정보를 입력해주세요.")
    }
    try {
      const {
        data: { AdminGiftProductWrite },
      } = await giftProductWriteMutation({
        variables: {
          giftConfig: configId,
          giftCategory: giftCategory && giftCategory.value,
          store: store && store.value,
          isDiscount: data.isDiscount === "true" ? true : false,
          discountPrice: data.discountPrice,
          price: data.price,
          name: data.name,
          contents: data.contents,
          timestamp,
          limit: data.limit ? data.limit : "0",
          schedule: data.schedule,
          place: data.place,
        },
      })
      if (AdminGiftProductWrite) {
        toast.success("처리 되었습니다.")
        history.push(`/giftConfigs/${configId}/products/${location.search}`)
      }
    } catch (e) {
      console.log(e)
      var error = e.toString()
      error = error.replace("Error: GraphQL error:", "")
      toast.error(`${error}`)
    }
  }
  // console.log(errors);

  const descriptionText = {
    content: `
      <dl>
        <dt>생산자</dt>
        <dd></dd>
      </dl>
      <dl>
        <dt>중량</dt>
        <dd></dd>
      </dl>
      <dl>
        <dt>포장타입</dt>
        <dd></dd>
      </dl>
      <dl>
        <dt>비고</dt>
        <dd></dd>
      </dl>
  `,
  }
  return (
    <>
      <Helmet>
        <title>상품 | 한밭페이</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">상품</li>
          </ol>
          <h1 className="page-header">상품</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/giftConfigs/${configId}/products${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>작성</PanelHeader>
                <PanelBody className="panel-form form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">분류</label>
                    <div className="col-md-4 select">
                      <SearchGiftCategory
                        history={history}
                        match={match}
                        location={location}
                        giftCategory={giftCategory}
                        setGiftCategory={setGiftCategory}
                      />
                      {errors.productCategory && (
                        <span>{errors.productCategory.message}</span>
                      )}
                    </div>
                    <label className="col-form-label col-md-2">가맹점</label>
                    <div className="col-md-4 select">
                      <SearchStore
                        history={history}
                        match={match}
                        location={location}
                        store={store}
                        setStore={setStore}
                      />
                      {errors.store && <span>{errors.store.message}</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">가격</label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="price"
                        className="form-control"
                        ref={register()}
                        placeholder="가격을 입력해주세요."
                      />
                      {errors.price && <span>{errors.price.message}</span>}
                    </div>
                    <label className="col-form-label col-md-2">
                      <input
                        type="checkbox"
                        name="isDiscount"
                        value={true}
                        ref={register()}
                      />
                      할인
                    </label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="discountPrice"
                        className="form-control"
                        ref={register()}
                        placeholder="할인금액을 입력해주세요."
                      />
                      {errors.isDiscount && (
                        <span>{errors.isDiscount.message}</span>
                      )}
                      {errors.discountPrice && (
                        <span>{errors.discountPrice.message}</span>
                      )}
                    </div>
                  </div>
                  {config.productType === "Ticket" && (
                    <>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">정원</label>
                        <div className="col-md-10">
                          <input
                            type="number"
                            name="limit"
                            className="form-control"
                            ref={register()}
                            placeholder="정원을 입력해주세요."
                          />
                          {errors.limit && <span>{errors.limit.message}</span>}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">일정</label>
                        <div className="col-md-10">
                          <input
                            type="text"
                            name="schedule"
                            className="form-control"
                            ref={register()}
                            placeholder="ex) 2021.8.20 (금) 18:00 ~ 20:00"
                          />
                          {errors.schedule && (
                            <span>{errors.schedule.message}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">장소</label>
                        <div className="col-md-10">
                          <input
                            type="text"
                            name="place"
                            className="form-control"
                            ref={register()}
                            placeholder="ex) 대전광역시 중구 중앙로79번길 47, 101호(선화동)"
                          />
                          {errors.place && <span>{errors.place.message}</span>}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">상품명</label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        ref={register()}
                        placeholder="상품명을 입력해주세요."
                      />
                      {errors.name && <span>{errors.name.message}</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">상세 정보</label>
                    <div className="col-md-10">
                      <Editor
                        configId={configId}
                        timestamp={timestamp}
                        value={
                          watch("contents") || `${descriptionText.content}`
                        }
                        setValue={setValue}
                      />
                      <textarea
                        name="contents"
                        className="form-control hide"
                        rows="10"
                        placeholder="상세 정보를 입력해주세요."
                        ref={register()}
                      ></textarea>
                      {errors.contents && (
                        <span>{errors.contents.message}</span>
                      )}
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/giftConfigs/${configId}/products${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
})
