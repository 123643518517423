import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  TICKET_ORDER_DETAIL_QUERY,
  TICKET_ORDER_REFUND_QUERY,
} from "./Queries";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../Config/Env";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(TICKET_ORDER_DETAIL_QUERY, {
    variables: { id },
  });
  const [orderProductRefundMutation] = useMutation(TICKET_ORDER_REFUND_QUERY, {
    refetchQueries: () => [
      {
        query: TICKET_ORDER_DETAIL_QUERY,
        variables: {
          id,
        },
      },
    ],
  });
  const onRefund = async (id, ticketOrderProductId) => {
    console.log("onRefund");
    if (!window.confirm("선물을 환불하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminTicketOrderRefund },
      } = await orderProductRefundMutation({
        variables: { id, ticketOrderProductId },
      });

      if (AdminTicketOrderRefund) {
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    const {
      AdminTicketOrderDetail: {
        id,
        ticketOrderProducts,

        // commonStatus,
        user,
        receiver,
        ticketProduct,
        // isDiscount,
        discountPrice,
        price,

        ticketProductOptionDetail1,
        // ticketProductOptionDetailPrice1,
        ticketProductOptionDetail2,
        // ticketProductOptionDetailPrice2,
        ticketProductOptionDetail3,
        // ticketProductOptionDetailPrice3,
        ticketProductOptionDetail4,
        // ticketProductOptionDetailPrice4,
        ticketProductOptionDetail5,
        // ticketProductOptionDetailPrice5,

        count,

        // totalCount,
        totalPrice,

        message,

        isRefund,
        // refundReceiver,
        refundTotalCount,
        refundTotalPrice,

        createdAt,
        // updatedAt,
      },
    } = data;

    const CoverImage =
      ticketProduct.coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${ticketProduct.coverImage}`;

    let OptionString = "";
    let OptionPrice = 0;
    if (ticketProductOptionDetail1) {
      OptionString += ` ${
        ticketProductOptionDetail1.name
      }(+${ticketProductOptionDetail1.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += ticketProductOptionDetail1.price;
      if (ticketProductOptionDetail2) {
        OptionString += ` / `;
      }
    }
    if (ticketProductOptionDetail2) {
      OptionString += ` ${
        ticketProductOptionDetail2.name
      }(+${ticketProductOptionDetail2.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += ticketProductOptionDetail2.price;
      if (ticketProductOptionDetail3) {
        OptionString += ` / `;
      }
    }
    if (ticketProductOptionDetail3) {
      OptionString += ` ${
        ticketProductOptionDetail3.name
      }(+${ticketProductOptionDetail3.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += ticketProductOptionDetail3.price;
      if (ticketProductOptionDetail4) {
        OptionString += ` / `;
      }
    }
    if (ticketProductOptionDetail4) {
      OptionString += ` ${
        ticketProductOptionDetail4.name
      }(+${ticketProductOptionDetail4.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += ticketProductOptionDetail4.price;
      if (ticketProductOptionDetail5) {
        OptionString += ` / `;
      }
    }
    if (ticketProductOptionDetail5) {
      OptionString += ` ${
        ticketProductOptionDetail5.name
      }(+${ticketProductOptionDetail5.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += ticketProductOptionDetail5.price;
    }

    const commonStatus1 = ticketOrderProducts.filter((ticketOrderProduct) => {
      const toDay = new Date();
      const endDateObj = new Date(ticketOrderProduct.endDate);
      return ticketOrderProduct.commonStatus === "S" && toDay <= endDateObj;
    });
    const commonStatus2 = ticketOrderProducts.filter((ticketOrderProduct) => {
      const toDay = new Date();
      const endDateObj = new Date(ticketOrderProduct.endDate);
      return ticketOrderProduct.commonStatus === "S" && toDay > endDateObj;
    });
    const commonStatus3 = ticketOrderProducts.filter((ticketOrderProduct) => {
      return (
        ticketOrderProduct.commonStatus === "C" &&
        ticketOrderProduct.isRefund === true
      );
    });
    const commonStatus4 = ticketOrderProducts.filter((ticketOrderProduct) => {
      return (
        ticketOrderProduct.commonStatus === "C" &&
        ticketOrderProduct.isRefund === false
      );
    });
    return (
      <>
        <Helmet>
          <title>드림상점 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">드림상점</li>
          </ol>
          <h1 className="page-header">드림상점</h1>
          <div className="row m-b-15">
            <div className="col-md-6 text-left">
              <Link
                className="btn btn-white"
                to={`/ticketOrders${location.search}`}
              >
                <i className="fa fa-list fa-fw"></i> 목록
              </Link>
            </div>
            <div className="col-md-6 text-right"></div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>구매정보</PanelHeader>
                <PanelBody className=" p-0">
                  <div className="invoice order-product">
                    <div className="invoice-company text-inverse f-w-600">
                      <span className="pull-right hidden-print">
                        <Link
                          className="btn btn-sm btn-white  text-primary"
                          to={`/stores/${ticketProduct.store.id}`}
                        >
                          <i className="fa fa-store-alt fa-fw" /> 가맹점 정보
                        </Link>
                      </span>
                      {ticketProduct.store.name}
                    </div>
                    <div className="order-product-list">
                      <div className="img">
                        <img src={CoverImage} alt={ticketProduct.name} />
                      </div>
                      <div className="cont">
                        <strong>
                          <Link
                            to={`/ticketConfigs/${ticketProduct.ticketConfig.id}/products/${ticketProduct.id}`}
                          >
                            {ticketProduct.name}
                          </Link>
                        </strong>
                        {OptionString !== "" && (
                          <div className="option">
                            추가옵션 : {OptionString}
                          </div>
                        )}
                        <div className="price">
                          <NumberFormat
                            displayType={"text"}
                            value={price}
                            thousandSeparator={true}
                            suffix={"드림"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="invoice-header">
                      <div className="invoice-from p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">구매정보</strong>
                          <ul className="common-status">
                            <li>
                              구 매 자 :{" "}
                              <Link to={`/users/${user.role}/${user.id}`}>
                                {user.name}({user.userId})
                              </Link>
                            </li>
                            <li>
                              구매일자 :{" "}
                              <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                {createdAt}
                              </ReactMoment>
                            </li>
                            <li>
                              수 신 자 :{" "}
                              {receiver.map((item) => {
                                return (
                                  <>
                                    <Link
                                      to={`/users/${item.role}/${item.id}`}
                                      className="m-r-10"
                                    >
                                      {item.name}({item.userId})
                                    </Link>
                                  </>
                                );
                              })}
                            </li>
                            <li>메 시 지 : {message ? message : "없음"}</li>
                            <li className="column">
                              <p>
                                전체수량 : {count}개 * {receiver.length}명 =
                                <b>{count * receiver.length}개</b>
                              </p>
                              <p>(수량 + 수신인원 = 전체수량)</p>
                            </li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-to p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">선물사용현황</strong>
                          <ul className="common-status">
                            <li>
                              <div className="left">사용가능</div>
                              <div className="right">
                                <span>{commonStatus1.length}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">기간만료</div>
                              <div className="right">
                                <span>{commonStatus2.length}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">환불완료</div>
                              <div className="right">
                                <span>{commonStatus3.length}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">사용완료</div>
                              <div className="right">
                                <span>{commonStatus4.length}</span>개
                              </div>
                            </li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-date p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="date text-inverse m-t-5">
                            가격정보
                          </strong>
                          <ul className="common-status">
                            <li>
                              <div className="right">
                                상품가격 :
                                <NumberFormat
                                  displayType={"text"}
                                  value={price - discountPrice + OptionPrice}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                            </li>
                            <li>
                              <div className="right">
                                전체수량 :
                                <NumberFormat
                                  displayType={"text"}
                                  value={count * receiver.length}
                                  thousandSeparator={true}
                                />
                                개
                              </div>
                            </li>
                            <li>
                              <div className="right">
                                총 결제 금액 :
                                <NumberFormat
                                  displayType={"text"}
                                  value={totalPrice}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                            </li>
                          </ul>
                        </address>
                      </div>
                    </div>
                    {/* 가격정보 */}
                    <div className="invoice-price">
                      <div className="invoice-price-left">
                        <div className="invoice-price-row">
                          <div className="sub-price">
                            <small>상품가격</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={price}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-minus text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>할인가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={discountPrice}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-plus text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>옵션가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={OptionPrice}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-price-right">
                        <small>상품가격</small>
                        <span className="f-w-600">
                          <NumberFormat
                            displayType={"text"}
                            value={price - discountPrice + OptionPrice}
                            thousandSeparator={true}
                            suffix={"드림"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>선물내역</PanelHeader>
                <PanelBody className="">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "*" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="text-center">상태</th>
                          <th className="text-center">유효기간 (연장)</th>
                          <th className="text-center">주문번호</th>
                          <th className="text-center">수신자</th>
                          <th className="text-center">수신일자</th>
                          <th className="text-center">금액</th>
                          <th className="text-center">기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketOrderProducts.map((item, index) => {
                          const toDay = new Date();
                          const endDateObj = new Date(item.endDate);
                          let refundAbleDate = new Date(item.startDate);
                          refundAbleDate.setDate(refundAbleDate.getDate() + 90);

                          // let refundAbleDate = new Date(item.endDate);
                          // refundAbleDate.setDate(refundAbleDate.getDate() + 90);
                          // let extensionAbleDate = new Date(item.endDate);
                          // extensionAbleDate.setDate(
                          //   extensionAbleDate.getDate() - 360
                          // extensionAbleDate.getDate() - 7
                          // );

                          return (
                            <tr key={index}>
                              <td className="text-center">
                                {item.commonStatus === "S" && (
                                  <>
                                    {toDay <= endDateObj ? (
                                      <span className="gray">사용가능</span>
                                    ) : (
                                      <span className="gray">기간만료</span>
                                    )}
                                  </>
                                )}
                                {item.commonStatus === "C" && (
                                  <>
                                    {item.isRefund === true && (
                                      <span className="danger">환불완료</span>
                                    )}
                                    {item.isRefund === false && (
                                      <span className="primary">사용완료</span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="text-center">
                                <ReactMoment format="YYYY-MM-DD">
                                  {item.endDate}
                                </ReactMoment>{" "}
                                (연장:{item.extensionCount}회)
                              </td>
                              <td className="text-center">
                                <ReactMoment format="YYMMDD-HHmmssSSS">
                                  {item.createdAt}
                                </ReactMoment>
                              </td>
                              <td className="text-center">
                                <Link
                                  to={`/users/${item.user.role}/${item.user.id}`}
                                >
                                  {item.user.name}({item.user.userId})
                                </Link>
                              </td>
                              <td className="text-center">
                                <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                  {item.createdAt}
                                </ReactMoment>
                              </td>
                              <td className="text-right">
                                <NumberFormat
                                  displayType={"text"}
                                  value={item.totalPrice}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </td>
                              <td className="text-center">
                                <Link
                                  to={`/ticketOrderProducts/${item.id}`}
                                  className="btn btn-white btn-xs text-primary"
                                >
                                  상세정보
                                </Link>{" "}
                                {item.commonStatus === "S" &&
                                toDay <= refundAbleDate ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-white btn-xs text-danger"
                                      onClick={() => onRefund(id, item.id)}
                                    >
                                      환불하기
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-white btn-xs text-danger disabled"
                                    >
                                      환불하기
                                    </button>
                                  </>
                                )}{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Alert color="danger" className="m-b-0">
                    <strong className="f-s-15">※ 주의사항(필독)</strong>
                    <br /> * 환불하기 : 구매 후 최초 유효기간 90일 이내에
                    구매자에게 환불됩니다.
                  </Alert>
                </PanelFooter>
              </Panel>
            </div>
            {isRefund && (
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>환불정보</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="invoice">
                      <div className="invoice-price">
                        <div className="invoice-price-left">
                          <div className="invoice-price-row">
                            <div className="sub-price">
                              <small>상품가격</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  displayType={"text"}
                                  value={totalPrice}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </span>
                            </div>
                            <div className="sub-price">
                              <i className="fa fa-minus text-muted"></i>
                            </div>
                            <div className="sub-price">
                              <small>수량</small>
                              <span className="text-inverse">
                                {refundTotalCount}개
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="invoice-price-right">
                          <small>상품가격</small>
                          <span className="f-w-600">
                            <NumberFormat
                              displayType={"text"}
                              value={refundTotalPrice}
                              thousandSeparator={true}
                              suffix={"드림"}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-4 text-left">
              <Link
                className="btn btn-white"
                to={`/ticketOrders${location.search}`}
              >
                <i className="fa fa-list fa-fw"></i> {""}
                목록
              </Link>
            </div>
            <div className="col-md-4 text-center"></div>
            <div className="col-md-4 text-right"></div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
