import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { dayOfWeekName } from "../../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_ADDRESS_DELETE_MUTATION,
  ADMIN_DELIVERY_ADDRESS_LIST_QUERY,
} from "../../Queries";
import Swal from "sweetalert2";

export default ({ history, match, location, isModal, data, baseUrl }) => {
  const modalRef = React.createRef();
  const id = match.params.id;

  const [
    AdminDeliveryAddressDeleteMutation,
    { loading: AdminDeliveryAddressDeleteLoading },
  ] = useMutation(ADMIN_DELIVERY_ADDRESS_DELETE_MUTATION, {
    refetchQueries: [
      {
        query: ADMIN_DELIVERY_ADDRESS_LIST_QUERY,
        variables: { deliveryBox_id: id, take: 100, skip: 0 },
      },
    ],
  });
  const onDelete = async (id) => {
    try {
      if (AdminDeliveryAddressDeleteLoading) {
        return;
      }

      Swal.fire({
        icon: "warning",
        title: "주의",
        text: "주소를 삭제 하시겠습니까?",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "취소",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const {
            data: { AdminDeliveryAddressDelete },
          } = await AdminDeliveryAddressDeleteMutation({
            variables: { id },
          });

          if (AdminDeliveryAddressDelete) {
            Swal.fire({
              icon: "info",
              title: "안내",
              text: "처리 되었습니다.",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "확인",
              focusConfirm: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`${baseUrl}/addresses${location.search}`);
              }
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`${baseUrl}${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`${baseUrl}${location.search}`);
          }}
        >
          주소 목록
        </ModalHeader>
        <ModalBody>
          {data.AdminDeliveryAddressList.count > 0 ? (
            <div className={"widget-list widget-list-rounded m-b-15"}>
              {data.AdminDeliveryAddressList.deliveryAddresses.map(
                (item, index) => {
                  return (
                    <div className="widget-list-item" key={index}>
                      <div className="widget-list-content">
                        <h4 className="widget-list-title">{item.name}</h4>
                        <div className="widget-list-desc">
                          <div>도로명 주소 : {item.roadAddress}</div>
                          <div>지번주소 : {item.jibunAddress}</div>
                          <div>상세주소 : {item.addressDetail}</div>
                          <div>배달요일 : {dayOfWeekName(item.dayOfWeek)}</div>
                        </div>
                      </div>
                      <div
                        className="widget-list-action"
                        style={{ width: "50%" }}
                      >
                        <div className="pull-right">
                          <Link
                            className="btn btn-white btn-xs m-l-5"
                            to={`${baseUrl}/addresses/${item.id}/edit`}
                          >
                            수정
                          </Link>
                          <button
                            className={`btn btn-white btn-xs m-l-5`}
                            disabled={item.childrenCount > 0 ? true : false}
                            onClick={() => onDelete(item.id)}
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div className="row">
              <div className="col-xs-12 p-40">
                <p className="text-center">등록된 데이터가 없습니다.</p>
              </div>
            </div>
          )}

          <Link
            to={`${baseUrl}/addresses/write`}
            className="btn btn-default btn-block  m-b-15"
          >
            <i className="fas fa-plus"></i> 추가
          </Link>
        </ModalBody>
      </Modal>
    </>
  );
};
