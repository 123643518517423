import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { DONATION_DETAIL_QUERY, DONATION_DELETE_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../Config/Env";
import ImageList from "./Image/List";
import { toast } from "react-toastify";
import nl2br from "react-nl2br";

export default withRouter(({ history, match, location }) => {
  const { data: detail, loading } = useQuery(DONATION_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  const [donationDeleteMutation] = useMutation(DONATION_DELETE_QUERY);
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminDonationDelete },
      } = await donationDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminDonationDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/donations${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && detail && detail.AdminDonationDetail) {
    const {
      AdminDonationDetail: {
        id,
        DonationCategory,
        title,
        contents,
        planner,
        plannerTel,
        plannerUseSNS,
        plannerSNS,
        goalAmount,
        startDate,
        endDate,
        detailImg,
        totalDRM,
        totalDNC,
        commonStatus,
        createdAt,
        wallet,
      },
    } = detail;
    const donationDetailImage =
      detailImg === "" || detailImg === null
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUri}${detailImg}`;
    return (
      <>
        <Helmet>
          <title>기부 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">기부</li>
          </ol>
          <h1 className="page-header">기부</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/donations${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  to={`/donations/${id}/wallet/${wallet.id}${location.search}`}
                  className="btn btn-white text-success"
                >
                  내역
                </Link>
                <Link
                  className="btn btn-white text-success"
                  to={`/donations/${id}/edit${location.search}`}
                >
                  수정
                </Link>
                {totalDNC + totalDRM === 0 && (
                  <Button
                    type="button"
                    color="white"
                    className="text-red"
                    onClick={() => {
                      onDelete(id);
                    }}
                  >
                    삭제
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>상세내용</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="p-20">
                    <img
                      src={donationDetailImage}
                      alt="이미지 업로드"
                      className="width-200"
                    />
                  </div>
                  <div className="wrapper">
                    {commonStatus === "C" ? (
                      <span className="label label-primary">공개</span>
                    ) : (
                      <span className="label label-danger">비공개</span>
                    )}
                    <h3 className="m-t-10 f-w-500">{title}</h3>
                    <p>
                      <b>작성일 :</b>
                      <Moment format="YYYY-MM-DD HH:mm:ss">{createdAt}</Moment>
                    </p>
                  </div>
                  <div className="">
                    <div className="invoice bg-grey-transparent-5">
                      <div className="info_ul">
                        <ul>
                          <li>
                            <b>프로젝트 분류 :</b>
                            {DonationCategory.name}
                          </li>
                          <li>
                            <b>프로젝트 기간 : </b>
                            <Moment format="YYYY-MM-DD">{startDate}</Moment>~
                            <Moment format="YYYY-MM-DD">{endDate}</Moment>
                          </li>
                          <li>
                            <b>프로젝트 관리자 :</b>
                            {planner}
                          </li>
                          <li>
                            <b>프로젝트 관리자 연락처 :</b>
                            {plannerTel}
                          </li>
                          {plannerUseSNS === "C" ? (
                            <li>
                              <b>프로젝트 관리자 SNS :</b> {plannerSNS}
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                      <div className="invoice-price bg-white m-b-0 rounded">
                        <div className="invoice-price-left">
                          <div className="invoice-price-row">
                            <div className="sub-price">
                              <small className="f-s-15">드림</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  value={totalDRM}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={"원"}
                                />
                              </span>
                            </div>
                            <div className="sub-price">
                              <i className="fa fa-plus text-muted"></i>
                            </div>
                            <div className="sub-price">
                              <small className="f-s-15">드림 포인트</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  value={totalDNC}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={"원"}
                                />
                              </span>
                            </div>
                            <div className="sub-price">
                              <i className="fa fa-equals text-muted"></i>
                            </div>
                            <div className="sub-price">
                              <small className="f-s-15">총 금액</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  value={totalDNC + totalDRM}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={"원"}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="invoice-price-right">
                          <small className="f-s-15">목표 금액</small>{" "}
                          <span className="f-w-600">
                            <NumberFormat
                              value={goalAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={"원"}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-20">
                    <p className="f-s-12 text-inverse">{nl2br(contents)}</p>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <ImageList history={history} match={match} location={location} />
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/donations${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  to={`/donations/${id}/wallet/${wallet.id}${location.search}`}
                  className="btn btn-white text-success"
                >
                  내역
                </Link>
                <Link
                  className="btn btn-white text-success"
                  to={`/donations/${id}/edit${location.search}`}
                >
                  수정
                </Link>
                {totalDNC + totalDRM === 0 && (
                  <Button
                    type="button"
                    color="white"
                    className="text-red"
                    onClick={() => {
                      onDelete(id);
                    }}
                  >
                    삭제
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
