import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { WALLET_LIST_QUERY } from "../../Queries";
import AsyncSelect from "react-select/async";

export default ({
  history,
  match,
  location,
  currency,
  transactionType,
  role,
  startSearchDate,
  endSearchDate,
  wallet,
}) => {
  const [keyword, setKeyword] = useState("");
  const { data: wallets } = useQuery(WALLET_LIST_QUERY, {
    variables: { keyword, skip: 0, first: 10 },
  });

  const loadOptions = (inputValue, callback) => {
    if (wallets && wallets.AdminWalletList) {
      var options = wallets.AdminWalletList.wallets.map((wallet) => {
        return {
          value: wallet.id,
          label: `${wallet.name}${wallet.userId ? `(${wallet.userId})` : ``}`,
        };
      });

      callback(options);
    }
  };
  const handleInputChange = (value) => {
    setTimeout(() => {
      setKeyword(value);
      return value;
    }, 500);
  };
  return (
    <AsyncSelect
      placeholder="사용자 이름 입력"
      noOptionsMessage={() => {
        return "사용자 이름을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={({ label, value }) => {
        const ledgersUrl =
          "?" +
          (transactionType ? "transactionType=" + transactionType + "&" : "") +
          (role ? "role=" + role + "&" : "") +
          (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
          (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
          (value ? "wallet=" + value + "&" : "");
        history.replace(
          `/ledgers/${
            match.params.Currency ? match.params.Currency : ""
          }${ledgersUrl}`
        );
      }}
      defaultValue=""
    />
  );
};
