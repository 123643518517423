import { gql } from "apollo-boost";

export const EXCHANGEREQUEST_CONFIG_QUERY = gql`
  query AdminExchangeRequestConfig($id: String) {
    AdminExchangeRequestConfig(id: $id) {
      id
      name
      manager {
        id
        role
        userId
        name
      }
    }
  }
`;
export const USER_LIST_QUERY = gql`
  query AdminUserList($keyword: String, $skip: Int, $first: Int) {
    AdminUserList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        userId
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const EXCHANGEREQUEST_CONFIG_WRITE_QUERY = gql`
  mutation AdminExchangeRequestConfigWrite($name: String!, $manager: String!) {
    AdminExchangeRequestConfigWrite(name: $name, manager: $manager)
  }
`;

export const EXCHANGEREQUEST_CONFIG_EDIT_QUERY = gql`
  mutation AdminExchangeRequestConfigEdit(
    $id: String
    $name: String!
    $manager: String!
  ) {
    AdminExchangeRequestConfigEdit(id: $id, name: $name, manager: $manager)
  }
`;

export const EXCHANGEREQUEST_LIST_QUERY = gql`
  query AdminExchangeRequestList(
    $currency: String
    $commonStatus: String
    $startSearchDate: String
    $endSearchDate: String
    $bank_name: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminExchangeRequestList(
      currency: $currency
      commonStatus: $commonStatus
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      bank_name: $bank_name
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      exchangeRequests {
        id
        commonStatus
        user {
          id
          role
          userId
          name
          store {
            id
            name
          }
        }
        wallet {
          id
        }

        currency
        amount

        bank_name
        bank_num
        bank_account_holder

        createdAt
        updatedAt
      }
      count
      total
    }
  }
`;

export const EXCHANGEREQUEST_EDIT_COMMONSTATUS_QUERY = gql`
  mutation AdminExchangeRequestEditCommonStatus(
    $id: String!
    $commonStatus: String!
  ) {
    AdminExchangeRequestEditCommonStatus(id: $id, commonStatus: $commonStatus)
  }
`;

export const EXCHANGEREQUEST_DELETE_QUERY = gql`
  mutation AdminExchangeRequestDelete($id: String!) {
    AdminExchangeRequestDelete(id: $id)
  }
`;
