import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";
import SearchProduct from "./SearchProduct";
import Moment from "moment";
import {
  giftOrderProductStatusArray,
  pastPeriod,
} from "../../../../Components/User/UserNames";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  page,
  giftProduct,
  status,
  startSearchDate,
  endSearchDate,
  keyword,
  keywordInput,
  blockSize,
  first,
  skip,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  handleSearch,
}) => {
  const Status = () => {
    const urlConfig = (status) => {
      const url =
        "?" +
        (giftProduct ? "giftProduct=" + giftProduct + "&" : "") +
        (status ? "status=" + status + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (keyword ? "keyword=" + keyword + "&" : "");
      return url;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white ${!status ? "active" : ""}
    `}
          to={`/giftOrderProducts${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체(주문일자 기준)
        </Link>
        {Object.keys(giftOrderProductStatusArray).map((item, index) => {
          return (
            <Link
              key={item}
              className={`btn btn-white ${status === item ? "active" : ""}
            `}
              to={`/giftOrderProducts${urlConfig(item)}`}
            >
              {giftOrderProductStatusArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>상품내역 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림상점</li>
          <li className="breadcrumb-item active">상품내역</li>
        </ol>
        <h1 className="page-header">상품내역</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminGiftOrderProductList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xs-12 select">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                  </div>
                </div>
                <div className="row m-b-15">
                  <div className="col-xs-12 select">
                    <SearchProduct giftProduct={giftProduct} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <Status />
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(구매자, 수신자) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody className="">
                {!loading && data.AdminGiftOrderProductList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col style={{ width: "15%" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "15%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">이미지</th>
                            <th className="text-center">
                              상품정보 (정가 - 할인가 + 옵션가 = 상품가격)
                            </th>
                            <th className="text-center">사용정보</th>
                            <th className="text-center">수신정보</th>
                            <th className="text-center">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminGiftOrderProductList.giftOrderProducts.map(
                            (item, index) => {
                              const CoverImage =
                                item.giftProduct.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.giftProduct.coverImage}`;

                              let OptionString = "";
                              let OptionPrice = 0;
                              if (item.giftProductOptionDetail1) {
                                OptionString += ` ${
                                  item.giftProductOptionDetail1.name
                                }(${
                                  item.giftProductOptionDetail1.price > 0
                                    ? "+"
                                    : ""
                                }${item.giftProductOptionDetail1.price.toLocaleString(
                                  "ko-KR",
                                  {
                                    maximumFractionDigits: 4,
                                  }
                                )}드림)`;
                                OptionPrice +=
                                  item.giftProductOptionDetail1.price;
                                if (item.giftProductOptionDetail2) {
                                  OptionString += ` / `;
                                }
                              }
                              if (item.giftProductOptionDetail2) {
                                OptionString += ` ${
                                  item.giftProductOptionDetail2.name
                                }(${
                                  item.giftProductOptionDetail2.price > 0
                                    ? "+"
                                    : ""
                                }${item.giftProductOptionDetail2.price.toLocaleString(
                                  "ko-KR",
                                  {
                                    maximumFractionDigits: 4,
                                  }
                                )}드림)`;
                                OptionPrice +=
                                  item.giftProductOptionDetail2.price;
                                if (item.giftProductOptionDetail3) {
                                  OptionString += ` / `;
                                }
                              }
                              if (item.giftProductOptionDetail3) {
                                OptionString += ` ${
                                  item.giftProductOptionDetail3.name
                                }(${
                                  item.giftProductOptionDetail3.price > 0
                                    ? "+"
                                    : ""
                                }${item.giftProductOptionDetail3.price.toLocaleString(
                                  "ko-KR",
                                  {
                                    maximumFractionDigits: 4,
                                  }
                                )}드림)`;
                                OptionPrice +=
                                  item.giftProductOptionDetail3.price;
                                if (item.giftProductOptionDetail4) {
                                  OptionString += ` / `;
                                }
                              }
                              if (item.giftProductOptionDetail4) {
                                OptionString += ` ${
                                  item.giftProductOptionDetail4.name
                                }(${
                                  item.giftProductOptionDetail4.price > 0
                                    ? "+"
                                    : ""
                                }${item.giftProductOptionDetail4.price.toLocaleString(
                                  "ko-KR",
                                  {
                                    maximumFractionDigits: 4,
                                  }
                                )}드림)`;
                                OptionPrice +=
                                  item.giftProductOptionDetail4.price;
                                if (item.giftProductOptionDetail5) {
                                  OptionString += ` / `;
                                }
                              }
                              if (item.giftProductOptionDetail5) {
                                OptionString += ` ${
                                  item.giftProductOptionDetail5.name
                                }(${
                                  item.giftProductOptionDetail5.price > 0
                                    ? "+"
                                    : ""
                                }${item.giftProductOptionDetail5.price.toLocaleString(
                                  "ko-KR",
                                  {
                                    maximumFractionDigits: 4,
                                  }
                                )}드림)`;
                                OptionPrice +=
                                  item.giftProductOptionDetail5.price;
                              }

                              const toDay = new Date();
                              const endDateObj = new Date(item.endDate);
                              const gap =
                                endDateObj.getTime() - toDay.getTime();
                              const dDay = Math.ceil(
                                gap / (1000 * 60 * 60 * 24)
                              );

                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    <div
                                      className="product-img"
                                      style={{
                                        backgroundImage: `url(${CoverImage})`,
                                      }}
                                    >
                                      <img
                                        src={CoverImage}
                                        alt={item.giftProduct.name}
                                      />
                                      {item.commonStatus === "S" && (
                                        <span className="day badge bg-yellow text-black">
                                          {dDay}일
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      가 맹 점 : {item.giftProduct.store.name}
                                    </div>
                                    <div>
                                      상 품 명 : <b>{item.giftProduct.name}</b>
                                    </div>
                                    {OptionString !== "" && (
                                      <div>추가옵션 : {OptionString}</div>
                                    )}
                                    <div>
                                      상품가격 :
                                      <NumberFormat
                                        displayType={"text"}
                                        value={item.price}
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                      />{" "}
                                      -{" "}
                                      <NumberFormat
                                        displayType={"text"}
                                        value={item.discountPrice}
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                      />{" "}
                                      + (
                                      <NumberFormat
                                        displayType={"text"}
                                        value={OptionPrice}
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                      />
                                      ) ={" "}
                                      <b>
                                        <NumberFormat
                                          displayType={"text"}
                                          value={
                                            item.price -
                                            item.discountPrice +
                                            OptionPrice
                                          }
                                          thousandSeparator={true}
                                          suffix={"드림"}
                                        />
                                      </b>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      상 태 :{" "}
                                      {item.commonStatus === "S" && (
                                        <>
                                          {toDay <= endDateObj ? (
                                            <span className="gray">
                                              사용가능
                                            </span>
                                          ) : (
                                            <span className="gray">
                                              기간만료
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {item.commonStatus === "C" && (
                                        <>
                                          {item.isRefund === true && (
                                            <span className="danger">
                                              환불완료
                                            </span>
                                          )}
                                          {item.isRefund === false && (
                                            <span className="primary">
                                              사용완료
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      유효기간(연장) :{" "}
                                      <ReactMoment format="YYYY-MM-DD">
                                        {item.endDate}
                                      </ReactMoment>{" "}
                                      ({item.extensionCount}회)
                                    </div>
                                    <div>
                                      주문번호 :{" "}
                                      <ReactMoment format="YYMMDD-HHmmssSSS">
                                        {item.createdAt}
                                      </ReactMoment>
                                    </div>
                                    {item.commonStatus === "C" && (
                                      <>
                                        {item.isRefund === true && (
                                          <>
                                            환불일시 :
                                            <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                              {item.updatedAt}
                                            </ReactMoment>
                                          </>
                                        )}
                                        {item.isRefund === false && (
                                          <>
                                            사용일시 :
                                            <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                              {item.updatedAt}
                                            </ReactMoment>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td className="text-left">
                                    <div>
                                      구 매 자 : {item.giftOrder.user.name}(
                                      {item.giftOrder.user.userId})
                                    </div>
                                    <div>
                                      발 신 자 : {item.sender.name}(
                                      {item.sender.userId})
                                    </div>
                                    <div>
                                      수 신 자 : {item.user.name}(
                                      {item.user.userId})
                                    </div>
                                    <div>
                                      수신일자 :{" "}
                                      <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                        {item.createdAt}
                                      </ReactMoment>
                                    </div>
                                    <div>
                                      메 시 지 :{" "}
                                      {item.giftOrder.message
                                        ? item.giftOrder.message
                                        : "없음"}
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <Link
                                      to={`/giftOrderProducts/${item.id}${location.search}`}
                                      className="btn btn-white btn-xs text-primary"
                                    >
                                      상세정보
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.AdminGiftOrderProductList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.AdminGiftOrderProductList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
