import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_STATISTICS_PRODUCT_QUERY } from "../Queries";

export default withRouter(({ history, match, location }) => {
  const year = match.params.year;
  const month = match.params.month;

  const baseUrl = `/deliveryStatistics/product/${year}/${month}`;

  const { data, loading, error } = useQuery(
    STORE_DELIVERY_STATISTICS_PRODUCT_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        year,
        month,
      },
    }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      year={year}
      month={month}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
