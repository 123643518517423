import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_DNT_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faReceipt,
  faMoneyBill,
  faSackDollar,
  faCreditCardBlank,
  faHeart,
  faAddressCard,
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import PerfectScrollbar from "react-perfect-scrollbar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import DatePicker from "../../../Containers/SelectedPeriod/DatePicker";
import { pastPeriod } from "../../../Components/User/UserNames";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const startDate = queryString.startDate
    ? queryString.startDate
    : Moment().subtract(1, "months").format("YYYY-MM-DD");
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().format("YYYY-MM-DD");

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const { data, loading } = useQuery(STATISTICS_DNT_QUERY, {
    variables: {
      startDate,
      endDate,
    },
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      AdminStatisticsDNT: {
        DNTTotal,
        DNTExchangeTotal,
        DNTWithdrawalTotal,
        DNTUserTotal,
        DNTDonationTotal,
        DNTIssueCount,
        DNTIssueTotal,
        DNTUseCount,
        DNTUseTotal,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>통계 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/statisticsUser">통계</Link>
            </li>
            <li className="breadcrumb-item active">드림P</li>
          </ol>
          <h1 className="page-header">드림P</h1>

          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">전체</b>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">총 발행량</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTExchangeTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">전체환전금액 (관리자)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTUserTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">
                    이용자 보유금 (관리자 제외)
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTWithdrawalTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">탈퇴자</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTDonationTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">기부완료금</div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-xl-12">
              <DatePicker
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                onStartDateSelect={onStartDateSelect}
                onEndDateSelect={onEndDateSelect}
                onPeriodConfig={onPeriodConfig}
                period={pastPeriod}
              />
            </div>
          </div>
          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">기간(거래내역 기준)</b>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statisticsDNT/IssueModal?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={DNTIssueTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTIssueCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">발행금액(건)</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statisticsDNT/UseModal?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={DNTUseTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTUseCount}명)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">기부금액(인원)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
