import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_SET_LIST_QUERY } from "../Queries";

import qs from "qs";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const keyword = queryString.keyword ? queryString.keyword : "";
  const page = queryString.page ? +queryString.page : 1;
  const take = 15;
  const baseUrl = "/delivery/deliverySets";

  const { data, loading, error } = useQuery(STORE_DELIVERY_SET_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take,
      skip: (page - 1) * take,
      keyword,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    var message = error.toString();
    message = message.replace("Error: GraphQL error:", "");
    return <>{message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      keyword={keyword}
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
