import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  COUPON_DETAIL_QUERY,
  COUPON_DELETE_QUERY,
  COUPON_STORESDELETE_QUERY,
} from "../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { ButtonGroup, Button } from "reactstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";
import { toast } from "react-toastify";
import nl2br from "react-nl2br";
import { commonStatusName } from "../../../../Components/User/UserNames";
import DetailUserList from "./DetailUserList";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(COUPON_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: id },
  });

  const [couponDeleteMutation] = useMutation(COUPON_DELETE_QUERY, {
    refetchQueries: () => [{ query: COUPON_DETAIL_QUERY, variables: { id } }],
  });
  const onDelete = async () => {
    try {
      const {
        data: { AdminCouponDelete },
      } = await couponDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminCouponDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/couponConfigs/${match.params.couponConfig}/coupons${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const [couponStoresDeleteMutation] = useMutation(COUPON_STORESDELETE_QUERY, {
    refetchQueries: () => [{ query: COUPON_DETAIL_QUERY, variables: { id } }],
  });
  const onStoresDelete = async (store) => {
    try {
      const {
        data: { AdminCouponStoresDelete },
      } = await couponStoresDeleteMutation({
        variables: {
          id: id,
          store: store,
        },
      });
      if (AdminCouponStoresDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminCouponDetail) {
    const {
      AdminCouponDetail: {
        id,
        commonStatus,
        startDate,
        endDate,
        title,
        contents,
        limit,
        price,
        store,
        productName,
        productDetail,
        productImage,
        stores,
        isDuplication,
        users,
        couponCategory,
      },
    } = data;
    const couponProductImage =
      productImage === ""
        ? "/assets/img/gallery/gallery-default.jpg"
        : `${graphqlUri}${productImage}`;

    return (
      <>
        <Helmet>
          <title>쿠폰 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">도움말</li>
          </ol>
          <h1 className="page-header">쿠폰</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/couponConfigs/${match.params.couponConfig}/coupons${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className=" btn btn-white text-green "
                  to={`/couponConfigs/${match.params.couponConfig}/coupons/${id}/qrcode${location.search}`}
                >
                  QRCode
                </Link>
                {users.length === 0 && (
                  <Button
                    type="button"
                    color="white"
                    className="text-red"
                    onClick={() => {
                      onDelete();
                    }}
                  >
                    삭제
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <Panel>
                <PanelHeader noButton={true}>
                  <ButtonGroup className="pull-right">
                    <Link
                      className="btn btn-white btn-xs text-success"
                      to={`/couponConfigs/${match.params.couponConfig}/coupons/${id}/editDefault${location.search}`}
                    >
                      수정
                    </Link>
                  </ButtonGroup>
                  기본정보
                </PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">분류</label>
                      <div className="col-md-4">{couponCategory.name}</div>
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-4">
                        {commonStatusName(commonStatus)}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">기간</label>
                      <div className="col-md-10">
                        <Moment format="YYYY-MM-DD HH:mm:ss">
                          {startDate}
                        </Moment>{" "}
                        ~<Moment format="YYYY-MM-DD HH:mm:ss">{endDate}</Moment>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        가맹점 중복
                      </label>
                      <div className="col-md-10">
                        {isDuplication === true && "중복"}
                        {isDuplication === false && "미중복"}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">인원</label>
                      <div className="col-md-4">
                        <NumberFormat
                          value={limit}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        명
                      </div>
                      <label className="col-form-label col-md-2">금액</label>
                      <div className="col-md-4">
                        <NumberFormat
                          value={price}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">가맹점</label>
                      <div className="col-md-10">{store.name}</div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">{title}</div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">내용</label>
                      <div className="col-md-10">{nl2br(contents)}</div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3">
              <Panel>
                <PanelHeader noButton={true}>
                  <ButtonGroup className="pull-right">
                    <Link
                      className="btn btn-white btn-xs text-success"
                      to={`/couponConfigs/${match.params.couponConfig}/coupons/${id}/editProduct${location.search}`}
                    >
                      수정
                    </Link>
                  </ButtonGroup>
                  상품정보
                </PanelHeader>
                <PanelBody>
                  <div className="form-group form-bordered row m-b-0">
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">이름</label>
                        <div className="col-md-9 m-t-10">{productName}</div>
                      </div>

                      <div className="form-group row">
                        <label className="col-form-label col-md-3">설명</label>
                        <div className="col-md-9 m-t-10">{productDetail}</div>
                      </div>
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={couponProductImage}
                            alt="이미지 업로드"
                            className="height-200"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>

            <div className="col-md-6">
              <Panel>
                <PanelHeader noButton={true}>
                  <ButtonGroup className="pull-right">
                    <Link
                      className="btn btn-white btn-xs text-primary"
                      to={`/couponConfigs/${match.params.couponConfig}/coupons/${id}/editStores${location.search}`}
                    >
                      추가
                    </Link>
                  </ButtonGroup>
                  참여 가맹점 정보({stores.length}건)
                </PanelHeader>
                <PanelBody className="p-0">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>상태</th>
                          <th>
                            가맹점명
                            <br />
                            이름(아이디)
                          </th>
                          <th>주소</th>
                          <th>기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stores.length > 0 ? (
                          <>
                            {stores.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{commonStatusName(item.commonStatus)}</td>
                                  <td>
                                    {item.name}
                                    <br />
                                    {item.user.name}({item.user.userId})
                                  </td>
                                  <td>
                                    ({item.storeZipcode})
                                    <br />
                                    {item.storeJibunAddress}{" "}
                                    {item.storeAddressDetail}
                                  </td>
                                  <td>
                                    <Button
                                      type="button"
                                      color="white"
                                      className="text-red btn-xs"
                                      onClick={() => {
                                        onStoresDelete(item.id);
                                      }}
                                    >
                                      삭제
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={3}>
                              <p className="m-40 text-center">
                                참여 가맹점이 없습니다.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-6">
              <DetailUserList id={id} limit={limit} users={users} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/couponConfigs/${match.params.couponConfig}/coupons${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className=" btn btn-white text-green "
                  to={`/couponConfigs/${match.params.couponConfig}/coupons/${id}/qrcode${location.search}`}
                >
                  QRCode
                </Link>
                {users.length === 0 && (
                  <Button
                    type="button"
                    color="white"
                    className="text-red"
                    onClick={() => {
                      onDelete();
                    }}
                  >
                    삭제
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
