import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  pastPeriod,
  pushTypeArray,
  pushTypeName,
} from "../../../../Components/User/UserNames";
import Moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  pushType,
  startSearchDate,
  endSearchDate,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  handleSearch,
}) => {
  const PushType = () => {
    const urlConfig = (pushType) => {
      const uri =
        "?" +
        (pushType ? "pushType=" + pushType + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");
      return uri;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!pushType ? "active" : ""}
    `}
          to={`/pushes${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>
        {Object.keys(pushTypeArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                pushType === item ? "active" : ""
              }
            `}
              to={`/pushes${urlConfig(item)}`}
            >
              {pushTypeArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>알림 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">알림</li>
        </ol>
        <h1 className="page-header">알림</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록(
                {!loading && data.AdminPushList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xs-12 select">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                  </div>
                </div>
                <PushType />
                <form onSubmit={handleSearch}>
                  <InputGroup>
                    <Input
                      type="text"
                      className="input-white"
                      placeholder="검색어(제목, 내용) 입력"
                      value={keywordInput.value}
                      onChange={keywordInput.onChange}
                    />
                    <InputGroupAddon addonType="append">
                      <Button type="submit" color="white">
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </form>
              </Alert>
              <PanelBody>
                {!loading && data.AdminPushList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            {/* <th>상태</th> */}
                            <th>분류</th>
                            <th>제목</th>
                            <th>현황(전체/읽음)</th>
                            <th>발송일시</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminPushList.pushes.map((push) => {
                            return (
                              <tr key={push.id}>
                                {/* <td>{commonStatusName(push.commonStatus)}</td> */}
                                <td>{pushTypeName(push.pushType)}</td>
                                <td>
                                  <Link to={`/pushes/${push.id}`}>
                                    {push.notificationTitle}
                                  </Link>
                                </td>
                                <td>
                                  {push.totalCount}건 / {push.readCount}건
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {push.createdAt}
                                  </ReactMoment>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row p-t-15">
                      <div className="col-md-12">
                        <Pagination
                          totalRecord={data.AdminPushList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <div className="btn-scroll-to-top">
        <Link
          to={`/pushes/fcm${location.search}`}
          className="btn btn-icon btn-circle btn-lg btn-primary m-l-10"
        >
          <i className="fas fa-mobile"></i>
        </Link>
        <Link
          to={`/pushes/expo${location.search}`}
          className="btn btn-icon btn-circle btn-lg btn-primary m-l-10"
        >
          <i className="fas fa-store"></i>
        </Link>
      </div>
    </>
  );
};
