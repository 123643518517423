import React from "react";
import styled, { css } from "styled-components";
import { graphqlUri } from "../../Config/Env";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo-hooks";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import OnlineEditor from "ckeditor5-custom-build/build/ckeditor";

/* 상품 이미지 */
export const COMMONFILE_WRITE_QUERY = gql`
  mutation StoreCommonFileWrite(
    $moduleId: String!
    $targetType: String!
    $timestamp: String!
    $commonFile: CommonFileUpload!
  ) {
    StoreCommonFileWrite(
      moduleType: "ticketConfig"
      moduleId: $moduleId
      moduleTable: "ticketProduct"
      targetType: $targetType
      timestamp: $timestamp
      commonFile: $commonFile
    ) {
      id
      moduleType
      moduleId
      moduleTable
      targetType
      timestamp

      filepath
      filename
      mimetype
      encoding

      createdAt
      updatedAt
    }
  }
`;

const EditorWrapper = styled.div`
  ${({ theme }) => {
    return css`
      // .ck.ck-editor {
      //   max-width: 500px;
      // }
      .ck-editor__editable {
        min-height: 700px;
      }
    `;
  }}
`;

const Editor = ({ configId, timestamp, value, setValue }) => {
  const [commonFileWriteMutation] = useMutation(COMMONFILE_WRITE_QUERY);

  const uploadFile = async (targetType, file) => {
    try {
      const {
        data: { StoreCommonFileWrite: commonFile },
      } = await commonFileWriteMutation({
        variables: {
          moduleId: configId,
          targetType,
          timestamp,
          commonFile: file,
        },
      });

      return commonFile;
    } catch (e) {
      console.log(e);
    }
  };

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then(async (file) => {
            try {
              const commonFile = await uploadFile("contents", file);
              console.log({ commonFile });
              const data = `${graphqlUri}${commonFile.filepath}`;
              resolve({
                default: data,
              });
            } catch (e) {
              reject(e);
              console.log(e);
            }
          });
        });
      },
    };
  };

  const uploadPlugin = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  };

  return (
    <EditorWrapper>
      <CKEditor
        className="editor"
        config={{
          extraPlugins: [uploadPlugin],
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "numberedList",
            "bulletedList",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "|",
            "fontBackgroundColor",
            "fontColor",
            "|",
            "undo",
            "redo",
          ],
        }}
        data={value}
        editor={OnlineEditor}
        onReady={(editor) => {}}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          setValue("contents", data);
          console.log(data);
        }}
      />
    </EditorWrapper>
  );
};

export default Editor;
