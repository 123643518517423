import { gql } from "apollo-boost";

export const TICKET_ORDER_LIST_QUERY = gql`
  query AdminTicketOrderList(
    $ticketProduct: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminTicketOrderList(
      ticketProduct: $ticketProduct
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketOrders {
        id
        ticketOrderProducts {
          id
          commonStatus

          isReservation
          isMember
          user {
            id
            avatar
            userId
            name
          }
          name
          phone

          count
          totalPrice

          isRefund
          meRefund

          createdAt
          updatedAt

          ticketOrderProductPeoples {
            id
            commonStatus
            name
            phone

            createdAt
            updatedAt
          }
          ticketProductDate {
            id
            commonStatus
            ticketDate
          }
          ticketProductDateTime {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }
        }

        commonStatus
        user {
          id
          userId
          name
        }
        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents
        }

        isDiscount
        discountPrice
        price

        count

        totalCount
        totalPrice

        message

        isRefund
        refundReceiver {
          id
        }
        refundTotalCount
        refundTotalPrice

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const TICKET_ORDER_DETAIL_QUERY = gql`
  query AdminTicketOrderDetail($id: String!) {
    AdminTicketOrderDetail(id: $id) {
      id
      ticketOrderProducts {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          role
          avatar
          userId
          name
        }
        name
        phone

        count
        totalPrice

        isRefund
        meRefund

        createdAt
        updatedAt

        ticketOrderProductPeoples {
          id
          commonStatus
          name
          phone

          createdAt
          updatedAt
        }
        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }
      }

      commonStatus
      user {
        id
        role
        userId
        name
      }
      ticketProduct {
        id
        ticketConfig {
          id
        }
        commonStatus

        coverImage

        store {
          id
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }

        name
        contents
      }

      isDiscount
      discountPrice
      price
      count

      totalCount
      totalPrice

      message

      isRefund
      refundReceiver {
        id
      }
      refundTotalCount
      refundTotalPrice

      createdAt
      updatedAt
    }
  }
`;

export const TICKET_ORDER_REFUND_QUERY = gql`
  mutation AdminTicketOrderRefund(
    $id: String!
    $ticketOrderProductId: String!
  ) {
    AdminTicketOrderRefund(id: $id, ticketOrderProductId: $ticketOrderProductId)
  }
`;
