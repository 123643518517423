import React from "react";
import { useMutation } from "react-apollo-hooks";
import { ADMIN_USER_STORE_ASYNC_SELECT_MUTATION } from "../Queries";
import AsyncSelect from "react-select/async";

const AdminUserStoreAsyncSelect = ({ onChange, value }) => {
  const [AdminUserStoreAsyncSelectMutation] = useMutation(
    ADMIN_USER_STORE_ASYNC_SELECT_MUTATION
  );

  const loadOptions = async (inputValue) => {
    const {
      data: { AdminUserStoreAsyncSelect },
    } = await AdminUserStoreAsyncSelectMutation({
      variables: {
        take: 20,
        skip: 0,
        keyword: inputValue,
      },
    });

    if (AdminUserStoreAsyncSelect.count > 0) {
      return AdminUserStoreAsyncSelect?.users.map((item) => ({
        value: item.id,
        label: `${item.store?.name} > ${item.name}`,
      }));
    }
  };

  return (
    <AsyncSelect
      placeholder="사용자(이름), 가맹점(이름)을 검색해주세요."
      noOptionsMessage={() => {
        return "사용자(이름), 가맹점(이름)을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onChange={onChange}
      defaultValue={value ? value : null}
      // cacheOptions={false}
    />
  );
};

export default AdminUserStoreAsyncSelect;
