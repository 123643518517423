import { gql } from "apollo-boost";

export const STORE_DELIVERY_EDIT_LOG_LIST_QUERY = gql`
  query StoreDeliveryEditLogList(
    $editType: String!
    $editTypeId: String!
    $skip: Int!
    $take: Int
  ) {
    StoreDeliveryEditLogList(
      editType: $editType
      editTypeId: $editTypeId
      skip: $skip
      take: $take
    ) {
      deliveryEditLogs {
        id
        createdAt
        updatedAt

        editType
        editTypeId
        description
        user {
          id
          createdAt
          updatedAt

          commonStatus
          role
          useStoreApp
          confirmed
          blocked
          avatar
          userId
          email
          phone
          name
          sex
          birthDate
          zipcode
          roadAddress
          jibunAddress
          addressDetail
        }
      }
      count
    }
  }
`;
