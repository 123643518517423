import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { FEE_DETAIL_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(FEE_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  const {
    AdminFeeDetail: {
      id,
      createdAt,
      updatedAt,
      commonStatus,
      title,
      contents,
      planner,
      plannerUseSNS,
      plannerSNS,
      isDefault,
      coverImg,
      detailImg,
    },
  } = data;
  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      id={id}
      createdAt={createdAt}
      updatedAt={updatedAt}
      commonStatus={commonStatus}
      title={title}
      contents={contents}
      planner={planner}
      plannerUseSNS={plannerUseSNS}
      plannerSNS={plannerSNS}
      isDefault={isDefault}
      coverImg={coverImg}
      detailImg={detailImg}
    />
  );
});
