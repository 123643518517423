import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";
import { commonStatusName } from "../../../../../Components/User/UserNames";

const Presenter = ({
  history,
  match,
  location,

  isVisible,
  toggleModal,

  keyword,
  setKeyword,
  handleSearch,

  stores,
  setStores,

  data,
  loading,

  isModal,
}) => {
  const modalRef = React.createRef();
  const [keywordInput, setKeywordInput] = useState(keyword);

  const [checkItems, setCheckItems] = useState([]);

  const handleSelectCheck = (checked, item) => {
    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      let itemArray = [];
      data.AdminStoreList.stores
        .filter((item) => item.commonStatus === "C")
        .forEach((item) => {
          itemArray.push(item);
        });
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };

  const handleSelectAdd = () => {
    if (checkItems.length === 0) {
      toast.error("추가할 수신자를 선택해주세요.");
      return;
    }
    let itemArray = [];
    checkItems.forEach((item) => {
      if (!stores.includes(item)) {
        itemArray.push(item);
      }
    });
    setStores([...stores, ...itemArray]);
    setKeyword("");
    setKeywordInput("");
    setCheckItems([]);
    toggleModal();
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={isVisible}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          toggleModal();
        }}
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
          }}
        >
          가맹점 목록 (
          {
            data.AdminStoreList.stores.filter(
              (item) => item.commonStatus === "C"
            ).length
          }
          건)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox ">
            <div className="vertical-box-inner-cell overflow-visible height-md">
              <PerfectScrollbar
                className="height-full p-15"
                options={{ suppressScrollX: true }}
              >
                <InputGroup className="p-b-15">
                  <Input
                    type="text"
                    className="input-white"
                    placeholder="검색어(가맹점명) 입력"
                    value={keywordInput}
                    onChange={(e) => {
                      setKeywordInput(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setKeyword(keywordInput);
                        handleSearch();
                      }
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="white"
                      onClick={() => {
                        setKeyword(keywordInput);
                        handleSearch();
                      }}
                    >
                      <i className="fa fa-search fa-fw"></i>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>상태</th>
                        <th>가맹점명 - 이름(아이디)</th>
                        <th>주소</th>
                        <th style={{ textAlign: "center" }}>
                          <input
                            type="checkbox"
                            onChange={(e) => handleAllCheck(e.target.checked)}
                            checked={
                              checkItems.length ===
                              data.AdminStoreList.stores.filter(
                                (item) => item.commonStatus === "C"
                              ).length
                            }
                          />
                        </th>
                      </tr>
                    </thead>
                    {!loading &&
                    data.AdminStoreList.stores.filter(
                      (item) => item.commonStatus === "C"
                    ).length > 0 ? (
                      <tbody>
                        {data.AdminStoreList.stores
                          .filter((item) => item.commonStatus === "C")
                          .map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{commonStatusName(item.commonStatus)}</td>
                                <td>
                                  {item.name} - {item.user.name}(
                                  {item.user.userId})
                                </td>
                                <td>
                                  ({item.storeZipcode}){item.storeJibunAddress}{" "}
                                  {item.storeAddressDetail}
                                </td>
                                <td align={"center"}>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleSelectCheck(e.target.checked, item)
                                    }
                                    checked={
                                      checkItems.includes(item) ? true : false
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <p className="m-40 text-center">
                              등록된 사용자가 없습니다.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className=" btn btn-white text-primary"
            onClick={() => {
              handleSelectAdd();
            }}
          >
            추가
          </button>
          {/* <button
            type="button"
            className=" btn btn-white text-primary"
            onClick={() => {
              handleAllAdd();
            }}
          >
            전체추가
          </button> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Presenter;
