import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { COUPON_CATEGORY_LIST_QUERY } from "../../../Queries";
// import { validate } from "graphql";

const ListCategory = ({ history, match, location, config }) => {
  const { data, loading, refetch } = useQuery(COUPON_CATEGORY_LIST_QUERY, {
    variables: {
      couponConfig: config.AdminCouponConfig.id,
    },
  });
  useEffect(() => {
    refetch();
  });

  return (
    <div className="">
      <Link
        className={`btn btn-white m-b-5 ${
          !match.params.couponCategories ? "active" : ""
        }
        `}
        to={`/couponConfigs/${config.AdminCouponConfig.id}/coupons/categories${location.search}`}
      >
        <i className={"fas fa-th"} /> 전체
      </Link>

      {!loading &&
        data.AdminCouponCategoryList.count > 0 &&
        data.AdminCouponCategoryList.couponCategories.map((category) => {
          return (
            <Link
              key={category.id}
              className={`btn btn-white m-l-5 m-b-5 ${
                category.id === match.params.couponCategories ? "active" : ""
              }
              `}
              to={`/couponConfigs/${config.AdminCouponConfig.id}/coupons/categories/${category.id}${location.search}`}
            >
              <i className={category.pcIcon} />
              {" " + category.name}
            </Link>
          );
        })}

      <Link
        className={"btn btn-white m-l-5 m-b-5 "}
        to={`/couponConfigs/${config.AdminCouponConfig.id}/categories${location.search}`}
      >
        <i className={"fas fa-cogs"} />
      </Link>
    </div>
  );
};

export default ListCategory;
