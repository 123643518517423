import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  GIFT_PRODUCT_DETAIL_QUERY,
  GIFT_PRODUCT_EDIT_COVER_QUERY,
  GIFT_PRODUCT_DELETE_COVER_QUERY,
} from "../../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";

import { commonStatusName } from "../../../../../Components/User/UserNames";
import renderHTML from "react-render-html";
import ProductOption from "./ProductOption";
import TicketOption from "./TicketOption";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(GIFT_PRODUCT_DETAIL_QUERY, {
    variables: { id },
  });

  const [giftProductEditCoverMutation] = useMutation(
    GIFT_PRODUCT_EDIT_COVER_QUERY,
    {
      refetchQueries: () => [
        { query: GIFT_PRODUCT_DETAIL_QUERY, variables: { id } },
      ],
    }
  );

  const [giftProductDeleteCoverMutation] = useMutation(
    GIFT_PRODUCT_DELETE_COVER_QUERY,
    {
      refetchQueries: () => [
        { query: GIFT_PRODUCT_DETAIL_QUERY, variables: { id } },
      ],
    }
  );

  const handleCover = async (e, data) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];

    try {
      const {
        data: { AdminGiftProductCoverImageEdit },
      } = await giftProductEditCoverMutation({
        variables: {
          id: id,
          file,
        },
      });

      if (AdminGiftProductCoverImageEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/giftConfigs/${match.params.config}/products/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onDeleteImg = async (id) => {
    try {
      const {
        data: { AdminGiftProductCoverImageDelete },
      } = await giftProductDeleteCoverMutation({
        variables: {
          id: id,
        },
      });
      if (AdminGiftProductCoverImageDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/giftConfigs/${match.params.config}/products/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onClick = () => {
    document.getElementById("Cover").click();
  };

  if (!loading) {
    const {
      AdminGiftProductDetail: {
        id,
        giftCategory,
        store,
        commonStatus,
        productType,
        coverImage,
        isSoldOut,
        isDiscount,
        discountPrice,
        price,
        name,
        contents,

        limit,
        schedule,
        place,

        isBest,
        isNew,

        giftProductOptions,
        giftProductOptionDetailsCount,
      },
    } = data;
    const Cover =
      coverImage === "" || coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${coverImage}`;

    return (
      <>
        <Helmet>
          <title>상품 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">상품</li>
          </ol>
          <h1 className="page-header">상품</h1>

          <div className="margin-bottom-0">
            <div className="row p-b-15">
              <div className="col-xs-4 text-left">
                <Link
                  to={`/giftConfigs/${match.params.config}/products${location.search}`}
                  className="btn btn-white"
                >
                  <FontAwesomeIcon icon={faList} /> 목록
                </Link>
              </div>
              <div className="col-xs-4 text-center"></div>
              <div className="col-xs-4 text-right">
                <Link
                  to={`/giftOrders?giftProduct=${id}`}
                  className="btn btn-white text-blue"
                >
                  구매내역
                </Link>
                <Link
                  to={`/giftConfigs/${match.params.config}/products/${match.params.id}/edit${location.search}`}
                  className="btn btn-white text-green"
                >
                  수정
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <Panel>
                  <PanelHeader noButton={true}>커버 이미지</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="bg-white rounded">
                        <div className="form-group row">
                          <div className="col-md-12">
                            <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                              <div className="vertical-box-column valign-middle text-center">
                                <img
                                  src={Cover}
                                  alt="이미지 업로드"
                                  className="width-200"
                                />
                              </div>
                            </div>
                            <div className="m-t-10">
                              <input
                                type="file"
                                id="Cover"
                                onChange={(e) => handleCover(e, data)}
                                className="hide"
                              />
                              <button
                                className="btn btn-primary btn-block"
                                onClick={onClick}
                              >
                                이미지 업로드
                              </button>
                              <Button
                                type="button"
                                color="white"
                                className="btn btn-primary btn-block text-red"
                                onClick={() => {
                                  onDeleteImg(id);
                                }}
                              >
                                이미지 삭제
                              </Button>
                            </div>
                            <div className="alert alert-primary fade show m-t-10 m-b-0">
                              <b>커버이미지 사이즈 :</b> 넓이 600px X 높이 600px
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
              <div className="col-sm-9">
                <Panel>
                  <PanelHeader noButton={true}>상품 정보</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">상태</label>
                        <div className="col-md-4">
                          {commonStatusName(commonStatus)}
                        </div>
                        <label className="col-form-label col-md-2">품절</label>
                        <div className="col-md-4">
                          {isSoldOut ? <i className="fas fa-check"></i> : "-"}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">분류</label>
                        <div className="col-md-4">
                          <Link
                            to={`/giftConfigs/${match.params.config}/products?category=${giftCategory.id}`}
                          >
                            {giftCategory.parentCategory
                              ? `${giftCategory.parentCategory.name} &gt; ${giftCategory.name}`
                              : `${giftCategory.name}`}
                          </Link>
                        </div>
                        <label className="col-form-label col-md-2">
                          가맹점
                        </label>
                        <div className="col-md-4">
                          <Link to={`/stores/${store.id}`}>{store.name}</Link>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">가격</label>
                        <div className="col-md-4">
                          <NumberFormat
                            value={price}
                            suffix={"원"}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </div>
                        <label className="col-form-label col-md-2">할인</label>
                        <div className="col-md-4">
                          {isDiscount ? (
                            <NumberFormat
                              value={discountPrice}
                              suffix={"원"}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      {productType === "Ticket" && (
                        <>
                          <div className="form-group row">
                            <label className="col-form-label col-md-2">
                              정원
                            </label>
                            <div className="col-md-10">{limit}명</div>
                          </div>
                          <div className="form-group row">
                            <label className="col-form-label col-md-2">
                              일정
                            </label>
                            <div className="col-md-10">
                              {schedule ? schedule : `-`}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-form-label col-md-2">
                              장소
                            </label>
                            <div className="col-md-10">
                              {place ? place : `-`}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          상품명
                        </label>
                        <div className="col-md-10">{name}</div>
                      </div>
                      <div className="form-group row hide">
                        <label className="col-form-label col-md-2">
                          BEST 상품
                        </label>
                        <div className="col-md-4">
                          {isBest ? <i className="fas fa-check"></i> : "-"}
                        </div>
                        <label className="col-form-label col-md-2">
                          NEW 상품
                        </label>
                        <div className="col-md-4">
                          {isNew ? <i className="fas fa-check"></i> : "-"}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          상품 정보
                        </label>
                        <div className="col-md-10">{renderHTML(contents)}</div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                {productType === "Product" && (
                  <ProductOption
                    giftProductOptions={giftProductOptions}
                    giftProductOptionDetailsCount={
                      giftProductOptionDetailsCount
                    }
                  />
                )}
                {productType === "Ticket" && (
                  <TicketOption
                    giftProductOptions={giftProductOptions}
                    giftProductOptionDetailsCount={
                      giftProductOptionDetailsCount
                    }
                  />
                )}
              </div>
            </div>

            <div className="row p-b-15">
              <div className="col-xs-4 text-left">
                <Link
                  to={`/giftConfigs/${match.params.config}/products${location.search}`}
                  className="btn btn-white"
                >
                  <FontAwesomeIcon icon={faList} /> 목록
                </Link>
              </div>
              <div className="col-xs-4 text-center"></div>
              <div className="col-xs-4 text-right">
                <Link
                  to={`/giftOrders?giftProduct=${id}`}
                  className="btn btn-white text-blue"
                >
                  구매내역
                </Link>
                <Link
                  to={`/giftConfigs/${match.params.config}/products/${match.params.id}/edit${location.search}`}
                  className="btn btn-white text-green"
                >
                  수정
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
