import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  STORE_CATEGORY_LIST_QUERY,
  STORE_EDIT_QUERY,
  STORE_DETAIL_QUERY,
} from "../Queries";
import { useQuery, useMutation } from "react-apollo-hooks";
import DaumPostcode from "react-daum-postcode";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Moment from "moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import {
  commonStatusName,
  bankNameArray,
} from "../../../../Components/User/UserNames";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

registerLocale("ko", ko);

export default ({
  history,
  match,
  location,

  id,
  commonStatus,
  storeCategory,
  storeCategories,
  name,
  productInfo,
  storeInfo,

  storeNumber,
  storeOperationHour,
  storePersonalDay,
  storeTel,
  storeCEO,
  email,
  phone,

  storeZipcode,
  storeRoadAddress,
  storeJibunAddress,
  storeAddressDetail,
  longitude,
  latitude,

  bank_name,
  bank_num,
  bank_account_holder,

  isGift,
  giftedAt,

  drmPercent,
  gruPercent,

  isModal,
}) => {
  const modalRef = React.createRef();
  const { data } = useQuery(STORE_CATEGORY_LIST_QUERY);
  const [storeEditMutation] = useMutation(STORE_EDIT_QUERY, {
    refetchQueries: () => [{ query: STORE_DETAIL_QUERY, variables: { id } }],
  });
  const storeCategoryIds = storeCategories.map((item) => {
    return item.id;
  });
  const { register, handleSubmit, setValue, errors, watch } = useForm({
    defaultValues: {
      commonStatus: commonStatus,
      storeCategory: storeCategory.id,
      storeCategories: storeCategoryIds,
      name: name,
      productInfo: productInfo,
      storeInfo: storeInfo,

      storeNumber: storeNumber,
      storeOperationHour: storeOperationHour,
      storePersonalDay: storePersonalDay,
      storeTel: storeTel,
      storeCEO: storeCEO,
      email: email,
      phone: phone,

      storeZipcode: storeZipcode,
      storeRoadAddress: storeRoadAddress,
      storeJibunAddress: storeJibunAddress,
      storeAddressDetail: storeAddressDetail,
      longitude: longitude,
      latitude: latitude,

      bank_name: bank_name,
      bank_num: bank_num,
      bank_account_holder: bank_account_holder,

      isGift: isGift,
      giftedAt: giftedAt,

      drmPercent: drmPercent,
      gruPercent: gruPercent,
    },
  });

  const [giftedDate, setGiftedDate] = useState(
    giftedAt ? Moment(giftedAt) : new Date()
  );
  const [zipcodeModal, setZipcodeModal] = useState(false);
  const handleZipcodeModal = () => {
    setZipcodeModal(!zipcodeModal);
  };
  const handleAddress = (data) => {
    const API_KEY = "7da7d093496c67033d3e14aa3102e838";
    const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&size=15`;
    fetch(url, {
      headers: {
        Authorization: `KakaoAK ${API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setValue("storeZipcode", data.zonecode);
        setValue("storeRoadAddress", data.roadAddress);
        setValue("storeJibunAddress", data.jibunAddress);

        setValue("longitude", json.documents[0].x);
        setValue("latitude", json.documents[0].y);

        handleZipcodeModal();
      });
  };
  const onSubmit = async (data) => {
    if (watch("storeCategories").includes(ecoCategory && ecoCategory.id)) {
      if (data.drmPercent === "") {
        toast.error("드림비율를 입력해주세요.");
        return;
      }
      if (data.gruPercent === "") {
        toast.error("그루비율를 입력해주세요.");
        return;
      }
      if (parseInt(data.drmPercent) + parseInt(data.gruPercent) !== 100) {
        toast.error("드림비율과 그루비율의 합이 100이 되지않습니다.");
        return;
      }
    }

    if (data.isGift === "true" && giftedDate === null) {
      toast.error("입점일을 입력해주세요.");
      return;
    }
    try {
      const {
        data: { AdminStoreEdit },
      } = await storeEditMutation({
        variables: {
          id: id,
          commonStatus: data.commonStatus,
          storeCategory: data.storeCategory,
          storeCategories: data.storeCategories,
          name: data.name,
          productInfo: data.productInfo,
          storeInfo: data.storeInfo,

          storeNumber: data.storeNumber,
          storeOperationHour: data.storeOperationHour,
          storePersonalDay: data.storePersonalDay,
          storeTel: data.storeTel,
          storeCEO: data.storeCEO,
          email: data.email,
          phone: data.phone,

          storeZipcode: data.storeZipcode,
          storeRoadAddress: data.storeRoadAddress,
          storeJibunAddress: data.storeJibunAddress,
          storeAddressDetail: data.storeAddressDetail,
          longitude: data.longitude,
          latitude: data.latitude,

          bank_name: data.bank_name,
          bank_num: data.bank_num,
          bank_account_holder: data.bank_account_holder,

          isGift: data.isGift === "true" ? true : false,
          giftedAt: giftedDate,

          drmPercent: data.drmPercent,
          gruPercent: data.gruPercent,
        },
      });
      if (AdminStoreEdit) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  const ecoCategory =
    data &&
    data.AdminStoreCategoryList.storeCategories.find(
      (item) => item.name === "에코"
    );

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/stores/${id}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`/stores/${id}${location.search}`);
            }}
          >
            수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-2">상태</label>
                <div className="col-md-10">
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="commonStatus1"
                      name="commonStatus"
                      value={"S"}
                      ref={register({
                        required: "상태를 선택해주세요.",
                      })}
                    />
                    <label htmlFor="commonStatus1">
                      {commonStatusName("S")}
                    </label>
                  </div>
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="commonStatus2"
                      name="commonStatus"
                      value={"C"}
                      ref={register({
                        required: "상태를 선택해주세요.",
                      })}
                    />
                    <label htmlFor="commonStatus2">
                      {commonStatusName("C")}
                    </label>
                  </div>
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="commonStatus3"
                      name="commonStatus"
                      value={"D"}
                      ref={register({
                        required: "상태를 선택해주세요.",
                      })}
                    />
                    <label htmlFor="commonStatus3">탈퇴</label>
                  </div>
                  {errors.commonStatus && (
                    <div className="text-red">
                      {errors.commonStatus.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row ">
                <label className="col-form-label col-md-2">대표분류</label>
                <div className="col-md-10">
                  {data &&
                    data.AdminStoreCategoryList.storeCategories.map(
                      (category, index) => {
                        return (
                          <div
                            key={index}
                            className="radio radio-css radio-inline"
                          >
                            <input
                              key={category.id}
                              type="radio"
                              id={`storeCategory${category.id}`}
                              name="storeCategory"
                              className="form-control"
                              value={category.id}
                              ref={register({
                                required: "분류를 선택해주세요.",
                              })}
                            />
                            <label htmlFor={`storeCategory${category.id}`}>
                              {category.name}
                            </label>
                          </div>
                        );
                      }
                    )}
                  {errors.storeCategory && (
                    <div className="text-red">
                      {errors.storeCategory.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">중복분류</label>
                <div className="col-md-10">
                  {data &&
                    data.AdminStoreCategoryList.storeCategories.map(
                      (category, index) => {
                        return (
                          <div
                            key={index}
                            className="checkbox checkbox-css checkbox-inline"
                          >
                            <input
                              key={category.id}
                              type="checkbox"
                              id={`storeCategories${category.id}`}
                              name="storeCategories"
                              className="form-control"
                              value={category.id}
                              ref={register({
                                required: "분류를 선택해주세요.",
                              })}
                            />
                            <label htmlFor={`storeCategories${category.id}`}>
                              {category.name}
                            </label>
                          </div>
                        );
                      }
                    )}
                  {errors.storeCategories && (
                    <div className="text-red">
                      {errors.storeCategories.message}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`form-group row ${
                  watch("storeCategories").includes(
                    ecoCategory && ecoCategory.id
                  )
                    ? ""
                    : "hide"
                }`}
              >
                <label className="col-form-label col-md-2">드림(%)</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="drmPercent"
                    className="form-control"
                    placeholder="드림비율를 입력해주세요."
                    ref={register()}
                  />
                </div>
                <label className="col-form-label col-md-2">그루(%)</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="gruPercent"
                    className="form-control"
                    placeholder="그루비율를 입력해주세요."
                    ref={register()}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">상호명</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="이름을 입력해주세요."
                    ref={register({
                      required: "이름을 입력해주세요.",
                    })}
                  />
                  {errors.name && (
                    <div className="text-red">{errors.name.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">상품정보</label>
                <div className="col-md-10">
                  <textarea
                    name="productInfo"
                    className="form-control"
                    placeholder="상품정보를 입력해주세요."
                    ref={register({
                      required: "상품정보를 입력해주세요.",
                    })}
                  ></textarea>
                  {errors.productInfo && (
                    <div className="text-red">{errors.productInfo.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">가맹점정보</label>
                <div className="col-md-10">
                  <textarea
                    name="storeInfo"
                    className="form-control"
                    placeholder="가맹점정보를 입력해주세요."
                    ref={register({
                      required: "가맹점정보를 입력해주세요.",
                    })}
                  ></textarea>
                  {errors.storeInfo && (
                    <div className="text-red">{errors.storeInfo.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">운영시간</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storeOperationHour"
                    className="form-control"
                    placeholder="운영시간을 입력해주세요."
                    ref={register({
                      required: "운영시간을 입력해주세요.",
                    })}
                  />
                  {errors.storeOperationHour && (
                    <div className="text-red">
                      {errors.storeOperationHour.message}
                    </div>
                  )}
                </div>
                <label className="col-form-label col-md-2">휴무일</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storePersonalDay"
                    className="form-control"
                    placeholder="휴무일를 입력해주세요."
                    ref={register({
                      required: "휴무일를 입력해주세요.",
                    })}
                  />
                  {errors.storePersonalDay && (
                    <div className="text-red">
                      {errors.storePersonalDay.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">전화번호</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storeTel"
                    className="form-control"
                    placeholder="입력해주세요."
                    ref={register({
                      required: "전화번호를 입력해주세요.",
                    })}
                  />
                  {errors.storeTel && (
                    <div className="text-red">{errors.storeTel.message}</div>
                  )}
                </div>
                <label className="col-form-label col-md-2">
                  사업자등록번호
                </label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storeNumber"
                    className="form-control"
                    placeholder="사업자등록번호를 입력해주세요."
                    ref={register({
                      required: "사업자등록번호를 입력해주세요.",
                    })}
                  />
                  {errors.storeNumber && (
                    <div className="text-red">{errors.storeNumber.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">대표자명</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeCEO"
                    className="form-control"
                    placeholder="대표자명을 입력해주세요."
                    ref={register({
                      required: "대표자명을 입력해주세요.",
                    })}
                  />
                  {errors.storeCEO && (
                    <div className="text-red">{errors.storeCEO.message}</div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">이메일</label>
                <div className="col-md-4">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="이메일를 입력해주세요."
                    ref={register()}
                  />
                </div>
                <label className="col-form-label col-md-2">휴대전화</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="휴대전화를 입력해주세요."
                    ref={register()}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">우편번호</label>
                <div className="col-md-10">
                  <InputGroup size="sm">
                    <input
                      type="text"
                      name="storeZipcode"
                      className="form-control"
                      placeholder="우편번호를 입력해주세요."
                      ref={register({
                        required: "대표자명을 입력해주세요.",
                      })}
                      readOnly
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        size="sm"
                        color="primary"
                        onClick={handleZipcodeModal}
                      >
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  {errors.storeZipcode && (
                    <div className="text-red">
                      {errors.storeZipcode.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">도로명주소</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeRoadAddress"
                    className="form-control"
                    placeholder="도로명주소를 입력해주세요."
                    ref={register({
                      required: "도로명주소를 입력해주세요.",
                    })}
                    readOnly
                  />
                  {errors.storeRoadAddress && (
                    <div className="text-red">
                      {errors.storeRoadAddress.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">지번주소</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeJibunAddress"
                    className="form-control"
                    placeholder="지번주소를 입력해주세요."
                    ref={register({
                      required: "지번주소를 입력해주세요.",
                    })}
                    readOnly
                  />
                  {errors.storeJibunAddress && (
                    <div className="text-red">
                      {errors.storeJibunAddress.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-2">경도</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="longitude"
                    className="form-control"
                    placeholder="경도를 입력해주세요."
                    ref={register({
                      required: "경도를 입력해주세요.",
                    })}
                    readOnly
                  />
                  {errors.longitude && (
                    <div className="text-red">{errors.longitude.message}</div>
                  )}
                </div>
                <label className="col-form-label col-md-2">위도</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="latitude"
                    className="form-control"
                    placeholder="위도를 입력해주세요."
                    ref={register({
                      required: "위도를 입력해주세요.",
                    })}
                    readOnly
                  />
                  {errors.latitude && (
                    <div className="text-red">{errors.latitude.message}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-2">상세주소</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeAddressDetail"
                    className="form-control"
                    placeholder="상세주소를 입력해주세요."
                    ref={register({
                      required: "상세주소를 입력해주세요.",
                    })}
                  />
                  {errors.storeAddressDetail && (
                    <div className="text-red">
                      {errors.storeAddressDetail.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-2">드림상점</label>
                <div className="col-md-4">
                  <div className="checkbox checkbox-css checkbox-inline">
                    <input
                      type="checkbox"
                      id="isGift"
                      name="isGift"
                      value={true}
                      ref={register()}
                    />
                    <label htmlFor="isGift">설정</label>
                    {errors.isGift && (
                      <div className="text-red">{errors.isGift.message}</div>
                    )}
                  </div>
                </div>
                <label className="col-form-label col-md-2">입점일</label>
                <div className="col-md-4">
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    selected={new Date(giftedDate)}
                    onChange={(date) => setGiftedDate(date)}
                    locale="ko"
                    renderCustomHeader={({
                      date,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      decreaseMonth,
                      increaseMonth,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="react-datepicker__navigation react-datepicker__navigation--previous"
                          aria-label="Previous Month"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          Previous Month
                        </button>
                        <div className="month-day">
                          {getYear(date)}년 {getMonth(date) + 1}월
                        </div>
                        <button
                          type="button"
                          className="react-datepicker__navigation react-datepicker__navigation--next"
                          aria-label="Next Month"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          Next Month
                        </button>
                      </div>
                    )}
                  />
                  {errors.giftedDate && (
                    <div className="text-red">{errors.giftedDate.message}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-2">은행</label>
                <div className="col-md-4">
                  <select
                    name="bank_name"
                    className="form-control"
                    ref={register()}
                  >
                    <option value="">은행선택</option>
                    {Object.keys(bankNameArray).map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {bankNameArray[item]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <label className="col-form-label col-md-2">계좌번호</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="bank_num"
                    className="form-control"
                    placeholder="계좌번호를 입력해주세요."
                    ref={register()}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-2">예금주</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="bank_account_holder"
                    className="form-control"
                    placeholder="예금주를 입력해주세요."
                    ref={register()}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
        <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode height={700} onComplete={handleAddress} />
        </ModalBody>
      </Modal>
    </>
  );
};
