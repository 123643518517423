import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  HELP_CATEGORY_LIST_QUERY,
  HELP_CATEGORY_DELETE_QUERY
} from "../../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data, loading, refetch } = useQuery(HELP_CATEGORY_LIST_QUERY, {
    variables: {
      helpConfig: match.params.helpConfig,
      commonApp: match.params.CommonApp,
    },
  });

  const [helpCategoryDeleteMutation] = useMutation(HELP_CATEGORY_DELETE_QUERY);

  const onDelete = async data => {
    try {
      const {
        data: { AdminHelpCategoryDelete }
      } = await helpCategoryDeleteMutation({
        variables: {
          id: data.id
        }
      });
      if (AdminHelpCategoryDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/categories${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    refetch();
  });

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      loading={loading}
      onDelete={onDelete}
    ></Presenter>
  );
});
