import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import {
  CHARGEREQUEST_CONFIG_QUERY,
  CHARGEREQUEST_CONFIG_EDIT_QUERY
} from "../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import WriteUser from "../WriteUser";

export default withRouter(
  ({ history, match, location, isModal, id, name, manager }) => {
    const modalRef = React.createRef();
    const [chargeRequestConfigEditMutation] = useMutation(
      CHARGEREQUEST_CONFIG_EDIT_QUERY,
      {
        refetchQueries: () => [{ query: CHARGEREQUEST_CONFIG_QUERY }]
      }
    );
    const { register, handleSubmit, watch, errors } = useForm({
      defaultValues: {
        name: name,
        manager: manager.id
      }
    });
    const [user, setUser] = useState(manager);

    const onSubmit = async data => {
      if (user === "") {
        toast.error("운영자명을 입력해주세요.");
        return;
      }

      try {
        const {
          data: { AdminChargeRequestConfigEdit }
        } = await chargeRequestConfigEditMutation({
          variables: {
            id: id,
            name: data.name,
            manager: user.id
          }
        });
        if (AdminChargeRequestConfigEdit) {
          toast.success("설정 되었습니다.");
          history.push(
            `/chargeRequestConfigs/${match.params.chargeRequestConfig}/chargeRequests`
          );
        }
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <>
        <Modal
          isOpen={true}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/chargeRequestConfigs/${match.params.chargeRequestConfig}/chargeRequests`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/chargeRequestConfigs/${match.params.chargeRequestConfig}/chargeRequests`
                );
              }}
            >
              설정수정
            </ModalHeader>
            <ModalBody className=" p-0">
              <div className="form-horizontal form-bordered">
                <div className="bg-white rounded">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">이름</label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="이름을 입력해주세요."
                        ref={register({ required: "이름을 입력해주세요." })}
                        defaultValue={watch("name")}
                      />
                      {errors.name && <span>{errors.name.message}</span>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">운영자</label>
                    <div className="col-md-10">
                      <WriteUser
                        history={history}
                        match={match}
                        location={location}
                        user={user}
                        setUser={setUser}
                      />
                      {errors.manager && <span>{errors.manager.message}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="row">
                <div className="col-xs-12 text-right">
                  <button
                    type="submit"
                    className="btn btn-white text-blue m-r-5"
                  >
                    확인
                  </button>
                  <Link
                    to={`/chargeRequestConfigs/${match.params.chargeRequestConfig}/chargeRequest`}
                    className="btn btn-white text-red"
                  >
                    취소
                  </Link>
                </div>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
);
