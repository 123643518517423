import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { BANNER_DETAIL_QUERY } from "../Queries";
import Presenter from "./Presenter";

const Container = withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(BANNER_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminBannerDetail) {
    const {
      AdminBannerDetail: {
        id,
        commonStatus,
        coverImage,
        group,
        title,
        stack,
        screen,
      },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        commonStatus={commonStatus}
        coverImage={coverImage}
        group={group}
        title={title}
        stack={stack}
        screen={screen}
      />
    );
  }
});

export default Container;
