import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  WITHDRAWAL_LIST_QUERY,
  WITHDRAWAL_CANCEL_QUERY,
  WITHDRAWAL_EDIT_COMMONSTATUS_QUERY,
} from "../Queries";
import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  roleName,
  confirmedName,
  blockedName,
  currencyArray,
  currencyName,
  commonStatusName,
  datePlus9,
  bankName,
} from "../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  var page = queryString.page ? queryString.page : 1;
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(WITHDRAWAL_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { startSearchDate, endSearchDate, keyword, skip, first },
  });
  const [withdrawalListMutation] = useMutation(WITHDRAWAL_LIST_QUERY);
  const [withdrawalCancelMutation] = useMutation(WITHDRAWAL_CANCEL_QUERY);
  const [withdrawalEditCommonStatusMutation] = useMutation(
    WITHDRAWAL_EDIT_COMMONSTATUS_QUERY
  );

  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/withdrawals${baseUrl}page=1`);
  };

  const handleDateApplyEvent = (event, picker) => {
    // event.preventDefault();

    var startDate = picker.startDate.format("YYYY-MM-DD");
    var endDate = picker.endDate.format("YYYY-MM-DD");

    const ledgersUrl =
      "?" +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "");

    if (match.params.role) {
      history.push(`/users/${match.params.role}${ledgersUrl}page=1`);
    } else {
      history.push(`/withdrawals${ledgersUrl}page=1`);
    }
  };

  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [
        [
          "은행",
          "은행계좌",
          "예금주",
          "탈퇴일시",
          "상태",
          "권한",
          "이름",
          "아이디",
          "이메일",
          "휴대전화",
          "잔액",
          "주소",
          "정기결제",
          "승인유무",
          "차단유무",
          "가입일시",
        ],
      ];
      try {
        const {
          data: { AdminWithdrawalList },
        } = await withdrawalListMutation({
          variables: { keyword },
        });
        AdminWithdrawalList.withdrawals.forEach((item) => {
          data.push([
            `${bankName(item.bank_name)}`,
            item.bank_num,
            item.bank_account_holder,
            `${datePlus9(item.createdAt)}`,
            `${commonStatusName(item.user.commonStatus)}`,
            `${roleName(item.user.role)}`,
            item.user.name,
            item.user.userId,
            item.user.email,
            item.user.phone,
            `${item.user.trustlines.map((trustline) => {
              return `${currencyName(trustline.currency)} : ${
                trustline.amount - currencyArray[trustline.currency].startAmount
              }\n`;
            })}`,
            `${item.user.zipcode}\n도로명주소 : ${item.user.roadAddress}\n지번주소 : ${item.user.jibunAddress}\n상세주소 : ${item.user.addressDetail}\n`,
            `${
              item.user.isCms
                ? `매월 ${item.user.cmsDay}일 ${String(
                    item.user.cmsValue
                  ).replace(/(.)(?=(\d{3})+$)/g, "$1,")}원 정기결제`
                : "신청안함"
            }`,
            `${confirmedName(item.user.confirmed)}`,
            `${blockedName(item.user.blocked)}`,
            `${datePlus9(item.user.createdAt)}`,
          ]);
        });
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    return (
      <>
        <button
          className="btn btn-white btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`탈퇴자.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  const handleCancel = async (e, item) => {
    e.preventDefault();

    try {
      await withdrawalCancelMutation({
        variables: {
          id: item.id,
        },
      });

      refetch();
      // history.replace(`/exchangeRequests${baseUrl}page=${page}`);
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const handleApproval = async (e, item) => {
    e.preventDefault();

    try {
      await withdrawalEditCommonStatusMutation({
        variables: {
          id: item.id,
          commonStatus: "C",
        },
      });

      refetch();
      // history.replace(`/exchangeRequests${baseUrl}page=${page}`);
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    refetch();
  });

  return (
    <>
      <Presenter
        history={history}
        match={match}
        location={location}
        page={page}
        startSearchDate={startSearchDate}
        endSearchDate={endSearchDate}
        keyword={keyword}
        keywordInput={keywordInput}
        blockSize={blockSize}
        first={first}
        data={data}
        loading={loading}
        baseUrl={baseUrl}
        handleDateApplyEvent={handleDateApplyEvent}
        handleSearch={handleSearch}
        DownloadButton={DownloadButton}
        handleCancel={handleCancel}
        handleApproval={handleApproval}
      ></Presenter>
    </>
  );
});
