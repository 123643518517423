import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { INQUIRY_CATEGORY_EDIT_QUERY } from "../../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default ({ history, match, location, isModal, id, name }) => {
  const modalRef = React.createRef();

  const [inquiryCategoryEditMutation] = useMutation(
    INQUIRY_CATEGORY_EDIT_QUERY
  );

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      name: name
    }
  });
  const onSubmit = async data => {
    try {
      const {
        data: { AdminInquiryCategoryEdit }
      } = await inquiryCategoryEditMutation({
        variables: {
          id: id,
          name: data.name
        }
      });
      if (AdminInquiryCategoryEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/inquiryConfigs/${match.params.id}/categories${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`
              );
            }}
          >
            분류 수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요."
                      })}
                      defaultValue={watch("name")}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Link
              to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/categories${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
