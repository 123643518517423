import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  CHARGEREQUESTCMS_DETAIL_QUERY,
  CHARGEREQUESTCMS_APPROVAL_QUERY,
  CHARGEREQUESTCMS_DELETE_QUERY,
} from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import DetailRow from "./DetailRow";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(CHARGEREQUESTCMS_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: id },
  });

  const [chargeRequestCMSApprovalMutation] = useMutation(
    CHARGEREQUESTCMS_APPROVAL_QUERY
  );
  const onApproval = async () => {
    try {
      const {
        data: {
          AdminChargeRequestCMSApproval: {
            success,
            errorType1,
            errorType2,
            errorType3,
          },
        },
      } = await chargeRequestCMSApprovalMutation({
        variables: {
          id: id,
        },
      });
      const resultString = `
        처리 되었습니다.\n
        성공 : ${success}건\n
        회원정보 없음 : ${errorType1}건\n
        이미 충전됨 : ${errorType2}건\n
        완전출금 안됨 : ${errorType3}건`;
      // toast.success(resultString);
      alert(resultString);
      history.push(`/chargeRequestCMses/${id}${location.search}`);
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const [chargeRequestCMSDeleteMutation] = useMutation(
    CHARGEREQUESTCMS_DELETE_QUERY
  );
  const onDelete = async () => {
    try {
      const {
        data: { AdminChargeRequestCMSDelete },
      } = await chargeRequestCMSDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminChargeRequestCMSDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/chargeRequestCMses${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (!loading) {
    const {
      AdminChargeRequestCMSDetail: {
        chargeRequestCMSDetails,
        commonStatus,
        year,
        month,

        file,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>알림 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">알림</li>
          </ol>
          <h1 className="page-header">알림</h1>

          <div key="topButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/chargeRequestCMses${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              {commonStatus === "S" ? (
                <Button
                  type="button"
                  color="white"
                  className="text-success"
                  onClick={() => {
                    onApproval();
                  }}
                >
                  승인
                </Button>
              ) : (
                <Button
                  type="button"
                  color="white"
                  className="text-primary disabled"
                >
                  완료
                </Button>
              )}
              <Button
                type="button"
                color="white"
                className="text-danger"
                onClick={() => {
                  onDelete();
                }}
              >
                삭제
              </Button>
            </div>
          </div>

          <div key="storeInfo" className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>
                  <b>{year}</b>년 <b>{month}</b>월 파일(<b>{file}</b>) 충전현황(
                  {chargeRequestCMSDetails.length}건)
                </PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>상태</th>
                          <th>납부자번호</th>
                          <th>이용서비스</th>
                          <th>CMS상태</th>
                          <th>출금일</th>
                          <th>납부금액</th>
                          <th>미납금액</th>
                          <th>누적액</th>
                          <th>만기금액</th>
                          <th>에러사항</th>
                          <th>최근출금일</th>
                          <th>이름</th>
                          <th>휴대전화</th>
                          <th>설명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {chargeRequestCMSDetails.map((detail) => {
                          return (
                            <DetailRow
                              key={detail.id}
                              year={year}
                              month={month}
                              detail={detail}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div key="bottomButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/chargeRequestCMses${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              {/* <Button
                type="button"
                color="white"
                className="text-red"
                onClick={() => {
                  onDelete();
                }}
              >
                삭제
              </Button> */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
