import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { GIFT_BANNER_DETAIL_QUERY, GIFT_BANNER_DELETE_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import { graphqlUri } from "../../../Config/Env";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data: detail, loading } = useQuery(GIFT_BANNER_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  const [giftBannerDeleteMutation] = useMutation(GIFT_BANNER_DELETE_QUERY);
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminGiftBannerDelete },
      } = await giftBannerDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminGiftBannerDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/giftBanners${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && detail && detail.AdminGiftBannerDetail) {
    const {
      AdminGiftBannerDetail: {
        id,
        commonStatus,
        coverImage,
        title,
        createdAt,
        // updatedAt,
      },
    } = detail;
    const giftBannerCoverImage =
      coverImage === ""
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUri}${coverImage}`;
    return (
      <>
        <Helmet>
          <title>배너 | 한밭페이 </title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">배너</li>
          </ol>
          <h1 className="page-header">배너</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/giftBanners${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/giftBanners/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>상세내용</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="wrapper">
                    <img
                      src={giftBannerCoverImage}
                      alt="이미지 업로드"
                      className="height-200"
                    />
                    <h3 className="m-t-20 f-w-500">
                      {commonStatus === "S" && (
                        <span className="label label-danger">비공개</span>
                      )}{" "}
                      {commonStatus === "C" && (
                        <span className="label label-primary">공개</span>
                      )}{" "}
                      {title}
                    </h3>
                  </div>
                  <div className="p-15 bg-grey-transparent-5">
                    <div className="info_ul">
                      <ul>
                        <li>
                          <b>작성일 :</b>
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {createdAt}
                          </Moment>
                        </li>
                      </ul>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/giftBanners${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/giftBanners/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
