import { gql } from "apollo-boost";

/* 배너 */
export const BANNER_LIST_QUERY = gql`
  query AdminBannerList($keyword: String, $skip: Int!, $first: Int!) {
    AdminBannerList(
      keyword: $keyword
      orderBy: "order_DESC"
      skip: $skip
      first: $first
    ) {
      banners {
        id
        commonStatus
        coverImage
        group
        title
        stack
        screen
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const BANNER_WRITE_QUERY = gql`
  mutation AdminBannerWrite(
    $commonStatus: String!
    $coverImage: CoverUpload!
    $group: String!
    $title: String!
    $stack: String!
    $screen: String!
    $timestamp: String
  ) {
    AdminBannerWrite(
      commonStatus: $commonStatus
      coverImage: $coverImage
      group: $group
      title: $title
      stack: $stack
      screen: $screen
      timestamp: $timestamp
    )
  }
`;

export const BANNER_DETAIL_QUERY = gql`
  query AdminBannerDetail($id: String!) {
    AdminBannerDetail(id: $id) {
      id
      commonStatus
      coverImage
      group
      title
      stack
      screen
      createdAt
      updatedAt
    }
  }
`;

export const BANNER_EDIT_QUERY = gql`
  mutation AdminBannerEdit(
    $id: String!
    $commonStatus: String!
    $coverImage: CoverUpload
    $group: String!
    $title: String!
    $stack: String!
    $screen: String!
  ) {
    AdminBannerEdit(
      id: $id
      commonStatus: $commonStatus
      coverImage: $coverImage
      group: $group
      title: $title
      stack: $stack
      screen: $screen
    )
  }
`;

export const BANNER_DELETE_QUERY = gql`
  mutation AdminBannerDelete($id: String!) {
    AdminBannerDelete(id: $id)
  }
`;

export const BANNER_PREVMOVE_QUERY = gql`
  mutation AdminBannerPrevMove($id: String!) {
    AdminBannerPrevMove(id: $id)
  }
`;
export const BANNER_NEXTMOVE_QUERY = gql`
  mutation AdminBannerNextMove($id: String!) {
    AdminBannerNextMove(id: $id)
  }
`;
