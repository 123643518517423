import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_PRODUCT_DATETIME_DETAIL_QUERY } from "../../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(TICKET_PRODUCT_DATETIME_DETAIL_QUERY, {
    variables: { id: match.params.ticketDateTimeId },
  });

  if (!loading) {
    const {
      StoreTicketProductDateTimeDetail: {
        id,
        commonStatus,
        isSoldOut,
        ticketDateTime,
      },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        commonStatus={commonStatus}
        isSoldOut={isSoldOut}
        ticketDateTime={ticketDateTime}
      />
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
