import { gql } from "apollo-boost";

/* 묶음 시작 */
export const STORE_DELIVERY_ORDER_LIST_QUERY = gql`
  query StoreDeliveryOrderList(
    $skip: Int!
    $take: Int
    $startDate: String
    $endDate: String
    $deliveryDeliver_id: String
    $deliveryBox_id: String
  ) {
    StoreDeliveryOrderList(
      skip: $skip
      take: $take
      startDate: $startDate
      endDate: $endDate
      deliveryDeliver_id: $deliveryDeliver_id
      deliveryBox_id: $deliveryBox_id
    ) {
      deliveryOrders {
        id
        createdAt
        updatedAt

        state
        deliverAt
        deliveryOrderProducts {
          id
          createdAt
          updatedAt

          state
          price
          count
          totalPrice

          deliveryProduct {
            id
            createdAt
            updatedAt

            commonStatus
            timestamp
            coverImage
            price
            name
            description
          }
          deliveryProductOptionDetail1 {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
          deliveryProductOptionDetail2 {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
          deliveryProductOptionDetail3 {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }

        price
        refundPrice
        totalPrice

        type
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        dayOfWeek

        # completedAddress
        # completedLatitude
        # completedLongitude
        # completedAt

        # storeOrder
        deliveryBox {
          id
          createdAt
          updatedAt
        }

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
        # deliveryOrderSteps
        deliveryDeliver {
          id
          createdAt
          updatedAt

          state
          # deliveryOrders
          user {
            id
            createdAt
            updatedAt

            userId
            name
            phone
          }
        }
        user {
          id
          createdAt
          updatedAt

          userId
          name
          phone
        }
      }
      count
    }
  }
`;
export const STORE_DELIVERY_ORDER_DETAIL_QUERY = gql`
  query StoreDeliveryOrderDetail($id: String!) {
    StoreDeliveryOrderDetail(id: $id) {
      id
      createdAt
      updatedAt

      state
      deliverAt
      deliveryOrderProducts {
        id
        createdAt
        updatedAt

        state
        price
        count
        totalPrice

        deliveryProduct {
          id
          createdAt
          updatedAt

          commonStatus
          timestamp
          coverImage
          price
          name
          description
        }
        deliveryProductOptionDetail1 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail2 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail3 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }

      price
      refundPrice
      totalPrice

      type
      zipcode
      jibunAddress
      roadAddress
      addressDetail
      dayOfWeek

      # completedAddress
      # completedLatitude
      # completedLongitude
      # completedAt

      # storeOrder
      deliveryBox {
        id
        createdAt
        updatedAt
      }

      deliveryStore {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
      }
      # deliveryOrderSteps
      deliveryDeliver {
        id
        createdAt
        updatedAt

        state
        # deliveryOrders
        user {
          id
          createdAt
          updatedAt

          userId
          name
          phone
        }
      }
      user {
        id
        createdAt
        updatedAt

        userId
        name
        phone
      }
    }
  }
`;
export const STORE_DELIVERY_ORDER_PRODUCT_REFUND_MUTATION = gql`
  mutation StoreDeliveryOrderProductRefund($id: String!) {
    StoreDeliveryOrderProductRefund(id: $id)
  }
`;

export const STORE_DELIVERY_ORDER_PRODUCT_QUERY = gql`
  query StoreDeliveryOrderProduct(
    $startDate: String
    $endDate: String
    $deliveryDeliver_id: String
    $deliveryBox_id: String
  ) {
    StoreDeliveryOrderProduct(
      startDate: $startDate
      endDate: $endDate
      deliveryDeliver_id: $deliveryDeliver_id
      deliveryBox_id: $deliveryBox_id
    ) {
      deliveryProduct_id
      deliveryProduct_name
      deliveryProductOptionDetail1_name
      deliveryProductOptionDetail2_name
      deliveryProductOptionDetail3_name
      sum
      count
    }
  }
`;
