import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import UserStoreAsyncSelect from "../../../../Containers/User/StoreAsyncSelect";
import StoreDongSelect from "../../../../Containers/DeliveryStoreDong/MultiSelect";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_STORE_EDIT_MUTATION,
  ADMIN_DELIVERY_STORE_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

const Edit = ({ history, match, location, data, baseUrl }) => {
  const {
    AdminDeliveryStoreDetail: {
      id,
      createdAt,
      updatedAt,

      store,
      user,
      deliveryStoreDongs,
      deliveryPlaces,
    },
  } = data;

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      deliveryStoreDongs: deliveryStoreDongs.map((item) => ({
        value: item.id,
        label: `${item.sido} ${item.sigungu} ${item.upmyundong} ${item.ri}`,
      })),
    },
  });

  const [
    AdminDeliveryStoreEditMutation,
    { loading: AdminDeliveryStoreEditLoading },
  ] = useMutation(ADMIN_DELIVERY_STORE_EDIT_MUTATION, {
    refetchQueries: [
      {
        query: ADMIN_DELIVERY_STORE_LIST_QUERY,
        variables: {
          take: 15,
          skip: 0,
          keyword: "",
        },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (AdminDeliveryStoreEditLoading) {
        return;
      }
      const {
        data: { AdminDeliveryStoreEdit },
      } = await AdminDeliveryStoreEditMutation({
        variables: {
          id,
          deliveryStoreDong_ids: data.deliveryStoreDongs.map(
            (item) => item.value
          ),
        },
      });
      if (AdminDeliveryStoreEdit) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
      <div className="row m-b-15">
        <div className="col-xs-4 text-left">
          <Link to={`${baseUrl}${location.search}`} className="btn btn-white">
            목록
          </Link>
        </div>
        <div className="col-xs-4 text-center"></div>
        <div className="col-xs-4 text-right">
          <Link
            to={`/deliveryPlaces?deliveryStore_id=${id}`}
            className="btn btn-white"
          >
            픽업장소
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-sm-12">
          <Panel>
            <PanelHeader noButton>수정</PanelHeader>
            <PanelBody className="panel-form">
              <div className="form-horizontal form-bordered">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">매장명</label>
                  <div className="col-md-10">
                    {store.name} &gt; {user.name}
                  </div>
                </div>
                <Controller
                  control={control}
                  name="deliveryStoreDongs"
                  rules={{ required: "배달가능한 동을 선택해주세요." }}
                  render={({ onChange, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        배달가능한 동
                      </label>
                      <div className="col-md-10">
                        <StoreDongSelect
                          onChange={onChange}
                          value={value}
                          deliveryStore_id={id}
                        />
                        {errors.deliveryStoreDongs && (
                          <span className="text-red errorText">
                            {errors.deliveryStoreDongs.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row m-b-15">
        <div className="col-xs-4 text-left"></div>
        <div className="col-xs-4 text-center">
          <div className="btn-group">
            <button type="submit" className="btn btn-white text-sucess">
              확인
            </button>
          </div>
        </div>
        <div className="col-xs-4 text-right"></div>
      </div>
    </form>
  );
};

const Presenter = ({ history, match, location, loading, data, baseUrl }) => {
  return (
    <div>
      <Helmet>
        <title>매장 | 한밭페이 </title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`${baseUrl}`}>로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">매장</li>
      </ol>
      <h1 className="page-header">매장</h1>

      {!loading ? (
        <Edit
          history={history}
          match={match}
          location={location}
          data={data}
          baseUrl={baseUrl}
        />
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};

export default Presenter;
