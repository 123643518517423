import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { COUPON_USER_STOREORDER_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;
  const user = match.params.user;

  const { data, loading } = useQuery(COUPON_USER_STOREORDER_QUERY, {
    variables: {
      id,
      user,
    },
  });

  if (!loading) {
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        user={user}
        data={data}
        loading={loading}
      ></Presenter>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
