import { gql } from "apollo-boost";

export const GIFT_ORDER_LIST_QUERY = gql`
  query AdminGiftOrderList(
    $giftProduct: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminGiftOrderList(
      giftProduct: $giftProduct
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      giftOrders {
        id
        giftOrderProducts {
          id
          commonStatus
          user {
            id
            avatar
            userId
            name
          }

          totalPrice

          startDate
          endDate
          extensionCount

          isRefund
          meRefund

          createdAt
          updatedAt
        }

        commonStatus
        user {
          id
          userId
          name
        }
        receiver {
          id
          userId
          name
        }
        giftProduct {
          id
          giftCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          store {
            id
            name
          }
          coverImage
          name
        }
        isDiscount
        discountPrice
        price

        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice1
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice2
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice3
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice4
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetailPrice5

        count

        totalCount
        totalPrice

        message

        isRefund
        refundReceiver {
          id
        }
        refundTotalCount
        refundTotalPrice

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GIFT_ORDER_DETAIL_QUERY = gql`
  query AdminGiftOrderDetail($id: String!) {
    AdminGiftOrderDetail(id: $id) {
      id
      giftOrderProducts {
        id
        commonStatus
        user {
          id
          role
          avatar
          userId
          name
        }

        totalPrice

        startDate
        endDate
        extensionCount

        isRefund
        meRefund

        createdAt
        updatedAt
      }

      commonStatus
      user {
        id
        role
        userId
        name
      }
      receiver {
        id
        role
        userId
        name
      }
      giftProduct {
        id
        giftConfig {
          id
        }
        giftCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        store {
          id
          name
        }
        coverImage
        name
      }
      isDiscount
      discountPrice
      price

      giftProductOptionDetail1 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice1
      giftProductOptionDetail2 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice2
      giftProductOptionDetail3 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice3
      giftProductOptionDetail4 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice4
      giftProductOptionDetail5 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetailPrice5

      count

      totalCount
      totalPrice

      message

      isRefund
      refundReceiver {
        id
      }
      refundTotalCount
      refundTotalPrice

      createdAt
      updatedAt
    }
  }
`;

export const GIFT_ORDER_REFUND_QUERY = gql`
  mutation AdminGiftOrderRefund($id: String!, $giftOrderProductId: String!) {
    AdminGiftOrderRefund(id: $id, giftOrderProductId: $giftOrderProductId)
  }
`;
