import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { USER_DETAIL_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../Config/Env";

import {
  roleName,
  confirmedName,
  blockedName,
  currencyArray,
  currencyName,
  commonStatusName,
} from "../../../Components/User/UserNames";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(USER_DETAIL_QUERY, {
    variables: { id },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.IssuerUserDetail) {
    const {
      IssuerUserDetail: {
        id,
        commonStatus,
        role,
        confirmed,
        blocked,
        avatar,
        userId,

        name,
        email,
        phone,

        zipcode,
        roadAddress,
        jibunAddress,
        addressDetail,
        longitude,
        latitude,

        wallet,
        store,
        updatedAt,

        isCms,
        cmsValue,
        cmsDay,

        addCurrency,
      },
    } = data;

    const avatarImage =
      avatar === "" || avatar === null
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUri}${avatar}`;

    return (
      <>
        <Helmet>
          <title>사용자 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/users/${match.params.role}`}>사용자</Link>
            </li>
            <li className="breadcrumb-item active">
              {roleName(match.params.role)}
            </li>
          </ol>
          <h1 className="page-header">{roleName(match.params.role)}</h1>
          <div className="margin-bottom-0">
            <div className="row p-b-15">
              <div className="col-xs-4 text-left">
                <Link
                  to={`/users/${match.params.role}${location.search}`}
                  className="btn btn-white"
                >
                  목록
                </Link>
              </div>
              <div className="col-xs-4 text-center"></div>
              <div className="col-xs-4 text-right"></div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <Panel>
                  <PanelHeader noButton={true}>프로필</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="bg-white rounded">
                        <div className="form-group row">
                          <div className="col-md-12">
                            <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                              <div className="vertical-box-column valign-middle text-center">
                                <img
                                  src={avatarImage}
                                  alt="업로드 이미지"
                                  className="width-200"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
              <div className="col-sm-9">
                <Panel>
                  <PanelHeader noButton={true}>지역화폐</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>화폐</th>
                            <th>금액</th>
                            <th>기능 </th>
                          </tr>
                        </thead>
                        <tbody>
                          {wallet.trustlines.map((trustline) => {
                            return (
                              trustline.currency === addCurrency && (
                                <tr key={trustline.id}>
                                  <td>{currencyName(trustline.currency)}</td>
                                  <td align="right">
                                    <NumberFormat
                                      value={
                                        trustline.amount -
                                        currencyArray[trustline.currency]
                                          .startAmount
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      to={`/users/${match.params.role}/${id}/trustlines/${trustline.id}${location.search}`}
                                      className="btn btn-white btn-xs text-green"
                                    >
                                      내역
                                    </Link>
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                </Panel>

                <Panel>
                  <PanelHeader noButton={true}>사용자({name}님)</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">상태</label>
                        <div className="col-md-9">
                          {commonStatusName(commonStatus)}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">권한</label>
                        <div className="col-md-9">{roleName(role)}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          아이디
                        </label>
                        <div className="col-md-9">{userId.toLowerCase()}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          승인유무
                        </label>
                        <div className="col-md-9">
                          {confirmedName(confirmed)}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          차단유무
                        </label>
                        <div className="col-md-9">{blockedName(blocked)}</div>
                      </div>
                      {match.params.role === "User" && (
                        <>
                          <div className="form-group row">
                            <label className="col-form-label col-md-3">
                              이메일
                            </label>
                            <div className="col-md-9">
                              {email.toLowerCase()}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-form-label col-md-3">
                              휴대전화
                            </label>
                            <div className="col-md-9">{phone}</div>
                          </div>
                        </>
                      )}
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">주소</label>
                        <div className="col-md-9">
                          {zipcode}
                          <br />
                          도로명주소 : {roadAddress}
                          <br />
                          지번주소 : {jibunAddress}
                          <br />
                          상세주소 : {addressDetail}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">경도</label>
                        <div className="col-md-3">{longitude}</div>
                        <label className="col-form-label col-md-3">위도</label>
                        <div className="col-md-3">{latitude}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          정기결제(CMS)
                        </label>
                        <div className="col-md-9">
                          {isCms
                            ? `매월 ${cmsDay}일 ${String(cmsValue).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                              )}원 정기결제`
                            : "신청안함"}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          최종수정일자
                        </label>
                        <div className="col-md-9">
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {updatedAt}
                          </Moment>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
                {match.params.role === "Store" && store && (
                  <Panel>
                    <PanelHeader noButton={true}>연동 가맹점</PanelHeader>
                    <PanelBody className="p-0">
                      <div className="form-horizontal form-bordered">
                        <div className="form-group row">
                          <label className="col-form-label col-md-3">
                            이름
                          </label>
                          <div className="col-md-9">
                            <Link to={`/stores/${store.id}`}>{store.name}</Link>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-md-3">
                            사업자등록증
                          </label>
                          <div className="col-md-9">
                            <img
                              src={`${graphqlUri}${store.storeProofData}`}
                              alt={"사업자등록증"}
                            />
                          </div>
                        </div>
                      </div>
                    </PanelBody>
                  </Panel>
                )}
              </div>
            </div>

            <div className="row p-b-15">
              <div className="col-xs-4 text-left">
                <Link
                  to={`/users/${match.params.role}${location.search}`}
                  className="btn btn-white"
                >
                  목록
                </Link>
              </div>
              <div className="col-xs-4 text-center"></div>
              <div className="col-xs-4 text-right"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
