import React, { Fragment, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import {
  dayOfWeekShortName,
  deliveryOrderStateName,
  deliveryBoxTypeName,
  deliveryBoxProductStateName,
  pastPeriod,
} from "../../../../Components/User/UserNames";
import DeliveryStoreSelect from "../../../../Containers/DeliveryStore/Select";
import DeliveryDeliverSelect from "../../../../Containers/DeliveryDeliver/Select";
import DeliveryBoxAsyncSelect from "../../../../Containers/DeliveryBox/AsyncSelect";
import NumberFormat from "react-number-format";
import Moment from "moment";
import ReactMoment from "react-moment";
import Pagination from "../../../../Components/Pagination/QSP";
import DatePicker from "../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  // keyword,
  deliveryStore_id,
  startDate,
  endDate,
  deliveryDeliver_id,
  deliveryBox_id,
  page,
  take,
  loading,
  data,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
}) => {
  const onToDate = () => {
    const startDate = Moment().format("YYYY-MM-DD");
    const endDate = Moment().format("YYYY-MM-DD");

    const baseUrl =
      "?" +
      // (keyword ? "keyword=" + keyword + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onTomorrowDate = () => {
    const startDate = Moment().add(1, "d").format("YYYY-MM-DD");
    const endDate = Moment().add(1, "d").format("YYYY-MM-DD");

    console.log({ startDate, endDate });

    const baseUrl =
      "?" +
      // (keyword ? "keyword=" + keyword + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onDeliveryStoreChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      // (keyword ? "keyword=" + keyword + "&" : "") +
      (e.target.value ? "deliveryStore_id=" + e.target.value + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onDeliveryDeliverChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      // (keyword ? "keyword=" + keyword + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (e.target.value ? "deliveryDeliver_id=" + e.target.value + "&" : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };
  const onDeliveryBoxChange = (option) => {
    const baseUrl =
      "?" +
      // (keyword ? "keyword=" + keyword + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (option.value ? "deliveryBox_id=" + option.value + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${baseUrl}page=1`);
  };

  const defaultUrl =
    baseUrl +
    "?" +
    // (keyword ? "keyword=" + keyword + "&" : "") +
    (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
    (deliveryDeliver_id
      ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
      : "") +
    (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
    (startDate ? "startDate=" + startDate + "&" : "") +
    (endDate ? "endDate=" + endDate + "&" : "");

  return (
    <div>
      <Helmet>
        <title>배송 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/deliveryOrders">배송</Link>
        </li>
        <li className="breadcrumb-item active">배송내역</li>
      </ol>
      <h1 className="page-header">배송</h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({!loading && data.AdminDeliveryOrderList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row">
                <div className="col-xl-12"></div>
              </div>
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <InputGroup>
                    <DeliveryStoreSelect
                      onChange={onDeliveryStoreChange}
                      value={deliveryStore_id}
                    />
                    <InputGroupAddon addonType="append">
                      <Link className={"btn btn-white"} to={`/deliveryStores`}>
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <InputGroup>
                    <DeliveryDeliverSelect
                      onChange={onDeliveryDeliverChange}
                      value={deliveryDeliver_id}
                    />
                    <InputGroupAddon addonType="append">
                      <Link
                        className={"btn btn-white"}
                        to={`/deliveryDelivers`}
                      >
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <DeliveryBoxAsyncSelect
                    onChange={onDeliveryBoxChange}
                    value={deliveryBox_id}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-8">
                  <DatePicker
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    onStartDateSelect={onStartDateSelect}
                    onEndDateSelect={onEndDateSelect}
                    onPeriodConfig={onPeriodConfig}
                    period={pastPeriod}
                  />
                </div>
                <div className="col-xs-4 text-right">
                  <button
                    className="btn btn-white mr-2 text-truncate"
                    onClick={onToDate}
                  >
                    오늘
                  </button>
                  <button
                    className="btn btn-white mr-2 text-truncate"
                    onClick={onTomorrowDate}
                  >
                    내일
                  </button>
                  <Link
                    className="btn btn-white mr-2 text-truncate"
                    to={`/deliveryOrdersProduct/print${location.search}`}
                  >
                    <i className="fa fa-print"></i> 배송상품
                  </Link>
                  <Link
                    className="btn btn-white"
                    to={`/deliveryOrdersOrder/print${location.search}`}
                  >
                    <i className="fa fa-print"></i> 운송장
                  </Link>
                </div>
              </div>
            </Alert>

            {!loading ? (
              <Fragment>
                {data.AdminDeliveryOrderList.count > 0 ? (
                  <>
                    <PanelBody>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped m-b-0">
                          <colgroup>
                            <col width="6%" />
                            <col width="15%" />
                            <col width="*" />
                            <col width="15%" />
                            <col width="10%" />
                            <col width="10%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-nowrap text-center">상태</th>
                              <th className="text-nowrap text-center">
                                구독박스정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송기사 정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송일
                              </th>
                              <th className="text-nowrap text-center">기능</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.AdminDeliveryOrderList.deliveryOrders.map(
                              (item, index) => {
                                const productString = item.deliveryOrderProducts
                                  .map((product) => {
                                    const option =
                                      (product.deliveryProductOptionDetail1 ||
                                        product.deliveryProductOptionDetail2 ||
                                        product.deliveryProductOptionDetail3) &&
                                      `${
                                        product.deliveryProductOptionDetail1
                                          ? `${product.deliveryProductOptionDetail1.name}`
                                          : ``
                                      }
                                          ${
                                            product.deliveryProductOptionDetail2
                                              ? `/${product.deliveryProductOptionDetail2.name}`
                                              : ``
                                          }
                                          ${
                                            product.deliveryProductOptionDetail3
                                              ? `/${product.deliveryProductOptionDetail3.name}`
                                              : ``
                                          }`;
                                    return `${product.deliveryProduct.name}${
                                      option ? `[${option}]` : ""
                                    }(${product.count}개)`;
                                  })
                                  .join(", ");

                                const date = new Date(item.deliverAt);
                                const deliveryDayOfWeek = date.getDay();

                                return (
                                  <tr key={index}>
                                    <td>
                                      {deliveryOrderStateName(item.state)}
                                    </td>
                                    <td>
                                      <div>
                                        이름 : {item.user.name}(
                                        {item.user.userId})
                                      </div>
                                      <div>연락처 : {item.user.phone}</div>
                                    </td>
                                    <td>
                                      <div>
                                        매장 : {item.deliveryStore.store.name}{" "}
                                        &gt; {item.deliveryStore.user.name}
                                      </div>
                                      <div>
                                        타입 : {deliveryBoxTypeName(item.type)}
                                      </div>
                                      <div>
                                        주소 : {item.roadAddress}{" "}
                                        {item.addressDetail}
                                      </div>
                                      <div>배송상품 : {productString}</div>
                                      <div>
                                        주문금액 :{" "}
                                        <NumberFormat
                                          value={item.totalPrice}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                        드림
                                      </div>
                                    </td>
                                    <td>
                                      {item.deliveryDeliver ? (
                                        <Fragment>
                                          <div>
                                            이름 :{" "}
                                            {item.deliveryDeliver.user.name}(
                                            {item.deliveryDeliver.user.userId})
                                          </div>
                                          <div>
                                            연락처 :
                                            {item.deliveryDeliver.user.phone}
                                          </div>
                                        </Fragment>
                                      ) : (
                                        <div className="text-center">
                                          미배정
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <div>
                                        <ReactMoment format="YYYY-MM-DD">
                                          {item.deliverAt}
                                        </ReactMoment>
                                        ({dayOfWeekShortName(deliveryDayOfWeek)}
                                        )
                                      </div>
                                    </td>
                                    <td className="text-nowrap text-center">
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`${baseUrl}/${item.id}${location.search}`}
                                        >
                                          상세정보
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/deliveryBoxes/${item.deliveryBox.id}`}
                                        >
                                          구독박스정보
                                        </Link>
                                      </div>
                                      {item.deliveryDeliver && (
                                        <div>
                                          <Link
                                            className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                            to={`/deliveryDelivers?deliveryDeliver_id=${item.deliveryDeliver.id}`}
                                          >
                                            배송기사정보
                                          </Link>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                    <PanelFooter>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.AdminDeliveryOrderList.count}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={defaultUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </PanelFooter>
                  </>
                ) : (
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                )}
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};
