import { gql } from "apollo-boost";

/* 공지사항 설정 */
export const NOTICE_CONFIG_QUERY = gql`
  query AdminNoticeConfig($id: String) {
    AdminNoticeConfig(id: $id) {
      id
      name
      listCount
      pageCount
    }
  }
`;

export const NOTICE_CONFIG_WRITE_QUERY = gql`
  mutation AdminNoticeConfigWrite(
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminNoticeConfigWrite(
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

export const NOTICE_CONFIG_EDIT_QUERY = gql`
  mutation AdminNoticeConfigEdit(
    $id: String
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminNoticeConfigEdit(
      id: $id
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

/* 공지사항 */
export const NOTICE_LIST_QUERY = gql`
  query AdminNoticeList(
    $commonApp: String
    $noticeConfig: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminNoticeList(
      commonApp: $commonApp
      noticeConfig: $noticeConfig
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      notices {
        id
        title
        contents
        readCount
        user {
          id
          name
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const NOTICE_WRITE_QUERY = gql`
  mutation AdminNoticeWrite(
    $commonApp: String
    $noticeConfig: String
    $isPush: Boolean
    $title: String!
    $contents: String!
  ) {
    AdminNoticeWrite(
      commonApp: $commonApp
      noticeConfig: $noticeConfig
      isPush: $isPush
      title: $title
      contents: $contents
    )
  }
`;

export const NOTICE_DETAIL_QUERY = gql`
  query AdminNoticeDetail($id: String!) {
    AdminNoticeDetail(id: $id) {
      id
      title
      contents
      readCount
      user {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const NOTICE_EDIT_QUERY = gql`
  mutation AdminNoticeEdit(
    $id: String
    $isPush: Boolean
    $title: String!
    $contents: String!
  ) {
    AdminNoticeEdit(
      id: $id
      isPush: $isPush
      title: $title
      contents: $contents
    )
  }
`;

export const NOTICE_DELETE_QUERY = gql`
  mutation AdminNoticeDelete($id: String!) {
    AdminNoticeDelete(id: $id)
  }
`;
