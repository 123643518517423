import React from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../Components/Panel/Panel";
import { graphqlUri } from "../../Config/Env";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_PRODUCT_LIST_QUERY,
  ADMIN_DELIVERY_PRODUCT_COVER_UPLOAD_MUTATION,
  ADMIN_DELIVERY_PRODUCT_COVER_DELETE_MUTATION,
  ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
} from "../../Routes/Administrator/DeliveryProduct/Queries";
import Swal from "sweetalert2";

const CoverImage = withRouter(
  ({ history, match, location, id, coverImage, baseUrl }) => {
    const [AdminDeliveryProductCoverImageUploadMutation] = useMutation(
      ADMIN_DELIVERY_PRODUCT_COVER_UPLOAD_MUTATION,
      {
        refetchQueries: () => [
          {
            query: ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
            variables: { id },
          },
        ],
      }
    );

    const [AdminDeliveryProductCoverImageDeleteMutation] = useMutation(
      ADMIN_DELIVERY_PRODUCT_COVER_DELETE_MUTATION,
      {
        refetchQueries: () => [
          {
            query: ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
            variables: { id },
          },
        ],
      }
    );

    const handleCoverImage = async (e) => {
      e.preventDefault();

      const files = e.target.files;
      const file = files[0];

      try {
        const {
          data: { AdminDeliveryProductCoverImageUpload },
        } = await AdminDeliveryProductCoverImageUploadMutation({
          variables: {
            id,
            coverImage: file,
          },
        });

        if (AdminDeliveryProductCoverImageUpload) {
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}/${id}/edit${location.search}`);
            }
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        });
      }
    };
    const onClickCoverImage = () => {
      document.getElementById("coverImage").click();
    };
    const onDeleteCoverImage = async (id) => {
      try {
        const {
          data: { AdminDeliveryProductCoverImageDelete },
        } = await AdminDeliveryProductCoverImageDeleteMutation({
          variables: {
            id,
          },
        });
        if (AdminDeliveryProductCoverImageDelete) {
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}/${id}/edit${location.search}`);
            }
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        });
      }
    };

    const cover =
      coverImage === "" || coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${coverImage}`;

    return (
      <Panel>
        <PanelHeader noButton>커버 이미지</PanelHeader>
        <PanelBody className="p-0">
          <div className="form-horizontal form-bordered">
            <div className="bg-white rounded">
              <div className="form-group row">
                <div className="col-md-12">
                  <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                    <div className="vertical-box-column valign-middle text-center">
                      <img
                        src={cover}
                        alt="이미지 업로드"
                        className="width-200"
                      />
                    </div>
                  </div>
                  <div className="m-t-10">
                    <input
                      type="file"
                      id="coverImage"
                      onChange={handleCoverImage}
                      className="hide"
                    />
                    <button
                      className="btn btn-primary btn-block"
                      onClick={onClickCoverImage}
                    >
                      이미지 업로드
                    </button>
                    <button
                      type="button"
                      color="white"
                      className="btn btn-white btn-block text-red"
                      onClick={() => {
                        onDeleteCoverImage(id);
                      }}
                    >
                      이미지 삭제
                    </button>
                  </div>
                  <div className="alert alert-primary fade show m-t-10 m-b-0">
                    <b>커버 이미지 사이즈 :</b> 넓이 600px X 높이 600px
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PanelBody>
      </Panel>
    );
  }
);

export default CoverImage;
