import { gql } from "apollo-boost";

/* 가맹점 분류 */
export const STORE_CATEGORY_LIST_QUERY = gql`
  query AdminStoreCategoryList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreCategoryList(
      keyword: $keyword
      orderBy: "order_DESC"
      skip: $skip
      first: $first
    ) {
      storeCategories {
        id
        storesCount
        pcIcon
        mobileIcon
        name
        isGift
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const STORE_CATEGORY_WRITE_QUERY = gql`
  mutation AdminStoreCategoryWrite(
    $pcIcon: String!
    $mobileIcon: String!
    $name: String!
    $isGift: Boolean
  ) {
    AdminStoreCategoryWrite(
      pcIcon: $pcIcon
      mobileIcon: $mobileIcon
      name: $name
      isGift: $isGift
    )
  }
`;
export const STORE_CATEGORY_DETAIL_QUERY = gql`
  query AdminStoreCategoryDetail($id: String!) {
    AdminStoreCategoryDetail(id: $id) {
      id
      pcIcon
      mobileIcon
      name
      isGift
      createdAt
      updatedAt
    }
  }
`;
export const STORE_CATEGORY_EDIT_QUERY = gql`
  mutation AdminStoreCategoryEdit(
    $id: String
    $pcIcon: String!
    $mobileIcon: String!
    $name: String!
    $isGift: Boolean
  ) {
    AdminStoreCategoryEdit(
      id: $id
      pcIcon: $pcIcon
      mobileIcon: $mobileIcon
      name: $name
      isGift: $isGift
    )
  }
`;
export const STORE_CATEGORY_DELETE_QUERY = gql`
  mutation AdminStoreCategoryDelete($id: String!) {
    AdminStoreCategoryDelete(id: $id)
  }
`;
export const STORE_CATEGORY_PREVMOVE_QUERY = gql`
  mutation AdminStoreCategoryPrevMove($id: String!) {
    AdminStoreCategoryPrevMove(id: $id)
  }
`;
export const STORE_CATEGORY_NEXTMOVE_QUERY = gql`
  mutation AdminStoreCategoryNextMove($id: String!) {
    AdminStoreCategoryNextMove(id: $id)
  }
`;

/* 가맹점 */
export const STORE_LIST_QUERY = gql`
  query AdminStoreList(
    $category: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminStoreList(
      category: $category
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id
        commonStatus
        storeCategory {
          id
          name
        }
        storeCategories {
          id
          name
        }
        storeProductsCount

        user {
          id
          role
          userId
          name
        }

        storeLogo
        name
        productInfo
        storeInfo

        storeOperationHour
        storePersonalDay
        storeTel
        storeCEO
        email
        phone

        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail

        isGift
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const STORE_WRITE_QUERY = gql`
  mutation AdminStoreWrite(
    $storeCategory: String!
    $name: String!
    $productInfo: String!
    $storeInfo: String!
    $storeOperationHour: String!
    $storePersonalDay: String!
    $storeTel: String!
    $storeCEO: String!
    $storeZipcode: String!
    $storeRoadAddress: String!
    $storeJibunAddress: String!
    $storeAddressDetail: String!
  ) {
    AdminStoreWrite(
      storeCategory: $storeCategory
      name: $name
      productInfo: $productInfo
      storeInfo: $storeInfo

      storeOperationHour: $storeOperationHour
      storePersonalDay: $storePersonalDay
      storeTel: $storeTel
      storeCEO: $storeCEO

      storeZipcode: $storeZipcode
      storeRoadAddress: $storeRoadAddress
      storeJibunAddress: $storeJibunAddress
      storeAddressDetail: $storeAddressDetail
    )
  }
`;
export const STORE_DETAIL_QUERY = gql`
  query AdminStoreDetail($id: String!) {
    AdminStoreDetail(id: $id) {
      id
      commonStatus
      UserId
      storeCategory {
        id
        name
      }
      storeCategories {
        id
        name
      }

      user {
        id
        role
        userId
        name
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
      }

      storeLogo
      name
      productInfo
      storeInfo

      storeNumber
      storeOperationHour
      storePersonalDay
      storeTel
      storeCEO
      email
      phone

      storeZipcode
      storeRoadAddress
      storeJibunAddress
      storeAddressDetail
      longitude
      latitude

      bank_name
      bank_num
      bank_account_holder

      storeProofData

      isGift
      giftedAt

      drmPercent
      gruPercent

      createdAt
      updatedAt
    }
  }
`;
export const STORE_EDIT_QUERY = gql`
  mutation AdminStoreEdit(
    $id: String
    $commonStatus: String
    $storeCategory: String!
    $storeCategories: [String]
    $name: String!
    $productInfo: String!
    $storeInfo: String!
    $storeOperationHour: String!
    $storePersonalDay: String!
    $storeTel: String!
    $storeCEO: String!
    $email: String
    $phone: String
    $storeNumber: String!
    $storeZipcode: String!
    $storeRoadAddress: String!
    $storeJibunAddress: String!
    $storeAddressDetail: String!
    $longitude: String
    $latitude: String
    $bank_name: String
    $bank_num: String
    $bank_account_holder: String
    $isGift: Boolean
    $giftedAt: String
    $drmPercent: String
    $gruPercent: String
  ) {
    AdminStoreEdit(
      id: $id
      commonStatus: $commonStatus
      storeCategory: $storeCategory
      storeCategories: $storeCategories
      name: $name
      productInfo: $productInfo
      storeInfo: $storeInfo

      storeNumber: $storeNumber
      storeOperationHour: $storeOperationHour
      storePersonalDay: $storePersonalDay
      storeTel: $storeTel
      storeCEO: $storeCEO
      email: $email
      phone: $phone

      storeZipcode: $storeZipcode
      storeRoadAddress: $storeRoadAddress
      storeJibunAddress: $storeJibunAddress
      storeAddressDetail: $storeAddressDetail
      longitude: $longitude
      latitude: $latitude

      bank_name: $bank_name
      bank_num: $bank_num
      bank_account_holder: $bank_account_holder

      isGift: $isGift
      giftedAt: $giftedAt

      drmPercent: $drmPercent
      gruPercent: $gruPercent
    )
  }
`;
export const STORE_EDIT_LOGO_QUERY = gql`
  mutation AdminStoreEditLogo($id: String!, $file: FileUpload!) {
    AdminStoreEditLogo(id: $id, file: $file)
  }
`;
export const STORE_DELETE_LOGO_QUERY = gql`
  mutation AdminStoreDeleteLogo($id: String!) {
    AdminStoreDeleteLogo(id: $id)
  }
`;
export const STORE_EDIT_PROOFDATA_QUERY = gql`
  mutation AdminStoreEditProofData($id: String!, $file: FileUpload!) {
    AdminStoreEditProofData(id: $id, file: $file)
  }
`;
export const STORE_DELETE_PROOFDATA_QUERY = gql`
  mutation AdminStoreDeleteProofData($id: String!) {
    AdminStoreDeleteProofData(id: $id)
  }
`;
export const STORE_DELETE_QUERY = gql`
  mutation AdminStoreDelete($id: String!) {
    AdminStoreDelete(id: $id)
  }
`;
export const STORE_ADD_CURRENCY_QUERY = gql`
  mutation AdminStoreAddCurrency($id: String!, $currency: String!) {
    AdminStoreAddCurrency(id: $id, currency: $currency)
  }
`;

/* 가맹점 상품 */
export const STORE_PRODUCT_LIST_QUERY = gql`
  query AdminStoreProductList(
    $store: String!
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminStoreProductList(
      store: $store
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      storeProducts {
        id
        storeProductImage
        name
        description
        price
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const STORE_PRODUCT_WRITE_QUERY = gql`
  mutation AdminStoreProductWrite(
    $store: String!
    $file: FileUpload!
    $name: String!
    $description: String!
    $price: String!
  ) {
    AdminStoreProductWrite(
      store: $store
      file: $file
      name: $name
      description: $description
      price: $price
    )
  }
`;
export const STORE_PRODUCT_DETAIL_QUERY = gql`
  query AdminStoreProductDetail($id: String!) {
    AdminStoreProductDetail(id: $id) {
      id
      storeProductImage
      name
      description
      price
      createdAt
      updatedAt
    }
  }
`;
export const STORE_PRODUCT_EDIT_QUERY = gql`
  mutation AdminStoreProductEdit(
    $store: String!
    $id: String!
    $file: FileUpload
    $name: String!
    $description: String!
    $price: String!
  ) {
    AdminStoreProductEdit(
      store: $store
      id: $id
      file: $file
      name: $name
      description: $description
      price: $price
    )
  }
`;
export const STORE_PRODUCT_DELETE_QUERY = gql`
  mutation AdminStoreProductDelete($id: String!) {
    AdminStoreProductDelete(id: $id)
  }
`;

/* 가맹점 구성원 */
export const STORE_EMPLOYEE_LIST_QUERY = gql`
  query AdminStoreEmployeeList($UserId: String!) {
    AdminStoreEmployeeList(UserId: $UserId) {
      id
      role
      confirmed
      blocked
      avatar
      userId
      email
      phone
      name
      sex
      birthDate
      createdAt
      updatedAt
      employees {
        id
        userId
        name
      }
    }
  }
`;
export const STORE_EMPLOYEE_DETAIL_QUERY = gql`
  query AdminStoreEmployeeDetail($UserId: String!) {
    AdminStoreEmployeeDetail(UserId: $UserId) {
      id
      role
      confirmed
      blocked
      avatar
      userId
      email
      phone
      name
      sex
      birthDate
      createdAt
      updatedAt
    }
  }
`;
