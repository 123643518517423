import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { USER_EDIT_QUERY, USER_DETAIL_QUERY } from "../Queries";
import { useMutation } from "react-apollo-hooks";
import DaumPostcode from "react-daum-postcode";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Moment from "react-moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { roleName } from "../../../../Components/User/UserNames";
import { toast } from "react-toastify";

export default ({
  history,
  match,
  location,
  data,

  isModal,
}) => {
  const modalRef = React.createRef();

  const {
    AdminUserDetail: {
      id,
      role,
      confirmed,
      blocked,

      userId,
      phone,
      email,

      zipcode,
      roadAddress,
      jibunAddress,
      addressDetail,
      longitude,
      latitude,
      addCurrency,

      updatedAt,

      isCms,
      cmsValue,
      cmsDay,
    },
  } = data;

  const [userEditMutation] = useMutation(USER_EDIT_QUERY, {
    refetchQueries: () => [{ query: USER_DETAIL_QUERY, variables: { id } }],
  });

  const { register, handleSubmit, setValue, errors, watch } = useForm({
    defaultValues: {
      userId: userId,
      role: role,
      confirmed: confirmed,
      blocked: blocked,

      email: email,
      phone: phone,

      zipcode: zipcode,
      roadAddress: roadAddress,
      jibunAddress: jibunAddress,
      addressDetail: addressDetail,
      longitude: longitude,
      latitude: latitude,
      addCurrency: addCurrency,

      isCms: isCms ? isCms : 0,
      cmsDay: cmsDay ? cmsDay : 0,
      cmsValue: isCms
        ? cmsValue === 10000 ||
          cmsValue === 50000 ||
          cmsValue === 100000 ||
          cmsValue === 150000 ||
          cmsValue === 200000
          ? cmsValue
          : "etc"
        : 0,
      etc_amount: cmsValue,
    },
  });

  const [zipcodeModal, setZipcodeModal] = useState(false);
  const handleZipcodeModal = () => {
    setZipcodeModal(!zipcodeModal);
  };
  const handleAddress = (data) => {
    const API_KEY = "7da7d093496c67033d3e14aa3102e838";
    const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&size=15`;
    fetch(url, {
      headers: {
        Authorization: `KakaoAK ${API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log({ json });
        setValue("zipcode", data.zonecode);
        setValue("roadAddress", data.roadAddress);
        setValue("jibunAddress", data.jibunAddress);

        setValue("longitude", json.documents[0].x);
        setValue("latitude", json.documents[0].y);

        handleZipcodeModal();
      });
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (!regex.test(e.target.value)) {
      setValue("authPassword", "");
    }
  };

  const onSubmit = async (data) => {
    if (data.isCms === "true" && data.cmsValue === "etc") {
      if (data.cmsValue === "") {
        toast.error("금액을 입력해주세요.");
        return;
      }
      if (data.cmsValue < 0) {
        toast.error("금액을 양수로 입력해주세요.");
        return;
      }
    }

    try {
      const {
        data: { AdminUserEdit },
      } = await userEditMutation({
        variables: {
          id: id,
          role: data.role,
          confirmed: data.confirmed === "true" ? true : false,
          blocked: data.blocked === "true" ? true : false,
          email: data.email,
          phone: data.phone,
          sex: data.sex,
          birthDate: new Date().toISOString().substr(0, 10),
          zipcode: data.zipcode,
          roadAddress: data.roadAddress,
          jibunAddress: data.jibunAddress,
          addressDetail: data.addressDetail,
          longitude: data.longitude,
          latitude: data.latitude,
          addCurrency: data.addCurrency,

          isCms: data.isCms === "true" ? true : false,
          cmsValue: data.cmsValue !== "etc" ? data.cmsValue : data.etc_amount,
          cmsDay: data.cmsDay,
        },
      });
      if (AdminUserEdit) {
        toast.success("처리 되었습니다.");
        history.push(`/users/${match.params.role}/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/users/${match.params.role}/${id}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/users/${match.params.role}/${id}${location.search}`
              );
            }}
          >
            수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-3">권한</label>
                <div className="col-md-9">
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="role1"
                      name="role"
                      value={"Administrator"}
                      ref={register({
                        required: "권한을 선택해주세요.",
                      })}
                    />
                    <label htmlFor="role1">{roleName("Administrator")}</label>
                  </div>
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="role2"
                      name="role"
                      value={"Issuer"}
                      ref={register({
                        required: "권한을 선택해주세요.",
                      })}
                    />
                    <label htmlFor="role2">{roleName("Issuer")}</label>
                  </div>
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="role3"
                      name="role"
                      value={"Store"}
                      ref={register({
                        required: "권한을 선택해주세요.",
                      })}
                    />
                    <label htmlFor="role3">{roleName("Store")}</label>
                  </div>
                  <div className="radio radio-css radio-inline">
                    <input
                      type="radio"
                      id="role4"
                      name="role"
                      value={"User"}
                      ref={register({
                        required: "권한을 선택해주세요.",
                      })}
                    />
                    <label htmlFor="role4">{roleName("User")}</label>
                  </div>
                  {errors.role && <span>{errors.role.message}</span>}
                </div>
              </div>
              {role === "Issuer" && (
                <div className="form-group row">
                  <label className="col-form-label col-md-3">
                    관리 화폐 {addCurrency}
                  </label>
                  <div className="col-md-9">
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="addCurrencyGJP"
                        name="addCurrency"
                        value={"GJP"}
                        ref={register()}
                      />
                      <label htmlFor="addCurrencyGJP">관저페이</label>
                    </div>
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="addCurrencyTOL"
                        name="addCurrency"
                        value={"TOL"}
                        ref={register()}
                      />
                      <label htmlFor="addCurrencyTOL">톨</label>
                    </div>
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="addCurrencyGRU"
                        name="addCurrency"
                        value={"GRU"}
                        ref={register()}
                      />
                      <label htmlFor="addCurrencyGRU">그루</label>
                    </div>
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="addCurrencySTR"
                        name="addCurrency"
                        value={"STR"}
                        ref={register()}
                      />
                      <label htmlFor="addCurrencySTR">별</label>
                    </div>
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="addCurrencyNDO"
                        name="addCurrency"
                        value={"NDO"}
                        ref={register()}
                      />
                      <label htmlFor="addCurrencyNDO">내동</label>
                    </div>
                    {errors.addCurrency && (
                      <span>{errors.addCurrency.message}</span>
                    )}
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label className="col-form-label col-md-3">아이디</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="userId"
                    className="form-control"
                    readOnly
                    ref={register()}
                  />
                  {errors.userId && <span>{errors.userId.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">승인유무</label>
                <div className="col-md-9">
                  <div className="checkbox checkbox-css checkbox-inline">
                    <input
                      type="checkbox"
                      id="confirmed"
                      name="confirmed"
                      value={true}
                      ref={register()}
                    />
                    <label htmlFor="confirmed">승인</label>
                    {errors.confirmed && (
                      <span>{errors.confirmed.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">차단유무</label>
                <div className="col-md-9">
                  <div className="checkbox checkbox-css checkbox-inline">
                    <input
                      type="checkbox"
                      id="blocked"
                      name="blocked"
                      value={true}
                      ref={register()}
                    />
                    <label htmlFor="blocked">차단</label>
                    {errors.blocked && <span>{errors.blocked.message}</span>}
                  </div>
                </div>
              </div>
              <div className={"form-group row"}>
                <label className="col-form-label col-md-3">이메일</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    ref={register({
                      required: "이메일를 입력해주세요.",
                    })}
                  />
                  {errors.email && <span>{errors.email.message}</span>}
                </div>
              </div>
              <div className={"form-group row"}>
                <label className="col-form-label col-md-3">휴대전화</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    className="form-control"
                    ref={register({
                      required: "휴대전화를 입력해주세요.",
                    })}
                  />
                  {errors.phone && <span>{errors.phone.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">우편번호</label>
                <div className="col-md-9">
                  <InputGroup size="sm">
                    <input
                      type="text"
                      name="zipcode"
                      className="form-control"
                      placeholder="우편번호를 입력해주세요."
                      ref={register({
                        required: "우편번호을 입력해주세요.",
                      })}
                      readOnly
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        size="sm"
                        color="primary"
                        onClick={handleZipcodeModal}
                      >
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  {errors.zipcode && <span>{errors.zipcode.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">도로명주소</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="roadAddress"
                    className="form-control"
                    placeholder="도로명주소를 입력해주세요."
                    ref={register({
                      required: "도로명주소를 입력해주세요.",
                    })}
                    readOnly
                  />
                  {errors.roadAddress && (
                    <span>{errors.roadAddress.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">지번주소</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="jibunAddress"
                    className="form-control"
                    placeholder="지번주소를 입력해주세요."
                    ref={register({
                      required: "지번주소를 입력해주세요.",
                    })}
                    readOnly
                  />
                  {errors.jibunAddress && (
                    <span>{errors.jibunAddress.message}</span>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3">경도</label>
                <div className="col-md-3">
                  <input
                    type="text"
                    name="longitude"
                    className="form-control"
                    placeholder="경도를 입력해주세요."
                    // ref={register({
                    //   required: "경도를 입력해주세요."
                    // })}
                    readOnly
                  />
                  {errors.longitude && <span>{errors.longitude.message}</span>}
                </div>
                <label className="col-form-label col-md-3">위도</label>
                <div className="col-md-3">
                  <input
                    type="text"
                    name="latitude"
                    className="form-control"
                    placeholder="위도를 입력해주세요."
                    // ref={register({
                    //   required: "위도를 입력해주세요."
                    // })}
                    readOnly
                  />
                  {errors.latitude && <span>{errors.latitude.message}</span>}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-md-3">상세주소</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="addressDetail"
                    className="form-control"
                    placeholder="상세주소를 입력해주세요."
                    ref={register({
                      required: "상세주소를 입력해주세요.",
                    })}
                  />
                  {errors.addressDetail && (
                    <span>{errors.addressDetail.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">CMS</label>
                <div className="col-md-9">
                  <div className="form-inline">
                    <div className="checkbox checkbox-css p-t-0 p-r-10">
                      <input
                        type="checkbox"
                        id="isCms"
                        name="isCms"
                        value={true}
                        ref={register()}
                      />
                      <label htmlFor="isCms">신청 </label>
                    </div>
                    매월
                    <select
                      className="form-control"
                      name="cmsDay"
                      ref={register({
                        validate: (value) =>
                          watch("isCms") !== false && value === "0"
                            ? "일수를 선택해주세요."
                            : undefined,
                      })}
                    >
                      <option key={`day`} value={0}>
                        선택
                      </option>
                      <option key={`day5`} value={5}>
                        5 일
                      </option>
                      <option key={`day25`} value={25}>
                        25 일
                      </option>
                    </select>
                    <select
                      className="form-control"
                      name="cmsValue"
                      ref={register({
                        validate: (value) =>
                          watch("isCms") !== false && value === "0"
                            ? "금액을 선택해주세요."
                            : undefined,
                      })}
                    >
                      <option key={`value`} value={0}>
                        선택
                      </option>
                      <option key={`value10000`} value={10000}>
                        10,000원
                      </option>
                      <option key={`value50000`} value={50000}>
                        50,000원
                      </option>
                      <option key={`value100000`} value={100000}>
                        100,000원
                      </option>
                      <option key={`value150000`} value={150000}>
                        150,000원
                      </option>
                      <option key={`value200000`} value={200000}>
                        200,000원
                      </option>
                      <option key={`valueEtc`} value={"etc"}>
                        기타
                      </option>
                    </select>
                    {watch("cmsValue") === "etc" && (
                      <input
                        type="number"
                        name="etc_amount"
                        className="form-control width-200"
                        placeholder="금액을 입력해주세요."
                        ref={register()}
                        style={{ display: "inline-block" }}
                      />
                    )}
                    정기결제
                    {errors.cmsDay && <span>{errors.cmsDay.message}</span>}
                    {errors.cmsValue && <span>{errors.cmsValue.message}</span>}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">최종수정일자</label>
                <div className="col-md-9">
                  <Moment format="YYYY-MM-DD HH:mm:ss">{updatedAt}</Moment>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
        <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode height={700} onComplete={handleAddress} />
        </ModalBody>
      </Modal>
    </>
  );
};
