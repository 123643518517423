import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  TICKET_ORDERPRODUCT_DETAIL_QUERY,
  TICKET_ORDERPRODUCT_REFUND_QUERY,
  TICKET_ORDERPRODUCT_USED_QUERY,
} from "../Queries";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";

import ReactMoment from "react-moment";
import moment from "moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";

import { dayOfWeekName } from "../../../../Components/User/UserNames";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(TICKET_ORDERPRODUCT_DETAIL_QUERY, {
    variables: { id },
  });

  const [orderProductRefundMutation] = useMutation(
    TICKET_ORDERPRODUCT_REFUND_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_ORDERPRODUCT_DETAIL_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );
  const [orderProductUsedMutation] = useMutation(
    TICKET_ORDERPRODUCT_USED_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_ORDERPRODUCT_DETAIL_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );
  const onRefund = async (id, ticketOrderProductId) => {
    console.log("onRefund");
    if (!window.confirm("티켓을 환불하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminTicketOrderRefund },
      } = await orderProductRefundMutation({
        variables: { id, ticketOrderProductId },
      });

      if (AdminTicketOrderRefund) {
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onUsed = async (id) => {
    console.log("onUsed");
    if (!window.confirm("티켓을 사용하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminTicketOrderProductUsed },
      } = await orderProductUsedMutation({
        variables: { id },
      });

      if (AdminTicketOrderProductUsed) {
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    const {
      AdminTicketOrderProductDetail: {
        id,
        commonStatus,

        isReservation,
        isMember,
        user,
        name,
        phone,

        isDiscount,
        discountPrice,
        price,
        count,
        totalPrice,

        ticketProduct,
        ticketOrder,
        ticketOrderProductPeoples,
        ticketProductReviews,

        ticketProductDate,
        ticketProductDateTime,

        isRefund,
        meRefund,

        isRead,
        usedAt,
        createdAt,
        updatedAt,
      },
    } = data;

    const CoverImage =
      ticketProduct.coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${ticketProduct.coverImage}`;

    const toDay = new Date();
    const endDateObj = isReservation
      ? new Date(ticketProductDateTime?.ticketDateTime)
      : new Date(ticketProduct.applyEndDateTime);
    const gap = endDateObj.getTime() - toDay.getTime();
    const dDay = Math.ceil(gap / (1000 * 60 * 60 * 24));

    const startDate = new Date(ticketProductDateTime?.ticketDateTime);
    const endDate = moment(ticketProductDateTime?.ticketDateTime).add(
      parseInt(ticketProduct.minute),
      "minutes"
    );

    return (
      <>
        <Helmet>
          <title>예매내역 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">예매내역</li>
          </ol>
          <h1 className="page-header">예매내역</h1>
          <div className="row m-b-15">
            <div className="col-md-6 text-left">
              <Link
                to={`/ticketOrderProducts${location.search}`}
                className="btn btn-white"
              >
                <i className="fa fa-list fa-fw"></i> 목록
              </Link>
            </div>
            <div className="col-md-6 text-right">
              <Link
                to={`/ticketOrders/${ticketOrder.id}`}
                className="btn btn-white text-blue"
              >
                상세구매내역
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>선물정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="invoice order-product">
                    <div className="invoice-company text-inverse f-w-600">
                      <span className="pull-right hidden-print">
                        <Link
                          className="btn btn-sm btn-white text-primary"
                          to={`/stores/${ticketProduct.store.id}`}
                        >
                          <i className="fa fa-store-alt fa-fw" /> 가맹점 정보
                        </Link>
                      </span>
                      {ticketProduct.store.name}
                    </div>
                    <div className="order-product-list">
                      <div className="img">
                        <img src={CoverImage} alt={ticketProduct.name} />
                        <span className="day badge bg-yellow text-black">
                          {dDay}일
                        </span>
                      </div>
                      <div className="cont">
                        <div>가 맹 점 : {ticketProduct.store.name}</div>
                        <div>
                          상 품 명 : [
                          {ticketProduct.ticketCategory.parentCategory
                            ? `${ticketProduct.ticketCategory.parentCategory.name} > ${ticketProduct.ticketCategory.name}`
                            : `${ticketProduct.ticketCategory.name}`}
                          ]{ticketProduct.name}
                        </div>
                        <div>정 원 : {ticketProduct.limit}명</div>
                        <div>
                          장 소 :{" "}
                          {ticketProduct.placeType === "OffLine" &&
                            `(${ticketProduct.placeZipcode}) ${ticketProduct.placeAddress} ${ticketProduct.placeAddressDetail}`}
                          {ticketProduct.placeType === "OnLine" && "온라인"}
                        </div>
                        <div className="date">
                          유효기간 :{" "}
                          <ReactMoment format="YYYY-MM-DD">
                            {ticketProduct.applyStartDateTime}
                          </ReactMoment>{" "}
                          ~{" "}
                          <ReactMoment format="YYYY-MM-DD">
                            {ticketProduct.applyEndDateTime}
                          </ReactMoment>
                        </div>
                        <div>
                          가격 :{" "}
                          {ticketProduct.isPay ? (
                            <>
                              (
                              <NumberFormat
                                displayType={"text"}
                                value={price}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />{" "}
                              -{" "}
                              <NumberFormat
                                displayType={"text"}
                                value={isDiscount ? discountPrice : 0}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                              ) * {count}개 ={" "}
                              <b>
                                <NumberFormat
                                  displayType={"text"}
                                  value={totalPrice}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </b>
                            </>
                          ) : (
                            "무료"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="invoice-header">
                      <div className="invoice-from p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">수신정보</strong>
                          <ul className="common-status">
                            <li>
                              <div className="left">구 매 자</div>
                              <div className="right">
                                <Link
                                  to={`/users/${ticketOrder.user.role}/${ticketOrder.user.id}`}
                                >
                                  {ticketOrder.user.name}(
                                  {ticketOrder.user.userId})
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="left">수 신 자</div>
                              <div className="right">
                                {isMember ? (
                                  <Link to={`/users/${user.role}/${user.id}`}>
                                    {user.name}({user.userId})
                                  </Link>
                                ) : (
                                  <>
                                    {name}({phone})
                                  </>
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="left">수신일자</div>
                              <div className="right">
                                <ReactMoment format="YYYY-MM-DD HH:mm">
                                  {createdAt}
                                </ReactMoment>
                              </div>
                            </li>
                            <li>
                              <div className="left">메 시 지</div>
                              <div className="right">
                                {ticketOrder.message
                                  ? ticketOrder.message
                                  : "없음"}
                              </div>
                            </li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-to p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">사용정보</strong>
                          <ul className="common-status">
                            <li>
                              <div className="left">상 태</div>
                              <div className="right">
                                {commonStatus === "S" && (
                                  <>
                                    {toDay <= endDateObj ? (
                                      <>
                                        {isReservation ? (
                                          <span className="gray">미사용</span>
                                        ) : (
                                          <span className="gray">예매대기</span>
                                        )}
                                      </>
                                    ) : (
                                      <span className="gray">기간만료</span>
                                    )}
                                  </>
                                )}
                                {commonStatus === "C" && (
                                  <>
                                    {isRefund === true && (
                                      <span className="danger">환불완료</span>
                                    )}
                                    {isRefund === false && (
                                      <span className="primary">사용완료</span>
                                    )}
                                  </>
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="left">티켓번호</div>
                              <div className="right">
                                <ReactMoment format="mmssSSS">
                                  {createdAt}
                                </ReactMoment>
                              </div>
                            </li>
                            <li>
                              <div className="left">수량</div>
                              <div className="right">{count}개</div>
                            </li>
                            {isReservation && (
                              <li>
                                <div className="left">예매일시</div>
                                <div className="right">
                                  <ReactMoment format="YYYY.MM.DD">
                                    {startDate}
                                  </ReactMoment>
                                  ({dayOfWeekName(startDate.getDay())}){" "}
                                  <ReactMoment format="HH:mm">
                                    {startDate}
                                  </ReactMoment>{" "}
                                  ~{" "}
                                  <ReactMoment format="HH:mm">
                                    {endDate}
                                  </ReactMoment>
                                </div>
                              </li>
                            )}
                            {commonStatus === "C" && (
                              <li>
                                <div className="left">
                                  {isRefund === true && "환불일시"}
                                  {isRefund === false && "사용일시"}
                                </div>
                                <div className="right">
                                  <ReactMoment format="YYYY-MM-DD ">
                                    {usedAt}
                                  </ReactMoment>
                                  ({dayOfWeekName(new Date(usedAt).getDay())}){" "}
                                  <ReactMoment format="HH:mm">
                                    {usedAt}
                                  </ReactMoment>
                                </div>
                              </li>
                            )}
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-to p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="date text-inverse m-t-5">
                            기능
                          </strong>
                          <ul className="common-status-btn">
                            {commonStatus === "S" && toDay <= endDateObj ? (
                              // {commonStatus === "S" ? (
                              <>
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-white text-danger"
                                    onClick={() => onRefund(id)}
                                  >
                                    <i className="fas fa-undo"></i>
                                    반환
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-white text-primary"
                                    onClick={() => onUsed(id)}
                                  >
                                    <i className="fas fa-cash-register"></i>
                                    사용하기
                                  </button>
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-white text-danger disabled"
                                  >
                                    <i className="fas fa-undo"></i>
                                    반환
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-white text-primary disabled"
                                  >
                                    <i className="fas fa-cash-register"></i>
                                    사용하기
                                  </button>
                                </li>
                              </>
                            )}
                          </ul>
                        </address>
                      </div>
                    </div>
                    {/* 가격정보 */}
                    <div className="invoice-price m-0">
                      <div className="invoice-price-left">
                        <div className="invoice-price-row">
                          <div className="sub-price">(</div>
                          <div className="sub-price">
                            <small>정가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={price}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-minus text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>할인가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={isDiscount ? discountPrice : 0}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">)</div>
                          <div className="sub-price">
                            <i className="fa fa-times text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>전체수량</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={count}
                                thousandSeparator={true}
                                suffix={"개"}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-price-right">
                        <small>가격</small>
                        <span className="f-w-600">
                          {ticketProduct.isPay ? (
                            <NumberFormat
                              displayType={"text"}
                              value={totalPrice}
                              thousandSeparator={true}
                              suffix={"드림"}
                            />
                          ) : (
                            "무료"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Alert color="danger" className="m-b-0">
                    <strong className="f-s-15">※ 주의사항(필독)</strong>
                    {/* <br /> * 양 도 : 유효기간 이내, 예매 시 예매일시 하루 전에
                    다른 사용자에게 양도됩니다. 수신자에게 환불됩니다. */}
                    <br /> * 반 환 : 유효기간 이내, 예매 시 예매일 하루 전 까지
                    환불정책에 따라 환불처리 됩니다.
                    <br /> * 사용하기 : 유효기간 이내, 예매 된 경우 사용처리
                    합니다.
                    <br />
                  </Alert>
                </PanelFooter>
              </Panel>
            </div>
          </div>
          {isReservation && (
            <div className="row">
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>
                    참여자({ticketOrderProductPeoples.length}명)
                  </PanelHeader>
                  <PanelBody className="">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <colgroup>
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "15%" }} />
                          <col style={{ width: "*" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">상태</th>
                            <th className="text-center">이름</th>
                            <th className="text-center">휴대전화</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketOrderProductPeoples.map((item, index) => {
                            console.log({ item });

                            return (
                              <tr key={index}>
                                <td className="text-center">
                                  {item.commonStatus === "S" && (
                                    <span className="badge bg-gray">
                                      참여대기
                                    </span>
                                  )}
                                  {item.commonStatus === "C" && (
                                    <span className="badge bg-primary">
                                      참여완료
                                    </span>
                                  )}
                                  {item.commonStatus === "D" && (
                                    <span className="badge bg-danger">
                                      불참
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">{item.name}</td>
                                <td className="text-center">{item.phone}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-4 text-left">
              <Link
                to={`/ticketOrderProducts${location.search}`}
                className="btn btn-white"
              >
                <i className="fa fa-list fa-fw"></i> {""}
                목록
              </Link>
            </div>
            <div className="col-md-4 text-center"></div>
            <div className="col-md-4 text-right">
              <Link
                to={`/ticketOrders/${ticketOrder.id}`}
                className="btn btn-white text-blue"
              >
                상세구매내역
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
