import { gql } from "apollo-boost";

//장부
export const STOREORDER_LIST_QUERY = gql`
  query AdminStoreOrderList(
    $store: String
    $startSearchDate: String
    $endSearchDate: String
    $orderBy: String!
    $skip: Int
    $first: Int
  ) {
    AdminStoreOrderList(
      store: $store
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      storeOrders {
        id
        commonStatus
        currency
        value
        createdAt

        store {
          id
          name
        }
        cashier {
          id
          userId
          name
        }
        creator {
          id
          userId
          name
        }
        accountUserLedger {
          id
          title
          createdAt
        }
      }
      count
    }
  }
`;

export const STORE_LIST_QUERY = gql`
  query AdminStoreList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const STORE_DETAIL_QUERY = gql`
  query AdminStoreDetail($id: String!) {
    AdminStoreDetail(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
