import React from "react";
import { useQuery } from "react-apollo-hooks";
import { GIFT_CATEGORY_LIST_QUERY } from "../Queries";
import AsyncSelect from "react-select/async";

export default ({
  history,
  match,
  location,
  giftCategory,
  setGiftCategory,
}) => {
  const { data, loading } = useQuery(GIFT_CATEGORY_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      giftConfig: match.params.config,
      parentId: "null",
    },
  });

  const items = () => {
    let array = [];

    data.AdminGiftCategoryList.giftCategories.forEach((depth1) => {
      depth1.childrenCount === 0 &&
        array.push({
          value: depth1.id,
          label: `${depth1.name}`,
        });

      depth1.childrenCount > 0 &&
        depth1.children.forEach((depth2) => {
          depth2.childrenCount === 0 &&
            array.push({
              value: depth2.id,
              label: `${depth1.name} > ${depth2.name}`,
            });

          depth2.childrenCount > 0 &&
            depth2.children.forEach((depth3) => {
              depth3.childrenCount === 0 &&
                array.push({
                  value: depth3.id,
                  label: `${depth1.name} > ${depth2.name} > ${depth3.name}`,
                });
            });
        });
    });

    return array;
  };

  const filterItems = async (inputValue) => {
    let data = await items();
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterItems(inputValue));
      }, 500);
    });

  return (
    <>
      {!loading && (
        <AsyncSelect
          placeholder="분류 선택"
          cacheOptions
          loadOptions={promiseOptions}
          defaultOptions
          onChange={({ label, value }) => {
            setGiftCategory({ label, value });
          }}
          defaultValue={giftCategory}
        />
      )}
    </>
  );
};
