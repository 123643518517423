import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { FEE_LIST_QUERY } from "../Queries";
import useInput from "../../../../Hooks/useInput";
import ListPresenter from "./Presenter";
import qs from "qs";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" + (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(FEE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      keyword,
      skip,
      first,
    },
  });

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/fees`);
  };

  useEffect(() => {
    refetch();
  });

  return (
    <ListPresenter
      history={history}
      match={match}
      location={location}
      page={page}
      keyword={keyword}
      keywordInput={keywordInput}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
      handleSearch={handleSearch}
    />
  );
});
