import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_DATE_DELETE_QUERY,
  TICKET_PRODUCT_DATETIME_DELETE_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import { toast } from "react-toastify";

import {
  commonStatusName,
  soldOutName,
  dayName,
} from "../../../../../Components/User/UserNames";

import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";

export default withRouter(
  ({
    history,
    location,
    match,
    applyStartDateTime,
    applyEndDateTime,
    ticketProductDates,
    ticketProductDateTimesCount,
    limit,
  }) => {
    const productCommonStatus = match.params.commonStatus;

    const [ticketProductDateDeleteMutation] = useMutation(
      TICKET_PRODUCT_DATE_DELETE_QUERY,
      {
        refetchQueries: () => [
          {
            query: TICKET_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );
    const [ticketProductDateTimeDeleteMutation] = useMutation(
      TICKET_PRODUCT_DATETIME_DELETE_QUERY,
      {
        refetchQueries: () => [
          {
            query: TICKET_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );
    const handleTicketProductDateDelete = async (item) => {
      console.log(item);
      if (item.ticketOrderProductPeoplesCount === 0) {
        try {
          const {
            data: { AdminTicketProductDateDelete },
          } = await ticketProductDateDeleteMutation({
            variables: {
              id: item.id,
            },
          });
          if (AdminTicketProductDateDelete) {
            toast.success("처리 되었습니다.");
            history.push(
              `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
            );
          }
        } catch (e) {
          console.log(e);
          toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
        }
      } else {
        return toast.error("예매 데이터가 존재하여 삭제할 수 없습니다.");
      }
    };
    const handleTicketProductDateTimeDelete = async (item) => {
      if (item.ticketOrderProductPeoplesCount === 0) {
        try {
          const {
            data: { AdminTicketProductDateTimeDelete },
          } = await ticketProductDateTimeDeleteMutation({
            variables: {
              id: item.id,
            },
          });
          if (AdminTicketProductDateTimeDelete) {
            toast.success("처리 되었습니다.");
            history.push(
              `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
            );
          }
        } catch (e) {
          console.log(e);
          toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
        }
      } else {
        return toast.error("예매 데이터가 존재하여 삭제할 수 없습니다.");
      }
    };

    const id = match.params.id;

    let ticketProductDateCount = 0;
    let ticketProductDateTimeCount = 0;
    return (
      <div className="row">
        <div className="col-sm-12">
          <Panel>
            <PanelHeader>일정정보</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-sm-12">
                  {ticketProductDates.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered tablebasic product_op_table">
                          <caption className="sr-only">
                            일정정보-일정,기능,시간,추가금액,기능
                          </caption>
                          <colgroup>
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th
                                abbr="일자"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                일자
                              </th>
                              <th
                                abbr="기능"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                기능
                              </th>
                              <th
                                abbr="시간"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                시간(예매/정원)
                              </th>
                              <th
                                abbr="기능"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                기능
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ticketProductDates.map((ticketProductDate) => {
                              ticketProductDateTimeCount = 0;
                              return ticketProductDate.ticketProductDateTimes.map(
                                (ticketProductDateTime, index) => {
                                  ticketProductDateCount++;
                                  ticketProductDateTimeCount++;
                                  const ticketDate = new Date(
                                    ticketProductDate.ticketDate
                                  );
                                  return (
                                    <tr key={index}>
                                      {ticketProductDateTimeCount === 1 && (
                                        <td
                                          rowSpan={
                                            ticketProductDate
                                              .ticketProductDateTimes.length
                                          }
                                          style={{
                                            verticalAlign: "middle",
                                            textAlign: "center",
                                          }}
                                        >
                                          {ticketProductDate.commonStatus !==
                                            "C" && (
                                            <span className="badge badge-danger">
                                              {commonStatusName(
                                                ticketProductDate.commonStatus
                                              )}
                                            </span>
                                          )}{" "}
                                          <ReactMoment format="YYYY-MM-DD">
                                            {ticketProductDate.ticketDate}
                                          </ReactMoment>
                                          ({dayName(ticketDate.getDay())}){" "}
                                        </td>
                                      )}
                                      {ticketProductDateTimeCount === 1 && (
                                        <td
                                          rowSpan={
                                            ticketProductDate
                                              .ticketProductDateTimes.length
                                          }
                                          style={{
                                            verticalAlign: "middle",
                                            textAlign: "center",
                                          }}
                                        >
                                          {ticketDate <= new Date() ? (
                                            <button
                                              type="button"
                                              className="btn btn-xs btn-white text-gray m-r-5"
                                              onClick={() =>
                                                alert(
                                                  "만료 일정은 일자를 수정할 수 없습니다."
                                                )
                                              }
                                            >
                                              <i className="fa fa-check"></i>{" "}
                                              일자변경
                                            </button>
                                          ) : (
                                            <Link
                                              to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/ticketDates/${ticketProductDate.id}/edit${location.search}`}
                                              className="btn btn-xs btn-white text-success m-r-5"
                                            >
                                              <i className="fa fa-check"></i>{" "}
                                              일자변경
                                            </Link>
                                          )}
                                          <button
                                            type="button"
                                            className={`btn btn-xs btn-white text-danger m-r-5 ${
                                              ticketProductDate.ticketOrderProductPeoplesCount >
                                              0
                                                ? "disabled"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              handleTicketProductDateDelete(
                                                ticketProductDate
                                              );
                                            }}
                                          >
                                            <i className="fa fa-trash"></i>{" "}
                                            일자삭제
                                          </button>
                                          <Link
                                            to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/ticketDates/${ticketProductDate.id}/ticketDateTimes/write${location.search}`}
                                            className="btn btn-xs btn-white text-primary m-r-5"
                                          >
                                            <i className="fa fa-plus"></i>{" "}
                                            시간추가
                                          </Link>
                                        </td>
                                      )}

                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                      >
                                        {ticketProductDateTime.commonStatus !==
                                          "C" && (
                                          <span className="badge badge-danger">
                                            {commonStatusName(
                                              ticketProductDateTime.commonStatus
                                            )}
                                          </span>
                                        )}{" "}
                                        <ReactMoment format="HH:mm:ss">
                                          {ticketProductDateTime.ticketDateTime}
                                        </ReactMoment>{" "}
                                        {ticketProductDateTime.isSoldOut && (
                                          <span className="badge badge-danger">
                                            {soldOutName(
                                              ticketProductDateTime.isSoldOut
                                            )}
                                          </span>
                                        )}
                                        (
                                        {
                                          ticketProductDateTime.ticketOrderProductPeoplesCount
                                        }
                                        명/{limit}명)
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                      >
                                        {ticketDate <= new Date() ? (
                                          <button
                                            type="button"
                                            className="btn btn-xs btn-white text-gray m-r-5"
                                            onClick={() =>
                                              alert(
                                                "만료 일정은 시간을 수정할 수 없습니다."
                                              )
                                            }
                                          >
                                            <i className="fa fa-check"></i>{" "}
                                            시간수정
                                          </button>
                                        ) : (
                                          <Link
                                            to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/ticketDates/${ticketProductDate.id}/ticketDateTimes/${ticketProductDateTime.id}/edit${location.search}`}
                                            className="btn btn-xs btn-white text-success m-r-5"
                                          >
                                            <i className="fa fa-check"></i>{" "}
                                            시간수정
                                          </Link>
                                        )}
                                        <button
                                          type="button"
                                          className={`btn btn-xs btn-white text-danger m-r-5 ${
                                            ticketProductDateTime.ticketOrderProductPeoplesCount >
                                            0
                                              ? "disabled"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            handleTicketProductDateTimeDelete(
                                              ticketProductDateTime
                                            );
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>{" "}
                                          시간삭제
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <Link
                        to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${id}/ticketDates/write${location.search}`}
                        className="btn btn-white text-primary btn-block"
                      >
                        <i className="fas fa-plus"></i> 일자추가
                      </Link>
                    </>
                  ) : (
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                        <div className="jumbotron nobottommargin text-center m-0">
                          <div className="feature-box fbox-center fbox-light fbox-plain">
                            <h3 style={{ fontSize: "28px" }}>
                              티켓 일정 정보를 입력해주세요.
                            </h3>
                            <p
                              style={{
                                fontSize: "13px",
                                lineHeight: "160%",
                              }}
                            >
                              티켓 일정 정보를 생성하셔야 티켓을 등록 판매할 수
                              있습니다.
                              <br />
                              감사합니다.
                            </p>
                            <p>
                              <Link
                                to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${id}/ticketDates/write${location.search}`}
                                className="btn btn-white"
                              >
                                <i className="fa fa-plus"></i> 일정생성
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 p-t-10">
                  <div className="text-left text-danger">
                    <strong>
                      <span className="f-s-15">※ 주의사항(필독)</span>
                      <br />
                      * 일정별 삭제시 관련 데이터가 삭제되므로 삭제보다는 해당
                      옵션을 [품절]로 상태값 변경을 권장합니다.
                      <br />* 일정별 삭제시 고객이 해당 상품의 옵션을 구매한
                      경우 데이터 손실이 발생되어 삭제 신중히 하시길 바랍니다.
                    </strong>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    );
  }
);
