import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { FEEMONTHFILE_DETAIL_QUERY } from "../../Queries";

import Uploaded from "./Presenter/Uploaded";
import Result from "./Presenter/Result";

export default withRouter(({ history, match, location }) => {
  const feeMonthFileId = match.params.feeMonthFileId;

  const { data, loading } = useQuery(FEEMONTHFILE_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: feeMonthFileId },
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  const {
    AdminFeeMonthFileDetail: {
      feeMonthFileDetails,
      commonStatus,
      year,
      month,

      file,
    },
  } = data;

  if (commonStatus === "S") {
    return <Uploaded data={data} />;
  }
  if (commonStatus === "C") {
    return <Result data={data} />;
  }
});
