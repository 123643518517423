import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;
  const [keyword, setKeyword] = useState("");

  const { data, loading, refetch } = useQuery(STORE_LIST_QUERY, {
    variables: {
      keyword,
    },
  });

  const handleSearch = () => {
    refetch();
  };

  if (!loading) {
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        keyword={keyword}
        setKeyword={setKeyword}
        handleSearch={handleSearch}
        data={data}
        loading={loading}
      ></Presenter>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
