import { gql } from "apollo-boost";

/* 가맹점 설정 */
export const DASHBOARD_QUERY = gql`
  query StoreTicketDashboard($year: String, $month: String) {
    StoreTicketDashboard(year: $year, month: $month) {
      ticketOrders {
        id
        ticketOrderProducts {
          id
          commonStatus

          isReservation
          isMember
          user {
            id
            avatar
            userId
            name
          }
          name
          phone

          count
          totalPrice

          isRefund
          meRefund

          createdAt
          updatedAt

          ticketOrderProductPeoples {
            id
            commonStatus
            name
            phone

            createdAt
            updatedAt
          }
          ticketProductDate {
            id
            commonStatus
            ticketDate
          }
          ticketProductDateTime {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }
        }

        commonStatus
        user {
          id
          userId
          name
        }
        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents
        }

        isDiscount
        discountPrice
        price

        count

        totalCount
        totalPrice

        message

        isRefund
        refundReceiver {
          id
        }
        refundTotalCount
        refundTotalPrice

        createdAt
        updatedAt
      }
      ticketOrderProducts {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          avatar
          userId
          name
        }
        name
        phone

        isDiscount
        discountPrice
        price
        count
        totalPrice

        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents
        }
        ticketOrder {
          id

          isDiscount
          discountPrice
          price
          count
          totalPrice

          message
          user {
            id
            role
            avatar
            userId
            name
          }
        }
        ticketOrderProductPeoples {
          id
          commonStatus
          name
          phone

          createdAt
          updatedAt
        }

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        isRefund
        meRefund

        usedAt
        createdAt
        updatedAt
      }
      ticketProductReviews {
        id
        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents

          createdAt
          updatedAt
        }

        ticketOrderProduct {
          id
          commonStatus

          isReservation
          isMember
          user {
            id
            role
            avatar
            userId
            name
          }
          name
          phone

          isDiscount
          discountPrice
          price
          totalPrice

          ticketProductDate {
            id
            commonStatus
            ticketDate
          }
          ticketProductDateTime {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }

          isRefund
          meRefund

          createdAt
          updatedAt
        }
        user {
          id
          userId
          name
        }
        commonStatus
        isSecret
        score
        contents
        isComment
        comment
        commentDate
        createdAt
        updatedAt
      }
      ticketProductInquiries {
        id
        ticketProduct {
          id
          ticketConfig {
            id
          }
          commonStatus

          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents

          createdAt
          updatedAt
        }
        user {
          id
          userId
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt
      }
    }
  }
`;
