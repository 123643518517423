import React from "react";
import { Link } from "react-router-dom";
import qs from "qs";

import { dayArray } from "../../../../../Components/User/UserNames";

export default ({ history, match, location, keyword }) => {
  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword };

  return (
    <div className="">
      <Link
        className={`btn btn-white m-b-5 ${!queryString.day ? "active" : ""}
        `}
        to={`/discounts?${qs.stringify({ ...query })}`}
      >
        <i className={"fas fa-th"} /> 전체
      </Link>

      {dayArray.map((item, index) => {
        const url =
          "?" +
          (item ? "day=" + index + "&" : "") +
          (keyword ? "keyword=" + keyword + "&" : "");
        return (
          <Link
            key={index}
            className={`btn btn-white m-l-5 m-b-5 ${
              String(index) === queryString.day ? "active" : ""
            }
              `}
            to={`/discounts${url}`}
          >
            {item}
          </Link>
        );
      })}
    </div>
  );
};
