import React from "react";
import { Link } from "react-router-dom";

const QSPI = ({ currentPage, baseUrl, startPage, endPage, totalPage }) => {
  let pages = [];
  let page = parseInt(currentPage);

  for (var i = startPage; i <= endPage && i <= totalPage; i++) {
    if (i === page) {
      pages.push(
        <li
          className={"page-item " + (i === page ? "active" : "")}
          key={"pagination" + i}
        >
          <div className="page-link" title="현재 페이지" to="#">
            {currentPage}
          </div>
        </li>
      );
    } else {
      pages.push(
        <li className="page-item" key={"pagination" + i}>
          <Link
            className="page-link"
            title={i + " 페이지 이동"}
            to={baseUrl + "page=" + i}
          >
            {i}
          </Link>
        </li>
      );
    }
  }
  return pages;
};

const QSP = ({ totalRecord, blockSize, pageSize, currentPage, baseUrl }) => {
  const currentBlock = Math.ceil(currentPage / blockSize);
  const totalPage = Math.ceil(totalRecord / pageSize);
  const totalBlock = Math.ceil(totalPage / blockSize);

  const startPage = (currentBlock - 1) * blockSize + 1;
  const endPage = currentBlock * blockSize;

  const prevBlockPage = startPage - 1;
  const nextBlockPage = endPage + 1;

  return (
    <ul className="pagination pagination-sm m-t-0 m-b-5">
      {currentBlock === 1 ? (
        <li className="page-item disabled" key={"paginationPrev"}>
          <Link className="page-link" title="이전 페이지 없음" to="">
            <i className="fa fa-angle-left"></i>
          </Link>
        </li>
      ) : (
        <>
          <li className="page-item" key={"paginationFirst"}>
            <Link
              className="page-link"
              title="첫 페이지 보기"
              to={baseUrl + "page=1"}
            >
              <i className="fa fa-angle-double-left"></i>
            </Link>
          </li>
          <li className="page-item" key={"paginationPrev"}>
            <Link
              className="page-link"
              title={"이전 " + blockSize + "페이지 보기"}
              to={baseUrl + "page=" + prevBlockPage}
            >
              <i className="fa fa-angle-left"></i>
            </Link>
          </li>
        </>
      )}
      <QSPI
        currentPage={currentPage}
        baseUrl={baseUrl}
        startPage={startPage}
        endPage={endPage}
        totalPage={totalPage}
      />
      {currentBlock === totalBlock ? (
        <li className="page-item disabled" key={"paginationNext"}>
          <Link className="page-link" title="다음 페이지 없음" to="#">
            <i className="fa fa-angle-right"></i>
          </Link>
        </li>
      ) : (
        <>
          <li className="page-item" key={"paginationNext"}>
            <Link
              className="page-link"
              title={"다음 " + nextBlockPage + "페이지 보기"}
              to={baseUrl + "page=" + nextBlockPage}
            >
              <i className="fa fa-angle-right"></i>
            </Link>
          </li>
          <li className="page-item" key={"paginationLast"}>
            <Link
              className="page-link"
              title="마지막 페이지 보기"
              to={baseUrl + "page=" + totalPage}
            >
              <i className="fa fa-angle-double-right"></i>
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};

export default QSP;
