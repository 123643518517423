import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_STATISTICS_MONTH_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import NumberFormat from "react-number-format";
import Chart from "react-google-charts";

export default withRouter(({ history, match, location }) => {
  const week = ["일", "월", "화", "수", "목", "금", "토"];

  const date = new Date();
  const year = match.params.year
    ? parseInt(match.params.year)
    : date.getFullYear();
  const month = match.params.month
    ? parseInt(match.params.month)
    : date.getMonth();
  const [dayMoneyTotal, setDayMoneyTotal] = useState(0);
  const [dayProductCountTotal, setDayProductCountTotal] = useState(0);
  const [dayCountTotal, setDayCountTotal] = useState(0);
  const [readyMoneyTotal, setReadyMoneyTotal] = useState(0);
  const [readyCountTotal, setReadyCountTotal] = useState(0);
  const [completionMoneyTotal, setCompletionMoneyTotal] = useState(0);
  const [completionCountTotal, setCompletionCountTotal] = useState(0);
  const [refundMoneyTotal, setRefundMoneyTotal] = useState(0);
  const [refundCountTotal, setRefundCountTotal] = useState(0);
  const [chartData, setChartData] = useState([["", "매출", "건수"]]);

  const { data, loading } = useQuery(GIFT_STATISTICS_MONTH_QUERY, {
    fetchPolicy: "network-only",
    variables: { year: year, month: month },
  });

  useEffect(() => {
    if (!loading) {
      let dayMoney = 0;
      let dayProductCount = 0;
      let dayCount = 0;
      let readyMoney = 0;
      let readyCount = 0;
      let completionMoney = 0;
      let completionCount = 0;
      let refundMoney = 0;
      let refundCount = 0;
      data.AdminGiftStatisticsMonth.statisticsDays.forEach((item) => {
        let array = chartData;
        array.push([`${item.Day}일`, item.DayMoney, item.DayCount]);
        setChartData(array);

        dayMoney += item.DayMoney;
        setDayMoneyTotal(dayMoney);
        dayProductCount += item.DayProductCount;
        setDayProductCountTotal(dayProductCount);
        dayCount += item.DayCount;
        setDayCountTotal(dayCount);

        readyMoney += item.ReadyMoney;
        setReadyMoneyTotal(readyMoney);
        readyCount += item.ReadyCount;
        setReadyCountTotal(readyCount);

        completionMoney += item.CompletionMoney;
        setCompletionMoneyTotal(completionMoney);
        completionCount += item.CompletionCount;
        setCompletionCountTotal(completionCount);

        refundMoney += item.RefundMoney;
        setRefundMoneyTotal(refundMoney);
        refundCount += item.RefundCount;
        setRefundCountTotal(refundCount);
      });
    }
  }, [data, loading, chartData]);

  const endNumber = new Date(year, month, 0).getDate();

  return (
    <>
      <Helmet>
        <title>통계 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림상점</li>
          <li className="breadcrumb-item active">통계</li>
        </ol>
        <h1 className="page-header">통계</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                {year}년 {month}월 매출통계
              </PanelHeader>
              {/* <Alert className="bg-gray-transparent-1"></Alert> */}
              <PanelBody>
                {!loading && data.AdminGiftStatisticsMonth ? (
                  <>
                    <Chart
                      style={{ width: "100%" }}
                      height={430}
                      chartType="Bar"
                      loader={<div>Loading Chart</div>}
                      data={chartData}
                      options={{
                        bars: "vertical",
                        vAxis: { format: "decimal" },
                        height: 400,
                        legend: { position: "top", maxLines: 3 },
                        bar: { groupWidth: "75%" },
                        isStacked: true,
                        colors: ["#1b9e77", "#7570b3", "#d95f02"],
                      }}
                      legendToggle
                    />
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-top m-b-0">
                        <thead>
                          <tr>
                            <th>기간</th>
                            <th>전체(주문일자 기준)</th>
                            <th>사용가능(주문일자 기준)</th>
                            <th>사용완료(사용일시 기준)</th>
                            <th>환불완료(환불일시 기준)</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">
                              {year}년 {month}월 전체
                            </td>
                            <td className="text-right">
                              <div>
                                매출 :{" "}
                                <NumberFormat
                                  value={dayMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/giftOrders?startSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-01&endSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-${endNumber}`}
                                >
                                  주문 : {dayCountTotal}건
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to={`/giftOrderProducts?startSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-01&endSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-${endNumber}`}
                                >
                                  상품 : {dayProductCountTotal}건
                                </Link>
                              </div>
                            </td>
                            <td className="text-right">
                              <div>
                                금액 :{" "}
                                <NumberFormat
                                  value={readyMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/giftOrderProducts?status=0&startSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-01&endSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-${endNumber}`}
                                >
                                  상품 :{readyCountTotal}개
                                </Link>
                              </div>
                            </td>
                            <td className="text-right">
                              <div>
                                <NumberFormat
                                  value={completionMoneyTotal * 0.97}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림 -{" "}
                                <NumberFormat
                                  value={completionMoneyTotal * 0.03}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림 ={" "}
                                <NumberFormat
                                  value={completionMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>정산금액 - 수수료(3%) = 전체금액</div>
                              <div>
                                <Link
                                  to={`/giftOrderProducts?status=1&startSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-01&endSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-${endNumber}`}
                                >
                                  상품 :{completionCountTotal}개
                                </Link>
                              </div>
                            </td>
                            <td className="text-right">
                              <div>
                                금액 :{" "}
                                <NumberFormat
                                  value={refundMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/giftOrderProducts?status=2&startSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-01&endSearchDate=${year}-${
                                    month < 10 ? `0${month}` : month
                                  }-${endNumber}`}
                                >
                                  상품 : {refundCountTotal}개
                                </Link>
                              </div>
                            </td>
                            <td className="text-center">
                              <Link
                                to={`/giftStatistics/year/${year}/month/${month}/orderRank`}
                                className="btn btn-white btn-xs text-primary"
                              >
                                주문순위
                              </Link>
                              <Link
                                to={`/giftStatistics/year/${year}/month/${month}/orderProductRank`}
                                className="btn btn-white btn-xs text-primary"
                              >
                                상품순위
                              </Link>
                            </td>
                          </tr>
                          {data.AdminGiftStatisticsMonth.statisticsDays.map(
                            (item, index) => {
                              const dayOfWeek =
                                week[
                                  new Date(year, month - 1, item.Day).getDay()
                                ];

                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    {year}년 {month}월 {item.Day}일 ({dayOfWeek}
                                    )
                                  </td>
                                  <td className="text-right">
                                    <div>
                                      매출 :{" "}
                                      <NumberFormat
                                        value={item.DayMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/giftOrders?startSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }&endSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }`}
                                      >
                                        주문 : {item.DayCount}건
                                      </Link>
                                    </div>
                                    <div>
                                      <Link
                                        to={`/giftOrderProducts?startSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }&endSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }`}
                                      >
                                        상품 : {item.DayProductCount}건
                                      </Link>
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    <div>
                                      금액 :{" "}
                                      <NumberFormat
                                        value={item.ReadyMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/giftOrderProducts?status=0&startSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }&endSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }`}
                                      >
                                        상품 : {item.ReadyCount}개
                                      </Link>
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    <div>
                                      <NumberFormat
                                        value={item.CompletionMoney * 0.97}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림 -{" "}
                                      <NumberFormat
                                        value={item.CompletionMoney * 0.03}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림 ={" "}
                                      <NumberFormat
                                        value={item.CompletionMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>정산금액 - 수수료(3%) = 전체금액</div>
                                    <div>
                                      <Link
                                        to={`/giftOrderProducts?status=1&startSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }&endSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }`}
                                      >
                                        상품 : {item.CompletionCount}개
                                      </Link>
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    <div>
                                      금액 :{" "}
                                      <NumberFormat
                                        value={item.RefundMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/giftOrderProducts?status=2&startSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }&endSearchDate=${year}-${
                                          month < 10 ? `0${month}` : month
                                        }-${
                                          item.Day < 10
                                            ? `0${item.Day}`
                                            : item.Day
                                        }`}
                                      >
                                        상품 : {item.RefundCount}개
                                      </Link>
                                    </div>
                                  </td>
                                  <td className="text-center">-</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
});
