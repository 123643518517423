import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter
} from "../../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../../Components/Pagination/QSP";

export default ({
  history,
  match,
  location,
  config,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch
}) => {
  return (
    <>
      <Helmet>
        <title>공지사항 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">공지사항</li>
        </ol>
        <h1 className="page-header">공지사항</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminNoticeList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(이름) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                          <Link
                            to={`/noticeConfigs/${match.params.CommonApp}/${config.AdminNoticeConfig.id}/edit${location.search}`}
                            className="btn btn-white"
                          >
                            <i className="fa fa-cog fa-fw"></i>
                          </Link>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              {!loading && data.AdminNoticeList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>제목</th>
                            <th>작성자</th>
                            <th>작성일</th>
                            <th>조회수</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminNoticeList.notices.map(item => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={`/noticeConfigs/${match.params.CommonApp}/${config.AdminNoticeConfig.id}/notices/${item.id}${location.search}`}
                                  >
                                    {item.title}
                                  </Link>
                                </td>
                                <td>{item.user.name}</td>
                                <td>
                                  <Moment format="YYYY-MM-DD HH:mm:ss">
                                    {item.createdAt}
                                  </Moment>
                                </td>
                                <td>{item.readCount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminNoticeList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/noticeConfigs/${match.params.CommonApp}/${config.AdminNoticeConfig.id}/notices/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
