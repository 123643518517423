import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import SearchProduct from "./SearchProduct";
import Moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default ({
  history,
  match,
  location,
  page,
  ticketProduct,
  startSearchDate,
  endSearchDate,
  keyword,
  keywordInput,
  blockSize,
  first,
  skip,
  data,
  loading,
  baseUrl,
  handleDateApplyEvent,
  handleSearch,
}) => {
  return (
    <>
      <Helmet>
        <title>구매내역 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림티켓</li>
          <li className="breadcrumb-item active">구매내역</li>
        </ol>
        <h1 className="page-header">구매내역</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.StoreTicketOrderList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row m-b-15">
                  <SearchProduct ticketProduct={ticketProduct} />
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <DateRangePicker
                          startDate={Moment(startSearchDate)}
                          endDate={Moment(endSearchDate)}
                          onApply={handleDateApplyEvent}
                        >
                          <button className="btn btn-white mr-2 text-truncate">
                            <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                            <span>
                              {startSearchDate} ~ {endSearchDate}
                            </span>
                            <b className="caret"></b>
                          </button>
                        </DateRangePicker>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(구매자, 수신자) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody className="">
                {!loading && data.StoreTicketOrderList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "*" }} />
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "15%" }} />
                          <col style={{ width: "10%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">이미지</th>
                            <th className="text-center">
                              티켓정보 (정가 - 할인가 = 가격)
                            </th>
                            <th className="text-center">구매정보</th>
                            <th className="text-center">사용현황</th>
                            <th className="text-center">결제정보</th>
                            <th className="text-center">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.StoreTicketOrderList.ticketOrders.map(
                            (item, index) => {
                              const CoverImage =
                                item.ticketProduct.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.ticketProduct.coverImage}`;

                              let commonStatus1 = 0;
                              let commonStatus2 = 0;
                              item.ticketOrderProducts.forEach(
                                (ticketOrderProduct) => {
                                  const toDay = new Date();
                                  const endDateObj =
                                    ticketOrderProduct.isReservation
                                      ? new Date(
                                          ticketOrderProduct.ticketProductDateTime?.ticketDateTime
                                        )
                                      : new Date(
                                          item.ticketProduct.applyEndDateTime
                                        );
                                  if (
                                    ticketOrderProduct.commonStatus === "S" &&
                                    toDay <= endDateObj
                                  ) {
                                    if (!ticketOrderProduct.isReservation) {
                                      commonStatus1 += ticketOrderProduct.count;
                                    } else {
                                      commonStatus2 += ticketOrderProduct.count;
                                    }
                                  }
                                }
                              );
                              let commonStatus3 = 0;
                              item.ticketOrderProducts.forEach(
                                (ticketOrderProduct) => {
                                  const toDay = new Date();
                                  const endDateObj =
                                    ticketOrderProduct.isReservation
                                      ? new Date(
                                          ticketOrderProduct.ticketProductDateTime?.ticketDateTime
                                        )
                                      : new Date(
                                          item.ticketProduct.applyEndDateTime
                                        );
                                  if (
                                    ticketOrderProduct.commonStatus === "S" &&
                                    toDay > endDateObj
                                  ) {
                                    commonStatus3 += ticketOrderProduct.count;
                                  }
                                }
                              );
                              let commonStatus4 = 0;
                              item.ticketOrderProducts.forEach(
                                (ticketOrderProduct) => {
                                  if (
                                    ticketOrderProduct.commonStatus === "C" &&
                                    ticketOrderProduct.isRefund === true
                                  ) {
                                    commonStatus4 += ticketOrderProduct.count;
                                  }
                                }
                              );
                              let commonStatus5 = 0;
                              item.ticketOrderProducts.forEach(
                                (ticketOrderProduct) => {
                                  if (
                                    ticketOrderProduct.commonStatus === "C" &&
                                    ticketOrderProduct.isRefund === false
                                  ) {
                                    commonStatus5 += ticketOrderProduct.count;
                                  }
                                }
                              );

                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    <div
                                      className="product-img"
                                      style={{
                                        backgroundImage: `url(${CoverImage})`,
                                      }}
                                    >
                                      <img
                                        src={CoverImage}
                                        alt={item.ticketProduct.name}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      가 맹 점 : {item.ticketProduct.store.name}
                                    </div>
                                    <div>
                                      티 켓 명 :{" "}
                                      <strong>
                                        [
                                        {item.ticketProduct.ticketCategory
                                          .parentCategory
                                          ? `${item.ticketProduct.ticketCategory.parentCategory.name} > ${item.ticketProduct.ticketCategory.name}`
                                          : `${item.ticketProduct.ticketCategory.name}`}
                                        ] {item.ticketProduct.name}
                                      </strong>
                                    </div>
                                    <div>
                                      정 원 : {item.ticketProduct.limit}명
                                    </div>
                                    <div>
                                      장 소 :{" "}
                                      {item.ticketProduct.placeType ===
                                        "OffLine" &&
                                        `(${item.ticketProduct.placeZipcode}) ${item.ticketProduct.placeAddress} ${item.ticketProduct.placeAddressDetail}`}
                                      {item.ticketProduct.placeType ===
                                        "OnLine" && "온라인"}
                                    </div>
                                    <div>
                                      유효기간 :{" "}
                                      <ReactMoment format="YYYY-MM-DD">
                                        {item.ticketProduct.applyStartDateTime}
                                      </ReactMoment>{" "}
                                      ~{" "}
                                      <ReactMoment format="YYYY-MM-DD">
                                        {item.ticketProduct.applyEndDateTime}
                                      </ReactMoment>
                                    </div>
                                    <div>
                                      가 격 :{" "}
                                      {item.ticketProduct.isPay ? (
                                        <>
                                          <NumberFormat
                                            displayType={"text"}
                                            value={item.price}
                                            thousandSeparator={true}
                                            suffix={"드림"}
                                          />{" "}
                                          -{" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            value={
                                              item.isDiscount
                                                ? item.discountPrice
                                                : 0
                                            }
                                            thousandSeparator={true}
                                            suffix={"드림"}
                                          />{" "}
                                          ={" "}
                                          <b>
                                            <NumberFormat
                                              displayType={"text"}
                                              value={
                                                item.price -
                                                (item.isDiscount
                                                  ? item.discountPrice
                                                  : 0)
                                              }
                                              thousandSeparator={true}
                                              suffix={"드림"}
                                            />
                                          </b>
                                        </>
                                      ) : (
                                        "무료"
                                      )}
                                    </div>
                                  </td>
                                  <td className="text-left">
                                    <div>
                                      구 매 자 : {item.user.name}(
                                      {item.user.userId})
                                    </div>
                                    <div>
                                      구매일자 :
                                      <ReactMoment format="YYYY-MM-DD HH:mm">
                                        {item.createdAt}
                                      </ReactMoment>
                                      {item.createdAt}
                                    </div>
                                    <div>
                                      전체수량 :<b> {item.totalCount}개</b>
                                    </div>
                                    <div>
                                      수 신 자 :{" "}
                                      {item.ticketOrderProducts
                                        .map((ticketOrderProduct) => {
                                          return ticketOrderProduct.isMember
                                            ? `${ticketOrderProduct.user.name}(${ticketOrderProduct.user.userId})`
                                            : `${ticketOrderProduct.name}(${ticketOrderProduct.phone})`;
                                        })
                                        .join(", ")}
                                    </div>
                                    <div>
                                      메 시 지 :{" "}
                                      {item.message ? item.message : "없음"}
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div>예약대기 : {commonStatus1}개</div>
                                    <div>미 사 용 : {commonStatus2}개</div>
                                    <div>기간만료 : {commonStatus3}개</div>
                                    <div>환불완료 : {commonStatus4}개</div>
                                    <div>사용완료 : {commonStatus5}개</div>
                                  </td>
                                  <td className="text-right">
                                    <div>
                                      정가 :{" "}
                                      {item.ticketProduct.isPay ? (
                                        <NumberFormat
                                          displayType={"text"}
                                          value={
                                            item.price -
                                            (item.isDiscount
                                              ? item.discountPrice
                                              : 0)
                                          }
                                          thousandSeparator={true}
                                          suffix={"드림"}
                                        />
                                      ) : (
                                        "무료"
                                      )}
                                    </div>
                                    <div>
                                      할인가 :
                                      <NumberFormat
                                        displayType={"text"}
                                        value={
                                          item.isDiscount
                                            ? item.discountPrice
                                            : 0
                                        }
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                      />
                                    </div>
                                    <div>
                                      전체수량 :{" "}
                                      <NumberFormat
                                        displayType={"text"}
                                        value={item.totalCount}
                                        thousandSeparator={true}
                                        suffix={"개"}
                                      />
                                    </div>
                                    <div>
                                      총 결제 금액 :{" "}
                                      <b>
                                        <NumberFormat
                                          displayType={"text"}
                                          value={item.totalPrice}
                                          thousandSeparator={true}
                                          suffix={"드림"}
                                        />
                                      </b>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <Link
                                      to={`/ticket/ticketOrders/${item.id}${location.search}`}
                                      className="btn btn-white btn-xs text-primary"
                                    >
                                      상세정보
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.StoreTicketOrderList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.StoreTicketOrderList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
