import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_ISSUER_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReceipt,
  faCreditCardBlank,
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { currencyName, pastPeriod } from "../../../Components/User/UserNames";
import DatePicker from "../../../Containers/SelectedPeriod/DatePicker";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const startDate = queryString.startDate
    ? queryString.startDate
    : Moment().subtract(1, "months").format("YYYY-MM-DD");
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().format("YYYY-MM-DD");

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const { data, loading } = useQuery(STATISTICS_ISSUER_QUERY, {
    variables: {
      addCurrency: "NDO",
      startDate,
      endDate,
    },
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      AdminStatisticsIssuer: {
        RemittanceCount,
        RemittanceTotal,
        RemittanceOrderCount,
        RemittanceOrderTotal,
        usersRemittance,
        usersRemittanceOrder,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>통계 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/statisticsUser">통계</Link>
            </li>
            <li className="breadcrumb-item active">톨</li>
          </ol>
          <h1 className="page-header">톨</h1>
          <div className="row">
            <div className="col-xl-12">
              <DatePicker
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                onStartDateSelect={onStartDateSelect}
                onEndDateSelect={onEndDateSelect}
                onPeriodConfig={onPeriodConfig}
                period={pastPeriod}
              />
            </div>
          </div>
          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">소비자 현황</b>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faCreditCardBlank} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/ledgers/NDO?transactionType=Remittance&startSearchDate=${startDate}&endSearchDate=${endDate}`}
                    >
                      <NumberFormat
                        value={RemittanceTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      {currencyName("NDO")}({RemittanceCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">송금금액(건)</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faCreditCardBlank} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/ledgers/NDO?transactionType=Remittance&startSearchDate=${startDate}&endSearchDate=${endDate}`}
                    >
                      <NumberFormat
                        value={RemittanceOrderTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      {currencyName("NDO")}({RemittanceOrderCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">거래금액(건)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3>송금 TOP 10</h3>
                  </div>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {usersRemittance?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            <Link
                              to={`/ledgers/NDO?transactionType=Remittance&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                            >
                              {item.RemittanceTotal > 0
                                ? `${item.name}(${item.userId})`
                                : "-"}
                            </Link>
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.RemittanceTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          {currencyName("NDO")}({item.RemittanceCount}건)
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3>거래 TOP 10</h3>
                  </div>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {usersRemittance.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            <Link
                              to={`/ledgers/NDO?transactionType=Remittance&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                            >
                              {item.RemittanceTotal > 0
                                ? `${item.name}(${item.userId})`
                                : "-"}
                            </Link>
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.RemittanceTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          {currencyName("NDO")}({item.RemittanceCount}건)
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
