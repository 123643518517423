import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_DONG_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const baseUrl = "/deliveryStores/dongs";

  const take = 100;
  const skip = 0;
  const { data, loading, error } = useQuery(
    ADMIN_DELIVERY_STORE_DONG_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { take, skip },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
