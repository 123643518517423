import { gql } from "apollo-boost";

export const GIFT_ORDERPRODUCT_LIST_QUERY = gql`
  query AdminGiftOrderProductList(
    $giftProduct: String
    $status: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminGiftOrderProductList(
      giftProduct: $giftProduct
      status: $status
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      giftOrderProducts {
        id
        commonStatus
        user {
          id
          avatar
          userId
          name
        }
        sender {
          id
          avatar
          userId
          name
        }
        giftOrder {
          id
          message
          user {
            id
            avatar
            userId
            name
          }
        }
        giftProduct {
          id
          giftCategory {
            id
            name
          }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }

        isDiscount
        discountPrice
        price
        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }

        totalPrice

        startDate
        endDate
        extensionCount

        isRefund
        meRefund

        usedAt
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GIFT_ORDERPRODUCT_DETAIL_QUERY = gql`
  query AdminGiftOrderProductDetail($id: String!) {
    AdminGiftOrderProductDetail(id: $id) {
      id
      commonStatus
      user {
        id
        role
        avatar
        userId
        name
      }
      sender {
        id
        role
        avatar
        userId
        name
      }
      giftOrder {
        id
        message
        user {
          id
          role
          avatar
          userId
          name
        }
      }
      giftProduct {
        id
        giftConfig {
          id
        }
        giftCategory {
          id
          name
        }
        store {
          id
          storeLogo
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt
      }

      isDiscount
      discountPrice
      price
      giftProductOptionDetail1 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail2 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail3 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail4 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      giftProductOptionDetail5 {
        id
        commonStatus
        isSoldOut
        name
        price
      }

      totalPrice

      startDate
      endDate
      extensionCount

      isRefund
      meRefund

      createdAt
      updatedAt
    }
  }
`;

export const GIFT_ORDERPRODUCT_EXTENSION_QUERY = gql`
  mutation AdminGiftOrderProductExtension($id: String!) {
    AdminGiftOrderProductExtension(id: $id)
  }
`;

export const GIFT_ORDERPRODUCT_REFUND_QUERY = gql`
  mutation AdminGiftOrderProductRefund($id: String!) {
    AdminGiftOrderProductRefund(id: $id)
  }
`;

export const GIFT_ORDERPRODUCT_USED_QUERY = gql`
  mutation AdminGiftOrderProductUsed($id: String!) {
    AdminGiftOrderProductUsed(id: $id)
  }
`;

export const USER_LIST_QUERY = gql`
  query AdminUserList(
    $role: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminUserList(
      role: $role
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        avatar
        userId
        name
      }
      count
    }
  }
`;

export const GIFT_ORDERPRODUCT_SEND_QUERY = gql`
  mutation AdminGiftOrderProductSend($id: String!, $user: String!) {
    AdminGiftOrderProductSend(id: $id, user: $user)
  }
`;
