import { gql } from "apollo-boost";

export const GIFT_PRODUCT_INQUIRY_LIST_QUERY = gql`
  query AdminGiftProductInquiryList(
    $giftProduct: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminGiftProductInquiryList(
      giftProduct: $giftProduct
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      giftProductInquiries {
        id
        giftProduct {
          id
          # giftCategory {
          #   id
          #   name
          # }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }
        user {
          id
          userId
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GIFT_PRODUCT_INQUIRY_DETAIL_QUERY = gql`
  query AdminGiftProductInquiryDetail($id: String!) {
    AdminGiftProductInquiryDetail(id: $id) {
      id
      giftProduct {
        id
        giftConfig {
          id
        }
        # giftCategory {
        #   id
        #   name
        # }
        store {
          id
          storeLogo
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt
      }
      user {
        id
        userId
        name
      }
      commonStatus
      isSecret
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

export const GIET_PRODUCT_INQUIRY_REPLY_QUERY = gql`
  mutation AdminGiftProductInquiryReply(
    $id: String!
    $answer: String!
    $isPush: Boolean
  ) {
    AdminGiftProductInquiryReply(id: $id, answer: $answer, isPush: $isPush)
  }
`;

export const GIET_PRODUCT_INQUIRY_SECRET_TOGGLE_QUERY = gql`
  mutation AdminGiftProductInquirySecretToggle($id: String!) {
    AdminGiftProductInquirySecretToggle(id: $id)
  }
`;

export const GIET_PRODUCT_INQUIRY_DELETE_QUERY = gql`
  mutation AdminGiftProductInquiryDelete($id: String!) {
    AdminGiftProductInquiryDelete(id: $id)
  }
`;
