import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  FEEMONTHFILE_PAYMENT_QUERY,
  FEEMONTHFILE_DETAIL_QUERY,
} from "../../../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { commonStatusName } from "../../../../../../Components/User/UserNames";
import ReactMoment from "react-moment";
import { useForm } from "react-hook-form";

export default withRouter(({ history, match, location, data }) => {
  const id = match.params.id;
  const feeMonthFileId = match.params.feeMonthFileId;

  const [paymentMutation] = useMutation(FEEMONTHFILE_PAYMENT_QUERY, {
    refetchQueries: () => [
      {
        query: FEEMONTHFILE_DETAIL_QUERY,
        variables: {
          id: feeMonthFileId,
        },
      },
    ],
  });

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    let nameColumn = false;
    let phoneColumn = false;
    for (let i = 1; i <= 15; i++) {
      if (watch(`column${i}`) === "name") {
        nameColumn = true;
      }
      if (watch(`column${i}`) === "phone") {
        phoneColumn = true;
      }
    }

    if (!nameColumn) {
      alert("이름을 선택해 주세요.");
      return;
    }
    if (!phoneColumn) {
      alert("전화번호를 선택해 주세요.");
      return;
    }
    try {
      const {
        data: { AdminFeeMonthFilePayment },
      } = await paymentMutation({
        variables: {
          id: feeMonthFileId,

          column1: data.column1,
          column2: data.column2,
          column3: data.column3,
          column4: data.column4,
          column5: data.column5,
          column6: data.column6,
          column7: data.column7,
          column8: data.column8,
          column9: data.column9,
          column10: data.column10,
          column11: data.column11,
          column12: data.column12,
          column13: data.column13,
          column14: data.column14,
          column15: data.column15,
        },
      });
      if (AdminFeeMonthFilePayment) {
        toast.success("처리 되었습니다.");
        history.push(
          `/fees/${id}/monthFiles/${feeMonthFileId}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const doPreview = () => {
    let nameColumn = false;
    let phoneColumn = false;
    for (let i = 1; i <= 15; i++) {
      if (watch(`column${i}`) === "name") {
        nameColumn = true;
      }
      if (watch(`column${i}`) === "phone") {
        phoneColumn = true;
      }
    }

    if (!nameColumn) {
      alert("이름을 선택해 주세요.");
      return;
    }
    if (!phoneColumn) {
      alert("전화번호를 선택해 주세요.");
      return;
    }

    let query = "";
    query += `column1=${watch("column1")}`;
    query += `&column2=${watch("column2")}`;
    query += `&column3=${watch("column3")}`;
    query += `&column4=${watch("column4")}`;
    query += `&column5=${watch("column5")}`;
    query += `&column6=${watch("column6")}`;
    query += `&column7=${watch("column7")}`;
    query += `&column8=${watch("column8")}`;
    query += `&column9=${watch("column9")}`;
    query += `&column10=${watch("column10")}`;
    query += `&column11=${watch("column11")}`;
    query += `&column12=${watch("column12")}`;
    query += `&column13=${watch("column13")}`;
    query += `&column14=${watch("column14")}`;
    query += `&column15=${watch("column15")}`;

    history.push(`/fees/${id}/monthFiles/${feeMonthFileId}/preview?${query}`);
  };

  const {
    AdminFeeMonthFileDetail: {
      feeMonthFileDetails,
      commonStatus,
      year,
      month,

      file,
      createdAt,
      updatedAt,
    },
  } = data;

  return (
    <>
      <Helmet>
        <title>알림 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">알림</li>
          </ol>
          <h1 className="page-header">알림</h1>

          <div key="topButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/fees/${id}/monthFiles${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <div className="btn-group">
                <Button
                  type="button"
                  color="white"
                  className="text-success"
                  onClick={doPreview}
                >
                  미리보기
                </Button>
                <Button type="submit" color="white" className="text-success">
                  출금
                </Button>
                {/* <Button type="submit" color="white" className="text-danger">
                  삭제
                </Button> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelHeader noButton={true}>상세정보</PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>상태</th>
                          <th>연도</th>
                          <th>월</th>
                          <th>파일정보</th>
                          <th>등록일시</th>
                          <th>인원</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{commonStatusName(commonStatus)}</td>
                          <td>{year}</td>
                          <td>{month}</td>
                          <td>{file}</td>
                          <td>
                            <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                              {createdAt}
                            </ReactMoment>
                          </td>
                          <td>{feeMonthFileDetails.length}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div key="storeInfo" className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>
                  <b>{year}</b>년 <b>{month}</b>월 파일(<b>{file}</b>) 충전현황(
                  {feeMonthFileDetails.length}건)
                </PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>
                            <select name="column1" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column2" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column3" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column4" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column5" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column6" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column7" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column8" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column9" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column10" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column11" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column12" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column13" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column14" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                          <th>
                            <select name="column15" ref={register({})}>
                              <option value={""}>선택</option>
                              <option value={"name"}>이름</option>
                              <option value={"phone"}>휴대전화</option>
                            </select>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {feeMonthFileDetails.map((detail, index) => {
                          return (
                            <tr key={index}>
                              <td>{detail.column1}</td>
                              <td>{detail.column2}</td>
                              <td>{detail.column3}</td>
                              <td>{detail.column4}</td>
                              <td>{detail.column5}</td>
                              <td>{detail.column6}</td>
                              <td>{detail.column7}</td>
                              <td>{detail.column8}</td>
                              <td>{detail.column9}</td>
                              <td>{detail.column10}</td>
                              <td>{detail.column11}</td>
                              <td>{detail.column12}</td>
                              <td>{detail.column13}</td>
                              <td>{detail.column14}</td>
                              <td>{detail.column15}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div key="bottomButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/fees/${id}/monthFiles${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              {/* <Button
                type="button"
                color="white"
                className="text-red"
                onClick={() => {
                  onDelete();
                }}
              >
                삭제
              </Button> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
});
