import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { GIFT_CONFIG_LIST_QUERY, GIFT_CONFIG_EDIT_QUERY } from "../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(
  ({ history, match, location, id, productType, name, isModal }) => {
    const modalRef = React.createRef();
    const [productConfigEditMutation] = useMutation(GIFT_CONFIG_EDIT_QUERY, {
      refetchQueries: [{ query: GIFT_CONFIG_LIST_QUERY }],
      awaitRefetchQueries: true,
    });
    const { register, handleSubmit, watch, errors } = useForm({
      defaultValues: {
        productType: productType,
        name: name,
      },
    });

    const onSubmit = async (data) => {
      try {
        const {
          data: { AdminGiftConfigEdit },
        } = await productConfigEditMutation({
          variables: {
            id: id,
            productType: data.productType,
            name: data.name,
          },
        });
        if (AdminGiftConfigEdit) {
          toast.success("설정 되었습니다.");
          history.push(
            `/giftConfigs/${match.params.config}/products${location.search}`
          );
        }
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <>
        <Modal
          isOpen={true}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/giftConfigs/${match.params.config}/products${location.search}`
            );
          }}
          size={"lg"}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/giftConfigs/${match.params.config}/products${location.search}`
                );
              }}
            >
              설정수정
            </ModalHeader>
            <ModalBody className=" p-0">
              <div className="form-horizontal form-bordered">
                <div className="bg-white rounded">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">상품분류</label>
                    <div className="col-md-10">
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="productType1"
                          name="productType"
                          value="Product"
                          ref={register({
                            required: "상품를 선택해주세요.",
                          })}
                        />
                        <label htmlFor="productType1">상품</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="productType2"
                          name="productType"
                          value="Ticket"
                          ref={register({
                            required: "티켓를 선택해주세요.",
                          })}
                        />
                        <label htmlFor="productType2">티켓</label>
                      </div>
                      {errors.productType && (
                        <div>{errors.productType.message}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">이름</label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="이름을 입력해주세요."
                        ref={register({ required: "이름을 입력해주세요." })}
                        defaultValue={watch("name")}
                      />
                      {errors.name && <span>{errors.name.message}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-white text-blue m-r-5">
                확인
              </button>
              <Link
                to={`/giftConfigs/${match.params.config}/products`}
                className="btn btn-white text-red"
              >
                취소
              </Link>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
);
