import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { EVENT_DETAIL_QUERY, EVENT_DELETE_QUERY } from "../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import { graphqlUri } from "../../../../Config/Env";
import { toast } from "react-toastify";
import nl2br from "react-nl2br";

export default withRouter(({ history, match, location }) => {
  const { data: detail, loading } = useQuery(EVENT_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  const [eventDeleteMutation] = useMutation(EVENT_DELETE_QUERY);
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminEventDelete },
      } = await eventDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminEventDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && detail && detail.AdminEventDetail) {
    const {
      AdminEventDetail: {
        id,
        title,
        contents,
        createdAt,
        detailImg,
        startDate,
        endDate,
        commonStatus,
      },
    } = detail;
    const eventDetailImage =
      detailImg === ""
        ? "/assets/img/gallery/gallery-default.jpg"
        : `${graphqlUri}${detailImg}`;
    const date = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    return (
      <>
        <Helmet>
          <title>이벤트 관리 | 한밭페이 </title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">이벤트</li>
          </ol>
          <h1 className="page-header">이벤트</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>상세내용</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="wrapper">
                    <img
                      src={eventDetailImage}
                      alt="이미지 업로드"
                      className="height-200"
                    />
                    <h3 className="m-t-20 f-w-500">
                      {commonStatus === "S" && (
                        <span className="label label-danger">비공개</span>
                      )}
                      {commonStatus === "C" && date >= start && date <= end ? (
                        <span className="label label-primary">진행</span>
                      ) : (
                        <span className="label label-danger">비공개</span>
                      )}
                      {title}
                    </h3>
                  </div>
                  <div className="p-15 bg-grey-transparent-5">
                    <div className="info_ul">
                      <ul>
                        <li>
                          <b>이벤트기간 :</b>
                          <Moment format="YYYY-MM-DD">{startDate}</Moment>~
                          <Moment format="YYYY-MM-DD">{endDate}</Moment>
                        </li>
                        <li>
                          <b>작성일 :</b>
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {createdAt}
                          </Moment>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="p-20">
                    <p className="f-s-12 text-inverse">{nl2br(contents)}</p>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
