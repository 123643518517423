import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReceipt,
  faCreditCardBlank
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { currencyName } from "../../../Components/User/UserNames";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  const [startDisplayDate, setStartDisplayDate] = useState(
    queryString.startSearchDate
      ? queryString.startSearchDate
      : Moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD")
  );
  const [startSearchDate, setStartSearchDate] = useState(startDisplayDate);
  const [endDisplayDate, setEndDisplayDate] = useState(
    queryString.endSearchDate
      ? queryString.endSearchDate
      : Moment().format("YYYY-MM-DD")
  );
  const [endSearchDate, setEndSearchDate] = useState(endDisplayDate);
  const handleDateApplyEvent = (event, picker) => {
    // event.preventDefault();

    var startDate = picker.startDate;
    var endDate = picker.endDate;

    setStartDisplayDate(startDate.format("YYYY-MM-DD"));
    setEndDisplayDate(endDate.format("YYYY-MM-DD"));
    setStartSearchDate(startDate.format("YYYY-MM-DD"));
    setEndSearchDate(endDate.format("YYYY-MM-DD"));

    const statisticsUrl =
      "?" +
      (startDate ? "startDate=" + startDate.format("YYYY-MM-DD") + "&" : "") +
      (endDate ? "endDate=" + endDate.format("YYYY-MM-DD") + "&" : "");

    history.push(`/statistics${statisticsUrl}`);
  };

  const { data, loading } = useQuery(STATISTICS_QUERY, {
    variables: {
      startDate: startSearchDate,
      endDate: endSearchDate
    }
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      IssuerStatistics: {
        PaymentCount,
        PaymentTotal,
        RemittanceCount,
        RemittanceTotal,

        usersStoreOrder,
        usersRemittance,
        stores
      }
    } = data;
    return (
      <>
        <Helmet>
          <title>통계 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">통계</li>
          </ol>
          <h1 className="page-header">통계</h1>
          <div className="row">
            <div className="col-xl-12">
              <DateRangePicker
                startDate={Moment(startDisplayDate)}
                endDate={Moment(endDisplayDate)}
                onApply={handleDateApplyEvent}
              >
                <button className="btn btn-white mr-2 text-truncate m-b-5">
                  <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                  <span>
                    {startDisplayDate} ~ {endDisplayDate}
                  </span>
                  <b className="caret"></b>
                </button>
              </DateRangePicker>
            </div>
          </div>
          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">소비자 현황</b>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faReceipt} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/Payment?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={PaymentTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      {currencyName(userData.AdminInfo.addCurrency)}(
                      {PaymentCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">결제금액(건)</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faCreditCardBlank} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/ledgers?currency=DRM&transactionType=Remittance&startSearchDate=${startDisplayDate}&endSearchDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={RemittanceTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      {currencyName(userData.AdminInfo.addCurrency)}(
                      {RemittanceCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">송금금액(건)</div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%"
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3>상점결제(환불제외) TOP 10</h3>
                  </div>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {usersStoreOrder.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            <Link
                              to={`/ledgers?currency=DRM&transactionType=StoreOrder&startSearchDate=${startDisplayDate}&endSearchDate=${endDisplayDate}&wallet=${item.wallet}`}
                            >
                              {item.StoreOrderTotal > 0
                                ? `${item.name}(${item.userId})`
                                : "-"}
                            </Link>
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.StoreOrderTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          원({item.StoreOrderCount}건)
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%"
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3>송금 TOP 10</h3>
                  </div>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {usersRemittance.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            <Link
                              to={`/ledgers?currency=DRM&transactionType=Remittance&startSearchDate=${startDisplayDate}&endSearchDate=${endDisplayDate}&wallet=${item.wallet}`}
                            >
                              {item.RemittanceTotal > 0
                                ? `${item.name}(${item.userId})`
                                : "-"}
                            </Link>
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.RemittanceTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          {currencyName(userData.AdminInfo.addCurrency)}(
                          {item.RemittanceCount}건)
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">가맹점 현황</b>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%"
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3>결제금액</h3>
                  </div>
                  <h3 className="m-b-10">
                    <NumberFormat
                      value={PaymentTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    {currencyName(userData.AdminInfo.addCurrency)}(
                    {PaymentCount}
                    건)
                  </h3>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {stores.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            <Link
                              to={`/statistics/StorePayment?id=${item.id}&startDate=${startDisplayDate}&endDate=${endDisplayDate}&wallet=${item.wallet}`}
                            >
                              {item.name}({item.count}건)
                            </Link>
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.total}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          {currencyName(userData.AdminInfo.addCurrency)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
