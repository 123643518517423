import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Panel,
  PanelHeader,
  PanelBody
} from "../../../../Components/Panel/Panel";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { HELP_CATEGORY_LIST_QUERY, HELP_WRITE_QUERY } from "../Queries";
import { useQuery, useMutation } from "react-apollo-hooks";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const { data } = useQuery(HELP_CATEGORY_LIST_QUERY, {
    variables: {
      helpConfig: match.params.helpConfig,
      commonApp: match.params.CommonApp
    }
  });
  const [helpWriteMutation] = useMutation(HELP_WRITE_QUERY);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    console.log(data);
    try {
      const {
        data: { AdminHelpWrite }
      } = await helpWriteMutation({
        variables: {
          commonApp: match.params.CommonApp,
          helpConfig: match.params.helpConfig,
          helpCategory: data.helpCategory,
          question: data.question,
          answer: data.answer
        }
      });
      if (AdminHelpWrite) {
        toast.success("처리 되었습니다.");
        history.push(
          `/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Helmet>
        <title>도움말 관리 | 한밭페이</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">도움말</li>
          </ol>
          <h1 className="page-header">도움말</h1>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>목록</PanelHeader>
                <PanelBody className="panel-form form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">분류</label>
                    <div className="col-md-10">
                      {data ? (
                        data.AdminHelpCategoryList.helpCategories.map(
                          (category, index) => {
                            return (
                              <div
                                key={index}
                                className="radio radio-css radio-inline"
                              >
                                <input
                                  key={category.id}
                                  type="radio"
                                  id={`helpCategory${category.id}`}
                                  name="helpCategory"
                                  className="form-control"
                                  value={category.id}
                                  ref={register({
                                    required: "분류를 선택해주세요."
                                  })}
                                />
                                <label htmlFor={`helpCategory${category.id}`}>
                                  {category.name}
                                </label>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <span>분류추가 후 이용해주세요.</span>
                      )}
                      {errors.helpCategory && (
                        <span>{errors.helpCategory.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">질문</label>
                    <div className="col-md-10">
                      <input
                        type="text"
                        name="question"
                        className="form-control"
                        placeholder="질문을 입력해주세요."
                        ref={register({
                          required: "질문을 입력해주세요."
                        })}
                      />
                      {errors.question && (
                        <span>{errors.question.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xs-12">
                      <textarea
                        name="answer"
                        className="form-control"
                        rows="10"
                        placeholder="답변 입력해주세요."
                        ref={register({
                          required: "답변 입력해주세요."
                        })}
                      ></textarea>
                      {errors.answer && <span>{errors.answer.message}</span>}
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/helpConfigs/${match.params.CommonApp}/${match.params.helpConfig}/helps${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
                {/* <a href="" className="btn btn-white text-success">
                <FontAwesomeIcon icon={faCheck} />
                저장
              </a> */}
                {/* <a href="" className="btn btn-white text-danger">
                <FontAwesomeIcon icon={faTrashAlt}/>
                삭제
              </a> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
