import { gql } from "apollo-boost";

// 비동기 유저의 가맹점 셀렉트
export const ADMIN_USER_STORE_ASYNC_SELECT_MUTATION = gql`
  mutation AdminUserStoreAsyncSelect($keyword: String, $skip: Int, $take: Int) {
    AdminUserStoreAsyncSelect(keyword: $keyword, skip: $skip, take: $take) {
      users {
        id
        role
        confirmed
        blocked
        userId
        name

        store {
          id
          commonStatus
          storeCategory {
            id
            name
          }
          storeCategories {
            id
            name
          }
          storeProductsCount

          storeLogo
          name
          productInfo
          storeInfo

          storeOperationHour
          storePersonalDay
          storeTel
          storeCEO
          email
          phone

          storeZipcode
          storeRoadAddress
          storeJibunAddress
          storeAddressDetail

          isGift
          createdAt
          updatedAt
        }
      }

      count
    }
  }
`;

// 동 목록
export const ADMIN_DELIVERY_STORE_DONG_LIST_QUERY = gql`
  query AdminDeliveryStoreDongList($skip: Int!, $take: Int) {
    AdminDeliveryStoreDongList(skip: $skip, take: $take) {
      deliveryStoreDongs {
        id
        createdAt
        updatedAt

        commonStatus
        sido
        sigungu
        upmyundong
        ri
      }
      count
    }
  }
`;

// 동 셀렉트
export const ADMIN_DELIVERY_STORE_DONG_SELECT_QUERY = gql`
  query AdminDeliveryStoreDongSelect(
    $deliveryStore_id: String
    $skip: Int!
    $take: Int
  ) {
    AdminDeliveryStoreDongSelect(
      deliveryStore_id: $deliveryStore_id
      skip: $skip
      take: $take
    ) {
      deliveryStoreDongs {
        id
        createdAt
        updatedAt

        commonStatus
        sido
        sigungu
        upmyundong
        ri
      }
      count
    }
  }
`;

// 매장 목록
export const ADMIN_DELIVERY_STORE_LIST_QUERY = gql`
  query AdminDeliveryStoreList($skip: Int!, $take: Int) {
    AdminDeliveryStoreList(skip: $skip, take: $take) {
      deliveryStores {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
        deliveryStoreDongs {
          id
          createdAt
          updatedAt

          sido
          sigungu
          upmyundong
          ri
        }
        deliveryPlaces {
          id
          createdAt
          updatedAt

          name
        }

        deliveryStoreDongsCount
        deliveryPlacesCount
      }
      count
    }
  }
`;

// 픽업장소 목록
export const ADMIN_DELIVERY_PLACE_LIST_QUERY = gql`
  query AdminDeliveryPlaceList($skip: Int!, $take: Int) {
    AdminDeliveryPlaceList(skip: $skip, take: $take) {
      deliveryPlaces {
        id
        createdAt
        updatedAt

        name
        managerName
        managerPhone
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        # deliveryStore {
        #   id
        #   createdAt
        #   updatedAt

        #   store {
        #     id
        #     createdAt
        #     updatedAt

        #     name
        #   }
        #   user {
        #     id
        #     createdAt
        #     updatedAt

        #     name
        #   }
        # }
      }
      count
    }
  }
`;

// 비동기 매장 셀렉트
export const ADMIN_DELIVERY_STORE_ASYNC_SELECT_MUTATION = gql`
  mutation AdminDeliveryStoreAsyncSelect(
    $keyword: String
    $skip: Int
    $take: Int
  ) {
    AdminDeliveryStoreAsyncSelect(keyword: $keyword, skip: $skip, take: $take) {
      deliveryStores {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
      }

      count
    }
  }
`;

// 상품 분류 목록
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY = gql`
  query AdminDeliveryProductCategoryList($skip: Int!, $take: Int) {
    AdminDeliveryProductCategoryList(skip: $skip, take: $take) {
      deliveryProductCategories {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order

        deliveryProductsCount
      }
      count
    }
  }
`;

// 상품 목록
export const ADMIN_DELIVERY_PRODUCT_LIST_QUERY = gql`
  query AdminDeliveryProductList($skip: Int!, $take: Int, $keyword: String) {
    AdminDeliveryProductList(skip: $skip, take: $take, keyword: $keyword) {
      deliveryProducts {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        price
        name
        description

        deliveryProductCategory {
          id
          createdAt
          updatedAt

          commonStatus
          iconImage
          name
          order
        }
        deliveryProductOptions {
          id
          createdAt
          updatedAt

          commonStatus
          name
          deliveryProductOptionDetails {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
  }
`;

// 배송기사 목록
export const ADMIN_DELIVERY_DELIVER_LIST_QUERY = gql`
  query AdminDeliveryDeliverList(
    $skip: Int!
    $take: Int
    $deliveryDeliver_id: String
  ) {
    AdminDeliveryDeliverList(
      skip: $skip
      take: $take
      deliveryDeliver_id: $deliveryDeliver_id
    ) {
      deliveryDelivers {
        id
        createdAt
        updatedAt

        state

        # deliveryOrders
        user {
          id
          createdAt
          updatedAt

          userId
          name
        }

        # orderCount
      }
      count
    }
  }
`;

// 구독박스 셀렉트
export const ADMIN_DELIVERY_BOX_ASYNC_SELECT_MUTATION = gql`
  mutation AdminDeliveryBoxAsyncSelect(
    $skip: Int!
    $take: Int
    $keyword: String
  ) {
    AdminDeliveryBoxAsyncSelect(skip: $skip, take: $take, keyword: $keyword) {
      deliveryBoxes {
        id
        createdAt
        updatedAt

        state
        type
        # deliveryPlace
        # deliveryAddress
        # deliveryBoxProducts

        # deliveryOrders
        user {
          id
          createdAt
          updatedAt

          userId
          name
        }

        # condition
        # totalPrice
        # deliveryDate
      }
      count
    }
  }
`;
// 구독박스 상세정보
export const ADMIN_DELIVERY_BOX_DETAIL_QUERY = gql`
  query AdminDeliveryBoxDetail($id: String!) {
    AdminDeliveryBoxDetail(id: $id) {
      id
      createdAt
      updatedAt

      state
      type
      deliveryPlace {
        id
        createdAt
        updatedAt

        commonStatus
        name
        managerName
        managerPhone
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      deliveryAddress {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        dayOfWeek
        sido
        sigungu
        upmyundong
        li

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      deliveryBoxProducts {
        id
        createdAt
        updatedAt

        state
        price
        count
        totalPrice

        deliveryProduct {
          id
          createdAt
          updatedAt

          commonStatus
          timestamp
          coverImage
          price
          name
          description
        }
        deliveryProductOptionDetail1 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail2 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail3 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }
      user {
        id
        createdAt
        updatedAt

        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail

        isCms
        cmsValue
        cmsDay

        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
      }

      condition
      totalPrice
      deliveryDate
    }
  }
`;

// 배송기사 셀렉트
export const ADMIN_DELIVERY_DELIVER_ASYNC_SELECT_MUTATION = gql`
  mutation AdminDeliveryDeliverAsyncSelect(
    $skip: Int!
    $take: Int
    $keyword: String
  ) {
    AdminDeliveryDeliverAsyncSelect(
      skip: $skip
      take: $take
      keyword: $keyword
    ) {
      deliveryDelivers {
        id
        createdAt
        updatedAt

        state

        # deliveryOrders
        user {
          id
          createdAt
          updatedAt

          userId
          name
        }

        # orderCount
      }
      count
    }
  }
`;
// 배송기사 상세정보
export const ADMIN_DELIVERY_DELIVER_DETAIL_QUERY = gql`
  query AdminDeliveryDeliverDetail($id: String!) {
    AdminDeliveryDeliverDetail(id: $id) {
      id
      createdAt
      updatedAt

      state

      # deliveryOrders
      user {
        id
        createdAt
        updatedAt

        userId
        name
      }

      # orderCount
    }
  }
`;

///////////////////////////////////////////

// 상품 분류 목록
export const STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY = gql`
  query StoreDeliveryProductCategoryList($skip: Int!, $take: Int) {
    StoreDeliveryProductCategoryList(skip: $skip, take: $take) {
      deliveryProductCategories {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order

        deliveryProductsCount
      }
      count
    }
  }
`;

// 픽업장소 목록
export const STORE_DELIVERY_PLACE_LIST_QUERY = gql`
  query StoreDeliveryPlaceList($skip: Int!, $take: Int) {
    StoreDeliveryPlaceList(skip: $skip, take: $take) {
      deliveryPlaces {
        id
        createdAt
        updatedAt

        name
        managerName
        managerPhone
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        # deliveryStore {
        #   id
        #   createdAt
        #   updatedAt

        #   store {
        #     id
        #     createdAt
        #     updatedAt

        #     name
        #   }
        #   user {
        #     id
        #     createdAt
        #     updatedAt

        #     name
        #   }
        # }
      }
      count
    }
  }
`;

// 배송기사 목록
export const STORE_DELIVERY_DELIVER_LIST_QUERY = gql`
  query StoreDeliveryDeliverList(
    $skip: Int!
    $take: Int
    $deliveryDeliver_id: String
  ) {
    StoreDeliveryDeliverList(
      skip: $skip
      take: $take
      deliveryDeliver_id: $deliveryDeliver_id
    ) {
      deliveryDelivers {
        id
        createdAt
        updatedAt

        state

        # deliveryOrders
        user {
          id
          createdAt
          updatedAt

          userId
          name
        }

        # orderCount
      }
      count
    }
  }
`;
