import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  EXCHANGEREQUEST_CONFIG_QUERY,
  EXCHANGEREQUEST_CONFIG_WRITE_QUERY
} from "./Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import WriteUser from "./WriteUser";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();

  const [exchangeRequestConfigWriteMutation] = useMutation(
    EXCHANGEREQUEST_CONFIG_WRITE_QUERY,
    {
      refetchQueries: () => [{ query: EXCHANGEREQUEST_CONFIG_QUERY }]
    }
  );
  const { register, handleSubmit, errors } = useForm();
  const [user, setUser] = useState(null);
  const onSubmit = async data => {
    if (user === "") {
      toast.error("운영자명을 입력해주세요.");
      return;
    }

    try {
      const {
        data: { AdminExchangeRequestConfigWrite }
      } = await exchangeRequestConfigWriteMutation({
        variables: {
          name: data.name,
          manager: user.id
        }
      });
      if (AdminExchangeRequestConfigWrite) {
        toast.success("설정 되었습니다.");
        history.push("/exchangeRequestConfigs");
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push("/exchangeRequestConfigs");
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push("/exchangeRequestConfigs");
            }}
          >
            설정등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요."
                      })}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">사용자</label>
                  <div className="col-md-10">
                    <WriteUser
                      history={history}
                      match={match}
                      location={location}
                      user={user}
                      setUser={setUser}
                    />
                    {errors.wallet && <span>{errors.wallet.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="col-xs-12 text-right">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
                <Link
                  to="/exchangeRequestConfigs"
                  className="btn btn-white text-red"
                >
                  취소
                </Link>
              </div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
