import { gql } from "apollo-boost";

/* 구독박스 시작 */
export const STORE_DELIVERY_BOX_LIST_QUERY = gql`
  query StoreDeliveryBoxList(
    $skip: Int!
    $take: Int
    $keyword: String
    $type: String
    $deliveryPlace_id: String
    $startDate: String
    $endDate: String
  ) {
    StoreDeliveryBoxList(
      skip: $skip
      take: $take
      keyword: $keyword
      type: $type
      deliveryPlace_id: $deliveryPlace_id
      startDate: $startDate
      endDate: $endDate
    ) {
      deliveryBoxes {
        id
        createdAt
        updatedAt

        state
        type
        deliveryPlace {
          id
          createdAt
          updatedAt

          commonStatus
          name
          managerName
          managerPhone
          zipcode
          jibunAddress
          roadAddress
          addressDetail
          longitude
          latitude
          dayOfWeek

          deliveryStore {
            id
            createdAt
            updatedAt

            store {
              id
              createdAt
              updatedAt

              name
            }
            user {
              id
              createdAt
              updatedAt

              name
            }
          }
        }
        deliveryAddress {
          id
          createdAt
          updatedAt

          commonStatus
          name
          zipcode
          jibunAddress
          roadAddress
          addressDetail
          dayOfWeek
          sido
          sigungu
          upmyundong
          li

          deliveryStore {
            id
            createdAt
            updatedAt

            store {
              id
              createdAt
              updatedAt

              name
            }
            user {
              id
              createdAt
              updatedAt

              name
            }
          }
        }
        user {
          id
          createdAt
          updatedAt

          commonStatus
          role
          useStoreApp
          confirmed
          blocked
          avatar
          userId
          email
          phone
          name
          sex
          birthDate
          zipcode
          roadAddress
          jibunAddress
          addressDetail

          isCms
          cmsValue
          cmsDay

          trustlines {
            id
            commonStatus
            currency
            amount
            createdAt
            updatedAt
          }
        }

        condition
        totalPrice
        deliveryDate
      }
      count
    }
  }
`;
export const STORE_DELIVERY_BOX_DETAIL_QUERY = gql`
  query StoreDeliveryBoxDetail($id: String!) {
    StoreDeliveryBoxDetail(id: $id) {
      id
      createdAt
      updatedAt

      state
      type
      deliveryPlace {
        id
        createdAt
        updatedAt

        commonStatus
        name
        managerName
        managerPhone
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      deliveryAddress {
        id
        createdAt
        updatedAt

        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        dayOfWeek
        sido
        sigungu
        upmyundong
        li

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
      }
      deliveryBoxProducts {
        id
        createdAt
        updatedAt

        state
        price
        count
        totalPrice

        deliveryProduct {
          id
          createdAt
          updatedAt

          commonStatus
          timestamp
          coverImage
          price
          name
          description
        }
        deliveryProductOptionDetail1 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail2 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail3 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }
      user {
        id
        createdAt
        updatedAt

        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail

        isCms
        cmsValue
        cmsDay

        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
      }

      condition
      totalPrice
      deliveryDate
    }
  }
`;
/* 구독박스 끝 */

/* 구독박스 주소 시작 */
export const STORE_DELIVERY_ADDRESS_LIST_QUERY = gql`
  query StoreDeliveryAddressList($skip: Int!, $take: Int) {
    StoreDeliveryAddressList(skip: $skip, take: $take) {
      deliveryAddresses {
        id
        createdAt
        updatedAt
        commonStatus
        name
        zipcode
        jibunAddress
        roadAddress
        addressDetail
        longitude
        latitude
        dayOfWeek
        sido
        sigungu
        upmyundong
        li

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }
        user {
          id
          createdAt
          updatedAt

          commonStatus
          role
          useStoreApp
          confirmed
          blocked
          avatar
          userId
          email
          phone
          name
          sex
          birthDate
          zipcode
          roadAddress
          jibunAddress
          addressDetail

          isCms
          cmsValue
          cmsDay

          trustlines {
            id
            commonStatus
            currency
            amount
            createdAt
            updatedAt
          }
        }
      }
      count
    }
  }
`;
export const STORE_DELIVERY_ADDRESS_WRITE_MUTATION = gql`
  mutation StoreDeliveryAddressWrite(
    $name: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String
    $latitude: String
    $dayOfWeek: String!
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $li: String
    $deliveryBox_id: String!
  ) {
    StoreDeliveryAddressWrite(
      name: $name
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      li: $li
      deliveryBox_id: $deliveryBox_id
    )
  }
`;
export const STORE_DELIVERY_ADDRESS_DETAIL_QUERY = gql`
  query StoreDeliveryAddressDetail($id: String!) {
    StoreDeliveryAddressDetail(id: $id) {
      id
      createdAt
      updatedAt
      commonStatus
      name
      zipcode
      jibunAddress
      roadAddress
      addressDetail
      longitude
      latitude
      dayOfWeek
      sido
      sigungu
      upmyundong
      li

      deliveryStore {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
      }
      user {
        id
        createdAt
        updatedAt

        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail

        isCms
        cmsValue
        cmsDay

        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const STORE_DELIVERY_ADDRESS_EDIT_MUTATION = gql`
  mutation StoreDeliveryAddressEdit(
    $id: String!
    $name: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String
    $latitude: String
    $dayOfWeek: String!
    $sido: String!
    $sigungu: String!
    $upmyundong: String!
    $li: String
  ) {
    StoreDeliveryAddressEdit(
      id: $id
      name: $name
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      sido: $sido
      sigungu: $sigungu
      upmyundong: $upmyundong
      li: $li
    )
  }
`;
export const STORE_DELIVERY_ADDRESS_DELETE_MUTATION = gql`
  mutation StoreDeliveryAddressDelete($id: String!) {
    StoreDeliveryAddressDelete(id: $id)
  }
`;

/* 구독박스 주소 끝 */

/* 요일별 주문현황 시작 */
export const STORE_DELIVERY_BOX_DAYOFWEEK_QUERY = gql`
  query StoreDeliveryBoxDayOfWeek {
    StoreDeliveryBoxDayOfWeek {
      dayOfWeek
      deliveryBoxDayOfWeek {
        dayOfWeek
        deliveryProduct_id
        deliveryProduct_name
        deliveryProductOptionDetail1_name
        deliveryProductOptionDetail2_name
        deliveryProductOptionDetail3_name
        sum
        count
      }
    }
  }
`;
/* 요일별 주문현황 */
