import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody
} from "../../../../Components/Panel/Panel";
import { useQuery, useMutation } from "react-apollo-hooks";
import { INQUIRY_DETAIL_QUERY, INQUIRY_DELETE_QUERY } from "../Queries";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faList } from "@fortawesome/pro-regular-svg-icons";
import nl2br from "react-nl2br";

export default withRouter(({ history, match, location }) => {
  const { data: detail, loading } = useQuery(INQUIRY_DETAIL_QUERY, {
    variables: { id: match.params.id }
  });

  const [inquiryDeleteMutation] = useMutation(INQUIRY_DELETE_QUERY);
  const onDelete = async id => {
    try {
      const {
        data: { AdminInquiryDelete }
      } = await inquiryDeleteMutation({
        variables: {
          id: id
        }
      });
      if (AdminInquiryDelete) {
        toast.success("처리 되었습니다.");
        history.push(
          `/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && detail && detail.AdminInquiryDetail) {
    const {
      AdminInquiryDetail: {
        id,
        commonApp,
        commonStatus,
        email,
        phone,
        user,
        question,
        answer,
        createdAt,
        updatedAt
      }
    } = detail;

    return (
      <>
        <Helmet>
          <title>1:1 문의 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">1:1문의</li>
          </ol>
          <h1 className="page-header">1:1문의</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries/${id}/reply${location.search}`}
                >
                  <FontAwesomeIcon icon={faReply} /> 답변
                </Link>
                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>
                  질문 - {user.name}({user.userId})
                </PanelHeader>
                <PanelBody>
                  <div className="wrapper">
                    <h3 className="m-t-0 m-b-15 f-w-500">
                      <label htmlFor="" className="label label-primary">
                        {commonStatus === "S" ? "답변대기" : "답변완료"}
                      </label>{" "}
                      질문합니다.
                    </h3>
                    <ul className="media-list underline m-b-15 p-b-15">
                      <li className="media media-sm clearfix">
                        <div className="media-body">
                          <div>
                            질문자 :{" "}
                            <a
                              href={`/users/${
                                commonApp === "Wallet" ? "User" : "Store"
                              }/${user.id}`}
                            >
                              {user.name}({user.userId})
                            </a>
                          </div>
                          <div>이메일 : {email}</div>
                          <div>연락처 : {phone}</div>
                          <div>
                            등록일 :{" "}
                            <Moment format="YYYY-MM-DD HH:mm:ss">
                              {createdAt}
                            </Moment>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <p className="f-s-12 text-inverse">{nl2br(question)}</p>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            {commonStatus === "C" && (
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader>답변</PanelHeader>
                  <PanelBody>
                    <div className="wrapper">
                      <h3 className="m-t-0 m-b-15 f-w-500">답변드립니다.</h3>
                      <ul className="media-list underline m-b-15 p-b-15">
                        <li className="media media-sm clearfix">
                          <div className="media-body">
                            <div>
                              <i className="far fa-calendar-alt fa-fw"></i>{" "}
                              <Moment format="YYYY-MM-DD HH:mm:ss">
                                {updatedAt}
                              </Moment>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <p className="f-s-12 text-inverse">{nl2br(answer)}</p>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <Link
                  className="btn btn-white text-success"
                  to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries/${id}/reply${location.search}`}
                >
                  <FontAwesomeIcon icon={faReply} /> 답변
                </Link>
                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
