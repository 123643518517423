import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  commonStatusName,
  roleArray,
  roleName,
  currencyName,
  bankName,
  reasonContent,
} from "../../../../Components/User/UserNames";
import Moment from "moment";
import NumberFormat from "react-number-format";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default ({
  history,
  match,
  location,
  page,
  startSearchDate,
  endSearchDate,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleDateApplyEvent,
  handleSearch,
  DownloadButton,
  handleCancel,
  handleApproval,
}) => {
  const Role = () => {
    const urlConfig = (role) => {
      const ledgersUrl = "?";
      // (currency ? "currency=" + currency + "&" : "") +
      // (transactionType ? "transactionType=" + transactionType + "&" : "") +
      // (match.params.role ? "role=" + match.params.role + "&" : "") +
      // (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      // (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
      // (wallet ? "wallet=" + wallet + "&" : "");
      return ledgersUrl;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        {Object.keys(roleArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                match.params.role === item ? "active" : ""
              }
            `}
              to={`/users/${item}${urlConfig(item)}`}
            >
              {roleArray[item]}
            </Link>
          );
        })}
        <Link className={`btn btn-white m-b-5 active`} to={`/withdrawals`}>
          탈퇴자
        </Link>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>탈퇴자 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/users/User">사용자</Link>
          </li>
          <li className="breadcrumb-item active">탈퇴자</li>
        </ol>
        <h1 className="page-header">탈퇴자</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminWithdrawalList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <Role />
                <div className="btn-group m-b-15 m-r-5">
                  <DateRangePicker
                    startDate={Moment(startSearchDate)}
                    endDate={Moment(endSearchDate)}
                    onApply={handleDateApplyEvent}
                  >
                    <button className="btn btn-white mr-2 text-truncate m-b-5">
                      <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                      <span>
                        {startSearchDate} ~ {endSearchDate}
                      </span>
                      <b className="caret"></b>
                    </button>
                  </DateRangePicker>
                  <DownloadButton />
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(아이디, 예금주, 이메일, 휴대전화) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              {!loading && data.AdminWithdrawalList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <colgroup>
                          <col width="5%" />
                          <col width="5%" />
                          <col width="15%" />
                          <col width="15%" />
                          <col width="15%" />
                          <col width="15%" />
                          <col width="15%" />
                          <col width="15%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>권한</th>
                            <th>사용자정보</th>
                            <th>환전정보 </th>
                            <th>잔액</th>
                            <th>탈퇴사유</th>
                            <th>탈퇴일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminWithdrawalList.withdrawals.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{commonStatusName(item.commonStatus)}</td>
                                <td>{roleName(item.user.role)}</td>
                                <td>
                                  <a
                                    href={`/users/${item.user.role}/${item.user.id}`}
                                  >
                                    {item.user.name}({item.userId})
                                  </a>
                                  <br />
                                  {item.email}
                                  <br />
                                  {item.phone}
                                </td>
                                <td>
                                  은행 : {bankName(item.bank_name)}
                                  <br />
                                  계좌번호 : {item.bank_num}
                                  <br />
                                  예금주 : {item.bank_account_holder}
                                </td>
                                <td align="right">
                                  {item.user.trustlines.map((trustline) => {
                                    return (
                                      <span key={trustline.id}>
                                        <NumberFormat
                                          value={trustline.amount}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={
                                            currencyName(trustline.currency) +
                                            " : "
                                          }
                                        />
                                        <br />
                                      </span>
                                    );
                                  })}
                                </td>
                                <td>
                                  이유 : {reasonContent(item.reason)}
                                  {item.reason === 4 && (
                                    <>
                                      <br />
                                      내용 : {item.memo}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {item.createdAt}
                                  </ReactMoment>
                                </td>
                                <td align="center">
                                  {item.commonStatus === "S" && (
                                    <>
                                      <button
                                        className="btn btn-white btn-xs text-red"
                                        onClick={(e) => handleCancel(e, item)}
                                      >
                                        복구
                                      </button>
                                      <button
                                        className="btn btn-white btn-xs text-red"
                                        onClick={(e) => handleApproval(e, item)}
                                      >
                                        승인
                                      </button>
                                    </>
                                  )}
                                  {item.commonStatus === "C" && (
                                    <button className="btn btn-white btn-xs text-green disabled">
                                      완료
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminWithdrawalList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
