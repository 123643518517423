import { gql } from "apollo-boost";

//사용자
export const USER_LIST_QUERY = gql`
  query IssuerUserList(
    $role: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    IssuerUserList(
      role: $role
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        addCurrency
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          name
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const USER_DETAIL_QUERY = gql`
  query IssuerUserDetail($id: String!) {
    IssuerUserDetail(id: $id) {
      id
      commonStatus
      confirmed
      blocked
      avatar
      userId
      email
      phone
      name
      zipcode
      roadAddress
      jibunAddress
      addressDetail
      role
      addCurrency
      wallet {
        id
        type
        userId
        address
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
        }
        memo
        createdAt
      }
      store {
        id
        name
        storeProofData
      }
      updatedAt

      isCms
      cmsValue
      cmsDay

      longitude
      latitude
    }
  }
`;

export const TRUSTLINE_DETAIL_QUERY = gql`
  query IssuerTrustlineDetail($id: String!) {
    IssuerTrustlineDetail(id: $id) {
      id
      commonStatus
      currency
      amount
      createdAt
      updatedAt
    }
  }
`;

export const TRUSTLINE_EDIT_COMMONSTATUS_QUERY = gql`
  mutation IssuerTrustlineEditCommonStatus($id: String!) {
    IssuerTrustlineEditCommonStatus(id: $id)
  }
`;

export const LEDGER_LIST_QUERY = gql`
  query IssuerLedgerList($trustline: String, $year: Int, $month: Int) {
    IssuerLedgerList(
      trustline: $trustline
      orderBy: "id_DESC"
      year: $year
      month: $month
    ) {
      ledgers {
        id
        transactionType
        accountWallet {
          id
          userId
        }
        destinationWallet {
          id
          userId
        }
        title
        currency
        value
        createdAt
        updatedAt
        wallet {
          name
        }
      }
      count
    }
  }
`;

export const WALLET_LIST_QUERY = gql`
  query AdminWalletList($keyword: String, $skip: Int, $first: Int) {
    AdminWalletList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      wallets {
        id
        type
        userId
        name
        address
        secret
        trustlines {
          id
        }
        memo
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const WALLET_DETAIL_QUERY = gql`
  query AdminWalletDetail($id: String!) {
    AdminWalletDetail(id: $id) {
      id
      type
      userId
      name
      address
      secret
      trustlines {
        id
      }
      memo
      createdAt
      updatedAt
    }
  }
`;

/* 가맹점 설정 */
export const STORE_CONFIG_QUERY = gql`
  query AdminStoreConfig($id: String) {
    AdminStoreConfig(id: $id) {
      id
      name
      listCount
      pageCount
    }
  }
`;

/* 가맹점 분류 */
export const STORE_CATEGORY_LIST_QUERY = gql`
  query AdminStoreCategoryList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreCategoryList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      storeCategories {
        id
        storesCount
        pcIcon
        mobileIcon
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

/* 가맹점 */
export const STORE_LIST_QUERY = gql`
  query AdminStoreList(
    $storeCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminStoreList(
      storeCategory: $storeCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id

        # storeConfig
        storeCategory {
          id
          name
        }
        storeProductsCount

        storeLogo
        name
        productInfo
        storeInfo

        storeOperationHour
        storePersonalDay
        storeTel
        storeCEO

        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const STORE_DETAIL_QUERY = gql`
  query AdminStoreDetail($id: String!) {
    AdminStoreDetail(id: $id) {
      id

      # storeConfig
      storeCategory {
        id
        name
      }

      storeLogo
      name
      productInfo
      storeInfo

      storeOperationHour
      storePersonalDay
      storeTel
      storeCEO

      storeZipcode
      storeRoadAddress
      storeJibunAddress
      storeAddressDetail
      createdAt
      updatedAt
    }
  }
`;

export const STORE_DETAIL_BY_USERID_QUERY = gql`
  query AdminStoreDetailByUserId($UserId: String!) {
    AdminStoreDetailByUserId(UserId: $UserId) {
      id

      # storeConfig
      storeCategory {
        id
        name
      }

      storeLogo
      name
      productInfo
      storeInfo

      storeOperationHour
      storePersonalDay
      storeTel
      storeCEO

      storeZipcode
      storeRoadAddress
      storeJibunAddress
      storeAddressDetail
      createdAt
      updatedAt
    }
  }
`;
