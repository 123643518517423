import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";
import qs from "qs";
import moment from "moment";

import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_BOX_LIST_QUERY } from "../Queries";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const keyword = queryString.keyword ? queryString.keyword : "";
  const type = queryString.type ? queryString.type : "";
  const deliveryStore_id = queryString.deliveryStore_id
    ? queryString.deliveryStore_id
    : "";
  const deliveryPlace_id = queryString.deliveryPlace_id
    ? queryString.deliveryPlace_id
    : "";
  const startDate = queryString.startDate
    ? queryString.startDate
    : moment().format("YYYY-MM-DD");
  const endDate = queryString.endDate
    ? queryString.endDate
    : moment().add(7, "d").format("YYYY-MM-DD");
  const page = queryString.page ? +queryString.page : 1;
  const take = 15;
  const baseUrl = "/deliveryBoxes";

  const onStartDateSelect = (date) => {
    var date = moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (type ? "type=" + type + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryPlace_id ? "deliveryPlace_id=" + deliveryPlace_id + "&" : "") +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (type ? "type=" + type + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryPlace_id ? "deliveryPlace_id=" + deliveryPlace_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().add(7, "d").format("YYYY-MM-DD");
    }
    if (e.target.value === "Today") {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "Tomorrow") {
      startDate = moment().add(1, "d").format("YYYY-MM-DD");
      endDate = moment().add(1, "d").format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (type ? "type=" + type + "&" : "") +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryPlace_id ? "deliveryPlace_id=" + deliveryPlace_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const { data, loading, error } = useQuery(ADMIN_DELIVERY_BOX_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take,
      skip: (page - 1) * take,
      keyword,
      type,
      deliveryStore_id,
      deliveryPlace_id,
      startDate,
      endDate,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      keyword={keyword}
      type={type}
      deliveryStore_id={deliveryStore_id}
      deliveryPlace_id={deliveryPlace_id}
      startDate={startDate}
      endDate={endDate}
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
    />
  );
});
