import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_PRODUCT_NEW_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(GIFT_PRODUCT_NEW_LIST_QUERY);

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      loading={loading}
    ></Presenter>
  );
});
