import { gql } from "apollo-boost";

/* 상품 설정 */
export const TICKET_CONFIG_LIST_QUERY = gql`
  query StoreTicketConfigList($skip: Int, $first: Int) {
    StoreTicketConfigList(orderBy: "id_ASC", skip: $skip, first: $first) {
      ticketConfigs {
        id
        name
        createdAt
        updatedAt
        ticketProductsCount
      }
      count
    }
  }
`;

/* 상품 분류 */
export const TICKET_CATEGORY_LIST_QUERY = gql`
  query StoreTicketCategoryList(
    $ticketConfig: String
    $parentId: String
    $skip: Int
    $first: Int
  ) {
    StoreTicketCategoryList(
      ticketConfig: $ticketConfig
      parentId: $parentId
      orderBy: "order_ASC"
      skip: $skip
      first: $first
    ) {
      ticketCategories {
        id
        parentId
        ticketConfig {
          id
        }
        commonStatus
        iconImage
        name
        order
        createdAt
        updatedAt

        ticketProductsCount
        childrenCount
        children {
          id
          parentId
          ticketConfig {
            id
          }
          commonStatus
          iconImage
          name
          order
          createdAt
          updatedAt

          ticketProductsCount
          childrenCount
          children {
            id
            parentId
            ticketConfig {
              id
            }
            commonStatus
            iconImage
            name
            order
            createdAt
            updatedAt

            ticketProductsCount
            childrenCount
          }
        }
      }
      count
    }
  }
`;

/* 상품 */
export const TICKET_PRODUCT_LIST_QUERY = gql`
  query StoreTicketProductList(
    $ticketConfig: String
    $ticketCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    StoreTicketProductList(
      ticketConfig: $ticketConfig
      ticketCategory: $ticketCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketProducts {
        id
        ticketConfig {
          id
          name
        }
        commonStatus

        coverImage

        store {
          id
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt

        ticketProductDates {
          id
          commonStatus
          ticketDate
          ticketProductDateTimes {
            id
            commonStatus
            isSoldOut
            ticketDateTime
            # ticketOrdersCount

            ticketOrderProductPeoplesCount
          }
          # ticketOrdersCount
          ticketOrderProductPeoplesCount
        }
      }
      count
    }
  }
`;
export const TICKET_PRODUCT_WRITE_QUERY = gql`
  mutation StoreTicketProductWrite(
    $ticketConfig: String!
    $coverImage: FileUpload!
    $managerName: String
    $managerTel: String
    $managerEmail: String
    $minute: String!
    $limit: String!
    $isPay: Boolean!
    $price: String
    $isDiscount: Boolean
    $discountPrice: String
    $applyStartDateTime: String!
    $applyEndDateTime: String!
    $placeType: String!
    $placeZipcode: String
    $placeAddress: String
    $placeAddressDetail: String
    $placeLongitude: String
    $placeLatitude: String
    $ticketCategory: String!
    $name: String!
    $contents: String
    $timestamp: String!
  ) {
    StoreTicketProductWrite(
      ticketConfig: $ticketConfig

      coverImage: $coverImage

      managerName: $managerName
      managerTel: $managerTel
      managerEmail: $managerEmail

      minute: $minute
      limit: $limit
      isPay: $isPay

      price: $price
      isDiscount: $isDiscount
      discountPrice: $discountPrice

      applyStartDateTime: $applyStartDateTime
      applyEndDateTime: $applyEndDateTime
      placeType: $placeType

      placeZipcode: $placeZipcode
      placeAddress: $placeAddress
      placeAddressDetail: $placeAddressDetail
      placeLongitude: $placeLongitude
      placeLatitude: $placeLatitude

      ticketCategory: $ticketCategory
      name: $name
      contents: $contents

      timestamp: $timestamp
    )
  }
`;
export const TICKET_PRODUCT_DETAIL_QUERY = gql`
  query StoreTicketProductDetail($id: String!) {
    StoreTicketProductDetail(id: $id) {
      id
      ticketConfig {
        id
        name
      }
      commonStatus

      coverImage

      store {
        id
        name
      }

      managerName
      managerTel
      managerEmail

      minute
      limit
      isPay

      price
      isDiscount
      discountPrice

      applyStartDateTime
      applyEndDateTime
      placeType

      placeZipcode
      placeAddress
      placeAddressDetail
      placeLongitude
      placeLatitude

      ticketCategory {
        id
        name
        parentCategory {
          id
          name
        }
      }
      name
      contents

      isSoldOut
      timestamp

      createdAt
      updatedAt

      ticketProductDates {
        id
        commonStatus
        ticketDate
        ticketProductDateTimes {
          id
          commonStatus
          isSoldOut
          ticketDateTime
          # ticketOrdersCount

          ticketOrderProductPeoplesCount
        }
        # ticketOrdersCount
        ticketOrderProductPeoplesCount
      }
      ticketProductDatesCount
      ticketProductDateTimesCount

      ticketProductReviews {
        id
        commonStatus
        # orderProduct {
        #   id
        # }
        contents
        createdAt
        updatedAt
      }
      ticketProductInquiries {
        id
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt
      }
    }
  }
`;
export const TICKET_PRODUCT_EDIT_QUERY = gql`
  mutation StoreTicketProductEdit(
    $id: String!
    $coverImage: FileUpload
    $isSoldOut: Boolean
    $managerName: String
    $managerTel: String
    $managerEmail: String
    $minute: String!
    $limit: String!
    $isPay: Boolean!
    $price: String
    $isDiscount: Boolean
    $discountPrice: String
    $applyStartDateTime: String!
    $applyEndDateTime: String!
    $placeType: String!
    $placeZipcode: String
    $placeAddress: String
    $placeAddressDetail: String
    $placeLongitude: String
    $placeLatitude: String
    $ticketCategory: String!
    $name: String!
    $contents: String
  ) {
    StoreTicketProductEdit(
      id: $id

      coverImage: $coverImage

      isSoldOut: $isSoldOut

      managerName: $managerName
      managerTel: $managerTel
      managerEmail: $managerEmail

      minute: $minute
      limit: $limit
      isPay: $isPay

      price: $price
      isDiscount: $isDiscount
      discountPrice: $discountPrice

      applyStartDateTime: $applyStartDateTime
      applyEndDateTime: $applyEndDateTime
      placeType: $placeType

      placeZipcode: $placeZipcode
      placeAddress: $placeAddress
      placeAddressDetail: $placeAddressDetail
      placeLongitude: $placeLongitude
      placeLatitude: $placeLatitude

      ticketCategory: $ticketCategory
      name: $name
      contents: $contents
    )
  }
`;
export const TICKET_PRODUCT_DELETE_QUERY = gql`
  mutation StoreTicketProductDelete($id: String!) {
    StoreTicketProductDelete(id: $id)
  }
`;

/* 티켓 일정 */
export const TICKET_PRODUCT_DATE_WRITE_QUERY = gql`
  mutation StoreTicketProductDateWrite(
    $ticketProduct: String!
    $commonStatus: String!
    $ticketDate: String!
    $ticketDateTimes: [WriteTicketDateTimeInput!]
  ) {
    StoreTicketProductDateWrite(
      ticketProduct: $ticketProduct
      commonStatus: $commonStatus
      ticketDate: $ticketDate
      ticketDateTimes: $ticketDateTimes
    )
  }
`;
export const TICKET_PRODUCT_DATE_REPETITION_WRITE_QUERY = gql`
  mutation StoreTicketProductDateRepetitionWrite(
    $ticketProduct: String!
    $commonStatus: String!
    $startDate: String!
    $endDate: String!
    $period: String!
    $term: String!
    $dayOption: [String!]
    $monthOption: String!
    $ticketDateTimes: [WriteTicketDateTimeInput!]
  ) {
    StoreTicketProductDateRepetitionWrite(
      ticketProduct: $ticketProduct
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      period: $period
      term: $term
      dayOption: $dayOption
      monthOption: $monthOption
      ticketDateTimes: $ticketDateTimes
    )
  }
`;
export const TICKET_PRODUCT_DATE_DETAIL_QUERY = gql`
  query StoreTicketProductDateDetail($id: String!) {
    StoreTicketProductDateDetail(id: $id) {
      id
      commonStatus
      ticketDate

      createdAt
      updatedAt
    }
  }
`;
export const TICKET_PRODUCT_DATE_EDIT_QUERY = gql`
  mutation StoreTicketProductDateEdit(
    $id: String!
    $commonStatus: String!
    $ticketDate: String!
  ) {
    StoreTicketProductDateEdit(
      id: $id
      commonStatus: $commonStatus
      ticketDate: $ticketDate
    )
  }
`;
export const TICKET_PRODUCT_DATE_DELETE_QUERY = gql`
  mutation StoreTicketProductDateDelete($id: String!) {
    StoreTicketProductDateDelete(id: $id)
  }
`;

/* 티켓 상세 일정 */
export const TICKET_PRODUCT_DATETIME_WRITE_QUERY = gql`
  mutation StoreTicketProductDateTimeWrite(
    $ticketProductId: String!
    $ticketProductDateId: String!
    $commonStatus: String!
    $ticketDateTime: String!
  ) {
    StoreTicketProductDateTimeWrite(
      ticketProductId: $ticketProductId
      ticketProductDateId: $ticketProductDateId
      commonStatus: $commonStatus
      ticketDateTime: $ticketDateTime
    )
  }
`;
export const TICKET_PRODUCT_DATETIME_DETAIL_QUERY = gql`
  query StoreTicketProductDateTimeDetail($id: String!) {
    StoreTicketProductDateTimeDetail(id: $id) {
      id
      commonStatus
      isSoldOut
      ticketDateTime
      createdAt
      updatedAt
    }
  }
`;
export const TICKET_PRODUCT_DATETIME_EDIT_QUERY = gql`
  mutation StoreTicketProductDateTimeEdit(
    $id: String!
    $commonStatus: String!
    $isSoldOut: Boolean
    $ticketDateTime: String!
  ) {
    StoreTicketProductDateTimeEdit(
      id: $id
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      ticketDateTime: $ticketDateTime
    )
  }
`;
export const TICKET_PRODUCT_DATETIME_DELETE_QUERY = gql`
  mutation StoreTicketProductDateTimeDelete($id: String!) {
    StoreTicketProductDateTimeDelete(id: $id)
  }
`;

/* 상품 이미지 */
export const COMMONFILE_WRITE_QUERY = gql`
  mutation StoreCommonFileWrite(
    $moduleId: String!
    $targetType: String!
    $timestamp: String!
    $commonFile: CommonFileUpload!
  ) {
    StoreCommonFileWrite(
      moduleType: "ticketConfig"
      moduleId: $moduleId
      moduleTable: "ticketProduct"
      targetType: $targetType
      timestamp: $timestamp
      commonFile: $commonFile
    ) {
      id
      moduleType
      moduleId
      moduleTable
      targetType
      timestamp

      filepath
      filename
      mimetype
      encoding

      createdAt
      updatedAt
    }
  }
`;
