import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  TICKET_ORDER_DETAIL_QUERY,
  TICKET_ORDER_REFUND_QUERY,
} from "./Queries";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(TICKET_ORDER_DETAIL_QUERY, {
    variables: { id },
  });
  const [orderProductRefundMutation] = useMutation(TICKET_ORDER_REFUND_QUERY, {
    refetchQueries: () => [
      {
        query: TICKET_ORDER_DETAIL_QUERY,
        variables: {
          id,
        },
      },
    ],
  });
  const onRefund = async (id, ticketOrderProductId) => {
    console.log("onRefund");
    if (!window.confirm("티켓을 환불하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { StoreTicketOrderRefund },
      } = await orderProductRefundMutation({
        variables: { id, ticketOrderProductId },
      });

      if (StoreTicketOrderRefund) {
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    const {
      StoreTicketOrderDetail: {
        id,
        ticketOrderProducts,

        commonStatus,
        user,
        receiver,
        ticketProduct,

        isDiscount,
        discountPrice,
        price,
        count,

        totalCount,
        totalPrice,

        message,

        isRefund,
        refundReceiver,
        refundTotalCount,
        refundTotalPrice,

        createdAt,
        updatedAt,
      },
    } = data;

    const CoverImage =
      ticketProduct.coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${ticketProduct.coverImage}`;

    let commonStatus1 = 0;
    let commonStatus2 = 0;
    ticketOrderProducts.forEach((ticketOrderProduct) => {
      const toDay = new Date();
      const endDateObj = ticketOrderProduct.isReservation
        ? new Date(ticketOrderProduct.ticketProductDateTime?.ticketDateTime)
        : new Date(ticketProduct.applyEndDateTime);
      if (ticketOrderProduct.commonStatus === "S" && toDay <= endDateObj) {
        if (!ticketOrderProduct.isReservation) {
          commonStatus1 += ticketOrderProduct.count;
        } else {
          commonStatus2 += ticketOrderProduct.count;
        }
      }
    });
    let commonStatus3 = 0;
    ticketOrderProducts.forEach((ticketOrderProduct) => {
      const toDay = new Date();
      const endDateObj = ticketOrderProduct.isReservation
        ? new Date(ticketOrderProduct.ticketProductDateTime?.ticketDateTime)
        : new Date(ticketProduct.applyEndDateTime);
      if (ticketOrderProduct.commonStatus === "S" && toDay > endDateObj) {
        commonStatus3 += ticketOrderProduct.count;
      }
    });
    let commonStatus4 = 0;
    ticketOrderProducts.forEach((ticketOrderProduct) => {
      if (
        ticketOrderProduct.commonStatus === "C" &&
        ticketOrderProduct.isRefund === true
      ) {
        commonStatus4 += ticketOrderProduct.count;
      }
    });
    let commonStatus5 = 0;
    ticketOrderProducts.forEach((ticketOrderProduct) => {
      if (
        ticketOrderProduct.commonStatus === "C" &&
        ticketOrderProduct.isRefund === false
      ) {
        commonStatus5 += ticketOrderProduct.count;
      }
    });

    return (
      <>
        <Helmet>
          <title>구매내역 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림티켓</li>
            <li className="breadcrumb-item active">구매내역</li>
          </ol>
          <h1 className="page-header">구매내역</h1>
          <div className="row m-b-15">
            <div className="col-md-6 text-left">
              <Link
                className="btn btn-white"
                to={`/ticket/ticketOrders${location.search}`}
              >
                <i className="fa fa-list fa-fw"></i> 목록
              </Link>
            </div>
            <div className="col-md-6 text-right"></div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>구매정보</PanelHeader>
                <PanelBody className=" p-0">
                  <div className="invoice order-product">
                    <div className="invoice-company text-inverse f-w-600">
                      {ticketProduct.store.name}
                    </div>
                    <div className="order-product-list">
                      <div className="img">
                        <img src={CoverImage} alt={ticketProduct.name} />
                      </div>
                      <div className="cont">
                        <div>가 맹 점 : {ticketProduct.store.name}</div>
                        <strong>
                          <Link
                            to={`/ticket/ticketConfigs/${ticketProduct.ticketConfig.id}/products/${ticketProduct.id}`}
                          >
                            티 켓 명 : [
                            {ticketProduct.ticketCategory.parentCategory
                              ? `${ticketProduct.ticketCategory.parentCategory.name} > ${ticketProduct.ticketCategory.name}`
                              : `${ticketProduct.ticketCategory.name}`}
                            ] {ticketProduct.name}
                          </Link>
                        </strong>
                        <div>정 원 : {ticketProduct.limit}명</div>
                        <div>
                          장 소 :{" "}
                          {ticketProduct.placeType === "OffLine" &&
                            `(${ticketProduct.placeZipcode}) ${ticketProduct.placeAddress} ${ticketProduct.placeAddressDetail}`}
                          {ticketProduct.placeType === "OnLine" && "온라인"}
                        </div>
                        <div className="date">
                          유효기간 :{" "}
                          <ReactMoment format="YYYY-MM-DD">
                            {ticketProduct.applyStartDateTime}
                          </ReactMoment>{" "}
                          ~{" "}
                          <ReactMoment format="YYYY-MM-DD">
                            {ticketProduct.applyEndDateTime}
                          </ReactMoment>
                        </div>
                        <div>
                          가 격 :{" "}
                          {ticketProduct.isPay ? (
                            <>
                              <NumberFormat
                                displayType={"text"}
                                value={price}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />{" "}
                              -{" "}
                              <NumberFormat
                                displayType={"text"}
                                value={isDiscount ? discountPrice : 0}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />{" "}
                              ={" "}
                              <b>
                                <NumberFormat
                                  displayType={"text"}
                                  value={
                                    isDiscount ? price - discountPrice : price
                                  }
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </b>
                            </>
                          ) : (
                            "무료"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="invoice-header">
                      <div className="invoice-from p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">구매정보</strong>
                          <ul className="common-status">
                            <li>
                              <div className="left">구 매 자</div>
                              <div className="right">
                                {user.name}({user.userId})
                              </div>
                            </li>
                            <li>
                              <div className="left">구매일자</div>
                              <div className="right">
                                <ReactMoment format="YYYY-MM-DD HH:mm">
                                  {createdAt}
                                </ReactMoment>
                              </div>
                            </li>
                            <li>
                              <div className="left">수 신 자</div>
                              <div className="right">
                                {ticketOrderProducts.map((item, index) => {
                                  return item.isMember ? (
                                    <div key={index}>
                                      <>
                                        {item.user.name}({item.user.userId})
                                      </>
                                    </div>
                                  ) : (
                                    <div key={index}>
                                      {item.name}({item.phone})
                                    </div>
                                  );
                                })}
                              </div>
                            </li>
                            <li>
                              <div className="left">메 시 지</div>
                              <div className="right">
                                {message ? message : "없음"}
                              </div>
                            </li>
                            <li>
                              <div className="left">전체수량</div>
                              <div className="right">
                                <p>
                                  <b>{totalCount}</b>개
                                </p>
                              </div>
                            </li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-to p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">사용현황</strong>
                          <ul className="common-status">
                            <li>
                              <div className="left">예약대기</div>
                              <div className="right">
                                <span>{commonStatus1}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">미 사 용</div>
                              <div className="right">
                                <span>{commonStatus2}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">기간만료</div>
                              <div className="right">
                                <span>{commonStatus3}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">환불완료</div>
                              <div className="right">
                                <span>{commonStatus4}</span>개
                              </div>
                            </li>
                            <li>
                              <div className="left">사용완료</div>
                              <div className="right">
                                <span>{commonStatus5}</span>개
                              </div>
                            </li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-date p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="date text-inverse m-t-5">
                            가격정보
                          </strong>
                          <ul className="common-status">
                            <li>
                              <div className="left">정가</div>
                              <div className="right">
                                <NumberFormat
                                  displayType={"text"}
                                  value={price}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </div>
                            </li>
                            <li>
                              <div className="left">할인가</div>
                              <div className="right">
                                <NumberFormat
                                  displayType={"text"}
                                  value={isDiscount ? discountPrice : 0}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </div>
                            </li>
                            <li>
                              <div className="left">전체수량</div>
                              <div className="right">
                                <NumberFormat
                                  displayType={"text"}
                                  value={totalCount}
                                  thousandSeparator={true}
                                  suffix={"개"}
                                />
                              </div>
                            </li>
                            <li>
                              <div className="left">총 결제 금액</div>
                              <div className="right">
                                {ticketProduct.isPay ? (
                                  <NumberFormat
                                    displayType={"text"}
                                    value={totalPrice}
                                    thousandSeparator={true}
                                    suffix={"드림"}
                                  />
                                ) : (
                                  "무료"
                                )}
                              </div>
                            </li>
                          </ul>
                        </address>
                      </div>
                    </div>
                    {/* 가격정보 */}
                    <div className="invoice-price m-0">
                      <div className="invoice-price-left">
                        <div className="invoice-price-row">
                          <div className="sub-price">(</div>
                          <div className="sub-price">
                            <small>정가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={price}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-minus text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>할인가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={isDiscount ? discountPrice : 0}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">)</div>
                          <div className="sub-price">
                            <i className="fa fa-times text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>전체수량</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={totalCount}
                                thousandSeparator={true}
                                suffix={"개"}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-price-right">
                        <small>총 결제 금액</small>
                        <span className="f-w-600">
                          {ticketProduct.isPay ? (
                            <NumberFormat
                              displayType={"text"}
                              value={totalPrice}
                              thousandSeparator={true}
                              suffix={"드림"}
                            />
                          ) : (
                            "무료"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>
                  티켓내역({ticketOrderProducts.length}건)
                </PanelHeader>
                <PanelBody className="">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "*" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="text-center">상태</th>
                          <th className="text-center">티켓번호</th>
                          <th className="text-center">수신자</th>
                          <th className="text-center">수신일자</th>
                          <th className="text-center">
                            (정가-할인가)-수량=금액
                          </th>
                          <th className="text-center">기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketOrderProducts.map((item, index) => {
                          const toDay = new Date();
                          const endDateObj = item.isReservation
                            ? new Date(
                                item.ticketProductDateTime?.ticketDateTime
                              )
                            : new Date(ticketProduct.applyEndDateTime);

                          return (
                            <tr key={index}>
                              <td className="text-center">
                                {item.commonStatus === "S" && (
                                  <>
                                    {toDay <= endDateObj ? (
                                      item.isReservation === false ? (
                                        <span className="gray">예약대기</span>
                                      ) : (
                                        <span className="gray">미 사 용</span>
                                      )
                                    ) : (
                                      <span className="gray">기간만료</span>
                                    )}
                                  </>
                                )}
                                {item.commonStatus === "C" && (
                                  <>
                                    {item.isRefund === true && (
                                      <span className="danger">환불완료</span>
                                    )}
                                    {item.isRefund === false && (
                                      <span className="primary">사용완료</span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="text-center">
                                <ReactMoment format="mmssSSS">
                                  {item.createdAt}
                                </ReactMoment>
                              </td>
                              <td className="text-center">
                                {item.isMember ? (
                                  <>
                                    {item.user.name}({item.user.userId})
                                  </>
                                ) : (
                                  <>
                                    {item.name}({item.phone})
                                  </>
                                )}
                              </td>
                              <td className="text-center">
                                <ReactMoment format="YYYY-MM-DD HH:mm">
                                  {item.createdAt}
                                </ReactMoment>
                              </td>
                              <td className="text-right">
                                (
                                <NumberFormat
                                  displayType={"text"}
                                  value={price}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />{" "}
                                -{" "}
                                <NumberFormat
                                  displayType={"text"}
                                  value={isDiscount ? discountPrice : 0}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                                ) *{" "}
                                <NumberFormat
                                  displayType={"text"}
                                  value={item.count}
                                  thousandSeparator={true}
                                  suffix={"개"}
                                />{" "}
                                ={" "}
                                {ticketProduct.isPay ? (
                                  <NumberFormat
                                    displayType={"text"}
                                    value={
                                      (price -
                                        (isDiscount ? discountPrice : 0)) *
                                      item.count
                                    }
                                    thousandSeparator={true}
                                    suffix={"드림"}
                                  />
                                ) : (
                                  "무료"
                                )}
                              </td>
                              <td className="text-center">
                                <Link
                                  to={`/ticket/ticketOrderProducts/${item.id}`}
                                  className="btn btn-white btn-xs text-primary"
                                >
                                  상세정보
                                </Link>{" "}
                                {/* {item.commonStatus === "S" &&
                                toDay <= endDateObj ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-white btn-xs text-danger"
                                      onClick={() => onRefund(id, item.id)}
                                    >
                                      환불하기
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-white btn-xs text-danger disabled"
                                    >
                                      환불하기
                                    </button>
                                  </>
                                )} */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            {isRefund && (
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>환불정보</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="invoice">
                      <div className="invoice-price m-0">
                        <div className="invoice-price-left">
                          <div className="invoice-price-row">
                            <div className="sub-price">
                              <small>총 결제 금액</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  displayType={"text"}
                                  value={totalPrice}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </span>
                            </div>
                            <div className="sub-price">
                              <i className="fa fa-minus text-muted"></i>
                            </div>
                            <div className="sub-price">(</div>
                            <div className="sub-price">(</div>
                            <div className="sub-price">
                              <small>정가</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  displayType={"text"}
                                  value={price}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </span>
                            </div>
                            <div className="sub-price">
                              <i className="fa fa-minus text-muted"></i>
                            </div>
                            <div className="sub-price">
                              <small>할인가</small>
                              <span className="text-inverse">
                                <NumberFormat
                                  displayType={"text"}
                                  value={isDiscount ? discountPrice : 0}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />
                              </span>
                            </div>
                            <div className="sub-price">)</div>
                            <div className="sub-price">
                              <i className="fa fa-times text-muted"></i>
                            </div>
                            <div className="sub-price">
                              <small>환불수량</small>
                              <span className="text-inverse">
                                {refundTotalCount}개
                              </span>
                            </div>
                            <div className="sub-price">)</div>
                          </div>
                        </div>
                        <div className="invoice-price-right">
                          <small>총 잔여 금액</small>
                          <span className="f-w-600">
                            <NumberFormat
                              displayType={"text"}
                              value={totalPrice - refundTotalPrice}
                              thousandSeparator={true}
                              suffix={"드림"}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-4 text-left">
              <Link
                className="btn btn-white"
                to={`/ticket/ticketOrders${location.search}`}
              >
                <i className="fa fa-list fa-fw"></i> {""}
                목록
              </Link>
            </div>
            <div className="col-md-4 text-center"></div>
            <div className="col-md-4 text-right"></div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
