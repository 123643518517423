import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { STORE_LIST_QUERY } from "../Queries";
import useInput from "../../../../Hooks/useInput";
import ListPresenter from "./Presenter/List";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { datePlus9 } from "../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  const category = queryString.category ? queryString.category : "";
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (category ? "category=" + category + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading } = useQuery(STORE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      category,
      startSearchDate,
      endSearchDate,
      keyword,
      skip,
      first,
    },
  });
  const [storelListMutation] = useMutation(STORE_LIST_QUERY);
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/stores${baseUrl}page=1`);
  };

  const handleDateApplyEvent = (event, picker) => {
    // event.preventDefault();

    var startDate = picker.startDate.format("YYYY-MM-DD");
    var endDate = picker.endDate.format("YYYY-MM-DD");

    const uri =
      "?" +
      (category ? "category=" + category + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`/stores${uri}page=1`);
  };

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (category ? "category=" + category + "&" : "") +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (category ? "category=" + category + "&" : "") +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (category ? "category=" + category + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };

  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [
        [
          "분류",
          "이름",
          "아이디",
          "업체명",
          "대표자",
          "이메일",
          "휴대전화",
          "주소",
          "가입일시",
        ],
      ];
      try {
        const {
          data: { AdminStoreList },
        } = await storelListMutation({
          variables: { keyword },
        });
        AdminStoreList.stores.forEach((item) => {
          data.push([
            item.storeCategory.name,
            item.user.name,
            item.user.userId,
            item.name,
            item.storeCEO,
            item.email,
            item.phone,
            `${item.storeZipcode} ${item.storeRoadAddress} ${item.storeRoadAddress}`,
            `${datePlus9(item.createdAt)}`,
          ]);
        });
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    return (
      <>
        <button
          className="btn btn-white btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`가맹점.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  // useEffect(() => {
  //   refetch();
  // });

  return (
    <ListPresenter
      history={history}
      match={match}
      location={location}
      page={page}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      keyword={keyword}
      keywordInput={keywordInput}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
      handleSearch={handleSearch}
      DownloadButton={DownloadButton}
    />
  );
});
