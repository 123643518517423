import React from "react"
import { Helmet } from "react-helmet"
import { Link, withRouter } from "react-router-dom"
import { useQuery } from "react-apollo-hooks"
import { DASHBOARD_QUERY } from "./Queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faStoreAlt,
  faCommentsAlt,
  faMegaphone,
  faMoneyBill,
} from "@fortawesome/pro-regular-svg-icons"
import Moment from "react-moment"
// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import { graphqlUri } from "../../Config/Env"
import { Button } from "reactstrap"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])
// Import Swiper styles
export default withRouter(({ userData, history, match, location }) => {
  const { data, loading } = useQuery(DASHBOARD_QUERY, {
    fetchPolicy: "network-only",
  })
  const OrderListPopup = () => {
    const url = "/deliveryOrderListPrint"
    window.open(url, "", `width=400,height=400`)
  }
  const ProductListPopup = () => {
    const url = "/deliveryProductListPrint"
    window.open(url, "", `width=400,height=400`)
  }
  const renderEventSwiper = (events) => {
    return (
      <div className="col-md-6">
        <div className="m-b-10 m-t-10 f-s-10">
          <a
            href="/eventConfigs/Wallet"
            className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
            data-toggle="modal"
          >
            더보기
          </a>
          <b className="text-inverse f-s-18">이벤트(사용자)</b>
        </div>
        <div className="m-b-20">
          <Swiper
            style={{ paddingBottom: 30 }}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {events.map((event, index) => {
              const eventCoverImg =
                event.coverImg === ""
                  ? "/assets/img/user/user-default.jpg"
                  : `${graphqlUri}${event.coverImg}`
              const date = new Date()
              const startDate = new Date(event.startDate)
              const endDate = new Date(event.endDate)

              return (
                <SwiperSlide key={index}>
                  <div className="main-banner">
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url("${eventCoverImg}")`,
                        height: "400px",
                      }}
                    ></div>
                    <div className="cont">
                      <a
                        href={`/eventConfigs/${event.commonApp}/${event.eventConfig.id}/events/${event.id}`}
                      >
                        <div className="category">
                          <span className="label label-primary">
                            {event.commonStatus === "S" && "비공개"}
                            {event.commonStatus === "C" &&
                            date >= startDate &&
                            date <= endDate
                              ? "진행"
                              : "종료"}
                          </span>
                        </div>
                        <strong>{event.title}</strong>
                        <p>
                          <Moment format="YYYY-MM-DD">{event.startDate}</Moment>
                          ~<Moment format="YYYY-MM-DD">{event.endDate}</Moment>
                        </p>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    )
  }
  const renderDonationSwiper = (donations) => {
    return (
      <div className="col-md-6">
        <div className="m-b-10 m-t-10 f-s-10">
          <a
            href="/donations"
            className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
            data-toggle="modal"
          >
            더보기
          </a>
          <b className="text-inverse f-s-18">기부프로젝트</b>
        </div>
        <div className="m-b-20">
          <Swiper
            style={{ paddingBottom: 30 }}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {donations.map((donation, index) => {
              const donationCoverImg =
                donation.coverImg === ""
                  ? "/assets/img/user/user-default.jpg"
                  : `${graphqlUri}${donation.coverImg}`
              const date = new Date()
              const startDate = new Date(donation.startDate)
              const endDate = new Date(donation.endDate)

              return (
                <SwiperSlide key={index}>
                  <div className="main-banner">
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url("${donationCoverImg}")`,
                        height: "400px",
                      }}
                    ></div>
                    <div className="cont">
                      <a href={`/donations/${donation.id}`}>
                        <div className="category">
                          <span className="label label-primary">
                            {donation.commonStatus === "S" && "비공개"}
                            {donation.commonStatus === "C" &&
                            date >= startDate &&
                            date <= endDate
                              ? "진행"
                              : "종료"}
                          </span>
                        </div>
                        <strong>{donation.title}</strong>
                        <p>
                          <Moment format="YYYY-MM-DD">
                            {donation.startDate}
                          </Moment>
                          ~
                          <Moment format="YYYY-MM-DD">
                            {donation.endDate}
                          </Moment>
                        </p>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    )
  }
  const renderStoreCount = (storeCount) => {
    return (
      <div className="col-xl-6 col-sm-6">
        <div className="widget widget-stats">
          <a href="/stores">
            <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
              <FontAwesomeIcon icon={faStoreAlt} />
            </div>
            <div className="stats-content">
              <div className="stats-number">{storeCount}개</div>
              <div className="stats-desc f-s-15">가맹점</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
  const renderUserCount = (userCount) => {
    return (
      <div className="col-xl-6 col-sm-6">
        <div className="widget widget-stats">
          <a href="/users/User">
            <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="stats-content">
              <div className="stats-number">{userCount}명</div>
              <div className="stats-desc f-s-15">회원수</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
  // const renderLedgerCount = ledgerCount => {
  //   return (
  //     <div className="col-xl-4 col-sm-4">
  //       <div className="widget widget-stats">
  //         <a href="/ledgers">
  //           <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
  //             <FontAwesomeIcon icon={faReceipt} />
  //           </div>
  //           <div className="stats-content">
  //             <div className="stats-number">{ledgerCount / 2}건</div>
  //             <div className="stats-desc f-s-15">거래건</div>
  //           </div>
  //         </a>
  //       </div>
  //     </div>
  //   );
  // };
  const renderNoticeCount = (noticeCount) => {
    return (
      <div className="col-xl-4 col-sm-4">
        <div className="widget widget-stats">
          <a href="/noticeConfigs/Wallet">
            <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
              <FontAwesomeIcon icon={faMegaphone} />
            </div>
            <div className="stats-content">
              <div className="stats-number">{noticeCount}건</div>
              <div className="stats-desc f-s-15">공지사항(사용자)</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
  const renderInquiryCount = (inquiryCount) => {
    return (
      <div className="col-xl-4 col-sm-4">
        <div className="widget widget-stats">
          <a href="/inquiryConfigs/Wallet">
            <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
              <FontAwesomeIcon icon={faCommentsAlt} />
            </div>
            <div className="stats-content">
              <div className="stats-number">{inquiryCount}건</div>
              <div className="stats-desc f-s-15">1:1문의(사용자)</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
  const renderChargeRequestCount = (chargeRequestCount) => {
    return (
      <div className="col-xl-4 col-sm-4">
        <div className="widget widget-stats">
          <a href="/chargeRequests">
            <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
              <FontAwesomeIcon icon={faMoneyBill} />
            </div>
            <div className="stats-content">
              <div className="stats-number">{chargeRequestCount}건</div>
              <div className="stats-desc f-s-15">충전내역</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
  const renderNotices = ({ notices }) => {
    return (
      <div className="col-md-6">
        <div className="m-b-10 m-t-10 f-s-10">
          <a
            href="/noticeConfigs/Wallet"
            className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
            data-toggle="modal"
          >
            더보기
          </a>
          <b className="text-inverse f-s-18">공지사항(사용자)</b>
        </div>
        {notices.length > 0 ? (
          <div className="widget-list widget-list-rounded m-b-30">
            {notices.map((notice, index) => (
              <a
                href={`/noticeConfigs/Wallet/${notice.noticeConfig.id}/notices/${notice.id}${location.search}`}
                key={notice.id}
                className="widget-list-item"
              >
                <div className="widget-list-content">
                  <h4 className="widget-list-title">{notice.title}</h4>
                  <p className="widget-list-desc">
                    <Moment format="YYYY-MM-DD">{notice.createdAt}</Moment>
                  </p>
                </div>
                <div className="widget-list-action text-right">
                  <i className="fa fa-angle-right fa-lg text-muted"></i>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div className="vertical-box p-15 text-center ">
            등록된 데이터가 없습니다.
          </div>
        )}
      </div>
    )
  }
  const renderInquiries = ({ inquiries }) => {
    return (
      <div className="col-md-6">
        <div className="m-b-10 m-t-10 f-s-10">
          <a
            href="/inquiryConfigs/Wallet"
            className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
            data-toggle="modal"
          >
            더보기
          </a>
          <b className="text-inverse f-s-18">1:1문의(사용자)</b>
        </div>
        {inquiries.length > 0 ? (
          <div className="widget-list widget-list-rounded m-b-30">
            {inquiries.map((inquiry, index) => (
              <a
                href={`/inquiryConfigs/Wallet/${inquiry.inquiryConfig.id}/inquiries/${inquiry.id}${location.search}`}
                key={inquiry.id}
                className="widget-list-item"
              >
                <div className="widget-list-content">
                  <h4 className="widget-list-title">
                    <span className="badge badge-primary">
                      {inquiry.commonStatus === "S" ? "답변대기" : "답변완료"}
                    </span>{" "}
                    {inquiry.question}
                  </h4>
                  <p className="widget-list-desc">
                    <Moment format="YYYY-MM-DD">{inquiry.createdAt}</Moment>
                  </p>
                </div>
                <div className="widget-list-action text-right">
                  <i className="fa fa-angle-right fa-lg text-muted"></i>
                </div>
              </a>
            ))}
          </div>
        ) : (
          <div className="vertical-box p-15 text-center ">
            등록된 데이터가 없습니다.
          </div>
        )}
      </div>
    )
  }

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    )
  } else {
    const {
      AdminDashboard: {
        events,
        donations,
        storeCount,
        userCount,
        noticeCount,
        inquiryCount,
        chargeRequestCount,
        notices,
        inquiries,
      },
    } = data
    return (
      <>
        <Helmet>
          <title>메인 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">매인</li>
          </ol>
          <h1 className="page-header">Dashboard</h1>
          <div className="row">
            <div className="col-xs-12">
              <div className="note note-light m-t-30 m-b-15">
                <div style={{ marginTop: -45, marginLeft: 30 }}>
                  <img
                    style={{ height: 130 }}
                    src="../assets/img/admin/dashboard-img.png"
                    alt=""
                  />
                </div>
                <div className="note-content  m-l-15">
                  <h4 className="f-s-18 m-t-15">
                    <b>
                      {userData &&
                        userData.AdminInfo &&
                        userData.AdminInfo.name}
                    </b>
                    님
                  </h4>
                  <p className="f-s-14">오늘하루도 좋은 하루 되시기바랍니다.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {renderEventSwiper(events)}
            {renderDonationSwiper(donations)}
          </div>
          <div className="row">
            {renderStoreCount(storeCount)}
            {renderUserCount(userCount)}
            {/* {renderLedgerCount(ledgerCount)} */}
          </div>
          <div className="row">
            {renderNoticeCount(noticeCount)}
            {renderInquiryCount(inquiryCount)}
            {renderChargeRequestCount(chargeRequestCount)}
          </div>
          {/* <div className="row">
        <div className="col-xs-12">
          <div className="widget-chart with-sidebar">
            <div className="widget-chart-content bg-white">
              <div className="widget-chart-full-width">
                <NVD3Chart
                  type="stackedAreaChart"
                  datum={this.areaChartData}
                  height={260}
                  options={this.areaChartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
          <div className="row">
            {renderNotices({ notices })}
            {renderInquiries({ inquiries })}
          </div>
        </div>
      </>
    )
  }
})
