import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FEE_WRITE_QUERY } from "./Queries";
import { useQuery, useMutation } from "react-apollo-hooks";

import { Helmet } from "react-helmet";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";

export default withRouter(({ history, match, location }) => {
  const [feeWriteMutation] = useMutation(FEE_WRITE_QUERY);
  const { register, handleSubmit, errors, watch } = useForm();
  const [feeCoverImg, setFeeCoverImg] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const [feeDetailImg, setFeeDetailImg] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const handleFeeCoverImg = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setFeeCoverImg(reader.result);
    };
  };
  const handleFeeDetailImg = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setFeeDetailImg(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImg").click();
  };
  const onClick_detail = () => {
    document.getElementById("detailImg").click();
  };

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminFeeWrite },
      } = await feeWriteMutation({
        variables: {
          commonStatus: data.commonStatus,

          title: data.title,
          contents: data.contents,
          planner: data.planner,
          plannerUseSNS: data.plannerUseSNS,
          plannerSNS: data.plannerUseSNS === "S" ? "-" : data.plannerSNS,
          isDefault: data.isDefault ? "true" : "false",

          coverImg: data.coverImg[0],
          detailImg: data.detailImg[0],
        },
      });
      if (AdminFeeWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/fees${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  return (
    <>
      <Helmet>
        <title>기부 관리 | 한밭페이</title>
      </Helmet>

      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">회비</li>
          </ol>
          <h1 className="page-header">회비</h1>

          <Alert color="primary" className="m-b-15">
            <div>
              <strong className="f-s-15">※ 체납 사용 여부 안내</strong>
            </div>
            <div>
              * 사용 : 미납 시 다음 납부일에 회비 + 미납금액이 추가되어
              납부됩니다.
            </div>
            <div>* 미사용 : 미납시 다음 납부일에 회비만 납부됩니다.</div>
          </Alert>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link to={`/fees${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">상태</label>
                      <div className="col-md-9">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            className="form-control m-b-5"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            className="form-control m-b-5"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div>{errors.commonStatus.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        프로젝트 관리자
                      </label>
                      <div className="col-md-9">
                        <input
                          name="planner"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="프로젝트 관리자 입력"
                          ref={register({
                            required: "프로젝트 관리자를 입력해주세요.",
                          })}
                        />
                        {errors.planner && (
                          <span>{errors.planner.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        SNS 사용 여부
                      </label>
                      <div className="col-md-9">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="snsUseC"
                            name="plannerUseSNS"
                            className="form-control m-b-5"
                            value="C"
                            ref={register({
                              required: "SNS 사용 여부를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`snsUseC`}>사용</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="snsUseS"
                            name="plannerUseSNS"
                            className="form-control m-b-5"
                            value="S"
                            ref={register({
                              required: "SNS 사용 여부를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`snsUseS`}>미사용</label>
                        </div>
                        {errors.plannerUseSNS && (
                          <div>
                            <span>{errors.plannerUseSNS.message}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {watch("plannerUseSNS") === "C" && (
                      <div className="form-group row">
                        <label className="col-form-label col-md-3">
                          프로젝트 SNS
                        </label>
                        <div className="col-md-9">
                          <input
                            name="plannerSNS"
                            type="text"
                            className="form-control m-b-5"
                            placeholder="프로젝트 SNS 입력"
                            ref={register({
                              validate: (value) => {
                                return watch("plannerUseSNS") === "C" &&
                                  value === ""
                                  ? "프로젝트 SNS를 입력해주세요."
                                  : undefined;
                              },
                            })}
                          />
                          {errors.plannerSNS && (
                            <div>
                              <span>{errors.plannerSNS.message}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        체납 사용 여부
                      </label>
                      <div className="col-md-9">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="isDefaultTrue"
                            name="isDefault"
                            className="form-control m-b-5"
                            value="true"
                            ref={register({
                              required: "체납 사용 여부를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`isDefaultTrue`}>사용</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="isDefaultFalse"
                            name="isDefault"
                            className="form-control m-b-5"
                            value="false"
                            ref={register({
                              required: "체납 사용 여부를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`isDefaultFalse`}>미사용</label>
                        </div>
                        {errors.isDefault && (
                          <div>
                            <span>{errors.isDefault.message}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">제목</label>
                      <div className="col-md-9">
                        <input
                          name="title"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && (
                          <div>
                            <span>{errors.title.message}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12">
                        <textarea
                          name="contents"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="내용입력"
                          ref={register({
                            required: "내용을 입력해주세요.",
                          })}
                          rows="10"
                        ></textarea>
                        {errors.contents && (
                          <span>{errors.contents.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>커버 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="bg-gray-transparent-1 rounded">
                        <div
                          className="coverImg"
                          style={{
                            backgroundImage: `url(${feeCoverImg})`,
                          }}
                        >
                          <img
                            src={feeCoverImg}
                            alt="이미지 업로드"
                            className="sr-only"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImg"
                          name="coverImg"
                          className="hide"
                          onChange={handleFeeCoverImg}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          커버 이미지 업로드
                        </button>
                      </div>
                      {errors.coverImg && (
                        <span>{errors.coverImg.message}</span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b> 이미지 사이즈 :</b> 넓이 1200px X 높이 1024px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>상세 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="bg-gray-transparent-1 rounded">
                        <div
                          className="coverImg"
                          style={{
                            backgroundImage: `url(${feeDetailImg})`,
                          }}
                        >
                          <img
                            src={feeCoverImg}
                            alt="이미지 업로드"
                            className="sr-only"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="detailImg"
                          name="detailImg"
                          className="hide"
                          onChange={handleFeeDetailImg}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_detail}
                        >
                          상세 이미지 업로드
                        </button>
                      </div>
                      {errors.detailImg && (
                        <span>{errors.detailImg.message}</span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b> 이미지 사이즈 : </b> 넓이 1200px X 높이 1200px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link to={`/fees${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
