import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default ({
  history,
  match,
  location,

  isVisible,
  toggleModal,

  keyword,
  setKeyword,
  handleSearch,

  users,
  setUsers,

  data,
  loading,

  isModal,
}) => {
  const modalRef = React.createRef();
  const [keywordInput, setKeywordInput] = useState(keyword);

  const [checkItems, setCheckItems] = useState([]);

  const handleSelectCheck = (checked, item) => {
    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      let itemArray = [];
      data.AdminUserList.users.forEach((item) => {
        itemArray.push(item);
      });
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };
  const handleCMS5Select = () => {
    let itemArray = [];
    data.AdminUserList.users.forEach((item) => {
      if (item.isCms && item.cmsDay === 5) {
        itemArray.push(item);
      }
    });
    setCheckItems(itemArray);
  };
  const handleCMS25Select = () => {
    let itemArray = [];
    data.AdminUserList.users.forEach((item) => {
      if (item.isCms && item.cmsDay === 25) {
        itemArray.push(item);
      }
    });
    setCheckItems(itemArray);
  };

  const handleSelectAdd = () => {
    if (checkItems.length === 0) {
      toast.error("추가할 수신자를 선택해주세요.");
      return;
    }
    let itemArray = [];
    checkItems.forEach((item) => {
      if (!users.includes(item)) {
        // if (users.indexOf(item) === -1) {
        itemArray.push(item);
      }
    });
    setUsers([...users, ...itemArray]);
    setKeyword("");
    setKeywordInput("");
    setCheckItems([]);
    toggleModal();
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={isVisible}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          toggleModal();
        }}
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
          }}
        >
          사용자 목록 ({data.AdminUserList.count}건)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox ">
            <div className="vertical-box-inner-cell overflow-visible height-md">
              <PerfectScrollbar
                className="height-full p-15"
                options={{ suppressScrollX: true }}
              >
                <InputGroup className="p-b-15">
                  <Input
                    type="text"
                    className="input-white"
                    placeholder="검색어(이름, 아이디) 입력"
                    value={keywordInput}
                    onChange={(e) => {
                      setKeywordInput(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setKeyword(keywordInput);
                        handleSearch();
                      }
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="white"
                      onClick={() => {
                        setKeyword(keywordInput);
                        handleSearch();
                      }}
                    >
                      <i className="fa fa-search fa-fw"></i>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>이름(아이디)</th>
                        <th>정기충전(CMS)</th>
                        <th style={{ textAlign: "center" }}>
                          <input
                            type="checkbox"
                            onChange={(e) => handleAllCheck(e.target.checked)}
                            checked={
                              checkItems.length ===
                              data.AdminUserList.users.length
                            }
                          />
                        </th>
                      </tr>
                    </thead>
                    {!loading && data.AdminUserList.count > 0 ? (
                      <tbody>
                        {data.AdminUserList.users.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                {item.name}({item.userId})
                              </td>

                              <td>
                                {item.isCms
                                  ? `매월 ${item.cmsDay}일 ${String(
                                      item.cmsValue
                                    ).replace(
                                      /(.)(?=(\d{3})+$)/g,
                                      "$1,"
                                    )}원 정기결제`
                                  : "-"}
                              </td>
                              <td align={"center"}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleSelectCheck(e.target.checked, item)
                                  }
                                  checked={
                                    checkItems.includes(item) ? true : false
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={2}>
                            <p className="m-40 text-center">
                              등록된 사용자가 없습니다.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className=" btn btn-white text-primary"
            onClick={() => {
              handleCMS5Select();
            }}
          >
            CMS 5일 체크
          </button>
          <button
            type="button"
            className=" btn btn-white text-primary"
            onClick={() => {
              handleCMS25Select();
            }}
          >
            CMS 25일 체크
          </button>
          <button
            type="button"
            className=" btn btn-white text-primary"
            onClick={() => {
              handleSelectAdd();
            }}
          >
            추가
          </button>
          {/* <button
            type="button"
            className=" btn btn-white text-primary"
            onClick={() => {
              handleAllAdd();
            }}
          >
            전체추가
          </button> */}
        </ModalFooter>
      </Modal>
    </>
  );
};
