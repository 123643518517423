import { gql } from "apollo-boost";

/* 회비 시작 */
export const FEE_LIST_QUERY = gql`
  query AdminFeeList($keyword: String, $skip: Int!, $first: Int!) {
    AdminFeeList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      fees {
        id
        createdAt
        updatedAt

        commonStatus
        title
        contents
        planner
        plannerUseSNS
        plannerSNS

        isDefault
        feeDefault
        feeAmount

        coverImg
        detailImg

        wallet {
          id
        }
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
      }
      count
    }
  }
`;
export const FEE_WRITE_QUERY = gql`
  mutation AdminFeeWrite(
    $commonStatus: String
    $title: String
    $contents: String
    $planner: String
    $plannerUseSNS: String
    $plannerSNS: String
    $isDefault: String
    $coverImg: CoverUpload
    $detailImg: DetailUpload
  ) {
    AdminFeeWrite(
      commonStatus: $commonStatus

      title: $title
      contents: $contents
      planner: $planner
      plannerUseSNS: $plannerUseSNS
      plannerSNS: $plannerSNS
      isDefault: $isDefault

      coverImg: $coverImg
      detailImg: $detailImg
    )
  }
`;
export const FEE_DETAIL_QUERY = gql`
  query AdminFeeDetail($id: String!) {
    AdminFeeDetail(id: $id) {
      id
      commonStatus
      title
      contents
      planner
      plannerUseSNS
      plannerSNS
      isDefault

      coverImg
      detailImg

      createdAt
      updatedAt
      wallet {
        id
      }
      trustlines {
        id
        commonStatus
        currency
        amount
        createdAt
        updatedAt
      }
    }
  }
`;
export const FEE_EDIT_QUERY = gql`
  mutation AdminFeeEdit(
    $id: String
    $commonStatus: String
    $title: String
    $contents: String
    $planner: String
    $plannerUseSNS: String
    $plannerSNS: String
    $isDefault: String
    $coverImg: CoverUpload
    $detailImg: DetailUpload
  ) {
    AdminFeeEdit(
      id: $id
      commonStatus: $commonStatus

      title: $title
      contents: $contents
      planner: $planner
      plannerUseSNS: $plannerUseSNS
      plannerSNS: $plannerSNS
      isDefault: $isDefault

      coverImg: $coverImg
      detailImg: $detailImg
    )
  }
`;
export const FEE_DELETE_QUERY = gql`
  mutation AdminFeeDelete($id: String!) {
    AdminFeeDelete(id: $id)
  }
`;

/* 회비 > 회원 시작 */
export const FEEHASUSER_LIST_QUERY = gql`
  query AdminFeeHasUserList($feeId: String!, $skip: Int!, $first: Int!) {
    AdminFeeHasUserList(feeId: $feeId, skip: $skip, first: $first) {
      feeHasUsers {
        id
        createdAt
        updatedAt

        commonStatus
        startDate
        endDate
        feeDay
        feeValue
        feeDefault
        feeAmount

        fee {
          id
          title
        }
        user {
          id
          commonStatus
          confirmed
          avatar
          userId
          name
          email
          phone
          blocked
          createdAt
          updatedAt
        }
      }
      count
    }
  }
`;

export const FEEHASUSER_EXCLUSIVEUSER_LIST_QUERY = gql`
  query AdminFeeHasUserExclusiveUserList(
    $fee_id: String!
    $keyword: String
    $orderBy: String
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    AdminFeeHasUserExclusiveUserList(
      fee_id: $fee_id
      keyword: $keyword
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      users {
        id
        createdAt
        updatedAt

        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          name
        }

        isCms
        cmsValue
        cmsDay
      }
      count
    }
  }
`;
export const FEEHASUSER_WRITE_QUERY = gql`
  mutation AdminFeeHasUserWrite(
    $user_ids: [String!]
    $fee_id: String!
    $feeDay: String!
    $feeValue: String!
  ) {
    AdminFeeHasUserWrite(
      user_ids: $user_ids
      fee_id: $fee_id
      feeDay: $feeDay
      feeValue: $feeValue
    )
  }
`;

export const FEEHASUSER_DELETE_QUERY = gql`
  mutation AdminFeeHasUserDelete($id: String!) {
    AdminFeeHasUserDelete(id: $id)
  }
`;
/* 회비 > 회원 끝 */

/* 회비 > 회원 상세내역 시작 */
export const FEEHASUSER_DETAIL_LIST_QUERY = gql`
  query AdminFeeHasUserDetailList(
    $feeHasUser_id: String!
    $skip: Int!
    $take: Int!
  ) {
    AdminFeeHasUserDetailList(
      feeHasUser_id: $feeHasUser_id
      skip: $skip
      take: $take
    ) {
      feeHasUserDetails {
        id
        createdAt
        updatedAt

        description
        feeValue
        feeDefault
        feeAmount

        # fee {
        #   id
        #   createdAt
        #   updatedAt
        # }
        # feeHasUser {
        #   id
        #   createdAt
        #   updatedAt
        # }
        # user {
        #   id
        #   createdAt
        #   updatedAt
        # }
      }
      count
    }
  }
`;
/* 회비 > 회원 상세내역 끝 */

/* 회비 > 출금 시작 */
export const FEEMONTHFILE_LIST_QUERY = gql`
  query AdminFeeMonthFileList($feeId: String!, $skip: Int, $first: Int) {
    AdminFeeMonthFileList(feeId: $feeId, skip: $skip, first: $first) {
      feeMonthFiles {
        id
        commonStatus
        name
        year
        month

        uploadPath
        downloadPath
        file

        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const FEEMONTHFILE_WRITE_QUERY = gql`
  mutation AdminFeeMonthFileWrite(
    $feeId: String!
    $name: String!
    $year: String!
    $month: String!
    $file: FileUpload!
  ) {
    AdminFeeMonthFileWrite(
      feeId: $feeId
      name: $name
      year: $year
      month: $month
      file: $file
    )
  }
`;
export const FEEMONTHFILE_DETAIL_QUERY = gql`
  query AdminFeeMonthFileDetail($id: String!) {
    AdminFeeMonthFileDetail(id: $id) {
      id
      feeMonthFileDetails {
        id
        commonStatus

        column1
        column2
        column3
        column4
        column5
        column6
        column7
        column8
        column9
        column10
        column11
        column12
        column13
        column14
        column15

        description

        createdAt
        updatedAt

        feeHasUser {
          id
          commonStatus
          startDate
          endDate

          feeDay
          feeValue
          feeDefault
          feeAmount

          fee {
            id
            title
          }
          user {
            id
            commonStatus
            confirmed
            avatar
            userId
            name
            email
            phone
            blocked

            isCms
            cmsValue
            cmsDay

            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      commonStatus
      name
      year
      month

      uploadPath
      downloadPath
      file

      nameColumn
      phoneColumn

      createdAt
      updatedAt
    }
  }
`;
export const FEEMONTHFILE_PREVIEW_QUERY = gql`
  query AdminFeeMonthFilePreview(
    $id: String!
    $column1: String
    $column2: String
    $column3: String
    $column4: String
    $column5: String
    $column6: String
    $column7: String
    $column8: String
    $column9: String
    $column10: String
    $column11: String
    $column12: String
    $column13: String
    $column14: String
    $column15: String
  ) {
    AdminFeeMonthFilePreview(
      id: $id
      column1: $column1
      column2: $column2
      column3: $column3
      column4: $column4
      column5: $column5
      column6: $column6
      column7: $column7
      column8: $column8
      column9: $column9
      column10: $column10
      column11: $column11
      column12: $column12
      column13: $column13
      column14: $column14
      column15: $column15
    ) {
      feeMonthFileDetails {
        id
        commonStatus

        column1
        column2
        column3
        column4
        column5
        column6
        column7
        column8
        column9
        column10
        column11
        column12
        column13
        column14
        column15

        description

        createdAt
        updatedAt

        isUser
        isChange

        feeHasUser {
          id
          commonStatus
          startDate
          endDate

          feeDay
          feeValue
          feeDefault
          feeAmount

          fee {
            id
            title
          }
          user {
            id
            commonStatus
            confirmed
            avatar
            userId
            name
            email
            phone
            blocked

            isCms
            cmsValue
            cmsDay

            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
      count
    }
  }
`;
export const FEEMONTHFILE_PAYMENT_QUERY = gql`
  mutation AdminFeeMonthFilePayment(
    $id: String!
    $column1: String
    $column2: String
    $column3: String
    $column4: String
    $column5: String
    $column6: String
    $column7: String
    $column8: String
    $column9: String
    $column10: String
    $column11: String
    $column12: String
    $column13: String
    $column14: String
    $column15: String
  ) {
    AdminFeeMonthFilePayment(
      id: $id
      column1: $column1
      column2: $column2
      column3: $column3
      column4: $column4
      column5: $column5
      column6: $column6
      column7: $column7
      column8: $column8
      column9: $column9
      column10: $column10
      column11: $column11
      column12: $column12
      column13: $column13
      column14: $column14
      column15: $column15
    )
  }
`;
/* 회비 > 출금 끝 */

/* 회비 끝 */
export const FEE_LEDGER_LIST_QUERY = gql`
  query AdminLedgerList($wallet: String) {
    AdminLedgerList(wallet: $wallet, orderBy: "id_DESC") {
      ledgers {
        id
        commonStatus
        transactionType
        accountWallet {
          id
          userId
        }
        destinationWallet {
          id
          userId
        }
        title
        currency
        value
        amount
        createdAt
        updatedAt
        wallet {
          id
          name
        }
      }
      count
    }
  }
`;
export const LEDGER_FEESYNC_QUERY = gql`
  mutation AdminETCLedgerFeeAmount($fee: String!) {
    AdminETCLedgerFeeAmount(fee: $fee)
  }
`;

export const FEE_CANCEL_QUERY = gql`
  mutation AdminFeeCancel($feeId: String!, $currency: String!, $id: String!) {
    AdminFeeCancel(feeId: $feeId, currency: $currency, id: $id)
  }
`;

/* 기부 프로젝트 이미지 */
export const USER_LIST_QUERY = gql`
  query AdminUserList(
    $role: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminUserList(
      role: $role
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
          updatedAt
        }
        wallet {
          id
        }
        store {
          id
          name
        }
        addCurrency
        createdAt
        updatedAt

        isCms
        cmsValue
        cmsDay
      }
      count
    }
  }
`;

export const FEE_TRUSTLINE_REMITTANCE_QUERY = gql`
  mutation AdminFeeTrustlineRemittance(
    $accountId: String!
    $destinationId: String!
    $currency: String!
    $value: String!
  ) {
    AdminFeeTrustlineRemittance(
      accountId: $accountId
      destinationId: $destinationId
      currency: $currency
      value: $value
    )
  }
`;

export const TRUSTLINE_DETAIL_QUERY = gql`
  query AdminTrustlineDetail($id: String!) {
    AdminTrustlineDetail(id: $id) {
      id
      commonStatus
      currency
      amount
      createdAt
      updatedAt
    }
  }
`;

export const LEDGER_LIST_QUERY = gql`
  query AdminLedgerList($trustline: String, $year: Int, $month: Int) {
    AdminLedgerList(
      trustline: $trustline
      orderBy: "createdAt_DESC"
      year: $year
      month: $month
    ) {
      ledgers {
        id
        transactionType
        accountWallet {
          id
          userId
        }
        destinationWallet {
          id
          userId
        }
        title
        currency
        value
        amount
        createdAt
        updatedAt
        wallet {
          id
          name
        }
      }
      count
    }
  }
`;
