import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Dashboard from "../Routes/Issuer/Dashboard";

import UserList from "../Routes/Issuer/User/List/index";
import UserDetail from "../Routes/Issuer/User/Detail";
import UserTrustline from "../Routes/Issuer/User/Trustline/List/index";

import LedgerList from "../Routes/Issuer/Ledger/List/index";

import Statistics from "../Routes/Issuer/Statistics/Index";

const IssuerRoutes = ({
  userData,
  history,
  location,
  match,
  isModal,
  previousLocation,
}) => (
  <Switch userData={userData} location={isModal ? previousLocation : location}>
    <Route exact path="/dashboard">
      <Dashboard userData={userData} />
    </Route>

    {/* 사용자 시작 */}
    <Route exact path={["/users/:role"]}>
      <UserList userData={userData} />
    </Route>
    <Route exact path="/users/:role/:id">
      <UserDetail userData={userData} />
    </Route>
    <Route exact path="/users/:role/:id/trustlines/:trustline">
      <UserDetail />
      <UserTrustline isModal={isModal} />
    </Route>
    {/* 사용자 끝 */}

    {/* 거래내역 */}
    <Route exact path={"/ledgers"} component={LedgerList} />
    {/* 거래내역 */}

    {/* 통계 */}
    <Route exact path="/statistics">
      <Statistics userData={userData} />
    </Route>
    {/* 통계 */}

    <Redirect from="*" to="/dashboard" />
  </Switch>
);

export default IssuerRoutes;
