import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  GIFT_ORDERPRODUCT_SEND_QUERY,
  GIFT_ORDERPRODUCT_DETAIL_QUERY,
} from "../../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import SearchUser from "./SearchUser";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const id = match.params.id;

  const { data, loading } = useQuery(GIFT_ORDERPRODUCT_DETAIL_QUERY, {
    variables: { id },
  });

  if (!loading) {
    const {
      AdminGiftOrderProductDetail: {
        // id,
        commonStatus,
        // user,
        // giftOrder,
        // giftProduct,

        // isDiscount,
        // discountPrice,
        // price,
        // giftProductOptionDetail1,
        // giftProductOptionDetailPrice1,
        // giftProductOptionDetail2,
        // giftProductOptionDetailPrice2,
        // giftProductOptionDetail3,
        // giftProductOptionDetailPrice3,
        // giftProductOptionDetail4,
        // giftProductOptionDetailPrice4,
        // giftProductOptionDetail5,
        // giftProductOptionDetailPrice5,

        // totalPrice,

        // startDate,
        endDate,
        extensionCount,

        // isRefund,
        // meRefund,

        // createdAt,
        // updatedAt,
      },
    } = data;

    const toDay = new Date();
    const endDateObj = new Date(endDate);

    // console.log(commonStatus !== "S", extensionCount > 2, toDay > endDateObj);
    if (commonStatus !== "S" || extensionCount > 2 || toDay > endDateObj) {
      toast.success(`사용하였거나 기간이 만료된 선물입니다.`);
      return;
    }
  }

  const [userId, setUserId] = useState(null);

  const [orderProductSendMutation] = useMutation(GIFT_ORDERPRODUCT_SEND_QUERY, {
    refetchQueries: () => [
      {
        query: GIFT_ORDERPRODUCT_DETAIL_QUERY,
        variables: {
          id,
        },
      },
    ],
  });

  const { handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      if (userId === "" || userId === null) {
        toast.error(`수신자 이름을 검색해주세요.`);
        return;
      }

      const {
        data: { AdminGiftOrderProductSend },
      } = await orderProductSendMutation({
        variables: {
          id,
          user: userId,
        },
      });

      if (AdminGiftOrderProductSend) {
        toast.success(`선물을 보냈습니다.`);
        history.replace(`/giftOrderProducts/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/giftOrderProducts/${match.params.id}?${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/giftOrderProducts/${match.params.id}?${location.search}`
              );
            }}
          >
            보내기
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <label className="col-form-label col-md-2">수신자</label>
              <div className="col-md-10">
                <SearchUser
                  history={history}
                  match={match}
                  location={location}
                  userId={userId}
                  setUserId={setUserId}
                />
                {errors.wallet && <span>{errors.wallet.message}</span>}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
