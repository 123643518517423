import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_LIST_QUERY } from "../../Queries";
import AsyncSelect from "react-select/async";

export default ({
  history,
  match,
  location,
  store,
  startSearchDate,
  endSearchDate,
}) => {
  const [keyword, setKeyword] = useState("");
  const { data: stores } = useQuery(STORE_LIST_QUERY, {
    variables: { keyword, skip: 0, first: 10 },
  });

  const loadOptions = (inputValue, callback) => {
    if (stores && stores.AdminStoreList) {
      var options = stores.AdminStoreList.stores.map((store) => {
        return {
          value: store.id,
          label: `${store.name}`,
        };
      });

      callback(options);
    }
  };
  const handleInputChange = (value) => {
    setTimeout(() => {
      setKeyword(value);
      return value;
    }, 500);
  };
  return (
    <AsyncSelect
      placeholder="가맹점 이름 입력"
      noOptionsMessage={() => {
        return "가맹점 이름을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={({ label, value }) => {
        const ledgersUrl =
          "?" +
          (value ? "store=" + value + "&" : "") +
          (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
          (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");
        history.replace(`/storeOrders${ledgersUrl}`);
      }}
      defaultValue=""
    />
  );
};
