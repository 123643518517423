import { gql } from "apollo-boost";

/* 이벤트 설정 */
export const EVENT_CONFIG_QUERY = gql`
  query AdminEventConfig($id: String) {
    AdminEventConfig(id: $id) {
      id
      name
      listCount
      pageCount
    }
  }
`;

export const EVENT_CONFIG_WRITE_QUERY = gql`
  mutation AdminEventConfigWrite(
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminEventConfigWrite(
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

export const EVENT_CONFIG_EDIT_QUERY = gql`
  mutation AdminEventConfigEdit(
    $id: String
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminEventConfigEdit(
      id: $id
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

/* 이벤트 */
export const EVENT_LIST_QUERY = gql`
  query AdminEventList(
    $commonApp: String
    $eventConfig: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminEventList(
      commonApp: $commonApp
      eventConfig: $eventConfig
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      events {
        id
        title
        contents
        readCount
        user {
          id
          name
        }
        commonStatus
        startDate
        endDate
        coverImg
        detailImg
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const EVENT_WRITE_QUERY = gql`
  mutation AdminEventWrite(
    $commonApp: String
    $eventConfig: String!
    $isPush: Boolean
    $title: String!
    $contents: String!
    $commonStatus: String!
    $startDate: String
    $endDate: String
    $coverImg: CoverUpload
    $detailImg: DetailUpload
  ) {
    AdminEventWrite(
      commonApp: $commonApp
      eventConfig: $eventConfig
      isPush: $isPush
      title: $title
      contents: $contents
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      coverImg: $coverImg
      detailImg: $detailImg
    )
  }
`;

export const EVENT_DETAIL_QUERY = gql`
  query AdminEventDetail($id: String!) {
    AdminEventDetail(id: $id) {
      id
      title
      contents
      readCount
      startDate
      endDate
      commonStatus
      user {
        id
        name
      }
      coverImg
      detailImg
      createdAt
      updatedAt
    }
  }
`;

export const EVENT_EDIT_QUERY = gql`
  mutation AdminEventEdit(
    $id: String
    $commonStatus: String!
    $isPush: Boolean
    $coverImg: CoverUpload
    $detailImg: DetailUpload
    $startDate: String
    $endDate: String
    $title: String!
    $contents: String!
  ) {
    AdminEventEdit(
      id: $id
      commonStatus: $commonStatus
      isPush: $isPush
      coverImg: $coverImg
      detailImg: $detailImg
      startDate: $startDate
      endDate: $endDate
      title: $title
      contents: $contents
    )
  }
`;

export const EVENT_DELETE_QUERY = gql`
  mutation AdminEventDelete($id: String!) {
    AdminEventDelete(id: $id)
  }
`;
