import { gql } from "apollo-boost";

/* 통계 설정 */
export const STATISTICS_ISSUER_QUERY = gql`
  query AdminStatisticsIssuer(
    $addCurrency: String
    $startDate: String
    $endDate: String
  ) {
    AdminStatisticsIssuer(
      addCurrency: $addCurrency
      startDate: $startDate
      endDate: $endDate
    ) {
      RemittanceCount
      RemittanceTotal
      RemittanceOrderCount
      RemittanceOrderTotal

      usersRemittance {
        wallet
        userId
        name
        RemittanceCount
        RemittanceTotal
      }

      usersRemittanceOrder {
        wallet
        userId
        name
        RemittanceCount
        RemittanceTotal
      }
    }
  }
`;
