import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faReceipt,
  faMoneyBill,
  faSackDollar,
  faCreditCardBlank,
  faHeart,
  faAddressCard,
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import PerfectScrollbar from "react-perfect-scrollbar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  const [startDisplayDate, setStartDisplayDate] = useState(
    queryString.startDate
      ? queryString.startDate
      : Moment("2020-09-01").format("YYYY-MM-DD")
  );
  const [startSearchDate, setStartSearchDate] = useState(startDisplayDate);
  const [endDisplayDate, setEndDisplayDate] = useState(
    queryString.endDate ? queryString.endDate : Moment().format("YYYY-MM-DD")
  );
  const [endSearchDate, setEndSearchDate] = useState(endDisplayDate);

  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const handleDateApplyEvent = (event, picker) => {
    // event.preventDefault();

    var startDate = picker.startDate;
    var endDate = picker.endDate;

    setStartDisplayDate(startDate.format("YYYY-MM-DD"));
    setEndDisplayDate(endDate.format("YYYY-MM-DD"));
    setStartSearchDate(startDate.format("YYYY-MM-DD"));
    setEndSearchDate(endDate.format("YYYY-MM-DD"));

    const statisticsUrl =
      "?" +
      (startDate ? "startDate=" + startDate.format("YYYY-MM-DD") + "&" : "") +
      (endDate ? "endDate=" + endDate.format("YYYY-MM-DD") + "&" : "");

    history.push(`/statistics${statisticsUrl}`);
  };

  const { data, loading } = useQuery(STATISTICS_QUERY, {
    variables: {
      startDate: startSearchDate,
      endDate: endSearchDate,
    },
  });

  useEffect(() => {
    if (!loading) {
      let csv = [["가맹점", "건수", "금액"]];
      data.AdminStatistics.stores.forEach((item) => {
        csv.push([`${item.name}`, item.count, item.total]);
      });
      setCsvData(csv);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      AdminStatistics: {
        DNTTotal,
        DNTIssueCount,
        DNTIssueTotal,
        DNTUseCount,
        DNTUseTotal,
        ChargeRequestUserCount,
        ChargeRequestCMSCount,
        ChargeRequestCMSTotal,
        ChargeRequestNomalCount,
        ChargeRequestNomalTotal,
        PaymentCount,
        PaymentTotal,
        RemittanceCount,
        RemittanceTotal,
        ExchangeRequestCount,
        ExchangeRequestTotal,
        usersCharge,
        usersStoreOrder,
        usersStoreReturn,
        usersRemittance,
        usersDonation,
        usersMinusStoreOrder,
        stores,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>통계 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/statisticsUser">통계</Link>
            </li>
            <li className="breadcrumb-item active">전체통계</li>
          </ol>
          <h1 className="page-header">전체통계</h1>
          <div className="row">
            <div className="col-xl-12">
              <DateRangePicker
                startDate={Moment(startDisplayDate)}
                endDate={Moment(endDisplayDate)}
                onApply={handleDateApplyEvent}
              >
                <button className="btn btn-white mr-2 text-truncate m-b-5">
                  <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                  <span>
                    {startDisplayDate} ~ {endDisplayDate}
                  </span>
                  <b className="caret"></b>
                </button>
              </DateRangePicker>
            </div>
          </div>

          <div className="m-b-10 m-t-10 f-s-10">
            <Link
              className="pull-right text-inverse m-r-3 f-w-700 f-s-15"
              to={`/statisticsDNT?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
            >
              더보기
            </Link>
            <b className="text-inverse f-s-18">드림P 현황(거래내역 기준)</b>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">발행금액</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTUse?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTUseTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTUseCount}명)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">기부금액(인원)</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTIssue?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTIssueTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTIssueCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">잔여금액(건)</div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="m-b-10 m-t-10 f-s-10">
            <Link
              className="pull-right text-inverse m-r-3 f-w-700 f-s-15"
              to={`/statisticsUser?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
            >
              더보기
            </Link>
            <b className="text-inverse f-s-18">드림 현황(거래내역 기준)</b>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">발행금액</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTUse?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTUseTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTUseCount}명)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">기부금액(인원)</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTIssue?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTIssueTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTIssueCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">잔여금액(건)</div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="m-b-10 m-t-10 f-s-10">
            <Link
              className="pull-right text-inverse m-r-3 f-w-700 f-s-15"
              to={`/statisticsGJP?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
            >
              더보기
            </Link>
            <b className="text-inverse f-s-18">관저페이 현황(거래내역 기준)</b>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">발행금액</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTUse?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTUseTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTUseCount}명)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">기부금액(인원)</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTIssue?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTIssueTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTIssueCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">잔여금액(건)</div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="m-b-10 m-t-10 f-s-10">
            <Link
              className="pull-right text-inverse m-r-3 f-w-700 f-s-15"
              to={`/statisticsTOL?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
            >
              더보기
            </Link>
            <b className="text-inverse f-s-18">톨 현황(거래내역 기준)</b>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">발행금액</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTUse?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTUseTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTUseCount}명)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">기부금액(인원)</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTIssue?startDate=${startDisplayDate}&endDate=${endDisplayDate}`}
                    >
                      <NumberFormat
                        value={DNTIssueTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTIssueCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">잔여금액(건)</div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </>
    );
  }
});
