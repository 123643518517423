import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { FEEMONTHFILE_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(FEEMONTHFILE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { feeId: id, skip: 0, first: 300 },
  });

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      loading={loading}
    ></Presenter>
  );
});
