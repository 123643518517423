import { gql } from "apollo-boost";

//장부
export const LEDGER_LIST_QUERY = gql`
  query AdminLedgerList(
    $currency: String
    $transactionType: String
    $role: String
    $startSearchDate: String
    $endSearchDate: String
    $wallet: String
    $orderBy: String!
    $skip: Int
    $first: Int
  ) {
    AdminLedgerList(
      currency: $currency
      transactionType: $transactionType
      role: $role
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      wallet: $wallet
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ledgers {
        id
        transactionType
        accountWallet {
          id
          userId
        }
        destinationWallet {
          id
          userId
        }
        title
        currency
        value
        amount
        remittanceType
        comment
        createdAt
        updatedAt
        wallet {
          id
          type
          userId
          name
        }
        user {
          id
          role
        }
      }
      count
    }
  }
`;

export const WALLET_LIST_QUERY = gql`
  query AdminWalletList($keyword: String, $skip: Int, $first: Int) {
    AdminWalletList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      wallets {
        id
        type
        userId
        name
        address
        secret
        trustlines {
          id
        }
        memo
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const WALLET_DETAIL_QUERY = gql`
  query AdminWalletDetail($id: String!) {
    AdminWalletDetail(id: $id) {
      id
      type
      userId
      name
      address
      secret
      trustlines {
        id
      }
      memo
      createdAt
      updatedAt
    }
  }
`;
