import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  COUPON_USERLIST_QUERY,
  COUPON_USERSTOGGLE_QUERY,
  COUPON_DETAIL_QUERY,
} from "../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { ButtonGroup, Button } from "reactstrap";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, id, limit, users }) => {
  const { data, loading, refetch } = useQuery(COUPON_USERLIST_QUERY, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  const [couponUsersToggleMutation] = useMutation(COUPON_USERSTOGGLE_QUERY, {
    refetchQueries: () => [{ query: COUPON_DETAIL_QUERY, variables: { id } }],
  });
  const onUserssToggle = async (user) => {
    try {
      const {
        data: { AdminCouponUsersToggle },
      } = await couponUsersToggleMutation({
        variables: {
          id,
          user,
        },
      });
      if (AdminCouponUsersToggle) {
        toast.success("처리 되었습니다.");
        refetch();
        // history.push(
        //   `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
        // );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  let completionCount = 0;

  return (
    <Panel>
      <PanelHeader noButton={true}>
        참여자 정보({!loading && data.AdminCouponUserList.count}
        명)
      </PanelHeader>
      <PanelBody className="p-0">
        {!loading && data.AdminCouponUserList.count > 0 ? (
          <>
            <div className="table-responsive">
              <table className="table table-bordered table-striped m-b-0">
                <thead>
                  <tr>
                    <th>이름(아이디)</th>
                    <th>전체참여</th>
                    <th>가맹점</th>
                    <th>완성일시</th>
                    <th>기능</th>
                  </tr>
                </thead>
                <tbody>
                  {data.AdminCouponUserList.users.map((item, index) => {
                    var checker = false;
                    for (var i = 0; i < users.length; i++) {
                      if (users[i].id === item.id) {
                        checker = true;
                        break;
                      }
                    }

                    var createdAt = new Date(parseInt(item.createdAt));
                    createdAt.setHours(createdAt.getHours() + 9);

                    if (item.DISCINT_COUNT >= 5) {
                      completionCount++;
                    }

                    return (
                      <tr key={item.id}>
                        <td>
                          {limit >= completionCount &&
                            item.DISCINT_COUNT >= 5 && (
                              <span className="badge badge-primary">완성</span>
                            )}{" "}
                          {item.name}({item.userId}){" "}
                          {checker && (
                            <span className="badge badge-primary">수령</span>
                          )}
                        </td>
                        <td align="right">
                          <NumberFormat
                            value={item.COUNT}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          건
                        </td>
                        <td align="right">
                          <NumberFormat
                            value={item.DISCINT_COUNT}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          건
                        </td>
                        <td>
                          {item.DISCINT_COUNT >= 5 ? (
                            <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                              {createdAt}
                            </ReactMoment>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <ButtonGroup className="pull-right">
                            <Link
                              className="btn btn-white btn-xs text-primary"
                              to={`/couponConfigs/${match.params.couponConfig}/coupons/${id}/user/${item.id}/stores${location.search}`}
                            >
                              상세정보
                            </Link>{" "}
                            {limit >= completionCount &&
                              item.DISCINT_COUNT >= 5 && (
                                <>
                                  {checker ? (
                                    <Button
                                      className="btn btn-white btn-xs text-danger"
                                      onClick={() => {
                                        onUserssToggle(item.id);
                                      }}
                                    >
                                      수령취소
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn btn-white btn-xs text-primary"
                                      onClick={() => {
                                        onUserssToggle(item.id);
                                      }}
                                    >
                                      수령하기
                                    </Button>
                                  )}
                                </>
                              )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <p className="text-center">참여 사용자가 없습니다.</p>
        )}
      </PanelBody>
    </Panel>
  );
});
