import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { DISCOUNT_DETAIL_QUERY, DISCOUNT_DELETE_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import Moment from "react-moment";
// import ProductList from "./Product/List";
import { graphqlUri } from "../../../Config/Env";
import { toast } from "react-toastify";

import { commonStatusName, dayName } from "../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(DISCOUNT_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });
  const [discountDeleteMutation] = useMutation(DISCOUNT_DELETE_QUERY);

  const onDelete = async (id) => {
    try {
      const {
        data: { AdminDiscountDelete },
      } = await discountDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminDiscountDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/discounts${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading) {
    const {
      AdminDiscountDetail: {
        id,
        commonStatus,
        startDate,
        endDate,
        day,
        coverImage,
        detailImage,
        title,
        contents,
        store,
      },
    } = data;

    const cover =
      coverImage === "" || coverImage === null
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUri}${coverImage}`;
    const detail =
      detailImage === "" || detailImage === null
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUri}${detailImage}`;
    return (
      <>
        <Helmet>
          <title>오늘의 할인 관리 | 한밭페이 </title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/stores">가맹점</Link>
            </li>
            <li className="breadcrumb-item active">오늘의 할인</li>
          </ol>
          <h1 className="page-header">오늘의 할인</h1>

          <div key="topButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/discounts${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <div className="btn-group">
                <Link
                  className=" btn btn-white text-green"
                  to={`/discounts/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>

          <div key="storeInfo" className="row">
            <div className="col-sm-3">
              <Panel>
                <PanelHeader noButton={true}>커버 이미지</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="bg-white rounded">
                      <div className="form-group row">
                        <div className="col-md-12">
                          <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                            <div className="vertical-box-column valign-middle text-center">
                              <img
                                src={cover}
                                alt="이미지 업로드"
                                className="width-200"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeader noButton={true}>상세 이미지</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="bg-white rounded">
                      <div className="form-group row">
                        <div className="col-md-12">
                          <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                            <div className="vertical-box-column valign-middle text-center">
                              <img
                                src={detail}
                                alt="이미지 업로드"
                                className="width-200"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-sm-9">
              <Panel>
                <PanelHeader noButton={true}>기본정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-10">
                        {commonStatusName(commonStatus)}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">기간</label>
                      <div className="col-md-10">
                        <Moment format="YYYY-MM-DD">{startDate}</Moment> ~
                        <Moment format="YYYY-MM-DD">{endDate}</Moment>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">요일</label>
                      <div className="col-md-10">{dayName(day)}</div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeader noButton={true}>가맹점 정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">가맹점</label>
                      <div className="col-md-10">
                        <Link to={`/stores/${store.id}`}>{store.name}</Link>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeader noButton={true}>상세정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">{title}</div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">내용</label>
                      <div className="col-md-10">{contents}</div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div key="bottomButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/discounts${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <div className="btn-group">
                <Link
                  className=" btn btn-white text-green "
                  to={`/discounts/${id}/edit${location.search}`}
                >
                  수정
                </Link>

                <Button
                  type="button"
                  color="white"
                  className="text-red"
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
