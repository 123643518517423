import { gql } from "apollo-boost";

export const TICKET_PRODUCT_REVIEW_LIST_QUERY = gql`
  query StoreTicketProductReviewList(
    $ticketProduct: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    StoreTicketProductReviewList(
      ticketProduct: $ticketProduct
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketProductReviews {
        id
        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents

          createdAt
          updatedAt
        }

        ticketOrderProduct {
          id
          commonStatus

          isReservation
          isMember
          user {
            id
            role
            avatar
            userId
            name
          }
          name
          phone

          isDiscount
          discountPrice
          price
          totalPrice

          ticketProductDate {
            id
            commonStatus
            ticketDate
          }
          ticketProductDateTime {
            id
            commonStatus
            isSoldOut
            ticketDateTime
          }

          isRefund
          meRefund

          createdAt
          updatedAt
        }
        user {
          id
          userId
          name
        }
        commonStatus
        isSecret
        score
        contents
        isComment
        comment
        commentDate
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const TICKET_PRODUCT_REVIEW_DETAIL_QUERY = gql`
  query StoreTicketProductReviewDetail($id: String!) {
    StoreTicketProductReviewDetail(id: $id) {
      id
      ticketProduct {
        id
        ticketConfig {
          id
        }
        commonStatus

        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }

        name
        contents

        createdAt
        updatedAt
      }

      ticketOrderProduct {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          role
          avatar
          userId
          name
        }
        name
        phone

        isDiscount
        discountPrice
        price
        totalPrice

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        isRefund
        meRefund

        createdAt
        updatedAt
      }
      user {
        id
        userId
        name
      }
      commonStatus
      isSecret
      score
      contents
      isComment
      comment
      commentDate
      createdAt
      updatedAt
    }
  }
`;

export const TICKET_PRODUCT_REVIEW_COMMENT_QUERY = gql`
  mutation StoreTicketProductReviewComment(
    $id: String!
    $comment: String!
    $isPush: Boolean
  ) {
    StoreTicketProductReviewComment(id: $id, comment: $comment, isPush: $isPush)
  }
`;

export const TICKET_PRODUCT_REVIEW_SECRET_TOGGLE_QUERY = gql`
  mutation StoreTicketProductReviewSecretToggle($id: String!) {
    StoreTicketProductReviewSecretToggle(id: $id)
  }
`;

export const TICKET_PRODUCT_REVIEW_DELETE_QUERY = gql`
  mutation StoreTicketProductReviewDelete($id: String!) {
    StoreTicketProductReviewDelete(id: $id)
  }
`;
