import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_DETAIL_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const baseUrl = "/deliveryStores";

  const { data, loading, error } = useQuery(ADMIN_DELIVERY_STORE_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
