import React, { useState, useEffect, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Select from "../../../../../../Containers/DeliveryProductCategory/Select";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_PRODUCT_CATEGORY_MOVE_MUTATION,
  STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
} from "../../Queries";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export default ({ history, match, location, isModal, data, baseUrl }) => {
  const modalRef = React.createRef();

  const {
    StoreDeliveryProductCategoryDetail: {
      id,
      createdAt,
      updatedAt,

      commonStatus,
      iconImage,
      name,
      order,

      deliveryProductsCount,
    },
  } = data;

  const [options, setOptions] = useState([]);
  const [selectDepth, setSelectDepth] = useState(0);

  const { control, handleSubmit, errors } = useForm();

  const [
    StoreDeliveryProductCategoryMoveMutation,
    { loading: StoreDeliveryProductCategoryMoveLoading },
  ] = useMutation(STORE_DELIVERY_PRODUCT_CATEGORY_MOVE_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
        variables: {
          take: 100,
          skip: 0,
        },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (StoreDeliveryProductCategoryMoveLoading) {
        return;
      }
      const {
        data: { StoreDeliveryProductCategoryMove },
      } = await StoreDeliveryProductCategoryMoveMutation({
        variables: {
          id: id,
          target: data.target,
          position: data.position,
        },
      });
      if (StoreDeliveryProductCategoryMove) {
        toast.success("처리 되었습니다.");
        history.push(`${baseUrl}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(`${baseUrl}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`${baseUrl}${location.search}`);
            }}
          >
            {name} 분류 이동
          </ModalHeader>
          <ModalBody className="p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <Alert className="m-0" color="primary">
                  선택한 분류를 기준 분류의 선택 위치로 이동합니다.
                </Alert>
                <Controller
                  control={control}
                  name="target"
                  rules={{ required: "기준을 선택해주세요." }}
                  render={({ onChange, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">기준</label>
                      <div className="col-md-10">
                        <Select onChange={onChange} value={value} />
                        {/* move : {selectDepth} {childrenCount}
                    <br />
                    select : {selectDepth} {selectChildrenCount}
                    <br /> */}
                        {errors.target && (
                          <span className="text-red errorText">
                            {errors.target.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="position"
                  rules={{ required: "위치를 선택해주세요." }}
                  render={({ onChange, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">위치</label>
                      <div className="col-md-10">
                        <div className="radio">
                          <input
                            type="radio"
                            id="position1"
                            onChange={onChange}
                            value={"up"}
                            checked={value === "up"}
                          />{" "}
                          <label htmlFor="position1">
                            선택한 분류를 기준 분류 위로 옮깁니다.
                          </label>
                        </div>
                        <div className="radio">
                          <input
                            type="radio"
                            id="position2"
                            onChange={onChange}
                            value={"down"}
                            checked={value === "down"}
                          />{" "}
                          <label htmlFor="position2">
                            선택한 분류를 기준 분류 아래로 옮깁니다.
                          </label>
                        </div>
                        {errors.position && (
                          <span className="text-red errorText">
                            {errors.position.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};
