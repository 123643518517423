import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import DeliveryEditor from "../../../../../Components/Editor/DeliveryEditor";
import { commonStatusName } from "../../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_SET_WRITE_MUTATION,
  STORE_DELIVERY_SET_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

export default withRouter(({ history, match, location }) => {
  const baseUrl = "/delivery/deliverySets";
  const timestamp = String(new Date().getTime());

  const { control, handleSubmit, errors } = useForm({
    defalutValues: {
      commonStatus: "",
      coverImage: "",
      name: "",
      description: "",
    },
  });

  const [
    StoreDeliverySetWriteMutation,
    { loading: StoreDeliverySetWriteLoading },
  ] = useMutation(STORE_DELIVERY_SET_WRITE_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_SET_LIST_QUERY,
        variables: { take: 15, skip: 0 },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (StoreDeliverySetWriteLoading) {
        return;
      }

      const {
        data: { StoreDeliverySetWrite },
      } = await StoreDeliverySetWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          coverImage: data.coverImage,
          timestamp,
          name: data.name,
          description: data.description,
        },
      });

      if (StoreDeliverySetWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const [cover, setCover] = useState("/assets/img/product/product-default.jpg");
  const handleCoverImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setCover(reader.result);
    };
  };
  const onClickCoverImage = () => {
    document.getElementById("coverImage").click();
  };

  return (
    <>
      <Helmet>
        <title>묶음 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={`/delivery/deliveryStores`}>로컬푸드 구독</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={`/delivery/deliveryProducts`}>상품</Link>
          </li>
          <li className="breadcrumb-item active">묶음</li>
        </ol>
        <h1 className="page-header">묶음</h1>

        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-3">
              <Panel>
                <PanelHeader noButton>커버 이미지</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="bg-white rounded">
                      <Controller
                        control={control}
                        name="coverImage"
                        rules={{ required: "커버 이미지를 선택해주세요." }}
                        render={({ onChange }) => (
                          <div className="form-group row">
                            <div className="col-md-12">
                              <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                                <div className="vertical-box-column valign-middle text-center">
                                  <img
                                    src={cover}
                                    alt="이미지 업로드"
                                    className="width-200"
                                  />
                                </div>
                              </div>
                              <div className="m-t-10">
                                <input
                                  type="file"
                                  id="coverImage"
                                  className="hide"
                                  onChange={(e) => {
                                    handleCoverImage(e);
                                    onChange(e.target.files[0]);
                                  }}
                                  accept="image/*"
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  onClick={onClickCoverImage}
                                >
                                  이미지 업로드
                                </button>
                                {errors.coverImage && (
                                  <span className="text-red errorText">
                                    {errors.coverImage.message}
                                  </span>
                                )}
                              </div>
                              <div className="alert alert-primary fade show m-t-10 m-b-0">
                                <b>커버이미지 사이즈 :</b> 넓이 600px X 높이
                                600px
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-sm-9">
              <Panel>
                <PanelHeader noButton>기본정보</PanelHeader>
                <PanelBody className="panel-form form-horizontal form-bordered">
                  <Controller
                    control={control}
                    name="commonStatus"
                    rules={{ required: "상태를 선택해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">상태</label>
                        <div className="col-md-10">
                          <div>
                            <div className="radio radio-css radio-inline">
                              <input
                                type="radio"
                                id="commonStatus1"
                                name="commonStatus"
                                onClick={onChange}
                                onBlur={onBlur}
                                value={"S"}
                                // checked={value === "S"}
                              />
                              <label htmlFor="commonStatus1">
                                {commonStatusName("S")}
                              </label>
                            </div>
                            <div className="radio radio-css radio-inline">
                              <input
                                type="radio"
                                id="commonStatus2"
                                name="commonStatus"
                                onClick={onChange}
                                onBlur={onBlur}
                                value={"C"}
                                // checked={value === "C"}
                              />
                              <label htmlFor="commonStatus2">
                                {commonStatusName("C")}
                              </label>
                            </div>
                          </div>
                          {errors.commonStatus && (
                            <span className="text-red errorText">
                              {errors.commonStatus.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  />

                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: "묶음명을 입력해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          묶음명
                        </label>
                        <div className="col-md-10">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="묶음명을 입력해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                          />
                          {errors.name && (
                            <span className="text-red errorText">
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  />

                  <Controller
                    control={control}
                    name="description"
                    rules={{ required: "상세정보를 입력해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          상세정보
                        </label>
                        <div className="col-md-10">
                          <DeliveryEditor
                            timestamp={timestamp}
                            onChange={onChange}
                            value={value || ""}
                          />
                          {errors.description && (
                            <span className="text-red errorText">
                              {errors.description.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-4 text-left"></div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-success">
                확인
              </button>
              <Link
                to={`/delivery/deliverySets${location.search}`}
                className="btn btn-white text-danger m-l-5"
              >
                취소
              </Link>
            </div>
            <div className="col-xs-4 text-right"></div>
          </div>
        </form>
      </div>
    </>
  );
});
