import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TICKET_PRODUCT_DATETIME_EDIT_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import { commonStatusArray } from "../../../../../../Components/User/UserNames";

import Moment from "moment";
import ReactMoment from "react-moment";

export default ({
  history,
  match,
  location,

  id,
  commonStatus,
  isSoldOut,
  ticketDateTime,

  isModal,
}) => {
  const modalRef = React.createRef();
  const productCommonStatus = match.params.commonStatus;

  const [ticketProductDateTimeEditMutation] = useMutation(
    TICKET_PRODUCT_DATETIME_EDIT_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_DETAIL_QUERY,
          variables: { id: match.params.id },
        },
      ],
    }
  );

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      commonStatus,
      isSoldOut,
      ticketDateTime: Moment(ticketDateTime).format("HH:mm:ss"),
    },
  });

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminTicketProductDateTimeEdit },
      } = await ticketProductDateTimeEditMutation({
        variables: {
          id: id,
          commonStatus: data.commonStatus,
          isSoldOut: data.isSoldOut === "true" ? true : false,
          ticketDateTime: data.ticketDateTime,
        },
      });
      if (AdminTicketProductDateTimeEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
              );
            }}
          >
            일정시간수정
          </ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <table className="table table-bordered tablebasic product_op_table m-0">
                <caption className="sr-only">
                  티켓시간수정-항목, 시간, 추가금액, 상태, 매진
                </caption>
                <thead>
                  <tr>
                    <th
                      abbr="시간"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                      }}
                    >
                      시간
                    </th>
                    <th
                      abbr="상태"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                      }}
                    >
                      상태
                    </th>
                    <th
                      abbr="매진"
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                      }}
                    >
                      매진
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="ticketDateTime"
                        className="form-control"
                        ref={register()}
                        placeholder="시간을 입력해주세요."
                      />
                    </td>
                    <td>
                      <select
                        className="form-control"
                        name="commonStatus"
                        ref={register({
                          required: "옵션상태를 입력해주세요.",
                        })}
                      >
                        {Object.keys(commonStatusArray).map((key, index) => (
                          <option key={index} value={key}>
                            {commonStatusArray[key]}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="form-control"
                        name="isSoldOut"
                        value={true}
                        ref={register()}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {errors.name && (
              <Fragment>
                <span>{errors.name.message}</span>
                <br />
              </Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};
