import React, { useEffect } from "react";
import { useMutation } from "react-apollo-hooks";
import {
  GIFT_PRODUCT_BESTPREVMOVE_QUERY,
  GIFT_PRODUCT_BESTNEXTMOVE_QUERY,
  GIFT_PRODUCT_BEST_LIST_QUERY,
} from "../../Queries";
import { Modal, ModalHeader, ModalBody, CardImg } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import { commonStatusName } from "../../../../../Components/User/UserNames";

export default ({ history, match, location, isModal, data, loading }) => {
  const modalRef = React.createRef();

  const [productBestPrevMoveMutation] = useMutation(
    GIFT_PRODUCT_BESTPREVMOVE_QUERY,
    {
      refetchQueries: () => [{ query: GIFT_PRODUCT_BEST_LIST_QUERY }],
    }
  );
  const [productBestNextMoveMutation] = useMutation(
    GIFT_PRODUCT_BESTNEXTMOVE_QUERY,
    {
      refetchQueries: () => [{ query: GIFT_PRODUCT_BEST_LIST_QUERY }],
    }
  );

  const prevMove = async (id) => {
    try {
      const {
        data: { AdminGiftProductBestPrevMove },
      } = await productBestPrevMoveMutation({
        variables: {
          id,
        },
      });
      if (AdminGiftProductBestPrevMove) {
        toast.success("처리 되었습니다.");
        history.push(
          `/giftConfigs/${match.params.config}/products/best${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  const nextMove = async (id) => {
    try {
      const {
        data: { AdminProductBestNextMove },
      } = await productBestNextMoveMutation({
        variables: {
          id,
        },
      });
      if (AdminProductBestNextMove) {
        toast.success("처리 되었습니다.");
        history.push(
          `/giftConfigs/${match.params.config}/products/best${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/giftConfigs/${match.params.config}/products${location.search}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/giftConfigs/${match.params.config}/products${location.search}`
            );
          }}
        >
          BEST 정렬 ({!loading && data.AdminProductBestList.count}건)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox bg-light height-sm">
            <div className="vertical-box-column">
              <div className="vertical-box">
                <div className="vertical-box-row bg-white">
                  <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                      {!loading && data.AdminProductBestList.count > 0 ? (
                        <PerfectScrollbar
                          className="width-full p-10"
                          options={{ suppressScrollY: true }}
                        >
                          <div className="product-list-wrap">
                            <ul>
                              {data.AdminProductBestList.products.map(
                                (item, index) => {
                                  const CoverImage =
                                    item.coverImg === "" ||
                                    item.coverImg === null
                                      ? "/assets/img/user/user-default.jpg"
                                      : `${graphqlUri}${item.coverImg}`;
                                  return (
                                    <li className="product-list width-300">
                                      <div key={index} className="img">
                                        <CardImg
                                          top
                                          src={CoverImage}
                                          alt={item.name}
                                        />
                                        <div className="category">
                                          <span className="label label-yellow">
                                            {commonStatusName(
                                              item.commonStatus
                                            )}
                                          </span>
                                          {item.isNew && (
                                            <span className="label label-primary">
                                              New
                                            </span>
                                          )}
                                          {item.isBest && (
                                            <span className="label label-success">
                                              Best
                                            </span>
                                          )}
                                          {item.isSoldOut && (
                                            <span className="label label-danger">
                                              품절
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="product-list-cont">
                                        <h4 className="tit">
                                          [{item.productCategory.name}]{" "}
                                          {item.name}{" "}
                                        </h4>
                                        <div className="desc">
                                          {item.description}
                                        </div>
                                        <div className="price-info">
                                          {item.isDiscount ? (
                                            <>
                                              <span className="price">
                                                <NumberFormat
                                                  value={item.price}
                                                  suffix={"원"}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                />
                                              </span>
                                              <span className="discount">
                                                <NumberFormat
                                                  value={
                                                    item.price -
                                                    item.discountPrice
                                                  }
                                                  prefix={""}
                                                  suffix={"원"}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                />
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span className="price">
                                                <NumberFormat
                                                  value={item.price}
                                                  suffix={"원"}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                />
                                              </span>
                                            </>
                                          )}
                                          <span className="date">
                                            <Moment format="YYYY-MM-DD">
                                              {item.createdAt}
                                            </Moment>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="btn-group  btn-block">
                                        <button
                                          class="btn btn-white btn-sm"
                                          onClick={() => {
                                            prevMove(item.id);
                                          }}
                                        >
                                          <i class="fa fa-chevron-left"></i>
                                        </button>
                                        <button
                                          class="btn btn-white btn-sm"
                                          onClick={() => {
                                            nextMove(item.id);
                                          }}
                                        >
                                          <i class="fa fa-chevron-right"></i>
                                        </button>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </PerfectScrollbar>
                      ) : (
                        <p className="text-center">등록된 데이터가 없습니다.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
