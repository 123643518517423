import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { DISCOUNT_DETAIL_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(DISCOUNT_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminDiscountDetail) {
    const {
      AdminDiscountDetail: {
        id,
        commonStatus,
        startDate,
        endDate,
        day,
        coverImage,
        detailImage,
        title,
        contents,
        store,
        createdAt,
        updatedAt,
      },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        commonStatus={commonStatus}
        startDateAt={startDate}
        endDateAt={endDate}
        day={day}
        coverImage={coverImage}
        detailImage={detailImage}
        title={title}
        contents={contents}
        storeId={store.id}
        storeName={store.name}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
    );
  }
});
