import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../../../Components/Panel/Panel";
import { InputGroup, InputGroupAddon, Button, Alert } from "reactstrap";
import { dayOfWeekName } from "../../../../Components/User/UserNames";
import PerfectScrollbar from "react-perfect-scrollbar";
import DeliveryStoreSelect from "../../../../Containers/DeliveryStore/Select";

export default ({
  history,
  match,
  location,
  deliveryStore_id,
  loading,
  data,
  baseUrl,
}) => {
  const onChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" + (e.target.value ? "deliveryStore_id=" + e.target.value + "&" : "");
    history.push(`${baseUrl}`);
  };

  return (
    <div>
      <Helmet>
        <title>요일별 주문현황 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/deliveryBoxes">배송</Link>
        </li>
        <li className="breadcrumb-item active">요일별 주문현황</li>
      </ol>
      <h1 className="page-header">요일별 주문현황</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>요일별 주문현황</PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row">
                <div className="col-xs-12">
                  <InputGroup>
                    <DeliveryStoreSelect
                      onChange={onChange}
                      value={deliveryStore_id}
                    />
                    <InputGroupAddon addonType="append">
                      <Link className={"btn btn-white"} to={`/deliveryStores`}>
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className="row m-t-15">
                <div className="col-xs-6"></div>
                <div className="col-xs-6 text-right">
                  <Link
                    className="btn btn-white"
                    to={"/deliveryBoxesDayOfWeek/print"}
                  >
                    <i className="fa fa-print"></i> 현황출력
                  </Link>
                </div>
              </div>
            </Alert>
            <PanelBody>
              <div className="d-flex">
                {!loading &&
                  data.AdminDeliveryBoxDayOfWeek.filter(
                    (item) => 0 < item.dayOfWeek && item.dayOfWeek < 6
                  ).map((item) => {
                    return (
                      <div className="flex-fill" key={item.dayOfWeek}>
                        <div className="vertical-box with-grid inbox ">
                          <div className="vertical-box-inner-cell overflow-visible height-420 ">
                            <PerfectScrollbar
                              className="height-full p-5"
                              options={{ suppressScrollX: true }}
                            >
                              <div className="card border-0 text-white mb-3 ">
                                <div className="card-body text-right bg-black-transparent-9 rounded-top">
                                  <div className="m-b-10 m-t-10 f-s-10">
                                    <h3 style={{ textAlign: "center" }}>
                                      {dayOfWeekName(item.dayOfWeek)}
                                    </h3>
                                  </div>
                                </div>
                                <div className="widget-list widget-list-rounded  border border-light overflow-hidden rounded-bottom">
                                  {item.deliveryBoxDayOfWeek.length > 0 ? (
                                    <Fragment>
                                      {item.deliveryBoxDayOfWeek.map(
                                        (product, index) => {
                                          return (
                                            <div
                                              className="widget-list-item rounded-0 p-t-3"
                                              key={index}
                                            >
                                              <div className="widget-list-content">
                                                <div className="widget-list-title">
                                                  {product.deliveryProduct_name}

                                                  {(product.deliveryProductOptionDetail1_name ||
                                                    product.deliveryProductOptionDetail2_name ||
                                                    product.deliveryProductOptionDetail3_name) && (
                                                    <span>
                                                      (
                                                      {product.deliveryProductOptionDetail1_name &&
                                                        `${product.deliveryProductOptionDetail1_name}`}
                                                      {product.deliveryProductOptionDetail2_name &&
                                                        ` / ${product.deliveryProductOptionDetail2_name}`}
                                                      {product.deliveryProductOptionDetail3_name &&
                                                        ` / ${product.deliveryProductOptionDetail3_name}`}
                                                      )
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="widget-list-action text-nowrap text-right">
                                                {product.sum}개
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Fragment>
                                  ) : (
                                    <p className="text-center text-inverse m-t-15">
                                      주문내역이 없습니다.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </PerfectScrollbar>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};
