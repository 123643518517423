import { gql } from "apollo-boost";

/* 통계 설정 */
export const STATISTICS_DNT_QUERY = gql`
  query AdminStatisticsDNT($startDate: String, $endDate: String) {
    AdminStatisticsDNT(startDate: $startDate, endDate: $endDate) {
      DNTTotal
      DNTExchangeTotal
      DNTWithdrawalTotal
      DNTUserTotal
      DNTDonationTotal
      DNTIssueCount
      DNTIssueTotal
      DNTUseCount
      DNTUseTotal
    }
  }
`;

export const STATISTICS_DNTISSUE_QUERY = gql`
  query AdminStatisticsDNTIssue($startDate: String!, $endDate: String!) {
    AdminStatisticsDNTIssue(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_DNTUSE_QUERY = gql`
  query AdminStatisticsDNTUse($startDate: String!, $endDate: String!) {
    AdminStatisticsDNTUse(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
