import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faReceipt,
  faMoneyBill,
  faSackDollar,
  faCreditCardBlank,
  faHeart,
  faAddressCard,
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import PerfectScrollbar from "react-perfect-scrollbar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import DatePicker from "../../../Containers/SelectedPeriod/DatePicker";
import { pastPeriod } from "../../../Components/User/UserNames";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const startDate = queryString.startDate
    ? queryString.startDate
    : Moment().subtract(1, "months").format("YYYY-MM-DD");
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().format("YYYY-MM-DD");

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const DownloadButton = () => {
    const handleDownload = async () => {
      try {
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    return (
      <>
        <button
          className="btn btn-white btn-xs btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i> 다운로드
        </button>
        <CSVLink
          data={csvData}
          filename={`통계_가맹점_현황_${startDate}_${endDate}.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  const { data, loading } = useQuery(STATISTICS_QUERY, {
    variables: {
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    if (!loading) {
      let csv = [["가맹점", "건수", "금액"]];
      data.AdminStatistics.stores.forEach((item) => {
        csv.push([`${item.name}`, item.count, item.total]);
      });
      setCsvData(csv);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      AdminStatistics: {
        DNTTotal,
        DNTIssueCount,
        DNTIssueTotal,
        DNTUseCount,
        DNTUseTotal,
        ChargeRequestUserCount,
        ChargeRequestCMSCount,
        ChargeRequestCMSTotal,
        ChargeRequestNomalCount,
        ChargeRequestNomalTotal,
        PaymentCount,
        PaymentTotal,
        RemittanceCount,
        RemittanceTotal,
        ExchangeRequestCount,
        ExchangeRequestTotal,
        usersCharge,
        usersStoreOrder,
        usersStoreReturn,
        usersRemittance,
        usersDonation,
        usersMinusStoreOrder,
        stores,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>통계 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/statisticsUser">통계</Link>
            </li>
            <li className="breadcrumb-item active">전체통계</li>
          </ol>
          <h1 className="page-header">전체통계</h1>
          <div className="row">
            <div className="col-xl-12">
              <DatePicker
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                onStartDateSelect={onStartDateSelect}
                onEndDateSelect={onEndDateSelect}
                onPeriodConfig={onPeriodConfig}
                period={pastPeriod}
              />
            </div>
          </div>
          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">
              기부포인트 현황(거래내역 기준)
            </b>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DNTTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림P
                  </div>
                  <div className="stats-desc f-s-15">기부포인트 총 잔액</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTIssue?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={DNTIssueTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTIssueCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">발행금액(건)</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/DNTUse?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={DNTUseTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림P ({DNTUseCount}명)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">기부금액(인원)</div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">충전 현황(충전내역 기준)</b>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/ChargeRequestUser?startDate=${startDate}&endDate=${endDate}`}
                    >
                      {ChargeRequestUserCount}명
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">충전인원</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faAddressCard} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/ChargeRequestCMS?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={ChargeRequestCMSTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림({ChargeRequestCMSCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">CMS 충전금액(건)</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faMoneyBill} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/ChargeRequestNomal?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={ChargeRequestNomalTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림({ChargeRequestNomalCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">일반 충전금액(건)</div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">소비자 현황</b>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faReceipt} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/Payment?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={PaymentTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림({PaymentCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">결제금액(건)</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faCreditCardBlank} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <Link
                      to={`/statistics/Remittance?startDate=${startDate}&endDate=${endDate}`}
                    >
                      <NumberFormat
                        value={RemittanceTotal}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                      드림({RemittanceCount}건)
                    </Link>
                  </div>
                  <div className="stats-desc f-s-15">송금금액(건)</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="vertical-box with-grid inbox">
                <div className="vertical-box-inner-cell overflow-visible height-420">
                  <PerfectScrollbar
                    className="height-full p-5"
                    options={{ suppressScrollX: true }}
                  >
                    {usersCharge.filter((item) => item.ChargeTotal > 0).length >
                    0 ? (
                      <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                        <div
                          className="card-body text-right"
                          style={{
                            background: "no-repeat bottom left",
                            backgroundImage: "url(/assets/img/svg/img-4.svg)",
                            backgroundSize: "auto 60%",
                          }}
                        >
                          <div className="m-b-10 m-t-10 f-s-10">
                            <Link
                              className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
                              to={`/statistics/UsersCharge?startDate=${startDate}&endDate=${endDate}`}
                            >
                              더보기
                            </Link>
                            <h3 style={{ textAlign: "left" }}>충전 TOP 10</h3>
                          </div>
                        </div>
                        <div className="widget-list widget-list-rounded ">
                          {usersCharge
                            .filter((item) => item.ChargeTotal > 0)
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="widget-list-item rounded-0 p-t-3"
                                >
                                  <div className="widget-list-content">
                                    <div className="widget-list-title">
                                      <Link
                                        to={`/ledgers/DRM?transactionType=Charge&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                      >
                                        {item.ChargeTotal > 0
                                          ? `${index + 1}.${item.name}(${
                                              item.userId
                                            })`
                                          : "-"}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="widget-list-action text-nowrap text-right">
                                    <NumberFormat
                                      value={item.ChargeTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    드림({item.ChargeCount}건)
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="vertical-box with-grid inbox">
                        <div className="vertical-box-inner-cell overflow-visible height-md">
                          <p className="text-center">충전 데이터가 없습니다.</p>
                        </div>
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="vertical-box with-grid inbox">
                <div className="vertical-box-inner-cell overflow-visible height-420">
                  <PerfectScrollbar
                    className="height-full p-5"
                    options={{ suppressScrollX: true }}
                  >
                    {usersStoreOrder.filter((item) => item.StoreOrderTotal > 0)
                      .length > 0 ? (
                      <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                        <div
                          className="card-body text-right"
                          style={{
                            background: "no-repeat bottom left",
                            backgroundImage: "url(/assets/img/svg/img-4.svg)",
                            backgroundSize: "auto 60%",
                          }}
                        >
                          <div className="m-b-10 m-t-10 f-s-10">
                            <Link
                              className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
                              to={`/statistics/UsersStoreOrder?startDate=${startDate}&endDate=${endDate}`}
                            >
                              더보기
                            </Link>
                            <h3 style={{ textAlign: "left" }}>
                              상점결제(환불제외) TOP 10
                            </h3>
                          </div>
                        </div>
                        <div className="widget-list widget-list-rounded ">
                          {usersStoreOrder
                            .filter((item) => item.StoreOrderTotal > 0)
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="widget-list-item rounded-0 p-t-3"
                                >
                                  <div className="widget-list-content">
                                    <div className="widget-list-title">
                                      <Link
                                        to={`/ledgers/DRM?transactionType=StoreOrder&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                      >
                                        {item.StoreOrderTotal > 0
                                          ? `${index + 1}.${item.name}(${
                                              item.userId
                                            })`
                                          : "-"}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="widget-list-action text-nowrap text-right">
                                    <NumberFormat
                                      value={item.StoreOrderTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    드림({item.StoreOrderCount}건)
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="vertical-box with-grid inbox">
                        <div className="vertical-box-inner-cell overflow-visible height-md">
                          <p className="text-center">
                            상점결제 데이터가 없습니다.
                          </p>
                        </div>
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="vertical-box with-grid inbox">
                <div className="vertical-box-inner-cell overflow-visible height-420">
                  <PerfectScrollbar
                    className="height-full p-5"
                    options={{ suppressScrollX: true }}
                  >
                    {usersStoreReturn.filter(
                      (item) => item.StoreReturnTotal > 0
                    ).length > 0 ? (
                      <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                        <div
                          className="card-body text-right"
                          style={{
                            background: "no-repeat bottom left",
                            backgroundImage: "url(/assets/img/svg/img-4.svg)",
                            backgroundSize: "auto 60%",
                          }}
                        >
                          <div className="m-b-10 m-t-10 f-s-10">
                            <Link
                              className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
                              to={`/statistics/UsersStoreReturn?startDate=${startDate}&endDate=${endDate}`}
                            >
                              더보기
                            </Link>
                            <h3 style={{ textAlign: "left" }}>
                              상점환불 TOP 10
                            </h3>
                          </div>
                        </div>
                        <div className="widget-list widget-list-rounded ">
                          {usersStoreReturn
                            .filter((item) => item.StoreReturnTotal > 0)
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="widget-list-item rounded-0 p-t-3"
                                >
                                  <div className="widget-list-content">
                                    <div className="widget-list-title">
                                      <Link
                                        to={`/ledgers/DRM?transactionType=StoreOrder&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                      >
                                        {item.StoreReturnTotal > 0
                                          ? `${index + 1}.${item.name}(${
                                              item.userId
                                            })`
                                          : "-"}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="widget-list-action text-nowrap text-right">
                                    <NumberFormat
                                      value={item.StoreReturnTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    드림({item.StoreReturnCount}건)
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="vertical-box with-grid inbox">
                        <div className="vertical-box-inner-cell overflow-visible height-md">
                          <p className="text-center">
                            상점환불 데이터가 없습니다.
                          </p>
                        </div>
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="vertical-box with-grid inbox">
                <div className="vertical-box-inner-cell overflow-visible height-420">
                  <PerfectScrollbar
                    className="height-full p-5"
                    options={{ suppressScrollX: true }}
                  >
                    {usersRemittance.filter((item) => item.RemittanceTotal > 0)
                      .length > 0 ? (
                      <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                        <div
                          className="card-body text-right"
                          style={{
                            background: "no-repeat bottom left",
                            backgroundImage: "url(/assets/img/svg/img-4.svg)",
                            backgroundSize: "auto 60%",
                          }}
                        >
                          <div className="m-b-10 m-t-10 f-s-10">
                            <Link
                              className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
                              to={`/statistics/UsersRemittance?startDate=${startDate}&endDate=${endDate}`}
                            >
                              더보기
                            </Link>
                            <h3 style={{ textAlign: "left" }}>송금 TOP 10</h3>
                          </div>
                        </div>
                        <div className="widget-list widget-list-rounded ">
                          {usersRemittance
                            .filter((item) => item.RemittanceTotal > 0)
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="widget-list-item rounded-0 p-t-3"
                                >
                                  <div className="widget-list-content">
                                    <div className="widget-list-title">
                                      <Link
                                        to={`/ledgers/DRM?transactionType=Remittance&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                      >
                                        {item.RemittanceTotal > 0
                                          ? `${index + 1}.${item.name}(${
                                              item.userId
                                            })`
                                          : "-"}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="widget-list-action text-nowrap text-right">
                                    <NumberFormat
                                      value={item.RemittanceTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    드림({item.RemittanceCount}건)
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="vertical-box with-grid inbox">
                        <div className="vertical-box-inner-cell overflow-visible height-md">
                          <p className="text-center">송금 데이터가 없습니다.</p>
                        </div>
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="vertical-box with-grid inbox">
                <div className="vertical-box-inner-cell overflow-visible height-420">
                  <PerfectScrollbar
                    className="height-full p-5"
                    options={{ suppressScrollX: true }}
                  >
                    {usersDonation.filter((item) => item.DonationTotal > 0)
                      .length > 0 ? (
                      <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                        <div
                          className="card-body text-right"
                          style={{
                            background: "no-repeat bottom left",
                            backgroundImage: "url(/assets/img/svg/img-4.svg)",
                            backgroundSize: "auto 60%",
                          }}
                        >
                          <div className="m-b-10 m-t-10 f-s-10">
                            <Link
                              className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
                              to={`/statistics/UsersDonation?startDate=${startDate}&endDate=${endDate}`}
                            >
                              더보기
                            </Link>
                            <h3 style={{ textAlign: "left" }}>기부 TOP 10</h3>
                          </div>
                        </div>
                        <div className="widget-list widget-list-rounded ">
                          {usersDonation
                            .filter((item) => item.DonationTotal > 0)
                            .map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="widget-list-item rounded-0 p-t-3"
                                >
                                  <div className="widget-list-content">
                                    <div className="widget-list-title">
                                      <Link
                                        to={`/ledgers/DRM?transactionType=Donation&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                      >
                                        {item.DonationTotal > 0
                                          ? `${index + 1}.${item.name}(${
                                              item.userId
                                            })`
                                          : "-"}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="widget-list-action text-nowrap text-right">
                                    <NumberFormat
                                      value={item.DonationTotal}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    드림({item.DonationCount}건)
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="vertical-box with-grid inbox">
                        <div className="vertical-box-inner-cell overflow-visible height-md">
                          <p className="text-center">기부 데이터가 없습니다.</p>
                        </div>
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="vertical-box with-grid inbox">
                <div className="vertical-box-inner-cell overflow-visible height-420">
                  <PerfectScrollbar
                    className="height-full p-5"
                    options={{ suppressScrollX: true }}
                  >
                    {usersMinusStoreOrder.length > 0 ? (
                      <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                        <div
                          className="card-body text-right"
                          style={{
                            background: "no-repeat bottom left",
                            backgroundImage: "url(/assets/img/svg/img-4.svg)",
                            backgroundSize: "auto 60%",
                          }}
                        >
                          <div className="m-b-10 m-t-10 f-s-10">
                            <Link
                              className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15"
                              to={`/statistics/UsersMinusStoreOrder?startDate=${startDate}&endDate=${endDate}`}
                            >
                              더보기
                            </Link>
                            <h3 style={{ textAlign: "left" }}>
                              땡겨쓰기(상점결제) TOP 10
                            </h3>
                          </div>
                        </div>
                        <div className="widget-list widget-list-rounded">
                          {usersMinusStoreOrder.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="widget-list-item rounded-0 p-t-3"
                              >
                                <div className="widget-list-content">
                                  <div className="widget-list-title">
                                    <Link
                                      to={`/ledgers/DRM?startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                    >
                                      {`${index + 1}.${item.name}(${
                                        item.userId
                                      })`}
                                    </Link>
                                  </div>
                                </div>
                                <div className="widget-list-action text-nowrap text-right">
                                  <NumberFormat
                                    value={item.MinusStoreOrderTotal}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림({item.MinusStoreOrderCount}건)
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="vertical-box with-grid inbox">
                        <div className="vertical-box-inner-cell overflow-visible height-md">
                          <p className="text-center">
                            땡겨쓰기 데이터가 없습니다.
                          </p>
                        </div>
                      </div>
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {usersRemittance.filter((item) => item.RemittanceTotal > 0).length >
          0 ? (
            <>
              <div className="m-b-10 m-t-10 f-s-10">
                <div className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15">
                  <DownloadButton stores={stores} />
                </div>
                <b className="text-inverse f-s-18">가맹점 현황</b>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                    <div
                      className="card-body text-right"
                      style={{
                        background: "no-repeat bottom left",
                        backgroundImage: "url(/assets/img/svg/img-4.svg)",
                        backgroundSize: "auto 60%",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        <h3>결제금액</h3>
                      </div>
                      <h3 className="m-b-10">
                        <NumberFormat
                          value={PaymentTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림({PaymentCount}건)
                      </h3>
                      <div className="text-grey m-b-1">
                        환전금액 :{" "}
                        <NumberFormat
                          value={ExchangeRequestTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림({ExchangeRequestCount}건)
                      </div>
                    </div>
                    <div className="widget-list widget-list-rounded ">
                      {stores.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="widget-list-item rounded-0 p-t-3"
                          >
                            <div className="widget-list-content">
                              <div className="widget-list-title">
                                {item.name}({item.count}건){" "}
                                <Link
                                  className="btn btn-default btn-xs"
                                  to={`/statistics/StorePayment?id=${item.id}&startDate=${startDate}&endDate=${endDate}&wallet=${item.wallet}`}
                                >
                                  전체
                                </Link>{" "}
                                <Link
                                  className="btn btn-default btn-xs"
                                  to={`/statistics/StorePaymentHour?id=${item.id}&startDate=${startDate}&endDate=${endDate}&wallet=${item.wallet}`}
                                >
                                  시간별
                                </Link>
                              </div>
                            </div>
                            <div className="widget-list-action text-nowrap text-right">
                              <NumberFormat
                                value={item.total}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="vertical-box with-grid inbox ">
              <div className="vertical-box-inner-cell overflow-visible height-sm">
                <p className="text-center">가맹점 데이터가 없습니다.</p>
              </div>
            </div>
          )}{" "}
        </div>
      </>
    );
  }
});
