import { gql } from "apollo-boost";

/* 통계 설정 */
export const STATISTICS_QUERY = gql`
  query IssuerStatistics($startDate: String, $endDate: String) {
    IssuerStatistics(startDate: $startDate, endDate: $endDate) {
      PaymentCount
      PaymentTotal
      RemittanceCount
      RemittanceTotal

      usersStoreOrder {
        wallet
        userId
        name
        StoreOrderCount
        StoreOrderTotal
      }
      usersRemittance {
        wallet
        userId
        name
        RemittanceCount
        RemittanceTotal
      }
      stores {
        wallet
        id
        name
        count
        total
      }
    }
  }
`;
