import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { COUPON_CONFIG_QUERY, COUPON_CONFIG_WRITE_QUERY } from "./Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, isModal }) => {
  const modalRef = React.createRef();

  const [couponConfigWriteMutation] = useMutation(COUPON_CONFIG_WRITE_QUERY, {
    refetchQueries: () => [{ query: COUPON_CONFIG_QUERY }],
  });

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminCouponConfigWrite },
      } = await couponConfigWriteMutation({
        variables: {
          name: data.name,
        },
      });
      if (AdminCouponConfigWrite) {
        toast.success("설정 되었습니다.");
        history.push("/couponConfigs");
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push("/couponConfigs");
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push("/couponConfigs");
            }}
          >
            설정등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요.",
                      })}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="col-xs-12 text-right">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
                <Link to="/couponConfigs" className="btn btn-white text-red">
                  취소
                </Link>
              </div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
