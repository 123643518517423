import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default ({
  history,
  match,
  location,
  isModal,
  data,
  loading,
  onDelete
}) => {
  const modalRef = React.createRef();

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/inquiries${location.search}`
            );
          }}
        >
          분류 목록 ({!loading && data.AdminInquiryCategoryList.count}건)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox bg-light height-sm">
            <div className="vertical-box-column">
              <div className="vertical-box">
                <div className="vertical-box-row bg-white">
                  <div className="vertical-box-cell">
                    <div className="vertical-box-inner-cell">
                      {!loading && data.AdminInquiryCategoryList.count > 0 ? (
                        <PerfectScrollbar
                          className="height-full"
                          options={{ suppressScrollX: true }}
                        >
                          <ListGroup className="list-group-lg no-radius list-email">
                            {data.AdminInquiryCategoryList.inquiryCategories.map(
                              category => {
                                return (
                                  <ListGroupItem
                                    key={category.id}
                                    className="unread"
                                  >
                                    <Link
                                      to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/categories/${category.id}/edit${location.search}`}
                                      className="email-user bg-gradient-blue"
                                    >
                                      <span className="text-white">
                                        <i className={category.pcIcon} />
                                      </span>
                                    </Link>
                                    <div className="email-info">
                                      <Link
                                        to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/categories/${category.id}/edit${location.search}`}
                                      >
                                        <span className="email-title">
                                          {category.name}
                                        </span>
                                        <span className="email-desc">
                                          {category.inquiriesCount}{" "}
                                          {category.commonApp}
                                        </span>
                                        <span className="email-time">
                                          <div className="btn-group">
                                            <Button
                                              type="button"
                                              size="xs"
                                              color="white"
                                              className="text-red"
                                              onClick={() => {
                                                onDelete(category);
                                              }}
                                            >
                                              삭제
                                            </Button>
                                          </div>
                                        </span>
                                      </Link>
                                    </div>
                                  </ListGroupItem>
                                );
                              }
                            )}
                          </ListGroup>
                        </PerfectScrollbar>
                      ) : (
                        <p className="text-center">등록된 데이터가 없습니다.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Link
            to={`/inquiryConfigs/${match.params.CommonApp}/${match.params.inquiryConfig}/categories/write${location.search}`}
            className="btn btn-white"
          >
            추가
          </Link>
        </ModalFooter>
      </Modal>
    </>
  );
};
