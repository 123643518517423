import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_STATISTICS_YEAR_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import NumberFormat from "react-number-format";
import Chart from "react-google-charts";

export default withRouter(({ history, match, location }) => {
  const date = new Date();
  const year = match.params.year
    ? parseInt(match.params.year)
    : date.getFullYear();
  const [monthMoneyTotal, setMonthMoneyTotal] = useState(0);
  const [monthProductCountTotal, setMonthProductCountTotal] = useState(0);
  const [monthCountTotal, setMonthCountTotal] = useState(0);
  const [readyMoneyTotal, setReadyMoneyTotal] = useState(0);
  const [readyCountTotal, setReadyCountTotal] = useState(0);
  const [reservationMoneyTotal, setReservationMoneyTotal] = useState(0);
  const [reservationCountTotal, setReservationCountTotal] = useState(0);
  const [expirationMoneyTotal, setExpirationMoneyTotal] = useState(0);
  const [expirationCountTotal, setExpirationCountTotal] = useState(0);
  const [completionMoneyTotal, setCompletionMoneyTotal] = useState(0);
  const [completionCountTotal, setCompletionCountTotal] = useState(0);
  const [refundMoneyTotal, setRefundMoneyTotal] = useState(0);
  const [refundCountTotal, setRefundCountTotal] = useState(0);
  const [chartData, setChartData] = useState([["", "매출", "건수"]]);

  const { data, loading } = useQuery(TICKET_STATISTICS_YEAR_QUERY, {
    fetchPolicy: "network-only",
    variables: { year: year },
  });

  const currentYear = new Date().getFullYear();
  const selectYears = Array.from(
    new Array(currentYear - 2020),
    (val, index) => index + 2021
  );
  const handleYearSelect = (e) => {
    const year = e.target.value;
    history.push(`/ticketStatistics/year/${year}`);
    setChartData([["", "매출", "건수"]]);
  };

  useEffect(() => {
    if (!loading) {
      let monthMoney = 0;
      let monthProductCount = 0;
      let monthCount = 0;
      let readyMoney = 0;
      let readyCount = 0;
      let reservationMoney = 0;
      let reservationCount = 0;
      let expirationMoney = 0;
      let expirationCount = 0;
      let completionMoney = 0;
      let completionCount = 0;
      let refundMoney = 0;
      let refundCount = 0;
      data.AdminTicketStatisticsYear.statisticsMonths.forEach((item) => {
        let array = chartData;
        array.push([`${item.Month}월`, item.MonthMoney, item.MonthCount]);
        setChartData(array);

        monthMoney += item.MonthMoney;
        setMonthMoneyTotal(monthMoney);
        monthProductCount += item.MonthProductCount;
        setMonthProductCountTotal(monthProductCount);
        monthCount += item.MonthCount;
        setMonthCountTotal(monthCount);

        readyMoney += item.ReadyMoney;
        setReadyMoneyTotal(readyMoney);
        readyCount += item.ReadyCount;
        setReadyCountTotal(readyCount);

        reservationMoney += item.ReservationMoney;
        setReservationMoneyTotal(reservationMoney);
        reservationCount += item.ReservationCount;
        setReservationCountTotal(reservationCount);

        expirationMoney += item.ExpirationMoney;
        setExpirationMoneyTotal(expirationMoney);
        expirationCount += item.ExpirationCount;
        setExpirationCountTotal(expirationCount);

        completionMoney += item.CompletionMoney;
        setCompletionMoneyTotal(completionMoney);
        completionCount += item.CompletionCount;
        setCompletionCountTotal(completionCount);

        refundMoney += item.RefundMoney;
        setRefundMoneyTotal(refundMoney);
        refundCount += item.RefundCount;
        setRefundCountTotal(refundCount);
      });
    }
  }, [data, loading, chartData]);

  return (
    <>
      <Helmet>
        <title>통계 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림상점</li>
          <li className="breadcrumb-item active">통계</li>
        </ol>
        <h1 className="page-header">통계</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>{year}년 매출통계</PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="form-group row m-b-0">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      onChange={handleYearSelect}
                      defaultValue={year}
                    >
                      {selectYears.map((selectYear, index) => {
                        return (
                          <option key={index} value={selectYear}>
                            {selectYear}년
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </Alert>
              <PanelBody>
                {!loading && data.AdminTicketStatisticsYear ? (
                  <>
                    <Chart
                      style={{ width: "100%" }}
                      height={430}
                      chartType="Bar"
                      loader={<div>Loading Chart</div>}
                      data={chartData}
                      options={{
                        bars: "vertical",
                        vAxis: { format: "decimal" },
                        height: 400,
                        legend: { position: "top", maxLines: 3 },
                        bar: { groupWidth: "75%" },
                        isStacked: true,
                        colors: ["#1b9e77", "#7570b3", "#d95f02"],
                      }}
                      legendToggle
                    />
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-top m-b-0">
                        <thead>
                          <tr>
                            <th>기간</th>
                            <th>전체(주문일자 기준)</th>
                            <th>예매대기(주문일자 기준)</th>
                            <th>미 사 용(주문일자 기준)</th>
                            <th>기간만료(주문일자 기준)</th>
                            <th>환불완료(환불일시 기준)</th>
                            <th>사용완료(사용일시 기준)</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">{year}년 전체</td>

                            <td className="text-right">
                              <div>
                                매출 :{" "}
                                <NumberFormat
                                  value={monthMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/ticketOrders?startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  주문 : {monthCountTotal}건
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to={`/ticketOrderProducts?startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  상품 : {monthProductCountTotal}건
                                </Link>
                              </div>
                            </td>

                            <td className="text-right">
                              <div>
                                금액 :{" "}
                                <NumberFormat
                                  value={readyMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/ticketOrderProducts?status=0&startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  상품 :{readyCountTotal}개
                                </Link>
                              </div>
                            </td>

                            <td className="text-right">
                              <div>
                                금액 :{" "}
                                <NumberFormat
                                  value={reservationMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/ticketOrderProducts?status=1&startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  상품 :{reservationCountTotal}개
                                </Link>
                              </div>
                            </td>

                            <td className="text-right">
                              <div>
                                금액 :{" "}
                                <NumberFormat
                                  value={expirationMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/ticketOrderProducts?status=2&startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  상품 :{expirationCountTotal}개
                                </Link>
                              </div>
                            </td>

                            <td className="text-right">
                              <div>
                                금액 :{" "}
                                <NumberFormat
                                  value={refundMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>
                                <Link
                                  to={`/ticketOrderProducts?status=3&startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  상품 : {refundCountTotal}개
                                </Link>
                              </div>
                            </td>

                            <td className="text-right">
                              <div>
                                <NumberFormat
                                  value={completionMoneyTotal * 0.97}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림 -{" "}
                                <NumberFormat
                                  value={completionMoneyTotal * 0.03}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림 ={" "}
                                <NumberFormat
                                  value={completionMoneyTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                드림
                              </div>
                              <div>정산금액 - 수수료(3%) = 전체금액</div>
                              <div>
                                <Link
                                  to={`/ticketOrderProducts?status=4&startSearchDate=${year}-01-01&endSearchDate=${year}-12-31`}
                                >
                                  상품 :{completionCountTotal}개
                                </Link>
                              </div>
                            </td>

                            <td className="text-center">
                              <Link
                                to={`/ticketStatistics/year/${year}/orderRank`}
                                className="btn btn-white btn-xs text-primary"
                              >
                                주문순위
                              </Link>
                              <Link
                                to={`/ticketStatistics/year/${year}/orderProductRank`}
                                className="btn btn-white btn-xs text-primary"
                              >
                                상품순위
                              </Link>
                            </td>
                          </tr>
                          {data.AdminTicketStatisticsYear.statisticsMonths.map(
                            (item, index) => {
                              const endNumber = new Date(
                                year,
                                item.Month,
                                0
                              ).getDate();
                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    {year}년 {item.Month}월
                                  </td>

                                  <td className="text-right">
                                    <div>
                                      매출 :{" "}
                                      <NumberFormat
                                        value={item.MonthMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/ticketOrders?startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        주문 : {item.MonthCount}건
                                      </Link>
                                    </div>
                                    <div>
                                      <Link
                                        to={`/ticketOrderProducts?startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        상품 : {item.MonthProductCount}건
                                      </Link>
                                    </div>
                                  </td>

                                  <td className="text-right">
                                    <div>
                                      금액 :{" "}
                                      <NumberFormat
                                        value={item.ReadyMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/ticketOrderProducts?status=0&startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        상품 : {item.ReadyCount}개
                                      </Link>
                                    </div>
                                  </td>

                                  <td className="text-right">
                                    <div>
                                      금액 :{" "}
                                      <NumberFormat
                                        value={item.ReservationMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/ticketOrderProducts?status=1&startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        상품 : {item.ReservationCount}개
                                      </Link>
                                    </div>
                                  </td>

                                  <td className="text-right">
                                    <div>
                                      금액 :{" "}
                                      <NumberFormat
                                        value={item.ExpirationMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/ticketOrderProducts?status=2&startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        상품 : {item.ExpirationCount}개
                                      </Link>
                                    </div>
                                  </td>

                                  <td className="text-right">
                                    <div>
                                      금액 :{" "}
                                      <NumberFormat
                                        value={item.RefundMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>
                                      <Link
                                        to={`/ticketOrderProducts?status=3&startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        상품 : {item.RefundCount}개
                                      </Link>
                                    </div>
                                  </td>

                                  <td className="text-right">
                                    <div>
                                      <NumberFormat
                                        value={item.CompletionMoney * 0.97}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림 -{" "}
                                      <NumberFormat
                                        value={item.CompletionMoney * 0.03}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림 ={" "}
                                      <NumberFormat
                                        value={item.CompletionMoney}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </div>
                                    <div>정산금액 - 수수료(3%) = 전체금액</div>
                                    <div>
                                      <Link
                                        to={`/ticketOrderProducts?status=4&startSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-01&endSearchDate=${year}-${
                                          item.Month < 10
                                            ? `0${item.Month}`
                                            : item.Month
                                        }-${endNumber}`}
                                      >
                                        상품 : {item.CompletionCount}개
                                      </Link>
                                    </div>
                                  </td>

                                  <td className="text-center">
                                    <Link
                                      to={`/ticketStatistics/year/${year}/month/${item.Month}`}
                                      className="btn btn-white btn-xs text-primary"
                                    >
                                      상세정보
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
});
