import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../../../../../Components/Panel/Panel";
import { graphqlUri } from "../../../../../../Config/Env";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
  STORE_DELIVERY_PRODUCT_CATEGORY_DELETE_MUTATION,
} from "../../Queries";
import Swal from "sweetalert2";

const Presenter = ({ history, match, location, loading, data, baseUrl }) => {
  const [
    StoreDeliveryProductCategoryDeleteMutation,
    { loading: StoreDeliveryProductCategoryDeleteLoading },
  ] = useMutation(STORE_DELIVERY_PRODUCT_CATEGORY_DELETE_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
        variables: { take: 100, skip: 0 },
      },
    ],
  });
  const onDelete = async (id) => {
    try {
      if (StoreDeliveryProductCategoryDeleteLoading) {
        return;
      }

      const {
        data: { StoreDeliveryProductCategoryDelete },
      } = await StoreDeliveryProductCategoryDeleteMutation({
        variables: { id },
      });

      if (StoreDeliveryProductCategoryDelete) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>상품관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">선물</li>
          <li className="breadcrumb-item">상품</li>
          <li className="breadcrumb-item active">분류설정</li>
        </ol>
        <h1 className="page-header">분류설정</h1>

        <div className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link
              to={`/delivery/deliveryProducts${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right"></div>
        </div>

        {!loading ? (
          <Fragment>
            <div className="row">
              <div className="col-xs-12">
                {data.StoreDeliveryProductCategoryList.count > 0 ? (
                  <div className={"widget-list widget-list-rounded m-b-15"}>
                    {data.StoreDeliveryProductCategoryList.deliveryProductCategories.map(
                      (item, index) => {
                        const iconImage =
                          item.iconImage === "" || item.iconImage === null
                            ? "/assets/img/category-default.png"
                            : `${graphqlUri}${item.iconImage}`;
                        return (
                          <Fragment key={index}>
                            <div className="widget-list-item">
                              <div className="widget-list-media">
                                <img
                                  src={iconImage}
                                  className="height-50"
                                  style={{
                                    borderRadius: 10,
                                    overflow: "hidden",
                                  }}
                                  alt={item.name}
                                />
                              </div>
                              <div className="widget-list-content">
                                <h4 className="widget-list-title">
                                  {item.commonStatus === "S" ? "[대기]" : ""}
                                  {item.name}
                                  {/* {item.order} */}
                                </h4>
                                <p className="widget-list-desc">
                                  {item.deliveryProductsCount}건
                                </p>
                              </div>
                              <div
                                className="widget-list-action"
                                style={{ width: "50%" }}
                              >
                                <div className="pull-right">
                                  <Link
                                    className="btn btn-white btn-xs m-l-5"
                                    to={`${baseUrl}/${item.id}/move`}
                                  >
                                    이동
                                  </Link>
                                  <Link
                                    className="btn btn-white btn-xs m-l-5"
                                    to={`${baseUrl}/${item.id}/edit`}
                                  >
                                    수정
                                  </Link>
                                  <button
                                    className={`btn btn-white btn-xs m-l-5`}
                                    disabled={
                                      item.childrenCount > 0 ? true : false
                                    }
                                    onClick={() => {
                                      if (window.confirm("삭제하시겠습니까?")) {
                                        onDelete(item.id);
                                      }
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <Panel>
                    <PanelBody>
                      <p className="text-center">등록된 데이터가 없습니다.</p>
                    </PanelBody>
                  </Panel>
                )}

                <Link
                  to={`${baseUrl}/write`}
                  className="btn btn-default btn-block  m-b-15"
                >
                  <i className="fas fa-plus"></i> 추가
                </Link>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Presenter;
