import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  TICKET_CATEGORY_LIST_QUERY,
  TICKET_CATEGORY_DELETE_QUERY,
} from "../../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(TICKET_CATEGORY_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      ticketConfig: match.params.config,
      parentId: "null",
    },
  });
  const [ticketCategoryDeleteMutation] = useMutation(
    TICKET_CATEGORY_DELETE_QUERY,
    {
      refetchQueries: [
        {
          query: TICKET_CATEGORY_LIST_QUERY,
          variables: {
            ticketConfig: match.params.config,
            parentId: "null",
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminTicketCategoryDelete },
      } = await ticketCategoryDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminTicketCategoryDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/ticketConfigs/${match.params.config}/categories`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <>
      <Presenter
        history={history}
        match={match}
        location={location}
        data={data}
        loading={loading}
        onDelete={onDelete}
      ></Presenter>
    </>
  );
});
