import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  CHARGEREQUESTGRU_LIST_QUERY,
  CHARGEREQUESTGRU_APPROVAL_QUERY,
  CHARGEREQUESTGRU_CANCEL_QUERY,
} from "../Queries";
import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter/List";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  commonStatusName,
  datePlus9,
} from "../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const commonStatus = queryString.commonStatus;
  var page = queryString.page ? queryString.page : 1;
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");

  const { data, loading } = useQuery(CHARGEREQUESTGRU_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      commonStatus,
      startSearchDate,
      endSearchDate,
      keyword,
      skip,
      first,
    },
  });

  const [userListMutation] = useMutation(CHARGEREQUESTGRU_LIST_QUERY);

  const [chargeRequestApproval] = useMutation(CHARGEREQUESTGRU_APPROVAL_QUERY, {
    refetchQueries: () => [
      {
        query: CHARGEREQUESTGRU_LIST_QUERY,
        variables: {
          commonStatus,
          startSearchDate,
          endSearchDate,
          keyword,
          skip,
          first,
        },
      },
    ],
  });
  const [chargeRequestCancel] = useMutation(CHARGEREQUESTGRU_CANCEL_QUERY, {
    refetchQueries: () => [
      {
        query: CHARGEREQUESTGRU_LIST_QUERY,
        variables: {
          commonStatus,
          startSearchDate,
          endSearchDate,
          keyword,
          skip,
          first,
        },
      },
    ],
  });
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/chargeRequestGRUs${baseUrl}page=1`);
  };

  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [["상태", "방법", "이름", "아이디", "금액", "결제일시"]];
      try {
        const {
          data: { AdminChargeRequestGRUList },
        } = await userListMutation({
          variables: {
            commonStatus,
            startSearchDate,
            endSearchDate,
            keyword,
          },
        });
        AdminChargeRequestGRUList.chargeRequestGRUs.forEach(
          (chargeRequestGRU) => {
            data.push([
              `${commonStatusName(chargeRequestGRU.commonStatus)}`,
              chargeRequestGRU.user.name,
              chargeRequestGRU.user.userId,
              chargeRequestGRU.amount,
              `${datePlus9(chargeRequestGRU.createdAt)}`,
            ]);
          }
        );
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };
    return (
      <>
        <button
          className="btn btn-white btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`충전내역.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  const handleApproval = async (e, item) => {
    e.preventDefault();

    try {
      await chargeRequestApproval({
        variables: {
          id: item.id,
        },
      });
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const handleCancel = async (e, item) => {
    e.preventDefault();

    try {
      await chargeRequestCancel({
        variables: {
          id: item.id,
        },
      });
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      commonStatus={commonStatus}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      keyword={keyword}
      keywordInput={keywordInput}
      page={page}
      blockSize={blockSize}
      first={first}
      baseUrl={baseUrl}
      data={data}
      loading={loading}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
      handleSearch={handleSearch}
      DownloadButton={DownloadButton}
      handleApproval={handleApproval}
      handleCancel={handleCancel}
    ></Presenter>
  );
});
