import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  GIFT_PRODUCT_TICKETOPTION_EDIT_QUERY,
  GIFT_PRODUCT_DETAIL_QUERY,
} from "../../../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import { commonStatusArray } from "../../../../../../Components/User/UserNames";

export default ({
  history,
  match,
  location,

  id,
  commonStatus,
  name,

  isModal,
}) => {
  const modalRef = React.createRef();

  const [giftProductOptionEditMutation] = useMutation(
    GIFT_PRODUCT_TICKETOPTION_EDIT_QUERY,
    {
      refetchQueries: () => [
        {
          query: GIFT_PRODUCT_DETAIL_QUERY,
          variables: { id: match.params.id },
        },
      ],
    }
  );

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      commonStatus: commonStatus,
      name: name,
    },
  });

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminGiftProductTicketOptionEdit },
      } = await giftProductOptionEditMutation({
        variables: {
          id: id,
          commonStatus: data.commonStatus,
          name: data.name,
        },
      });
      if (AdminGiftProductTicketOptionEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/giftConfigs/${match.params.config}/products/${match.params.id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
              );
            }}
          >
            상품옵션수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-group">
              <div className=" p-10 ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered tablebasic product_op_table">
                        <caption className="sr-only">옵션명</caption>
                        <thead>
                          <tr>
                            <th
                              abbr="옵션값"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션명
                            </th>
                            <th
                              abbr="옵션상태"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션상태
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="ex) 색상"
                                ref={register({
                                  required: "옵션명을 입력해주세요.",
                                })}
                                defaultValue={watch("name")}
                              />
                            </td>
                            <td>
                              <select
                                className="form-control"
                                name="commonStatus"
                                ref={register({
                                  required: "옵션상태를 입력해주세요.",
                                })}
                              >
                                <option value="">상태</option>
                                {Object.keys(commonStatusArray).map(
                                  (key, index) => (
                                    <option key={index} value={key}>
                                      {commonStatusArray[key]}
                                    </option>
                                  )
                                )}
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {errors.name && (
                      <Fragment>
                        <span>{errors.name.message}</span>
                        <br />
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};
