import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_DATE_WRITE_QUERY,
  TICKET_PRODUCT_DATE_REPETITION_WRITE_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import {
  commonStatusArray,
  dayName,
} from "../../../../../Components/User/UserNames";

import Moment from "moment";
import ReactMoment from "react-moment";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
registerLocale("ko", ko);

export default withRouter(
  ({
    history,
    match,
    location,

    isModal,
  }) => {
    const modalRef = React.createRef();
    const productCommonStatus = match.params.commonStatus;

    const applyStartDateTime = location?.state?.applyStartDateTime;
    const applyEndDateTime = location?.state?.applyEndDateTime;

    const [ticketProductDateWriteMutation] = useMutation(
      TICKET_PRODUCT_DATE_WRITE_QUERY,
      {
        refetchQueries: () => [
          {
            query: TICKET_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );

    const [ticketProductDateRepetitionWriteMutation] = useMutation(
      TICKET_PRODUCT_DATE_REPETITION_WRITE_QUERY,
      {
        refetchQueries: () => [
          {
            query: TICKET_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );

    const [ticketDate, setTicketDate] = useState(new Date());
    const date = new Date();
    const toDay = date.getDay();
    const toHour = date.getHours();
    const [startDate, setStartDate] = useState(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
    const [endDate, setEndDate] = useState(
      new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), 0, 0, 0)
    );
    const [ticketDateTimes, setTicketDateTimes] = useState([
      { ticketDateTime: `${toHour}:00:00`, commonStatus: "C" },
    ]);
    // console.log({ ticketDateTimes });
    const handleTicketDateTimeAdd = () => {
      if (ticketDateTimes.length >= 10) {
        return toast.error("10개 이상 추가 할 수 없습니다.");
      }
      setTicketDateTimes([
        ...ticketDateTimes,
        { ticketDateTime: `${toHour}:00:00`, commonStatus: "C" },
      ]);
    };
    const handleTicketDateTimeRemove = (index) => {
      if (ticketDateTimes.length <= 1) {
        return toast.error("1개 이하로 삭제 할 수 없습니다.");
      }
      const rows = ticketDateTimes.filter((item, idx) => idx !== index);
      setTicketDateTimes(rows);
    };
    const handleTicketDateTimeChange = (e, index) => {
      const { name, value } = e.target;

      const rows = [...ticketDateTimes];
      rows[index][name] = value;
      setTicketDateTimes(rows);
    };

    const { register, handleSubmit, watch } = useForm({
      defaultValues: {
        commonStatus: "C",
        period: "week",
        term: 1,
        dayOption: [String(toDay)],
        monthOption: "ordinal",
      },
    });

    const onSubmit = async (data) => {
      console.log({ data, ticketDateTimes });

      if (data.isRepetition) {
        try {
          if (startDate === "") {
            return toast.error("반복등록 시작일을 입력해주세요.");
          }
          if (endDate === "") {
            return toast.error("반복등록 종료일을 입력해주세요.");
          }

          if (data.term === "") {
            return toast.error("반복등록 간격을 입력해주세요.");
          }

          if (data.period === "week") {
            if (data.dayOption.length === 0) {
              return toast.error("반복등록 요일을 입력해주세요.");
            }
          }

          const {
            data: { AdminTicketProductDateRepetitionWrite },
          } = await ticketProductDateRepetitionWriteMutation({
            variables: {
              ticketProduct: match.params.id,
              commonStatus: data.commonStatus,

              startDate: startDate,
              endDate: endDate,

              period: data.period,
              term: data.term,

              dayOption: data.dayOption,
              monthOption: data.monthOption,

              ticketDateTimes: ticketDateTimes,
            },
          });
          if (AdminTicketProductDateRepetitionWrite) {
            toast.success("처리 되었습니다.");
            history.push(
              `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
            );
          }
        } catch (e) {
          console.log(e);
          var error = e.toString();
          error = error.replace("Error: GraphQL error:", "");
          toast.error(`${error}`);
        }
      } else {
        try {
          if (ticketDate === "") {
            return toast.error("공연일을 입력해주세요.");
          }

          const {
            data: { AdminTicketProductDateWrite },
          } = await ticketProductDateWriteMutation({
            variables: {
              ticketProduct: match.params.id,

              commonStatus: data.commonStatus,
              ticketDate: Moment(ticketDate).format("YYYY-MM-DD"),

              ticketDateTimes: ticketDateTimes,
            },
          });
          if (AdminTicketProductDateWrite) {
            toast.success("처리 되었습니다.");
            history.push(
              `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}${location.search}`
            );
          }
        } catch (e) {
          console.log(e);
          var error = e.toString();
          error = error.replace("Error: GraphQL error:", "");
          toast.error(`${error}`);
        }
      }
    };
    // console.log(errors);

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    const getWeekOfMonth = (date) => {
      let dayCount = 0;
      for (let loop = 1; loop <= date.getDate(); loop++) {
        const loopDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          loop,
          0,
          0,
          0
        );
        if (loopDate.getDay() === date.getDay()) {
          dayCount++;
        }
      }
      return dayCount;

      // console.log(date.getDate(), date.getDay());
      // let adjustedDate = date.getDate() + date.getDay();
      // let prefixes = ["0", "1", "2", "3", "4", "5"];
      // return parseInt(prefixes[0 | (adjustedDate / 7)]) + 1;
    };

    let weekCount = 0;
    let monthCount = 0;
    const monthsCount = (startDate, endDate) => {
      const yearsCount = endDate.getFullYear() - startDate.getFullYear();
      const monthsCount = endDate.getMonth() - startDate.getMonth();

      return yearsCount * 12 + monthsCount + 1;
    };
    const datesCount = (startDate, endDate) => {
      const ms = endDate.getTime() - startDate.getTime();

      return ms / (1000 * 60 * 60 * 24);
    };
    let cardinalSequence = getWeekOfMonth(startDate);
    let cardinaldDay = startDate.getDay();

    return (
      <Fragment>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/${location.search}`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/${location.search}`
                );
              }}
            >
              티켓일정등록
            </ModalHeader>
            <ModalBody className="p-0 form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-2">
                  * 일정(
                  <input
                    type="checkbox"
                    name="isRepetition"
                    value={true}
                    ref={register()}
                  />
                  반복등록)
                </label>

                <div className={`col-md-10 p-0`}>
                  <div
                    className={`form-group row ${
                      watch("isRepetition") === "true" ? "hide" : ""
                    }`}
                  >
                    <label className="col-form-label col-md-2">* 일자</label>
                    <div className={`col-md-10`}>
                      <DatePicker
                        name="ticketDate"
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        selected={ticketDate}
                        onChange={(date) => setTicketDate(date)}
                        locale="ko"
                        minDate={new Date()}
                        renderCustomHeader={({
                          date,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                          decreaseMonth,
                          increaseMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--previous"
                              aria-label="Previous Month"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              Previous Month
                            </button>
                            <div className="month-day">
                              {getYear(date)}년 {getMonth(date) + 1}월
                            </div>
                            <button
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--next"
                              aria-label="Next Month"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              Next Month
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group row ${
                      watch("isRepetition") === "true" ? "" : "hide"
                    }`}
                  >
                    <label className="col-form-label col-md-2">* 시작일</label>
                    <div className={`col-md-4`}>
                      <DatePicker
                        name="startDate"
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        locale="ko"
                        minDate={new Date()}
                        renderCustomHeader={({
                          date,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                          decreaseMonth,
                          increaseMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--previous"
                              aria-label="Previous Month"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              Previous Month
                            </button>
                            <div className="month-day">
                              {getYear(date)}년 {getMonth(date) + 1}월
                            </div>
                            <button
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--next"
                              aria-label="Next Month"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              Next Month
                            </button>
                          </div>
                        )}
                      />
                    </div>
                    <label className="col-form-label col-md-2">* 종료일</label>
                    <div className={`col-md-4`}>
                      <DatePicker
                        name="endDate"
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        locale="ko"
                        minDate={new Date()}
                        renderCustomHeader={({
                          date,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                          decreaseMonth,
                          increaseMonth,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--previous"
                              aria-label="Previous Month"
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              Previous Month
                            </button>
                            <div className="month-day">
                              {getYear(date)}년 {getMonth(date) + 1}월
                            </div>
                            <button
                              type="button"
                              className="react-datepicker__navigation react-datepicker__navigation--next"
                              aria-label="Next Month"
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              Next Month
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group row ${
                      watch("isRepetition") === "true" ? "" : "hide"
                    }`}
                  >
                    <label className="col-form-label col-md-2">* 주기</label>
                    <div className={`col-md-4`}>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="periodWeek"
                          name="period"
                          value={"week"}
                          ref={register()}
                        />
                        <label htmlFor="periodWeek">매주</label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="periodMonth"
                          name="period"
                          value={"month"}
                          ref={register()}
                        />
                        <label htmlFor="periodMonth">매월</label>
                      </div>
                    </div>
                    <label className="col-form-label col-md-2">* 간격</label>
                    <div className={`col-md-4`}>
                      <input
                        type="text"
                        className="form-control"
                        name="term"
                        placeholder="간격"
                        ref={register()}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group row ${
                      watch("isRepetition") === "true" &&
                      watch("period") === "week"
                        ? ""
                        : "hide"
                    }`}
                  >
                    <label className="col-form-label col-md-2">* 요일</label>
                    <div className={`col-md-10`}>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption0"
                          name="dayOption"
                          className="form-check-input"
                          value="0"
                          ref={register()}
                        />
                        <label className="form-check-label" htmlFor="dayOption">
                          일
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption1"
                          name="dayOption"
                          className="form-check-input"
                          value="1"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dayOption1"
                        >
                          월
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption2"
                          name="dayOption"
                          className="form-check-input"
                          value="2"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dayOption2"
                        >
                          화
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption3"
                          name="dayOption"
                          className="form-check-input"
                          value="3"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dayOption3"
                        >
                          수
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption4"
                          name="dayOption"
                          className="form-check-input"
                          value="4"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dayOption4"
                        >
                          목
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption5"
                          name="dayOption"
                          className="form-check-input"
                          value="5"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dayOption5"
                        >
                          금
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="dayOption6"
                          name="dayOption"
                          className="form-check-input"
                          value="6"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="dayOption6"
                        >
                          토
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`form-group row ${
                      watch("isRepetition") === "true" &&
                      watch("period") === "month"
                        ? ""
                        : "hide"
                    }`}
                  >
                    <label className="col-form-label col-md-2">* 기준</label>
                    <div className={`col-md-10`}>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="monthOptionOrdinal"
                          name="monthOption"
                          value={"ordinal"}
                          ref={register()}
                        />
                        <label htmlFor="monthOptionOrdinal">
                          {startDate.getDate()}일
                        </label>
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="monthOptionCardinal"
                          name="monthOption"
                          value={"cardinal"}
                          ref={register()}
                        />
                        <label htmlFor="monthOptionCardinal">
                          {getWeekOfMonth(startDate)}번째{" "}
                          {dayName(startDate.getDay())}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className={`form-group row`}>
                    <label className="col-form-label col-md-2">* 상태</label>
                    <div className={`col-md-4`}>
                      {Object.keys(commonStatusArray)
                        .filter((item) => item !== "D")
                        .map((key, index) => (
                          <div
                            key={index}
                            className="radio radio-css radio-inline"
                          >
                            <input
                              type="radio"
                              id={`commonStatus${index}`}
                              name="commonStatus"
                              value={key}
                              ref={register()}
                            />
                            <label htmlFor={`commonStatus${index}`}>
                              {commonStatusArray[key]}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div
                    className={`form-group row ${
                      watch("isRepetition") === "true" ? "" : "hide"
                    }`}
                  >
                    <div className="col-xs-12">
                      <div className="alert alert-primary m-b-0">
                        <b>
                          <ReactMoment format="YYYY-MM-DD">
                            {startDate}
                          </ReactMoment>
                        </b>
                        부터{" "}
                        <b>
                          <ReactMoment format="YYYY-MM-DD">
                            {endDate}
                          </ReactMoment>
                        </b>
                        까지{" "}
                        <b>
                          {watch("term") > 1
                            ? watch("period") === "week"
                              ? `${watch("term")}주마다`
                              : `${watch("term")}개월마다`
                            : watch("period") === "week"
                            ? "매주"
                            : "매월"}{" "}
                          {watch("period") === "week"
                            ? String(watch("dayOption"))
                                .split(",")
                                .map((item) => {
                                  return dayName(item);
                                })
                                .join(", ")
                            : watch("monthOption") === "ordinal"
                            ? `${startDate.getDate()}일`
                            : `${getWeekOfMonth(startDate)}번째 ${dayName(
                                startDate.getDay()
                              )}`}{" "}
                        </b>
                        반복
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className=" p-10 ">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered tablebasic product_op_table">
                        <caption className="sr-only">
                          티켓시간생성-항목, 시간, 추가금액, 상태, 기능,
                        </caption>
                        <thead>
                          <tr>
                            <th
                              abbr="시간"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              시간
                            </th>
                            <th
                              abbr="상태"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              상태
                            </th>
                            <th
                              abbr="기능"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              기능
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketDateTimes.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="text"
                                    name="ticketDateTime"
                                    className="form-control"
                                    placeholder="시간을 입력해주세요."
                                    defaultValue={
                                      ticketDateTimes[index].ticketDateTime
                                    }
                                    onChange={(e) => {
                                      handleTicketDateTimeChange(e, index);
                                    }}
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="commonStatus"
                                    value={ticketDateTimes[index].commonStatus}
                                    onChange={(e) => {
                                      handleTicketDateTimeChange(e, index);
                                    }}
                                  >
                                    {Object.keys(commonStatusArray)
                                      .filter((item) => item !== "D")
                                      .map((key, index) => (
                                        <option key={index} value={key}>
                                          {commonStatusArray[key]}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleTicketDateTimeRemove(index)
                                    }
                                    className="btn btn-default btn-block"
                                  >
                                    <i className="fas fa-minus"></i> 삭제
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <button
                        type="button"
                        onClick={handleTicketDateTimeAdd}
                        className="btn btn-default btn-block  m-b-15"
                      >
                        <i className="fas fa-plus"></i> 추가
                      </button>
                    </div>
                    <div className="col-sm-12 p-t-10">
                      <div className="text-left text-danger">
                        <strong>
                          <span className="f-s-15">※ 주의사항(필독)</span>
                          <div className="text-left text-danger">
                            * 유효기간
                            <b>
                              (
                              <ReactMoment format="YYYY-MM-DD">
                                {applyStartDateTime}
                              </ReactMoment>{" "}
                              ~
                              <ReactMoment format="YYYY-MM-DD">
                                {applyEndDateTime}
                              </ReactMoment>
                              )
                            </b>{" "}
                            이내로 등록해주세요. 이 외의 일정은 서비스에
                            표시되지 않습니다.
                          </div>
                          <div className="text-left text-danger">
                            * 일정의 시간은 최대 10개까지 등록이 가능합니다.
                          </div>
                        </strong>
                      </div>
                    </div>
                  </div>
                  {/* <div>{datesCount(startDate, endDate)}</div>
                  <div>{startDate.getDate()}</div>
                  <div>{endDate.getDate()}</div>
                  <div>
                    week-------------------------
                    {watch("period") === "week" &&
                      Array.from(Array(datesCount(startDate, endDate))).map(
                        (x, index) => {
                          const loopDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate() + index,
                            0,
                            0,
                            0
                          );
                          if (
                            watch("dayOption").includes(
                              String(loopDate.getDay())
                            ) &&
                            Math.ceil(index / 7) % parseInt(watch("term")) === 0
                          ) {
                            weekCount++;
                            return (
                              <div key={index}>
                                <ReactMoment format="YYYY-MM-DD">
                                  {loopDate}
                                </ReactMoment>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    month-------------------------
                    {watch("period") === "month" &&
                      watch("monthOption") === "ordinal" &&
                      Array.from(Array(monthsCount(startDate, endDate))).map(
                        (x, index) => {
                          const loopDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth() + index,
                            startDate.getDate(),
                            0,
                            0,
                            0
                          );
                          if (index % parseInt(watch("term")) === 0) {
                            return (
                              <div key={index}>
                                <ReactMoment format="YYYY-MM-DD">
                                  {loopDate}
                                </ReactMoment>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    {watch("period") === "month" &&
                      watch("monthOption") === "cardinal" &&
                      Array.from(Array(monthsCount(startDate, endDate))).map(
                        (x, mIndex) => {
                          let dayCount = 0;
                          const loopStartDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth() + mIndex,
                            1,
                            0,
                            0,
                            0
                          );
                          const loopEndDate = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth() + mIndex + 1,
                            0,
                            0,
                            0,
                            0
                          );
                          Array.from(
                            Array(datesCount(loopStartDate, loopEndDate))
                          ).map((x, dIndex) => {
                            const loopDate = new Date(
                              loopStartDate.getFullYear(),
                              loopStartDate.getMonth(),
                              loopStartDate.getDate() + dIndex,
                              0,
                              0,
                              0
                            );
                            if (loopDate.getDay() === date.getDay()) {
                              dayCount++;
                            }
                            if (
                              cardinalSequence === dayCount &&
                              cardinaldDay === loopDate.getDay() &&
                              mIndex % parseInt(watch("term")) === 0
                            ) {
                              console.log(
                                cardinalSequence,
                                dayCount,
                                cardinaldDay,
                                mIndex,
                                loopDate.getDay(),
                                loopDate.toISOString()
                              );
                              return (
                                <div key={dIndex}>
                                  <ReactMoment format="YYYY-MM-DD">
                                    {loopDate}
                                  </ReactMoment>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          });
                        }
                      )}
                  </div> */}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="col-xs-12 text-center">
                <button type="submit" className="btn btn-white text-blue">
                  확인
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </Fragment>
    );
  }
);
