import { gql } from "apollo-boost";

/* 묶음 시작 */
export const STORE_DELIVERY_SET_LIST_QUERY = gql`
  query StoreDeliverySetList($skip: Int!, $take: Int, $keyword: String) {
    StoreDeliverySetList(skip: $skip, take: $take, keyword: $keyword) {
      deliverySets {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        totalPrice
        name
        description

        deliverySetProducts {
          id
          createdAt
          updatedAt

          price
          count
          totalPrice

          deliveryProduct {
            id
            createdAt
            updatedAt

            commonStatus
            timestamp
            coverImage
            price
            name
            description
          }
          deliveryProductOptionDetail1 {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
          deliveryProductOptionDetail2 {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
          deliveryProductOptionDetail3 {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
  }
`;
export const STORE_DELIVERY_SET_WRITE_MUTATION = gql`
  mutation StoreDeliverySetWrite(
    $commonStatus: String!
    $coverImage: FileUpload
    $timestamp: String
    $name: String
    $description: String
  ) {
    StoreDeliverySetWrite(
      commonStatus: $commonStatus
      coverImage: $coverImage
      timestamp: $timestamp
      name: $name
      description: $description
    )
  }
`;
export const STORE_DELIVERY_SET_DETAIL_QUERY = gql`
  query StoreDeliverySetDetail($id: String!) {
    StoreDeliverySetDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      coverImage
      timestamp
      totalPrice
      name
      description

      deliverySetProducts {
        id
        createdAt
        updatedAt

        price
        count
        totalPrice

        deliveryProduct {
          id
          createdAt
          updatedAt

          commonStatus
          timestamp
          coverImage
          price
          name
          description
        }
        deliveryProductOptionDetail1 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail2 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
        deliveryProductOptionDetail3 {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }
    }
  }
`;
export const STORE_DELIVERY_SET_EDIT_MUTATION = gql`
  mutation StoreDeliverySetEdit(
    $id: String!
    $commonStatus: String!
    $name: String
    $description: String
  ) {
    StoreDeliverySetEdit(
      id: $id
      commonStatus: $commonStatus
      name: $name
      description: $description
    )
  }
`;
export const STORE_DELIVERY_SET_COVER_UPLOAD_MUTATION = gql`
  mutation StoreDeliverySetCoverImageUpload(
    $id: String!
    $coverImage: FileUpload!
  ) {
    StoreDeliverySetCoverImageUpload(id: $id, coverImage: $coverImage) {
      id
      coverImage
    }
  }
`;
export const STORE_DELIVERY_SET_COVER_DELETE_MUTATION = gql`
  mutation StoreDeliverySetCoverImageDelete($id: String!) {
    StoreDeliverySetCoverImageDelete(id: $id)
  }
`;
export const STORE_DELIVERY_SET_DELETE_MUTATION = gql`
  mutation StoreDeliverySetDelete($id: String!) {
    StoreDeliverySetDelete(id: $id)
  }
`;
/* 묶음 끝 */

/* 묶음 상품 시작 */
export const STORE_DELIVERY_SET_PRODUCT_WRITE_MUTATION = gql`
  mutation StoreDeliverySetProductWrite(
    $count: String!
    $deliverySet_id: String!
    $deliveryProduct_id: String!
    $deliveryProductOptionDetail1_id: String
    $deliveryProductOptionDetail2_id: String
    $deliveryProductOptionDetail3_id: String
  ) {
    StoreDeliverySetProductWrite(
      count: $count
      deliverySet_id: $deliverySet_id
      deliveryProduct_id: $deliveryProduct_id
      deliveryProductOptionDetail1_id: $deliveryProductOptionDetail1_id
      deliveryProductOptionDetail2_id: $deliveryProductOptionDetail2_id
      deliveryProductOptionDetail3_id: $deliveryProductOptionDetail3_id
    )
  }
`;
export const STORE_DELIVERY_SET_PRODUCT_SELECT_DELETE_MUTATION = gql`
  mutation StoreDeliverySetProductSelectDelete($ids: [String!]) {
    StoreDeliverySetProductSelectDelete(ids: $ids)
  }
`;
export const STORE_DELIVERY_SET_PRODUCT_ALL_DELETE_MUTATION = gql`
  mutation StoreDeliverySetProductAllDelete($deliverySet_id: String!) {
    StoreDeliverySetProductAllDelete(deliverySet_id: $deliverySet_id)
  }
`;
/* 묶음 상품 끝 */

/* 상품 상세 옵션 시작 */
export const STORE_DELIVERY_SET_OPTION_DETAIL_WRITE_MUTATION = gql`
  mutation StoreDeliverySetOptionDetailWrite(
    $deliverySet_id: String!
    $deliverySetOption_id: String!
    $commonStatus: String!
    $price: String!
    $name: String!
  ) {
    StoreDeliverySetOptionDetailWrite(
      deliverySet_id: $deliverySet_id
      deliverySetOption_id: $deliverySetOption_id
      commonStatus: $commonStatus
      price: $price
      name: $name
    )
  }
`;
export const STORE_DELIVERY_SET_OPTION_DETAIL_DETAIL_QUERY = gql`
  query StoreDeliverySetOptionDetailDetail($id: String!) {
    StoreDeliverySetOptionDetailDetail(id: $id) {
      id
      commonStatus
      price
      name
      createdAt
      updatedAt
    }
  }
`;
export const STORE_DELIVERY_SET_OPTION_DETAIL_EDIT_MUTATION = gql`
  mutation StoreDeliverySetOptionDetailEdit(
    $id: String!
    $commonStatus: String!
    $price: String!
    $name: String!
  ) {
    StoreDeliverySetOptionDetailEdit(
      id: $id
      commonStatus: $commonStatus
      price: $price
      name: $name
    )
  }
`;
/* 묶음 상품 끝 */
