import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-apollo-hooks";
import { USER_DETAIL_QUERY, TRUSTLINE_REMITTANCE_QUERY } from "../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { currencyName } from "../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import qs from "qs";
import { toast } from "react-toastify";

import RemittanceUser from "./RemittanceUser";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const { register, handleSubmit, errors } = useForm();
  const [account, setAccount] = useState(null);
  const [trustline, setTrustline] = useState(null);
  const [destinationId, setDestinationId] = useState("");

  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword, page: queryString.page };

  const [remittanceMutation] = useMutation(TRUSTLINE_REMITTANCE_QUERY, {
    refetchQueries: () => [
      { query: USER_DETAIL_QUERY, variables: { id: match.params.id } },
    ],
  });
  const { data, loading } = useQuery(USER_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  //   accountId,  destinationId, currency, value, remittanceType, comment

  const onSubmit = async (data) => {
    if (destinationId === "") {
      toast.error("받는사람을 입력해주세요.");
    }
    // trustline 존재유무
    // 잔액확인
    console.log({ account, data });
    if (trustline.currency === "DRM" && trustline.amount < data.value) {
      throw Error("송금금액이 부족합니다.");
    }
    try {
      const {
        data: { AdminTrustlineRemittance },
      } = await remittanceMutation({
        variables: {
          accountId: account.id,
          destinationId: destinationId,
          currency: trustline.currency,
          value: data.value,
        },
      });
      if (AdminTrustlineRemittance) {
        toast.success("송금 되었습니다.");
        history.replace(
          `/users/${match.params.role}/${match.params.id}/trustlines/${match.params.trustline}?${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  useEffect(() => {
    if (!loading) {
      setAccount(data.AdminUserDetail);
      const trustline = data.AdminUserDetail.trustlines.find((item) => {
        return item.id === match.params.trustline;
      });
      setTrustline(trustline);
    }
  }, [loading, data, match]);
  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  if (!loading) {
    const {
      AdminUserDetail: { name, trustlines },
    } = data;
    const accountTrustline = trustlines.find((item) => {
      return item.id === match.params.trustline;
    });
    return (
      <>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/users/${match.params.role}/${match.params.id}?${qs.stringify({
                ...query,
              })}`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/users/${match.params.role}/${
                    match.params.id
                  }?${qs.stringify({
                    ...query,
                  })}`
                );
              }}
            >
              {name}님 {currencyName(accountTrustline.currency)} 송금
            </ModalHeader>
            <ModalBody>
              <div className="widget widget-stats bg-green">
                <div className="stats-icon">
                  <i className="fa fa-money-bill-alt"></i>
                </div>
                <div className="stats-info">
                  <h4>합계</h4>
                  <p>
                    {
                      <NumberFormat
                        value={accountTrustline.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                    {currencyName(accountTrustline.currency)}
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">받는사람</label>
                <div className="col-md-10">
                  <RemittanceUser
                    history={history}
                    match={match}
                    location={location}
                    destinationId={destinationId}
                    setDestinationId={setDestinationId}
                  />
                  {errors.wallet && <span>{errors.wallet.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">금액</label>
                <div className="col-md-10">
                  <input
                    type="number"
                    name="value"
                    className="form-control"
                    placeholder="금액을 입력해주세요."
                    ref={register({
                      required: "금액을 입력해주세요.",
                    })}
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
              <Link
                to={`/users/${match.params.role}/${match.params.id}/trustlines/${match.params.trustline}?${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
