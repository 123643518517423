import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_DELIVER_LIST_QUERY } from "../Queries";

import qs from "qs";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const deliveryDeliver_id = queryString.deliveryDeliver_id
    ? queryString.deliveryDeliver_id
    : "";
  const page = queryString.page ? +queryString.page : 1;
  const take = 15;
  const baseUrl = "/delivery/deliveryDelivers";

  const { data, error, loading } = useQuery(STORE_DELIVERY_DELIVER_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take,
      skip: (page - 1) * take,
      deliveryDeliver_id,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    var message = error.toString();
    message = message.replace("Error: GraphQL error:", "");
    return <>{message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      deliveryDeliver_id={deliveryDeliver_id}
      page={page}
      take={take}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
