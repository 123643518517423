import { gql } from "apollo-boost";

export const TICKET_PRODUCT_INQUIRY_LIST_QUERY = gql`
  query AdminTicketProductInquiryList(
    $ticketProduct: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminTicketProductInquiryList(
      ticketProduct: $ticketProduct
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketProductInquiries {
        id
        ticketProduct {
          id
          ticketConfig {
            id
          }
          commonStatus

          coverImage

          store {
            id
            storeLogo
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents

          createdAt
          updatedAt
        }
        user {
          id
          userId
          name
        }
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const TICKET_PRODUCT_INQUIRY_DETAIL_QUERY = gql`
  query AdminTicketProductInquiryDetail($id: String!) {
    AdminTicketProductInquiryDetail(id: $id) {
      id
      ticketProduct {
        id
        ticketConfig {
          id
        }
        commonStatus

        coverImage

        store {
          id
          storeLogo
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }

        name
        contents

        createdAt
        updatedAt
      }
      user {
        id
        userId
        name
      }
      commonStatus
      isSecret
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

export const TICKET_PRODUCT_INQUIRY_REPLY_QUERY = gql`
  mutation AdminTicketProductInquiryReply(
    $id: String!
    $answer: String!
    $isPush: Boolean
  ) {
    AdminTicketProductInquiryReply(id: $id, answer: $answer, isPush: $isPush)
  }
`;

export const TICKET_PRODUCT_INQUIRY_SECRET_TOGGLE_QUERY = gql`
  mutation AdminTicketProductInquirySecretToggle($id: String!) {
    AdminTicketProductInquirySecretToggle(id: $id)
  }
`;

export const TICKET_PRODUCT_INQUIRY_DELETE_QUERY = gql`
  mutation AdminTicketProductInquiryDelete($id: String!) {
    AdminTicketProductInquiryDelete(id: $id)
  }
`;
