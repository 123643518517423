import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  commonStatusArray,
  commonStatusName,
  pay_methodArray,
  pay_methodName,
  currencyName,
  pastPeriod,
} from "../../../../../../Components/User/UserNames";
import Moment from "moment";
import NumberFormat from "react-number-format";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "../../../../../../Containers/SelectedPeriod/DatePicker";

const List = ({
  history,
  match,
  location,
  config,
  commonStatus,
  pay_method,
  startSearchDate,
  endSearchDate,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  handleSearch,
  DownloadButton,
  handleApproval,
  handleCancel,
}) => {
  const CommonStatus = () => {
    const urlConfig = (commonStatus) => {
      const uri =
        "?" +
        (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
        (pay_method ? "pay_method=" + pay_method + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");
      return uri;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!commonStatus ? "active" : ""}
    `}
          to={`/chargeRequestConfigs/${
            config.AdminChargeRequestConfig.id
          }/chargeRequests${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>
        {Object.keys(commonStatusArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                commonStatus === item ? "active" : ""
              }
            `}
              to={`/chargeRequestConfigs/${
                config.AdminChargeRequestConfig.id
              }/chargeRequests${urlConfig(item)}`}
            >
              {commonStatusArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };
  const PayMethod = () => {
    const urlConfig = (pay_method) => {
      const uri =
        "?" +
        (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
        (pay_method ? "pay_method=" + pay_method + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");
      return uri;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!pay_method ? "active" : ""}
    `}
          to={`/chargeRequestConfigs/${
            config.AdminChargeRequestConfig.id
          }/chargeRequests${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>
        {Object.keys(pay_methodArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                pay_method === item ? "active" : ""
              }
            `}
              to={`/chargeRequestConfigs/${
                config.AdminChargeRequestConfig.id
              }/chargeRequests${urlConfig(item)}`}
            >
              {pay_methodArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>충전내역 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/chargeRequestConfigs">충전내역</Link>
          </li>
          <li className="breadcrumb-item active">충전내역</li>
        </ol>
        <h1 className="page-header">충전내역</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록(
                {!loading && data.AdminChargeRequestList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xl-12">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                    {/* <div>
                      <DownloadButton />
                    </div> */}
                  </div>
                </div>
                <CommonStatus />
                <PayMethod />
                {/* <div className="btn-group m-b-15 m-r-5">
                  <DownloadButton />
                </div> */}
                <form onSubmit={handleSearch}>
                  <InputGroup>
                    <Input
                      type="text"
                      className="input-white"
                      placeholder="검색어(은행, 계좌번호, 이메일, 이름, 휴대전화) 입력"
                      value={keywordInput.value}
                      onChange={keywordInput.onChange}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color="white">
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                      <Link
                        to={`/chargeRequestConfigs/${config.AdminChargeRequestConfig.id}/edit${location.search}`}
                        className="btn btn-white"
                      >
                        <i className="fa fa-cog fa-fw"></i>
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </form>
              </Alert>
              <PanelBody>
                {!loading && data.AdminChargeRequestList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>계정정보</th>
                            <th>충전정보</th>
                            <th>요청자정보</th>
                            <th>금액 </th>
                            <th>등록일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminChargeRequestList.chargeRequests.map(
                            (chargeRequest) => {
                              return (
                                <tr key={chargeRequest.id}>
                                  <td>
                                    {commonStatusName(
                                      chargeRequest.commonStatus
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      href={`/users/${chargeRequest.user.role}/${chargeRequest.user.id}`}
                                    >
                                      {chargeRequest.user.name}(
                                      {chargeRequest.user.userId})
                                    </a>
                                  </td>
                                  <td>
                                    {pay_methodName(chargeRequest.pay_method)}
                                    <br />
                                    {chargeRequest.pay_method === "vbank" &&
                                      `${chargeRequest.vbank_name}-${chargeRequest.vbank_num}`}
                                  </td>
                                  <td>
                                    {chargeRequest.buyer_name}
                                    <br />
                                    {chargeRequest.buyer_email}
                                    <br />
                                    {chargeRequest.buyer_tel}
                                  </td>
                                  <td>
                                    <NumberFormat
                                      value={chargeRequest.amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    {currencyName(chargeRequest.currency)}
                                  </td>
                                  <td>
                                    <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                      {chargeRequest.createdAt}
                                    </ReactMoment>
                                  </td>
                                  <td align={"center"}>
                                    {chargeRequest.commonStatus === "S" && (
                                      <button
                                        className="btn btn-sm btn-white text-primary"
                                        onClick={(e) =>
                                          handleApproval(e, chargeRequest)
                                        }
                                      >
                                        승인
                                      </button>
                                    )}
                                    {chargeRequest.commonStatus === "C" && (
                                      <button
                                        className="btn btn-sm btn-white text-danger"
                                        onClick={(e) =>
                                          handleCancel(e, chargeRequest)
                                        }
                                      >
                                        취소
                                      </button>
                                    )}
                                    {chargeRequest.commonStatus === "D" && "-"}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="row p-t-15">
                      <div className="col-md-12">
                        <Pagination
                          totalRecord={data.AdminChargeRequestList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/chargeRequestConfigs/${config.AdminChargeRequestConfig.id}/chargeRequests/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};

export default List;
