import React from "react"
import { withRouter } from "react-router-dom"
import { Panel, PanelHeader, PanelBody } from "../../Components/Panel/Panel"
import { graphqlUri } from "../../Config/Env"

import { useMutation } from "react-apollo-hooks"
import {
  ADMIN_DELIVERY_SET_LIST_QUERY,
  ADMIN_DELIVERY_SET_COVER_UPLOAD_MUTATION,
  ADMIN_DELIVERY_SET_COVER_DELETE_MUTATION,
} from "../../Routes/Administrator/DeliverySet/Queries"
import Swal from "sweetalert2"

const CoverImage = withRouter(
  ({ history, match, location, id, coverImage, baseUrl }) => {
    const [AdminDeliverySetCoverImageUploadMutation] = useMutation(
      ADMIN_DELIVERY_SET_COVER_UPLOAD_MUTATION,
      {
        refetchQueries: () => [
          {
            query: ADMIN_DELIVERY_SET_LIST_QUERY,
            variables: { take: 15, skip: 0 },
          },
        ],
      }
    )

    const [AdminDeliverySetCoverImageDeleteMutation] = useMutation(
      ADMIN_DELIVERY_SET_COVER_DELETE_MUTATION,
      {
        refetchQueries: () => [
          {
            query: ADMIN_DELIVERY_SET_LIST_QUERY,
            variables: { take: 15, skip: 0 },
          },
        ],
      }
    )

    const handleCoverImage = async (e) => {
      e.preventDefault()

      const files = e.target.files
      const file = files[0]

      try {
        const {
          data: { AdminDeliverySetCoverImageUpload },
        } = await AdminDeliverySetCoverImageUploadMutation({
          variables: {
            id: id,
            coverImage: file,
          },
        })

        if (AdminDeliverySetCoverImageUpload) {
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}/${id}/edit${location.search}`)
            }
          })
        }
      } catch (e) {
        console.log(e)
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        })
      }
    }
    const onClickCoverImage = () => {
      document.getElementById("coverImage").click()
    }
    const onDeleteCoverImage = async (id) => {
      try {
        const {
          data: { AdminDeliverySetCoverImageDelete },
        } = await AdminDeliverySetCoverImageDeleteMutation({
          variables: {
            id,
          },
        })
        if (AdminDeliverySetCoverImageDelete) {
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}/${id}/edit${location.search}`)
            }
          })
        }
      } catch (e) {
        console.log(e)
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        })
      }
    }

    const cover =
      coverImage === "" || coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${coverImage}`

    return (
      <Panel>
        <PanelHeader noButton>커버 이미지</PanelHeader>
        <PanelBody className="p-0">
          <div className="form-horizontal form-bordered">
            <div className="bg-white rounded">
              <div className="form-group row">
                <div className="col-md-12">
                  <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                    <div className="vertical-box-column valign-middle text-center">
                      <img
                        src={cover}
                        alt="이미지 업로드"
                        className="width-200"
                      />
                    </div>
                  </div>
                  <div className="m-t-10">
                    <input
                      type="file"
                      id="coverImage"
                      onChange={handleCoverImage}
                      className="hide"
                    />
                    <button
                      className="btn btn-primary btn-block"
                      onClick={onClickCoverImage}
                    >
                      이미지 업로드
                    </button>
                    <button
                      type="button"
                      color="white"
                      className="btn btn-white btn-block text-red"
                      onClick={() => {
                        onDeleteCoverImage(id)
                      }}
                    >
                      이미지 삭제
                    </button>
                  </div>
                  <div className="alert alert-primary fade show m-t-10 m-b-0">
                    <b>커버 이미지 사이즈 :</b> 넓이 450px X 높이 630px
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PanelBody>
      </Panel>
    )
  }
)

export default CoverImage
