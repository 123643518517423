import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  BANNER_LIST_QUERY,
  BANNER_PREVMOVE_QUERY,
  BANNER_NEXTMOVE_QUERY,
} from "../Queries";
import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";
import { toast } from "react-toastify";

const Container = withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" + (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading } = useQuery(BANNER_LIST_QUERY, {
    variables: {
      keyword,
      skip,
      first,
    },
  });

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/banners${baseUrl}page=1`);
  };

  const [bannerPrevMoveMutation] = useMutation(BANNER_PREVMOVE_QUERY, {
    refetchQueries: () => [
      {
        query: BANNER_LIST_QUERY,
        variables: {
          keyword: "",
          skip: 0,
          first: 15,
        },
      },
    ],
  });
  const [bannerNextMoveMutation] = useMutation(BANNER_NEXTMOVE_QUERY, {
    refetchQueries: () => [
      {
        query: BANNER_LIST_QUERY,
        variables: {
          keyword: "",
          skip: 0,
          first: 15,
        },
      },
    ],
  });

  const prevMove = async (id) => {
    try {
      const {
        data: { AdminBannerPrevMove },
      } = await bannerPrevMoveMutation({
        variables: {
          id,
        },
      });
      if (AdminBannerPrevMove) {
        toast.success("처리 되었습니다.");
        history.push(`/banners${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  const nextMove = async (id) => {
    try {
      const {
        data: { AdminBannerNextMove },
      } = await bannerNextMoveMutation({
        variables: {
          id,
        },
      });
      if (AdminBannerNextMove) {
        toast.success("처리 되었습니다.");
        history.push(`/banners${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  return (
    <>
      <Presenter
        history={history}
        match={match}
        location={location}
        page={page}
        keyword={keyword}
        keywordInput={keywordInput}
        blockSize={blockSize}
        first={first}
        data={data}
        loading={loading}
        baseUrl={baseUrl}
        handleSearch={handleSearch}
        prevMove={prevMove}
        nextMove={nextMove}
      ></Presenter>
    </>
  );
});

export default Container;
