export const transactionTypeArray = {
  Charge: "충전",
  Remittance: "송금",
  Exchange: "환전",
  StoreOrder: "상점 결제",
  MallOrder: "쇼핑몰 결제",
  Donation: "기부",
  Fee: "회비",
};
export const transactionTypeName = (transactionType) => {
  return transactionTypeArray[transactionType];
};

export const addCurrencyTransactionTypeArray = {
  // StoreOrder: "상점",
  Remittance: "송금",
};
export const addCurrencyTransactionTypeName = (transactionType) => {
  return addCurrencyTransactionTypeArray[transactionType];
};

export const roleArray = {
  User: "사용자",
  Store: "가맹점",
  Issuer: "발행인",
  Administrator: "운영자",
};
export const roleName = (role) => {
  return roleArray[role];
};

export const confirmedName = (confirmed) => {
  const confirmedArray = {
    true: "승인",
    false: "미승인",
  };
  return confirmedArray[confirmed];
};

export const blockedName = (blocked) => {
  const blockedArray = {
    true: "차단",
    false: "미차단",
  };
  return blockedArray[blocked];
};

export const sexName = (sex) => {
  const sexArray = {
    D: "-",
    M: "남성",
    F: "여성",
  };
  return sexArray[sex];
};

export const currencyArray = {
  DRM: {
    display: true,
    name: "드림",
    unit: "드림",
    addCurrency: false,
    startAmount: 0,
  },
  DNT: {
    display: true,
    name: "드림P",
    unit: "P",
    addCurrency: false,
    startAmount: 0,
  },
  GJP: {
    display: true,
    name: "관저페이",
    unit: "관저페이",
    addCurrency: true,
    startAmount: 0,
  },
  TOL: {
    display: true,
    name: "톨",
    unit: "톨",
    addCurrency: true,
    startAmount: 0,
  },
  GRU: {
    display: true,
    name: "그루",
    unit: "그루",
    addCurrency: true,
    startAmount: 0,
  },
  STR: {
    display: true,
    name: "별",
    unit: "별",
    addCurrency: true,
    startAmount: 0,
  },
  NDO: {
    display: true,
    name: "내동",
    unit: "내동",
    addCurrency: true,
    startAmount: 0,
  },
  LCT: {
    display: true,
    name: "레츠톨",
    unit: "레츠톨",
    addCurrency: true,
    startAmount: 0,
  },
  PUM: {
    display: true,
    name: "품",
    unit: "품",
    addCurrency: true,
    startAmount: 0,
  },
  BIT: {
    display: true,
    name: "조각",
    unit: "조각",
    addCurrency: true,
    startAmount: 0,
  },
};
export const currencyName = (currency) => {
  return currencyArray[currency].name;
};

export const commonStatusArray = {
  S: "대기",
  C: "승인",
  D: "삭제",
};
export const commonStatusName = (commonStatus) => {
  return commonStatusArray[commonStatus];
};

export const pay_methodArray = {
  trans: "실시간 계좌이체",
  vbank: "가상계좌",
  cms: "정기충전(CMS)",
  direct: "일반충전",
  gift: "상품권",
};
export const pay_methodName = (pay_method) => {
  return pay_methodArray[pay_method];
};

export const amountArray = {
  // 5000: "5,000원",
  10000: "10,000원",
  20000: "20,000원",
  50000: "50,000원",
  100000: "100,000원",
  150000: "150,000원",
  200000: "200,000원",
};

export const bankNameArray = {
  1: "카카오뱅크",
  2: "국민은행",
  3: "기업은행",
  4: "농협은행",
  5: "신한은행",
  6: "산업은행",
  7: "우리은행",
  8: "한국씨티은행",
  9: "하나은행",
  10: "SC제일은행",
  11: "경남은행",
  12: "광주은행",
  13: "대구은행",
  14: "도이치은행",
  15: "뱅크오프아메리카",
  16: "부산은행",
  17: "산림조합중앙회",
  18: "저축은행",
  19: "새마을금고",
  20: "수협은행",
  21: "신협중앙회",
  22: "우체국",
  23: "전북은행",
  24: "제주은행",
  25: "중국건설은행",
  26: "중국공산은행",
  27: "BNP파리바은행",
  28: "HSBC은행",
  29: "JP모간체이스은행",
  30: "케이뱅크",
  31: "카카오뱅크",
  32: "중국건설",
};

export const bankName = (bankCode) => {
  return bankNameArray[bankCode];
};

export const datePlus9 = (date) => {
  var datePlus9 = new Date(date);
  datePlus9.setHours(datePlus9.getHours() + 9);
  var newDate = datePlus9.toISOString();
  return `${newDate.substring(0, 10)} ${newDate.substring(11, 19)}`;
};
export const orderNumber = (date) => {
  var orderNumber = new Date(date);
  orderNumber.setHours(orderNumber.getHours() + 9);
  var newDate = orderNumber.toISOString();
  return `${newDate.substring(2, 4)}${newDate.substring(
    5,
    7
  )}${newDate.substring(8, 10)}-${newDate.substring(11, 13)}${newDate.substring(
    14,
    16
  )}${newDate.substring(17, 19)}${newDate.substring(20, 23)}`;
};

export const soldOutArray = {
  true: "매진",
  false: "-",
};
export const soldOutName = (value) => {
  return soldOutArray[value];
};

export const pushTypeArray = {
  FCM: "사용자",
  EXPO: "가맹점",
};
export const pushTypeName = (pushType) => {
  return pushTypeArray[pushType];
};

export const deliveryStatusArray = {
  1: "입금대기",
  2: "배송준비중",
  3: "배송중",
  4: "배송완료",
  5: "주문취소",
};
export const deliveryStatusName = (deliveryStatus) => {
  return deliveryStatusArray[deliveryStatus];
};

export const reasonArray = {
  1: "자주 이용하지 않음",
  2: "개인정보 유출이 우려됨",
  3: "서비스 이용이 불편함",
  4: "기타(직접입력)",
};
export const reasonContent = (reason) => {
  return reasonArray[reason];
};

export const dayArray = ["일", "월", "화", "수", "목", "금", "토", "항상"];
export const dayName = (day) => {
  return dayArray[day];
};

export const dayOfWeekShortArray = ["일", "월", "화", "수", "목", "금", "토"];
export const dayOfWeekShortName = (day) => {
  return dayOfWeekShortArray[day];
};

export const dayOfWeekArray = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일",
];
export const dayOfWeekName = (day) => {
  return dayOfWeekArray[day];
};

export const giftOrderProductStatusArray = [
  "사용가능(주문일자 기준)",
  "사용완료(사용일시 기준)",
  "환불완료(환불일시 기준)",
];
export const giftOrderProductStatusName = (status) => {
  return giftOrderProductStatusArray[status];
};

export const ticketOrderProductStatusArray = [
  "예매대기(주문일자 기준)",
  "미 사 용(주문일자 기준)",
  "기간만료(주문일자 기준)",
  "환불완료(환불일시 기준)",
  "사용완료(사용일시 기준)",
];
export const ticketOrderProductStatusName = (status) => {
  return ticketOrderProductStatusArray[status];
};

// 일자 카운터
export const datesCounter = (startDate, endDate) => {
  const oneDatesecond = 1000 * 60 * 60 * 24;
  const ms = endDate.getTime() - startDate.getTime() + oneDatesecond;

  return ms / oneDatesecond;
};

// 구독 배송 타입
export const deliveryBoxTypeObject = { Pickup: "픽업", Delivery: "배달" };
export const deliveryBoxTypeName = (type) => {
  return deliveryBoxTypeObject[type];
};

// 구독 조건 상태
export const deliveryBoxConditionObject = {
  Subscription: "구독",
  None: "조건미달",
};
export const deliveryBoxConditionName = (condition) => {
  return deliveryBoxConditionObject[condition];
};

// 구독 결제 상태
export const deliveryBoxStateObject = {
  Pay: "결제",
  Next: "한주미룸",
};
export const deliveryBoxStateName = (state) => {
  return deliveryBoxStateObject[state];
};

// 구독 상품 상태
export const deliveryBoxProductStateObject = {
  Onetime: "1회 구독",
  Subscription: "정기구독",
};
export const deliveryBoxProductStateName = (state) => {
  return deliveryBoxProductStateObject[state];
};

// 구독 기사 상태
export const deliveryDeliverStateObject = {
  Standby: "대기",
  Approval: "승인",
  Block: "차단",
};
export const deliveryDeliverStateName = (state) => {
  return deliveryDeliverStateObject[state];
};

//구독 배송 상태
export const deliveryOrderStateObject = {
  Ready: "배송대기",
  Delivery: "배송중",
  Completed: "배송완료",
};
export const deliveryOrderStateName = (state) => {
  return deliveryOrderStateObject[state];
};
//구독 배송 상품 상태
export const deliveryOrderProductStateObject = {
  Pay: "결제",
  Refund: "환불",
};
export const deliveryOrderProductStateName = (state) => {
  return deliveryOrderProductStateObject[state];
};

// 행정구역
export const areaArray = [
  {
    name: "대전",
    area: [
      {
        name: "동구",
        area: [
          { name: "원동" },
          { name: "정동" },
          { name: "중동" },
          { name: "소제동" },
          { name: "신안동" },
          { name: "인동" },
          { name: "신흥동" },
          { name: "효동" },
          { name: "천동" },
          { name: "가오동" },
          { name: "판암동" },
          { name: "삼정동" },
          { name: "용운동" },
          { name: "대동" },
          { name: "자양동" },
          { name: "가양동" },
          { name: "용전동" },
          { name: "성남동" },
          { name: "홍도동" },
          { name: "삼성동" },
          { name: "추동" },
          { name: "효평동" },
          { name: "직동" },
          { name: "비룡동" },
          { name: "주산동" },
          { name: "용계동" },
          { name: "마산동" },
          { name: "세천동" },
          { name: "신상동" },
          { name: "신하동" },
          { name: "신촌동" },
          { name: "사성동" },
          { name: "내탑동" },
          { name: "오동" },
          { name: "주촌동" },
          { name: "낭월동" },
          { name: "대별동" },
          { name: "이사동" },
          { name: "대성동" },
          { name: "장척동" },
          { name: "소호동" },
          { name: "구도동" },
          { name: "삼괴동" },
          { name: "상소동" },
          { name: "하소동" },
        ],
      },
      {
        name: "중구",
        area: [
          { name: "은행동" },
          { name: "선화동" },
          { name: "목동" },
          { name: "중촌동" },
          { name: "문창동" },
          { name: "대흥동" },
          { name: "석교동" },
          { name: "호동" },
          { name: "옥계동" },
          { name: "대사동" },
          { name: "부사동" },
          { name: "용두동" },
          { name: "오류동" },
          { name: "태평동" },
          { name: "유천동" },
          { name: "문화동" },
          { name: "산성동" },
          { name: "사정동" },
          { name: "안영동" },
          { name: "구완동" },
          { name: "무수동" },
          { name: "침산동" },
          { name: "목달동" },
          { name: "정생동" },
          { name: "어남동" },
          { name: "금동" },
        ],
      },
      {
        name: "서구",
        area: [
          { name: "복수동" },
          { name: "도마동" },
          { name: "정림동" },
          { name: "괴곡동" },
          { name: "변동" },
          { name: "용문동" },
          { name: "탄방동" },
          { name: "둔산동" },
          { name: "괴정동" },
          { name: "가장동" },
          { name: "내동" },
          { name: "갈마동" },
          { name: "월평동" },
          { name: "만년동" },
          { name: "가수원동" },
          { name: "괴곡동" },
          { name: "도안동" },
          { name: "관저동" },
          { name: "흑석동" },
          { name: "매노동" },
          { name: "산직동" },
          { name: "장안동" },
          { name: "평촌동" },
          { name: "오동" },
          { name: "우명동" },
          { name: "원정동" },
          { name: "용촌동" },
          { name: "봉곡동" },
        ],
      },
      {
        name: "유성구",
        area: [
          { name: "원내동" },
          { name: "대정동" },
          { name: "계산동" },
          { name: "교촌동" },
          { name: "용계동" },
          { name: "학하동" },
          { name: "송정동" },
          { name: "방동" },
          { name: "성북동" },
          { name: "세동" },
          { name: "원신흥동" },
          { name: "상대동" },
          { name: "봉명동" },
          { name: "구암동" },
          { name: "덕명동" },
          { name: "복용동" },
          { name: "장대동" },
          { name: "궁동" },
          { name: "죽동" },
          { name: "어은동" },
          { name: "구성동" },
          { name: "노은동" },
          { name: "지족동" },
          { name: "갑동" },
          { name: "하기동" },
          { name: "외삼동" },
          { name: "안산동" },
          { name: "수남동" },
          { name: "반석동" },
          { name: "신성동" },
          { name: "도룡동" },
          { name: "추목동" },
          { name: "신봉동" },
          { name: "화암동" },
          { name: "가정동" },
          { name: "장동" },
          { name: "자운동" },
          { name: "덕진동" },
          { name: "방현동" },
          { name: "전민동" },
          { name: "문지동" },
          { name: "원촌동" },
          { name: "송강동" },
          { name: "봉산동" },
          { name: "대동" },
          { name: "금탄동" },
          { name: "구룡동" },
          { name: "둔곡동" },
          { name: "금고동" },
          { name: "신동" },
          { name: "관평동" },
          { name: "용산동" },
          { name: "탑립동" },
        ],
      },
      {
        name: "대덕구",
        area: [
          { name: "오정동" },
          { name: "대화동" },
          { name: "읍내동" },
          { name: "연축동" },
          { name: "신대동" },
          { name: "와동" },
          { name: "장동" },
          { name: "비래동" },
          { name: "송촌동" },
          { name: "중리동" },
          { name: "법동" },
          { name: "신탄진동" },
          { name: "용호동" },
          { name: "이현동" },
          { name: "갈전동" },
          { name: "부수동" },
          { name: "황호동" },
          { name: "삼정동" },
          { name: "미호동" },
          { name: "석봉동" },
          { name: "덕암동" },
          { name: "상서동" },
          { name: "평촌동" },
          { name: "목상동" },
          { name: "문평동" },
          { name: "신일동" },
        ],
      },
    ],
  },
];

// 과거기간
export const pastPeriod = [
  { id: "ALL", name: "전체" },
  { id: "1W", name: "1주일" },
  { id: "1M", name: "1개월" },
  { id: "3M", name: "3개월" },
  { id: "6M", name: "6개월" },
];
// 미래기간
export const futurePeriod = [
  { id: "ALL", name: "전체" },
  { id: "Today", name: "오늘" },
  { id: "Tomorrow", name: "내일" },
];
