import { gql } from "apollo-boost";

/* 이벤트 설정 */
export const COUPON_CONFIG_QUERY = gql`
  query AdminCouponConfig($id: String) {
    AdminCouponConfig(id: $id) {
      id
      name
    }
  }
`;

export const COUPON_CONFIG_WRITE_QUERY = gql`
  mutation AdminCouponConfigWrite($name: String!) {
    AdminCouponConfigWrite(name: $name)
  }
`;

export const COUPON_CONFIG_EDIT_QUERY = gql`
  mutation AdminCouponConfigEdit($id: String, $name: String!) {
    AdminCouponConfigEdit(id: $id, name: $name)
  }
`;

/* 이벤트 분류 */
export const COUPON_CATEGORY_LIST_QUERY = gql`
  query AdminCouponCategoryList(
    $couponConfig: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminCouponCategoryList(
      couponConfig: $couponConfig
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      couponCategories {
        id
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const COUPON_CATEGORY_DETAIL_QUERY = gql`
  query AdminCouponCategoryDetail($id: String!) {
    AdminCouponCategoryDetail(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const COUPON_CATEGORY_WRITE_QUERY = gql`
  mutation AdminCouponCategoryWrite($couponConfig: String!, $name: String!) {
    AdminCouponCategoryWrite(couponConfig: $couponConfig, name: $name)
  }
`;

export const COUPON_CATEGORY_EDIT_QUERY = gql`
  mutation AdminCouponCategoryEdit(
    $couponConfig: String
    $id: String!
    $name: String
  ) {
    AdminCouponCategoryEdit(couponConfig: $couponConfig, id: $id, name: $name)
  }
`;

export const COUPON_CATEGORY_DELETE_QUERY = gql`
  mutation AdminCouponCategoryDelete($id: String!) {
    AdminCouponCategoryDelete(id: $id)
  }
`;

/* 이벤트 */
export const COUPON_LIST_QUERY = gql`
  query AdminCouponList(
    $couponConfig: String
    $couponCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminCouponList(
      couponConfig: $couponConfig
      couponCategory: $couponCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      coupons {
        id
        commonStatus
        startDate
        endDate
        title
        contents
        limit
        price
        store {
          id
          name
        }
        productName
        productImage
        stores {
          id
          name
        }
        users {
          id
          name
        }
        couponCategory {
          id
          name
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const COUPON_WRITE_QUERY = gql`
  mutation AdminCouponWrite(
    $commonStatus: String
    $startDate: String
    $endDate: String
    $title: String!
    $contents: String
    $limit: String!
    $price: String!
    $store: String
    $productName: String!
    $productDetail: String!
    $productImage: ProductUpload
    $stores: [String!]
    $isDuplication: Boolean
    # $users: [String!]
    $couponConfig: String
    $couponCategory: String
  ) {
    AdminCouponWrite(
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      title: $title
      contents: $contents
      limit: $limit
      price: $price
      store: $store
      productName: $productName
      productDetail: $productDetail
      productImage: $productImage
      stores: $stores
      isDuplication: $isDuplication
      # users: $users
      couponConfig: $couponConfig
      couponCategory: $couponCategory
    )
  }
`;

export const COUPON_DETAIL_QUERY = gql`
  query AdminCouponDetail($id: String!) {
    AdminCouponDetail(id: $id) {
      id
      commonStatus
      startDate
      endDate
      title
      contents
      limit
      price
      store {
        id
        name
      }
      productName
      productDetail
      productImage
      stores {
        id
        commonStatus
        storeCategory {
          id
          name
        }
        user {
          id
          role
          userId
          name
        }

        storeLogo
        name

        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail

        createdAt
        updatedAt
      }
      isDuplication
      users {
        id
        userId
        name
      }
      couponCategory {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const COUPON_USERLIST_QUERY = gql`
  query AdminCouponUserList($id: String!) {
    AdminCouponUserList(id: $id) {
      users {
        id
        name
        userId
        DISCINT_COUNT
        COUNT
        createdAt
      }
      count
    }
  }
`;

export const COUPON_USER_STOREORDER_QUERY = gql`
  query AdminCouponUserStoreOrderList($id: String!, $user: String!) {
    AdminCouponUserStoreOrderList(id: $id, user: $user) {
      storeOrders {
        id
        commonStatus
        store {
          id
          name
        }

        currency
        value
        createdAt
        updatedAt

        accountUser {
          id
          userId
          name
        }
      }
      count
    }
  }
`;

export const COUPON_EDIT_QUERY = gql`
  mutation AdminCouponEdit(
    $id: String
    $startDate: String
    $endDate: String
    $title: String!
    $contents: String
    $limit: Int!
    $price: Int!
    $store: String
    $isDuplication: Boolean
    $productName: String!
    $productDetail: String!
    $productImage: String
    $stores: [String!]
    $users: [String!]
    $couponConfig: String
    $couponCategory: String
  ) {
    AdminCouponEdit(
      id: $id
      startDate: $startDate
      endDate: $endDate
      title: $title
      contents: $contents
      limit: $limit
      price: $price
      store: $store
      isDuplication: $isDuplication
      productName: $productName
      productDetail: $productDetail
      productImage: $productImage
      stores: $stores
      users: $users
      couponConfig: $couponConfig
      couponCategory: $couponCategory
    )
  }
`;

export const COUPON_EDITDEFAULT_QUERY = gql`
  mutation AdminCouponEditDefault(
    $id: String
    $commonStatus: String
    $startDate: String
    $endDate: String
    $title: String!
    $contents: String
    $limit: String!
    $price: String!
    $store: String
    $isDuplication: Boolean
    $couponCategory: String
  ) {
    AdminCouponEditDefault(
      id: $id
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      title: $title
      contents: $contents
      limit: $limit
      price: $price
      store: $store
      isDuplication: $isDuplication
      couponCategory: $couponCategory
    )
  }
`;

export const COUPON_EDITPRODUCT_QUERY = gql`
  mutation AdminCouponEditProduct(
    $id: String
    $productName: String!
    $productDetail: String!
    $productImage: ProductUpload
  ) {
    AdminCouponEditProduct(
      id: $id
      productName: $productName
      productDetail: $productDetail
      productImage: $productImage
    )
  }
`;

export const COUPON_STORESWRITE_QUERY = gql`
  mutation AdminCouponStoresWrite($id: String!, $stores: [String!]!) {
    AdminCouponStoresWrite(id: $id, stores: $stores)
  }
`;

export const COUPON_STORESDELETE_QUERY = gql`
  mutation AdminCouponStoresDelete($id: String!, $store: String!) {
    AdminCouponStoresDelete(id: $id, store: $store)
  }
`;

export const COUPON_USERSTOGGLE_QUERY = gql`
  mutation AdminCouponUsersToggle($id: String!, $user: String!) {
    AdminCouponUsersToggle(id: $id, user: $user)
  }
`;

export const COUPON_DELETE_QUERY = gql`
  mutation AdminCouponDelete($id: String!) {
    AdminCouponDelete(id: $id)
  }
`;

export const STORE_LIST_QUERY = gql`
  query AdminStoreList(
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminStoreList(
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id
        commonStatus
        storeCategory {
          id
          name
        }
        user {
          id
          role
          userId
          name
        }

        storeLogo
        name

        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail

        createdAt
        updatedAt
      }
      count
    }
  }
`;
