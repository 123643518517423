import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "react-apollo-hooks";
import { TICKET_BANNER_WRITE_QUERY } from "./Queries";
import { useForm } from "react-hook-form";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const [ticketBannerWriteMutation] = useMutation(TICKET_BANNER_WRITE_QUERY);
  const { register, handleSubmit, errors } = useForm();
  const [ticketBannerCoverImage, setTicketBannerCoverImage] = useState(
    "/assets/img/gallery/gallery-default.jpg"
  );
  const handleTicketBannerCoverImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setTicketBannerCoverImage(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImage").click();
  };

  const timestamp = String(new Date().getTime());
  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminTicketBannerWrite },
      } = await ticketBannerWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          coverImage: data.coverImage[0],
          title: data.title,
          timestamp,
        },
      });
      if (AdminTicketBannerWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/ticketBanners${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  return (
    <>
      <Helmet>
        <title>배너 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">배너</li>
          </ol>
          <h1 className="page-header">배너</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/ticketBanners${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader>이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={ticketBannerCoverImage}
                            alt="이미지 업로드"
                            className="height-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImage"
                          name="coverImage"
                          className="hide"
                          onChange={handleTicketBannerCoverImage}
                          ref={register({
                            required: "이미지를 선택해주세요.",
                          })}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          이미지 업로드
                        </button>
                      </div>
                      {errors.coverImage && (
                        <div className="text-danger">
                          {errors.coverImage.message}
                        </div>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b>이미지 사이즈 : </b> 넓이 1200px X 높이 1024px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-9 col-sm-12">
              <Panel>
                <PanelHeader>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-10">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            className="form-control"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            className="form-control"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div>{errors.commonStatus.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="title"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <span>{errors.title.message}</span>}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/ticketBanners${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
