import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { USER_LIST_QUERY } from "../../Queries";
import AsyncSelect from "react-select/async";

export default withRouter(
  ({ history, match, location, userId, setUserId, isModal }) => {
    const [keyword, setKeyword] = useState("");
    const { data, loading } = useQuery(USER_LIST_QUERY, {
      variables: { keyword, skip: 0, first: 10 },
    });

    const loadOptions = (inputValue, callback) => {
      if (!loading) {
        var options = data.AdminUserList.users.map((user) => {
          return {
            value: user.id,
            label: `${user.commonStatus === "D" ? "[탈퇴] " : ""}${user.name}(${
              user.userId
            })`,
          };
        });
        callback(options);
      }
    };
    const handleInputChange = (value) => {
      setTimeout(() => {
        setKeyword(value);
        return value;
      }, 500);
    };

    return (
      <AsyncSelect
        name="wallet"
        placeholder="수신자 이름 입력"
        noOptionsMessage={() => {
          return "수신자 이름을 검색해주세요.";
        }}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={({ label, value }) => {
          console.log({ label, value });
          setUserId(value);
        }}
        defaultValue=""
      />
    );
  }
);
