import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_CONFIG_LIST_QUERY } from "../../Queries";
import InitPresenter from "../List/Presenter/Init";
import Container from "./Container";

export default withRouter(({ history, match }) => {
  const { data, loading } = useQuery(GIFT_CONFIG_LIST_QUERY);

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  if (!loading) {
    if (data.AdminGiftConfigList.count > 0) {
      // const configs = data.AdminGiftConfigList.giftConfigs;
      const config =
        match.params && match.params.config
          ? data.AdminGiftConfigList.giftConfigs.find(
              (item) => item.id === match.params.config
            )
          : data.AdminGiftConfigList.giftConfigs[0];
      return (
        <>
          <Container config={config}></Container>
        </>
      );
    } else {
      return (
        <>
          <InitPresenter></InitPresenter>
        </>
      );
    }
  }
});
