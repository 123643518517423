import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_PRODUCT_PRODUCTOPTIONDETAIL_DETAIL_QUERY } from "../../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(
    GIFT_PRODUCT_PRODUCTOPTIONDETAIL_DETAIL_QUERY,
    {
      variables: { id: match.params.detail },
    }
  );

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (
    !loading &&
    data &&
    data.AdminGiftProductProductOptionDetailDetail
  ) {
    const {
      AdminGiftProductProductOptionDetailDetail: {
        id,
        commonStatus,
        isSoldOut,
        price,
        name,
      },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        commonStatus={commonStatus}
        isSoldOut={isSoldOut}
        price={price}
        name={name}
      />
    );
  }
});
