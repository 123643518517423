import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { PUSH_DETAIL_QUERY, PUSH_DELETE_QUERY } from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import Moment from "react-moment";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(PUSH_DETAIL_QUERY, {
    variables: { id: id },
  });

  const [pushDeleteMutation] = useMutation(PUSH_DELETE_QUERY);
  const onDelete = async () => {
    try {
      const {
        data: { AdminPushDelete },
      } = await pushDeleteMutation({
        variables: {
          id: id,
        },
      });
      if (AdminPushDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/pushes${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (!loading) {
    const {
      AdminPushDetail: {
        notificationTitle,
        notificationBody,
        pushDetails,

        totalCount,
        readCount,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>알림 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">알림</li>
          </ol>
          <h1 className="page-header">알림</h1>

          <div key="topButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link to={`/pushes${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <Button
                type="button"
                color="white"
                className="text-red"
                onClick={() => {
                  onDelete();
                }}
              >
                삭제
              </Button>
            </div>
          </div>

          <div key="storeInfo" className="row">
            <div className="col-sm-4">
              <Panel>
                <PanelHeader noButton={true}>알림정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">제목</label>
                      <div className="col-md-9">{notificationTitle}</div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">내용</label>
                      <div className="col-md-9">{notificationBody}</div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-sm-8">
              <Panel>
                <PanelHeader noButton={true}>
                  수신자 정보(전체:{totalCount}건 / 읽음:{readCount}건)
                </PanelHeader>
                <PanelBody className="p-0">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>이름(아이디)</th>
                          <th>읽음</th>
                          <th>수신일자</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pushDetails.map((detail) => {
                          return (
                            <tr key={detail.id}>
                              <td>
                                {detail.user.name}({detail.user.userId})
                              </td>
                              <td>
                                {detail.isRead ? (
                                  <i className="fa fa-check"></i>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {detail.createdAt === detail.updatedAt ? (
                                  "-"
                                ) : (
                                  <Moment format="YYYY-MM-DD HH:mm:ss">
                                    {detail.updatedAt}
                                  </Moment>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div key="bottomButton" className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link to={`/pushes${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <Button
                type="button"
                color="white"
                className="text-red"
                onClick={() => {
                  onDelete();
                }}
              >
                삭제
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
