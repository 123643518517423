import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import {
  dayOfWeekArray,
  dayOfWeekName,
} from "../../../../../Components/User/UserNames";

export default ({
  history,
  match,
  location,
  dayOfWeek,
  keyword,
  page,
  take,
  loading,
  data,
  baseUrl,
}) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      keyword,
    },
  });

  const onSubmit = async (data) => {
    const baseUrl =
      "?" +
      (dayOfWeek ? "dayOfWeek=" + dayOfWeek + "&" : "") +
      (data.keyword ? "keyword=" + data.keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onChange = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (dayOfWeek ? "dayOfWeek=" + dayOfWeek + "&" : "") +
      (keyword ? "keyword=" + keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onClick = (e) => {
    e.preventDefault();

    const baseUrl =
      "?" +
      (e.target.value ? "dayOfWeek=" + e.target.value + "&" : "") +
      (keyword ? "keyword=" + keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const defaultUrl =
    baseUrl +
    "?" +
    (dayOfWeek ? `dayOfWeek=${dayOfWeek}&` : ``) +
    (keyword ? `keyword=${keyword}&` : ``);

  return (
    <div>
      <Helmet>
        <title>픽업장소 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/delivery/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">매장</li>
      </ol>
      <h1 className="page-header">매장</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({!loading && data.StoreDeliveryPlaceList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <div className="btn-group">
                    <button
                      className={`btn btn-white ${
                        dayOfWeek === "" ? "active" : ""
                      } `}
                      value=""
                      onClick={onClick}
                    >
                      전체
                    </button>
                    {dayOfWeekArray.map((day, index) => (
                      <button
                        key={index}
                        className={`btn btn-white ${
                          index === parseInt(dayOfWeek) ? "active" : ""
                        } `}
                        value={index}
                        onClick={onClick}
                      >
                        {day}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="keyword"
                        render={({ onChange, onBlur, value }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어(장소명) 입력"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <Button type="submit" size="sm" color="white">
                          <i className="fa fa-search fa-fw"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </form>
                </div>
              </div>
            </Alert>
            {!loading ? (
              <>
                {data.StoreDeliveryPlaceList.count > 0 ? (
                  <>
                    <PanelBody>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                          <colgroup>
                            <col width="*" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="10%" />
                            <col width="10%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-nowrap text-center">
                                장소정보
                              </th>
                              <th className="text-nowrap text-center">
                                담당자 정보
                              </th>
                              <th className="text-nowrap text-center">
                                배송정보
                              </th>
                              <th className="text-nowrap text-center">
                                신청자
                              </th>
                              <th className="text-nowrap text-center">기능</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.StoreDeliveryPlaceList.deliveryPlaces.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="text-nowrap">
                                        장소명 : {item.name}
                                      </div>
                                      <div className="text-nowrap">
                                        도로명주소 : {item.roadAddress}
                                      </div>
                                      <div className="text-nowrap">
                                        지번주소 : {item.jibunAddress}
                                      </div>
                                      <div className="text-nowrap">
                                        상세주소 :{item.addressDetail}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-nowrap">
                                        담당자 : {item.managerName}
                                      </div>
                                      <div className="text-nowrap">
                                        연락처 : {item.managerPhone}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-nowrap">
                                        매장명 : {item.deliveryStore.store.name}{" "}
                                        &gt; {item.deliveryStore.user.name}
                                      </div>
                                      <div className="text-nowrap">
                                        픽업요일 :{" "}
                                        {dayOfWeekName(item.dayOfWeek)}
                                      </div>
                                    </td>
                                    <td className="text-nowrap text-center">
                                      {item.deliveryBoxesCount}명
                                    </td>
                                    <td className="text-nowrap text-center">
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/delivery/deliveryBoxes?type=Pickup&deliveryPlace_id=${item.id}`}
                                        >
                                          구독현황
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                    <PanelFooter>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.StoreDeliveryPlaceList.count}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={defaultUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </PanelFooter>
                  </>
                ) : (
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                )}
              </>
            ) : (
              <></>
            )}
          </Panel>
        </div>
      </div>
      <Link
        to={`${baseUrl}/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </div>
  );
};
