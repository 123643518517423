import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";
import qs from "qs";

import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_BOX_DAYOFWEEK_QUERY } from "../../DeliveryBox/Queries";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const deliveryStore_id = queryString.deliveryStore_id
    ? queryString.deliveryStore_id
    : "";
  const baseUrl = "/DeliveryBoxDayOfWeek";

  const { data, loading, error } = useQuery(
    ADMIN_DELIVERY_BOX_DAYOFWEEK_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { deliveryStore_id },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      deliveryStore_id={deliveryStore_id}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
