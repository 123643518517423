import { gql } from "apollo-boost";

export const GIFT_PRODUCT_REVIEW_LIST_QUERY = gql`
  query AdminGiftProductReviewList(
    $giftProduct: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminGiftProductReviewList(
      giftProduct: $giftProduct
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      giftProductReviews {
        id
        giftProduct {
          id
          # giftCategory {
          #   id
          #   name
          # }
          store {
            id
            storeLogo
            name
          }
          commonStatus
          productType
          coverImage
          isSoldOut
          isDiscount
          discountPrice
          price
          name
          contents
          createdAt
          updatedAt
        }
        giftOrderProduct {
          id
          commonStatus

          isDiscount
          discountPrice
          price
          giftProductOptionDetail1 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail2 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail3 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail4 {
            id
            commonStatus
            isSoldOut
            name
            price
          }
          giftProductOptionDetail5 {
            id
            commonStatus
            isSoldOut
            name
            price
          }

          totalPrice

          startDate
          endDate
          extensionCount

          isRefund
          meRefund

          createdAt
          updatedAt
        }
        user {
          id
          userId
          name
        }
        commonStatus
        isSecret
        score
        contents
        isComment
        comment
        commentDate
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GIFT_PRODUCT_REVIEW_DETAIL_QUERY = gql`
  query AdminGiftProductReviewDetail($id: String!) {
    AdminGiftProductReviewDetail(id: $id) {
      id
      giftProduct {
        id
        giftConfig {
          id
        }
        # giftCategory {
        #   id
        #   name
        # }
        store {
          id
          storeLogo
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        createdAt
        updatedAt
      }
      giftOrderProduct {
        id
        commonStatus

        isDiscount
        discountPrice
        price
        giftProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        giftProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }

        totalPrice

        startDate
        endDate
        extensionCount

        isRefund
        meRefund

        createdAt
        updatedAt
      }
      user {
        id
        userId
        name
      }
      commonStatus
      isSecret
      score
      contents
      isComment
      comment
      commentDate
      createdAt
      updatedAt
    }
  }
`;

export const GIET_PRODUCT_REVIEW_COMMENT_QUERY = gql`
  mutation AdminGiftProductReviewComment(
    $id: String!
    $comment: String!
    $isPush: Boolean
  ) {
    AdminGiftProductReviewComment(id: $id, comment: $comment, isPush: $isPush)
  }
`;

export const GIET_PRODUCT_REVIEW_SECRET_TOGGLE_QUERY = gql`
  mutation AdminGiftProductReviewSecretToggle($id: String!) {
    AdminGiftProductReviewSecretToggle(id: $id)
  }
`;

export const GIET_PRODUCT_REVIEW_DELETE_QUERY = gql`
  mutation AdminGiftProductReviewDelete($id: String!) {
    AdminGiftProductReviewDelete(id: $id)
  }
`;
