import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_PRODUCT_DETAIL_QUERY } from "../../Queries";
import Presenter from "./presenter";

export default withRouter(({ history, match, location, config }) => {
  const { data, loading } = useQuery(GIFT_PRODUCT_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  if (!loading) {
    console.log({ data });
    const {
      AdminGiftProductDetail: {
        id,
        giftCategory,
        store,
        commonStatus,
        productType,
        isSoldOut,
        isDiscount,
        discountPrice,
        price,
        name,

        contents,
        timestamp,

        limit,
        schedule,
        place,

        isBest,
        isNew,

        giftProductOptionsCount,
      },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        config={config}
        id={id}
        giftCategoryId={giftCategory.id}
        giftCategoryName={
          giftCategory.parentCategory
            ? `${giftCategory.parentCategory.name} > ${giftCategory.name}`
            : `${giftCategory.name}`
        }
        storeId={store.id}
        storeName={store.name}
        commonStatus={commonStatus}
        productType={productType}
        isSoldOut={isSoldOut}
        isDiscount={isDiscount}
        discountPrice={discountPrice}
        price={price}
        name={name}
        contents={contents}
        timestamp={timestamp}
        limit={limit}
        schedule={schedule}
        place={place}
        isBest={isBest}
        isNew={isNew}
        giftProductOptionsCount={giftProductOptionsCount}
      />
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
