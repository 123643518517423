import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  currencyName,
  commonStatusName,
  bankNameArray,
  bankName,
  pastPeriod,
} from "../../../../../../Components/User/UserNames";
import Moment from "moment";
import NumberFormat from "react-number-format";
import DatePicker from "../../../../../../Containers/SelectedPeriod/DatePicker";

export default ({
  history,
  match,
  location,
  config,
  currency,
  commonStatus,
  startSearchDate,
  endSearchDate,
  bank_name,
  keyword,
  keywordInput,
  page,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  handleSearch,
  DownloadButton,
  handleApproval,
  onDelete,
}) => {
  const Currency = () => {
    const currencyArray = {
      DRM: {
        name: "드림",
        unit: "드림",
      },
      GRU: {
        name: "그루",
        unit: "그루",
      },
    };
    const urlConfig = (currency) => {
      const ledgersUrl =
        "?" +
        (currency ? "currency=" + currency + "&" : "") +
        (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (bank_name ? "bank_name=" + bank_name + "&" : "");
      return ledgersUrl;
    };

    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!currency ? "active" : ""}
      `}
          to={`/exchangeRequestConfigs${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>

        {Object.keys(currencyArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                currency === item ? "active" : ""
              }
            `}
              to={`/exchangeRequestConfigs${urlConfig(item)}`}
            >
              {currencyArray[item].name}
            </Link>
          );
        })}
      </div>
    );
  };
  const CommonStatus = () => {
    const commonStatusArray = {
      S: "대기",
      C: "승인",
    };
    const urlConfig = (commonStatus) => {
      const uri =
        "?" +
        (currency ? "currency=" + currency + "&" : "") +
        (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (bank_name ? "bank_name=" + bank_name + "&" : "");
      return uri;
    };
    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!commonStatus ? "active" : ""}
    `}
          to={`/exchangeRequestConfigs${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>
        {Object.keys(commonStatusArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                commonStatus === item ? "active" : ""
              }
            `}
              to={`/exchangeRequestConfigs${urlConfig(item)}`}
            >
              {commonStatusArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };
  const BankName = () => {
    const onChange = (event) => {
      const uri =
        "?" +
        (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (event.target.value ? "bank_name=" + event.target.value + "&" : "");

      history.push(`/exchangeRequestConfigs${uri}page=1`);
    };

    return (
      <select
        name="bank_name"
        className="form-control"
        onChange={onChange}
        value={bank_name}
      >
        <option value="">은행 </option>
        {Object.keys(bankNameArray).map((item, index) => {
          return (
            <option key={index} value={String(item)}>
              {bankNameArray[item]}
            </option>
          );
        })}
      </select>
    );
  };
  return (
    <>
      <Helmet>
        <title>정산내역 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home {bank_name}</Link>
          </li>
          <li className="breadcrumb-item active">정산내역</li>
        </ol>
        <h1 className="page-header">정산내역</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록(
                {!loading && data.AdminExchangeRequestList.count}건 /{" "}
                <NumberFormat
                  value={!loading && data.AdminExchangeRequestList.total}
                  displayType={"text"}
                  thousandSeparator={true}
                />
                원)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xl-12">
                    <DatePicker
                      startDate={new Date(startSearchDate)}
                      endDate={new Date(endSearchDate)}
                      onStartDateSelect={onStartDateSelect}
                      onEndDateSelect={onEndDateSelect}
                      onPeriodConfig={onPeriodConfig}
                      period={pastPeriod}
                    />
                  </div>
                </div>
                <Currency />
                <CommonStatus />
                <div className="btn-group m-b-15 m-r-5">
                  <DownloadButton />
                </div>
                <form onSubmit={handleSearch}>
                  <div className="row">
                    <div className="col-sm-3">
                      <BankName />
                    </div>
                    <div className="col-sm-9">
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(계좌번호, 예금주) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                          <Link
                            to={`/exchangeRequestConfigs/${config.AdminExchangeRequestConfig.id}/edit${location.search}`}
                            className="btn btn-white"
                          >
                            <i className="fa fa-cog fa-fw"></i>
                          </Link>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                  </div>
                </form>
              </Alert>
              <PanelBody>
                {!loading && data.AdminExchangeRequestList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>화폐</th>
                            <th>상태</th>
                            <th>상호명 - 이름(아이디)</th>
                            <th>입금정보</th>
                            <th>금액</th>
                            <th>등록일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminExchangeRequestList.exchangeRequests.map(
                            (exchangeRequest) => {
                              return (
                                <tr key={exchangeRequest.id}>
                                  <td>
                                    {currencyName(exchangeRequest.currency)}
                                  </td>
                                  <td>
                                    {commonStatusName(
                                      exchangeRequest.commonStatus
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      href={`/users/${exchangeRequest.user.role}/${exchangeRequest.user.id}`}
                                    >
                                      {exchangeRequest.user.store.name} -{" "}
                                      {exchangeRequest.user.name}(
                                      {exchangeRequest.user.userId})
                                    </a>
                                  </td>
                                  <td>
                                    은행 : {bankName(exchangeRequest.bank_name)}
                                    <br />
                                    계좌번호 : {exchangeRequest.bank_num}
                                    <br />
                                    예금주 :{" "}
                                    {exchangeRequest.bank_account_holder}
                                    <br />
                                  </td>
                                  <td align="right">
                                    요청금액 :
                                    <NumberFormat
                                      value={exchangeRequest.amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    원
                                    <br />
                                    운영비 : -
                                    <NumberFormat
                                      value={Math.ceil(
                                        exchangeRequest.amount * 0.01
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    원
                                    <br />
                                    기부금 : -
                                    <NumberFormat
                                      value={Math.ceil(
                                        exchangeRequest.amount * 0.02
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    원
                                    <br />
                                    합계 :
                                    <NumberFormat
                                      value={Math.floor(
                                        exchangeRequest.amount -
                                          exchangeRequest.amount * 0.03
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    원
                                    <br />
                                  </td>
                                  <td>
                                    <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                      {exchangeRequest.createdAt}
                                    </ReactMoment>
                                  </td>
                                  <td align="center">
                                    {exchangeRequest.commonStatus === "S" && (
                                      <>
                                        <button
                                          className="btn btn-white btn-xs text-red"
                                          onClick={(e) =>
                                            handleApproval(e, exchangeRequest)
                                          }
                                        >
                                          승인
                                        </button>
                                        <button
                                          className="btn btn-white btn-xs text-red"
                                          onClick={(e) =>
                                            onDelete(e, exchangeRequest)
                                          }
                                        >
                                          삭제
                                        </button>
                                      </>
                                    )}
                                    {exchangeRequest.commonStatus === "C" && (
                                      <button className="btn btn-white btn-xs text-green disabled">
                                        완료
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="row p-t-15">
                      <div className="col-md-6 text-left">
                        <Pagination
                          totalRecord={data.AdminExchangeRequestList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6 text-right f-s-20">
                        합계 :{" "}
                        <NumberFormat
                          value={data.AdminExchangeRequestList.total}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        원
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
