import { gql } from "apollo-boost";

export const WITHDRAWAL_LIST_QUERY = gql`
  query AdminWithdrawalList(
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminWithdrawalList(
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      withdrawals {
        id
        commonStatus
        user {
          id
          commonStatus
          role
          confirmed
          blocked
          userId
          email
          phone
          name
          zipcode
          roadAddress
          jibunAddress
          addressDetail
          trustlines {
            id
            commonStatus
            currency
            amount
            createdAt
            updatedAt
          }

          createdAt
          updatedAt
        }

        userId
        email
        phone

        bank_name
        bank_num
        bank_account_holder

        reason
        memo

        createdAt
        updatedAt
      }

      count
    }
  }
`;

export const WITHDRAWAL_CANCEL_QUERY = gql`
  mutation AdminWithdrawalCancel($id: String!) {
    AdminWithdrawalCancel(id: $id)
  }
`;

export const WITHDRAWAL_EDIT_COMMONSTATUS_QUERY = gql`
  mutation AdminWithdrawalEditCommonStatus(
    $id: String!
    $commonStatus: String!
  ) {
    AdminWithdrawalEditCommonStatus(id: $id, commonStatus: $commonStatus)
  }
`;
