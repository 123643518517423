import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { FEEHASUSER_DETAIL_LIST_QUERY } from "../../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  // const { data: detail } = useQuery(DONATION_DETAIL_QUERY, {
  //   fetchPolicy: "network-only",
  //   variables: { id: match.params.id },
  // });

  const { data, loading } = useQuery(FEEHASUSER_DETAIL_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      feeHasUser_id: match.params.detail,
      skip: 0,
      take: 300,
    },
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
    />
  );
});
