import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_REVIEW_DETAIL_QUERY,
  TICKET_PRODUCT_REVIEW_SECRET_TOGGLE_QUERY,
  TICKET_PRODUCT_REVIEW_DELETE_QUERY,
  TICKET_PRODUCT_REVIEW_LIST_QUERY,
  TICKET_PRODUCT_REVIEW_COMMENT_QUERY,
} from "./Queries";

import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { graphqlUri } from "../../../Config/Env";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import renderHTML from "react-render-html";

export default withRouter(({ history, match, location }) => {
  const { register, handleSubmit, errors } = useForm();
  const id = match.params.id;

  const { data, loading } = useQuery(TICKET_PRODUCT_REVIEW_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id },
  });

  const [ticketProductReviewSecretToggleMutation] = useMutation(
    TICKET_PRODUCT_REVIEW_SECRET_TOGGLE_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_REVIEW_DETAIL_QUERY,
          variables: { id },
        },
      ],
    }
  );
  const [ticketProductReviewDeleteMutation] = useMutation(
    TICKET_PRODUCT_REVIEW_DELETE_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_REVIEW_LIST_QUERY,
          variables: {
            keyword: "",
            skip: 0,
            first: 15,
          },
        },
      ],
    }
  );
  const [ticketProductReviewCommentMutation] = useMutation(
    TICKET_PRODUCT_REVIEW_COMMENT_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_REVIEW_DETAIL_QUERY,
          variables: { id },
        },
      ],
    }
  );

  const onSecretoggle = async (id) => {
    console.log(id);
    try {
      const {
        data: { AdminTicketProductReviewSecretToggle },
      } = await ticketProductReviewSecretToggleMutation({
        variables: {
          id,
        },
      });
      if (AdminTicketProductReviewSecretToggle) {
        toast.success("처리 되었습니다.");
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  const onDelete = async (id) => {
    console.log(id);

    if (!window.confirm("삭제 하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminTicketProductReviewDelete },
      } = await ticketProductReviewDeleteMutation({
        variables: {
          id,
        },
      });
      if (AdminTicketProductReviewDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/ticketProductReviews`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminTicketProductReviewComment },
      } = await ticketProductReviewCommentMutation({
        variables: {
          id,
          comment: data.comment,
          isPush: data.isPush,
        },
      });
      if (AdminTicketProductReviewComment) {
        toast.success("처리 되었습니다.");
        history.push(`/ticketProductReviews${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  if (!loading) {
    const {
      AdminTicketProductReviewDetail: {
        id,
        ticketProduct,
        ticketOrderProduct,
        user,
        commonStatus,
        isSecret,
        score,
        contents,
        // isComment,
        comment,
        // commentDate,
        createdAt,
        // updatedAt,
      },
    } = data;

    const CoverImage =
      ticketProduct.coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${ticketProduct.coverImage}`;

    return (
      <>
        <Helmet>
          <title>후기 | 한밭페이</title>
        </Helmet>
        <div>
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item ">드림상점</li>
              <li className="breadcrumb-item active">후기</li>
            </ol>
            <h1 className="page-header">후기</h1>

            <div className="row m-b-15">
              <div className="col-md-6 text-left">
                <Link
                  className="btn btn-white"
                  to={`/ticketProductReviews${location.search}`}
                >
                  목록
                </Link>
              </div>
              <div className="col-md-6 text-right">
                {isSecret ? (
                  <button
                    type="button"
                    className="btn btn-white text-info"
                    onClick={() => onSecretoggle(id)}
                  >
                    <i className="fa fa-unlock fa-fw"></i> 일반글
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-white text-secondary"
                    onClick={() => onSecretoggle(id)}
                  >
                    <i className="fa fa-lock fa-fw"></i> 비밀글
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-white text-danger m-l-5"
                  onClick={() => onDelete(id)}
                >
                  <i className="fa fa-trash fa-fw"></i> 삭제
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>상품 리뷰</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="invoice order-product">
                      <div className="invoice-company text-inverse f-w-600">
                        <span className="pull-right hidden-print">
                          <Link
                            className="btn btn-white "
                            to={`/stores/${ticketProduct.store.id}`}
                          >
                            <i className="fa fa-store-alt fa-fw" /> 가맹점 정보
                          </Link>
                          <Link
                            to={`/ticketOrderProducts/${ticketOrderProduct.id}`}
                            className="btn btn-white text-primary m-l-5"
                          >
                            <i className="fa fa-reply fa-fw" /> 사용내역
                          </Link>
                        </span>
                        {ticketProduct.store.name}
                      </div>
                      <div className="underline p-b-15 m-b-15">
                        <ul className="title-info-ul">
                          <li>
                            {commonStatus === "S" && (
                              <span className="label label-yellow">
                                댓글대기
                              </span>
                            )}
                            {commonStatus === "C" && (
                              <span className="label label-primary">
                                댓글완료
                              </span>
                            )}
                            {commonStatus === "D" && (
                              <span className="label label-danger">삭제</span>
                            )}{" "}
                            {isSecret ? (
                              <span className="label label-light text-black">
                                비밀글
                              </span>
                            ) : (
                              <span className="label label-white text-primary">
                                일반글
                              </span>
                            )}
                          </li>
                          <li>
                            <span>{user.name}</span>
                          </li>
                          <li>
                            <span className="m-l-5">
                              <i className="far fa-clock "></i>
                              <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                {createdAt}
                              </ReactMoment>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="order-product-list m-0">
                        <div className="img">
                          <img src={CoverImage} alt={ticketProduct.name} />
                        </div>
                        <div className="cont">
                          <div className="f-s-14">
                            가 맹 점 : {ticketProduct.store.name}
                          </div>
                          <strong>
                            <Link
                              to={`/ticketConfigs/${ticketProduct.ticketConfig.id}/products/${ticketProduct.id}`}
                            >
                              티 켓 명 : [
                              {ticketProduct.ticketCategory.parentCategory
                                ? `${ticketProduct.ticketCategory.parentCategory.name} &gt; ${ticketProduct.ticketCategory.name}`
                                : `${ticketProduct.ticketCategory.name}`}
                              ]{ticketProduct.name}
                            </Link>
                          </strong>
                          <div className="f-s-14">
                            가 격 :
                            {ticketProduct.isPay ? (
                              <>
                                <NumberFormat
                                  displayType={"text"}
                                  value={ticketOrderProduct.price}
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />{" "}
                                -{" "}
                                <NumberFormat
                                  displayType={"text"}
                                  value={
                                    ticketOrderProduct.isDiscount
                                      ? ticketOrderProduct.discountPrice
                                      : 0
                                  }
                                  thousandSeparator={true}
                                  suffix={"드림"}
                                />{" "}
                                ={" "}
                                <b>
                                  <NumberFormat
                                    displayType={"text"}
                                    value={ticketOrderProduct.totalPrice}
                                    thousandSeparator={true}
                                    suffix={"드림"}
                                  />
                                </b>
                              </>
                            ) : (
                              " 무료"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader noButton={true}>후기&amp;답글</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="form-group ">
                        <label className="col-form-label col-md-12">
                          <strong className="f-s-14">
                            <i className="far fa-comment-alt fa-fw"></i> 후
                            기(별점:<b>{score}점</b>)
                          </strong>
                        </label>
                        <div className="col-md-12 bg-light p-10">
                          <div className="bg-white rounded p-10">
                            {renderHTML(contents)}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label col-md-12">
                          <strong className="f-s-14">
                            <i className="far fa-comment-alt fa-fw"></i> 답 글
                          </strong>
                        </label>
                        <div className="col-md-12 bg-light p-10">
                          <div className="bg-white rounded">
                            <textarea
                              name="comment"
                              className="form-control"
                              rows="5"
                              placeholder="선물후기 댓글를 입력해주세요."
                              ref={register({
                                required: "선물후기 댓글를 입력해주세요.",
                              })}
                            >
                              {comment}
                            </textarea>
                          </div>
                          {errors.comment && (
                            <p className="m-t-5 m-b-0 text-danger">
                              * {errors.comment.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">알림</label>
                        <div className="col-md-10">
                          <div className="checkbox checkbox-css">
                            <input
                              type="checkbox"
                              id="isPush"
                              name="isPush"
                              value={true}
                              ref={register()}
                            />
                            <label htmlFor="isPush">&nbsp;</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 text-left">
                <Link
                  className="btn btn-white"
                  to={`/ticketProductReviews${location.search}`}
                >
                  목록
                </Link>
              </div>
              <div className="col-md-4 text-center">
                <button type="submit" className="btn btn-white text-primary">
                  확인
                </button>
              </div>
              <div className="col-md-4 text-right">
                {isSecret ? (
                  <button
                    onClick={() => onSecretoggle(id)}
                    className="btn btn-white text-info"
                  >
                    <i className="fa fa-unlock fa-fw"></i> 일반글
                  </button>
                ) : (
                  <button
                    onClick={() => onSecretoggle(id)}
                    className="btn btn-white text-secondary"
                  >
                    <i className="fa fa-lock fa-fw"></i> 비밀글
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-white text-danger m-l-5"
                  onClick={() => onDelete(id)}
                >
                  <i className="fa fa-trash fa-fw"></i> 삭제
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
