import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  USER_DETAIL_QUERY,
  USER_EDIT_AVATAR_QUERY,
  USER_DELETE_AVATAR_QUERY,
} from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../Config/Env";
import { toast } from "react-toastify";

import {
  roleName,
  confirmedName,
  blockedName,
  currencyName,
  commonStatusName,
} from "../../../Components/User/UserNames";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(USER_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id },
  });

  const [userEditAvatarMutation] = useMutation(USER_EDIT_AVATAR_QUERY, {
    refetchQueries: () => [{ query: USER_DETAIL_QUERY, variables: { id } }],
  });

  const [userDeleteAvatarMutation] = useMutation(USER_DELETE_AVATAR_QUERY, {
    refetchQueries: () => [{ query: USER_DETAIL_QUERY, variables: { id } }],
  });

  const handleAvatar = async (e, data) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];

    try {
      const {
        data: { AdminUserEditAvatar },
      } = await userEditAvatarMutation({
        variables: {
          id: id,
          file,
        },
      });

      if (AdminUserEditAvatar) {
        toast.success("처리 되었습니다.");
        history.push(`/users/${match.params.role}/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onDeleteImg = async (id) => {
    try {
      const {
        data: { AdminUserDeleteAvatar },
      } = await userDeleteAvatarMutation({
        variables: {
          id: id,
        },
      });
      if (AdminUserDeleteAvatar) {
        toast.success("처리 되었습니다.");
        history.push(`/users/${match.params.role}/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onClick = () => {
    document.getElementById("avatar").click();
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminUserDetail) {
    const {
      AdminUserDetail: {
        id,
        commonStatus,
        role,
        confirmed,
        blocked,
        avatar,
        userId,

        authPassword,
        name,
        email,
        phone,

        zipcode,
        roadAddress,
        jibunAddress,
        addressDetail,
        longitude,
        latitude,

        wallet,
        store,
        updatedAt,

        isCms,
        cmsValue,
        cmsDay,
      },
    } = data;

    const avatarImage =
      avatar === "" || avatar === null
        ? "/assets/img/user/user-default.jpg"
        : `${graphqlUri}${avatar}`;

    return (
      <>
        <Helmet>
          <title>사용자 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/users/${match.params.role}`}>사용자</Link>
            </li>
            <li className="breadcrumb-item active">
              {roleName(match.params.role)}
            </li>
          </ol>
          <h1 className="page-header">{roleName(match.params.role)}</h1>
          <div className="margin-bottom-0">
            <div className="row p-b-15">
              <div className="col-xs-4 text-left">
                <Link
                  to={`/users/${match.params.role}${location.search}`}
                  className="btn btn-white"
                >
                  목록
                </Link>
              </div>
              <div className="col-xs-4 text-center"></div>
              <div className="col-xs-4 text-right">
                <Link
                  to={`/users/${match.params.role}/${match.params.id}/edit${location.search}`}
                  className="btn btn-white text-green"
                >
                  수정
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <Panel>
                  <PanelHeader noButton={true}>프로필</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="bg-white rounded">
                        <div className="form-group row">
                          <div className="col-md-12">
                            <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                              <div className="vertical-box-column valign-middle text-center">
                                <img
                                  src={avatarImage}
                                  alt="이미지 업로드"
                                  className="width-200"
                                />
                              </div>
                            </div>
                            <div className="m-t-10">
                              <input
                                type="file"
                                id="avatar"
                                onChange={(e) => handleAvatar(e, data)}
                                className="hide"
                              />
                              <button
                                className="btn btn-primary btn-block"
                                onClick={onClick}
                              >
                                이미지 업로드
                              </button>
                              <Button
                                type="button"
                                color="white"
                                className="btn btn-primary btn-block text-red"
                                onClick={() => {
                                  onDeleteImg(id);
                                }}
                              >
                                이미지 삭제
                              </Button>
                            </div>
                            <div className="alert alert-primary fade show m-t-10 m-b-0">
                              <b>이미지 사이즈 : </b> 넓이 600px X 높이 600px
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
              <div className="col-sm-9">
                <Panel>
                  <PanelHeader noButton={true}>지역화폐</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>화폐</th>
                            <th>금액</th>
                            <th>기능 </th>
                          </tr>
                        </thead>
                        <tbody>
                          {wallet.trustlines.map((trustline) => {
                            return (
                              <tr key={trustline.id}>
                                <td>{currencyName(trustline.currency)}</td>
                                <td align="right">
                                  <NumberFormat
                                    value={trustline.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </td>
                                <td>
                                  <Link
                                    to={`/users/${match.params.role}/${id}/trustlines/${trustline.id}${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    관리
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                </Panel>

                <Panel>
                  <PanelHeader noButton={true}>사용자({name}님)</PanelHeader>
                  <PanelBody className="p-0">
                    <div className="form-horizontal form-bordered">
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">상태</label>
                        <div className="col-md-4">
                          {commonStatusName(commonStatus)}
                        </div>
                        <label className="col-form-label col-md-2">권한</label>
                        <div className="col-md-4">{roleName(role)}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          아이디
                        </label>
                        <div className="col-md-4">{userId.toLowerCase()}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          비밀번호
                        </label>
                        <div className="col-md-4">
                          <Link
                            to={`/users/${match.params.role}/${id}/editPassword${location.search}`}
                            className="btn btn-white btn-xs text-green"
                          >
                            비밀번호 변경하기
                          </Link>
                        </div>
                        <label className="col-form-label col-md-2">
                          승인비밀번호
                        </label>
                        <div className="col-md-4">
                          {authPassword}{" "}
                          <Link
                            to={`/users/${match.params.role}/${id}/editAuthPassword${location.search}`}
                            className="btn btn-white btn-xs text-green"
                          >
                            승인비밀번호 변경하기
                          </Link>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          승인유무
                        </label>
                        <div className="col-md-4">
                          {confirmedName(confirmed)}
                        </div>
                        <label className="col-form-label col-md-2">
                          차단유무
                        </label>
                        <div className="col-md-4">{blockedName(blocked)}</div>
                      </div>
                      {/* {match.params.role === "User" && ( */}
                      <>
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            이메일
                          </label>
                          <div className="col-md-4">{email.toLowerCase()}</div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            휴대전화
                          </label>
                          <div className="col-md-4">{phone}</div>
                        </div>
                      </>
                      {/* )} */}
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">주소</label>
                        <div className="col-md-10">
                          {zipcode}
                          <br />
                          도로명주소 : {roadAddress}
                          <br />
                          지번주소 : {jibunAddress}
                          <br />
                          상세주소 : {addressDetail}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">경도</label>
                        <div className="col-md-4">{longitude}</div>
                        <label className="col-form-label col-md-2">위도</label>
                        <div className="col-md-4">{latitude}</div>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">
                          정기충전(CMS)
                        </label>
                        <div className="col-md-4">
                          {isCms
                            ? `매월 ${cmsDay}일 ${String(cmsValue).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                              )}원 정기결제`
                            : "신청안함"}
                        </div>
                        <label className="col-form-label col-md-2">
                          최종수정일자
                        </label>
                        <div className="col-md-4">
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {updatedAt}
                          </Moment>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
                {match.params.role === "Store" && (
                  <Panel>
                    <PanelHeader noButton={true}>연동 가맹점</PanelHeader>
                    <PanelBody className="p-0">
                      <div className="form-horizontal form-bordered">
                        <div className="form-group row">
                          <label className="col-form-label col-md-3">
                            이름
                          </label>
                          <div className="col-md-9">
                            <Link to={`/stores/${store.id}`}>{store.name}</Link>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-md-3">
                            사업자등록증
                          </label>
                          <div className="col-md-9">
                            <img
                              src={`${graphqlUri}${store.storeProofData}`}
                              alt={"사업자등록증"}
                            />
                          </div>
                        </div>
                      </div>
                    </PanelBody>
                  </Panel>
                )}
              </div>
            </div>

            <div className="row p-b-15">
              <div className="col-xs-4 text-left">
                <Link
                  to={`/users/${match.params.role}${location.search}`}
                  className="btn btn-white"
                >
                  목록
                </Link>
              </div>
              <div className="col-xs-4 text-center"></div>
              <div className="col-xs-4 text-right">
                <Link
                  to={`/users/${match.params.role}/${match.params.id}/edit${location.search}`}
                  className="btn btn-white text-green"
                >
                  수정
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
