import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import UserStoreAsyncSelect from "../../../../Containers/User/StoreAsyncSelect";
import StoreDongSelect from "../../../../Containers/DeliveryStoreDong/MultiSelect";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_STORE_WRITE_MUTATION,
  ADMIN_DELIVERY_STORE_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

export default withRouter(({ history, match, location }) => {
  const baseUrl = "/deliveryStores";

  const { control, handleSubmit, errors } = useForm({
    defalutValues: {
      user: null,
      deliveryStoreDongs: [],
    },
  });

  const [
    AdminDeliveryStoreWriteMutation,
    { loading: AdminDeliveryStoreWriteLoading },
  ] = useMutation(ADMIN_DELIVERY_STORE_WRITE_MUTATION, {
    refetchQueries: [
      {
        query: ADMIN_DELIVERY_STORE_LIST_QUERY,
        variables: {
          take: 15,
          skip: 0,
          keyword: "",
        },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (AdminDeliveryStoreWriteLoading) {
        return;
      }

      const {
        data: { AdminDeliveryStoreWrite },
      } = await AdminDeliveryStoreWriteMutation({
        variables: {
          user_id: data.user.value,
          deliveryStoreDong_ids: data.deliveryStoreDongs.map(
            (item) => item.value
          ),
        },
      });
      if (AdminDeliveryStoreWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>매장 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`${baseUrl}`}>로컬푸드 구독</Link>
            </li>
            <li className="breadcrumb-item active">매장</li>
          </ol>
          <h1 className="page-header">매장</h1>

          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Panel>
                <PanelHeader noButton>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <Controller
                      control={control}
                      name="user"
                      rules={{ required: "매장명을 검색해주세요." }}
                      render={({ onChange, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            매장명
                          </label>
                          <div className="col-md-10">
                            <UserStoreAsyncSelect
                              onChange={onChange}
                              value={value}
                            />
                            {errors.user && (
                              <span className="text-red errorText">
                                {errors.user.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name="deliveryStoreDongs"
                      rules={{ required: "배달가능한 동을 선택해주세요." }}
                      render={({ onChange, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            배달가능한 동
                          </label>
                          <div className="col-md-10">
                            <StoreDongSelect
                              onChange={onChange}
                              value={value}
                            />
                            {errors.deliveryStoreDongs && (
                              <span className="text-red errorText">
                                {errors.deliveryStoreDongs.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-4 text-left"></div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-success">
                확인
              </button>
              <Link
                to={`${baseUrl}${location.search}`}
                className="btn btn-white text-danger m-l-5"
              >
                취소
              </Link>
            </div>
            <div className="col-xs-4 text-right"></div>
          </div>
        </div>
      </form>
    </>
  );
});
