import React from "react";
import { withRouter } from "react-router-dom";
import { LEDGER_LIST_QUERY } from "../Queries";
import { useQuery } from "react-apollo-hooks";
import Presenter from "./Presenter/List";
import qs from "qs";

import Moment from "moment";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const transactionType = queryString.transactionType
    ? queryString.transactionType
    : "";
  const role = queryString.role ? queryString.role : "";
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : Moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD");
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  const wallet = queryString.wallet ? queryString.wallet : "";
  const page = queryString.page ? queryString.page : 1;

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (transactionType ? "transactionType=" + transactionType + "&" : "") +
    (role ? "role=" + role + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (wallet ? "wallet=" + wallet + "&" : "");

  const { data, loading } = useQuery(LEDGER_LIST_QUERY, {
    variables: {
      transactionType,
      role,
      startSearchDate,
      endSearchDate,
      wallet,
      orderBy: "id_DESC",
      skip,
      first
    }
  });

  const handleDateApplyEvent = (event, picker) => {
    // event.preventDefault();

    var startDate = picker.startDate.format("YYYY-MM-DD");
    var endDate = picker.endDate.format("YYYY-MM-DD");

    const ledgersUrl =
      "?" +
      (transactionType ? "transactionType=" + transactionType + "&" : "") +
      (role ? "role=" + role + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (wallet ? "wallet=" + wallet + "&" : "");

    history.push(`/ledgers${ledgersUrl}page=1`);
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      transactionType={transactionType}
      role={role}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      handleDateApplyEvent={handleDateApplyEvent}
      wallet={wallet}
      page={page}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
    ></Presenter>
  );
});
