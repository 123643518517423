import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "../../../../../Containers/SelectedPeriod/DatePicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import { Link } from "react-router-dom";
import { EVENT_EDIT_QUERY, EVENT_DETAIL_QUERY } from "../../Queries";
import { useMutation } from "react-apollo-hooks";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { graphqlUri } from "../../../../../Config/Env";

export default ({
  history,
  match,
  location,

  id,
  title,
  contents,
  createdAt,
  updatedAt,
  coverImg,
  detailImg,
  startDateAt,
  endDateAt,
  commonStatus,
  isModal,
}) => {
  const modalRef = React.createRef();
  const [startDate, setStartDate] = useState(startDateAt, new Date());
  const [endDate, setEndDate] = useState(endDateAt, new Date());
  const [eventEditMutation] = useMutation(EVENT_EDIT_QUERY, {
    refetchQueries: () => [{ query: EVENT_DETAIL_QUERY, variables: { id } }],
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id: id,
      title: title,
      contents: contents,
      commonStatus: commonStatus,
      startDate: startDate,
      endDate: endDate,
    },
  });

  const [eventCoverImg, setEventCoverImg] = useState(
    coverImg ? graphqlUri + coverImg : "/assets/img/gallery/gallery-default.jpg"
  );
  const [eventDetailImg, setEventDetailImg] = useState(
    detailImg
      ? graphqlUri + detailImg
      : "/assets/img/gallery/gallery-default.jpg"
  );
  const handleEventCoverImg = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setEventCoverImg(reader.result);
    };
  };
  const handleEventDetailImg = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setEventDetailImg(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImg").click();
  };
  const onClick_detail = () => {
    document.getElementById("detailImg").click();
  };

  const onSubmit = async (data) => {
    try {
      let event = {
        id: id,
        commonStatus: data.commonStatus,
        isPush: data.isPush === "true" ? true : false,
        startDate,
        endDate,
        title: data.title,
        contents: data.contents,
      };

      if (data.coverImg[0]) {
        event = { ...event, coverImg: data.coverImg[0] };
      }

      if (data.detailImg[0]) {
        event = { ...event, detailImg: data.detailImg[0] };
      }
      //   console.log(event);
      const {
        data: { AdminEventEdit },
      } = await eventEditMutation({
        variables: event,
      });
      if (AdminEventEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-white" onClick={onClick}>
      {value}
      <i className="fa fa-calendar fa-fw text-black-transparent-5 m-l-5"></i>
    </button>
  ));

  return (
    <>
      <Helmet>
        <title>이벤트 관리 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">이벤트</li>
          </ol>
          <h1 className="page-header">이벤트</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-success">
                  <FontAwesomeIcon icon={faCheck} />
                  수정
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Panel>
                <PanelHeader>수정</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="commonStatusC"
                          name="commonStatus"
                          value="C"
                          ref={register({
                            required: "상태를 선택해주세요.",
                          })}
                        />
                        <label htmlFor={`commonStatusC`}>공개</label>
                        {errors.commonStatus && (
                          <span className="text-danger">
                            {errors.commonStatus.message}
                          </span>
                        )}
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="commonStatusS"
                          name="commonStatus"
                          value="S"
                          ref={register({
                            required: "상태를 선택해주세요.",
                          })}
                        />
                        <label htmlFor={`commonStatusS`}>비공개</label>
                        {errors.commonStatus && (
                          <span className="text-danger">
                            {errors.commonStatus.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        이벤트 기간
                      </label>
                      <div className="col-md-10">
                        <div className="input-group">
                          <DatePicker
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day text-white">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                            locale={ko}
                            name="startDate"
                            dateFormat="yyyy-MM-dd"
                            selected={Date.parse(startDate)}
                            onChange={(date) => setStartDate(date)}
                            // ref={register({
                            //   required: "이벤트기간을 입력해주세요.",
                            // })}
                          />
                          {errors.startDate && (
                            <span>{errors.startDate.message}</span>
                          )}
                          <span className="custom-react-datepicker__split-span">
                            ~
                          </span>
                          <DatePicker
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day text-white">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                            locale={ko}
                            name="endDate"
                            dateFormat="yyyy-MM-dd"
                            selected={Date.parse(endDate)}
                            onChange={(date) => setEndDate(date)}
                            // ref={register({
                            //   required: "이벤트기간을 입력해주세요.",
                            // })}
                          />
                          {errors.endDate && (
                            <span>{errors.endDate.message}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="title"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <span>{errors.title.message}</span>}
                      </div>
                      <label className="col-form-label col-md-2">알림</label>
                      <div className="col-md-2">
                        <input
                          type="checkbox"
                          id="isPush"
                          className="form-control"
                          name="isPush"
                          value={true}
                          ref={register()}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-xs-12">
                        <textarea
                          rows="10"
                          type="text"
                          name="contents"
                          className="form-control m-b-5"
                          placeholder="내용입력"
                          ref={register({
                            required: "내용 입력해주세요.",
                          })}
                        ></textarea>
                        {errors.contents && (
                          <span>{errors.contents.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader>커버 이미지 </PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={eventCoverImg}
                            alt="이미지 업로드"
                            className="width-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImg"
                          name="coverImg"
                          className="hide"
                          onChange={handleEventCoverImg}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          상세 이미지
                        </button>
                      </div>
                      {errors.coverImg && (
                        <span className="text-danger">
                          {errors.coverImg.message}
                        </span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <span className="close" data-dismiss="alert">
                          ×
                        </span>
                        <b>커버이미지 사이즈 :</b> 넓이 1200px X 높이 1024px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader>상세 이미지 커버</PanelHeader>
                <PanelBody>
                  <div className="form-group row  m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={eventDetailImg}
                            alt="이미지 업로드"
                            className="width-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="detailImg"
                          name="detailImg"
                          className="hide"
                          onChange={handleEventDetailImg}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_detail}
                        >
                          상세 이미지 업로드
                        </button>
                      </div>
                      {errors.detailImg && (
                        <span>{errors.detailImg.message}</span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <span className="close" data-dismiss="alert">
                          ×
                        </span>
                        <b>상세이미지 사이즈 :</b> 넓이 1200px X 높이 1200px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/eventConfigs/${match.params.CommonApp}/${match.params.eventConfig}/events${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-success">
                  <FontAwesomeIcon icon={faCheck} />
                  수정
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
