import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../../Components/Panel/Panel";
import { commonStatusName } from "../../../../../../Components/User/UserNames";
import ReactMoment from "react-moment";

export default withRouter(({ history, match, location, data }) => {
  const id = match.params.id;
  const feeMonthFileId = match.params.feeMonthFileId;

  const {
    AdminFeeMonthFileDetail: {
      feeMonthFileDetails,
      commonStatus,
      name,
      year,
      month,

      file,

      nameColumn,
      phoneColumn,

      createdAt,
      updatedAt,
    },
  } = data;

  return (
    <>
      <Helmet>
        <title>알림 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">알림</li>
        </ol>
        <h1 className="page-header">알림</h1>

        <div key="topButton" className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link
              to={`/fees/${id}/monthFiles${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right">
            <div className="btn-group"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <Panel>
              <PanelHeader noButton={true}>상세정보</PanelHeader>
              <PanelBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>상태</th>
                        <th>이름</th>
                        <th>연도</th>
                        <th>월</th>
                        <th>파일정보</th>
                        <th>등록일시</th>
                        <th>데이터</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{commonStatusName(commonStatus)}</td>
                        <td>{name}</td>
                        <td>{year}</td>
                        <td>{month}</td>
                        <td>{file}</td>
                        <td>
                          <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                            {createdAt}
                          </ReactMoment>
                        </td>
                        <td>{feeMonthFileDetails.length}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>

        <div key="storeInfo" className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                <b>{year}</b>년 <b>{month}</b>월 파일(<b>{file}</b>) 충전현황(
                {feeMonthFileDetails.length}건)
              </PanelHeader>
              <PanelBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>이름</th>
                        <th>전화번호</th>
                        <th>설명</th>
                        <th>출금일</th>
                        <th>출금금액</th>
                      </tr>
                    </thead>
                    <tbody>
                      {feeMonthFileDetails.map((detail, index) => {
                        return (
                          <tr key={index}>
                            <td>{detail[nameColumn]}</td>
                            <td>{detail[phoneColumn]}</td>
                            <td>
                              {detail.description ? detail.description : "-"}
                            </td>
                            <td>
                              {detail.feeHasUser
                                ? detail.feeHasUser.feeDay
                                : "-"}
                            </td>
                            <td>
                              {detail.feeHasUser
                                ? detail.feeHasUser.feeValue
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>

        <div key="bottomButton" className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link
              to={`/fees/${id}/monthFiles${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right">
            {/* <Button
                type="button"
                color="white"
                className="text-red"
                onClick={() => {
                  onDelete();
                }}
              >
                삭제
              </Button> */}
          </div>
        </div>
      </div>
    </>
  );
});
