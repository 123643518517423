import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GIFT_STATISTICS_MONTHORDERRANK_QUERY } from "./Queries";
import { useQuery } from "react-apollo-hooks";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default withRouter(({ history, match, location, isModal }) => {
  const date = new Date();
  const year = match.params.year
    ? parseInt(match.params.year)
    : date.getFullYear();
  const month = match.params.month
    ? parseInt(match.params.month)
    : date.getMonth();

  const [dayOrderCountTotal, setDayOrderCountTotal] = useState(0);

  const { data, loading } = useQuery(GIFT_STATISTICS_MONTHORDERRANK_QUERY, {
    fetchPolicy: "network-only",
    variables: { year: year, month: month - 1 },
  });

  const modalRef = React.createRef();
  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  useEffect(() => {
    if (!loading) {
      let dayOrderCount = 0;
      data.AdminGiftStatisticsMonthOrderRank.giftProductMonthOrderRanks.forEach(
        (item) => {
          dayOrderCount += item.orderCount;
          setDayOrderCountTotal(dayOrderCount);
        }
      );
    }
  }, [data, loading]);

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/giftStatistics/year/${year}/month/${month}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/giftStatistics/year/${year}/month/${month}`);
          }}
        >
          {year}년 {month}월 주문순위
        </ModalHeader>
        <ModalBody className="p-0">
          {!loading ? (
            <>
              <div className="m-15">
                <div className="table-responsive ">
                  <table className="table table-bordered table-striped m-b-0 text-center">
                    <thead>
                      <tr>
                        <th>순위</th>
                        <th>상품명</th>
                        <th>주문건수</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>전체</td>
                        <td>-</td>
                        <td>{dayOrderCountTotal}건</td>
                      </tr>
                      {data.AdminGiftStatisticsMonthOrderRank.giftProductMonthOrderRanks.map(
                        (item, index) => {
                          const endNumber = new Date(year, month, 0).getDate();
                          return (
                            <tr key={index}>
                              <td>{index + 1}위</td>
                              <td className="text-left">
                                <a
                                  href={`/giftOrders?giftProduct=${item.id}&startSearchDate=${year}-${month}-01&endSearchDate=${year}-${month}-${endNumber}`}
                                >
                                  {item.productName}
                                </a>
                              </td>
                              <td>{item.orderCount}건</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <p className="text-center">등록된 데이터가 없습니다.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  );
});
