import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { COUPON_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(COUPON_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminCouponDetail) {
    return (
      <>
        <Presenter
          history={history}
          match={match}
          location={location}
          data={data}
        ></Presenter>
      </>
    );
  }
});
