import { gql } from "apollo-boost";

export const GIFT_STATISTICS_YEAR_QUERY = gql`
  query AdminGiftStatisticsYear($year: Int!) {
    AdminGiftStatisticsYear(year: $year) {
      statisticsMonths {
        Month
        MonthMoney
        MonthProductCount
        MonthCount
        ReadyMoney
        ReadyCount
        CompletionMoney
        CompletionCount
        RefundMoney
        RefundCount
      }
    }
  }
`;

export const GIFT_STATISTICS_YEARORDERRANK_QUERY = gql`
  query AdminGiftStatisticsYearOrderRank($year: Int!) {
    AdminGiftStatisticsYearOrderRank(year: $year) {
      giftConfig {
        id
      }
      giftProductYearOrderRanks {
        id
        orderCount
        productName
      }
    }
  }
`;

export const GIFT_STATISTICS_YEARORDERPRODUCTRANK_QUERY = gql`
  query AdminGiftStatisticsYearOrderProductRank($year: Int!) {
    AdminGiftStatisticsYearOrderProductRank(year: $year) {
      giftConfig {
        id
      }
      giftProductYearOrderProductRanks {
        id
        orderProductCount
        productName
      }
    }
  }
`;

export const GIFT_STATISTICS_MONTH_QUERY = gql`
  query AdminGiftStatisticsMonth($year: Int!, $month: Int!) {
    AdminGiftStatisticsMonth(year: $year, month: $month) {
      statisticsDays {
        Day
        DayMoney
        DayProductCount
        DayCount
        ReadyMoney
        ReadyCount
        CompletionMoney
        CompletionCount
        RefundMoney
        RefundCount
      }
    }
  }
`;

export const GIFT_STATISTICS_MONTHORDERRANK_QUERY = gql`
  query AdminGiftStatisticsMonthOrderRank($year: Int!, $month: Int!) {
    AdminGiftStatisticsMonthOrderRank(year: $year, month: $month) {
      giftConfig {
        id
      }
      giftProductMonthOrderRanks {
        id
        orderCount
        productName
      }
    }
  }
`;

export const GIFT_STATISTICS_MONTHORDERPRODUCTRANK_QUERY = gql`
  query AdminGiftStatisticsMonthOrderProductRank($year: Int!, $month: Int!) {
    AdminGiftStatisticsMonthOrderProductRank(year: $year, month: $month) {
      giftConfig {
        id
      }
      giftProductMonthOrderProductRanks {
        id
        orderProductCount
        productName
      }
    }
  }
`;
