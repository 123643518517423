import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { GIFT_PRODUCT_LIST_QUERY } from "../../Queries";
import useInput from "../../../../../Hooks/useInput";
import ListPresenter from "./Presenter/List";
import qs from "qs";

export default withRouter(({ history, match, location, configs, config }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  var keyword = queryString.keyword ? queryString.keyword : "";
  var category = queryString.category ? queryString.category : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 16;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (category ? "category=" + category + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(GIFT_PRODUCT_LIST_QUERY, {
    variables: {
      giftConfig: config.id,
      giftCategory: category ? category : "",
      keyword,
      skip,
      first,
    },
  });

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/giftConfigs/${config.id}/products${baseUrl}page=1`);
  };

  useEffect(() => {
    refetch();
  });

  return (
    <>
      <ListPresenter
        history={history}
        match={match}
        location={location}
        configs={configs}
        config={config}
        page={page}
        keyword={keyword}
        keywordInput={keywordInput}
        blockSize={blockSize}
        first={first}
        data={data}
        loading={loading}
        baseUrl={baseUrl}
        handleSearch={handleSearch}
      ></ListPresenter>
    </>
  );
});
