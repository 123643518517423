import { gql } from "apollo-boost";

/* 상품 설정 */
export const GIFT_CONFIG_LIST_QUERY = gql`
  query AdminGiftConfigList($skip: Int, $first: Int) {
    AdminGiftConfigList(orderBy: "id_ASC", skip: $skip, first: $first) {
      giftConfigs {
        id
        productType
        name
        createdAt
        updatedAt
        giftProductsCount
      }
      count
    }
  }
`;
export const GIFT_CONFIG_QUERY = gql`
  query AdminGiftConfig($id: String) {
    AdminGiftConfig(id: $id) {
      id
      productType
      name
      createdAt
      updatedAt
    }
  }
`;
export const GIFT_CONFIG_WRITE_QUERY = gql`
  mutation AdminGiftConfigWrite($productType: String!, $name: String!) {
    AdminGiftConfigWrite(productType: $productType, name: $name)
  }
`;
export const GIFT_CONFIG_EDIT_QUERY = gql`
  mutation AdminGiftConfigEdit(
    $id: String!
    $productType: String!
    $name: String!
  ) {
    AdminGiftConfigEdit(id: $id, productType: $productType, name: $name)
  }
`;

/* 상품 분류 */
export const GIFT_CATEGORY_LIST_QUERY = gql`
  query AdminGiftCategoryList(
    $giftConfig: String
    $parentId: String
    $skip: Int
    $first: Int
  ) {
    AdminGiftCategoryList(
      giftConfig: $giftConfig
      parentId: $parentId
      orderBy: "order_ASC"
      skip: $skip
      first: $first
    ) {
      giftCategories {
        id
        parentId
        giftConfig {
          id
        }
        commonStatus
        iconImage
        name
        order
        createdAt
        updatedAt

        giftProductsCount
        childrenCount
        children {
          id
          parentId
          giftConfig {
            id
          }
          commonStatus
          iconImage
          name
          order
          createdAt
          updatedAt

          giftProductsCount
          childrenCount
          children {
            id
            parentId
            giftConfig {
              id
            }
            commonStatus
            iconImage
            name
            order
            createdAt
            updatedAt

            giftProductsCount
            childrenCount
          }
        }
      }
      count
    }
  }
`;
export const GIFT_CATEGORY_WRITE_QUERY = gql`
  mutation AdminGiftCategoryWrite(
    $giftConfig: String!
    $commonStatus: String!
    $iconImage: IconUpload
    $name: String!
    $parentId: String
  ) {
    AdminGiftCategoryWrite(
      giftConfig: $giftConfig
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
      parentId: $parentId
    )
  }
`;
export const GIFT_CATEGORY_DETAIL_QUERY = gql`
  query AdminGiftCategoryDetail($id: String!) {
    AdminGiftCategoryDetail(id: $id) {
      id
      giftConfig {
        id
      }
      commonStatus
      iconImage
      name
      createdAt
      updatedAt

      giftProductsCount
      childrenCount
    }
  }
`;
export const GIFT_CATEGORY_EDIT_QUERY = gql`
  mutation AdminGiftCategoryEdit(
    $id: String!
    $giftConfig: String!
    $commonStatus: String!
    $iconImage: IconUpload
    $name: String!
  ) {
    AdminGiftCategoryEdit(
      id: $id
      giftConfig: $giftConfig
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
    )
  }
`;
export const GIFT_CATEGORY_MOVE_QUERY = gql`
  mutation AdminGiftCategoryMove(
    $id: String!
    $target: String!
    $position: String!
  ) {
    AdminGiftCategoryMove(id: $id, target: $target, position: $position)
  }
`;
export const GIFT_CATEGORY_DELETE_QUERY = gql`
  mutation AdminGiftCategoryDelete($id: String!) {
    AdminGiftCategoryDelete(id: $id)
  }
`;

/* 상품 */
export const GIFT_PRODUCT_LIST_QUERY = gql`
  query AdminGiftProductList(
    $giftConfig: String
    $giftCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminGiftProductList(
      giftConfig: $giftConfig
      giftCategory: $giftCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      giftProducts {
        id
        giftConfig {
          id
          name
        }
        giftCategory {
          id
          name
        }
        store {
          id
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        isBest
        bestOrder
        isNew
        newOrder
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const GIFT_PRODUCT_WRITE_QUERY = gql`
  mutation AdminGiftProductWrite(
    $giftConfig: String!
    $giftCategory: String!
    $store: String!
    $isDiscount: Boolean
    $discountPrice: String
    $price: String!
    $name: String!
    $contents: String
    $timestamp: String
    $limit: String
    $schedule: String
    $place: String
  ) {
    AdminGiftProductWrite(
      giftConfig: $giftConfig
      giftCategory: $giftCategory
      store: $store
      isDiscount: $isDiscount
      discountPrice: $discountPrice
      price: $price
      name: $name
      contents: $contents
      timestamp: $timestamp

      limit: $limit
      schedule: $schedule
      place: $place
    )
  }
`;
export const STORE_LIST_QUERY = gql`
  query AdminStoreList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id
        name
        storeCEO
      }
      count
    }
  }
`;
export const GIFT_PRODUCT_DETAIL_QUERY = gql`
  query AdminGiftProductDetail($id: String!) {
    AdminGiftProductDetail(id: $id) {
      id
      giftConfig {
        id
        name
      }
      giftCategory {
        id
        name
        parentCategory {
          id
          name
        }
      }
      store {
        id
        name
      }
      commonStatus
      productType
      coverImage
      isSoldOut
      isDiscount
      discountPrice
      price
      name
      contents
      isBest
      isNew
      timestamp

      limit
      schedule
      place

      createdAt
      updatedAt

      giftProductOptions {
        id
        commonStatus
        name
        giftProductOptionDetails {
          id
          commonStatus
          isSoldOut
          price
          name
        }
      }
      giftProductOptionsCount
      giftProductOptionDetailsCount
      giftProductReviews {
        id
        commonStatus
        # orderProduct {
        #   id
        # }
        contents
        createdAt
        updatedAt
      }
      giftProductInquiries {
        id
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt
      }
    }
  }
`;
export const GIFT_PRODUCT_EDIT_QUERY = gql`
  mutation AdminGiftProductEdit(
    $id: String!
    $giftCategory: String!
    $store: String
    $commonStatus: String!
    $isSoldOut: Boolean
    $isDiscount: Boolean
    $discountPrice: String
    $price: String!
    $name: String!
    $contents: String
    $limit: String
    $schedule: String
    $place: String
    $isBest: Boolean
    $isNew: Boolean
  ) {
    AdminGiftProductEdit(
      id: $id
      giftCategory: $giftCategory
      store: $store
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      isDiscount: $isDiscount
      discountPrice: $discountPrice
      price: $price
      name: $name
      contents: $contents

      limit: $limit
      schedule: $schedule
      place: $place

      isBest: $isBest
      isNew: $isNew
    )
  }
`;
export const GIFT_PRODUCT_EDIT_COVER_QUERY = gql`
  mutation AdminGiftProductCoverImageEdit($id: String!, $file: FileUpload!) {
    AdminGiftProductCoverImageEdit(id: $id, file: $file) {
      id
      coverImage
    }
  }
`;
export const GIFT_PRODUCT_DELETE_COVER_QUERY = gql`
  mutation AdminGiftProductCoverImageDelete($id: String!) {
    AdminGiftProductCoverImageDelete(id: $id)
  }
`;
export const GIFT_PRODUCT_DELETE_QUERY = gql`
  mutation AdminGiftProductDelete($id: String!) {
    AdminGiftProductDelete(id: $id)
  }
`;

/* 상품 옵션 */
export const GIFT_PRODUCT_PRODUCTOPTION_WRITE_QUERY = gql`
  mutation AdminGiftProductProductOptionWrite(
    $giftProduct: String!
    $commonStatus: String!
    $name: String!
    $detailCommonStatus: String!
    $detailIsSoldOut: Boolean
    $detailPrice: String!
    $detailName: String!
  ) {
    AdminGiftProductProductOptionWrite(
      giftProduct: $giftProduct
      commonStatus: $commonStatus
      name: $name
      detailCommonStatus: $detailCommonStatus
      detailIsSoldOut: $detailIsSoldOut
      detailPrice: $detailPrice
      detailName: $detailName
    )
  }
`;
export const GIFT_PRODUCT_PRODUCTOPTION_DETAIL_QUERY = gql`
  query AdminGiftProductProductOptionDetail($id: String!) {
    AdminGiftProductProductOptionDetail(id: $id) {
      id
      commonStatus
      name

      createdAt
      updatedAt
    }
  }
`;
export const GIFT_PRODUCT_PRODUCTOPTION_EDIT_QUERY = gql`
  mutation AdminGiftProductProductOptionEdit(
    $id: String!
    $commonStatus: String!
    $name: String!
  ) {
    AdminGiftProductProductOptionEdit(
      id: $id
      commonStatus: $commonStatus
      name: $name
    )
  }
`;

/* 상품 상세 옵션 */
export const GIFT_PRODUCT_PRODUCTOPTIONDETAIL_WRITE_QUERY = gql`
  mutation AdminGiftProductProductOptionDetailWrite(
    $giftProduct: String!
    $giftProductOption: String!
    $commonStatus: String!
    $isSoldOut: Boolean
    $price: String!
    $name: String!
  ) {
    AdminGiftProductProductOptionDetailWrite(
      giftProduct: $giftProduct
      giftProductOption: $giftProductOption
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      price: $price
      name: $name
    )
  }
`;
export const GIFT_PRODUCT_PRODUCTOPTIONDETAIL_DETAIL_QUERY = gql`
  query AdminGiftProductProductOptionDetailDetail($id: String!) {
    AdminGiftProductProductOptionDetailDetail(id: $id) {
      id
      commonStatus
      isSoldOut
      price
      name
      createdAt
      updatedAt
    }
  }
`;
export const GIFT_PRODUCT_PRODUCTOPTIONDETAIL_EDIT_QUERY = gql`
  mutation AdminGiftProductProductOptionDetailEdit(
    $id: String!
    $commonStatus: String!
    $isSoldOut: Boolean
    $price: String!
    $name: String!
  ) {
    AdminGiftProductProductOptionDetailEdit(
      id: $id
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      price: $price
      name: $name
    )
  }
`;

/* 티켓 일정 */
export const GIFT_PRODUCT_TICKETOPTION_WRITE_QUERY = gql`
  mutation AdminGiftProductTicketOptionWrite(
    $giftProduct: String!
    $ticketDate: String!
    $ticketTime: String!
  ) {
    AdminGiftProductTicketOptionWrite(
      giftProduct: $giftProduct
      ticketDate: $ticketDate
      ticketTime: $ticketTime
    )
  }
`;
export const GIFT_PRODUCT_TICKETOPTION_DETAIL_QUERY = gql`
  query AdminGiftProductTicketOptionDetail($id: String!) {
    AdminGiftProductTicketOptionDetail(id: $id) {
      id
      commonStatus
      name

      createdAt
      updatedAt
    }
  }
`;
export const GIFT_PRODUCT_TICKETOPTION_EDIT_QUERY = gql`
  mutation AdminGiftProductTicketOptionEdit(
    $id: String!
    $commonStatus: String!
    $name: String!
  ) {
    AdminGiftProductTicketOptionEdit(
      id: $id
      commonStatus: $commonStatus
      name: $name
    )
  }
`;

/* 티켓 상세 일정 */
export const GIFT_PRODUCT_TICKETOPTIONDETAIL_WRITE_QUERY = gql`
  mutation AdminGiftProductTicketOptionDetailWrite(
    $giftProduct: String!
    $giftProductOption: String!
    $commonStatus: String!
    $isSoldOut: Boolean
    $price: String!
    $name: String!
  ) {
    AdminGiftProductTicketOptionDetailWrite(
      giftProduct: $giftProduct
      giftProductOption: $giftProductOption
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      price: $price
      name: $name
    )
  }
`;
export const GIFT_PRODUCT_TICKETOPTIONDETAIL_DETAIL_QUERY = gql`
  query AdminGiftProductTicketOptionDetailDetail($id: String!) {
    AdminGiftProductTicketOptionDetailDetail(id: $id) {
      id
      commonStatus
      isSoldOut
      price
      name
      createdAt
      updatedAt
    }
  }
`;
export const GIFT_PRODUCT_TICKETOPTIONDETAIL_EDIT_QUERY = gql`
  mutation AdminGiftProductTicketOptionDetailEdit(
    $id: String!
    $commonStatus: String!
    $isSoldOut: Boolean
    $price: String!
    $name: String!
  ) {
    AdminGiftProductTicketOptionDetailEdit(
      id: $id
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      price: $price
      name: $name
    )
  }
`;

/* 인기 상품 */
export const GIFT_PRODUCT_BEST_LIST_QUERY = gql`
  query AdminGiftProductBestList($skip: Int, $first: Int) {
    AdminGiftProductBestList(
      orderBy: "bestOrder_DESC"
      skip: $skip
      first: $first
    ) {
      giftProducts {
        id
        giftConfig {
          id
          name
        }
        giftCategory {
          id
          name
        }
        store {
          id
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        isBest
        bestOrder
        isNew
        newOrder
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const GIFT_PRODUCT_BESTPREVMOVE_QUERY = gql`
  mutation AdminGiftProductBestPrevMove($id: String!) {
    AdminGiftProductBestPrevMove(id: $id)
  }
`;
export const GIFT_PRODUCT_BESTNEXTMOVE_QUERY = gql`
  mutation AdminProductBestNextMove($id: String!) {
    AdminProductBestNextMove(id: $id)
  }
`;

/* 신규 상품 */
export const GIFT_PRODUCT_NEW_LIST_QUERY = gql`
  query AdminGiftProductNewList($skip: Int, $first: Int) {
    AdminGiftProductNewList(
      orderBy: "newOrder_DESC"
      skip: $skip
      first: $first
    ) {
      giftProducts {
        id
        giftConfig {
          id
          name
        }
        giftCategory {
          id
          name
        }
        store {
          id
          name
        }
        commonStatus
        productType
        coverImage
        isSoldOut
        isDiscount
        discountPrice
        price
        name
        contents
        isBest
        bestOrder
        isNew
        newOrder
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const GIFT_PRODUCT_NEWPREVMOVE_QUERY = gql`
  mutation AdminGiftProductNewPrevMove($id: String!) {
    AdminGiftProductNewPrevMove(id: $id)
  }
`;
export const GIFT_PRODUCT_NEWNEXTMOVE_QUERY = gql`
  mutation AdminGiftProductNewNextMove($id: String!) {
    AdminGiftProductNewNextMove(id: $id)
  }
`;

/* 상품 이미지 */
export const COMMONFILE_WRITE_QUERY = gql`
  mutation AdminCommonFileWrite(
    $moduleId: String!
    $targetType: String!
    $timestamp: String!
    $commonFile: CommonFileUpload!
  ) {
    AdminCommonFileWrite(
      moduleType: "giftConfig"
      moduleId: $moduleId
      moduleTable: "giftProduct"
      targetType: $targetType
      timestamp: $timestamp
      commonFile: $commonFile
    ) {
      id
      moduleType
      moduleId
      moduleTable
      targetType
      timestamp

      filepath
      filename
      mimetype
      encoding

      createdAt
      updatedAt
    }
  }
`;
