import { gql } from "apollo-boost";

//사용자
export const USER_LIST_QUERY = gql`
  query AdminUserList(
    $role: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminUserList(
      role: $role
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        commonStatus
        role
        useStoreApp
        confirmed
        blocked
        avatar
        userId
        email
        phone
        name
        sex
        birthDate
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        wallet {
          id
          trustlines {
            id
            commonStatus
            currency
            amount
            createdAt
            updatedAt
          }
        }
        store {
          id
          name
        }
        addCurrency
        createdAt
        updatedAt

        isCms
        cmsValue
        cmsDay
      }
      count
    }
  }
`;

export const USER_WRITE_QUERY = gql`
  mutation AdminUserWrite(
    $confirmed: Boolean
    $blocked: Boolean
    $userId: String
    $addCurrency: String
    $name: String
    $password: String
    $authPassword: String
    $email: String
    $phone: String
    $zipcode: String
    $roadAddress: String
    $jibunAddress: String
    $addressDetail: String
    $avatar: FileUpload
  ) {
    AdminUserWrite(
      confirmed: $confirmed
      blocked: $blocked
      userId: $userId
      addCurrency: $addCurrency
      name: $name
      password: $password
      authPassword: $authPassword
      email: $email
      phone: $phone
      zipcode: $zipcode
      roadAddress: $roadAddress
      jibunAddress: $jibunAddress
      addressDetail: $addressDetail
      avatar: $avatar
    )
  }
`;

export const USER_DETAIL_QUERY = gql`
  query AdminUserDetail($id: String!) {
    AdminUserDetail(id: $id) {
      id
      commonStatus
      confirmed
      blocked
      avatar
      userId
      authPassword
      email
      phone
      name
      zipcode
      roadAddress
      jibunAddress
      addressDetail
      role
      wallet {
        id
        type
        userId
        address
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
        }
        memo
        createdAt
      }
      trustlines {
        id
        commonStatus
        currency
        amount
        createdAt
        updatedAt
      }
      store {
        id
        name
        storeProofData
      }
      addCurrency
      updatedAt

      isCms
      cmsValue
      cmsDay

      longitude
      latitude
    }
  }
`;

export const USER_EDIT_QUERY = gql`
  mutation AdminUserEdit(
    $id: String
    $role: Role
    $confirmed: Boolean
    $blocked: Boolean
    $email: String
    $phone: String
    $sex: Sex
    $birthDate: String
    $zipcode: String
    $roadAddress: String
    $jibunAddress: String
    $addressDetail: String
    $longitude: String
    $latitude: String
    $isCms: Boolean
    $cmsValue: String
    $cmsDay: String
  ) {
    AdminUserEdit(
      id: $id
      role: $role
      confirmed: $confirmed
      blocked: $blocked
      email: $email
      phone: $phone
      sex: $sex
      birthDate: $birthDate
      zipcode: $zipcode
      roadAddress: $roadAddress
      jibunAddress: $jibunAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      isCms: $isCms
      cmsValue: $cmsValue
      cmsDay: $cmsDay
    )
  }
`;

export const USER_EDIT_AVATAR_QUERY = gql`
  mutation AdminUserEditAvatar($id: String!, $file: FileUpload!) {
    AdminUserEditAvatar(id: $id, file: $file) {
      id
      avatar
    }
  }
`;

export const USER_DELETE_AVATAR_QUERY = gql`
  mutation AdminUserDeleteAvatar($id: String!) {
    AdminUserDeleteAvatar(id: $id)
  }
`;

export const USER_EDIT_PASSWORD_QUERY = gql`
  mutation AdminUserEditPassword($id: String, $password: String) {
    AdminUserEditPassword(id: $id, password: $password)
  }
`;

export const USER_EDIT_AUTHPASSWORD_QUERY = gql`
  mutation AdminUserEditAuthPassword($id: String, $authPassword: String) {
    AdminUserEditAuthPassword(id: $id, authPassword: $authPassword)
  }
`;

export const USER_EDIT_USESTOREAPP_QUERY = gql`
  mutation AdminUserEditUseStoreApp($id: String!) {
    AdminUserEditUseStoreApp(id: $id)
  }
`;

export const TRUSTLINE_DETAIL_QUERY = gql`
  query AdminTrustlineDetail($id: String!) {
    AdminTrustlineDetail(id: $id) {
      id
      commonStatus
      currency
      amount
      createdAt
      updatedAt
    }
  }
`;

export const LEDGER_LIST_QUERY = gql`
  query AdminLedgerList($trustline: String, $year: Int, $month: Int) {
    AdminLedgerList(
      trustline: $trustline
      orderBy: "createdAt_DESC"
      year: $year
      month: $month
    ) {
      ledgers {
        id
        transactionType
        accountWallet {
          id
          userId
        }
        destinationWallet {
          id
          userId
        }
        title
        currency
        value
        amount
        createdAt
        updatedAt
        wallet {
          id
          name
        }
      }
      count
    }
  }
`;

export const LEDGER_SYNC_QUERY = gql`
  mutation AdminETCLedgerAmount($currency: String!) {
    AdminETCLedgerAmount(currency: $currency)
  }
`;
export const LEDGER_USERSYNC_QUERY = gql`
  mutation AdminETCLedgerUserAmount($user: String!, $currency: String!) {
    AdminETCLedgerUserAmount(user: $user, currency: $currency)
  }
`;

export const WALLET_LIST_QUERY = gql`
  query AdminWalletList($keyword: String, $skip: Int, $first: Int) {
    AdminWalletList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      wallets {
        id
        user {
          id
          commonStatus
        }
        type
        userId
        name
        address
        secret
        trustlines {
          id
          commonStatus
          currency
          amount
          createdAt
        }
        memo
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const WALLET_DETAIL_QUERY = gql`
  query AdminWalletDetail($id: String!) {
    AdminWalletDetail(id: $id) {
      id
      type
      userId
      name
      address
      secret
      trustlines {
        id
      }
      memo
      createdAt
      updatedAt
    }
  }
`;

/* 가맹점 설정 */
export const STORE_CONFIG_QUERY = gql`
  query AdminStoreConfig($id: String) {
    AdminStoreConfig(id: $id) {
      id
      name
      listCount
      pageCount
    }
  }
`;

export const STORE_CONFIG_WRITE_QUERY = gql`
  mutation AdminStoreConfigWrite(
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminStoreConfigWrite(
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

export const STORE_CONFIG_EDIT_QUERY = gql`
  mutation AdminStoreConfigEdit(
    $id: String
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminStoreConfigEdit(
      id: $id
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

/* 가맹점 분류 */
export const STORE_CATEGORY_LIST_QUERY = gql`
  query AdminStoreCategoryList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreCategoryList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      storeCategories {
        id
        storesCount
        pcIcon
        mobileIcon
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const STORE_CATEGORY_WRITE_QUERY = gql`
  mutation AdminStoreCategoryWrite(
    $pcIcon: String!
    $mobileIcon: String!
    $name: String!
  ) {
    AdminStoreCategoryWrite(
      pcIcon: $pcIcon
      mobileIcon: $mobileIcon
      name: $name
    )
  }
`;

export const STORE_CATEGORY_DETAIL_QUERY = gql`
  query AdminStoreCategoryDetail($id: String!) {
    AdminStoreCategoryDetail(id: $id) {
      id
      pcIcon
      mobileIcon
      name
      createdAt
      updatedAt
    }
  }
`;

export const STORE_CATEGORY_EDIT_QUERY = gql`
  mutation AdminStoreCategoryEdit(
    $id: String
    $pcIcon: String!
    $mobileIcon: String!
    $name: String!
  ) {
    AdminStoreCategoryEdit(
      id: $id
      pcIcon: $pcIcon
      mobileIcon: $mobileIcon
      name: $name
    )
  }
`;

export const STORE_CATEGORY_DELETE_QUERY = gql`
  mutation AdminStoreCategoryDelete($id: String!) {
    AdminStoreCategoryDelete(id: $id)
  }
`;

/* 가맹점 */
export const STORE_LIST_QUERY = gql`
  query AdminStoreList(
    $storeCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminStoreList(
      storeCategory: $storeCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id

        # storeConfig
        storeCategory {
          id
          name
        }
        storeProductsCount

        storeLogo
        name
        productInfo
        storeInfo

        storeOperationHour
        storePersonalDay
        storeTel
        storeCEO

        storeZipcode
        storeRoadAddress
        storeJibunAddress
        storeAddressDetail

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const STORE_WRITE_QUERY = gql`
  mutation AdminStoreWrite(
    $storeConfig: String!
    $storeCategory: String!
    $name: String!
    $productInfo: String!
    $storeInfo: String!
    $storeOperationHour: String!
    $storePersonalDay: String!
    $storeTel: String!
    $storeCEO: String!
    $storeZipcode: String!
    $storeRoadAddress: String!
    $storeJibunAddress: String!
    $storeAddressDetail: String!
  ) {
    AdminStoreWrite(
      storeConfig: $storeConfig

      storeCategory: $storeCategory
      name: $name
      productInfo: $productInfo
      storeInfo: $storeInfo

      storeOperationHour: $storeOperationHour
      storePersonalDay: $storePersonalDay
      storeTel: $storeTel
      storeCEO: $storeCEO

      storeZipcode: $storeZipcode
      storeRoadAddress: $storeRoadAddress
      storeJibunAddress: $storeJibunAddress
      storeAddressDetail: $storeAddressDetail
    )
  }
`;

export const STORE_DETAIL_QUERY = gql`
  query AdminStoreDetail($id: String!) {
    AdminStoreDetail(id: $id) {
      id

      # storeConfig
      storeCategory {
        id
        name
      }

      storeLogo
      name
      productInfo
      storeInfo

      storeOperationHour
      storePersonalDay
      storeTel
      storeCEO

      storeZipcode
      storeRoadAddress
      storeJibunAddress
      storeAddressDetail
      createdAt
      updatedAt
    }
  }
`;

export const STORE_DETAIL_BY_USERID_QUERY = gql`
  query AdminStoreDetailByUserId($UserId: String!) {
    AdminStoreDetailByUserId(UserId: $UserId) {
      id

      # storeConfig
      storeCategory {
        id
        name
      }

      storeLogo
      name
      productInfo
      storeInfo

      storeOperationHour
      storePersonalDay
      storeTel
      storeCEO

      storeZipcode
      storeRoadAddress
      storeJibunAddress
      storeAddressDetail
      createdAt
      updatedAt
    }
  }
`;

export const STORE_EDIT_QUERY = gql`
  mutation AdminStoreEdit(
    $id: String
    $storeCategory: String!
    $name: String!
    $productInfo: String!
    $storeInfo: String!
    $storeOperationHour: String!
    $storePersonalDay: String!
    $storeTel: String!
    $storeCEO: String!
    $storeZipcode: String!
    $storeRoadAddress: String!
    $storeJibunAddress: String!
    $storeAddressDetail: String!
  ) {
    AdminStoreEdit(
      id: $id

      storeCategory: $storeCategory
      name: $name
      productInfo: $productInfo
      storeInfo: $storeInfo

      storeOperationHour: $storeOperationHour
      storePersonalDay: $storePersonalDay
      storeTel: $storeTel
      storeCEO: $storeCEO

      storeZipcode: $storeZipcode
      storeRoadAddress: $storeRoadAddress
      storeJibunAddress: $storeJibunAddress
      storeAddressDetail: $storeAddressDetail
    )
  }
`;

export const STORE_EDIT_LOGO_QUERY = gql`
  mutation AdminStoreEditLogo($id: String!, $file: FileUpload!) {
    AdminStoreEditLogo(id: $id, file: $file)
  }
`;

export const STORE_DELETE_LOGO_QUERY = gql`
  mutation AdminStoreDeleteLogo($id: String!) {
    AdminStoreDeleteLogo(id: $id)
  }
`;

export const STORE_DELETE_QUERY = gql`
  mutation AdminStoreDelete($id: String!) {
    AdminStoreDelete(id: $id)
  }
`;

/* 가맹점 상품 */
export const STORE_PRODUCT_LIST_QUERY = gql`
  query AdminStoreProductList(
    $store: String!
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminStoreProductList(
      store: $store
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      storeProducts {
        id
        storeProductImage
        name
        description
        price
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const STORE_PRODUCT_WRITE_QUERY = gql`
  mutation AdminStoreProductWrite(
    $store: String!
    $file: FileUpload!
    $name: String!
    $description: String!
    $price: String!
  ) {
    AdminStoreProductWrite(
      store: $store
      file: $file
      name: $name
      description: $description
      price: $price
    )
  }
`;

export const STORE_PRODUCT_DETAIL_QUERY = gql`
  query AdminStoreProductDetail($id: String!) {
    AdminStoreProductDetail(id: $id) {
      id
      storeProductImage
      name
      description
      price
      createdAt
      updatedAt
    }
  }
`;

export const STORE_PRODUCT_EDIT_QUERY = gql`
  mutation AdminStoreProductEdit(
    $store: String!
    $id: String!
    $file: FileUpload
    $name: String!
    $description: String!
    $price: String!
  ) {
    AdminStoreProductEdit(
      store: $store
      id: $id
      file: $file
      name: $name
      description: $description
      price: $price
    )
  }
`;

export const STORE_PRODUCT_DELETE_QUERY = gql`
  mutation AdminStoreProductDelete($id: String!) {
    AdminStoreProductDelete(id: $id)
  }
`;

export const TRUSTLINE_REMITTANCE_QUERY = gql`
  mutation AdminTrustlineRemittance(
    $accountId: String!
    $destinationId: String!
    $currency: String!
    $value: String!
  ) {
    AdminTrustlineRemittance(
      accountId: $accountId
      destinationId: $destinationId
      currency: $currency
      value: $value
    )
  }
`;
