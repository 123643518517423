import React from "react";
import ReactMoment from "react-moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import { graphqlUri } from "../../../../Config/Env";

const Presenter = ({
  history,
  match,
  location,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,

  prevMove,
  nextMove,
}) => {
  return (
    <>
      <Helmet>
        <title>배너 관리 | 한밭페이 </title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">선물</li>
          <li className="breadcrumb-item active">배너</li>
        </ol>
        <h1 className="page-header">배너</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminBannerList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(제목) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              {!loading && data.AdminBannerList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col width="15%" />
                          <col width="*" />
                          <col width="15%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">이미지</th>
                            <th className="text-center">배너정보</th>
                            <th className="text-center">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminBannerList.banners.map((item) => {
                            const bannerCoverImage =
                              item.coverImage === ""
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${item.coverImage}`;
                            return (
                              <tr key={item.id}>
                                <td>
                                  <div className="responsive_img_area">
                                    <div className="img_item">
                                      <img
                                        src={bannerCoverImage}
                                        alt={item.name}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div>
                                    <Link
                                      to={`/banners/${item.id}${location.search}`}
                                    >
                                      <div className="widget-list-title m-t-5">
                                        제목 : {item.title}
                                      </div>
                                      <div className="widget-list-title m-t-5">
                                        상태 :{" "}
                                        {item.commonStatus === "S" && "비공개"}
                                        {item.commonStatus === "C" && "공개"}
                                      </div>
                                      <div className="widget-list-title m-t-5">
                                        Group : {item.group}
                                      </div>
                                      <div className="widget-list-title m-t-5">
                                        Stack : {item.stack}
                                      </div>
                                      <div className="widget-list-title m-t-5">
                                        Screen : {item.screen}
                                      </div>
                                      <div className="widget-list-title m-t-5">
                                        작성일시 :
                                        <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                          {item.createdAt}
                                        </ReactMoment>
                                      </div>
                                    </Link>
                                  </div>
                                </td>
                                <td className="text-nowrap text-center valign-middle">
                                  <div className="btn-group-vertical">
                                    <button
                                      type="button"
                                      className="btn btn-white"
                                      onClick={() => prevMove(item.id)}
                                    >
                                      <i className="fa fa-arrow-up"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-white"
                                      onClick={() => nextMove(item.id)}
                                    >
                                      <i className="fa fa-arrow-down"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminBannerList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/banners/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};

export default Presenter;
