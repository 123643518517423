import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import { commonStatusName } from "../../../../../Components/User/UserNames";

const List = ({
  history,
  match,
  location,
  config,
  page,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
}) => {
  return (
    <>
      <Helmet>
        <title>CMS충전내역 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/chargeRequestConfigs">충전내역</Link>
          </li>
          <li className="breadcrumb-item active">CMS충전내역</li>
        </ol>
        <h1 className="page-header">CMS충전내역</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록(
                {!loading && data.AdminChargeRequestCMSList.count}건)
              </PanelHeader>
              <PanelBody>
                {!loading && data.AdminChargeRequestCMSList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>연도</th>
                            <th>월</th>
                            <th>파일정보</th>
                            <th>등록일시</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminChargeRequestCMSList.chargeRequestCMses.map(
                            (chargeRequestCMS) => {
                              return (
                                <tr key={chargeRequestCMS.id}>
                                  <td>
                                    {commonStatusName(
                                      chargeRequestCMS.commonStatus
                                    )}
                                  </td>
                                  <td>{chargeRequestCMS.year}</td>
                                  <td>{chargeRequestCMS.month}</td>
                                  <td>{chargeRequestCMS.file}</td>
                                  <td>
                                    <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                      {chargeRequestCMS.createdAt}
                                    </ReactMoment>
                                  </td>
                                  <td align={"center"}>
                                    <Link
                                      to={`/chargeRequestCMses/${chargeRequestCMS.id}${location.search}`}
                                      className="btn btn-white btn-xs text-green"
                                    >
                                      관리
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="row p-t-15">
                      <div className="col-md-12">
                        <Pagination
                          totalRecord={data.AdminChargeRequestCMSList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/chargeRequestCMses/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-upload"></i>
      </Link>
    </>
  );
};

export default List;
