/*global kakao*/
import React, { useEffect } from "react";

const KakaoMap = ({ address, longitude, latitude }) => {
  useEffect(() => {
    var markerPosition = new kakao.maps.LatLng(latitude, longitude);
    // var markerPosition = new kakao.maps.LatLng(36.35069, 127.384787);

    var container = document.getElementById("map");
    var options = {
      center: markerPosition,
      level: 2,
    };
    var map = new kakao.maps.Map(container, options);

    var marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);

    if (address) {
      var iwContent = `<div style="width:150px; text-align:center;">${address}</div>`,
        iwPosition = new kakao.maps.LatLng(33.450701, 126.570667);

      var infowindow = new kakao.maps.InfoWindow({
        position: iwPosition,
        content: iwContent,
      });

      infowindow.open(map, marker);
    }
  }, [address, longitude, latitude]);

  return (
    <div>
      <div id="map" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
};

export default KakaoMap;
