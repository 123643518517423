import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  commonStatusName,
  currencyName,
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import ReactMoment from "react-moment";

const Presenter = ({
  history,
  match,
  location,
  id,
  user,
  data,
  loading,

  isModal,
}) => {
  const modalRef = React.createRef();

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
            );
          }}
        >
          참여 목록 ({data.AdminCouponUserStoreOrderList.count}건)
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox ">
            <div className="vertical-box-inner-cell overflow-visible height-md">
              <PerfectScrollbar
                className="height-full p-15"
                options={{ suppressScrollX: true }}
              >
                <div className="table-responsive">
                  <table className="table table-bordered table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>상태</th>
                        <th>이름(아이디)</th>
                        <th>가맹점</th>
                        <th>금액</th>
                        <th>주문일자</th>
                      </tr>
                    </thead>
                    {!loading &&
                    data.AdminCouponUserStoreOrderList.count > 0 ? (
                      <tbody>
                        {data.AdminCouponUserStoreOrderList.storeOrders.map(
                          (item) => {
                            return (
                              <tr key={item.id}>
                                <td>{commonStatusName(item.commonStatus)}</td>
                                <td>
                                  {item.accountUser.name}(
                                  {item.accountUser.userId})
                                </td>
                                <td>{item.store.name}</td>
                                <td align="right">
                                  <NumberFormat
                                    value={item.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(item.currency)}
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {item.createdAt}
                                  </ReactMoment>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={3}>
                            <p className="m-40 text-center">
                              등록된 사용자가 없습니다.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Presenter;
