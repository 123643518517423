import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import {
  USER_DETAIL_QUERY,
  TRUSTLINE_DETAIL_QUERY,
  LEDGER_LIST_QUERY,
} from "../../Queries";
// import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  let currentDate =
    queryString.year && queryString.month
      ? new Date(queryString.year, queryString.month - 1, 1, 0, 0, 0)
      : new Date();
  // if (queryString.year && queryString.month) {
  //   currentDate.setFullYear(queryString.year);
  //   currentDate.setMonth(queryString.month - 1);
  //   currentDate.setDate(1);
  //   console.log(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth(),
  //     currentDate.getDate()
  //   );
  // }

  const date = currentDate;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const prevDate = new Date(date.setMonth(month - 1));
  const prevYear = prevDate.getFullYear();
  const prevMonth = prevDate.getMonth();

  const nextDate = new Date(date.setMonth(month + 1));
  const nextYear = nextDate.getFullYear();
  const nextMonth = nextDate.getMonth();

  // console.log("prev : " + prevYear, prevMonth);
  console.log("current : " + year, month);
  // console.log("next : " + nextYear, nextMonth);

  const { data: user } = useQuery(USER_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });

  const { data: trustline } = useQuery(TRUSTLINE_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.trustline },
  });

  const { data, loading } = useQuery(LEDGER_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      trustline: match.params.trustline,
      year: year,
      month: month,
    },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminLedgerList) {
    return (
      <>
        <Presenter
          history={history}
          match={match}
          location={location}
          user={user}
          trustline={trustline}
          data={data}
          year={year}
          month={month}
          prevYear={prevYear}
          prevMonth={prevMonth}
          nextYear={nextYear}
          nextMonth={nextMonth}
        ></Presenter>
      </>
    );
  }
});
