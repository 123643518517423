import { gql } from "apollo-boost";

/* 상품 분류 시작 */
export const STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY = gql`
  query StoreDeliveryProductCategoryList($skip: Int!, $take: Int) {
    StoreDeliveryProductCategoryList(skip: $skip, take: $take) {
      deliveryProductCategories {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order

        deliveryProductsCount
      }
      count
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_CATEGORY_WRITE_MUTATION = gql`
  mutation StoreDeliveryProductCategoryWrite(
    $commonStatus: String!
    $iconImage: FileUpload
    $name: String!
  ) {
    StoreDeliveryProductCategoryWrite(
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_CATEGORY_DETAIL_QUERY = gql`
  query StoreDeliveryProductCategoryDetail($id: String!) {
    StoreDeliveryProductCategoryDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      iconImage
      name
      order

      deliveryProductsCount
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_CATEGORY_EDIT_MUTATION = gql`
  mutation StoreDeliveryProductCategoryEdit(
    $id: String!
    $commonStatus: String!
    $iconImage: FileUpload
    $name: String!
  ) {
    StoreDeliveryProductCategoryEdit(
      id: $id
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_CATEGORY_MOVE_MUTATION = gql`
  mutation StoreDeliveryProductCategoryMove(
    $id: String!
    $target: String!
    $position: String!
  ) {
    StoreDeliveryProductCategoryMove(
      id: $id
      target: $target
      position: $position
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_CATEGORY_DELETE_MUTATION = gql`
  mutation StoreDeliveryProductCategoryDelete($id: String!) {
    StoreDeliveryProductCategoryDelete(id: $id)
  }
`;
/* 상품 분류 끝 */

/* 상품 시작 */
export const STORE_DELIVERY_PRODUCT_LIST_QUERY = gql`
  query StoreDeliveryProductList(
    $skip: Int!
    $take: Int
    $keyword: String
    $deliveryProductCategory_id: String
    $commonStatus: String
  ) {
    StoreDeliveryProductList(
      skip: $skip
      take: $take
      keyword: $keyword
      deliveryProductCategory_id: $deliveryProductCategory_id
      commonStatus: $commonStatus
    ) {
      deliveryProducts {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        price
        name
        description

        deliveryProductCategory {
          id
          createdAt
          updatedAt

          commonStatus
          iconImage
          name
          order
        }
        deliveryProductOptions {
          id
          createdAt
          updatedAt

          commonStatus
          name
          deliveryProductOptionDetails {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_WRITE_MUTATION = gql`
  mutation StoreDeliveryProductWrite(
    $commonStatus: String!
    $coverImage: FileUpload
    $timestamp: String
    $price: String
    $name: String
    $description: String
    $deliveryProductCategory_id: String
  ) {
    StoreDeliveryProductWrite(
      commonStatus: $commonStatus
      coverImage: $coverImage
      timestamp: $timestamp
      price: $price
      name: $name
      description: $description
      deliveryProductCategory_id: $deliveryProductCategory_id
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_DETAIL_QUERY = gql`
  query StoreDeliveryProductDetail($id: String!) {
    StoreDeliveryProductDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      coverImage
      timestamp
      price
      name
      description

      deliveryProductCategory {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order
      }
      deliveryProductOptions {
        id
        createdAt
        updatedAt

        commonStatus
        name
        deliveryProductOptionDetails {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_EDIT_MUTATION = gql`
  mutation StoreDeliveryProductEdit(
    $id: String!
    $commonStatus: String!
    $price: String
    $name: String
    $description: String
    $deliveryProductCategory_id: String
  ) {
    StoreDeliveryProductEdit(
      id: $id
      commonStatus: $commonStatus
      price: $price
      name: $name
      description: $description
      deliveryProductCategory_id: $deliveryProductCategory_id
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_COVER_UPLOAD_MUTATION = gql`
  mutation StoreDeliveryProductCoverImageUpload(
    $id: String!
    $coverImage: FileUpload!
  ) {
    StoreDeliveryProductCoverImageUpload(id: $id, coverImage: $coverImage) {
      id
      coverImage
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_COVER_DELETE_MUTATION = gql`
  mutation StoreDeliveryProductCoverImageDelete($id: String!) {
    StoreDeliveryProductCoverImageDelete(id: $id)
  }
`;
export const STORE_DELIVERY_PRODUCT_DELETE_MUTATION = gql`
  mutation StoreDeliveryProductCoverImageDelete($id: String!) {
    StoreDeliveryProductCoverImageDelete(id: $id)
  }
`;
/* 상품 끝 */

/* 상품 옵션 시작 */
export const STORE_DELIVERY_PRODUCT_OPTION_WRITE_MUTATION = gql`
  mutation StoreDeliveryProductOptionWrite(
    $deliveryProduct_id: String!
    $commonStatus: String!
    $name: String!
    $detailCommonStatus: String!
    $detailPrice: String!
    $detailName: String!
  ) {
    StoreDeliveryProductOptionWrite(
      deliveryProduct_id: $deliveryProduct_id
      commonStatus: $commonStatus
      name: $name
      detailCommonStatus: $detailCommonStatus
      detailPrice: $detailPrice
      detailName: $detailName
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_OPTION_DETAIL_QUERY = gql`
  query StoreDeliveryProductOptionDetail($id: String!) {
    StoreDeliveryProductOptionDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      name
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_OPTION_EDIT_MUTATION = gql`
  mutation StoreDeliveryProductOptionEdit(
    $id: String!
    $commonStatus: String!
    $name: String!
  ) {
    StoreDeliveryProductOptionEdit(
      id: $id
      commonStatus: $commonStatus
      name: $name
    )
  }
`;
/* 상품 옵션 끝 */

/* 상품 상세 옵션 시작 */
export const STORE_DELIVERY_PRODUCT_OPTION_DETAIL_WRITE_MUTATION = gql`
  mutation StoreDeliveryProductOptionDetailWrite(
    $deliveryProduct_id: String!
    $deliveryProductOption_id: String!
    $commonStatus: String!
    $price: String!
    $name: String!
  ) {
    StoreDeliveryProductOptionDetailWrite(
      deliveryProduct_id: $deliveryProduct_id
      deliveryProductOption_id: $deliveryProductOption_id
      commonStatus: $commonStatus
      price: $price
      name: $name
    )
  }
`;
export const STORE_DELIVERY_PRODUCT_OPTION_DETAIL_DETAIL_QUERY = gql`
  query StoreDeliveryProductOptionDetailDetail($id: String!) {
    StoreDeliveryProductOptionDetailDetail(id: $id) {
      id
      commonStatus
      price
      name
      createdAt
      updatedAt
    }
  }
`;
export const STORE_DELIVERY_PRODUCT_OPTION_DETAIL_EDIT_MUTATION = gql`
  mutation StoreDeliveryProductOptionDetailEdit(
    $id: String!
    $commonStatus: String!
    $price: String!
    $name: String!
  ) {
    StoreDeliveryProductOptionDetailEdit(
      id: $id
      commonStatus: $commonStatus
      price: $price
      name: $name
    )
  }
`;
/* 상품 상세 옵션 끝 */
