import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_INQUIRY_SECRET_TOGGLE_QUERY,
  TICKET_PRODUCT_INQUIRY_DELETE_QUERY,
  TICKET_PRODUCT_INQUIRY_LIST_QUERY,
} from "../Queries";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";
import SearchProduct from "./SearchProduct";
import { toast } from "react-toastify";

export default ({
  history,
  match,
  location,
  page,
  ticketProduct,
  keyword,
  keywordInput,
  blockSize,
  first,
  skip,
  data,
  loading,
  baseUrl,
  handleSearch,
}) => {
  const [ticketProductInquirySecretToggleMutation] = useMutation(
    TICKET_PRODUCT_INQUIRY_SECRET_TOGGLE_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_INQUIRY_LIST_QUERY,
          variables: {
            keyword,
            skip,
            first,
          },
        },
      ],
    }
  );
  const [ticketProductInquiryDeleteMutation] = useMutation(
    TICKET_PRODUCT_INQUIRY_DELETE_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_INQUIRY_LIST_QUERY,
          variables: {
            keyword,
            skip,
            first,
          },
        },
      ],
    }
  );

  const onToggleSecret = async (id) => {
    console.log(id);
    try {
      const {
        data: { AdminTicketProductSecretToggle },
      } = await ticketProductInquirySecretToggleMutation({
        variables: {
          id,
        },
      });
      if (AdminTicketProductSecretToggle) {
        toast.success("처리 되었습니다.");
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  const onDelete = async (id) => {
    console.log(id);

    if (!window.confirm("삭제 하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminTicketProductInquiryDelete },
      } = await ticketProductInquiryDeleteMutation({
        variables: {
          id,
        },
      });
      if (AdminTicketProductInquiryDelete) {
        toast.success("처리 되었습니다.");
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>문의 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림상점</li>
          <li className="breadcrumb-item active">문의</li>
        </ol>
        <h1 className="page-header">문의</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminTicketProductInquiryList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row m-b-15">
                  <div className="col-xs-12 select">
                    <SearchProduct ticketProduct={ticketProduct} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(작성자) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody className="">
                {!loading && data.AdminTicketProductInquiryList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "15%" }} />
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "30%" }} />
                          <col style={{ width: "15%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">상태</th>
                            <th className="text-center">이미지</th>
                            <th className="text-center">
                              티켓정보 (정가 - 할인가 = 가격)
                            </th>
                            <th className="text-center">문의정보</th>
                            <th className="text-center">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminTicketProductInquiryList.ticketProductInquiries.map(
                            (item, index) => {
                              const CoverImage =
                                item.ticketProduct.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.ticketProduct.coverImage}`;

                              return (
                                <tr key={index}>
                                  <td className="text-center">
                                    {item.commonStatus === "S" && (
                                      <div>
                                        <span className="label label-yellow m-b-5">
                                          답변대기
                                        </span>
                                      </div>
                                    )}
                                    {item.commonStatus === "C" && (
                                      <div>
                                        <span className="label label-primary m-b-5">
                                          답변완료
                                        </span>
                                      </div>
                                    )}
                                    {item.commonStatus === "D" && (
                                      <div>
                                        <span className="label label-danger m-b-5">
                                          삭제
                                        </span>
                                      </div>
                                    )}{" "}
                                    {item.isSecret ? (
                                      <div>
                                        <span className="label label-secondary">
                                          비밀글
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="label label-info ">
                                          일반글
                                        </span>
                                      </div>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div
                                      className="product-img"
                                      style={{
                                        backgroundImage: `url(${CoverImage})`,
                                      }}
                                    >
                                      <img
                                        src={CoverImage}
                                        alt={item.ticketProduct.name}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      가 맹 점 : {item.ticketProduct.store.name}
                                    </div>
                                    <div>
                                      티 켓 명 :{" "}
                                      <b>
                                        [
                                        {item.ticketProduct.ticketCategory
                                          .parentCategory
                                          ? `${item.ticketProduct.ticketCategory.parentCategory.name} &gt; ${item.ticketProduct.ticketCategory.name}`
                                          : `${item.ticketProduct.ticketCategory.name}`}
                                        ]{item.ticketProduct.name}
                                      </b>
                                    </div>
                                    <div>
                                      가 격 :{" "}
                                      <NumberFormat
                                        displayType={"text"}
                                        value={item.ticketProduct.price}
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                      />{" "}
                                      -{" "}
                                      <NumberFormat
                                        displayType={"text"}
                                        value={
                                          item.ticketProduct.isDiscount
                                            ? item.ticketProduct.discountPrice
                                            : 0
                                        }
                                        thousandSeparator={true}
                                        suffix={"드림"}
                                      />{" "}
                                      ={" "}
                                      <b>
                                        <NumberFormat
                                          displayType={"text"}
                                          value={item.ticketProduct.totalPrice}
                                          thousandSeparator={true}
                                          suffix={"드림"}
                                        />
                                      </b>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        width: "300px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        display: "block",
                                        fontWeight: "400",
                                        letterSpacing: "-.5px",
                                      }}
                                    >
                                      질 문 : {item.question}
                                    </div>
                                    <div>
                                      작 성 자 : {item.user.name}(
                                      {item.user.userId})
                                    </div>
                                    <div>
                                      작성일시 :{" "}
                                      <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                        {item.createdAt}
                                      </ReactMoment>
                                    </div>
                                    {item.commonStatus === "C" && (
                                      <div
                                        style={{
                                          width: "300px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                          fontWeight: "400",
                                          letterSpacing: "-.5px",
                                        }}
                                      >
                                        답 변 : {item.answer}
                                      </div>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div>
                                      <Link
                                        to={`/ticketProductInquiries/${item.id}`}
                                        className="btn btn-white btn-xs text-primary m-b-5"
                                      >
                                        <i className="fa fa-reply fa-fw" /> 답변
                                      </Link>
                                    </div>
                                    {item.isSecret ? (
                                      <div>
                                        <button
                                          onClick={() =>
                                            onToggleSecret(item.id)
                                          }
                                          className="btn btn-white btn-xs text-info m-b-5"
                                        >
                                          <i className="fa fa-unlock fa-fw"></i>{" "}
                                          일반글
                                        </button>
                                      </div>
                                    ) : (
                                      <div>
                                        <button
                                          onClick={() =>
                                            onToggleSecret(item.id)
                                          }
                                          className="btn btn-white btn-xs text-secondary m-b-5"
                                        >
                                          <i className="fa fa-lock fa-fw"></i>{" "}
                                          비밀글
                                        </button>
                                      </div>
                                    )}
                                    <div>
                                      <button
                                        className="btn btn-white btn-xs text-danger"
                                        onClick={() => onDelete(item.id)}
                                      >
                                        <i className="fa fa-trash fa-fw"></i>{" "}
                                        삭제
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.AdminTicketProductInquiryList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.AdminTicketProductInquiryList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
