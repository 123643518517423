import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_BOX_DETAIL_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;
  const baseUrl = `/delivery/deliveryBoxes/${id}`;

  const { data, loading, error } = useQuery(STORE_DELIVERY_BOX_DETAIL_QUERY, {
    variables: { id },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
