import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DONATION_CATEGORY_WRITE_QUERY } from "../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const [donationCategoryWriteMutation] = useMutation(
    DONATION_CATEGORY_WRITE_QUERY
  );
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    try {
      const {
        data: { AdminDonationCategoryWrite }
      } = await donationCategoryWriteMutation({
        variables: {
          pcIcon: data.pcIcon,
          mobileIcon: data.mobileIcon,
          name: data.name
        }
      });
      if (AdminDonationCategoryWrite) {
        toast.success("설정 되었습니다.");
        history.replace(`/donations/categories${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/donations/categories${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`/donations/categories${location.search}`);
            }}
          >
            분류 등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">PC 아이콘</label>
                  <div className="col-md-4">
                    <input
                      type="text"
                      name="pcIcon"
                      className="form-control"
                      placeholder="PC 아이콘을 입력해주세요."
                      ref={register({
                        required: "PC아이콘을 입력해주세요."
                      })}
                    />
                    {errors.pcIcon && <span>{errors.pcIcon.message}</span>}
                  </div>
                  <label className="col-form-label col-md-2">
                    Mobile 아이콘
                  </label>
                  <div className="col-md-4">
                    <input
                      type="text"
                      name="mobileIcon"
                      className="form-control"
                      placeholder="Mobile 아이콘을 입력해주세요."
                      ref={register({
                        required: "Mobile 아이콘을 입력해주세요."
                      })}
                    />
                    {errors.mobileIcon && (
                      <span>{errors.mobileIcon.message}</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요."
                      })}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-4 text-left">
              <Link
                to={`/donations/categories${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
            <div className="col-xs-4 text-right"></div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
