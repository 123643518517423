import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { FEEHASUSER_EXCLUSIVEUSER_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  return <Presenter history={history} match={match} location={location} />;

  // const id = match.params.id;

  // const handleSearch = () => {
  //   refetch();
  // };

  // if (!loading) {
  //   return (
  //   );
  // } else {
  //   return (
  //     <div className="panel-loader">
  //       <span className="spinner-small"></span>
  //     </div>
  //   );
  // }
});
