import { gql } from "apollo-boost";

/* 픽업장소 시작 */
export const STORE_DELIVERY_PLACE_LIST_QUERY = gql`
  query StoreDeliveryPlaceList(
    $skip: Int!
    $take: Int
    $keyword: String
    $dayOfWeek: String
  ) {
    StoreDeliveryPlaceList(
      skip: $skip
      take: $take
      keyword: $keyword
      dayOfWeek: $dayOfWeek
    ) {
      deliveryPlaces {
        id
        createdAt
        updatedAt

        name
        managerName
        managerPhone
        zipcode
        roadAddress
        jibunAddress
        addressDetail
        longitude
        latitude
        dayOfWeek

        deliveryStore {
          id
          createdAt
          updatedAt

          store {
            id
            createdAt
            updatedAt

            name
          }
          user {
            id
            createdAt
            updatedAt

            name
          }
        }

        deliveryBoxesCount
      }
      count
    }
  }
`;
export const STORE_DELIVERY_PLACE_WRITE_MUTATION = gql`
  mutation StoreDeliveryPlaceWrite(
    $name: String!
    $managerName: String!
    $managerPhone: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String!
    $latitude: String!
    $dayOfWeek: String!
    $deliveryStore_id: String!
  ) {
    StoreDeliveryPlaceWrite(
      name: $name
      managerName: $managerName
      managerPhone: $managerPhone
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      deliveryStore_id: $deliveryStore_id
    )
  }
`;
export const STORE_DELIVERY_PLACE_DETAIL_QUERY = gql`
  query StoreDeliveryPlaceDetail($id: String!) {
    StoreDeliveryPlaceDetail(id: $id) {
      id
      createdAt
      updatedAt

      name
      managerName
      managerPhone
      zipcode
      roadAddress
      jibunAddress
      addressDetail
      longitude
      latitude
      dayOfWeek

      deliveryStore {
        id
        createdAt
        updatedAt

        store {
          id
          createdAt
          updatedAt

          name
        }
        user {
          id
          createdAt
          updatedAt

          name
        }
      }
    }
  }
`;
export const STORE_DELIVERY_PLACE_EDIT_MUTATION = gql`
  mutation StoreDeliveryPlaceEdit(
    $id: String!
    $name: String!
    $managerName: String!
    $managerPhone: String!
    $zipcode: String!
    $jibunAddress: String!
    $roadAddress: String!
    $addressDetail: String!
    $longitude: String!
    $latitude: String!
    $dayOfWeek: String!
    $deliveryStore_id: String!
  ) {
    StoreDeliveryPlaceEdit(
      id: $id
      name: $name
      managerName: $managerName
      managerPhone: $managerPhone
      zipcode: $zipcode
      jibunAddress: $jibunAddress
      roadAddress: $roadAddress
      addressDetail: $addressDetail
      longitude: $longitude
      latitude: $latitude
      dayOfWeek: $dayOfWeek
      deliveryStore_id: $deliveryStore_id
    )
  }
`;
export const STORE_DELIVERY_STORE_DELETE_MUTATION = gql`
  mutation StoreDeliveryStoreDelete($id: String!) {
    StoreDeliveryStoreDelete(id: $id)
  }
`;
/* 픽업장소 끝 */
