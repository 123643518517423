import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_GRU_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faReceipt,
  faMoneyBill,
  faSackDollar,
  faCreditCardBlank,
  faHeart,
  faAddressCard,
  faGift,
  faGiftCard,
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import PerfectScrollbar from "react-perfect-scrollbar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import DatePicker from "../../../Containers/SelectedPeriod/DatePicker";
import { pastPeriod } from "../../../Components/User/UserNames";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const startDate = queryString.startDate
    ? queryString.startDate
    : Moment().subtract(1, "months").format("YYYY-MM-DD");
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().format("YYYY-MM-DD");

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const { data, loading } = useQuery(STATISTICS_GRU_QUERY, {
    variables: {
      startDate,
      endDate,
    },
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  const {
    AdminStatisticsGRU: {
      GRUTotal,
      GRUExchangeTotal,
      GRUWithdrawalTotal,
      GRUUserTotal,

      ChargeRequestGRUUserCount,
      ChargeRequestGRUCount,
      ChargeRequestGRUTotal,

      PaymentCount,
      PaymentTotal,
      RemittanceCount,
      RemittanceTotal,
      ExchangeRequestCount,
      ExchangeRequestTotal,

      usersCharge,
      usersStoreOrder,
      usersStoreReturn,
      usersRemittance,

      PaymentStores,
      ExchangeRequestStores,
    },
  } = data;
  return (
    <>
      <Helmet>
        <title>통계 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/statisticsUser">통계</Link>
          </li>
          <li className="breadcrumb-item active">그루</li>
        </ol>
        <h1 className="page-header">그루</h1>

        <div className="m-b-10 m-t-10 f-s-10">
          <b className="text-inverse f-s-18">전체</b>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faSackDollar} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={GRUTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  그루
                </div>
                <div className="stats-desc f-s-15">총 발행량</div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faSackDollar} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={GRUExchangeTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  그루
                </div>
                <div className="stats-desc f-s-15">가맹점 환전금 (관리자)</div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faSackDollar} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={GRUUserTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  그루
                </div>
                <div className="stats-desc f-s-15">
                  이용자 보유금 (관리자 제외)
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faSackDollar} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={GRUWithdrawalTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림
                </div>
                <div className="stats-desc f-s-15">탈퇴자 환전금</div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-xl-12">
            <DatePicker
              startDate={new Date(startDate)}
              endDate={new Date(endDate)}
              onStartDateSelect={onStartDateSelect}
              onEndDateSelect={onEndDateSelect}
              onPeriodConfig={onPeriodConfig}
              period={pastPeriod}
            />
          </div>
        </div>

        <div className="m-b-10 m-t-10 f-s-10">
          <b className="text-inverse f-s-18">충전 현황(충전내역 기준)</b>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  {ChargeRequestGRUUserCount}명
                </div>
                <div className="stats-desc f-s-15">충전인원</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faMoneyBill} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={ChargeRequestGRUTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  그루({ChargeRequestGRUCount}건)
                </div>
                <div className="stats-desc f-s-15">충전금액(건)</div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="m-b-10 m-t-10 f-s-10">
          <b className="text-inverse f-s-18">소비자 현황</b>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faReceipt} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={PaymentTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림({PaymentCount}건)
                </div>
                <div className="stats-desc f-s-15">결제금액(건)</div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="widget widget-stats">
              <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                <FontAwesomeIcon icon={faCreditCardBlank} />
              </div>
              <div className="stats-content">
                <div className="stats-number text-primary">
                  <NumberFormat
                    value={RemittanceTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림({RemittanceCount}건)
                </div>
                <div className="stats-desc f-s-15">송금금액(건)</div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="vertical-box with-grid inbox">
              <div className="vertical-box-inner-cell overflow-visible height-420">
                <PerfectScrollbar
                  className="height-full p-5"
                  options={{ suppressScrollX: true }}
                >
                  {usersCharge.filter((item) => item.ChargeTotal > 0).length >
                  0 ? (
                    <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                      <div
                        className="card-body text-right"
                        style={{
                          background: "no-repeat bottom left",
                          backgroundImage: "url(/assets/img/svg/img-4.svg)",
                          backgroundSize: "auto 60%",
                        }}
                      >
                        <div className="m-b-10 m-t-10 f-s-10">
                          <h3 style={{ textAlign: "left" }}>충전 TOP 10</h3>
                        </div>
                      </div>
                      <div className="widget-list widget-list-rounded ">
                        {usersCharge
                          .filter((item) => item.ChargeTotal > 0)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="widget-list-item rounded-0 p-t-3"
                              >
                                <div className="widget-list-content">
                                  <div className="widget-list-title">
                                    {item.ChargeTotal > 0
                                      ? `${index + 1}.${item.name}(${
                                          item.userId
                                        })`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="widget-list-action text-nowrap text-right">
                                  <NumberFormat
                                    value={item.ChargeTotal}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림({item.ChargeCount}건)
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="vertical-box with-grid inbox">
                      <div className="vertical-box-inner-cell overflow-visible height-md">
                        <p className="text-center">충전 데이터가 없습니다.</p>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="vertical-box with-grid inbox">
              <div className="vertical-box-inner-cell overflow-visible height-420">
                <PerfectScrollbar
                  className="height-full p-5"
                  options={{ suppressScrollX: true }}
                >
                  {usersStoreOrder.filter((item) => item.StoreOrderTotal > 0)
                    .length > 0 ? (
                    <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                      <div
                        className="card-body text-right"
                        style={{
                          background: "no-repeat bottom left",
                          backgroundImage: "url(/assets/img/svg/img-4.svg)",
                          backgroundSize: "auto 60%",
                        }}
                      >
                        <div className="m-b-10 m-t-10 f-s-10">
                          <h3 style={{ textAlign: "left" }}>
                            상점결제(환불제외) TOP 10
                          </h3>
                        </div>
                      </div>
                      <div className="widget-list widget-list-rounded ">
                        {usersStoreOrder
                          .filter((item) => item.StoreOrderTotal > 0)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="widget-list-item rounded-0 p-t-3"
                              >
                                <div className="widget-list-content">
                                  <div className="widget-list-title">
                                    {item.StoreOrderTotal > 0
                                      ? `${index + 1}.${item.name}(${
                                          item.userId
                                        })`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="widget-list-action text-nowrap text-right">
                                  <NumberFormat
                                    value={item.StoreOrderTotal}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림({item.StoreOrderCount}건)
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="vertical-box with-grid inbox">
                      <div className="vertical-box-inner-cell overflow-visible height-md">
                        <p className="text-center">
                          상점결제 데이터가 없습니다.
                        </p>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="vertical-box with-grid inbox">
              <div className="vertical-box-inner-cell overflow-visible height-420">
                <PerfectScrollbar
                  className="height-full p-5"
                  options={{ suppressScrollX: true }}
                >
                  {usersStoreReturn.filter((item) => item.StoreReturnTotal > 0)
                    .length > 0 ? (
                    <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                      <div
                        className="card-body text-right"
                        style={{
                          background: "no-repeat bottom left",
                          backgroundImage: "url(/assets/img/svg/img-4.svg)",
                          backgroundSize: "auto 60%",
                        }}
                      >
                        <div className="m-b-10 m-t-10 f-s-10">
                          <h3 style={{ textAlign: "left" }}>상점환불 TOP 10</h3>
                        </div>
                      </div>
                      <div className="widget-list widget-list-rounded ">
                        {usersStoreReturn
                          .filter((item) => item.StoreReturnTotal > 0)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="widget-list-item rounded-0 p-t-3"
                              >
                                <div className="widget-list-content">
                                  <div className="widget-list-title">
                                    {item.StoreReturnTotal > 0
                                      ? `${index + 1}.${item.name}(${
                                          item.userId
                                        })`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="widget-list-action text-nowrap text-right">
                                  <NumberFormat
                                    value={item.StoreReturnTotal}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림({item.StoreReturnCount}건)
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="vertical-box with-grid inbox">
                      <div className="vertical-box-inner-cell overflow-visible height-md">
                        <p className="text-center">
                          상점환불 데이터가 없습니다.
                        </p>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="vertical-box with-grid inbox">
              <div className="vertical-box-inner-cell overflow-visible height-420">
                <PerfectScrollbar
                  className="height-full p-5"
                  options={{ suppressScrollX: true }}
                >
                  {usersRemittance.filter((item) => item.RemittanceTotal > 0)
                    .length > 0 ? (
                    <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                      <div
                        className="card-body text-right"
                        style={{
                          background: "no-repeat bottom left",
                          backgroundImage: "url(/assets/img/svg/img-4.svg)",
                          backgroundSize: "auto 60%",
                        }}
                      >
                        <div className="m-b-10 m-t-10 f-s-10">
                          <h3 style={{ textAlign: "left" }}>송금 TOP 10</h3>
                        </div>
                      </div>
                      <div className="widget-list widget-list-rounded ">
                        {usersRemittance
                          .filter((item) => item.RemittanceTotal > 0)
                          .map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="widget-list-item rounded-0 p-t-3"
                              >
                                <div className="widget-list-content">
                                  <div className="widget-list-title">
                                    {item.RemittanceTotal > 0
                                      ? `${index + 1}.${item.name}(${
                                          item.userId
                                        })`
                                      : "-"}
                                  </div>
                                </div>
                                <div className="widget-list-action text-nowrap text-right">
                                  <NumberFormat
                                    value={item.RemittanceTotal}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림({item.RemittanceCount}건)
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="vertical-box with-grid inbox">
                      <div className="vertical-box-inner-cell overflow-visible height-md">
                        <p className="text-center">송금 데이터가 없습니다.</p>
                      </div>
                    </div>
                  )}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="m-b-10 m-t-10 f-s-10">
          <b className="text-inverse f-s-18">가맹점 현황</b>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="card border-0 bg-black-transparent-9 text-white mb-3">
              <div
                className="card-body text-right"
                style={{
                  background: "no-repeat bottom left",
                  backgroundImage: "url(/assets/img/svg/img-4.svg)",
                  backgroundSize: "auto 60%",
                }}
              >
                <div className="m-b-10 m-t-10 f-s-10">
                  <h3 style={{ textAlign: "left" }}>결제금액</h3>
                </div>
                <h3 className="m-b-10">
                  <NumberFormat
                    value={PaymentTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림({PaymentCount}건)
                </h3>
              </div>
              <div className="widget-list widget-list-rounded ">
                {PaymentStores.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="widget-list-item rounded-0 p-t-3"
                    >
                      <div className="widget-list-content">
                        <div className="widget-list-title">
                          {item.name}({item.count}건)
                        </div>
                      </div>
                      <div className="widget-list-action text-nowrap text-right">
                        <NumberFormat
                          value={item.total}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card border-0 bg-black-transparent-9 text-white mb-3">
              <div
                className="card-body text-right"
                style={{
                  background: "no-repeat bottom left",
                  backgroundImage: "url(/assets/img/svg/img-4.svg)",
                  backgroundSize: "auto 60%",
                }}
              >
                <div className="m-b-10 m-t-10 f-s-10">
                  <h3 style={{ textAlign: "left" }}>정산금액</h3>
                </div>
                <h3 className="m-b-10">
                  <NumberFormat
                    value={ExchangeRequestTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  드림({ExchangeRequestCount}건)
                </h3>
              </div>
              <div className="widget-list widget-list-rounded ">
                {ExchangeRequestStores.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="widget-list-item rounded-0 p-t-3"
                    >
                      <div className="widget-list-content">
                        <div className="widget-list-title">
                          {item.name}({item.count}건)
                        </div>
                      </div>
                      <div className="widget-list-action text-nowrap text-right">
                        <NumberFormat
                          value={item.total}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
