import React, { useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import { STORE_EMPLOYEE_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default ({ history, match, location, store }) => {
  const {
    AdminStoreDetail: { UserId },
  } = store;

  const { data, loading } = useQuery(STORE_EMPLOYEE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { UserId },
  });

  useEffect(() => {
    // refetch();
  });

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      store={store}
      data={data}
      loading={loading}
    ></Presenter>
  );
};
