import React, { useState } from "react"
import { withRouter, Link } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import { Panel, PanelHeader, PanelBody } from "../../Components/Panel/Panel"
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Alert,
  Label,
} from "reactstrap"
import NumberFormat from "react-number-format"

import { useMutation, useQuery } from "react-apollo-hooks"
import { ADMIN_DELIVERY_PRODUCT_LIST_QUERY } from "../../Routes/Administrator/DeliveryProduct/Queries"
import {
  ADMIN_DELIVERY_SET_PRODUCT_WRITE_MUTATION,
  ADMIN_DELIVERY_SET_DETAIL_QUERY,
  ADMIN_DELIVERY_SET_PRODUCT_SELECT_DELETE_MUTATION,
  ADMIN_DELIVERY_SET_PRODUCT_ALL_DELETE_MUTATION,
} from "../../Routes/Administrator/DeliverySet/Queries"
import Swal from "sweetalert2"

const Product = withRouter(
  ({ history, location, match, item, baseUrl, searchReset }) => {
    const id = match.params.id

    const { control, handleSubmit, errors } = useForm({
      defaultValues: {
        count: "0",
        deliveryProductOptionDetail1_id: "",
        deliveryProductOptionDetail2_id: "",
        deliveryProductOptionDetail3_id: "",
      },
    })

    const [
      AdminDeliverySetProductWriteMutation,
      { loading: AdminDeliverySetProductWriteLoading },
    ] = useMutation(ADMIN_DELIVERY_SET_PRODUCT_WRITE_MUTATION, {
      refetchQueries: [
        {
          query: ADMIN_DELIVERY_SET_DETAIL_QUERY,
          variables: { id },
        },
      ],
    })

    const onSubmit = async (data) => {
      try {
        if (AdminDeliverySetProductWriteLoading) {
          return
        }

        const {
          data: { AdminDeliverySetProductWrite },
        } = await AdminDeliverySetProductWriteMutation({
          variables: {
            count: data.count,
            deliverySet_id: id,
            deliveryProduct_id: item.id,
            deliveryProductOptionDetail1_id:
              data.deliveryProductOptionDetail1_id,
            deliveryProductOptionDetail2_id:
              data.deliveryProductOptionDetail2_id,
            deliveryProductOptionDetail3_id:
              data.deliveryProductOptionDetail3_id,
          },
        })

        if (AdminDeliverySetProductWrite) {
          searchReset()
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}${location.search}`)
            }
          })
        }
      } catch (e) {
        console.log(e)
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        })
      }
    }

    return (
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-6">
            <h5 className="m-b-0">
              [{item.deliveryProductCategory.name}]{item.name}
            </h5>
            <span>
              <b>
                <NumberFormat
                  value={item.price}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </b>
              드림
            </span>
          </div>
          <div className="col-4">
            <Controller
              control={control}
              name="count"
              rules={{
                required: "수량을 입력해주세요.",
                min: {
                  value: 1,
                  message: "1개 이상 입력해주세요.",
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <>
                  <input
                    type="number"
                    className="form-control text-center"
                    placeholder="수량"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {errors.count && (
                    <span className="text-red errorText">
                      {errors.count.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-2">
            <button
              type="submit"
              className="btn btn-white text-primary btn-block"
            >
              담기
            </button>
          </div>
        </div>
        {item.deliveryProductOptions.filter(
          (option) => option.commonStatus === "C"
        ).length > 0 && (
          <div className="input-option">
            {item.deliveryProductOptions
              .filter((option) => option.commonStatus === "C")
              .map((option, index) => (
                <Controller
                  key={index}
                  control={control}
                  name={`deliveryProductOptionDetail${index + 1}_id`}
                  rules={{ required: `${option.name}을 입력해주세요.` }}
                  render={({ onChange }) => (
                    <div>
                      <select className="form-control" onChange={onChange}>
                        <option key={index}>{option.name}</option>
                        {option.deliveryProductOptionDetails
                          .filter((detail) => detail.commonStatus === "C")
                          .map((detail, index) => (
                            <option key={index} value={detail.id}>
                              {detail.name}
                              {detail.price > 0 && `(+${detail.price}드림)`}
                            </option>
                          ))}
                      </select>
                      {errors[`deliveryProductOptionDetail${index + 1}_id`] && (
                        <span className="text-red errorText">
                          {
                            errors[`deliveryProductOptionDetail${index + 1}_id`]
                              .message
                          }
                        </span>
                      )}
                    </div>
                  )}
                />
              ))}
          </div>
        )}
      </form>
    )
  }
)

const Products = withRouter(
  ({ history, location, match, deliverySetProducts, totalPrice }) => {
    const id = match.params.id
    const baseUrl = `/deliverySets/${id}/edit`

    const [checkItems, setCheckItems] = useState([])

    const doChecker = (event, item) => {
      const checked = event.target.checked

      if (checked) {
        setCheckItems([...checkItems, item])
      } else {
        setCheckItems(checkItems.filter((el) => el !== item))
      }
    }

    const doAllChecker = (event) => {
      const checked = event.target.checked

      if (checked) {
        let itemArray = []
        deliverySetProducts.forEach((item) => {
          itemArray.push(item)
        })
        setCheckItems(itemArray)
      } else {
        setCheckItems([])
      }
    }

    const [
      AdminDeliverySetProductSelectDeleteMutation,
      { loading: AdminDeliverySetProductSelectDeleteLoading },
    ] = useMutation(ADMIN_DELIVERY_SET_PRODUCT_SELECT_DELETE_MUTATION, {
      refetchQueries: [
        {
          query: ADMIN_DELIVERY_SET_DETAIL_QUERY,
          variables: { id },
        },
      ],
    })

    const onSelectDelete = async () => {
      try {
        if (AdminDeliverySetProductSelectDeleteLoading) {
          return
        }

        if (checkItems.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "주의",
            text: "선택된 상품이 없습니다. 상품을 선택해주세요.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          })
          return
        }

        const ids = checkItems.map((item) => item.id)
        const {
          data: { AdminDeliverySetProductSelectDelete },
        } = await AdminDeliverySetProductSelectDeleteMutation({
          variables: { ids },
        })
        console.log({ AdminDeliverySetProductSelectDelete })
        if (AdminDeliverySetProductSelectDelete) {
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}${location.search}`)
            }
          })
        }
      } catch (e) {
        console.log(e)
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        })
      }
    }

    const [
      AdminDeliverySetProductAllDeleteMutation,
      { loading: AdminDeliverySetProductAllDeleteLoading },
    ] = useMutation(ADMIN_DELIVERY_SET_PRODUCT_ALL_DELETE_MUTATION, {
      refetchQueries: [
        {
          query: ADMIN_DELIVERY_SET_DETAIL_QUERY,
          variables: { id },
        },
      ],
    })

    const onAllDelete = async () => {
      try {
        if (AdminDeliverySetProductAllDeleteLoading) {
          return
        }

        Swal.fire({
          icon: "warning",
          title: "주의",
          text: "묶음상품을 전체삭제 하시겠습니까?",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "취소",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const {
              data: { AdminDeliverySetProductAllDelete },
            } = await AdminDeliverySetProductAllDeleteMutation({
              variables: { deliverySet_id: id },
            })
            if (AdminDeliverySetProductAllDelete) {
              Swal.fire({
                icon: "info",
                title: "안내",
                text: "처리 되었습니다.",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "확인",
                focusConfirm: true,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push(`${baseUrl}${location.search}`)
                }
              })
            }
          }
        })
      } catch (e) {
        console.log(e)
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        })
      }
    }

    const [keyword, setKeyword] = useState("")

    const searchReset = () => {
      setKeyword("")
    }

    const { data, error, loading, refetch } = useQuery(
      ADMIN_DELIVERY_PRODUCT_LIST_QUERY,
      {
        fetchPolicy: "network-only",
        variables: {
          skip: 0,
          take: 15,
          keyword,
        },
      }
    )

    if (error) {
      return <>{error.message}</>
    }

    return (
      <div className="row">
        <div className="col-sm-6">
          <Panel>
            <PanelHeader noButton>상품검색</PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row">
                <div className="col-xs-12">
                  <InputGroup>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="검색어(상품명) 입력해주세요."
                      onChange={(e) => {
                        setKeyword(e.target.value)
                        refetch()
                      }}
                      value={keyword}
                    />
                  </InputGroup>
                </div>
              </div>
            </Alert>
            <PanelBody>
              {!loading &&
              keyword !== "" &&
              data.AdminDeliveryProductList.count > 0 ? (
                <ul className="sets-list">
                  {data.AdminDeliveryProductList.deliveryProducts.map(
                    (item, index) => (
                      <li>
                        <Product
                          key={index}
                          item={item}
                          baseUrl={baseUrl}
                          searchReset={searchReset}
                        />
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <div className="row">
                  <div className="col-xs-12 p-40">
                    <p className="text-center">검색된 데이터가 없습니다.</p>
                  </div>
                </div>
              )}
            </PanelBody>
          </Panel>
        </div>
        <div className="col-sm-6">
          <Panel>
            <PanelHeader noButton>묶음목록</PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="all-check">
                <div className="check">
                  <input type="checkbox" id="all" onChange={doAllChecker} />
                  <label htmlFor="all">전체선택</label>
                </div>
                <div className="btn-box">
                  <button
                    type="button"
                    className="btn btn-white text-danger"
                    onClick={onSelectDelete}
                  >
                    <i className="fa fa-check fa-fw"></i>
                    선택삭제
                  </button>
                  <button
                    type="submit"
                    className="btn btn-white text-danger m-l-5"
                    onClick={onAllDelete}
                  >
                    <i className="fa fa-trash fa-fw"></i>
                    전체삭제
                  </button>
                </div>
              </div>
            </Alert>
            <PanelBody>
              {deliverySetProducts.length > 0 ? (
                <ul className="product-list">
                  {deliverySetProducts.map((item, index) => (
                    <li key={index}>
                      <div className="check">
                        <label htmlFor={item.id}>
                          <input
                            id={item.id}
                            type="checkbox"
                            value={item}
                            onChange={(event) => doChecker(event, item)}
                            checked={checkItems.includes(item) ? true : false}
                          />
                        </label>
                      </div>
                      <div className="info">
                        <h5 className="m-b-0">
                          {item.deliveryProduct.name}(
                          <NumberFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          드림)
                        </h5>
                        {(item.deliveryProductOptionDetail1 ||
                          item.deliveryProductOptionDetail2 ||
                          item.deliveryProductOptionDetail3) && (
                          <div>
                            [옵션]
                            {item.deliveryProductOptionDetail1 &&
                              item.deliveryProductOptionDetail1.commonStatus ===
                                "C" &&
                              ` ${item.deliveryProductOptionDetail1.name}${
                                item.deliveryProductOptionDetail1.price > 0
                                  ? `(+${item.deliveryProductOptionDetail1.price}드림)`
                                  : ``
                              }`}
                            {item.deliveryProductOptionDetail2 &&
                              item.deliveryProductOptionDetail2.commonStatus ===
                                "C" &&
                              ` / ${item.deliveryProductOptionDetail2.name}${
                                item.deliveryProductOptionDetail2.price > 0
                                  ? `(+${item.deliveryProductOptionDetail2.price}드림)`
                                  : ``
                              }`}
                            {item.deliveryProductOptionDetail3 &&
                              item.deliveryProductOptionDetail3.commonStatus ===
                                "C" &&
                              ` / ${item.deliveryProductOptionDetail3.name}${
                                item.deliveryProductOptionDetail3.price > 0
                                  ? `(+${item.deliveryProductOptionDetail3.price}드림)`
                                  : ``
                              }`}
                          </div>
                        )}
                      </div>
                      <div className="count"> {item.count}개</div>
                      <div className="price">
                        <NumberFormat
                          value={item.totalPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </div>
                    </li>
                  ))}
                  <li className="total">
                    <div className="info f-s-16">
                      <b>합계</b>
                    </div>
                    <div className="count f-s-16"></div>
                    <div className="price f-s-16">
                      <b>
                        <NumberFormat
                          value={totalPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </b>
                    </div>
                  </li>
                </ul>
              ) : (
                <div className="row">
                  <div className="col-xs-12 p-40">
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </div>
                </div>
              )}
            </PanelBody>
          </Panel>
        </div>
      </div>
    )
  }
)

export default Products
