import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../../../../Components/Panel/Panel";
import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_STORE_DONG_LIST_QUERY,
  ADMIN_DELIVERY_STORE_DONG_DELETE_MUTATION,
} from "../../Queries";
import Swal from "sweetalert2";

export default ({ history, match, location, loading, data, baseUrl }) => {
  const [AdminDeliveryStoreDongDeleteMutation] = useMutation(
    ADMIN_DELIVERY_STORE_DONG_DELETE_MUTATION,
    {
      refetchQueries: [
        {
          query: ADMIN_DELIVERY_STORE_DONG_LIST_QUERY,
          variables: { take: 100, skip: 0 },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminDeliveryStoreDongDelete },
      } = await AdminDeliveryStoreDongDeleteMutation({
        variables: { id },
      });
      if (AdminDeliveryStoreDongDelete) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>동 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">로컬푸드 구독</li>
          <li className="breadcrumb-item">매장</li>
          <li className="breadcrumb-item active">동</li>
        </ol>

        <h1 className="page-header">동</h1>

        <div className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link
              to={`/deliveryStores${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right"></div>
        </div>

        {!loading ? (
          <div className="row">
            <div className="col-xs-12">
              {data.AdminDeliveryStoreDongList.count > 0 ? (
                <div className={"widget-list widget-list-rounded m-b-15"}>
                  {data.AdminDeliveryStoreDongList.deliveryStoreDongs.map(
                    (item, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="widget-list-item">
                            <div className="widget-list-content">
                              <h4 className="widget-list-title">
                                {item.commonStatus === "S" ? "[대기]" : ""}
                                {item.sido} &gt; {item.sigungu} &gt;{" "}
                                {item.upmyundong}
                              </h4>
                            </div>
                            <div
                              className="widget-list-action"
                              style={{ width: "50%" }}
                            >
                              <div className="pull-right">
                                <Link
                                  className="btn btn-white btn-xs text-success m-l-5"
                                  to={`${baseUrl}/${item.id}/edit`}
                                >
                                  수정
                                </Link>
                                <button
                                  className={`btn btn-white btn-xs text-danger m-l-5`}
                                  disabled={
                                    item.childrenCount > 0 ? true : false
                                  }
                                  onClick={() => {
                                    if (window.confirm("삭제하시겠습니까?")) {
                                      onDelete(item.id);
                                    }
                                  }}
                                >
                                  삭제
                                </button>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    }
                  )}
                </div>
              ) : (
                <Panel>
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                </Panel>
              )}

              <Link
                to={`${baseUrl}/write`}
                className="btn btn-default btn-block  m-b-15"
              >
                <i className="fas fa-plus"></i> 추가
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Fragment>
  );
};
