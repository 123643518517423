import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { DONATION_LIST_QUERY, LEDGER_DONATIONSYNC_QUERY } from "../Queries";
import useInput from "../../../../Hooks/useInput";
import ListPresenter from "./Presenter/List";
import qs from "qs";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, config }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  const category = queryString.category ? queryString.category : "";
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (category ? "category=" + category + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(DONATION_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      category,
      keyword,
      skip,
      first,
    },
  });
  const [ledgerDonationSyncMutation] = useMutation(LEDGER_DONATIONSYNC_QUERY);

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/donations`);
  };
  const onSync = async (donation) => {
    try {
      const {
        data: { AdminETCLedgerDonationAmount },
      } = await ledgerDonationSyncMutation({
        variables: {
          donation: donation,
        },
      });
      if (AdminETCLedgerDonationAmount) {
        toast.success("처리 되었습니다.");
        history.push(`/donations/${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    refetch();
  });

  return (
    <>
      <ListPresenter
        history={history}
        match={match}
        location={location}
        config={config}
        page={page}
        keyword={keyword}
        keywordInput={keywordInput}
        blockSize={blockSize}
        first={first}
        data={data}
        loading={loading}
        baseUrl={baseUrl}
        handleSearch={handleSearch}
        onSync={onSync}
      ></ListPresenter>
    </>
  );
});
