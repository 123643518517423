import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  EXCHANGEREQUEST_LIST_QUERY,
  EXCHANGEREQUEST_EDIT_COMMONSTATUS_QUERY,
  EXCHANGEREQUEST_DELETE_QUERY,
} from "../../Queries";
import useInput from "../../../../../Hooks/useInput";
import Presenter from "./Presenter/List";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  currencyName,
  commonStatusName,
  bankName,
  datePlus9,
} from "../../../../../Components/User/UserNames";

export default withRouter(({ history, match, location, config }) => {
  const queryString = qs.parse(location.search.substring(1));

  const currency = queryString.currency;
  const commonStatus = queryString.commonStatus;
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  const bank_name = queryString.bank_name;
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  var page = queryString.page ? queryString.page : 1;

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (currency ? "currency=" + currency + "&" : "") +
    (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (bank_name ? "bank_name=" + bank_name + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(EXCHANGEREQUEST_LIST_QUERY, {
    variables: {
      currency,
      commonStatus,
      startSearchDate,
      endSearchDate,
      bank_name,
      keyword,
      skip,
      first,
    },
  });
  const [exchangeListMutation] = useMutation(EXCHANGEREQUEST_LIST_QUERY);
  const [exchangeRequestEditCommonStatusMutation] = useMutation(
    EXCHANGEREQUEST_EDIT_COMMONSTATUS_QUERY
  );
  const [exchangeRequestDeleteMutation] = useMutation(
    EXCHANGEREQUEST_DELETE_QUERY
  );
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (currency ? "currency=" + currency + "&" : "") +
      (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
      (bank_name ? "bank_name=" + bank_name + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (currency ? "currency=" + currency + "&" : "") +
      (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "") +
      (bank_name ? "bank_name=" + bank_name + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (currency ? "currency=" + currency + "&" : "") +
      (commonStatus ? "commonStatus=" + commonStatus + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (bank_name ? "bank_name=" + bank_name + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(
      `/exchangeRequestConfigs/${config.AdminExchangeRequestConfig.id}/exchangeRequests${baseUrl}page=1`
    );
  };

  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [
        [
          "화폐",
          "상태",
          "상호명",
          "이름",
          "아이디",
          "은행",
          "계좌번호",
          "예금주",
          "요청금액",
          "운영비",
          "기부금",
          "합계",
          "요청일시",
          "처리일시",
        ],
      ];
      try {
        const {
          data: { AdminExchangeRequestList },
        } = await exchangeListMutation({
          variables: {
            currency,
            commonStatus,
            startSearchDate,
            endSearchDate,
            bank_name,
            keyword,
          },
        });
        AdminExchangeRequestList.exchangeRequests.forEach((exchangeRequest) => {
          data.push([
            currencyName(exchangeRequest.currency),
            `${commonStatusName(exchangeRequest.commonStatus)}`,
            exchangeRequest.user.store.name,
            exchangeRequest.user.name,
            exchangeRequest.user.userId,
            `${bankName(exchangeRequest.bank_name)}`,
            exchangeRequest.bank_num,
            exchangeRequest.bank_account_holder,
            exchangeRequest.amount,
            Math.ceil(exchangeRequest.amount * 0.01),
            Math.ceil(exchangeRequest.amount * 0.02),
            Math.floor(exchangeRequest.amount - exchangeRequest.amount * 0.03),
            `${datePlus9(exchangeRequest.createdAt)}`,
            `${datePlus9(exchangeRequest.updatedAt)}`,
          ]);
        });
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };
    return (
      <>
        <button
          className="btn btn-white btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`충전내역.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  const handleApproval = async (e, item) => {
    e.preventDefault();

    try {
      await exchangeRequestEditCommonStatusMutation({
        variables: {
          id: item.id,
          commonStatus: "C",
        },
      });

      refetch();
      // history.replace(`/exchangeRequests${baseUrl}page=${page}`);
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onDelete = async (e, item) => {
    e.preventDefault();

    try {
      await exchangeRequestDeleteMutation({
        variables: {
          id: item.id,
        },
      });

      refetch();
      // history.replace(`/exchangeRequests${baseUrl}page=${page}`);
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      config={config}
      currency={currency}
      commonStatus={commonStatus}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      bank_name={bank_name}
      keyword={keyword}
      keywordInput={keywordInput}
      page={page}
      blockSize={blockSize}
      first={first}
      baseUrl={baseUrl}
      data={data}
      loading={loading}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
      handleSearch={handleSearch}
      DownloadButton={DownloadButton}
      handleApproval={handleApproval}
      onDelete={onDelete}
    ></Presenter>
  );
});
