import React from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";

// Import Swiper styles
export default withRouter(({ history, match, location, userData }) => {
  return (
    <>
      <Helmet>
        <title>메인 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">매인</li>
        </ol>
        <h1 className="page-header">Dashboard</h1>

        <div className="row">
          <div className="col-xs-12">
            <div className="note note-light m-t-30 m-b-15">
              <div style={{ marginTop: -45, marginLeft: 30 }}>
                <img
                  style={{ height: 130 }}
                  src="../assets/img/admin/dashboard-img.png"
                  alt=""
                />
              </div>
              <div className="note-content  m-l-15">
                <h4 className="f-s-18 m-t-15">
                  <b>
                    {userData && userData.AdminInfo && userData.AdminInfo.name}
                  </b>
                  님
                </h4>
                <p className="f-s-14">오늘하루도 좋은 하루 되시기바랍니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
