import React from "react";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_LIST_QUERY } from "../Queries";

const DeliveryStoreSelect = ({ onChange, value }) => {
  const { data, error, loading } = useQuery(ADMIN_DELIVERY_STORE_LIST_QUERY, {
    variables: { skip: 0, take: 100 },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <select className="form-control" onChange={onChange} defaultValue={value}>
      <option value={""}>전체매장</option>
      {data.AdminDeliveryStoreList.deliveryStores.map((item, index) => {
        return (
          <option value={item.id} key={index}>
            {item.store.name} &gt; {item.user.name}
          </option>
        );
      })}
    </select>
  );
};

export default DeliveryStoreSelect;
