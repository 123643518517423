import { gql } from "apollo-boost";

/* 통계 설정 */
export const STATISTICS_QUERY = gql`
  query AdminStatistics($startDate: String, $endDate: String) {
    AdminStatistics(startDate: $startDate, endDate: $endDate) {
      DNTTotal
      DNTIssueCount
      DNTIssueTotal
      DNTUseCount
      DNTUseTotal
      ChargeRequestUserCount
      ChargeRequestCMSCount
      ChargeRequestCMSTotal
      ChargeRequestNomalCount
      ChargeRequestNomalTotal
      PaymentCount
      PaymentTotal
      RemittanceCount
      RemittanceTotal
      ExchangeRequestCount
      ExchangeRequestTotal

      usersCharge {
        wallet
        userId
        name
        ChargeCount
        ChargeTotal
      }
      usersStoreOrder {
        wallet
        userId
        name
        StoreOrderCount
        StoreOrderTotal
      }
      usersStoreReturn {
        wallet
        userId
        name
        StoreReturnCount
        StoreReturnTotal
      }
      usersRemittance {
        wallet
        userId
        name
        RemittanceCount
        RemittanceTotal
      }
      usersDonation {
        wallet
        userId
        name
        DonationCount
        DonationTotal
      }
      usersMinusStoreOrder {
        wallet
        userId
        name
        MinusStoreOrderCount
        MinusStoreOrderTotal
      }
      stores {
        wallet
        id
        name
        count
        total
      }
    }
  }
`;

export const STATISTICS_DNTISSUE_QUERY = gql`
  query AdminStatisticsDNTIssue($startDate: String!, $endDate: String!) {
    AdminStatisticsDNTIssue(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_DNTUSE_QUERY = gql`
  query AdminStatisticsDNTUse($startDate: String!, $endDate: String!) {
    AdminStatisticsDNTUse(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_CHARGEREQUESTUSER_QUERY = gql`
  query AdminStatisticsChargeRequestUser(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsChargeRequestUser(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_CHARGEREQUESTCMS_QUERY = gql`
  query AdminStatisticsChargeRequestCMS(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsChargeRequestCMS(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_CHARGEREQUESTNOMAL_QUERY = gql`
  query AdminStatisticsChargeRequestNomal(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsChargeRequestNomal(
      startDate: $startDate
      endDate: $endDate
    ) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_REMITTANCE_QUERY = gql`
  query AdminStatisticsRemittance($startDate: String!, $endDate: String!) {
    AdminStatisticsRemittance(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_USERSCHARGE_QUERY = gql`
  query AdminStatisticsUsersChargeTop100(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsUsersChargeTop100(startDate: $startDate, endDate: $endDate) {
      usersChargeTop100 {
        wallet
        userId
        name
        ChargeCount
        ChargeTotal
      }
    }
  }
`;
export const STATISTICS_USERSSTOREORDER_QUERY = gql`
  query AdminStatisticsUsersStoreOrderTop100(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsUsersStoreOrderTop100(
      startDate: $startDate
      endDate: $endDate
    ) {
      usersStoreOrderTop100 {
        wallet
        userId
        name
        StoreOrderCount
        StoreOrderTotal
      }
    }
  }
`;
export const STATISTICS_USERSSTORERETURN_QUERY = gql`
  query AdminStatisticsUsersStoreReturnTop100(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsUsersStoreReturnTop100(
      startDate: $startDate
      endDate: $endDate
    ) {
      usersStoreReturnTop100 {
        wallet
        userId
        name
        StoreReturnCount
        StoreReturnTotal
      }
    }
  }
`;
export const STATISTICS_USERSREMITTANCE_QUERY = gql`
  query AdminStatisticsUsersRemittanceTop100(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsUsersRemittanceTop100(
      startDate: $startDate
      endDate: $endDate
    ) {
      usersRemittanceTop100 {
        wallet
        userId
        name
        RemittanceCount
        RemittanceTotal
      }
    }
  }
`;
export const STATISTICS_USERSDONATION_QUERY = gql`
  query AdminStatisticsUsersDonationTop100(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsUsersDonationTop100(
      startDate: $startDate
      endDate: $endDate
    ) {
      usersDonationTop100 {
        wallet
        userId
        name
        DonationCount
        DonationTotal
      }
    }
  }
`;
export const STATISTICS_USERSMINUSSTOREORDER_QUERY = gql`
  query AdminStatisticsUsersMinusStoreOrderTop100(
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsUsersMinusStoreOrderTop100(
      startDate: $startDate
      endDate: $endDate
    ) {
      usersMinusStoreOrderTop100 {
        wallet
        userId
        name
        MinusStoreOrderCount
        MinusStoreOrderTotal
      }
    }
  }
`;
export const STATISTICS_PAYMENT_QUERY = gql`
  query AdminStatisticsPayment($startDate: String!, $endDate: String!) {
    AdminStatisticsPayment(startDate: $startDate, endDate: $endDate) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_STOREPAYMENT_QUERY = gql`
  query AdminStatisticsStorePayment(
    $id: String!
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsStorePayment(
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      list {
        date
        count
        total
      }
    }
  }
`;
export const STATISTICS_STOREPAYMENTHOUR_QUERY = gql`
  query AdminStatisticsStorePaymentHour(
    $id: String!
    $startDate: String!
    $endDate: String!
  ) {
    AdminStatisticsStorePaymentHour(
      id: $id
      startDate: $startDate
      endDate: $endDate
    ) {
      list {
        hour
        count
        total
      }
    }
  }
`;
