import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  USER_LIST_QUERY,
  USER_EDIT_USESTOREAPP_QUERY,
  LEDGER_USERSYNC_QUERY,
} from "../Queries";
import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  roleName,
  confirmedName,
  blockedName,
  currencyArray,
  currencyName,
  commonStatusName,
  datePlus9,
} from "../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  var page = queryString.page ? queryString.page : 1;
  const role = match.params.role;
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(USER_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { role, startSearchDate, endSearchDate, keyword, skip, first },
  });
  const [userListMutation] = useMutation(USER_LIST_QUERY);
  const [userEditUseStoreAppMutation] = useMutation(
    USER_EDIT_USESTOREAPP_QUERY
  );
  const [ledgerUserSyncMutation] = useMutation(LEDGER_USERSYNC_QUERY);

  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

    history.push(`${uri}page=1`);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    history.push(`/users/${match.params.role}${baseUrl}page=1`);
  };

  const DownloadButton = () => {
    const handleDownload = async () => {
      if (match.params.role === "User") {
        let data = [
          [
            "상태",
            "권한",
            "이름",
            "아이디",
            "이메일",
            "휴대전화",
            "드림",
            "드림P",
            "관저페이",
            "톨",
            "그루",
            "별",
            "내동",
            "주소",
            "정기결제",
            "승인유무",
            "차단유무",
            "가입일시",
          ],
        ];
        try {
          const {
            data: { AdminUserList },
          } = await userListMutation({
            variables: { role, startSearchDate, endSearchDate, keyword },
          });
          AdminUserList.users.forEach((user) => {
            const DRM =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "DRM";
              }).amount - currencyArray["DRM"].startAmount;
            const DNT =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "DNT";
              })?.amount - currencyArray["DNT"].startAmount;
            const GJP =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "GJP";
              })?.amount - currencyArray["GJP"].startAmount;
            const TOL =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "TOL";
              })?.amount - currencyArray["TOL"].startAmount;
            const GRU =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "GRU";
              })?.amount - currencyArray["GRU"].startAmount;
            const STR =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "STR";
              })?.amount - currencyArray["STR"].startAmount;
            const NDO =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "NDO";
              })?.amount - currencyArray["NDO"].startAmount;

            data.push([
              `${commonStatusName(user.commonStatus)}`,
              `${roleName(user.role)}`,
              user.name,
              user.userId,
              user.email,
              user.phone,
              DRM ? DRM : 0,
              DNT ? DNT : 0,
              GJP ? GJP : 0,
              TOL ? TOL : 0,
              GRU ? GRU : 0,
              STR ? STR : 0,
              NDO ? NDO : 0,
              // `${user.wallet.trustlines.map((trustline) => {
              //   return `${currencyName(trustline.currency)} : ${
              //     trustline.amount -
              //     currencyArray[trustline.currency].startAmount
              //   }\n`;
              // })}`,
              `${user.zipcode}\n도로명주소 : ${user.roadAddress}\n지번주소 : ${user.jibunAddress}\n상세주소 : ${user.addressDetail}\n`,
              `${
                user.isCms
                  ? `매월 ${user.cmsDay}일 ${String(user.cmsValue).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )}원 정기결제`
                  : "신청안함"
              }`,
              `${confirmedName(user.confirmed)}`,
              `${blockedName(user.blocked)}`,
              `${datePlus9(user.createdAt)}`,
            ]);
          });
          setCsvData(data);
          downloadButton.current.link.click();
        } catch (e) {
          console.log(e);
          toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
        }
      } else {
        let data = [
          [
            "상태",
            "권한",
            "상호명",
            "이름",
            "아이디",
            "이메일",
            "휴대전화",
            "드림",
            "드림P",
            "관저페이",
            "톨",
            "그루",
            "별",
            "내동",
            "주소",
            "정기결제",
            "승인유무",
            "차단유무",
            "가입일시",
          ],
        ];
        try {
          const {
            data: { AdminUserList },
          } = await userListMutation({
            variables: { role, keyword },
          });
          AdminUserList.users.forEach((user) => {
            const DRM =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "DRM";
              }).amount - currencyArray["DRM"].startAmount;
            const DNT =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "DNT";
              })?.amount - currencyArray["DNT"].startAmount;
            const GJP =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "GJP";
              })?.amount - currencyArray["GJP"].startAmount;
            const TOL =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "TOL";
              })?.amount - currencyArray["TOL"].startAmount;
            const GRU =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "GRU";
              })?.amount - currencyArray["GRU"].startAmount;
            const STR =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "STR";
              })?.amount - currencyArray["STR"].startAmount;
            const NDO =
              user.wallet.trustlines.find((trustline) => {
                return trustline.currency === "NDO";
              })?.amount - currencyArray["NDO"].startAmount;

            data.push([
              `${commonStatusName(user.commonStatus)}`,
              `${roleName(user.role)}`,
              user.store ? user.store.name : "연동안됨",
              user.name,
              user.userId,
              user.email,
              user.phone,
              DRM ? DRM : 0,
              DNT ? DNT : 0,
              GJP ? GJP : 0,
              TOL ? TOL : 0,
              GRU ? GRU : 0,
              STR ? STR : 0,
              NDO ? NDO : 0,
              // `${user.wallet.trustlines.map((trustline) => {
              //   return `${currencyName(trustline.currency)} : ${
              //     trustline.amount -
              //     currencyArray[trustline.currency].startAmount
              //   }\n`;
              // })}`,
              `${user.zipcode}\n도로명주소 : ${user.roadAddress}\n지번주소 : ${user.jibunAddress}\n상세주소 : ${user.addressDetail}\n`,
              `${
                user.isCms
                  ? `매월 ${user.cmsDay}일 ${String(user.cmsValue).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )}원 정기결제`
                  : "신청안함"
              }`,
              `${confirmedName(user.confirmed)}`,
              `${blockedName(user.blocked)}`,
              `${datePlus9(user.createdAt)}`,
            ]);
          });
          setCsvData(data);
          downloadButton.current.link.click();
        } catch (e) {
          console.log(e);
          toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
        }
      }
    };

    return (
      <>
        <button
          className="btn btn-white btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`사용자_${roleName(match.params.role)}.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  const handleApproval = async (e, item) => {
    e.preventDefault();

    try {
      await userEditUseStoreAppMutation({
        variables: {
          id: item.id,
        },
      });

      refetch();
      // history.replace(`/exchangeRequests${baseUrl}page=${page}`);
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const onSync = async (user, currency) => {
    try {
      const {
        data: { AdminETCLedgerUserAmount },
      } = await ledgerUserSyncMutation({
        variables: {
          user: user,
          currency: currency,
        },
      });
      if (AdminETCLedgerUserAmount) {
        toast.success("처리 되었습니다.");
        history.push(`/users/${match.params.role}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      keyword={keyword}
      keywordInput={keywordInput}
      page={page}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
      handleSearch={handleSearch}
      DownloadButton={DownloadButton}
      handleApproval={handleApproval}
      onSync={onSync}
    ></Presenter>
  );
});
