import React, { forwardRef, useState } from "react";
import { useForm } from "react-hook-form";
import { DONATION_CATEGORY_LIST_QUERY, DONATION_WRITE_QUERY } from "./Queries";
import { useQuery, useMutation } from "react-apollo-hooks";

import DatePicker from "react-datepicker";
import "../../../Containers/SelectedPeriod/DatePicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

import { Helmet } from "react-helmet";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data } = useQuery(DONATION_CATEGORY_LIST_QUERY);
  const [donationWriteMutation] = useMutation(DONATION_WRITE_QUERY);
  const { register, handleSubmit, errors, watch } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [donationCoverImg, setDonationCoverImg] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const [donationDetailImg, setDonationDetailImg] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const handleDonationCoverImg = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setDonationCoverImg(reader.result);
    };
  };
  const handleDonationDetailImg = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setDonationDetailImg(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImg").click();
  };
  const onClick_detail = () => {
    document.getElementById("detailImg").click();
  };

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminDonationWrite },
      } = await donationWriteMutation({
        variables: {
          DonationCategory: data.DonationCategory,

          title: data.title,
          contents: data.contents,
          planner: data.planner,
          plannerTel: data.plannerTel,
          plannerUseSNS: data.plannerUseSNS,
          plannerSNS: data.plannerUseSNS === "S" ? "-" : data.plannerSNS,
          goalAmount: Number(data.goalAmount),
          startDate,
          endDate,
          coverImg: data.coverImg[0],
          detailImg: data.detailImg[0],
          commonStatus: data.commonStatus,
        },
      });
      if (AdminDonationWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/donations${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-white" onClick={onClick}>
      {value}
      <i className="fa fa-calendar fa-fw text-black-transparent-5 m-l-5"></i>
    </button>
  ));
  return (
    <>
      <Helmet>
        <title>기부 관리 | 한밭페이</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">기부</li>
          </ol>
          <h1 className="page-header">기부</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/donations${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">분류</label>
                      <div className="col-md-9">
                        {data &&
                          data.AdminDonationCategoryList.donationCategories.map(
                            (category, index) => {
                              return (
                                <div
                                  className="radio radio-css radio-inline"
                                  key={index}
                                >
                                  <input
                                    key={category.id}
                                    type="radio"
                                    id={`DonationCategory${category.id}`}
                                    name="DonationCategory"
                                    className="form-control"
                                    value={category.id}
                                    ref={register({
                                      required: "분류를 선택해주세요.",
                                    })}
                                  />
                                  <label
                                    htmlFor={`DonationCategory${category.id}`}
                                  >
                                    {category.name}
                                  </label>
                                </div>
                              );
                            }
                          )}
                        {errors.DonationCategory && (
                          <div>{errors.DonationCategory.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">상태</label>
                      <div className="col-md-9">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            className="form-control"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            className="form-control"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div>{errors.commonStatus.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        프로젝트 기간
                      </label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <DatePicker
                            name="startDate"
                            customInput={<CustomInput />}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            // ref={register({
                            //   required: "시작일을 입력해주세요.",
                            // })}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day text-white">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                          />
                          {errors.startDate && (
                            <span>{errors.startDate.message}</span>
                          )}
                          <span className="custom-react-datepicker__split-span">
                            ~
                          </span>
                          <DatePicker
                            name="endDate"
                            customInput={<CustomInput />}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            // ref={register({
                            //   required: "종료일을 입력해주세요.",
                            // })}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                          />
                          {errors.endDate && (
                            <span>{errors.endDate.message}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        프로젝트 관리자
                      </label>
                      <div className="col-md-9">
                        <input
                          name="planner"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="프로젝트 관리자 입력"
                          ref={register({
                            required: "프로젝트 관리자를 입력해주세요.",
                          })}
                        />
                        {errors.planner && (
                          <span>{errors.planner.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        프로젝트 관리자 연락처
                      </label>
                      <div className="col-md-9">
                        <input
                          name="plannerTel"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="프로젝트 관리자 연락처 입력"
                          ref={register({
                            required: "프로젝트 관리자 연락처를 입력해주세요.",
                          })}
                        />
                        {errors.plannerTel && (
                          <span>{errors.plannerTel.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        SNS 사용 여부
                      </label>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="snsUseC"
                          name="plannerUseSNS"
                          className="form-control"
                          value="C"
                          ref={register({
                            required: "상태를 선택해주세요.",
                          })}
                        />
                        <label htmlFor={`snsUseC`}>사용</label>
                        {errors.plannerUseSNS && (
                          <span>{errors.plannerUseSNS.message}</span>
                        )}
                      </div>
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="snsUseS"
                          name="plannerUseSNS"
                          className="form-control"
                          value="S"
                          ref={register({
                            required: "상태를 선택해주세요.",
                          })}
                        />
                        <label htmlFor={`snsUseS`}>미사용</label>
                        {errors.plannerUseSNS && (
                          <span>{errors.plannerUseSNS.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        프로젝트 관리자 SNS
                      </label>
                      <div className="col-md-9">
                        <input
                          name="plannerSNS"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="프로젝트 관리자 SNS 입력"
                          ref={register({
                            validate: (value) => {
                              return watch("plannerUseSNS") === "C" &&
                                value === ""
                                ? "프로젝트 관리자 SNS를 입력해주세요."
                                : undefined;
                            },
                          })}
                        />
                        {errors.plannerSNS && (
                          <span>{errors.plannerSNS.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">
                        프로젝트 목표 금액
                      </label>
                      <div className="col-md-9">
                        <input
                          name="goalAmount"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="프로젝트 목표 금액 입력"
                          ref={register({
                            required: "프로젝트 목표 금액 입력해주세요.",
                          })}
                        />
                        {errors.goalAmount && (
                          <span>{errors.goalAmount.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-3">제목</label>
                      <div className="col-md-9">
                        <input
                          name="title"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <span>{errors.title.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-xs-12">
                        <textarea
                          name="contents"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="내용입력"
                          ref={register({
                            required: "내용을 입력해주세요.",
                          })}
                          rows="10"
                        ></textarea>
                        {errors.contents && (
                          <span>{errors.contents.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>커버 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="bg-gray-transparent-1 rounded">
                        <div
                          className="coverImg"
                          style={{
                            backgroundImage: `url(${donationCoverImg})`,
                          }}
                        >
                          <img
                            src={donationCoverImg}
                            alt="이미지 업로드"
                            className="sr-only"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImg"
                          name="coverImg"
                          className="hide"
                          onChange={handleDonationCoverImg}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          커버 이미지 업로드
                        </button>
                      </div>
                      {errors.coverImg && (
                        <span>{errors.coverImg.message}</span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b> 이미지 사이즈 :</b> 넓이 1200px X 높이 1024px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>상세 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <div className="bg-gray-transparent-1 rounded">
                        <div
                          className="coverImg"
                          style={{
                            backgroundImage: `url(${donationDetailImg})`,
                          }}
                        >
                          <img
                            src={donationCoverImg}
                            alt="이미지 업로드"
                            className="sr-only"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="detailImg"
                          name="detailImg"
                          className="hide"
                          onChange={handleDonationDetailImg}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_detail}
                        >
                          상세 이미지 업로드
                        </button>
                      </div>
                      {errors.detailImg && (
                        <span>{errors.detailImg.message}</span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b> 이미지 사이즈 : </b> 넓이 1200px X 높이 1200px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/donations${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
