import { gql } from "apollo-boost";

/* 통계 설정 */
export const STATISTICS_GRU_QUERY = gql`
  query AdminStatisticsGRU($startDate: String, $endDate: String) {
    AdminStatisticsGRU(startDate: $startDate, endDate: $endDate) {
      GRUTotal
      GRUExchangeTotal
      GRUWithdrawalTotal
      GRUUserTotal

      ChargeRequestGRUUserCount
      ChargeRequestGRUCount
      ChargeRequestGRUTotal

      PaymentCount
      PaymentTotal
      RemittanceCount
      RemittanceTotal
      ExchangeRequestCount
      ExchangeRequestTotal

      usersCharge {
        wallet
        userId
        name
        ChargeCount
        ChargeTotal
      }
      usersStoreOrder {
        wallet
        userId
        name
        StoreOrderCount
        StoreOrderTotal
      }
      usersStoreReturn {
        wallet
        userId
        name
        StoreReturnCount
        StoreReturnTotal
      }
      usersRemittance {
        wallet
        userId
        name
        RemittanceCount
        RemittanceTotal
      }

      PaymentStores {
        id
        wallet
        name
        count
        total
      }
      ExchangeRequestStores {
        id
        wallet
        name
        count
        total
      }
    }
  }
`;
