import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import Pagination from "../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import {
  currencyName,
  addCurrencyTransactionTypeArray,
  transactionTypeName,
  roleName,
} from "../../../../../Components/User/UserNames";
import Moment from "moment";
import NumberFormat from "react-number-format";
import Wallet from "./ListWallet";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default ({
  history,
  match,
  location,
  transactionType,
  role,
  startSearchDate,
  endSearchDate,
  handleDateApplyEvent,
  wallet,
  page,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
}) => {
  const TransactionType = () => {
    const urlConfig = (transactionType) => {
      const ledgersUrl =
        "?" +
        (transactionType ? "transactionType=" + transactionType + "&" : "") +
        (role ? "role=" + role + "&" : "") +
        (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
        (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
        (wallet ? "wallet=" + wallet + "&" : "");
      return ledgersUrl;
    };

    return (
      <div className="btn-group m-b-15 m-r-5">
        <Link
          className={`btn btn-white m-b-5 ${!transactionType ? "active" : ""}
      `}
          to={`/ledgers${urlConfig()}`}
        >
          <i className={"fas fa-th"} /> 전체
        </Link>

        {Object.keys(addCurrencyTransactionTypeArray).map((item) => {
          return (
            <Link
              key={item}
              className={`btn btn-white m-b-5 ${
                transactionType === item ? "active" : ""
              }
            `}
              to={`/ledgers${urlConfig(item)}`}
            >
              {addCurrencyTransactionTypeArray[item]}
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>거래내역 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">거래내역</li>
        </ol>
        <h1 className="page-header">거래내역</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.IssuerLedgerList.count}건)
                <div className="pull-right"></div>
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <TransactionType />
                {/* <Role /> */}
                <div className="btn-group m-b-15 m-r-5">
                  <DateRangePicker
                    startDate={Moment(startSearchDate)}
                    endDate={Moment(endSearchDate)}
                    onApply={handleDateApplyEvent}
                  >
                    <button className="btn btn-white mr-2 text-truncate m-b-5">
                      <i className="fa fa-calendar fa-fw text-black-transparent-5 ml-n1"></i>
                      <span>
                        {startSearchDate} ~ {endSearchDate}
                      </span>
                      <b className="caret"></b>
                    </button>
                  </DateRangePicker>
                </div>
                <Wallet
                  history={history}
                  match={match}
                  location={location}
                  transactionType={transactionType}
                  role={role}
                  startSearchDate={startSearchDate}
                  endSearchDate={endSearchDate}
                  wallet={wallet}
                />
              </Alert>
              <PanelBody>
                {!loading && data.IssuerLedgerList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>화폐</th>
                            <th>형태</th>
                            <th>권한</th>
                            <th>이름(아이디)</th>
                            <th>내용</th>
                            <th>일자</th>
                            <th>금액</th>
                            <th>합계</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.IssuerLedgerList.ledgers.map((ledger) => {
                            return (
                              <tr key={ledger.id}>
                                <td>{currencyName(ledger.currency)}</td>
                                <td>
                                  {transactionTypeName(ledger.transactionType)}
                                </td>
                                <td>
                                  {ledger.user && ledger.user.role
                                    ? roleName(ledger.user.role)
                                    : "-"}
                                </td>
                                <td>
                                  {ledger.transactionType !== "Donation"
                                    ? `${ledger.wallet.name}(${ledger.wallet.userId})`
                                    : "-"}
                                </td>
                                <td>
                                  상세내용 : {ledger.title}
                                  {ledger.remittanceType === "Order" && (
                                    <>
                                      <br />
                                      거래내용 : {ledger.comment}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {ledger.createdAt}
                                  </ReactMoment>
                                </td>
                                <td align="right">
                                  <NumberFormat
                                    value={ledger.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(ledger.currency)}
                                </td>
                                <td align="right">
                                  <NumberFormat
                                    value={ledger.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  {currencyName(ledger.currency)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                )}
              </PanelBody>
              {!loading && data.IssuerLedgerList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.IssuerLedgerList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
