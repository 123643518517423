import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "react-moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  transactionTypeName,
  currencyName,
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";

export default ({
  history,
  match,
  location,
  detail,
  data,
  DownloadButton,
  onDelete,

  isModal,
}) => {
  const modalRef = React.createRef();

  const {
    AdminLedgerList: { ledgers, count },
  } = data;

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  let DRMSum = 0;
  let DNTSum = 0;
  let totalSum = 0;

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/donations/${match.params.id}${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/donations/${match.params.id}${location.search}`);
          }}
        >
          기부자 목록
        </ModalHeader>
        <ModalBody>
          {count > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-b-0">
                  <thead>
                    <tr>
                      <th>형태</th>
                      <th>내용</th>
                      <th>일자</th>
                      <th>금액</th>
                      <th>기능</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledgers.map((ledger) => {
                      DRMSum +=
                        ledger.transactionType === "Donation" &&
                        ledger.currency === "DRM"
                          ? parseInt(ledger.value)
                          : 0;
                      DNTSum +=
                        ledger.transactionType === "Donation" &&
                        ledger.currency === "DNT"
                          ? parseInt(ledger.value)
                          : 0;

                      return (
                        <tr key={ledger.id}>
                          <td>{transactionTypeName(ledger.transactionType)}</td>
                          <td>
                            {ledger.title}{" "}
                            {ledger.commonStatus === "D" && <>(취소됨)</>}
                          </td>
                          <td>
                            <Moment format="YYYY-MM-DD HH:mm:ss">
                              {ledger.createdAt}
                            </Moment>
                          </td>
                          <td align="right">
                            <NumberFormat
                              value={ledger.value}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            {currencyName(ledger.currency)}
                          </td>
                          <td>
                            {ledger.commonStatus === "C" &&
                            ledger.transactionType === "Donation" ? (
                              <button
                                className="btn btn-white btn-xs text-red"
                                onClick={(e) => {
                                  onDelete(
                                    detail.AdminDonationDetail.id,
                                    ledger
                                  );
                                }}
                              >
                                취소
                              </button>
                            ) : (
                              <>-</>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>합계</td>
                      <td></td>
                      <td></td>
                      <td>
                        <div align="right">
                          {currencyName("DRM")} :
                          <NumberFormat
                            value={DRMSum}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </div>
                        <div align="right">
                          {currencyName("DNT")} :
                          <NumberFormat
                            value={DNTSum}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </div>
                        <div align="right">
                          합계 :
                          <NumberFormat
                            value={DRMSum + DNTSum}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p className="text-center">등록된 데이터가 없습니다.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="btn-group m-b-15 m-r-5">
            {detail && detail.AdminDonationDetail && (
              <Link
                to={`/donations/${match.params.id}/wallet/${
                  match.params.wallet
                }/trustlines/${
                  detail.AdminDonationDetail.trustlines.find(
                    (item) => item.currency === "DRM"
                  ).id
                }/remittance`}
                className="btn btn-white"
              >
                송금
              </Link>
            )}
            <DownloadButton />
            <button
              type="button"
              className="btn btn-white text-red"
              onClick={() => {
                history.push(`/donations/${match.params.id}${location.search}`);
              }}
            >
              닫기
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
