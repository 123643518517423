import { gql } from "apollo-boost";

/* 배너 */
export const GIFT_BANNER_LIST_QUERY = gql`
  query AdminGiftBannerList($keyword: String, $skip: Int!, $first: Int!) {
    AdminGiftBannerList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      giftBanners {
        id
        commonStatus
        coverImage
        title
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GIFT_BANNER_WRITE_QUERY = gql`
  mutation AdminGiftBannerWrite(
    $commonStatus: String!
    $coverImage: CoverUpload!
    $title: String!
    $timestamp: String
  ) {
    AdminGiftBannerWrite(
      commonStatus: $commonStatus
      coverImage: $coverImage
      title: $title
      timestamp: $timestamp
    )
  }
`;

export const GIFT_BANNER_DETAIL_QUERY = gql`
  query AdminGiftBannerDetail($id: String!) {
    AdminGiftBannerDetail(id: $id) {
      id
      commonStatus
      coverImage
      title
      createdAt
      updatedAt
    }
  }
`;

export const GIFT_BANNER_EDIT_QUERY = gql`
  mutation AdminGiftBannerEdit(
    $id: String!
    $commonStatus: String!
    $coverImage: CoverUpload
    $title: String!
  ) {
    AdminGiftBannerEdit(
      id: $id
      commonStatus: $commonStatus
      coverImage: $coverImage
      title: $title
    )
  }
`;

export const GIFT_BANNER_DELETE_QUERY = gql`
  mutation AdminGiftBannerDelete($id: String!) {
    AdminGiftBannerDelete(id: $id)
  }
`;
