import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { ButtonGroup } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PUSH_WRITE_QUERY } from "./Queries";
import { useMutation } from "react-apollo-hooks";
import { toast } from "react-toastify";

import UsersSearchModal from "./User/index";

export default withRouter(({ history, match, location }) => {
  const [pushWriteMutation] = useMutation(PUSH_WRITE_QUERY);
  const { register, handleSubmit, errors } = useForm();

  const [users, setUsers] = useState([]);
  const [checkItems, setCheckItems] = useState([]);

  const [usersSearchIsVisible, setUsersSearchIsVisible] = useState(false);
  const usersSearchToggleModal = () => {
    setUsersSearchIsVisible(!usersSearchIsVisible);
  };

  const handleSelectCheck = (checked, item) => {
    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      let itemArray = [];
      users.forEach((item) => itemArray.push(item));
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };
  const onDelete = () => {
    if (checkItems.length === 0) {
      toast.error("삭제할 수신자를 선택해주세요.");
      return;
    }

    let itemArray = [];
    users.forEach((item) => {
      !checkItems.includes(item) && itemArray.push(item);
    });
    setUsers(itemArray);
  };

  const onSubmit = async (data) => {
    try {
      const UserIds = users.map((item) => {
        return item.id;
      });
      const {
        data: { AdminPushWrite },
      } = await pushWriteMutation({
        variables: {
          pushType: "FCM",
          notificationTitle: data.notificationTitle,
          notificationBody: data.notificationBody,
          UserIds: UserIds,
        },
      });
      if (AdminPushWrite) {
        toast.success("처리 되었습니다.");
        history.replace(`/pushes${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);
  // console.log(users);
  return (
    <>
      <Helmet>
        <title>알림 관리 | 한밭페이</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">알림</li>
          </ol>
          <h1 className="page-header">한밭페이 알림</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link to={`/pushes${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  발송
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <Panel>
                <PanelHeader noButton={true}>알림정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="notificationTitle"
                          className="form-control"
                          placeholder="제목을 입력해주세요."
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.notificationTitle && (
                          <span>{errors.notificationTitle.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">내용</label>
                      <div className="col-md-10">
                        <textarea
                          name="notificationBody"
                          className="form-control"
                          placeholder="내용을 입력해주세요."
                          ref={register({
                            required: "내용을 입력해주세요.",
                          })}
                          rows={15}
                        ></textarea>
                        {errors.notificationBody && (
                          <span>{errors.notificationBody.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-sm-8">
              <Panel>
                <PanelHeader noButton={true}>
                  <ButtonGroup className="pull-right">
                    <button
                      type="button"
                      className=" btn btn-white btn-xs text-primary"
                      onClick={() => {
                        usersSearchToggleModal();
                      }}
                    >
                      <i className="fas fa-plus"></i> 추가
                    </button>
                    <button
                      type="button"
                      className=" btn btn-white btn-xs text-danger"
                      onClick={() => {
                        onDelete();
                        setCheckItems([]);
                      }}
                    >
                      <i className="fas fa-trash-alt"></i> 삭제
                    </button>
                  </ButtonGroup>
                  수신자 정보({users.length}건)
                </PanelHeader>
                <PanelBody className="p-0">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <thead>
                        <tr>
                          <th>이름(아이디)</th>
                          <th>정기충전(CMS)</th>
                          <th>
                            <input
                              type="checkbox"
                              onChange={(e) => handleAllCheck(e.target.checked)}
                              checked={
                                users.length > 0 &&
                                checkItems.length === users.length
                                  ? true
                                  : false
                              }
                            />
                          </th>
                        </tr>
                      </thead>
                      {users.length > 0 ? (
                        <tbody>
                          {users.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  {item.name}({item.userId})
                                </td>
                                <td>
                                  {item.isCms
                                    ? `매월 ${item.cmsDay}일 ${String(
                                        item.cmsValue
                                      ).replace(
                                        /(.)(?=(\d{3})+$)/g,
                                        "$1,"
                                      )}원 정기결제`
                                    : "-"}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleSelectCheck(e.target.checked, item)
                                    }
                                    checked={
                                      checkItems.includes(item) ? true : false
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <p className="m-40 text-center">
                                수신자를 추가해주세요.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </PanelBody>
              </Panel>
              <UsersSearchModal
                isVisible={usersSearchIsVisible}
                toggleModal={usersSearchToggleModal}
                isDeviceToken={true}
                isPosDeviceToken={false}
                users={users}
                setUsers={setUsers}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6 text-left">
              <Link to={`/pushes${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  발송
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
