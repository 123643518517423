import { gql } from "apollo-boost";

export const CHARGEREQUEST_CONFIG_QUERY = gql`
  query AdminChargeRequestConfig($id: String) {
    AdminChargeRequestConfig(id: $id) {
      id
      name
      manager {
        id
        role
        userId
        name
      }
    }
  }
`;
export const USER_LIST_QUERY = gql`
  query AdminUserList($keyword: String, $skip: Int, $first: Int) {
    AdminUserList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        userId
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const CHARGEREQUESTCMS_LIST_QUERY = gql`
  query AdminChargeRequestCMSList($skip: Int, $first: Int) {
    AdminChargeRequestCMSList(orderBy: "id_DESC", skip: $skip, first: $first) {
      chargeRequestCMses {
        id
        commonStatus
        year
        month

        uploadPath
        downloadPath
        file

        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const CHARGEREQUESTCMS_WRITE_QUERY = gql`
  mutation AdminChargeRequestCMSWrite(
    $year: String!
    $month: String!
    $file: FileUpload!
  ) {
    AdminChargeRequestCMSWrite(year: $year, month: $month, file: $file)
  }
`;
export const CHARGEREQUESTCMS_DETAIL_QUERY = gql`
  query AdminChargeRequestCMSDetail($id: String!) {
    AdminChargeRequestCMSDetail(id: $id) {
      id
      chargeRequestCMSDetails {
        id
        commonStatus

        column1
        column2
        column3
        column4
        column5
        column6
        column7
        column8
        column9
        column10
        column11
        column12
        column13

        createdAt
        updatedAt
      }
      commonStatus
      year
      month

      uploadPath
      downloadPath
      file

      createdAt
      updatedAt

      # users {
      #   id
      # }
      # chargeRequests {
      #   id
      # }
    }
  }
`;
export const CHARGEREQUESTCMS_LOAD_QUERY = gql`
  mutation AdminChargeRequestCMSLoad($id: String!) {
    AdminChargeRequestCMSLoad(id: $id)
  }
`;
export const CHARGEREQUESTCMS_APPROVAL_QUERY = gql`
  mutation AdminChargeRequestCMSApproval($id: String!) {
    AdminChargeRequestCMSApproval(id: $id) {
      success
      errorType1
      errorType2
      errorType3
    }
  }
`;
export const CHARGEREQUESTCMS_DETAILCHECKER_QUERY = gql`
  query AdminChargeRequestCMSDetailChecker(
    $year: Int!
    $month: Int!
    $name: String!
    $phone: String!
  ) {
    AdminChargeRequestCMSDetailChecker(
      year: $year
      month: $month
      name: $name
      phone: $phone
    ) {
      users {
        id
      }
      chargeRequests {
        id
      }
    }
  }
`;

export const CHARGEREQUESTCMS_DELETE_QUERY = gql`
  mutation AdminChargeRequestCMSDelete($id: String!) {
    AdminChargeRequestCMSDelete(id: $id)
  }
`;
