import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_EMPLOYEE_DETAIL_QUERY } from "../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import QRCode from "qrcode.react";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();

  const { data, loading } = useQuery(STORE_EMPLOYEE_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { UserId: match.params.user }
  });

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });
  if (!loading) {
    const {
      AdminStoreEmployeeDetail: { id }
    } = data;

    return (
      <>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/stores/${match.params.id}/employees${location.search}`
            );
          }}
        >
          <ModalHeader
            toggle={() => {
              history.push(
                `/stores/${match.params.id}/employees${location.search}`
              );
            }}
          >
            구성원 QRCode
          </ModalHeader>
          <ModalBody className=" p-10 bg-grey-transparent-5">
            <div className="col-12 text-center">
              <div
                style={{ display: "inline-block" }}
                className="rounded m-b-10 bg-white text-white p-15 valign-middle"
              >
                <QRCode
                  size={512}
                  level={"H"}
                  style={{ width: 512, height: 512 }}
                  value={`C-${id}`}
                />
              </div>
            </div>
            <div className="alert alert-primary fade show" role="alert">
              <ul className="m-0 p-l-15 f-s-14">
                <li>
                  <b>'QR코드'</b>를 인쇄하시려면 오른쪽마우스 클릭 후{" "}
                  <b>'이미지를 다른이름으로 저장하기'</b> 저장합니다.{" "}
                </li>
              </ul>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-right">
              <Link
                to={`/stores/${match.params.id}/employees${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
