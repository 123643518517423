import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { CHARGEREQUESTCMS_DETAILCHECKER_QUERY } from "./Queries";
import { commonStatusName } from "../../../Components/User/UserNames";

export default withRouter(
  ({ history, match, location, year, month, detail }) => {
    const { data, loading } = useQuery(CHARGEREQUESTCMS_DETAILCHECKER_QUERY, {
      variables: {
        year: year,
        month: month,
        name: detail.column12,
        phone: detail.column13,
      },
    });

    if (loading) {
      return <></>;
    } else {
      // console.log(data);
      return (
        <tr>
          <td>{commonStatusName(detail.commonStatus)}</td>
          <td>{detail.column1}</td>
          <td>{detail.column2}</td>
          <td>{detail.column3}</td>
          <td>{detail.column4}</td>
          <td>{detail.column5}</td>
          <td>{detail.column6}</td>
          <td>{detail.column7}</td>
          <td>{detail.column8}</td>
          <td>{detail.column9}</td>
          <td>{detail.column10}</td>
          <td>{detail.column12}</td>
          <td>{detail.column13}</td>
          <td>
            {data.AdminChargeRequestCMSDetailChecker.users.length > 0
              ? data.AdminChargeRequestCMSDetailChecker.chargeRequests.length >
                0
                ? "이미 충전됨"
                : detail.column3 !== "완전출금"
                ? "완전출금 안됨"
                : detail.commonStatus === "C"
                ? "처리완료"
                : "처리가능"
              : "회원정보(이름, 휴대전화) 없음"}
          </td>
        </tr>
      );
    }
  }
);
