import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { useForm, Controller } from "react-hook-form";
import DeliveryDeliverSelect from "../../../../Containers/DeliveryDeliver/Select";
import { deliveryOrderStateName } from "../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_ORDER_EDIT_STATE_COMPLETED_MUTATION,
  ADMIN_DELIVERY_ORDER_DETAIL_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

export default ({
  isModal,
  history,
  match,
  location,
  loading,
  data,
  baseUrl,
}) => {
  const modalRef = React.createRef();

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  const {
    AdminDeliveryOrderDetail: { id, state, deliveryDeliver },
  } = data;

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      deliveryDeliver_id: deliveryDeliver?.id,
    },
  });
  console.log({ data, errors });

  const [
    AdminDeliveryOrderEditStateCompletedMutation,
    { loading: AdminDeliveryOrderEditStateCompletedLoading },
  ] = useMutation(ADMIN_DELIVERY_ORDER_EDIT_STATE_COMPLETED_MUTATION, {
    refetchQueries: [
      {
        query: ADMIN_DELIVERY_ORDER_DETAIL_QUERY,
        variables: {
          id,
        },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (AdminDeliveryOrderEditStateCompletedLoading) {
        return;
      }
      const {
        data: { AdminDeliveryOrderEditStateCompleted },
      } = await AdminDeliveryOrderEditStateCompletedMutation({
        variables: {
          id,
          deliveryDeliver_id: data.deliveryDeliver_id,
        },
      });
      if (AdminDeliveryOrderEditStateCompleted) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.replace(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      ref={modalRef}
      toggle={() => {
        history.push(`/deliveryOrders/${id}${location.search}`);
      }}
    >
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          toggle={() => {
            history.push(`/deliveryOrders/${id}${location.search}`);
          }}
        >
          배송상태변경
        </ModalHeader>
        <ModalBody className=" p-10 bg-grey-transparent-5">
          <div className="form-horizontal form-bordered">
            <div className="form-group row">
              <label className="col-form-label col-md-2">배송상태</label>
              <div className="col-md-10">
                {deliveryOrderStateName(state)} =&gt; 배송완료
              </div>
            </div>

            <Controller
              name="deliveryDeliver_id"
              control={control}
              rules={{ required: "배송기사님을 선택해주세요." }}
              render={({ onChange, value }) => (
                <div className="form-group row">
                  <label className="col-form-label col-md-2">배송기사</label>
                  <div className="col-md-10">
                    <DeliveryDeliverSelect onChange={onChange} value={value} />
                    {errors.deliveryDeliver_id && (
                      <span className="text-red errorText">
                        {errors.deliveryDeliver_id.message}
                      </span>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-white text-sucess">
            확인
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
