import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { USER_EDIT_AUTHPASSWORD_QUERY } from "./Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, location, match, isModal }) => {
  const modalRef = React.createRef();
  const id = match.params.id;

  const { register, handleSubmit, errors, watch, setValue } = useForm();

  const [userEditAuthPasswordMutation] = useMutation(
    USER_EDIT_AUTHPASSWORD_QUERY
  );

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (!regex.test(e.target.value)) {
      setValue("authPassword", "");
    }
  };

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminUserEditAuthPassword },
      } = await userEditAuthPasswordMutation({
        variables: {
          id: id,
          authPassword: data.authPassword,
        },
      });
      if (AdminUserEditAuthPassword) {
        toast.success("처리 되었습니다.");
        history.push(`/users/${match.params.role}/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"md"}
        ref={modalRef}
        toggle={() => {
          history.push(`/users/${match.params.role}/${id}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/users/${match.params.role}/${id}${location.search}`
              );
            }}
          >
            승인비밀번호 변경
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-3">
                  변경 승인비밀번호
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="authPassword"
                    onChange={handleChange}
                    className="form-control"
                    ref={register({
                      required: "승인비밀번호를 입력해주세요.",
                    })}
                    minLength={6}
                    maxLength={6}
                  />
                  {errors.authPassword && (
                    <span>{errors.authPassword.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">
                  변경 승인비밀번호 확인
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="authPasswordConfirm"
                    onChange={handleChange}
                    className="form-control"
                    ref={register({
                      required: "승인비밀번호 확인을 입력해주세요.",
                      validate: (value) => {
                        return value !== watch("authPassword")
                          ? "승인비밀번호와 승인비밀번호확인이 일치하지 않습니다. 다시 입력해주세요."
                          : undefined;
                      },
                    })}
                    minLength={6}
                    maxLength={6}
                  />
                  {errors.authPasswordConfirm && (
                    <span>{errors.authPasswordConfirm.message}</span>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
