import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { STATISTICS_USERSSTOREORDER_QUERY } from "./Queries";
import { useQuery } from "react-apollo-hooks";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import qs from "qs";
import NumberFormat from "react-number-format";

export default withRouter(({ history, match, location, isModal }) => {
  const queryString = qs.parse(location.search.substr(1));
  const startDate = queryString.startDate;
  const endDate = queryString.endDate;

  const { data, loading } = useQuery(STATISTICS_USERSSTOREORDER_QUERY, {
    variables: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  const modalRef = React.createRef();
  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });
  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/statisticsUser?startDate=${startDate}&endDate=${endDate}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/statisticsUser?startDate=${startDate}&endDate=${endDate}`
            );
          }}
        >
          상점결제(환불제외) TOP 100
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="vertical-box with-grid inbox">
            <div className="vertical-box-inner-cell overflow-visible height-md">
              <PerfectScrollbar
                className="height-full p-5"
                options={{ suppressScrollX: true }}
              >
                {!loading ? (
                  <>
                    <div className="m-15">
                      <div className="table-responsive ">
                        <table className="table table-bordered table-striped m-b-0 text-center">
                          <thead>
                            <tr>
                              <th>순위</th>
                              <th>사용자</th>
                              <th>금액</th>
                              <th>건수</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.AdminStatisticsUsersStoreOrderTop100.usersStoreOrderTop100
                              .filter((item) => item.StoreOrderTotal > 0)
                              .map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}위</td>
                                    <td>
                                      <Link
                                        to={`/ledgers/DRM?transactionType=StoreOrder&startSearchDate=${startDate}&endSearchDate=${endDate}&wallet=${item.wallet}`}
                                      >
                                        {item.StoreOrderTotal > 0
                                          ? `${item.name}(${item.userId})`
                                          : "-"}
                                      </Link>
                                    </td>
                                    <td align={"right"}>
                                      <NumberFormat
                                        value={item.StoreOrderTotal}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      드림
                                    </td>
                                    <td>{item.StoreOrderCount}건</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center">데이터를 로딩중입니다.</p>
                )}
              </PerfectScrollbar>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
});
