import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { USER_EDIT_PASSWORD_QUERY } from "./Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, location, match, isModal }) => {
  const modalRef = React.createRef();
  const id = match.params.id;

  const { register, handleSubmit, errors, watch } = useForm();

  const [userEditPasswordMutation] = useMutation(USER_EDIT_PASSWORD_QUERY);

  const onSubmit = async data => {
    try {
      const {
        data: { AdminUserEditPassword }
      } = await userEditPasswordMutation({
        variables: {
          id: id,
          password: data.password
        }
      });
      if (AdminUserEditPassword) {
        toast.success("처리 되었습니다.");
        history.push(`/users/${match.params.role}/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"md"}
        ref={modalRef}
        toggle={() => {
          history.push(`/users/${match.params.role}/${id}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/users/${match.params.role}/${id}${location.search}`
              );
            }}
          >
            비밀번호 변경
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-3">비밀번호</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    ref={register({
                      required: "비밀번호를 입력해주세요."
                    })}
                  />
                  {errors.password && <span>{errors.password.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-3">비밀번호 확인</label>
                <div className="col-md-9">
                  <input
                    type="password"
                    name="passwordConfirm"
                    className="form-control"
                    ref={register({
                      required: "비밀번호 확인을 입력해주세요.",
                      validate: value => {
                        return value !== watch("password")
                          ? "비밀번호와 비밀번호확인이 일치하지 않습니다. 다시 입력해주세요."
                          : undefined;
                      }
                    })}
                  />
                  {errors.passwordConfirm && (
                    <span>{errors.passwordConfirm.message}</span>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-4 text-left"></div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
            <div className="col-xs-4 text-right"></div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
