import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  commonStatusName,
  areaArray,
} from "../../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_STORE_DONG_WRITE_MUTATION,
  ADMIN_DELIVERY_STORE_DONG_LIST_QUERY,
} from "../../Queries";
import Swal from "sweetalert2";

const Write = withRouter(({ history, match, location, isModal }) => {
  const baseUrl = "/deliveryStores/dongs";
  const modalRef = React.createRef();

  const [sidoArray, setSidoArray] = useState([]);
  const [sigunguArray, setSigunguArray] = useState([]);
  const [upmyundongArray, setUpmyundongArray] = useState([]);

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      commonStatus: "C",
      sido: "",
      sigungu: "",
      upmyundong: "",
    },
  });

  const [AdminDeliveryStoreDongWriteMutation] = useMutation(
    ADMIN_DELIVERY_STORE_DONG_WRITE_MUTATION,
    {
      refetchQueries: [
        {
          query: ADMIN_DELIVERY_STORE_DONG_LIST_QUERY,
          variables: { take: 100, skip: 0 },
        },
      ],
    }
  );

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminDeliveryStoreDongWrite },
      } = await AdminDeliveryStoreDongWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          sido: data.sido,
          sigungu: data.sigungu,
          upmyundong: data.upmyundong,
        },
      });
      if (AdminDeliveryStoreDongWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "이미 등록된 데이터입니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  useEffect(() => {
    setSidoArray(areaArray);
  }, []);
  useEffect(() => {
    if (watch("sido") !== "") {
      const sigunguArray = sidoArray.find(
        (item) => item.name === watch("sido")
      );
      const sigunguArrayArea = sigunguArray.area.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
      setSigunguArray(sigunguArrayArea);
    }
  }, [watch("sido")]);
  useEffect(() => {
    if (watch("sigungu") !== "") {
      const upmyundongArray = sigunguArray.find(
        (item) => item.name === watch("sigungu")
      );
      const upmyundongArrayArea = upmyundongArray.area.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
      setUpmyundongArray(upmyundongArrayArea);
    }
  }, [watch("sigungu")]);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Modal
      isOpen={true}
      size={"lg"}
      ref={modalRef}
      toggle={() => {
        history.push(`${baseUrl}${location.search}`);
      }}
    >
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          toggle={() => {
            history.push(`${baseUrl}${location.search}`);
          }}
        >
          동 등록
        </ModalHeader>
        <ModalBody className=" p-0">
          <div className="form-horizontal form-bordered">
            <div className="bg-white rounded">
              <Controller
                control={control}
                name="commonStatus"
                rules={{ required: "상태를 선택해주세요." }}
                render={({ onChange, value }) => (
                  <div className="form-group row">
                    <label className="col-form-label col-md-3">상태</label>
                    <div className="col-md-9">
                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="commonStatus1"
                          name="commonStatus"
                          value={"S"}
                          onChange={onChange}
                          checked={value === "S"}
                        />
                        <label htmlFor="commonStatus1">
                          {commonStatusName("S")}
                        </label>
                      </div>

                      <div className="radio radio-css radio-inline">
                        <input
                          type="radio"
                          id="commonStatus2"
                          name="commonStatus"
                          value={"C"}
                          onChange={onChange}
                          checked={value === "C"}
                        />
                        <label htmlFor="commonStatus2">
                          {commonStatusName("C")}
                        </label>
                      </div>
                      {errors.commonStatus && (
                        <div>{errors.commonStatus.message}</div>
                      )}
                    </div>
                  </div>
                )}
              />

              <div className="form-group row">
                <label className="col-form-label col-md-3">구역</label>
                <Controller
                  control={control}
                  name="sido"
                  rules={{ required: "시를 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      >
                        <option value="">시를 선택해주세요.</option>
                        {sidoArray.map((area, index) => (
                          <option key={index} value={area.name}>
                            {area.name}
                          </option>
                        ))}
                      </select>

                      {errors.sido && (
                        <span className="text-red errorText">
                          {errors.sido.message}
                        </span>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="sigungu"
                  rules={{ required: "구를 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      >
                        <option value="">구를 선택해주세요.</option>
                        {sigunguArray.map((area, index) => (
                          <option key={index} value={area.name}>
                            {area.name}
                          </option>
                        ))}
                      </select>

                      {errors.sigungu && (
                        <span className="text-red errorText">
                          {errors.sigungu.message}
                        </span>
                      )}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="upmyundong"
                  rules={{ required: "동을 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      >
                        <option value="">동을 선택해주세요.</option>
                        {upmyundongArray.map((area, index) => (
                          <option key={index} value={area.name}>
                            {area.name}
                          </option>
                        ))}
                      </select>

                      {errors.upmyundong && (
                        <span className="text-red errorText">
                          {errors.upmyundong.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-white text-blue">
            확인
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
});

export default Write;
