import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_ORDERPRODUCT_LIST_QUERY } from "../Queries";
import useInput from "../../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  const ticketProduct = queryString.ticketProduct
    ? queryString.ticketProduct
    : "";
  const ticketProductDate = queryString.ticketProductDate
    ? queryString.ticketProductDate
    : "";
  const ticketProductDateTime = queryString.ticketProductDateTime
    ? queryString.ticketProductDateTime
    : "";
  const status = queryString.status ? queryString.status : "";
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2021-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (ticketProduct ? "ticketProduct=" + ticketProduct + "&" : "") +
    (ticketProductDate ? "ticketProductDate=" + ticketProductDate + "&" : "") +
    (ticketProductDateTime
      ? "ticketProductDateTime=" + ticketProductDateTime + "&"
      : "") +
    (status ? "status=" + status + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading } = useQuery(TICKET_ORDERPRODUCT_LIST_QUERY, {
    variables: {
      ticketProduct,
      ticketProductDate,
      ticketProductDateTime,
      status,
      startSearchDate,
      endSearchDate,
      keyword,
      skip,
      first,
    },
  });

  const handleDateApplyEvent = (event, picker) => {
    var startDate = picker.startDate.format("YYYY-MM-DD");
    var endDate = picker.endDate.format("YYYY-MM-DD");

    const uri =
      "?" +
      (ticketProduct ? "ticketProduct=" + ticketProduct + "&" : "") +
      (ticketProductDate
        ? "ticketProductDate=" + ticketProductDate + "&"
        : "") +
      (ticketProductDateTime
        ? "ticketProductDateTime=" + ticketProductDateTime + "&"
        : "") +
      (status ? "status=" + status + "&" : "") +
      (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "");

    history.push(`/ticket/ticketOrderProducts${uri}page=1`);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/ticket/ticketOrderProducts${baseUrl}page=1`);
  };

  return (
    <>
      <Presenter
        history={history}
        match={match}
        location={location}
        page={page}
        ticketProduct={ticketProduct}
        ticketProductDate={ticketProductDate}
        ticketProductDateTime={ticketProductDateTime}
        status={status}
        startSearchDate={startSearchDate}
        endSearchDate={endSearchDate}
        keyword={keyword}
        keywordInput={keywordInput}
        blockSize={blockSize}
        first={first}
        skip={skip}
        data={data}
        loading={loading}
        baseUrl={baseUrl}
        handleDateApplyEvent={handleDateApplyEvent}
        handleSearch={handleSearch}
      ></Presenter>
    </>
  );
});
