import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo-hooks";
import {
  GIFT_PRODUCT_TICKETOPTIONDETAIL_WRITE_QUERY,
  GIFT_PRODUCT_DETAIL_QUERY,
} from "../../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import { commonStatusArray } from "../../../../../Components/User/UserNames";

export default withRouter(
  ({
    history,
    match,
    location,

    isModal,
  }) => {
    const modalRef = React.createRef();

    const [giftProductOptionDetailWriteMutation] = useMutation(
      GIFT_PRODUCT_TICKETOPTIONDETAIL_WRITE_QUERY,
      {
        refetchQueries: () => [
          {
            query: GIFT_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (data) => {
      try {
        const {
          data: { AdminGiftProductTicketOptionDetailWrite },
        } = await giftProductOptionDetailWriteMutation({
          variables: {
            giftProduct: match.params.id,
            giftProductOption: match.params.option,
            commonStatus: data.commonStatus,
            isSoldOut: data.isSoldOut === "true" ? true : false,
            price: data.price,
            name: data.name,
          },
        });
        if (AdminGiftProductTicketOptionDetailWrite) {
          toast.success("처리 되었습니다.");
          history.push(
            `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
          );
        }
      } catch (e) {
        console.log(e);
        var error = e.toString();
        error = error.replace("Error: GraphQL error:", "");
        toast.error(`${error}`);
      }
    };
    // console.log(errors);

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <Fragment>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/giftConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
                );
              }}
            >
              티켓일정등록
            </ModalHeader>
            <ModalBody className=" p-0">
              <div className="form-group">
                <div className=" p-10 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered tablebasic product_op_table">
                          <caption className="sr-only">
                            상품옵션생성-항목,옵션명,옵션값,옵션가격,옵션품정상태
                          </caption>
                          <thead>
                            <tr>
                              <th
                                abbr="옵션값"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션값
                              </th>
                              <th
                                abbr="옵션가격"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션가격
                              </th>
                              <th
                                abbr="옵션상태"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션상태
                              </th>
                              <th
                                abbr="품절"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                품절
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="ex) 2020-08-15, 02:00:00"
                                  ref={register({
                                    required: "옵션값을 입력해주세요.",
                                  })}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="price"
                                  placeholder="ex) 10000"
                                  ref={register({
                                    required: "옵션가격을 입력해주세요.",
                                  })}
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name="commonStatus"
                                  ref={register({
                                    required: "옵션상태를 입력해주세요.",
                                  })}
                                >
                                  <option value="">상태</option>
                                  {Object.keys(commonStatusArray).map(
                                    (key, index) => (
                                      <option key={index} value={key}>
                                        {commonStatusArray[key]}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  name="isSoldOut"
                                  value={true}
                                  ref={register()}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {errors.detailName && (
                        <Fragment>
                          <span>{errors.detailName.message}</span>
                          <br />
                        </Fragment>
                      )}
                      {errors.detailPrice && (
                        <Fragment>
                          <span>{errors.detailPrice.message}</span>
                          <br />
                        </Fragment>
                      )}
                      {errors.detailCommonStatus && (
                        <Fragment>
                          <span>{errors.detailCommonStatus.message}</span>
                          <br />
                        </Fragment>
                      )}
                    </div>
                    <div className="col-sm-12">
                      <div className="text-left text-danger">
                        * 옵션값을 추가할 경우, 옵션값, 옵션가격, 옵션상태를
                        입력할 수 있습니다.
                        <br />
                        (티켓 ex. 옵션값 - 일정 또는 시간(2020-08-15, 02:00:00)
                        <br />
                        <br />* 품절 - 옵션의 품절 여부를 선택합니다.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="col-xs-12 text-center">
                <button type="submit" className="btn btn-white text-blue">
                  확인
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </Fragment>
    );
  }
);
