import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { STORE_CATEGORY_LIST_QUERY, STORE_WRITE_QUERY } from "./Queries";
import { useQuery, useMutation } from "react-apollo-hooks";
import DaumPostcode from "react-daum-postcode";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const { data } = useQuery(STORE_CATEGORY_LIST_QUERY, {
    fetchPolicy: "network-only"
  });
  const [storeWriteMutation] = useMutation(STORE_WRITE_QUERY);
  const { register, handleSubmit, setValue, errors } = useForm();

  const [zipcodeModal, setZipcodeModal] = useState(false);
  const handleZipcodeModal = () => {
    setZipcodeModal(!zipcodeModal);
  };
  const handleAddress = data => {
    setValue("storeZipcode", data.zonecode);
    setValue("storeRoadAddress", data.roadAddress);
    setValue("storeJibunAddress", data.jibunAddress);
    handleZipcodeModal();
  };
  const onSubmit = async data => {
    try {
      const {
        data: { AdminStoreWrite }
      } = await storeWriteMutation({
        variables: {
          storeConfig: match.params.storeConfig,

          storeCategory: data.storeCategory,
          name: data.name,
          productInfo: data.productInfo,
          storeInfo: data.storeInfo,

          storeOperationHour: data.storeOperationHour,
          storePersonalDay: data.storePersonalDay,
          storeTel: data.storeTel,
          storeCEO: data.storeCEO,

          storeZipcode: data.storeZipcode,
          storeRoadAddress: data.storeRoadAddress,
          storeJibunAddress: data.storeJibunAddress,
          storeAddressDetail: data.storeAddressDetail
        }
      });
      if (AdminStoreWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/stores${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/stores${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`/stores${location.search}`);
            }}
          >
            등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-form-label col-md-2">분류</label>
                <div className="col-md-10">
                  {data &&
                    data.AdminStoreCategoryList.storeCategories.map(
                      category => {
                        return (
                          <>
                            <div class="radio radio-css radio-inline">
                              <input
                                key={category.id}
                                type="radio"
                                id={`storeCategory${category.id}`}
                                name="storeCategory"
                                className="form-control"
                                value={category.id}
                                ref={register({
                                  required: "분류를 선택해주세요."
                                })}
                              />
                              <label htmlFor={`storeCategory${category.id}`}>
                                {category.name}
                              </label>
                            </div>
                          </>
                        );
                      }
                    )}
                  {errors.storeCategory && (
                    <span>{errors.storeCategory.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">이름</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="이름을 입력해주세요."
                    ref={register({
                      required: "이름을 입력해주세요."
                    })}
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">상품정보</label>
                <div className="col-md-10">
                  <textarea
                    name="productInfo"
                    className="form-control"
                    placeholder="상품정보를 입력해주세요."
                    ref={register({
                      required: "상품정보를 입력해주세요."
                    })}
                  ></textarea>
                  {errors.productInfo && (
                    <span>{errors.productInfo.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">가맹점정보</label>
                <div className="col-md-10">
                  <textarea
                    name="storeInfo"
                    className="form-control"
                    placeholder="가맹점정보를 입력해주세요."
                    ref={register({
                      required: "가맹점정보를 입력해주세요."
                    })}
                  ></textarea>
                  {errors.storeInfo && <span>{errors.storeInfo.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">운영시간</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storeOperationHour"
                    className="form-control"
                    placeholder="운영시간을 입력해주세요."
                    ref={register({
                      required: "운영시간을 입력해주세요."
                    })}
                  />
                  {errors.storeOperationHour && (
                    <span>{errors.storeOperationHour.message}</span>
                  )}
                </div>
                <label className="col-form-label col-md-2">휴무일</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storePersonalDay"
                    className="form-control"
                    placeholder="휴무일를 입력해주세요."
                    ref={register({
                      required: "휴무일를 입력해주세요."
                    })}
                  />
                  {errors.storePersonalDay && (
                    <span>{errors.storePersonalDay.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">전화번호</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storeTel"
                    className="form-control"
                    placeholder="전화번호를 입력해주세요."
                    ref={register({
                      required: "전화번호를 입력해주세요."
                    })}
                  />
                  {errors.storeTel && <span>{errors.storeTel.message}</span>}
                </div>
                <label className="col-form-label col-md-2">대표자명</label>
                <div className="col-md-4">
                  <input
                    type="text"
                    name="storeCEO"
                    className="form-control"
                    placeholder="대표자명을 입력해주세요."
                    ref={register({
                      required: "대표자명을 입력해주세요."
                    })}
                  />
                  {errors.storeCEO && <span>{errors.storeCEO.message}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">우편번호</label>
                <div className="col-md-10">
                  <InputGroup size="sm">
                    <input
                      type="text"
                      name="storeZipcode"
                      className="form-control"
                      placeholder="우편번호를 입력해주세요."
                      ref={register({
                        required: "대표자명을 입력해주세요."
                      })}
                      readOnly
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        size="sm"
                        color="primary"
                        onClick={handleZipcodeModal}
                      >
                        <i className="fa fa-search fa-fw"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  {errors.storeZipcode && (
                    <span>{errors.storeZipcode.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">도로명주소</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeRoadAddress"
                    className="form-control"
                    placeholder="도로명주소를 입력해주세요."
                    ref={register({
                      required: "도로명주소를 입력해주세요."
                    })}
                    readOnly
                  />
                  {errors.storeRoadAddress && (
                    <span>{errors.storeRoadAddress.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">지번주소</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeJibunAddress"
                    className="form-control"
                    placeholder="지번주소를 입력해주세요."
                    ref={register({
                      required: "지번주소를 입력해주세요."
                    })}
                    readOnly
                  />
                  {errors.storeJibunAddress && (
                    <span>{errors.storeJibunAddress.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-md-2">상세주소</label>
                <div className="col-md-10">
                  <input
                    type="text"
                    name="storeAddressDetail"
                    className="form-control"
                    placeholder="상세주소를 입력해주세요."
                    ref={register({
                      required: "상세주소를 입력해주세요."
                    })}
                  />
                  {errors.storeAddressDetail && (
                    <span>{errors.storeAddressDetail.message}</span>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-4 text-left"></div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
            <div className="col-xs-4 text-right"></div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
        <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode height={700} onComplete={handleAddress} />
        </ModalBody>
      </Modal>
    </>
  );
});
