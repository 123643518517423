import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import {
  deliveryBoxConditionName,
  deliveryBoxStateName,
  dayOfWeekShortName,
  dayOfWeekName,
  deliveryBoxTypeName,
  deliveryBoxProductStateName,
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactMoment from "react-moment";

const Detail = ({ history, match, location, data, baseUrl }) => {
  const {
    StoreDeliveryBoxDetail: {
      id,
      createdAt,
      updatedAt,

      state,
      type,
      deliveryPlace,
      deliveryAddress,
      deliveryBoxProducts,
      user,

      condition,
      totalPrice,
      deliveryDate,
    },
  } = data;

  const date = new Date(parseInt(deliveryDate));
  const deliveryDayOfWeek = date.getDay();

  const payDate = new Date(moment(date).add(-1, "d"));
  const dayDayOfWeek = payDate.getDay();

  const drm = user.trustlines.find((trustline) => trustline.currency === "DRM");
  const amount = user.isCms ? drm.amount - user.cmsValue : drm.amount;

  return (
    <Fragment>
      <div className="row m-b-15">
        <div className="col-xs-4 text-left">
          <Link
            to={`/delivery/deliveryBoxes${location.search}`}
            className="btn btn-white"
          >
            목록
          </Link>
        </div>
        <div className="col-xs-4 text-center"></div>
        <div className="col-xs-4 text-right">
          <Link
            to={`/delivery/deliveryOrders?deliveryBox_id=${id}`}
            className="btn btn-white m-l-5"
          >
            배송내역
          </Link>
        </div>
      </div>

      <Alert color="primary" className="m-b-15">
        <div>
          <strong className="f-s-15">※ 구독 상태 안내</strong>
        </div>
        <div>
          * 구독 : 3만드림 이상 상품을 구독한 경우로 배송이 가능한 상태입니다.
        </div>
        <div>
          * 조건미달 : 보유드림이 부족하거나, 3만드림 이하로 상품을 구독한
          경우로 배송이 불가능한 상태입니다.
        </div>
        <div>
          <strong className="f-s-15">※ 결제 상태 안내</strong>
        </div>
        <div>* 결제 : 지정한 일정으로 상품을 결제하는 상태입니다.</div>
        <div>
          * 한주미룸 : 지정한 일정을 기준으로 구독을 한주 넘기는 상태입니다.
        </div>
      </Alert>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>상세정보</PanelHeader>
            <PanelBody className="p-0">
              <div className="invoice order-product">
                <div className="invoice-header p-0">
                  <div className="invoice-from p-10">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">상태</strong>
                      <ul className="common-status">
                        <li>
                          <div className="left">구독</div>
                          <div className="right">
                            {deliveryBoxConditionName(condition)}
                          </div>
                        </li>
                        <li>
                          <div className="left">결제</div>
                          <div className="right">
                            {deliveryBoxStateName(state)}
                          </div>
                        </li>
                      </ul>
                    </address>
                  </div>
                  <div className="invoice-to p-10">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">구독자 정보</strong>
                      <ul className="common-status">
                        <li>
                          <div className="left">이름</div>
                          <div className="right">
                            {user.name}({user.userId})
                          </div>
                        </li>
                        <li>
                          <div className="left">연락처</div>
                          <div className="right">{user.phone}</div>
                        </li>
                        <li>
                          <div className="left">결제일</div>
                          <div className="right">
                            <ReactMoment format="YYYY-MM-DD">
                              {payDate}
                            </ReactMoment>
                            ({dayOfWeekShortName(dayDayOfWeek)})
                          </div>
                        </li>
                        <li>
                          <div className="left">배송일</div>
                          <div className="right">
                            <ReactMoment format="YYYY-MM-DD">
                              {date}
                            </ReactMoment>
                            ({dayOfWeekShortName(deliveryDayOfWeek)})
                          </div>
                        </li>
                      </ul>
                    </address>
                  </div>
                  <div className="invoice-to p-10">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">배송정보</strong>
                      <ul className="common-status">
                        <li>
                          <div className="left">타입</div>
                          <div className="right">
                            {deliveryBoxTypeName(type)}
                          </div>
                        </li>
                        {type === "Pickup" && (
                          <>
                            <li>
                              <div className="left">배송매장</div>
                              <div className="right">
                                {deliveryPlace.deliveryStore.store.name} &gt;{" "}
                                {deliveryPlace.deliveryStore.user.name}
                              </div>
                            </li>
                            <li>
                              <div className="left">도로명 주소</div>
                              <div className="right">
                                {deliveryPlace.roadAddress}
                              </div>
                            </li>
                            <li>
                              <div className="left">지번주소</div>
                              <div className="right">
                                {deliveryPlace.jibunAddress}
                              </div>
                            </li>
                            <li>
                              <div className="left">상세주소</div>
                              <div className="right">
                                {deliveryPlace.addressDetail}
                              </div>
                            </li>
                            <li>
                              <div className="left">픽업요일</div>
                              <div className="right">
                                {dayOfWeekName(deliveryPlace.dayOfWeek)}
                              </div>
                            </li>
                          </>
                        )}
                        {type === "Delivery" && (
                          <>
                            <li>
                              <div className="left">배송매장</div>
                              <div className="right">
                                {deliveryAddress.deliveryStore.store.name} &gt;{" "}
                                {deliveryAddress.deliveryStore.user.name}
                              </div>
                            </li>
                            <li>
                              <div className="left">도로명 주소</div>
                              <div className="right">
                                {deliveryAddress.roadAddress}
                              </div>
                            </li>
                            <li>
                              <div className="left">지번주소</div>
                              <div className="right">
                                {deliveryAddress.jibunAddress}
                              </div>
                            </li>
                            <li>
                              <div className="left">상세주소</div>
                              <div className="right">
                                {deliveryAddress.addressDetail}
                              </div>
                            </li>
                            <li>
                              <div className="left">배달요일</div>
                              <div className="right">
                                {dayOfWeekName(deliveryAddress.dayOfWeek)}
                              </div>
                            </li>
                          </>
                        )}
                      </ul>
                    </address>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              구독상품({deliveryBoxProducts.length}개)
            </PanelHeader>
            {deliveryBoxProducts.length > 0 ? (
              <>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <colgroup>
                        <col width="15%" />
                        <col width="*" />
                        <col width="15%" />
                        <col width="15%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>구독상태</th>
                          <th>상품명</th>
                          <th>수량</th>
                          <th>금액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryBoxProducts.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{deliveryBoxProductStateName(item.state)}</td>
                              <td>
                                <div>
                                  <Link
                                    to={`/delivery/deliveryProducts/${item.deliveryProduct.id}/edit`}
                                  >
                                    {item.deliveryProduct.name}(
                                    <NumberFormat
                                      value={item.deliveryProduct.price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    드림)
                                  </Link>
                                </div>
                                {(item.deliveryProductOptionDetail1 ||
                                  item.deliveryProductOptionDetail2 ||
                                  item.deliveryProductOptionDetail3) && (
                                  <div>
                                    [옵션]
                                    {item.deliveryProductOptionDetail1 && (
                                      <>
                                        {item.deliveryProductOptionDetail1.name}
                                        {item.deliveryProductOptionDetail1
                                          .price > 0 && (
                                          <>
                                            (+
                                            <NumberFormat
                                              value={
                                                item
                                                  .deliveryProductOptionDetail1
                                                  .price
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                            />
                                            드림)
                                          </>
                                        )}
                                      </>
                                    )}
                                    {item.deliveryProductOptionDetail2 && (
                                      <>
                                        {" "}
                                        /{" "}
                                        {item.deliveryProductOptionDetail2.name}
                                        {item.deliveryProductOptionDetail2
                                          .price > 0 && (
                                          <>
                                            (+
                                            <NumberFormat
                                              value={
                                                item
                                                  .deliveryProductOptionDetail2
                                                  .price
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                            />
                                            드림)
                                          </>
                                        )}
                                      </>
                                    )}
                                    {item.deliveryProductOptionDetail3 && (
                                      <>
                                        {" "}
                                        /{" "}
                                        {item.deliveryProductOptionDetail3.name}
                                        {item.deliveryProductOptionDetail3
                                          .price > 0 && (
                                          <>
                                            (+
                                            <NumberFormat
                                              value={
                                                item
                                                  .deliveryProductOptionDetail3
                                                  .price
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                            />
                                            드림)
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </td>
                              <td>
                                <div>{item.count}개</div>
                              </td>
                              <td>
                                <div className="text-right">
                                  <NumberFormat
                                    value={item.totalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>합계</td>
                          <td colSpan={2}></td>
                          <td>
                            <div className="text-right">
                              <NumberFormat
                                value={totalPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </PanelBody>
              </>
            ) : (
              <PanelBody>
                <p className="text-center">등록된 데이터가 없습니다.</p>
              </PanelBody>
            )}
          </Panel>
        </div>
      </div>
    </Fragment>
  );
};

const Presenter = ({ history, match, location, loading, data, baseUrl }) => {
  return (
    <div>
      <Helmet>
        <title>구독박스 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/stores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">구독박스</li>
      </ol>
      <h1 className="page-header">구독박스</h1>

      {!loading ? (
        <Detail
          history={history}
          match={match}
          location={location}
          data={data}
          baseUrl={baseUrl}
        />
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};

export default Presenter;
