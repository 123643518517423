import { gql } from "apollo-boost";

/* 가맹점 설정 */
export const DASHBOARD_QUERY = gql`
  query AdminDashboard {
    AdminDashboard {
      events {
        id
        eventConfig {
          id
        }
        commonApp
        commonStatus
        coverImg
        title
        startDate
        endDate
      }
      donations {
        id
        commonStatus
        coverImg
        title
        startDate
        endDate
      }
      storeCount
      userCount
      ledgerCount
      noticeCount
      inquiryCount
      chargeRequestCount
      notices {
        id
        noticeConfig {
          id
        }
        title
        createdAt
      }
      inquiries {
        id
        commonStatus
        inquiryConfig {
          id
        }
        question
        createdAt
      }
    }
  }
`;
