import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { COUPON_EDITPRODUCT_QUERY, COUPON_DETAIL_QUERY } from "../../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { graphqlUri } from "../../../../../Config/Env";
import { toast } from "react-toastify";

const Presenter = ({
  history,
  match,
  location,
  data,

  isModal,
}) => {
  const modalRef = React.createRef();

  const {
    AdminCouponDetail: { id, productName, productDetail, productImage },
  } = data;

  const [couponEditMutation] = useMutation(COUPON_EDITPRODUCT_QUERY, {
    refetchQueries: () => [{ query: COUPON_DETAIL_QUERY, variables: { id } }],
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      productName: productName,
      productDetail: productDetail,
    },
  });

  const [displayProductImage, setDisplayProductImage] = useState(
    productImage
      ? graphqlUri + productImage
      : "/assets/img/user/user-default.jpg"
  );
  const handleProductImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setDisplayProductImage(reader.result);
    };
  };
  const onClick_product = () => {
    document.getElementById("productImage").click();
  };
  const onSubmit = async (data) => {
    try {
      let couponData = {
        id: id,
        productName: data.productName,
        productDetail: data.productDetail,
      };

      if (data.productImage[0]) {
        couponData = { ...couponData, productImage: data.productImage[0] };
      }
      const {
        data: { AdminCouponEditProduct },
      } = await couponEditMutation({
        variables: couponData,
      });
      if (AdminCouponEditProduct) {
        toast.success("처리 되었습니다.");
        history.push(
          `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"sm"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/couponConfigs/${match.params.couponConfig}/coupons/${id}${location.search}`
              );
            }}
          >
            수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="form-group row m-b-0">
                <label className="col-form-label col-md-3">상품명</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="productName"
                    className="form-control"
                    placeholder="상품명을 입력해주세요."
                    ref={register({
                      required: "상품명을 입력해주세요.",
                    })}
                  />
                  {errors.productName && (
                    <span>{errors.productName.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row m-b-0">
                <label className="col-form-label col-md-3">상품설명</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="productDetail"
                    className="form-control"
                    placeholder="상품설명을 입력해주세요."
                    ref={register({
                      required: "상품설명을 입력해주세요.",
                    })}
                  />
                  {errors.productDetail && (
                    <span>{errors.productDetail.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group row m-b-0">
                <div className="col-md-12">
                  <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                    <div className="vertical-box-column valign-middle text-center">
                      <img
                        src={displayProductImage}
                        alt="이미지 업로드"
                        className="width-200"
                      />
                    </div>
                  </div>
                  <div className="m-t-10">
                    <input
                      type="file"
                      id="productImage"
                      name="productImage"
                      className="hide"
                      onChange={handleProductImage}
                      ref={register({})}
                    />
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={onClick_product}
                    >
                      커버 이미지 업로드
                    </button>
                  </div>
                  {errors.coverImage && (
                    <span className="text-danger">
                      {errors.coverImage.message}
                    </span>
                  )}
                  <div className="alert alert-primary fade show m-t-10 m-b-0">
                    <b>커버이미지 사이즈 : 넓이 600px X 높이 600px </b>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default Presenter;
