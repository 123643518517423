import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  STORE_DETAIL_QUERY,
  STORE_EDIT_LOGO_QUERY,
  STORE_DELETE_LOGO_QUERY,
  STORE_EDIT_PROOFDATA_QUERY,
  STORE_DELETE_PROOFDATA_QUERY,
  STORE_ADD_CURRENCY_QUERY,
} from "./Queries";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import ReactMoment from "react-moment";
import ProductList from "./Product/List";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../Config/Env";
import { toast } from "react-toastify";

import nl2br from "react-nl2br";
import {
  currencyName,
  commonStatusName,
  bankName,
} from "../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(STORE_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });

  const [userEditAvatarMutation] = useMutation(STORE_EDIT_LOGO_QUERY, {
    refetchQueries: () => [
      { query: STORE_DETAIL_QUERY, variables: { id: match.params.id } },
    ],
  });
  const [userDeleteAvatarMutation] = useMutation(STORE_DELETE_LOGO_QUERY, {
    refetchQueries: () => [
      { query: STORE_DETAIL_QUERY, variables: { id: match.params.id } },
    ],
  });
  const handleLogo = async (e, id) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];

    try {
      const {
        data: { AdminStoreEditLogo },
      } = await userEditAvatarMutation({
        variables: {
          id: id,
          file,
        },
      });
      if (AdminStoreEditLogo) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const onDeleteLogo = async (id) => {
    try {
      const {
        data: { AdminStoreDeleteLogo },
      } = await userDeleteAvatarMutation({
        variables: {
          id: id,
        },
      });
      if (AdminStoreDeleteLogo) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${match.params.id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const onClickLogo = () => {
    document.getElementById("logo").click();
  };

  const [userEditProofDataMutation] = useMutation(STORE_EDIT_PROOFDATA_QUERY, {
    refetchQueries: () => [
      { query: STORE_DETAIL_QUERY, variables: { id: match.params.id } },
    ],
  });
  const [userDeleteProofDataMutation] = useMutation(
    STORE_DELETE_PROOFDATA_QUERY,
    {
      refetchQueries: () => [
        { query: STORE_DETAIL_QUERY, variables: { id: match.params.id } },
      ],
    }
  );
  const handleProofData = async (e, id) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];

    try {
      const {
        data: { AdminStoreEditProofData },
      } = await userEditProofDataMutation({
        variables: {
          id: id,
          file,
        },
      });
      if (AdminStoreEditProofData) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const onDeleteProofData = async (id) => {
    try {
      const {
        data: { AdminStoreDeleteProofData },
      } = await userDeleteProofDataMutation({
        variables: {
          id: id,
        },
      });
      if (AdminStoreDeleteProofData) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${match.params.id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  const onClickProofData = () => {
    document.getElementById("proofData").click();
  };

  const [storeAddCurrencyMutation] = useMutation(STORE_ADD_CURRENCY_QUERY, {
    refetchQueries: () => [
      { query: STORE_DETAIL_QUERY, variables: { id: match.params.id } },
    ],
  });
  const onAddCurrency = async () => {
    try {
      const {
        data: { AdminStoreAddCurrency },
      } = await storeAddCurrencyMutation({
        variables: {
          id: match.params.id,
          currency: "GRU",
        },
      });
      if (AdminStoreAddCurrency) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${match.params.id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  const {
    AdminStoreDetail: {
      id,
      commonStatus,
      UserId,
      storeCategory,
      storeCategories,

      storeLogo,
      name,
      productInfo,
      storeInfo,
      storeNumber,

      storeOperationHour,
      storePersonalDay,
      storeTel,
      storeCEO,
      email,
      phone,

      storeZipcode,
      storeRoadAddress,
      storeJibunAddress,
      storeAddressDetail,
      longitude,
      latitude,

      bank_name,
      bank_num,
      bank_account_holder,

      storeProofData,

      isGift,
      giftedAt,

      drmPercent,
      gruPercent,

      updatedAt,
      user,
    },
  } = data;

  const storeLogoImage =
    storeLogo === "" || storeLogo === null
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUri}${storeLogo}`;
  const storeProofDataImage = `${graphqlUri}${storeProofData}`;

  const ecoCategory = storeCategories.find((item) => item.name === "에코");
  const ecoTrustline = user.trustlines.find((item) => item.currency === "GRU");

  return (
    <>
      <Helmet>
        <title>한밭페이 | 가맹점 관리</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="/stores">가맹점</Link>
          </li>
          <li className="breadcrumb-item active">가맹점</li>
        </ol>
        <h1 className="page-header">가맹점</h1>

        <div key="topButton" className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link to={`/stores${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right">
            <Link
              className=" btn btn-white text-green "
              to={`/stores/${id}/user/${UserId}/qrcode${location.search}`}
            >
              QRCode
            </Link>
            <Link
              className=" btn btn-white text-green "
              to={`/stores/${id}/edit${location.search}`}
            >
              수정
            </Link>
          </div>
        </div>

        <div key="storeInfo" className="row">
          <div className="col-sm-3">
            <Panel>
              <PanelHeader noButton={true}>이미지</PanelHeader>
              <PanelBody className="p-0">
                <div className="form-horizontal form-bordered">
                  <div className="bg-white rounded">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                          <div className="vertical-box-column valign-middle text-center">
                            <img
                              src={storeLogoImage}
                              alt="이미지 업로드"
                              className="width-200"
                            />
                          </div>
                        </div>
                        <div className="m-t-10">
                          <input
                            type="file"
                            id="logo"
                            onChange={(e) => handleLogo(e, id)}
                            className="hide"
                          />
                          <button
                            className="btn btn-primary btn-block"
                            onClick={onClickLogo}
                          >
                            이미지 업로드
                          </button>
                          <Button
                            type="button"
                            color="white"
                            className="btn btn-primary btn-block text-red"
                            onClick={() => {
                              onDeleteLogo(id);
                            }}
                          >
                            이미지 삭제
                          </Button>
                        </div>
                        <div className="alert alert-primary fade show m-t-10 m-b-0">
                          <b>이미지 사이즈 : </b> 넓이 600px X 높이 600px
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>

          <div className="col-sm-9">
            {ecoCategory && !ecoTrustline && (
              <div className="alert alert-danger fade show f-s-14">
                * 분류 중 중복 분류에 에코가 포함된, 경우 그루를 해주세요.
                <br /> &nbsp;&nbsp; 미 추가시 드림으로만 결제할 수 있습니다.
                <button
                  style={{ position: "absolute", top: 20, right: 15 }}
                  className="btn btn-danger f-s-14"
                  onClick={onAddCurrency}
                >
                  추가
                </button>
              </div>
            )}
            <Panel>
              <PanelHeader noButton={true}>지역화폐</PanelHeader>
              <PanelBody className="p-0">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped m-b-0">
                    <thead>
                      <tr>
                        <th>화폐</th>
                        <th>금액</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.trustlines.map((trustline) => {
                        return (
                          <tr key={trustline.id}>
                            <td>{currencyName(trustline.currency)}</td>
                            <td align="right">
                              <NumberFormat
                                value={trustline.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelHeader noButton={true}>가맹점 정보</PanelHeader>
              <PanelBody className="p-0">
                <div className="form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">상태</label>
                    <div className="col-md-10">
                      {commonStatusName(commonStatus)}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-md-2">분류</label>
                    <div className="col-md-4">
                      <div>대표 : {storeCategory.name}</div>
                      <div>
                        중복 :{" "}
                        {storeCategories
                          .map((category, index) => {
                            return category.name;
                          })
                          .join(", ")}
                      </div>
                    </div>
                    <label className="col-form-label col-md-2">상호명</label>
                    <div className="col-md-4">{name}</div>
                  </div>

                  {ecoCategory && (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">드림(%)</label>
                      <div className="col-md-4">{drmPercent}</div>
                      <label className="col-form-label col-md-2">그루(%)</label>
                      <div className="col-md-4">{gruPercent}</div>
                    </div>
                  )}

                  <div className="form-group row">
                    <label className="col-form-label col-md-2">상품정보</label>
                    <div className="col-md-10">{productInfo}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">
                      가맹점정보
                    </label>
                    <div className="col-md-10">{nl2br(storeInfo)}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">운영시간</label>
                    <div className="col-md-4">{storeOperationHour}</div>
                    <label className="col-form-label col-md-2">휴무일</label>
                    <div className="col-md-4">{storePersonalDay}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">전화번호</label>
                    <div className="col-md-4">{storeTel}</div>
                    <label className="col-form-label col-md-2">
                      사업자등록번호
                    </label>
                    <div className="col-md-4">{storeNumber}</div>
                  </div>
                  <div className="form-group row">
                    <div className="col-form-label col-md-2">
                      사업자등록증
                      <br />
                      <input
                        type="file"
                        id="proofData"
                        onChange={(e) => handleProofData(e, id)}
                        className="hide"
                      />
                      <button
                        type="button"
                        className="btn btn-xs btn-primary"
                        onClick={onClickProofData}
                      >
                        업로드
                      </button>
                      <button
                        type="button"
                        className="btn btn-xs btn-white text-red"
                        onClick={() => {
                          onDeleteProofData(id);
                        }}
                      >
                        삭제
                      </button>
                    </div>
                    <div className="col-md-10">
                      {storeProofData ? (
                        <img src={storeProofDataImage} alt={"사업자등록증"} />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">드림상점</label>
                    <div className="col-md-4">{isGift ? "입점" : "미입점"}</div>
                    <label className="col-form-label col-md-2">입점일</label>
                    <div className="col-md-4">
                      {isGift && giftedAt ? (
                        <ReactMoment format="YYYY-MM-DD">
                          {giftedAt}
                        </ReactMoment>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">
                      최종수정일자
                    </label>
                    <div className="col-md-10">
                      <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                        {updatedAt}
                      </ReactMoment>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader noButton={true}>대표자 정보</PanelHeader>
              <PanelBody className="p-0">
                <div className="form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">대표자</label>
                    <div className="col-md-10">{storeCEO}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">이메일</label>
                    <div className="col-md-4">{email ? email : "-"}</div>
                    <label className="col-form-label col-md-2">휴대전화</label>
                    <div className="col-md-4">{phone ? phone : "-"}</div>
                  </div>
                </div>
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader noButton={true}>주소 정보</PanelHeader>
              <PanelBody className="p-0">
                <div className="form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">주소</label>
                    <div className="col-md-10">
                      {storeZipcode}
                      <br />
                      도로명주소 : {storeRoadAddress}
                      <br />
                      지번주소 : {storeJibunAddress}
                      <br />
                      상세주소 : {storeAddressDetail}
                      <br />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">경도</label>
                    <div className="col-md-4">{longitude}</div>
                    <label className="col-form-label col-md-2">위도</label>
                    <div className="col-md-4">{latitude}</div>
                  </div>
                </div>
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader noButton={true}>정산 정보</PanelHeader>
              <PanelBody className="p-0">
                <div className="form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">은행</label>
                    <div className="col-md-10">
                      {bank_name ? bankName(bank_name) : "-"}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">계좌번호</label>
                    <div className="col-md-4">{bank_num ? bank_num : "-"}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">예금주</label>
                    <div className="col-md-4">
                      {bank_account_holder ? bank_account_holder : "-"}
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>

            <Panel>
              <PanelHeader noButton={true}>연동 사용자</PanelHeader>
              <PanelBody className="p-0">
                <div className="form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">이름</label>
                    <div className="col-md-10">
                      <Link to={`/users/Store/${user.id}`}>
                        {user.userId}({user.name})
                      </Link>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
        </div>
        <ProductList history={history} match={match} location={location} />

        <div key="bottomButton" className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link to={`/stores${location.search}`} className="btn btn-white">
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right">
            <Link
              className=" btn btn-white text-green "
              to={`/stores/${id}/user/${UserId}/qrcode${location.search}`}
            >
              QRCode
            </Link>
            <Link
              className=" btn btn-white text-green "
              to={`/stores/${id}/edit${location.search}`}
            >
              수정
            </Link>
          </div>
        </div>
      </div>
    </>
  );
});
