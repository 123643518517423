import React from "react";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_DONG_LIST_QUERY } from "../Queries";

const DeliveryStoreDongSelect = ({ onChange, value }) => {
  const skip = 0;
  const take = 100;

  const { data, error, loading } = useQuery(
    ADMIN_DELIVERY_STORE_DONG_LIST_QUERY,
    { variables: { skip, take } }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <select className="form-control" onChange={onChange} defaultValue={value}>
      <option value={""}>전체동</option>
      {data.AdminDeliveryStoreDongList.deliveryStoreDongs.map((item, index) => {
        return (
          <option value={item.id} key={index}>
            {item.sido} {item.sigungu} {item.upmyundong} {item.ri}
          </option>
        );
      })}
    </select>
  );
};

export default DeliveryStoreDongSelect;
