import { forwardRef } from "react"
import "../../assets/css/common/print.css"
import ReactMoment from "react-moment"
import { dayOfWeekShortName } from "../User/UserNames"

const ProductPrint = forwardRef(({ startDate, endDate, data }, ref) => {
  const start = new Date(startDate)
  const startDayOfWeek = start.getDay()

  const end = new Date(endDate)
  const endDayOfWeek = end.getDay()

  return (
    <div ref={ref} className="page">
      <div className="print-page not">
        <div>
          <div className="print-page-body">
            <div className="box">
              기간 :<ReactMoment format="YYYY-MM-DD">{startDate}</ReactMoment>(
              {dayOfWeekShortName(startDayOfWeek)}) ~
              <ReactMoment format="YYYY-MM-DD">{endDate}</ReactMoment>(
              {dayOfWeekShortName(endDayOfWeek)})
            </div>
          </div>
          <div className="print-page-footer">
            <ul className="order-product-ul">
              {data.StoreDeliveryOrderProduct.map((item, index) => {
                const option =
                  (item.deliveryProductOptionDetail1_name ||
                    item.deliveryProductOptionDetail2_name ||
                    item.deliveryProductOptionDetail3_name) &&
                  `${
                    item.deliveryProductOptionDetail1_name &&
                    `${item.deliveryProductOptionDetail1_name}`
                  }
                    ${
                      item.deliveryProductOptionDetail2_name &&
                      `/${item.deliveryProductOptionDetail2_name}`
                    }
                    ${
                      item.deliveryProductOptionDetail3_name &&
                      `/${item.deliveryProductOptionDetail3_name}`
                    }`

                const productString = `${item.deliveryProduct_name}${
                  option ? `[${option}]` : ""
                }`

                return (
                  <li key={index}>
                    <div>
                      <div className="first">{productString}</div>
                      <div className="second">{item.sum}개</div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
})

export default ProductPrint
