import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { USER_LIST_QUERY, TRUSTLINE_EDIT_COMMONSTATUS_QUERY } from "../Queries";
import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, userData }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  const role = match.params.role;
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" + (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(USER_LIST_QUERY, {
    variables: {
      role,
      keyword,
      skip,
      first,
    },
  });
  const [trustlineEditCommonStatusMutation] = useMutation(
    TRUSTLINE_EDIT_COMMONSTATUS_QUERY
  );

  const handleSearch = async (e) => {
    e.preventDefault();
    history.push(`/users/${match.params.role}${baseUrl}page=1`);
  };

  const handleApproval = async (e, item) => {
    e.preventDefault();

    try {
      await trustlineEditCommonStatusMutation({
        variables: {
          id: item.id,
        },
      });

      refetch();
      // history.replace(`/exchangeRequests${baseUrl}page=${page}`);
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      userData={userData}
      page={page}
      keyword={keyword}
      keywordInput={keywordInput}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
      handleSearch={handleSearch}
      handleApproval={handleApproval}
    ></Presenter>
  );
});
