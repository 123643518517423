import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  GIFT_ORDERPRODUCT_DETAIL_QUERY,
  GIFT_ORDERPRODUCT_EXTENSION_QUERY,
  GIFT_ORDERPRODUCT_REFUND_QUERY,
  GIFT_ORDERPRODUCT_USED_QUERY,
} from "../Queries";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";

import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";

export default withRouter(({ history, location, match }) => {
  const id = match.params.id;

  const { data, loading } = useQuery(GIFT_ORDERPRODUCT_DETAIL_QUERY, {
    variables: { id },
  });

  const [orderProductExtensionMutation] = useMutation(
    GIFT_ORDERPRODUCT_EXTENSION_QUERY,
    {
      refetchQueries: () => [
        {
          query: GIFT_ORDERPRODUCT_DETAIL_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );
  const [orderProductRefundMutation] = useMutation(
    GIFT_ORDERPRODUCT_REFUND_QUERY,
    {
      refetchQueries: () => [
        {
          query: GIFT_ORDERPRODUCT_DETAIL_QUERY,
          variables: {
            id,
          },
        },
      ],
    }
  );
  const [orderProductUsedMutation] = useMutation(GIFT_ORDERPRODUCT_USED_QUERY, {
    refetchQueries: () => [
      {
        query: GIFT_ORDERPRODUCT_DETAIL_QUERY,
        variables: {
          id,
        },
      },
    ],
  });
  const onExtension = async (id) => {
    console.log("onExtension");
    if (!window.confirm("선물의 유효기간을 연장하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminGiftOrderProductExtension },
      } = await orderProductExtensionMutation({
        variables: { id: id },
      });

      if (AdminGiftOrderProductExtension) {
        //   history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onRefund = async (id) => {
    console.log("onRefund");
    if (!window.confirm("선물을 환불하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminGiftOrderProductRefund },
      } = await orderProductRefundMutation({
        variables: { id },
      });

      if (AdminGiftOrderProductRefund) {
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onUsed = async (id) => {
    console.log("onRefund");
    if (!window.confirm("선물을 사용하시겠습니까?")) {
      return;
    }

    try {
      const {
        data: { AdminGiftOrderProductUsed },
      } = await orderProductUsedMutation({
        variables: { id },
      });

      if (AdminGiftOrderProductUsed) {
        // history.replace(`/account/completeOrderProducts`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    const {
      AdminGiftOrderProductDetail: {
        id,
        commonStatus,
        user,
        sender,
        giftOrder,
        giftProduct,

        // isDiscount,
        discountPrice,
        price,
        giftProductOptionDetail1,
        // giftProductOptionDetailPrice1,
        giftProductOptionDetail2,
        // giftProductOptionDetailPrice2,
        giftProductOptionDetail3,
        // giftProductOptionDetailPrice3,
        giftProductOptionDetail4,
        // giftProductOptionDetailPrice4,
        giftProductOptionDetail5,
        // giftProductOptionDetailPrice5,

        // totalPrice,

        // startDate,
        endDate,
        extensionCount,

        isRefund,
        // meRefund,

        createdAt,
        // updatedAt,
      },
    } = data;

    const CoverImage =
      giftProduct.coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${giftProduct.coverImage}`;

    let OptionString = "";
    let OptionPrice = 0;
    if (giftProductOptionDetail1) {
      OptionString += ` ${
        giftProductOptionDetail1.name
      }(+${giftProductOptionDetail1.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += giftProductOptionDetail1.price;
      if (giftProductOptionDetail2) {
        OptionString += ` / `;
      }
    }
    if (giftProductOptionDetail2) {
      OptionString += ` ${
        giftProductOptionDetail2.name
      }(+${giftProductOptionDetail2.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += giftProductOptionDetail2.price;
      if (giftProductOptionDetail3) {
        OptionString += ` / `;
      }
    }
    if (giftProductOptionDetail3) {
      OptionString += ` ${
        giftProductOptionDetail3.name
      }(+${giftProductOptionDetail3.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += giftProductOptionDetail3.price;
      if (giftProductOptionDetail4) {
        OptionString += ` / `;
      }
    }
    if (giftProductOptionDetail4) {
      OptionString += ` ${
        giftProductOptionDetail4.name
      }(+${giftProductOptionDetail4.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += giftProductOptionDetail4.price;
      if (giftProductOptionDetail5) {
        OptionString += ` / `;
      }
    }
    if (giftProductOptionDetail5) {
      OptionString += ` ${
        giftProductOptionDetail5.name
      }(+${giftProductOptionDetail5.price.toLocaleString("ko-KR", {
        maximumFractionDigits: 4,
      })}드림)`;
      OptionPrice += giftProductOptionDetail5.price;
    }

    const toDay = new Date();
    const endDateObj = new Date(endDate);
    const gap = endDateObj.getTime() - toDay.getTime();
    const dDay = Math.ceil(gap / (1000 * 60 * 60 * 24));
    let refundAbleDate = new Date(endDate);
    refundAbleDate.setDate(refundAbleDate.getDate() + 90);
    let extensionAbleDate = new Date(endDate);
    extensionAbleDate.setDate(extensionAbleDate.getDate() - 31);

    return (
      <>
        <Helmet>
          <title>사용내역 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림상점</li>
            <li className="breadcrumb-item active">사용내역</li>
          </ol>
          <h1 className="page-header">사용내역</h1>
          <div className="row m-b-15">
            <div className="col-md-6 text-left">
              <Link
                to={`/giftOrderProducts${location.search}`}
                className="btn btn-white"
              >
                <i className="fa fa-list fa-fw"></i> 목록
              </Link>
            </div>
            <div className="col-md-6 text-right">
              <Link
                to={`/giftOrders/${giftOrder.id}`}
                className="btn btn-white text-blue"
              >
                상세구매내역
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader noButton={true}>선물정보</PanelHeader>
                <PanelBody className="p-0">
                  <div className="invoice order-product">
                    <div className="invoice-company text-inverse f-w-600">
                      <span className="pull-right hidden-print">
                        <Link
                          className="btn btn-sm btn-white text-primary"
                          to={`/stores/${giftProduct.store.id}`}
                        >
                          <i className="fa fa-store-alt fa-fw" /> 가맹점 정보
                        </Link>
                      </span>
                      {giftProduct.store.name}
                    </div>
                    <div className="order-product-list">
                      <div className="img">
                        <img src={CoverImage} alt={giftProduct.name} />
                        <span className="day badge bg-yellow text-black">
                          {dDay}일
                        </span>
                      </div>
                      <div className="cont">
                        <strong>
                          <Link to={`/stores/${giftProduct.store.id}`}>
                            {giftProduct.name}
                          </Link>
                        </strong>
                        {OptionString !== "" && (
                          <div className="option">
                            추가옵션 : {OptionString}
                          </div>
                        )}
                        <div className="price">
                          <NumberFormat
                            displayType={"text"}
                            value={price}
                            thousandSeparator={true}
                            suffix={"드림"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="invoice-header">
                      <div className="invoice-from p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">수신정보</strong>
                          <ul className="common-status">
                            <li>
                              구 매 자 :{" "}
                              <Link
                                to={`/users/${giftOrder.user.role}/${giftOrder.user.id}`}
                              >
                                {giftOrder.user.name}({giftOrder.user.userId})
                              </Link>
                            </li>
                            <li>
                              발 신 자 :{" "}
                              <Link to={`/users/${sender.role}/${sender.id}`}>
                                {sender.name}({sender.userId})
                              </Link>
                            </li>
                            <li>
                              수 신 자 :{" "}
                              <Link to={`/users/${user.role}/${user.id}`}>
                                {user.name}({user.userId})
                              </Link>
                            </li>
                            <li>
                              수신일자 :
                              <ReactMoment format="YYYY-MM-DD">
                                {createdAt}
                              </ReactMoment>
                            </li>
                            <li>
                              메 시 지 :{" "}
                              {giftOrder.message ? giftOrder.message : "없음"}
                            </li>
                            <li className="column"></li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-to p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="text-inverse">사용정보</strong>
                          <ul className="common-status">
                            <li>
                              상 태 :{" "}
                              {commonStatus === "S" && (
                                <>
                                  {toDay <= endDateObj ? (
                                    <span className="gray">사용가능</span>
                                  ) : (
                                    <span className="gray">기간만료</span>
                                  )}
                                </>
                              )}
                              {commonStatus === "C" && (
                                <>
                                  {isRefund === true && (
                                    <span className="danger">환불완료</span>
                                  )}
                                  {isRefund === false && (
                                    <span className="primary">사용완료</span>
                                  )}
                                </>
                              )}
                            </li>
                            <li>
                              주문번호 :{" "}
                              <ReactMoment format="YYMMDD-HHmmssSSS">
                                {createdAt}
                              </ReactMoment>
                            </li>
                            <li>
                              유효기간(연장) :{" "}
                              <ReactMoment format="YYYY-MM-DD">
                                {endDate}
                              </ReactMoment>{" "}
                              ({extensionCount}회)
                            </li>
                          </ul>
                        </address>
                      </div>
                      <div className="invoice-to p-10">
                        <address className="m-t-5 m-b-5">
                          <strong className="date text-inverse m-t-5">
                            기능
                          </strong>
                          <ul className="common-status-btn">
                            <li>
                              {commonStatus === "S" &&
                              extensionCount < 2 &&
                              toDay <= endDateObj &&
                              toDay >= extensionAbleDate ? (
                                <button
                                  type="button"
                                  className="btn btn-white text-success"
                                  onClick={() => onExtension(id)}
                                >
                                  <i className="far fa-clock"></i>
                                  연장하기
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-white text-success disabled"
                                >
                                  <i className="far fa-clock"></i>
                                  연장하기
                                </button>
                              )}
                            </li>
                            <li>
                              {commonStatus === "S" &&
                              extensionCount >= 1 &&
                              extensionCount <= 2 &&
                              toDay <= endDateObj ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-white text-danger"
                                    onClick={() => onRefund(id)}
                                  >
                                    <i className="fas fa-undo"></i>
                                    환불하기
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-white text-danger disabled"
                                  >
                                    <i className="fas fa-undo"></i>
                                    환불하기
                                  </button>
                                </>
                              )}
                            </li>
                            <li>
                              {commonStatus === "S" && toDay <= endDateObj ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-white text-primary"
                                    onClick={() => onUsed(id)}
                                  >
                                    <i className="fas fa-cash-register"></i>
                                    사용하기
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-white text-primary disabled"
                                  >
                                    <i className="fas fa-cash-register"></i>
                                    사용하기
                                  </button>
                                </>
                              )}
                            </li>
                            <li>
                              {commonStatus === "S" &&
                              extensionCount <= 2 &&
                              toDay <= endDateObj ? (
                                <Link
                                  to={`/giftOrderProducts/${id}/send${location.search}`}
                                  className="btn btn-white text-warning"
                                >
                                  <i className="far fa-paper-plane"></i>
                                  보내기
                                </Link>
                              ) : (
                                <Link
                                  to={`/giftOrderProducts/${id}/send${location.search}`}
                                  className="btn btn-white text-warning disabled"
                                >
                                  <i className="far fa-paper-plane"></i>
                                  보내기
                                </Link>
                              )}
                            </li>
                          </ul>
                        </address>
                      </div>
                    </div>
                    {/* 가격정보 */}
                    <div className="invoice-price m-0">
                      <div className="invoice-price-left">
                        <div className="invoice-price-row">
                          <div className="sub-price">
                            <small>정가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={price}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-minus text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>할인가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={discountPrice}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                          <div className="sub-price">
                            <i className="fa fa-plus text-muted"></i>
                          </div>
                          <div className="sub-price">
                            <small>옵션가</small>
                            <span className="text-inverse">
                              <NumberFormat
                                displayType={"text"}
                                value={OptionPrice}
                                thousandSeparator={true}
                                suffix={"드림"}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-price-right">
                        <small>상품가격</small>
                        <span className="f-w-600">
                          <NumberFormat
                            displayType={"text"}
                            value={price - discountPrice + OptionPrice}
                            thousandSeparator={true}
                            suffix={"드림"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Alert color="danger" className="m-b-0">
                    <strong className="f-s-15">※ 주의사항(필독)</strong>
                    <br />* 보 내 기 : 유효기간 이내에 다른 사용에게 양도됩니다.
                    <br /> * 연장히기 : 유효가간 만료 7일 전, 최대 2회까지
                    연장됩니다.
                    <br /> * 환불하기 : 최초 유효기간 90일 후, 최대 2회 연장까지
                    수신자에게 환불됩니다.
                  </Alert>
                </PanelFooter>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-left">
              <Link
                to={`/giftOrderProducts${location.search}`}
                className="btn btn-white"
              >
                <i className="fa fa-list fa-fw"></i> {""}
                목록
              </Link>
            </div>
            <div className="col-md-4 text-center"></div>
            <div className="col-md-4 text-right">
              <Link
                to={`/giftOrders/${giftOrder.id}`}
                className="btn btn-white text-blue"
              >
                상세구매내역
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
