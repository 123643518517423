import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";

import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_ORDER_LIST_QUERY } from "../Queries";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  // const keyword = queryString.keyword ? queryString.keyword : "";
  const deliveryStore_id = queryString.deliveryStore_id
    ? queryString.deliveryStore_id
    : "";
  const startDate = queryString.startDate
    ? queryString.startDate
    : "2023-10-01";
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().add(1, "d").format("YYYY-MM-DD");
  const deliveryDeliver_id = queryString.deliveryDeliver_id
    ? queryString.deliveryDeliver_id
    : "";
  const deliveryBox_id = queryString.deliveryBox_id
    ? queryString.deliveryBox_id
    : "";
  const page = queryString.page ? +queryString.page : 1;
  const take = 15;
  const baseUrl = "/deliveryOrders";

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (deliveryStore_id ? "deliveryStore_id=" + deliveryStore_id + "&" : "") +
      (deliveryDeliver_id
        ? "deliveryDeliver_id=" + deliveryDeliver_id + "&"
        : "") +
      (deliveryBox_id ? "deliveryBox_id=" + deliveryBox_id + "&" : "") +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const { data, loading, error } = useQuery(ADMIN_DELIVERY_ORDER_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take,
      skip: (page - 1) * take,
      // keyword,
      startDate,
      endDate,
      deliveryStore_id,
      deliveryDeliver_id,
      deliveryBox_id,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      // keyword={keyword}
      deliveryStore_id={deliveryStore_id}
      startDate={startDate}
      endDate={endDate}
      deliveryDeliver_id={deliveryDeliver_id}
      deliveryBox_id={deliveryBox_id}
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
    />
  );
});
