import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_LIST_QUERY } from "../Queries";

import qs from "qs";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const deliveryStoreDong_id = queryString.deliveryStoreDong_id
    ? queryString.deliveryStoreDong_id
    : "";
  const keyword = queryString.keyword ? queryString.keyword : "";
  const page = queryString.page ? +queryString.page : 1;
  const take = 15;
  const baseUrl = "/deliveryStores";

  const { data, error, loading } = useQuery(ADMIN_DELIVERY_STORE_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      deliveryStoreDong_id,
      take,
      skip: (page - 1) * take,
      keyword,
    },
  });

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      deliveryStoreDong_id={deliveryStoreDong_id}
      keyword={keyword}
      page={page}
      take={take}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
