import { graphqlUri } from "../../Config/Env";

const ticketConfig =
  graphqlUri === "http://3.39.203.58:4000"
    ? `ckuql5n28046t08981vb0i661`
    : `ckxdjy7q3s8k80814a5zzeohc`;

const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1;

export const AdministratorMenu = [
  {
    path: ["/dashboard"],
    icon: "fa fa-th",
    title: "메인",
  },
  {
    path: ["/banners", "/banners/write", "/banners/:id", "/banners/:id/edit"],
    icon: "fas fa-cog",
    title: "설정",
    children: [
      {
        path: [
          "/banners",
          "/banners/write",
          "/banners/:id",
          "/banners/:id/edit",
        ],
        title: "배너",
      },
    ],
  },
  {
    path: [
      "/users/:role",
      "/users/:role/:id",
      "/withdrawals",
      "/withdrawals/:id",
    ],
    icon: "far fa-user",
    title: "사용자",
    children: [
      {
        path: ["/users/User", "/users/User/:id"],
        title: "사용자",
      },
      {
        path: ["/users/Store", "/users/Store/:id"],
        title: "가맹점",
      },
      {
        path: ["/users/Issuer", "/users/Issuer/:id"],
        title: "발행인(마을화폐)",
      },
      {
        path: ["/users/Administrator", "/users/Administrator/:id"],
        title: "운영자",
      },
      {
        path: ["/withdrawals", "/withdrawals/:id"],
        title: "탈퇴자",
      },
    ],
  },
  {
    path: [
      "/stores",
      "/stores/categories",
      "/stores/categories/:storeCategories",
      "/discounts",
      "/discounts/write",
      "/discounts/:id",
      "/discounts/:id/edit",
    ],
    icon: "fa fa-store-alt",
    title: "가맹점",
    children: [
      {
        path: [
          "/stores",
          "/stores/categories",
          "/stores/categories/:storeCategories",
        ],
        title: "가맹점",
      },
      {
        path: [
          "/discounts",
          "/discounts/write",
          "/discounts/:id",
          "/discounts/:id/edit",
        ],
        title: "오늘의 할인",
      },
    ],
  },
  {
    path: [
      "/chargeRequestConfigs",
      "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests",
      "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests/write",
      "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests/:id",
      "/chargeRequestCMses",
      "/chargeRequestCMses/write",
      "/chargeRequestCMses/:id",
      "/chargeRequestGRUs",
      "/chargeRequestGRUs/write",
      "/chargeRequestGRUs/:id",
    ],
    icon: "far fa-money-bill-alt",
    title: "충전내역",
    children: [
      {
        path: [
          "/chargeRequestConfigs",
          "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests",
          "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests/write",
          "/chargeRequestConfigs/:chargeRequestConfig/chargeRequests/:id",
        ],
        title: "충전내역",
      },
      {
        path: [
          "/chargeRequestCMses",
          "/chargeRequestCMses/write",
          "/chargeRequestCMses/:id",
        ],
        title: "CMS충전내역",
      },
      {
        path: [
          "/chargeRequestGRUs",
          "/chargeRequestGRUs/write",
          "/chargeRequestGRUs/:id",
        ],
        title: "GRU충전내역",
      },
    ],
  },
  {
    path: [
      "/exchangeRequestConfigs",
      "/exchangeRequestConfigs/:exchangeRequestConfig/exchangeRequests",
    ],
    icon: "fas fa-calculator",
    title: "정산내역",
  },
  {
    path: ["/ledgers", "/ledgers/:currency"],
    icon: "fa fa-receipt",
    title: "거래내역",
    children: [
      {
        path: ["/ledgers/DRM"],
        title: "드림",
      },
      {
        path: ["/ledgers/DNT"],
        title: "드림P",
      },
      {
        path: ["/ledgers/GJP"],
        title: "관저페이",
      },
      {
        path: ["/ledgers/TOL"],
        title: "톨페이",
      },
      {
        path: ["/ledgers/GRU"],
        title: "그루",
      },
      {
        path: ["/ledgers/STR"],
        title: "별",
      },
      {
        path: ["/ledgers/NDO"],
        title: "내동",
      },
      {
        path: ["/ledgers/BIT"],
        title: "조각",
      },
    ],
  },
  {
    path: ["/storeOrders"],
    icon: "fa fa-receipt",
    title: "상점결제내역",
  },

  {
    path: [
      "/deliveryStores",
      "/deliveryStores/write",
      "/deliveryStores/:id/edit",

      "/deliveryPlaces",
      "/deliveryPlaces/write",
      "/deliveryPlaces/:id/edit",

      "/deliveryProducts",
      "/deliveryProducts/write",
      "/deliveryProducts/:id/edit",

      "/deliverySets",
      "/deliverySets/write",
      "/deliverySets/:id/edit",

      "/deliveryBoxes",
      "/deliveryBoxes/:id",
      "/deliveryBoxesDayOfWeek",

      "/deliveryOrders",
      "/deliveryOrders/:id",

      "/deliveryDelivers",
      "/deliveryDelivers/:id",

      "/deliveryBanners",
      "/deliveryBanners/write",
      "/deliveryBanners/:id/edit",

      "/deliveryStatistics/month/:year/:month",
      "/deliveryStatistics/product/:year/:month",
    ],
    icon: "fa fa-box",
    title: "로컬푸드 구독",
    children: [
      {
        path: [
          "/deliveryStores",
          "/deliveryStores/write",
          "/deliveryStores/:id/edit",
        ],
        title: "매장",
      },
      {
        path: [
          "/deliveryPlaces",
          "/deliveryPlaces/write",
          "/deliveryPlaces/:id/edit",
        ],
        title: "픽업장소",
      },
      {
        path: [
          "/deliveryProducts",
          "/deliveryProducts/write",
          "/deliveryProducts/:id/edit",

          "/deliverySets",
          "/deliverySets/write",
          "/deliverySets/:id/edit",
        ],
        title: "상품",
        children: [
          {
            path: [
              "/deliveryProducts",
              "/deliveryProducts/write",
              "/deliveryProducts/:id/edit",
            ],
            title: "상품",
          },
          {
            path: [
              "/deliverySets",
              "/deliverySets/write",
              "/deliverySets/:id/edit",
            ],
            title: "묶음",
          },
        ],
      },
      {
        path: [
          "/deliveryBoxes",
          "/deliveryBoxes/:id",
          "/deliveryBoxesDayOfWeek",
        ],
        title: "주문정보",
        children: [
          {
            path: ["/deliveryBoxes", "/deliveryBoxes/:id"],
            title: "주문정보",
          },
          {
            path: ["/deliveryBoxesDayOfWeek"],
            title: "요일별 주문현황",
          },
        ],
      },
      {
        path: ["/deliveryOrders", "/deliveryOrders/:id"],
        title: "배송",
      },
      {
        path: ["/deliveryDelivers", "/deliveryDelivers/:id"],
        title: "기사",
      },
      {
        path: [
          "/deliveryBanners",
          "/deliveryBanners/write",
          "/deliveryBanners/:id/edit",
        ],
        title: "배너",
      },
      {
        path: [
          "/deliveryStatistics/month/:year/:month",
          "/deliveryStatistics/product/:year/:month",
        ],
        title: "통계",
        children: [
          {
            path: [
              `/deliveryStatistics/month/${year}/${month}`,
              "/deliveryStatistics/month/:year/:month",
            ],
            title: "월별",
          },
          {
            path: [
              `/deliveryStatistics/product/${year}/${month}`,
              "/deliveryStatistics/product/:year/:month",
            ],
            title: "상품별",
          },
        ],
      },
    ],
  },

  {
    path: [
      "/couponConfigs",
      "/couponConfigs/:couponConfig/coupons",
      "/couponConfigs/:couponConfig/coupons/write",
      "/couponConfigs/:couponConfig/coupons/:id",
      "/couponConfigs/:couponConfig/coupons/:id/edit",
    ],
    icon: "fa fa-ticket-alt",
    title: "쿠폰",
  },
  {
    path: [
      "/giftConfigs",
      "/giftConfigs/:config/categories",
      "/giftConfigs/:config/categories/:category",
      "/giftConfigs/:config/products",
      "/giftConfigs/:config/products/write",
      "/giftConfigs/:config/products/:id",
      "/giftConfigs/:config/products/:id/edit",

      "/giftOrders",
      "/giftOrders/:id",

      "/giftOrderProducts",
      "/giftOrderProducts/:id",

      "/giftExchanges",
      "/giftExchanges/year/:year/month/:month",
      "/giftExchanges/year/:year/month/:month/:id",

      "/giftProductReviews",
      "/giftProductReviews/:id",

      "/giftProductInquiries",
      "/giftProductInquiries/:id",

      "/giftBanners",
      "/giftBanners/write",
      "/giftBanners/:id",
      "/giftBanners/:id/edit",

      "/giftStatistics",
      "/giftStatistics/year/:year",
      "/giftStatistics/year/:year/month/:month",
      "/giftStatistics/year/:year/month/:month/day:day",
    ],
    icon: "fas fa-gift",
    title: "드림상점",
    children: [
      {
        path: [
          "/giftConfigs",
          "/giftConfigs/:config/categories",
          "/giftConfigs/:config/categories/:category",
          "/giftConfigs/:config/products",
          "/giftConfigs/:config/products/write",
          "/giftConfigs/:config/products/:id",
          "/giftConfigs/:config/products/:id/edit",
        ],
        title: "상품",
      },
      {
        path: ["/giftOrders", "/giftOrders:id"],
        title: "주문내역",
      },
      {
        path: ["/giftOrderProducts", "/giftOrderProducts:id"],
        title: "상품내역",
      },
      {
        path: [
          "/giftExchanges",
          "/giftExchanges/year/:year/month/:month",
          "/giftExchanges/year/:year/month/:month/:id",
        ],
        title: "정산내역",
      },
      {
        path: ["/giftProductReviews", "/giftProductReviews:id"],
        title: "후기",
      },
      {
        path: [
          "/giftProductInquiries",
          "/giftProductInquiries/:id",
          "/giftProductInquiries/:id/reply",
        ],
        title: "문의",
      },
      {
        path: [
          "/giftBanners",
          "/giftBanners/write",
          "/giftBanners/:id",
          "/giftBanners/:id/edit",
        ],
        title: "배너",
      },
      {
        path: [
          "/giftStatistics",
          "/giftStatistics/year/:year",
          "/giftStatistics/year/:year/month/:month",
          "/giftStatistics/year/:year/month/:month/day:day",
        ],
        title: "통계",
      },
    ],
  },
  {
    path: [
      `/ticketConfigs`,
      `/ticketConfigs/${ticketConfig}/categories`,
      `/ticketConfigs/${ticketConfig}/categories/:category`,
      `/ticketConfigs/${ticketConfig}/products/:commonStatus`,
      `/ticketConfigs/${ticketConfig}/products/:commonStatus/write`,
      `/ticketConfigs/${ticketConfig}/products/:commonStatus/:id`,
      `/ticketConfigs/${ticketConfig}/products/:commonStatus/:id/edit`,

      "/ticketOrders",
      "/ticketOrders/:id",

      "/ticketOrderProducts",
      "/ticketOrderProducts/:id",

      "/ticketExchanges",
      "/ticketExchanges/year/:year/month/:month",
      "/ticketExchanges/year/:year/month/:month/:id",

      "/ticketProductReviews",
      "/ticketProductReviews/:id",

      "/ticketProductInquiries",
      "/ticketProductInquiries/:id",

      "/ticketBanners",
      "/ticketBanners/write",
      "/ticketBanners/:id",
      "/ticketBanners/:id/edit",

      "/ticketStatistics",
      "/ticketStatistics/year/:year",
      "/ticketStatistics/year/:year/month/:month",
      "/ticketStatistics/year/:year/month/:month/day:day",
    ],
    icon: "fas fa-ticket-alt",
    title: "드림티켓",
    children: [
      {
        path: [
          `/ticketConfigs`,
          `/ticketConfigs/${ticketConfig}/categories`,
          `/ticketConfigs/${ticketConfig}/categories/:category`,
          `/ticketConfigs/${ticketConfig}/products/:commonStatus`,
          `/ticketConfigs/${ticketConfig}/products/:commonStatus/write`,
          `/ticketConfigs/${ticketConfig}/products/:commonStatus/:id`,
          `/ticketConfigs/${ticketConfig}/products/:commonStatus/:id/edit`,
        ],
        title: "티켓",
        children: [
          {
            path: [
              `/ticketConfigs/${ticketConfig}/products/standby`,
              `/ticketConfigs/${ticketConfig}/products/standby/write`,
              `/ticketConfigs/${ticketConfig}/products/standby/:id`,
              `/ticketConfigs/${ticketConfig}/products/standby/:id/edit`,
            ],
            title: "대기",
          },
          {
            path: [
              `/ticketConfigs/${ticketConfig}/products/completion`,
              `/ticketConfigs/${ticketConfig}/products/completion/write`,
              `/ticketConfigs/${ticketConfig}/products/completion/:id`,
              `/ticketConfigs/${ticketConfig}/products/completion/:id/edit`,
            ],
            title: "승인",
          },
          {
            path: [
              `/ticketConfigs/${ticketConfig}/products/expiration`,
              `/ticketConfigs/${ticketConfig}/products/expiration/write`,
              `/ticketConfigs/${ticketConfig}/products/expiration/:id`,
              `/ticketConfigs/${ticketConfig}/products/expiration/:id/edit`,
            ],
            title: "만료",
          },
        ],
      },
      {
        path: ["/ticketOrders", "/ticketOrders:id"],
        title: "구매내역",
      },
      {
        path: ["/ticketOrderProducts", "/ticketOrderProducts:id"],
        title: "예매내역",
      },
      {
        path: [
          "/ticketExchanges",
          "/ticketExchanges/year/:year/month/:month",
          "/ticketExchanges/year/:year/month/:month/:id",
        ],
        title: "정산내역",
      },
      {
        path: ["/ticketProductReviews", "/ticketProductReviews:id"],
        title: "후기",
      },
      {
        path: [
          "/ticketProductInquiries",
          "/ticketProductInquiries/:id",
          "/ticketProductInquiries/:id/reply",
        ],
        title: "문의",
      },
      {
        path: [
          "/ticketBanners",
          "/ticketBanners/write",
          "/ticketBanners/:id",
          "/ticketBanners/:id/edit",
        ],
        title: "배너",
      },
      {
        path: [
          "/ticketStatistics",
          "/ticketStatistics/year/:year",
          "/ticketStatistics/year/:year/month/:month",
          "/ticketStatistics/year/:year/month/:month/day:day",
        ],
        title: "통계",
      },
    ],
  },

  {
    path: [
      "/noticeConfigs",
      "/noticeConfigs/:noticeConfig/notices",
      "/noticeConfigs/:noticeConfig/notices/write",
      "/noticeConfigs/:noticeConfig/notices/:id",

      "/eventConfigs",
      "/eventConfigs/:eventConfig/events",
      "/eventConfigs/:eventConfig/events/write",
      "/eventConfigs/:eventConfig/events/:id",
      "/eventConfigs/:eventConfig/events/:id/edit",

      "/helpConfigs",
      "/helpConfigs/:helpConfig/helps",
      "/helpConfigs/:helpConfig/helps/write",
      "/helpConfigs/:helpConfig/helps/:id",
      "/helpConfigs/:helpConfig/helps/:id/edit",

      "/inquiryConfigs",
      "/inquiryConfigs/:inquiryConfig/inquirys",
      "/inquiryConfigs/:inquiryConfig/inquirys/:id",
      "/inquiryConfigs/:inquiryConfig/inquirys/:id/reply",
      "/inquiryConfigs/:inquiryConfig/inquirys/:id/edit",
    ],
    icon: "fas fa-info-circle",
    title: "고객센터",
    children: [
      {
        path: [
          "/noticeConfigs",
          "/noticeConfigs/:noticeConfig/notices",
          "/noticeConfigs/:noticeConfig/notices/:id",
          "/noticeConfigs/:noticeConfig/notices/:id/write",
        ],
        title: "공지사항",
        children: [
          {
            path: [
              "/noticeConfigs/Wallet",
              "/noticeConfigs/Wallet/:noticeConfig/notices",
              "/noticeConfigs/Wallet/:noticeConfig/notices/:id",
            ],
            title: "사용자",
          },
          {
            path: [
              "/noticeConfigs/POS",
              "/noticeConfigs/POS/:noticeConfig/notices",
              "/noticeConfigs/POS/:noticeConfig/notices/:id",
            ],
            title: "가맹점",
          },
          {
            path: [
              "/noticeConfigs/Gift",
              "/noticeConfigs/Gift/:noticeConfig/notices",
              "/noticeConfigs/Gift/:noticeConfig/notices/:id",
            ],
            title: "드림상점",
          },
          {
            path: [
              "/noticeConfigs/Ticket",
              "/noticeConfigs/Ticket/:noticeConfig/notices",
              "/noticeConfigs/Ticket/:noticeConfig/notices/:id",
            ],
            title: "드림티켓",
          },
        ],
      },
      {
        path: [
          "/eventConfigs",
          "/eventConfigs/:eventConfig/events",
          "/eventConfigs/:eventConfig/events/:id",
          "/eventConfigs/:eventConfig/events/:id/write",
          "/eventConfigs/:eventConfig/events/:id/edit",
        ],
        title: "이벤트",
        children: [
          {
            path: [
              "/eventConfigs/Wallet",
              "/eventConfigs/Wallet/:eventConfig/events",
              "/eventConfigs/Wallet/:eventConfig/events/:id",
            ],
            title: "사용자",
          },
          {
            path: [
              "/eventConfigs/POS",
              "/eventConfigs/POS/:eventConfig/events",
              "/eventConfigs/POS/:eventConfig/events/:id",
            ],
            title: "가맹점",
          },
        ],
      },
      {
        path: [
          "/helpConfigs",
          "/helpConfigs/:helpConfig/helps",
          "/helpConfigs/:helpConfig/helps/write",
          "/helpConfigs/:helpConfig/helps/:id",
          "/helpConfigs/:helpConfig/helps/:id/edit",
        ],
        title: "도움말",
        children: [
          {
            path: [
              "/helpConfigs/Wallet",
              "/helpConfigs/Wallet/:helpConfig/helps",
              "/helpConfigs/Wallet/:helpConfig/helps/:id",
              "/helpConfigs/Wallet/:helpConfig/categories",
              "/helpConfigs/Wallet/:helpConfig/categories/:helpCategories",
            ],
            title: "사용자",
          },
          {
            path: [
              "/helpConfigs/POS",
              "/helpConfigs/POS/:helpConfig/helps",
              "/helpConfigs/POS/:helpConfig/helps/:id",
              "/helpConfigs/POS/:helpConfig/categories",
              "/helpConfigs/POS/:helpConfig/categories/:helpCategories",
            ],
            title: "가맹점",
          },
        ],
      },
      {
        path: [
          "/inquiryConfigs",
          "/inquiryConfigs/:inquiryConfig/inquirys",
          "/inquiryConfigs/:inquiryConfig/inquirys/:id",
          "/inquiryConfigs/:inquiryConfig/inquirys/:id/reply",
          "/inquiryConfigs/:inquiryConfig/inquirys/:id/edit",
        ],
        title: "1:1문의",
        children: [
          {
            path: [
              "/inquiryConfigs/Wallet",
              "/inquiryConfigs/Wallet/:inquiryConfig/inquirys",
              "/inquiryConfigs/Wallet/:inquiryConfig/inquirys/:id",
              "/inquiryConfigs/Wallet/:inquiryConfig/categories",
              "/inquiryConfigs/Wallet/:inquiryConfig/categories/:inquiryCategories",
            ],
            title: "사용자",
          },
          {
            path: [
              "/inquiryConfigs/POS",
              "/inquiryConfigs/POS/:inquiryConfig/inquirys",
              "/inquiryConfigs/POS/:inquiryConfig/inquirys/:id",
              "/inquiryConfigs/POS/:inquiryConfig/categories",
              "/inquiryConfigs/POS/:inquiryConfig/categories/:inquiryCategories",
            ],
            title: "가맹점",
          },
        ],
      },
    ],
  },
  {
    path: ["/donations", "/donations/write", "/donations/:id/edit"],
    icon: "far fa-heart",
    title: "기부",
  },
  {
    path: ["/fees", "/fees/write", "/fees/:id/edit"],
    icon: "far fa-calendar-check",
    title: "회비",
  },
  {
    path: ["/pushes"],
    icon: "fa fa-bell",
    title: "알림",
  },
  {
    path: [
      "/StatisticsMain",
      "/statisticsDNT",
      "/statisticsUser",
      "/statisticsStore",
      "/statisticsGJP",
      "/statisticsTOL",
      "/statisticsGRU",
      "/statisticsSTR",
      "/statisticsNDO",
      "/statistics",
    ],
    icon: "fas fa-chart-bar",
    title: "통계",
    children: [
      // {
      //   path: ["/StatisticsMain"],
      //   title: "전체",
      // },
      {
        path: ["/statisticsUser", "/statisticsStore"],
        title: "드림",
        children: [
          {
            path: ["/statisticsUser"],
            title: "사용자",
          },
          {
            path: ["/statisticsStore"],
            title: "가맹점",
          },
        ],
      },
      {
        path: ["/statisticsDNT"],
        title: "드림P",
      },
      {
        path: ["/statisticsGJP"],
        title: "관저페이",
      },
      {
        path: ["/statisticsTOL"],
        title: "톨",
      },
      {
        path: ["/statisticsGRU"],
        title: "그루",
      },
      {
        path: ["/statisticsSTR"],
        title: "별",
      },
      {
        path: ["/statisticsNDO"],
        title: "내동",
      },
      {
        path: ["/statistics"],
        title: "종합",
      },
    ],
  },
];
export const IssuerMenu = [
  {
    path: ["/dashboard"],
    icon: "fa fa-th",
    title: "메인",
  },
  {
    path: [
      "/users/:role",
      "/users/:role/:id",
      "/withdrawals",
      "/withdrawals/:id",
    ],
    icon: "far fa-user",
    title: "사용자",
    children: [
      {
        path: ["/users/User", "/users/User/:id"],
        title: "사용자",
      },
      {
        path: ["/users/Store", "/users/Store/:id"],
        title: "가맹점",
      },
    ],
  },
  {
    path: ["/ledgers"],
    icon: "fa fa-receipt",
    title: "거래내역",
  },
  {
    path: ["/statistics"],
    icon: "fas fa-chart-bar",
    title: "통계",
  },
];
export const StoreMenu = [
  {
    path: [
      "/delivery/deliveryPlaces",
      "/delivery/deliveryPlaces/write",
      "/delivery/deliveryPlaces/:id/edit",

      "/delivery/deliveryProducts",
      "/delivery/deliveryProducts/write",
      "/delivery/deliveryProducts/:id/edit",

      "/delivery/deliverySets",
      "/delivery/deliverySets/write",
      "/delivery/deliverySets/:id/edit",

      "/delivery/deliveryBoxes",
      "/delivery/deliveryBoxes/:id",
      "/delivery/deliveryBoxesDayOfWeek",

      "/delivery/deliveryOrders",
      "/delivery/deliveryOrders/:id",

      "/delivery/deliveryDelivers",
      "/delivery/deliveryDelivers/:id",

      "/delivery/deliveryStatistics/month/:year/:month",
      "/delivery/deliveryStatistics/product/:year/:month",
    ],
    icon: "fa fa-box",
    title: "로컬푸드 구독",
    children: [
      {
        path: [
          "/delivery/deliveryPlaces",
          "/delivery/deliveryPlaces/write",
          "/delivery/deliveryPlaces/:id/edit",
        ],
        title: "매장",
      },
      {
        path: [
          "/delivery/deliveryProducts",
          "/delivery/deliveryProducts/write",
          "/delivery/deliveryProducts/:id/edit",

          "/delivery/deliverySets",
          "/delivery/deliverySets/write",
          "/delivery/deliverySets/:id/edit",
        ],
        title: "상품",
        children: [
          {
            path: [
              "/delivery/deliveryProducts",
              "/delivery/deliveryProducts/write",
              "/delivery/deliveryProducts/:id/edit",
            ],
            title: "상품",
          },
          {
            path: [
              "/delivery/deliverySets",
              "/delivery/deliverySets/write",
              "/delivery/deliverySets/:id/edit",
            ],
            title: "묶음",
          },
        ],
      },
      {
        path: [
          "/delivery/deliveryBoxes",
          "/delivery/deliveryBoxes/:id",
          "/delivery/deliveryBoxesDayOfWeek",
        ],
        title: "주문정보",
        children: [
          {
            path: ["/delivery/deliveryBoxes", "/delivery/deliveryBoxes/:id"],
            title: "주문정보",
          },
          {
            path: ["/delivery/deliveryBoxesDayOfWeek"],
            title: "요일별 주문현황",
          },
        ],
      },
      {
        path: ["/delivery/deliveryOrders", "/delivery/deliveryOrders/:id"],
        title: "배송",
      },
      {
        path: ["/delivery/deliveryDelivers", "/delivery/deliveryDelivers/:id"],
        title: "기사",
      },
      {
        path: [
          "/delivery/deliveryStatistics/month/:year/:month",
          "/delivery/deliveryStatistics/product/:year/:month",
        ],
        title: "통계",
        children: [
          {
            path: [
              `/delivery/deliveryStatistics/month/${year}/${month}`,
              "/delivery/deliveryStatistics/month/:year/:month",
            ],
            title: "월별",
          },
          {
            path: [
              `/delivery/deliveryStatistics/product/${year}/${month}`,
              "/delivery/deliveryStatistics/product/:year/:month",
            ],
            title: "상품별",
          },
        ],
      },
    ],
  },
  // {
  //   path: [
  //     "/ticket/dashboard",
  //     "/ticket/ticketConfigs",
  //     "/ticket/ticketConfigs/:config/categories",
  //     "/ticket/ticketConfigs/:config/categories/:category",
  //     "/ticket/ticketConfigs/:config/products",
  //     "/ticket/ticketConfigs/:config/products/write",
  //     "/ticket/ticketConfigs/:config/products/:id",
  //     "/ticket/ticketConfigs/:config/products/:id/edit",
  //     "/ticket/ticketOrders",
  //     "/ticket/ticketOrders/:id",
  //     "/ticket/ticketOrderProducts",
  //     "/ticket/ticketOrderProducts/:id",
  //     "/ticket/ticketExchanges",
  //     "/ticket/ticketExchanges/year/:year/month/:month",
  //     "/ticket/ticketExchanges/year/:year/month/:month/:id",
  //     "/ticket/ticketProductReviews",
  //     "/ticket/ticketProductReviews/:id",
  //     "/ticket/ticketProductInquiries",
  //     "/ticket/ticketProductInquiries/:id",
  //     "/ticket/ticketStatistics",
  //     "/ticket/ticketStatistics/year/:year",
  //     "/ticket/ticketStatistics/year/:year/month/:month",
  //     "/ticket/ticketStatistics/year/:year/month/:month/day:day",
  //   ],
  //   icon: "fa fa-ticket-alt",
  //   title: "드림티켓",
  //   children: [
  //     {
  //       path: ["/ticket/dashboard"],
  //       title: "메인",
  //     },
  //     {
  //       path: [
  //         "/ticket/ticketConfigs",
  //         "/ticket/ticketConfigs/:config/categories",
  //         "/ticket/ticketConfigs/:config/categories/:category",
  //         "/ticket/ticketConfigs/:config/products",
  //         "/ticket/ticketConfigs/:config/products/write",
  //         "/ticket/ticketConfigs/:config/products/:id",
  //         "/ticket/ticketConfigs/:config/products/:id/edit",
  //       ],
  //       title: "티켓",
  //     },
  //     {
  //       path: ["/ticket/ticketOrders", "/ticket/ticketOrders/:id"],
  //       title: "구매내역",
  //     },
  //     {
  //       path: [
  //         "/ticket/ticketOrderProducts",
  //         "/ticket/ticketOrderProducts/:id",
  //       ],
  //       title: "예매내역",
  //     },
  //     {
  //       path: [
  //         "/ticket/ticketExchanges",
  //         "/ticket/ticketExchanges/year/:year/month/:month",
  //         "/ticket/ticketExchanges/year/:year/month/:month/:id",
  //       ],
  //       title: "정산내역",
  //     },
  //     {
  //       path: [
  //         "/ticket/ticketProductReviews",
  //         "/ticket/ticketProductReviews/:id",
  //       ],
  //       title: "후기",
  //     },
  //     {
  //       path: [
  //         "/ticket/ticketProductInquiries",
  //         "/ticket/ticketProductInquiries/:id",
  //       ],
  //       title: "문의",
  //     },
  //     {
  //       path: [
  //         "/ticket/ticketStatistics",
  //         "/ticket/ticketStatistics/year/:year",
  //         "/ticket/ticketStatistics/year/:year/month/:month",
  //         "/ticket/ticketStatistics/year/:year/month/:month/day:day",
  //       ],
  //       title: "통계",
  //     },
  //   ],
  // },
];
