import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STATISTICS_STORE_QUERY } from "./Queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faReceipt,
  faMoneyBill,
  faSackDollar,
  faCreditCardBlank,
  faHeart,
  faAddressCard,
} from "@fortawesome/pro-regular-svg-icons";
import qs from "qs";

import PerfectScrollbar from "react-perfect-scrollbar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Moment from "moment";
import NumberFormat from "react-number-format";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import DatePicker from "../../../Containers/SelectedPeriod/DatePicker";
import { pastPeriod } from "../../../Components/User/UserNames";

export default withRouter(({ userData, history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const startDate = queryString.startDate
    ? queryString.startDate
    : Moment().subtract(1, "months").format("YYYY-MM-DD");
  const endDate = queryString.endDate
    ? queryString.endDate
    : Moment().format("YYYY-MM-DD");

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (date ? "startDate=" + date + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (date ? "endDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (startDate ? "startDate=" + startDate + "&" : "") +
      (endDate ? "endDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const DownloadButton = () => {
    const handleDownload = async () => {
      try {
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };

    return (
      <>
        <button
          className="btn btn-white btn-xs btn-block mr-2 text-truncate m-b-5"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i> 다운로드
        </button>
        <CSVLink
          data={csvData}
          filename={`통계_가맹점_현황_${startDate}_${endDate}.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  const { data, loading } = useQuery(STATISTICS_STORE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      startDate,
      endDate,
    },
  });

  useEffect(() => {
    if (!loading) {
      let csv = [["가맹점", "건수", "금액"]];
      data.AdminStatisticsStore.PaymentStores.forEach((item) => {
        csv.push([`${item.name}`, item.count, item.total]);
      });
      setCsvData(csv);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      AdminStatisticsStore: {
        DRMTotal,
        DRMExchangeTotal,
        DRMWithdrawalTotal,
        DRMUserTotal,
        DRMDonationTotal,

        PaymentCount,
        PaymentTotal,
        ExchangeRequestCount,
        ExchangeRequestTotal,
        PaymentStores,
        ExchangeRequestStores,
      },
    } = data;
    return (
      <>
        <Helmet>
          <title>통계 관리 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/statisticsUser">통계</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/statisticsUser">드림</Link>
            </li>
            <li className="breadcrumb-item active">가맹점</li>
          </ol>
          <h1 className="page-header">가맹점</h1>

          <div className="m-b-10 m-t-10 f-s-10">
            <b className="text-inverse f-s-18">전체</b>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DRMTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림
                  </div>
                  <div className="stats-desc f-s-15">총 발행량</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DRMExchangeTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림
                  </div>
                  <div className="stats-desc f-s-15">
                    가맹점 환전금 (관리자)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DRMUserTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림
                  </div>
                  <div className="stats-desc f-s-15">
                    이용자 보유금 (관리자 제외)
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DRMWithdrawalTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림
                  </div>
                  <div className="stats-desc f-s-15">탈퇴자 환전금</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-icon rounded bg-blue-transparent-1 widget-card-cover-icon text-center">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <div className="stats-content">
                  <div className="stats-number text-primary">
                    <NumberFormat
                      value={DRMDonationTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림
                  </div>
                  <div className="stats-desc f-s-15">기부금</div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-xl-12">
              <DatePicker
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                onStartDateSelect={onStartDateSelect}
                onEndDateSelect={onEndDateSelect}
                onPeriodConfig={onPeriodConfig}
                period={pastPeriod}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="m-b-10 m-t-10 f-s-10">
                <div className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15">
                  <DownloadButton stores={PaymentStores} />
                </div>
                <b className="text-inverse f-s-18">결제금액</b>
              </div>

              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%",
                  }}
                >
                  <h3 className="m-b-10">
                    <NumberFormat
                      value={PaymentTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림({PaymentCount}건)
                  </h3>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {PaymentStores.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            {item.name}({item.count}건){" "}
                            <Link
                              className="btn btn-default btn-xs"
                              to={`/statisticsStore/StorePayment?id=${item.id}&startDate=${startDate}&endDate=${endDate}&wallet=${item.wallet}`}
                            >
                              전체
                            </Link>{" "}
                            <Link
                              className="btn btn-default btn-xs"
                              to={`/statisticsStore/StorePaymentHour?id=${item.id}&startDate=${startDate}&endDate=${endDate}&wallet=${item.wallet}`}
                            >
                              시간별
                            </Link>
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.total}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          드림
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="m-b-10 m-t-10 f-s-10">
                <div className="pull-right text-grey-darker m-r-3 f-w-700 f-s-15">
                  <DownloadButton stores={ExchangeRequestStores} />
                </div>
                <b className="text-inverse f-s-18">정산금액</b>
              </div>

              <div className="card border-0 bg-black-transparent-9 text-white mb-3">
                <div
                  className="card-body text-right"
                  style={{
                    background: "no-repeat bottom left",
                    backgroundImage: "url(/assets/img/svg/img-4.svg)",
                    backgroundSize: "auto 60%",
                  }}
                >
                  <h3 className="m-b-10">
                    <NumberFormat
                      value={ExchangeRequestTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    드림({ExchangeRequestCount}건)
                  </h3>
                </div>
                <div className="widget-list widget-list-rounded ">
                  {ExchangeRequestStores.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="widget-list-item rounded-0 p-t-3"
                      >
                        <div className="widget-list-content">
                          <div className="widget-list-title">
                            {item.name}({item.count}건){" "}
                            {/* <Link
                              className="btn btn-default btn-xs"
                              to={`/statistics/StorePayment?id=${item.id}&startDate=${startDate}&endDate=${endDate}&wallet=${item.wallet}`}
                            >
                              전체
                            </Link>{" "}
                            <Link
                              className="btn btn-default btn-xs"
                              to={`/statistics/StorePaymentHour?id=${item.id}&startDate=${startDate}&endDate=${endDate}&wallet=${item.wallet}`}
                            >
                              시간별
                            </Link> */}
                          </div>
                        </div>
                        <div className="widget-list-action text-nowrap text-right">
                          <NumberFormat
                            value={item.total}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                          드림
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
});
