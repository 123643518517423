import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import {
  deliveryOrderStateName,
  deliveryBoxTypeName,
  dayOfWeekShortName,
  deliveryOrderProductStateName,
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
import ReactMoment from "react-moment";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_ORDER_PRODUCT_REFUND_MUTATION,
  STORE_DELIVERY_ORDER_DETAIL_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

const Detail = ({ history, match, location, data, baseUrl }) => {
  const {
    StoreDeliveryOrderDetail: {
      id,
      createdAt,
      updatedAt,

      state,
      deliverAt,
      deliveryOrderProducts,

      price,
      refundPrice,
      totalPrice,

      type,
      zipcode,
      jibunAddress,
      roadAddress,
      addressDetail,
      dayOfWeek,

      deliveryBox,
      deliveryStore,
      deliveryDeliver,
      user,
    },
  } = data;

  const [
    StoreDeliveryOrderProductRefundMutation,
    { loading: StoreDeliveryOrderProductRefundLoading },
  ] = useMutation(STORE_DELIVERY_ORDER_PRODUCT_REFUND_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_ORDER_DETAIL_QUERY,
        variables: { id },
      },
    ],
  });

  const onRefund = async (item) => {
    try {
      if (StoreDeliveryOrderProductRefundLoading) {
        return;
      }

      const {
        data: { StoreDeliveryOrderProductRefund },
      } = await StoreDeliveryOrderProductRefundMutation({
        variables: { id: item.id },
      });

      if (StoreDeliveryOrderProductRefund) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          // if (result.isConfirmed) {
          history.push(`${baseUrl}${location.search}`);
          // }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const productString = deliveryOrderProducts
    .map((product) => {
      const option =
        (product.deliveryProductOptionDetail1 ||
          product.deliveryProductOptionDetail2 ||
          product.deliveryProductOptionDetail3) &&
        `${
          product.deliveryProductOptionDetail1 &&
          `${product.deliveryProductOptionDetail1.name}`
        }
          ${
            product.deliveryProductOptionDetail2 &&
            `/${product.deliveryProductOptionDetail2.name}`
          }
          ${
            product.deliveryProductOptionDetail3 &&
            `/${product.deliveryProductOptionDetail3.name}`
          }`;
      return `${product.deliveryProduct.name}${option ? `(${option})` : ""} ${
        product.count
      }개`;
    })
    .join(", ");

  const date = new Date(deliverAt);
  const deliveryDayOfWeek = date.getDay();

  return (
    <Fragment>
      <div className="row m-b-15">
        <div className="col-xs-6 text-left">
          <Link
            to={`/delivery/deliveryOrders${location.search}`}
            className="btn btn-white"
          >
            목록
          </Link>
        </div>
        <div className="col-xs-6 text-right">
          <Link
            to={`/delivery/deliveryBoxes/${deliveryBox.id}`}
            className="btn btn-white m-l-5"
          >
            구독박스정보
          </Link>
          {deliveryDeliver && (
            <Link
              to={`/delivery/deliveryOrders${location.search}`}
              className="btn btn-white m-l-5"
            >
              배송기사정보
            </Link>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton>상세정보</PanelHeader>
            <PanelBody className="p-0">
              <div className="p-10">
                {state === "Ready" && (
                  <span className="label label-gray f-s-13">
                    {deliveryOrderStateName(state)}
                  </span>
                )}
                {state === "Delivery" && (
                  <span className="label label-primary f-s-13">
                    {deliveryOrderStateName(state)}
                  </span>
                )}
                {state === "Completed" && (
                  <span className="label label-success f-s-13">
                    {deliveryOrderStateName(state)}
                  </span>
                )}
              </div>

              <div className="text-box">
                <div>
                  <strong>배송일 </strong>
                  <ReactMoment format="YYYY-MM-DD">{deliverAt}</ReactMoment>(
                  {dayOfWeekShortName(deliveryDayOfWeek)})
                </div>
              </div>
              <div className="invoice order-product p-t-0 p-b-0 ">
                <div className="invoice-header m-b-0">
                  <div className="invoice-from p-10">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">구매정보</strong>
                      <ul className="common-status">
                        <li>
                          <div className="left">이름</div>
                          <div className="right">
                            {user.name}({user.userId})
                          </div>
                        </li>
                        <li>
                          <div className="left">연락처</div>
                          <div className="right">{user.phone}</div>
                        </li>
                      </ul>
                    </address>
                  </div>
                  <div className="invoice-from p-10">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">배송정보</strong>
                      <ul className="common-status">
                        <li>
                          <div className="left">매장</div>
                          <div className="right">
                            {deliveryStore.store.name} &gt;{" "}
                            {deliveryStore.user.name}
                          </div>
                        </li>
                        <li>
                          <div className="left">타입</div>
                          <div className="right">
                            {deliveryBoxTypeName(type)}
                          </div>
                        </li>
                        <li>
                          <div style={{ width: 100 }}>주소</div>
                          <div className="right">
                            {roadAddress}
                            <br />
                            {addressDetail}
                          </div>
                        </li>
                        <li>
                          <div style={{ width: 100 }}>배송상품</div>
                          <div className="right">{productString}</div>
                        </li>
                      </ul>
                    </address>
                  </div>
                  <div className="invoice-from p-10">
                    <address className="m-t-5 m-b-5">
                      <strong className="text-inverse">배송기사 정보</strong>
                      <ul className="common-status">
                        {deliveryDeliver ? (
                          <Fragment>
                            <li>
                              <div className="left">이름</div>
                              <div className="right">
                                {deliveryDeliver.user.name}(
                                {deliveryDeliver.user.userId})
                              </div>
                            </li>
                            <li>
                              <div className="left">연락처</div>
                              <div className="right">
                                {deliveryDeliver.user.phone}
                              </div>
                            </li>
                          </Fragment>
                        ) : (
                          <li>
                            <div className="left">배송기사</div>
                            <div className="right">미배정</div>
                          </li>
                        )}
                      </ul>
                    </address>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>

      <Alert color="primary" className="m-b-15">
        <strong className="f-s-15">※ 안내</strong>
        <br /> * 결품 발생시 환불을 진행해주세요.
      </Alert>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>배송상품(1건)</PanelHeader>
            {deliveryOrderProducts.length > 0 ? (
              <>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped m-b-0">
                      <colgroup>
                        <col width="*" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>상품명</th>
                          <th>수량</th>
                          <th>금액</th>
                          <th>기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryOrderProducts.map((product, index) => {
                          const option =
                            (product.deliveryProductOptionDetail1 ||
                              product.deliveryProductOptionDetail2 ||
                              product.deliveryProductOptionDetail3) &&
                            `${
                              product.deliveryProductOptionDetail1 &&
                              `${product.deliveryProductOptionDetail1.name}`
                            }
                            ${
                              product.deliveryProductOptionDetail2 &&
                              `/${product.deliveryProductOptionDetail2.name}`
                            }
                            ${
                              product.deliveryProductOptionDetail3 &&
                              `/${product.deliveryProductOptionDetail3.name}`
                            }`;

                          return (
                            <tr key={index}>
                              <td>
                                <div>
                                  {product.state === "Refund" && (
                                    <>
                                      [
                                      {deliveryOrderProductStateName(
                                        product.state
                                      )}
                                      ]
                                    </>
                                  )}
                                  {product.deliveryProduct.name}
                                  {option ? `[${option}]` : ``}
                                </div>
                              </td>
                              <td>
                                <div>{product.count}개</div>
                              </td>
                              <td>
                                <div className="text-right">
                                  <NumberFormat
                                    value={product.totalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  드림
                                </div>
                              </td>
                              <td>
                                {product.state === "Pay" ? (
                                  <div>
                                    <button
                                      className={`btn btn-white btn-xs text-red`}
                                      onClick={() => onRefund(product)}
                                    >
                                      환불
                                    </button>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2}>합계</td>
                          <td>
                            <div className="text-right">
                              <NumberFormat
                                value={totalPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </PanelBody>
              </>
            ) : (
              <PanelBody>
                <p className="text-center">등록된 데이터가 없습니다.</p>
              </PanelBody>
            )}
          </Panel>
        </div>
      </div>
    </Fragment>
  );
};

const Presenter = ({ history, match, location, loading, data, baseUrl }) => {
  return (
    <div>
      <Helmet>
        <title>배송 | 한밭페이</title>
      </Helmet>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/delivery/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/delivery/deliveryOrders">배송</Link>
        </li>
        <li className="breadcrumb-item active">배송내역</li>
      </ol>
      <h1 className="page-header">배송</h1>

      {!loading ? (
        <Detail
          history={history}
          match={match}
          location={location}
          data={data}
          baseUrl={baseUrl}
        />
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
};

export default Presenter;
