import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FEEMONTHFILE_WRITE_QUERY, FEEMONTHFILE_LIST_QUERY } from "../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();

  const id = match.params.id;

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const years = Array.from(
    new Array(year - 2020),
    (val, index) => index + year
  );
  const months = Array.from(new Array(12), (val, index) => index + 1);

  const [writeMutation] = useMutation(FEEMONTHFILE_WRITE_QUERY, {
    refetchQueries: () => [
      {
        query: FEEMONTHFILE_LIST_QUERY,
        variables: { feeId: id, skip: 0, first: 300 },
      },
    ],
  });
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const files = data.file;
      const file = files[0];
      const extension = file.name.split(".").pop();

      if (extension !== "csv" && extension !== "xlsx" && extension !== "xls") {
        toast.error("확장자가 .csv, .xlsx, .xls인 엑셀 파일로 업로드해주세요.");
        return;
      }

      const {
        data: { AdminFeeMonthFileWrite },
      } = await writeMutation({
        variables: {
          feeId: id,
          name: data.name,
          year: data.year,
          month: data.month,
          file: file,
        },
      });
      if (AdminFeeMonthFileWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/fees/${match.params.id}/monthFiles${location.search}`);
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(`/fees/${match.params.id}/monthFiles${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/fees/${match.params.id}/monthFiles${location.search}`
              );
            }}
          >
            파일등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      ref={register({
                        required: "이름을 입력해주세요.",
                      })}
                    />
                    {errors.name && <div>{errors.name.message}</div>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">연도</label>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      name="year"
                      ref={register({
                        required: "연도를 선택해주세요.",
                      })}
                      defaultValue={year}
                    >
                      {years.map((item, index) => {
                        return (
                          <option
                            key={`year${index}`}
                            value={item}
                            // disabled={true}
                          >
                            {item}년
                          </option>
                        );
                      })}
                    </select>
                    {errors.year && <div>{errors.year.message}</div>}
                  </div>
                  <label className="col-form-label col-md-2">월</label>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      name="month"
                      ref={register({
                        required: "월을 선택해주세요.",
                      })}
                      defaultValue={month}
                    >
                      {months.map((item, index) => {
                        return (
                          <option
                            key={`month${index + 1}`}
                            value={item}
                            // disabled={true}
                          >
                            {item}월
                          </option>
                        );
                      })}
                    </select>
                    {errors.month && <div>{errors.month.message}</div>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">파일</label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="file"
                      name="file"
                      ref={register({
                        required: "파일를 선택해주세요.",
                      })}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    {errors.file && <div>{errors.file.message}</div>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="col-xs-12 text-right">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
