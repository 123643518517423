import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../Components/Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NOTICE_WRITE_QUERY } from "../Queries";
import { useMutation } from "react-apollo-hooks";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const [noticeWriteMutation] = useMutation(NOTICE_WRITE_QUERY);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminNoticeWrite },
      } = await noticeWriteMutation({
        variables: {
          commonApp: match.params.CommonApp,
          noticeConfig: match.params.noticeConfig,
          isPush: data.isPush === "true" ? true : false,
          title: data.title,
          contents: data.contents,
        },
      });
      if (AdminNoticeWrite) {
        toast.success("처리 되었습니다.");
        history.push(
          `/noticeConfigs/${match.params.CommonApp}/${match.params.noticeConfig}/notices${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Helmet>
        <title>공지사항 관리 | 한밭페이</title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">공지사항</li>
          </ol>
          <h1 className="page-header">공지사항</h1>

          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/noticeConfigs/${match.params.CommonApp}/${match.params.noticeConfig}/notices${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} />
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>목록</PanelHeader>
                <PanelBody className="panel-form form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">제목</label>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="제목을 입력해주세요."
                        ref={register({
                          required: "제목을 입력해주세요.",
                        })}
                      />
                      {errors.title && <span>{errors.title.message}</span>}
                    </div>
                    <label className="col-form-label col-md-2">알림</label>
                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        id="isPush"
                        className="form-control"
                        name="isPush"
                        value={true}
                        ref={register()}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xs-12">
                      <textarea
                        name="contents"
                        className="form-control"
                        rows="10"
                        placeholder="내용 입력해주세요."
                        ref={register({
                          required: "내용 입력해주세요.",
                        })}
                      ></textarea>
                      {errors.contents && (
                        <span>{errors.contents.message}</span>
                      )}
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/noticeConfigs/${match.params.CommonApp}/${match.params.noticeConfig}/notices${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} />
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-blue ">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
