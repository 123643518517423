import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownProfile from "./Dropdown/Profile";

import { PageSettings } from "../../Config/PageSettings";

const Header = ({ userData }) => {
  const [collapseMegaMenu, setCollapseMegaMenu] = useState(false);

  const toggleMegaMenu = () => {
    setCollapseMegaMenu(!collapseMegaMenu);
  };

  return (
    <PageSettings.Consumer>
      {({
        toggleMobileSidebar,
        toggleRightSidebar,
        toggleMobileRightSidebar,
        toggleMobileTopMenu,
        pageHeaderLanguageBar,
        pageHeaderMegaMenu,
        pageTwoSidebar,
        pageTopMenu,
        pageSidebar,
      }) => (
        <div id="header" className="header navbar-inverse">
          <div className="navbar-header">
            {pageTwoSidebar && (
              <button
                type="button"
                className="navbar-toggle pull-left"
                onClick={toggleMobileRightSidebar}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            )}

            {pageHeaderMegaMenu && (
              <button
                type="button"
                className="navbar-toggle pt-0 pb-0 mr-0"
                onClick={toggleMegaMenu}
              >
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x"></i>
                  <i className="fa fa-cog fa-stack-1x"></i>
                </span>
              </button>
            )}
            {pageTopMenu && pageSidebar && (
              <button
                type="button"
                className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                onClick={toggleMobileTopMenu}
              >
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x"></i>
                  <i className="fa fa-cog fa-stack-1x"></i>
                </span>
              </button>
            )}
            {!pageSidebar && pageTopMenu && (
              <button
                type="button"
                className="navbar-toggle pull-left"
                onClick={toggleMobileTopMenu}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            )}
            {pageSidebar && (
              <button
                type="button"
                className="navbar-toggle pull-left"
                onClick={toggleMobileSidebar}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            )}
            <Link to="/" className="navbar-brand">
              <img src="/assets/img/admin/logo.png" alt="한밭페이" />
            </Link>

            <ul className="navbar-nav navbar-right">
              <DropdownProfile userData={userData} />

              {pageTwoSidebar && (
                <li className="divider d-none d-md-block"></li>
              )}

              {pageTwoSidebar && (
                <li className="d-none d-md-block">
                  <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                    <i className="fa fa-th"></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default Header;
