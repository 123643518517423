import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_PRODUCT_OPTION_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;
  const option = match.params.option;
  const baseUrl = `/delivery/deliveryProducts/${id}/edit`;

  const { data, loading, error } = useQuery(
    STORE_DELIVERY_PRODUCT_OPTION_DETAIL_QUERY,
    {
      variables: { id: option },
    }
  );

  if (error) {
    return <>{error.message}</>;
  }
  if (loading) {
    return <></>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
