import React, { useState, useEffect, useRef } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import ReactToPrint from "react-to-print"
import DayOfWeekPrint from "../../../../Components/Print/DayOfWeekPrint"

export default ({ isModal, history, match, location, data, baseUrl }) => {
  const pageStyle = `@media print {@page{ size: landscape!important; }}`
  const modalRef = useRef()
  const printRef = useRef()
  const [content, setContent] = useState("")

  useEffect(() => {
    const element = modalRef.current

    if (isModal) {
      disableBodyScroll(element)
    }

    return () => {
      enableBodyScroll(element)
    }
  })

  return (
    <Modal
      isOpen={true}
      size={"sm"}
      ref={modalRef}
      toggle={() => {
        history.push(`${baseUrl}${location.search}`)
      }}
    >
      <ModalHeader
        toggle={() => {
          history.push(`${baseUrl}${location.search}`)
        }}
      >
        현황출력
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <p className="text-center">
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-white">
                    <i className="fa fa-print"></i> 출력하기
                  </button>
                )}
                content={() => printRef.current}
                documentTitle="요일별 주문현황 출력"
                pageStyle={pageStyle}
              />
              <div style={{ display: "none" }}>
                <DayOfWeekPrint ref={printRef} data={data} />
              </div>
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
