import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import Chart from "react-google-charts";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import NumberFormat from "react-number-format";
import { dayOfWeekShortName } from "../../../../../Components/User/UserNames";

export default ({ history, match, location, year, month, data, baseUrl }) => {
  const currentYear = new Date().getFullYear();
  const selectYears = Array.from(
    new Array(currentYear - 2022),
    (val, index) => index + 2023
  ).sort((a, b) => b - a);
  const selectMonths = Array.from(
    new Array(12),
    (val, index) => index + 1
  ).sort((a, b) => b - a);

  const onMonthChange = (e) => {
    const [year, month] = e.target.value.split("-");
    history.push(`/delivery/deliveryStatistics/product/${year}/${month}`);
  };

  const [chartData, setChartData] = useState([["상품", "수량", "매출"]]);
  // const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    let array = chartData;
    for (const [
      index,
      value,
    ] of data.StoreDeliveryStatisticsProduct.statisticsProducts.entries()) {
      chartData.push([
        `${index + 1}.${value.name}`,
        value.payTotalPrice,
        value.payTotalCount,
      ]);
      // setCount((count) => count + value.payTotalCount);
      setPrice((price) => price + value.payTotalPrice);
    }
    setChartData(array);

    console.log({ chartData });
  }, []);

  return (
    <>
      <Helmet>
        <title>월별 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to="/deliveryStores">로컬푸드 구독</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to={`/deliveryStatistics/month/${year}/${month}`}>통계</Link>
          </li>
          <li className="breadcrumb-item active">상품별</li>
        </ol>
        <h1 className="page-header">상품별</h1>
        <Panel>
          <PanelHeader noButton={true}>상품별</PanelHeader>
          <Alert className="bg-gray-transparent-1">
            <div className="row m-b-15">
              <div className="col-md-12">
                <select
                  className="form-control"
                  defaultValue={`${year}-${month}`}
                  onChange={onMonthChange}
                >
                  {selectYears.map((selectYear) => {
                    return selectMonths.map((selectMonth, index) => {
                      return (
                        <option
                          key={index}
                          value={`${selectYear}-${selectMonth}`}
                        >
                          {selectYear}년 {selectMonth}월
                        </option>
                      );
                    });
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 rounded-1 p-10 height-330">
                  <Chart
                    style={{ width: "100%" }}
                    height={330}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={{
                      bars: "vertical",
                      vAxis: { format: "decimal" },
                      // height: 300,
                      legend: { position: "right", maxLines: 3 },
                      bar: { groupWidth: "75%" },
                      isStacked: true,
                      // colors: ["#1b9e77", "#7570b3", "#d95f02"],
                    }}
                    legendToggle
                  />
                </div>
              </div>
            </div>
          </Alert>
          <PanelBody>
            <>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-top m-b-0">
                  <thead>
                    <tr>
                      <th>상품정보</th>
                      <th>판매수량</th>
                      <th>금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>전체</td>
                      <td align="right">-</td>
                      <td align="right">
                        <NumberFormat
                          value={price}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </td>
                    </tr>
                    {chartData
                      .filter((item) => item[0] !== `상품`)
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item[0]}</td>
                            <td align="right">{item[2]}개</td>
                            <td align="right">
                              <NumberFormat
                                value={item[1]}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              드림
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td>전체</td>
                      <td align="right">-</td>
                      <td align="right">
                        <NumberFormat
                          value={price}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        드림
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </PanelBody>
        </Panel>
      </div>
    </>
  );
};
