import { gql } from "apollo-boost";

/* 1:1문의 설정 */
export const INQUIRY_CONFIG_QUERY = gql`
  query AdminInquiryConfig($id: String) {
    AdminInquiryConfig(id: $id) {
      id
      name
      manager {
        id
        role
        userId
        name
      }
      listCount
      pageCount
    }
  }
`;
export const USER_LIST_QUERY = gql`
  query AdminUserList($keyword: String, $skip: Int, $first: Int) {
    AdminUserList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        userId
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const INQUIRY_CONFIG_WRITE_QUERY = gql`
  mutation AdminInquiryConfigWrite(
    $name: String!
    $manager: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminInquiryConfigWrite(
      name: $name
      manager: $manager
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

export const INQUIRY_CONFIG_EDIT_QUERY = gql`
  mutation AdminInquiryConfigEdit(
    $id: String
    $name: String!
    $manager: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminInquiryConfigEdit(
      id: $id
      name: $name
      manager: $manager
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

/* 1:1문의 분류 */
export const INQUIRY_CATEGORY_LIST_QUERY = gql`
  query AdminInquiryCategoryList(
    $inquiryConfig: String
    $commonApp: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminInquiryCategoryList(
      inquiryConfig: $inquiryConfig
      commonApp: $commonApp
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      inquiryCategories {
        id
        commonApp
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const INQUIRY_CATEGORY_DETAIL_QUERY = gql`
  query AdminInquiryCategoryDetail($id: String!) {
    AdminInquiryCategoryDetail(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const INQUIRY_CATEGORY_WRITE_QUERY = gql`
  mutation AdminInquiryCategoryWrite(
    $inquiryConfig: String!
    $name: String!
    $commonApp: String!
  ) {
    AdminInquiryCategoryWrite(
      inquiryConfig: $inquiryConfig
      name: $name
      commonApp: $commonApp
    )
  }
`;

export const INQUIRY_CATEGORY_EDIT_QUERY = gql`
  mutation AdminInquiryCategoryEdit(
    $inquiryConfig: String
    $id: String!
    $name: String
  ) {
    AdminInquiryCategoryEdit(
      inquiryConfig: $inquiryConfig
      id: $id
      name: $name
    )
  }
`;

export const INQUIRY_CATEGORY_DELETE_QUERY = gql`
  mutation AdminInquiryCategoryDelete($id: String!) {
    AdminInquiryCategoryDelete(id: $id)
  }
`;

/* 1:1문의 */
export const INQUIRY_LIST_QUERY = gql`
  query AdminInquiryList(
    $commonApp: String
    $inquiryCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminInquiryList(
      commonApp: $commonApp
      inquiryCategory: $inquiryCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      inquiries {
        id
        commonStatus
        inquiryCategory {
          id
          name
        }
        user {
          id
          userId
          name
        }
        email
        phone
        question
        answer
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const INQUIRY_DETAIL_QUERY = gql`
  query AdminInquiryDetail($id: String!) {
    AdminInquiryDetail(id: $id) {
      id
      commonApp
      commonStatus
      email
      phone
      question
      answer
      createdAt
      updatedAt
      inquiryCategory {
        id
        name
      }
      user {
        id
        userId
        name
      }
    }
  }
`;

export const INQUIRY_REPLY_QUERY = gql`
  mutation AdminInquiryReply(
    $id: String
    $inquiryCategory: String
    $question: String
    $answer: String
    $commonStatus: String
  ) {
    AdminInquiryReply(
      id: $id
      inquiryCategory: $inquiryCategory
      question: $question
      answer: $answer
      commonStatus: $commonStatus
    )
  }
`;

export const INQUIRY_DELETE_QUERY = gql`
  mutation AdminInquiryDelete($id: String!) {
    AdminInquiryDelete(id: $id)
  }
`;
