import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";

import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STATISTICS_MONTH_QUERY } from "../Queries";

export default withRouter(({ history, match, location }) => {
  const year = match.params.year;
  const month = match.params.month;
  const queryString = qs.parse(location.search.substr(1));

  const deliveryStore_id = queryString.deliveryStore_id
    ? queryString.deliveryStore_id
    : "";
  const baseUrl = `/deliveryStatistics/month/${year}/${month}`;

  const { data, loading, error } = useQuery(
    ADMIN_DELIVERY_STATISTICS_MONTH_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        year,
        month,
        deliveryStore_id,
      },
    }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      year={year}
      month={month}
      deliveryStore_id={deliveryStore_id}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
