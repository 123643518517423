import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";

import { useQuery } from "react-apollo-hooks";
import { DELIVERY_BANNER_LIST_QUERY } from "../Queries";
import qs from "qs";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const keyword = queryString.keyword ? queryString.keyword : "";
  const page = queryString.page ? +queryString.page : 1;
  const take = 15;
  const baseUrl = "/deliveryBanners";

  const { data, loading, error } = useQuery(DELIVERY_BANNER_LIST_QUERY, {
    variables: {
      take,
      skip: (page - 1) * take,
      keyword,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      keyword={keyword}
      page={page}
      take={take}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
