import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { commonStatusArray } from "../../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  ADMIN_DELIVERY_PRODUCT_OPTION_WRITE_MUTATION,
  ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
} from "../../Queries";
import Swal from "sweetalert2";

const Write = withRouter(
  ({
    history,
    match,
    location,

    isModal,
  }) => {
    const id = match.params.id;
    const baseUrl = `/deliveryProducts/${id}/edit`;
    const modalRef = React.createRef();

    const { control, handleSubmit, errors } = useForm({
      defaultValues: {
        name: "",
        commonStatus: "",
        detailName: "",
        detailCommonStatus: "",
        detailPrice: "",
      },
    });

    const [
      AdminDeliveryProductOptionWriteMutation,
      { loading: AdminDeliveryProductOptionWriteLoading },
    ] = useMutation(ADMIN_DELIVERY_PRODUCT_OPTION_WRITE_MUTATION, {
      refetchQueries: () => [
        {
          query: ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY,
          variables: { id },
        },
      ],
    });

    const onSubmit = async (data) => {
      try {
        if (AdminDeliveryProductOptionWriteLoading) {
          return;
        }
        const {
          data: { AdminDeliveryProductOptionWrite },
        } = await AdminDeliveryProductOptionWriteMutation({
          variables: {
            deliveryProduct_id: id,

            commonStatus: data.commonStatus,
            name: data.name,

            detailCommonStatus: data.detailCommonStatus,
            detailPrice: data.detailPrice,
            detailName: data.detailName,
          },
        });
        if (AdminDeliveryProductOptionWrite) {
          Swal.fire({
            icon: "info",
            title: "안내",
            text: "처리 되었습니다.",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "확인",
            focusConfirm: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`${baseUrl}${location.search}`);
            }
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "오류",
          text: "에러가 발생했습니다. 다시 시도해 주세요.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        });
      }
    };

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <Fragment>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(`${baseUrl}${location.search}`);
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(`${baseUrl}${location.search}`);
              }}
            >
              상품옵션등록
            </ModalHeader>
            <ModalBody className=" p-0">
              <div className="form-group">
                <div className=" p-10 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered tablebasic product_op_table">
                          <caption className="sr-only">
                            상품옵션생성-항목,옵션명,옵션상태,상세옵션명,상세옵션가격,상세옵션상태
                          </caption>
                          <thead>
                            <tr>
                              <th
                                abbr="옵션명"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션명
                              </th>
                              <th
                                abbr="옵션상태"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션상태
                              </th>
                              <th
                                abbr="옵션값"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션값
                              </th>
                              <th
                                abbr="옵션값 가격"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션값 가격
                              </th>
                              <th
                                abbr="옵션값 상태"
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                옵션값 상태
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="name"
                                  rules={{ required: "옵션명을 입력해주세요." }}
                                  render={({ onChange, onBlur, value }) => (
                                    <>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="ex) 색상"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                      />
                                      {errors.name && (
                                        <span className="text-red errorText">
                                          {errors.name.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                />
                              </td>
                              <td>
                                <Controller
                                  control={control}
                                  name="commonStatus"
                                  rules={{
                                    required: "옵션상태를 선택해주세요.",
                                  }}
                                  render={({ onChange, value }) => (
                                    <>
                                      <select
                                        className="form-control"
                                        onChange={onChange}
                                        defaultValue={value}
                                      >
                                        <option value="">상태</option>
                                        {Object.keys(commonStatusArray)
                                          .filter((item) => item !== "D")
                                          .map((key, index) => (
                                            <option key={index} value={key}>
                                              {commonStatusArray[key]}
                                            </option>
                                          ))}
                                      </select>
                                      {errors.commonStatus && (
                                        <span className="text-red errorText">
                                          {errors.commonStatus.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                />
                              </td>
                              <td>
                                <Controller
                                  control={control}
                                  name="detailName"
                                  rules={{
                                    required: "옵션값을 입력해주세요.",
                                  }}
                                  render={({ onChange, onBlur, value }) => (
                                    <>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="ex) 베이지"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                      />
                                      {errors.detailName && (
                                        <span className="text-red errorText">
                                          {errors.detailName.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                />
                              </td>
                              <td>
                                <Controller
                                  control={control}
                                  name="detailPrice"
                                  rules={{
                                    required: "옵션값 가격을 입력해주세요.",
                                  }}
                                  render={({ onChange, onBlur, value }) => (
                                    <>
                                      <input
                                        className="form-control"
                                        type="number"
                                        placeholder="ex) 10000"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                      />
                                      {errors.detailPrice && (
                                        <span className="text-red errorText">
                                          {errors.detailPrice.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                />
                              </td>
                              <td>
                                <Controller
                                  control={control}
                                  name="detailCommonStatus"
                                  rules={{
                                    required: "옵션값 상태를 선택해주세요.",
                                  }}
                                  render={({ onChange, value }) => (
                                    <>
                                      <select
                                        className="form-control"
                                        onChange={onChange}
                                        defaultValue={value}
                                      >
                                        <option value="">상태</option>
                                        {Object.keys(commonStatusArray)
                                          .filter((item) => item !== "D")
                                          .map((key, index) => (
                                            <option key={index} value={key}>
                                              {commonStatusArray[key]}
                                            </option>
                                          ))}
                                      </select>
                                      {errors.detailCommonStatus && (
                                        <span className="text-red errorText">
                                          {errors.detailCommonStatus.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="text-left text-danger">
                        * 옵션명은 최대 3개까지 등록이 가능합니다.
                        <br />
                        (ex. 옵션명 - 색상 / 사이즈 등)
                        <br />
                        (ex. 옵션값 - 색상 &gt; 화이트 , 블랙 / 사이즈 &gt;
                        240mm, 250mm)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="col-xs-12 text-center">
                <button type="submit" className="btn btn-white text-blue">
                  확인
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </Fragment>
    );
  }
);

export default Write;
