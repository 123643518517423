import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  STORE_PRODUCT_WRITE_QUERY,
  STORE_PRODUCT_LIST_QUERY,
} from "../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const [storeProductWriteMutation] = useMutation(STORE_PRODUCT_WRITE_QUERY, {
    refetchQueries: () => [
      {
        query: STORE_PRODUCT_LIST_QUERY,
        variables: { store: match.params.id },
      },
    ],
  });
  const { register, handleSubmit, errors } = useForm();

  const [storeLogoImage, setStoreLogoImage] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const handleProductImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setStoreLogoImage(reader.result);
    };
  };
  const onClick = (e) => {
    e.preventDefault();
    document.getElementById("productImage").click();
  };

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminStoreProductWrite },
      } = await storeProductWriteMutation({
        variables: {
          store: match.params.id,
          file: data.file[0],
          name: data.name,
          description: data.description,
          price: data.price,
        },
      });
      if (AdminStoreProductWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${match.params.id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  //   console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(`/stores/${match.params.id}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`/stores/${match.params.id}${location.search}`);
            }}
          >
            등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <div className="col-md-12">
                    <div className="img_item">
                      <img src={storeLogoImage} alt="이미지 업로드" />
                    </div>
                    <div className="m-t-10">
                      <input
                        type="file"
                        id="productImage"
                        name="file"
                        className="hide"
                        onChange={handleProductImage}
                        ref={register({
                          required: "이미지를 선택해주세요.",
                        })}
                      />
                      <button
                        className="btn btn-primary btn-block"
                        onClick={onClick}
                      >
                        이미지 업로드
                      </button>
                    </div>
                    {errors.file && <span>{errors.file.message}</span>}
                    <div class="alert alert-primary fade show m-t-10 m-b-0">
                      <span class="close" data-dismiss="alert">
                        ×
                      </span>
                      <b>이미지 사이즈 :</b> 넓이 600px X 높이 450px
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-4">상품명</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      ref={register({
                        required: "상품명을 입력해주세요.",
                      })}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-4">상품가격</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="price"
                      className="form-control"
                      ref={register({
                        required: "상품가격을 입력해주세요.",
                      })}
                    />
                    {errors.price && <span>{errors.price.message}</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-4">상품정보</label>
                  <div className="col-md-8">
                    <textarea
                      name="description"
                      className="form-control"
                      ref={register({
                        required: "상품정보를 입력해주세요.",
                      })}
                    ></textarea>
                    {errors.description && (
                      <span>{errors.description.message}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
