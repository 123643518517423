import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";

import {
  commonStatusName,
  soldOutName,
} from "../../../../../Components/User/UserNames";

export default withRouter(
  ({
    history,
    location,
    match,
    giftProductOptions,
    giftProductOptionDetailsCount,
  }) => {
    const id = match.params.id;

    // let optionCount = 0;
    let optionDetailCount = 0;
    return (
      <div className="row">
        <div className="col-sm-12">
          <Panel>
            <PanelHeader noButton={true}>일정 정보</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-sm-12">
                  {giftProductOptions.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered tablebasic product_op_table">
                        <caption className="sr-only">
                          티켓 구분,일정,기능
                        </caption>
                        <colgroup>
                          <col width="20%" />
                          <col width="20%" />
                          <col width="20%" />
                          <col width="20%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th
                              abbr="구분"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              구분
                            </th>
                            <th
                              abbr="옵션값"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션값
                            </th>
                            <th
                              abbr="옵션가격"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              옵션가격
                            </th>
                            <th
                              abbr="기능"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              기능
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {giftProductOptions.map((option) => {
                            optionDetailCount = 0;
                            return option.giftProductOptionDetails.map(
                              (optionDetail, index) => {
                                // optionCount++;
                                optionDetailCount++;
                                return (
                                  <tr key={index}>
                                    {optionDetailCount === 1 && (
                                      <td
                                        rowSpan={
                                          option.giftProductOptionDetails.length
                                        }
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                      >
                                        {option.commonStatus !== "C" && (
                                          <span className="badge badge-danger">
                                            {commonStatusName(
                                              option.commonStatus
                                            )}
                                          </span>
                                        )}{" "}
                                        {option.name}
                                      </td>
                                    )}

                                    <td>
                                      {optionDetail.commonStatus !== "C" && (
                                        <span className="badge badge-danger">
                                          {commonStatusName(
                                            optionDetail.commonStatus
                                          )}
                                        </span>
                                      )}
                                      {optionDetail.name}{" "}
                                      {optionDetail.isSoldOut && (
                                        <span className="badge badge-danger">
                                          {soldOutName(optionDetail.isSoldOut)}
                                        </span>
                                      )}
                                    </td>
                                    <td>{optionDetail.price}</td>
                                    <td
                                      className="text-center"
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <Link
                                        to={`/giftConfigs/${match.params.config}/products/${match.params.id}/ticketOptions/${option.id}/details/${optionDetail.id}/edit${location.search}`}
                                        className="btn btn-xs btn-success m-r-5"
                                      >
                                        <i className="fa fa-check"></i> 수정
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              }
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                        <div className="jumbotron nobottommargin text-center m-0">
                          <div className="feature-box fbox-center fbox-light fbox-plain">
                            <h3 style={{ fontSize: "28px" }}>
                              티켓 일정 정보를 입력해주세요.
                            </h3>
                            <p
                              style={{
                                fontSize: "13px",
                                lineHeight: "160%",
                              }}
                            >
                              티켓 일정 정보를 생성하셔야 티켓을 등록 판매할 수
                              있습니다.
                              <br />
                              감사합니다.
                            </p>
                            <p>
                              <Link
                                to={`/giftConfigs/${match.params.config}/products/${id}/ticketOptions/write${location.search}`}
                                className="btn btn-white"
                              >
                                <i className="fa fa-plus"></i> 일정 생성
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    );
  }
);
