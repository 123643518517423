import React from "react";
// import { Link } from "react-router-dom";
import { PageSettings } from "../../Config/PageSettings.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const SidebarProfile = ({ userData }) => {
  const {
    AdminInfo: { name, role },
  } = userData;

  return (
    <PageSettings.Consumer>
      {({ pageSidebarMinify, toggleMobileSidebar }) => (
        <ul className="nav">
          <li className={"nav-profile p-0 p-t-20 p-b-20 "}>
            <div className="cover with-shadow"></div>
            <div className="info">
              {name}
              <small>{role}</small>
            </div>
            <button
              type="button"
              className="close-toggle d-lg-none d-md-none d-sm-inline-block"
              onClick={toggleMobileSidebar}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </li>
        </ul>
      )}
    </PageSettings.Consumer>
  );
};

export default SidebarProfile;
