import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_ADDRESS_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const id = match.params.id;
  const baseUrl = `/delivery/deliveryBoxes/${id}`;

  const { data, loading, error } = useQuery(STORE_DELIVERY_ADDRESS_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: { take: 100, skip: 0 },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
