import { gql } from "apollo-boost";

export const ADMIN_DELIVERY_STATISTICS_MONTH_QUERY = gql`
  query AdminDeliveryStatisticsMonth(
    $year: String!
    $month: String!
    $deliveryStore_id: String
  ) {
    AdminDeliveryStatisticsMonth(
      year: $year
      month: $month
      deliveryStore_id: $deliveryStore_id
    ) {
      statisticsDays {
        createdAt
        payTotalPrice
        refundTotalPrice
      }
    }
  }
`;

export const ADMIN_DELIVERY_STATISTICS_PRODUCT_QUERY = gql`
  query AdminDeliveryStatisticsProduct(
    $year: String!
    $month: String!
    $deliveryStore_id: String
  ) {
    AdminDeliveryStatisticsProduct(
      year: $year
      month: $month
      deliveryStore_id: $deliveryStore_id
    ) {
      statisticsProducts {
        name
        payTotalPrice
        payTotalCount
      }
    }
  }
`;
