import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { TICKET_CONFIG_LIST_QUERY, TICKET_CONFIG_EDIT_QUERY } from "../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, id, name, isModal }) => {
  const modalRef = React.createRef();
  const productCommonStatus = match.params.commonStatus;

  const [productConfigEditMutation] = useMutation(TICKET_CONFIG_EDIT_QUERY, {
    refetchQueries: [{ query: TICKET_CONFIG_LIST_QUERY }],
    awaitRefetchQueries: true,
  });
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      name: name,
    },
  });

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminTicketConfigEdit },
      } = await productConfigEditMutation({
        variables: {
          id: id,
          name: data.name,
        },
      });
      if (AdminTicketConfigEdit) {
        toast.success("설정 되었습니다.");
        history.push(
          `/ticketConfigs/${match.params.config}/products/${productCommonStatus}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/ticketConfigs/${match.params.config}/products/${productCommonStatus}${location.search}`
          );
        }}
        size={"lg"}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/ticketConfigs/${match.params.config}/products/${productCommonStatus}${location.search}`
              );
            }}
          >
            설정수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({ required: "이름을 입력해주세요." })}
                      defaultValue={watch("name")}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue m-r-5">
              확인
            </button>
            <Link
              to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}`}
              className="btn btn-white text-red"
            >
              취소
            </Link>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
});
