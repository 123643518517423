import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_DETAIL_QUERY,
  TICKET_PRODUCT_EDIT_QUERY,
  COMMONFILE_WRITE_QUERY,
} from "../../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../../Components/Panel/Panel";
import DaumPostcode from "react-daum-postcode";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// import { Editor } from "@tinymce/tinymce-react";
import Editor from "../../../../../../Components/Editor/Editor";
import { graphqlUri } from "../../../../../../Config/Env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";

import SearchTicketCategory from "../SearchTicketCategory";

import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from "moment";
import "moment/locale/ko";

import KakaoMap from "../../../../../../Components/Map/KakaoMap";

import { commonStatusArray } from "../../../../../../Components/User/UserNames";

export default withRouter(
  ({
    history,
    match,
    location,

    config,
    id,
    commonStatus,

    coverImageSrc,

    managerName,
    managerTel,
    managerEmail,

    minute,
    limit,
    isPay,

    price,
    isDiscount,
    discountPrice,

    applyStartDateTimeAt,
    applyEndDateTimeAt,
    placeType,

    placeZipcode,
    placeAddress,
    placeAddressDetail,
    placeLongitude,
    placeLatitude,

    ticketCategoryId,
    ticketCategoryName,
    name,
    contents,

    isSoldOut,
    timestamp,

    ticketProductOptionsCount,
  }) => {
    const configId = match.params.config;

    const [ticketCategory, setTicketCategory] = useState({
      label: ticketCategoryName,
      value: ticketCategoryId,
    });

    const [ticketProductEditMutation] = useMutation(TICKET_PRODUCT_EDIT_QUERY, {
      refetchQueries: () => [
        { query: TICKET_PRODUCT_DETAIL_QUERY, variables: { id } },
      ],
    });
    const [commonFileWriteMutation] = useMutation(COMMONFILE_WRITE_QUERY);

    const { register, handleSubmit, watch, errors, setValue } = useForm({
      defaultValues: {
        managerName,
        managerTel,
        managerEmail,

        minute,
        limit,
        isPay: String(isPay),

        price,
        isDiscount,
        discountPrice,

        applyStartDateTime: applyStartDateTimeAt,
        applyEndDateTime: applyEndDateTimeAt,
        placeType,

        placeZipcode,
        placeAddress,
        placeAddressDetail,
        placeLongitude,
        placeLatitude,

        ticketCategory: ticketCategoryId,
        name,
        contents,

        isSoldOut,
      },
    });

    const [coverImage, setCoverImage] = useState(
      coverImageSrc
        ? graphqlUri + coverImageSrc
        : "/assets/img/gallery/gallery-default.jpg"
    );
    const handleCoverImage = async (e) => {
      e.preventDefault();

      const files = e.target.files;
      const file = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setCoverImage(reader.result);
      };
    };
    const onClickCover = () => {
      document.getElementById("coverImage").click();
    };
    const [applyStartDateTime, setApplyStartDateTime] = useState(
      new Date(applyStartDateTimeAt)
    );
    const [applyEndDateTime, setApplyEndDateTime] = useState(
      new Date(applyEndDateTimeAt)
    );

    const onSubmit = async (data) => {
      if (data.managerName === "") {
        return toast.error("담당자 이름을 입력해주세요.");
      }
      if (data.managerTel === "") {
        return toast.error("담당자 전화번호를 입력해주세요.");
      }
      if (data.managerEmail === "") {
        return toast.error("담당자 이메일를 입력해주세요.");
      }

      if (data.minute === "") {
        return toast.error("시간을 입력해주세요.");
      }
      if (data.limit === "") {
        return toast.error("정원을 입력해주세요.");
      }
      if (data.isPay === "true") {
        if (data.price === "") {
          return toast.error("가격을 입력해주세요.");
        }
        if (data.isDiscount === "true") {
          if (data.discountPrice === "") {
            return toast.error("할인가격을 입력해주세요.");
          }
        }
      }

      if (data.applyStartDateTime === "") {
        return toast.error("유효기간 시작일시를 입력해주세요.");
      }
      if (data.applyEndDateTime === "") {
        return toast.error("유효기간 시작일시를 입력해주세요.");
      }
      if (data.placeType === "OffLine") {
        if (data.placeAddress === "") {
          return toast.error("주소를 검색해주세요.");
        }
        if (data.placeAddressDetail === "") {
          return toast.error("상세주소를 입력해주세요.");
        }
      }

      if (ticketCategory === "") {
        return toast.error("분류를 선택해주세요.");
      }
      if (data.name === "") {
        return toast.error("상품명을 입력해주세요.");
      }
      if (data.contents === "") {
        return toast.error("상세정보를 입력해주세요.");
      }

      try {
        const {
          data: { StoreTicketProductEdit },
        } = await ticketProductEditMutation({
          variables: {
            id,

            coverImage: data.coverImage[0],

            isSoldOut: data.isSoldOut === "true" ? true : false,

            managerName: data.managerName,
            managerTel: data.managerTel,
            managerEmail: data.managerEmail,

            minute: data.minute ? data.minute : "0",
            limit: data.limit ? data.limit : "0",
            isPay: data.isPay === "true" ? true : false,

            price: data.price,
            isDiscount: data.isDiscount === "true" ? true : false,
            discountPrice: data.discountPrice,

            applyStartDateTime,
            applyEndDateTime,
            placeType: data.placeType,

            placeZipcode: data.placeZipcode,
            placeAddress: data.placeAddress,
            placeAddressDetail: data.placeAddressDetail,
            placeLongitude: data.placeLongitude,
            placeLatitude: data.placeLatitude,

            ticketCategory: ticketCategory.value,
            name: data.name,
            contents: data.contents,
          },
        });
        if (StoreTicketProductEdit) {
          toast.success("처리 되었습니다.");
          history.push(
            `/ticket/ticketConfigs/${configId}/products/${id}${location.search}`
          );
        }
      } catch (e) {
        console.log(e);
        var error = e.toString();
        error = error.replace("Error: GraphQL error:", "");
        toast.error(`${error}`);
      }
    };
    // console.log(errors);

    const [zipcodeModal, setZipcodeModal] = useState(false);
    const handleZipcodeModal = () => {
      setZipcodeModal(!zipcodeModal);
    };
    const handleAddress = (data) => {
      const API_KEY = "7da7d093496c67033d3e14aa3102e838";
      const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&size=15`;
      fetch(url, {
        headers: {
          Authorization: `KakaoAK ${API_KEY}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          console.log({ json });
          console.log({ data });
          setValue("placeZipcode", data.zonecode);
          setValue("placeAddress", data.address);

          setValue("placeLongitude", json.documents[0].x);
          setValue("placeLatitude", json.documents[0].y);
          handleZipcodeModal();
        });
    };

    return (
      <>
        <Helmet>
          <title>드림티켓 | 한밭페이</title>
        </Helmet>
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item ">드림티켓</li>
              <li className="breadcrumb-item active">티켓</li>
            </ol>
            <h1 className="page-header">티켓</h1>

            <div className="row m-b-15">
              <div className="col-xs-6 text-left">
                <Link
                  to={`/ticket/ticketConfigs/${configId}/products/${id}${location.search}`}
                  className="btn btn-white"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> 뒤로
                </Link>
              </div>
              <div className="col-xs-6 text-right">
                <div className="btn-group">
                  <button type="submit" className="btn btn-white text-blue ">
                    확인
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-2 col-sm-12">
                <Panel>
                  <PanelHeader noButton={true}>커버</PanelHeader>
                  <PanelBody>
                    <div className="form-group row m-b-0">
                      <div className="col-md-12">
                        <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                          <div className="vertical-box-column valign-middle text-center">
                            <img
                              src={coverImage}
                              alt="이미지 업로드"
                              className="height-200"
                            />
                          </div>
                        </div>
                        <div className="m-t-10">
                          <input
                            type="file"
                            id="coverImage"
                            name="coverImage"
                            className="hide"
                            onChange={handleCoverImage}
                            ref={register({
                              // required: "이미지를 선택해주세요.",
                            })}
                          />
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={onClickCover}
                          >
                            이미지 업로드
                          </button>
                        </div>
                        {errors.coverImage && (
                          <div className="text-danger">
                            {errors.coverImage.message}
                          </div>
                        )}
                        <div className="alert alert-primary fade show m-t-10 m-b-0">
                          <b>이미지 사이즈 : </b> 넓이 1024px X 높이 567px
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
              <div className="col-md-10 col-sm-12">
                <Panel>
                  <PanelHeader noButton={true}>기본정보</PanelHeader>
                  <PanelBody className="panel-form form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">* 매진</label>
                      <div className="col-md-10">
                        <input
                          type="checkbox"
                          className="form-control"
                          name="isSoldOut"
                          value={true}
                          ref={register()}
                        />
                        {errors.isSoldOut && (
                          <span>{errors.isSoldOut.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        * 담당자
                      </label>
                      <div className="col-md-2">
                        <input
                          type="text"
                          name="managerName"
                          className="form-control"
                          ref={register()}
                          placeholder="담당자명을 입력해주세요."
                        />
                        {errors.managerName && (
                          <span>{errors.managerName.message}</span>
                        )}
                      </div>
                      <div className="col-md-2">
                        <input
                          type="text"
                          name="managerTel"
                          className="form-control"
                          ref={register()}
                          placeholder="전화번호를 입력해주세요."
                        />
                        {errors.managerTel && (
                          <span>{errors.managerTel.message}</span>
                        )}
                      </div>
                      <div className="col-md-2">
                        <input
                          type="text"
                          name="managerEmail"
                          className="form-control"
                          ref={register()}
                          placeholder="이메일을 입력해주세요."
                        />
                        {errors.managerEmail && (
                          <span>{errors.managerEmail.message}</span>
                        )}
                      </div>
                      <label className="col-form-label col-md-2">
                        * 시간(분)
                      </label>
                      <div className="col-md-2">
                        <input
                          type="number"
                          name="minute"
                          className="form-control"
                          ref={register()}
                          placeholder="시간(분)을 입력해주세요."
                        />
                        {errors.limit && <span>{errors.limit.message}</span>}
                      </div>
                    </div>
                    <div className={`form-group row`}>
                      <label className="col-form-label col-md-2">* 정원</label>
                      <div className="col-md-4">
                        <input
                          type="number"
                          name="limit"
                          className="form-control"
                          ref={register()}
                          placeholder="정원을 입력해주세요."
                        />
                        {errors.limit && <span>{errors.limit.message}</span>}
                      </div>
                      <label className="col-form-label col-md-2">
                        * 유/무료
                      </label>
                      <div className="col-md-4">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="isPayTrue"
                            name="isPay"
                            value={true}
                            ref={register()}
                          />
                          <label htmlFor="isPayTrue">유료</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="isPayFalse"
                            name="isPay"
                            value={false}
                            ref={register()}
                          />
                          <label htmlFor="isPayFalse">무료</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`form-group row ${
                        watch("isPay") === "true" ? "" : "hide"
                      }`}
                    >
                      <label className="col-form-label col-md-2">가격</label>
                      <div className="col-md-4">
                        <input
                          type="number"
                          name="price"
                          className="form-control"
                          ref={register()}
                          placeholder="가격을 입력해주세요."
                        />
                        {errors.price && <span>{errors.price.message}</span>}
                      </div>
                      <label className="col-form-label col-md-2">
                        <input
                          type="checkbox"
                          name="isDiscount"
                          value={true}
                          ref={register()}
                        />
                        할인
                      </label>
                      <div className="col-md-4">
                        <input
                          type="number"
                          name="discountPrice"
                          className="form-control"
                          ref={register()}
                          placeholder="할인금액을 입력해주세요."
                        />
                        {errors.isDiscount && (
                          <span>{errors.isDiscount.message}</span>
                        )}
                        {errors.discountPrice && (
                          <span>{errors.discountPrice.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        * 유효기간
                      </label>
                      <div className="col-md-4">
                        <div className="input-group">
                          <DateTime
                            locale="ko"
                            name="applyStartDateTime"
                            defaultValue={Moment(applyStartDateTime)}
                            inputProps={{ placeholder: "시작일시" }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm:ss"
                            onChange={(date) => {
                              setApplyStartDateTime(
                                Moment(date).format("YYYY-MM-DD HH:mm:ss")
                              );
                            }}
                          />
                          {errors.applyStartDateTime && (
                            <span>{errors.applyStartDateTime.message}</span>
                          )}
                          <div className="input-group-prepend hidden-xs">
                            <span className="input-group-text">~</span>
                          </div>
                          <DateTime
                            locale="ko"
                            name="applyEndDateTime"
                            defaultValue={Moment(applyEndDateTimeAt)}
                            inputProps={{ placeholder: "종료일시" }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm:ss"
                            onChange={(date) => {
                              setApplyEndDateTime(
                                Moment(date).format("YYYY-MM-DD HH:mm:ss")
                              );
                            }}
                          />
                          {errors.applyEndDateTime && (
                            <span>{errors.applyEndDateTime.message}</span>
                          )}
                        </div>
                      </div>
                      <label className="col-form-label col-md-2">
                        * 온/오프라인
                      </label>
                      <div className="col-md-4">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="placeTypeOffLine"
                            name="placeType"
                            value={"OffLine"}
                            ref={register()}
                          />
                          <label htmlFor="placeTypeOffLine">오프라인</label>
                          <div className="radio radio-css radio-inline">
                            <input
                              type="radio"
                              id="placeTypeOnline"
                              name="placeType"
                              value={"OnLine"}
                              ref={register()}
                            />
                            <label htmlFor="placeTypeOnline">온라인</label>
                          </div>
                        </div>
                        {errors.placeType && (
                          <span>{errors.placeType.message}</span>
                        )}
                      </div>
                    </div>

                    <div
                      className={`form-group row ${
                        watch("placeType") === "OffLine" ? "" : "hide"
                      }`}
                    >
                      <label className="col-form-label col-md-2">장소</label>
                      <div className="col-md-10 p-0">
                        <div className="form-group row">
                          <div className="col-md-3">
                            <InputGroup size="sm">
                              <input
                                type="text"
                                name="placeZipcode"
                                className="form-control"
                                placeholder="우편번호를 입력해주세요."
                                ref={register({
                                  // required: "우편번호을 입력해주세요.",
                                })}
                                readOnly
                                onClick={handleZipcodeModal}
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={handleZipcodeModal}
                                >
                                  <i className="fa fa-search fa-fw"></i>
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </div>
                          <div className="col-md-5">
                            <input
                              type="text"
                              name="placeAddress"
                              className="form-control"
                              placeholder="주소를 입력해주세요."
                              ref={register()}
                              readOnly
                              onClick={handleZipcodeModal}
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              type="text"
                              name="placeAddressDetail"
                              className="form-control"
                              placeholder="상세주소를 입력해주세요."
                              ref={register()}
                            />
                          </div>
                        </div>

                        <div className="form-group row hide">
                          <div className="col-md-6">
                            <input
                              type="text"
                              name="placeLongitude"
                              className="form-control"
                              placeholder="경도를 입력해주세요."
                              ref={register()}
                              readOnly
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              name="placeLatitude"
                              className="form-control"
                              placeholder="위도를 입력해주세요."
                              ref={register()}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12 p-0">
                            <KakaoMap
                              longitude={watch("placeLongitude")}
                              latitude={watch("placeLatitude")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <Panel>
                  <PanelHeader>티켓정보</PanelHeader>
                  <PanelBody className="panel-form form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        * 분류/티켓명
                      </label>
                      <div className="col-md-2 select">
                        <SearchTicketCategory
                          history={history}
                          match={match}
                          location={location}
                          ticketCategory={ticketCategory}
                          setTicketCategory={setTicketCategory}
                        />
                        {errors.ticketCategory && (
                          <span>{errors.ticketCategory.message}</span>
                        )}
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          ref={register()}
                          placeholder="상품명을 입력해주세요."
                        />
                        {errors.name && <span>{errors.name.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        상세 정보
                      </label>
                      <div className="col-md-10 p-0">
                        <Editor
                          configId={configId}
                          timestamp={timestamp}
                          value={watch("contents")}
                          setValue={setValue}
                        />
                        <textarea
                          name="contents"
                          className="form-control hide"
                          rows="10"
                          placeholder="상세 정보를 입력해주세요."
                          ref={register()}
                          defaultValue={watch("contents")}
                        ></textarea>
                        {errors.contents && (
                          <span>{errors.contents.message}</span>
                        )}
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6 text-left">
                <Link
                  to={`/ticketConfigs/${configId}/products${location.search}`}
                  className="btn btn-white"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> 뒤로
                </Link>
              </div>
              <div className="col-xs-6 text-right">
                <div className="btn-group">
                  <button type="submit" className="btn btn-white text-blue ">
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
          <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
          <ModalBody>
            <DaumPostcode height={700} onComplete={handleAddress} />
          </ModalBody>
        </Modal>
      </>
    );
  }
);
