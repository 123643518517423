import { gql } from "apollo-boost";

export const CHARGEREQUEST_CONFIG_QUERY = gql`
  query AdminChargeRequestConfig($id: String) {
    AdminChargeRequestConfig(id: $id) {
      id
      name
      manager {
        id
        role
        userId
        name
      }
    }
  }
`;
export const USER_LIST_QUERY = gql`
  query AdminUserList($keyword: String, $skip: Int, $first: Int) {
    AdminUserList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        userId
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const CHARGEREQUEST_CONFIG_WRITE_QUERY = gql`
  mutation AdminChargeRequestConfigWrite($name: String!, $manager: String!) {
    AdminChargeRequestConfigWrite(name: $name, manager: $manager)
  }
`;

export const CHARGEREQUEST_CONFIG_EDIT_QUERY = gql`
  mutation AdminChargeRequestConfigEdit(
    $id: String
    $name: String!
    $manager: String!
  ) {
    AdminChargeRequestConfigEdit(id: $id, name: $name, manager: $manager)
  }
`;

export const CHARGEREQUEST_APPROVAL_QUERY = gql`
  mutation AdminChargeRequestApproval($id: String!) {
    AdminChargeRequestApproval(id: $id)
  }
`;
export const CHARGEREQUEST_CANCEL_QUERY = gql`
  mutation AdminChargeRequestCancel($id: String!) {
    AdminChargeRequestCancel(id: $id)
  }
`;
export const CHARGE_QUERY = gql`
  mutation AdminCharge($user: String!, $value: Int!, $title: String!) {
    AdminCharge(user: $user, value: $value, title: $title)
  }
`;

export const CHARGEREQUEST_LIST_QUERY = gql`
  query AdminChargeRequestList(
    $commonStatus: String
    $pay_method: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminChargeRequestList(
      commonStatus: $commonStatus
      pay_method: $pay_method
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      chargeRequests {
        id
        commonStatus
        user {
          id
          role
          userId
          name
        }
        wallet {
          id
        }

        currency
        pg
        pay_method
        merchant_uid
        imp_uid
        name
        amount

        buyer_email
        buyer_name
        buyer_tel
        buyer_addr
        buyer_postcode

        vbank_name
        vbank_num
        vbank_date

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const CHARGEREQUEST_WRITE_QUERY = gql`
  mutation AdminChargeRequestWrite(
    $pay_method: String!
    $currency: String!
    $amount: String!
    $wallet: String!
  ) {
    AdminChargeRequestWrite(
      pay_method: $pay_method
      currency: $currency
      amount: $amount
      wallet: $wallet
    )
  }
`;

export const WALLET_LIST_QUERY = gql`
  query AdminWalletList($keyword: String, $skip: Int, $first: Int) {
    AdminWalletList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      wallets {
        id
        user {
          id
          commonStatus
        }
        type
        userId
        name
        address
        secret
        trustlines {
          id
        }
        memo
        createdAt
        updatedAt
      }
      count
    }
  }
`;
