import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_PRODUCT_DETAIL_QUERY } from "../../Queries";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../Config/Env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons";

import { commonStatusName } from "../../../../../Components/User/UserNames";
import renderHTML from "react-render-html";
import TicketDate from "./TicketDate";
import KakaoMap from "../../../../../Components/Map/KakaoMap";

export default withRouter(({ history, location, match }) => {
  const productCommonStatus = match.params.commonStatus;
  const id = match.params.id;

  const { data, loading } = useQuery(TICKET_PRODUCT_DETAIL_QUERY, {
    variables: { id },
  });

  if (!loading) {
    const {
      AdminTicketProductDetail: {
        id,
        commonStatus,

        coverImage,

        store,

        managerName,
        managerTel,
        managerEmail,

        minute,
        limit,
        isPay,

        price,
        isDiscount,
        discountPrice,

        applyStartDateTime,
        applyEndDateTime,
        placeType,

        placeZipcode,
        placeAddress,
        placeAddressDetail,
        placeLongitude,
        placeLatitude,

        ticketCategory,
        name,
        contents,

        isSoldOut,

        ticketProductDates,
        ticketProductDateTimesCount,
      },
    } = data;
    const Cover =
      coverImage === "" || coverImage === null
        ? "/assets/img/product/product-default.jpg"
        : `${graphqlUri}${coverImage}`;

    return (
      <>
        <Helmet>
          <title>드림티켓 | 한밭페이</title>
        </Helmet>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">드림티켓</li>
            <li className="breadcrumb-item active">티켓</li>
          </ol>
          <h1 className="page-header">티켓</h1>

          <div className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <div className="btn-group">
                <Link
                  to={`/ticketOrders?ticketProduct=${id}`}
                  className="btn btn-white text-blue"
                >
                  구매내역
                </Link>
                <Link
                  to={`/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/edit${location.search}`}
                  className="btn btn-white text-green"
                >
                  수정
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2 col-sm-3">
              <Panel>
                <PanelHeader>커버</PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="bg-white rounded">
                      <div className="form-group row">
                        <div className="col-md-12">
                          <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                            <div className="vertical-box-column valign-middle text-center">
                              <img
                                src={Cover}
                                alt="이미지 업로드"
                                className="width-200"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-10 col-sm-9">
              <Panel>
                <PanelHeader>
                  기본정보{" "}
                  <span className="badge bg-yellow text-inverse">
                    {commonStatusName(commonStatus)}
                  </span>
                  {new Date(applyEndDateTime) <= new Date() && (
                    <span className="badge bg-yellow text-inverse">만료</span>
                  )}
                </PanelHeader>
                <PanelBody className="p-0">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        * 가맹점
                      </label>
                      <div className="col-md-6">
                        <Link to={`/stores/${store.id}`}>{store.name}</Link>
                      </div>
                      <label className="col-form-label col-md-2">매진</label>
                      <div className="col-md-2">
                        {isSoldOut ? <i className="fas fa-check"></i> : "-"}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        * 담당자
                      </label>
                      <div className="col-md-2">
                        <div>이름</div>
                        <div>{managerName}</div>
                      </div>
                      <div className="col-md-2">
                        <div>전화번호</div>
                        <div>{managerTel}</div>
                      </div>
                      <div className="col-md-2">
                        <div>이메일</div>
                        <div>{managerEmail}</div>
                      </div>
                      <label className="col-form-label col-md-2">
                        * 시간(분)
                      </label>
                      <div className="col-md-2">
                        <NumberFormat
                          value={minute}
                          suffix={"분"}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">* 정원</label>
                      <div className="col-md-6">
                        <NumberFormat
                          value={limit}
                          suffix={"명"}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </div>
                      <label className="col-form-label col-md-2">
                        * 유/무료
                      </label>
                      <div className="col-md-2">{isPay ? "유료" : "무료"}</div>
                    </div>
                    {isPay ? (
                      <div className="form-group row">
                        <label className="col-form-label col-md-2">가격</label>
                        <div className="col-md-6">
                          <NumberFormat
                            value={price}
                            suffix={"원"}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </div>
                        <label className="col-form-label col-md-2">할인</label>
                        <div className="col-md-2">
                          {isDiscount ? (
                            <NumberFormat
                              value={discountPrice}
                              suffix={"원"}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        * 유효기간
                      </label>
                      <div className="col-md-6">
                        <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                          {applyStartDateTime}
                        </ReactMoment>{" "}
                        ~{" "}
                        <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                          {applyEndDateTime}
                        </ReactMoment>
                      </div>
                      <label className="col-form-label col-md-2">
                        * 온/오프라인
                      </label>
                      <div className="col-md-2">
                        {placeType === "OffLine" && "오프라인"}
                        {placeType === "OnLine" && "온라인"}
                      </div>
                    </div>

                    {placeType === "OffLine" ? (
                      <>
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            주소
                          </label>
                          <div className="col-md-10 p-0">
                            <div className="form-group row">
                              <div className="col-md-12 p-0">
                                <KakaoMap
                                  address={`(${placeZipcode})<br /> ${placeAddress}<br /> ${placeAddressDetail}`}
                                  longitude={placeLongitude}
                                  latitude={placeLatitude}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Panel>
                <PanelHeader>티켓정보</PanelHeader>
                <PanelBody className="panel-form form-horizontal form-bordered">
                  <div className="form-group row">
                    <label className="col-form-label col-md-2">
                      * [분류]티켓명
                    </label>
                    <div className="col-md-10">
                      [
                      {ticketCategory.parentCategory
                        ? `${ticketCategory.parentCategory.name} &gt; ${ticketCategory.name}`
                        : `${ticketCategory.name}`}
                      ] {name}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-md-2">
                      * 상세정보
                    </label>
                    <div className="col-md-10">{renderHTML(contents)}</div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <TicketDate
                applyStartDateTime={applyStartDateTime}
                applyEndDateTime={applyEndDateTime}
                ticketProductDates={ticketProductDates}
                ticketProductDateTimesCount={ticketProductDateTimesCount}
                limit={limit}
              />
            </div>
          </div>

          <div className="row p-b-15">
            <div className="col-xs-4 text-left">
              <Link
                to={`/ticketConfigs/${match.params.config}/products/${commonStatus}${location.search}`}
                className="btn btn-white"
              >
                <FontAwesomeIcon icon={faList} /> 목록
              </Link>
            </div>
            <div className="col-xs-4 text-center"></div>
            <div className="col-xs-4 text-right">
              <div className="btn-group">
                <Link
                  to={`/ticketOrders?ticketProduct=${id}`}
                  className="btn btn-white text-blue"
                >
                  예매내역
                </Link>
                <Link
                  to={`/ticketConfigs/${match.params.config}/products/${commonStatus}/${match.params.id}/edit${location.search}`}
                  className="btn btn-white text-green"
                >
                  수정
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
