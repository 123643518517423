import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import { STOREORDER_LIST_QUERY } from "../Queries";
import Presenter from "./Presenter/List";
import qs from "qs";
import Moment from "moment";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { datePlus9, orderNumber } from "../../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const store = queryString.store ? queryString.store : "";
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  const page = queryString.page ? queryString.page : 1;

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (store ? "store=" + store + "&" : "") +
    (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
    (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");

  const { data, loading } = useQuery(STOREORDER_LIST_QUERY, {
    variables: {
      store,
      startSearchDate,
      endSearchDate,
      orderBy: "id_DESC",
      skip,
      first,
    },
  });
  const [ledgerListMutation] = useMutation(STOREORDER_LIST_QUERY);
  const [csvData, setCsvData] = useState([]);
  const downloadButton = useRef();

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (store ? "store=" + store + "&" : "") +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (store ? "store=" + store + "&" : "") +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (store ? "store=" + store + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const DownloadButton = () => {
    const handleDownload = async () => {
      let data = [
        [
          "가맹점",
          "캐셔",
          "주문번호",
          "거래번호",
          "금액",
          "내역정보",
          "거래일자",
        ],
      ];
      try {
        const {
          data: { AdminStoreOrderList },
        } = await ledgerListMutation({
          variables: {
            store,
            startSearchDate,
            endSearchDate,
            orderBy: "id_DESC",
          },
        });
        AdminStoreOrderList.storeOrders.forEach((storeOrder) => {
          data.push([
            `${storeOrder.store.name}`,
            `${`${storeOrder.cashier.name}(${storeOrder.cashier.userId})`}`,
            `${orderNumber(storeOrder.createdAt)}`,
            `${
              storeOrder.accountUserLedger?.createdAt
                ? orderNumber(storeOrder.accountUserLedger?.createdAt)
                : "-"
            }`,
            `${storeOrder.value}`,
            `${
              storeOrder.accountUserLedger?.title
                ? storeOrder.accountUserLedger?.title
                : "드림상점, 드림티켓에서 구입한 상품이 교환됨"
            }`,
            `${
              storeOrder.accountUserLedger?.createdAt
                ? datePlus9(storeOrder.accountUserLedger?.createdAt)
                : "-"
            }`,
          ]);
        });
        setCsvData(data);
        downloadButton.current.link.click();
      } catch (e) {
        console.log(e);
        toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
      }
    };
    return (
      <>
        <button
          className="btn btn-white btn-block mr-2 text-truncate"
          onClick={handleDownload}
        >
          <i className="fa fa-download fa-fw"></i>
        </button>
        <CSVLink
          data={csvData}
          filename={`상점결제내역.csv`}
          className="btn btn-white text-black hide"
          ref={downloadButton}
        >
          <i className="fa fa-download fa-fw"></i>
        </CSVLink>
      </>
    );
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      store={store}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      page={page}
      blockSize={blockSize}
      first={first}
      data={data}
      loading={loading}
      baseUrl={baseUrl}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
      DownloadButton={DownloadButton}
    ></Presenter>
  );
});
