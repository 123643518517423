import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_DATETIME_WRITE_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import { commonStatusArray } from "../../../../../Components/User/UserNames";

export default withRouter(
  ({
    history,
    match,
    location,

    isModal,
  }) => {
    const modalRef = React.createRef();
    const productCommonStatus = match.params.commonStatus;

    const [ticketProductDateTimeWriteMutation] = useMutation(
      TICKET_PRODUCT_DATETIME_WRITE_QUERY,
      {
        refetchQueries: () => [
          {
            query: TICKET_PRODUCT_DETAIL_QUERY,
            variables: { id: match.params.id },
          },
        ],
      }
    );

    const date = new Date();
    const toHour = date.getHours();
    const { register, handleSubmit, errors } = useForm({
      defaultValues: {
        ticketDateTime: `${toHour}:00:00`,
        commonStatus: "C",
      },
    });

    const onSubmit = async (data) => {
      try {
        const {
          data: { AdminTicketProductDateTimeWrite },
        } = await ticketProductDateTimeWriteMutation({
          variables: {
            ticketProductId: match.params.id,
            ticketProductDateId: match.params.ticketDateId,
            commonStatus: data.commonStatus,
            ticketDateTime: data.ticketDateTime,
          },
        });
        if (AdminTicketProductDateTimeWrite) {
          toast.success("처리 되었습니다.");
          history.push(
            `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/${location.search}`
          );
        }
      } catch (e) {
        console.log(e);
        var error = e.toString();
        error = error.replace("Error: GraphQL error:", "");
        toast.error(`${error}`);
      }
    };
    // console.log(errors);

    useEffect(() => {
      const element = modalRef.current;

      if (isModal) {
        disableBodyScroll(element);
      }

      return () => {
        enableBodyScroll(element);
      };
    });

    return (
      <Fragment>
        <Modal
          isOpen={true}
          size={"lg"}
          ref={modalRef}
          toggle={() => {
            history.push(
              `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/${location.search}`
            );
          }}
        >
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              toggle={() => {
                history.push(
                  `/ticketConfigs/${match.params.config}/products/${productCommonStatus}/${match.params.id}/${location.search}`
                );
              }}
            >
              일정시간등록
            </ModalHeader>
            <ModalBody>
              <div className="table-responsive">
                <table className="table table-bordered tablebasic product_op_table m-0">
                  <caption className="sr-only">
                    티켓시간생성-항목, 시간, 상태, 품절
                  </caption>
                  <thead>
                    <tr>
                      <th
                        abbr="시간"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        시간
                      </th>
                      <th
                        abbr="상태"
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        상태
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          className="form-control"
                          name="ticketDateTime"
                          ref={register()}
                        >
                          {Array.from(Array(24)).map((x, index) => {
                            return (
                              <Fragment key={index}>
                                <option
                                  value={`${
                                    index < 10 ? `0${index}` : index
                                  }:00:00`}
                                >
                                  {`${index < 10 ? `0${index}` : index}:00:00`}
                                </option>
                                <option
                                  value={`${
                                    index < 10 ? `0${index}` : index
                                  }:30:00`}
                                >
                                  {`${index < 10 ? `0${index}` : index}:30:00`}
                                </option>
                              </Fragment>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-control"
                          name="commonStatus"
                          ref={register({})}
                        >
                          <option value="">상태</option>
                          {Object.keys(commonStatusArray)
                            .filter((item) => item !== "D")
                            .map((key, index) => (
                              <option key={index} value={key}>
                                {commonStatusArray[key]}
                              </option>
                            ))}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="col-xs-12 text-center">
                <button type="submit" className="btn btn-white text-blue">
                  확인
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </Fragment>
    );
  }
);
