import React from "react";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";
import { currencyName } from "../../../../Components/User/UserNames";

export default ({
  history,
  match,
  location,
  config,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,
}) => {
  const doExcelDown = async (wallet) => {
    try {
      const params = `?` + (wallet ? "walletId=" + wallet + "&" : "");

      await fetch(`${graphqlUri}/excels/ledgers${params}`, {
        method: "POST",
      })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `납부내역.xlsx`;
          document.body.appendChild(a);
          a.click();
          setTimeout((_) => {
            window.URL.revokeObjectURL(url);
          }, 60000);
          a.remove();
        })
        .catch((err) => {
          console.error("err: ", err);
        });

      // if (res.ok) {
      //   refetch();
      //   return toast.success("커버 이미지가 업로드되었습니다.");
      // }
    } catch (e) {
      console.log(e);
      // toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <>
      <Helmet>
        <title>회비 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">회비</li>
        </ol>
        <h1 className="page-header">회비</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.AdminFeeList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(제목) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>

              {!loading && data.AdminFeeList.count > 0 ? (
                <>
                  <PanelBody>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <colgroup>
                          <col width="15%" />
                          <col width="*" />
                          <col width="15%" />
                          <col width="15%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>이미지</th>
                            <th>정보</th>
                            <th>작성일</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.AdminFeeList.fees.map((item) => {
                            const feeCoverImg =
                              item.coverImg === "" || item.coverImg === null
                                ? "/assets/img/gallery/gallery-default.jpg"
                                : `${graphqlUri}${item.coverImg}`;

                            const trustline = item.trustlines.find(
                              (item) => item.currency === "DRM"
                            );

                            return (
                              <tr key={item.id}>
                                <td className="hidden-sm">
                                  <div
                                    className="product-img"
                                    style={{
                                      backgroundImage: `url(${feeCoverImg})`,
                                    }}
                                  >
                                    <img src={feeCoverImg} alt={item.name} />
                                  </div>
                                </td>
                                <td className="valign-middle">
                                  <div>
                                    <Link
                                      to={`/fees/${item.id}/edit${location.search}`}
                                    >
                                      <h4 className="widget-list-title m-t-5">
                                        {item.title}
                                      </h4>
                                    </Link>
                                  </div>
                                  <div>
                                    출금액 :{" "}
                                    <NumberFormat
                                      value={item.feeAmount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />{" "}
                                    드림
                                  </div>
                                  {item.isDefault === "true" && (
                                    <div>
                                      체납액 :{" "}
                                      <NumberFormat
                                        value={item.feeDefault}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />{" "}
                                      드림
                                    </div>
                                  )}
                                </td>
                                <td className="text-nowrap text-center valign-middle">
                                  <div className="btn-group-vertical">
                                    <Moment format="YYYY-MM-DD HH:mm:ss">
                                      {item.createdAt}
                                    </Moment>
                                  </div>
                                </td>
                                <td className="text-nowrap text-center valign-middle">
                                  <button
                                    className="btn btn-white btn-xs text-green"
                                    onClick={() => doExcelDown(item.wallet.id)}
                                  >
                                    다운
                                  </button>{" "}
                                  <Link
                                    to={`/fees/${item.id}/users${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    회원
                                  </Link>{" "}
                                  <Link
                                    to={`/fees/${item.id}/trustlines/${trustline.id}${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    내역
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </PanelBody>
                  <PanelFooter>
                    <div className="row">
                      <div className="col-md-6">
                        <Pagination
                          totalRecord={data.AdminFeeList.count}
                          blockSize={blockSize}
                          pageSize={first}
                          currentPage={page}
                          baseUrl={baseUrl}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </PanelFooter>
                </>
              ) : (
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              )}
            </Panel>
          </div>
        </div>
      </div>
      <Link
        to={`/fees/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
