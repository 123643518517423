import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_EDIT_LOG_LIST_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  console.log({ history, match, location });

  let baseUrl = "";
  let editType = "";
  if (match.params.editType === "deliveryProducts") {
    baseUrl = "/deliveryProducts";
    editType = "DeliveryProduct";
  }
  if (match.params.editType === "deliverySets") {
    baseUrl = "/deliverySets";
    editType = "DeliverySet";
  }
  const editTypeId = match.params.id;
  const take = 100;
  const skip = 0;
  const { data, loading, error } = useQuery(
    ADMIN_DELIVERY_EDIT_LOG_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { editType, editTypeId, take, skip },
    }
  );

  // if (loading) {
  //   return <></>;
  // }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
