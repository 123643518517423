import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { INQUIRY_CATEGORY_LIST_QUERY } from "../../../Queries";
// import { validate } from "graphql";

export default ({ history, match, location, config }) => {
  const { data, loading, refetch } = useQuery(INQUIRY_CATEGORY_LIST_QUERY, {
    variables: {
      inquiryConfig: config.AdminInquiryConfig.id,
      commonApp: match.params.CommonApp
    }
  });
  useEffect(() => {
    refetch();
  });

  return (
    <div className="btn-group">
      <Link
        className={`btn btn-white m-b-5 ${
          !match.params.inquiryCategories ? "active" : ""
        }
        `}
        to={`/inquiryConfigs/${match.params.CommonApp}/${config.AdminInquiryConfig.id}/inquiries/categories${location.search}`}
      >
        <i className={"fas fa-th"} /> 전체
      </Link>

      {!loading &&
        data.AdminInquiryCategoryList.count > 0 &&
        data.AdminInquiryCategoryList.inquiryCategories.map(category => {
          return (
            <Link
              key={category.id}
              className={`btn btn-white m-b-5 ${
                category.id === match.params.inquiryCategories ? "active" : ""
              }
              `}
              to={`/inquiryConfigs/${match.params.CommonApp}/${config.AdminInquiryConfig.id}/inquiries/categories/${category.id}${location.search}`}
            >
              <i className={category.pcIcon} />
              {" " + category.name}
            </Link>
          );
        })}

      <Link
        className={"btn btn-white m-r-5 m-b-5 "}
        to={`/inquiryConfigs/${match.params.CommonApp}/${config.AdminInquiryConfig.id}/categories${location.search}`}
      >
        <i className={"fas fa-cogs"} />
      </Link>
    </div>
  );
};
