import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { commonStatusName } from "../../../../../../Components/User/UserNames";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_PRODUCT_CATEGORY_WRITE_MUTATION,
  STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
} from "../../Queries";
import Swal from "sweetalert2";

const Write = withRouter(({ history, match, location, isModal }) => {
  const baseUrl = "/delivery/deliveryProducts/categories";
  const modalRef = React.createRef();

  const { control, register, handleSubmit, errors } = useForm({
    defalutValues: {
      commonStatus: "",
      iconImage: "",
      name: "",
    },
  });

  const [
    StoreDeliveryProductCategoryWriteMutation,
    { loading: StoreDeliveryProductCategoryWriteLoading },
  ] = useMutation(STORE_DELIVERY_PRODUCT_CATEGORY_WRITE_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY,
        variables: { take: 100, skip: 0 },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (StoreDeliveryProductCategoryWriteLoading) {
        return;
      }

      const {
        data: { StoreDeliveryProductCategoryWrite },
      } = await StoreDeliveryProductCategoryWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          iconImage: data.iconImage,
          name: data.name,
        },
      });

      if (StoreDeliveryProductCategoryWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const [iconImage, setIconImage] = useState(
    "/assets/img/category-default.png"
  );
  const handleIconImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setIconImage(reader.result);
    };
  };
  const onClickIcon = () => {
    document.getElementById("iconImage").click();
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`${baseUrl}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`${baseUrl}${location.search}`);
            }}
          >
            분류 등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <Controller
                  control={control}
                  name="commonStatus"
                  rules={{ required: "상태를 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-10">
                        <div>
                          <div className="radio radio-css radio-inline">
                            <input
                              type="radio"
                              id="commonStatus1"
                              name="commonStatus"
                              onClick={onChange}
                              onBlur={onBlur}
                              value={"S"}
                              checked={value === "S"}
                            />
                            <label htmlFor="commonStatus1">
                              {commonStatusName("S")}
                            </label>
                          </div>
                          <div className="radio radio-css radio-inline">
                            <input
                              type="radio"
                              id="commonStatus2"
                              name="commonStatus"
                              onClick={onChange}
                              onBlur={onBlur}
                              value={"C"}
                              checked={value === "C"}
                            />
                            <label htmlFor="commonStatus2">
                              {commonStatusName("C")}
                            </label>
                          </div>
                        </div>
                        {errors.commonStatus && (
                          <span className="text-red errorText">
                            {errors.commonStatus.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="iconImage"
                  rules={{ required: "아이콘을 선택해주세요." }}
                  render={({ onChange }) => (
                    <div className="form-group row">
                      <label
                        className="col-form-label col-md-2"
                        htmlFor="iconImage"
                      >
                        아이콘
                      </label>
                      <div className="col-md-10">
                        <input
                          type="file"
                          id="iconImage"
                          className="hide"
                          onChange={(e) => {
                            handleIconImage(e);
                            onChange(e.target.files[0]);
                          }}
                          accept="image/*"
                        />
                        <img
                          src={iconImage}
                          alt="이미지 업로드"
                          className="height-50"
                          style={{ borderRadius: 10, overflow: "hidden" }}
                          onClick={onClickIcon}
                        />
                        <div className="alert alert-primary fade show m-t-10 m-b-0">
                          이미지 클릭시 업로드가 가능합니다.
                          <br />
                          <b>이미지 사이즈 : </b> 넓이 300px X 높이 300px
                        </div>
                        {errors.iconImage && (
                          <span className="text-red errorText">
                            {errors.iconImage.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "이름을 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">이름</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="이름을 입력해주세요."
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ""}
                        />
                        {errors.name && (
                          <span className="text-red errorText">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});

export default Write;
