import React from "react";
import { useMutation } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_ASYNC_SELECT_MUTATION } from "../Queries";
import AsyncSelect from "react-select/async";

const DeliveryStoreAsyncSelect = ({ onChange, value }) => {
  const [AdminDeliveryStoreAsyncSelectMutation] = useMutation(
    ADMIN_DELIVERY_STORE_ASYNC_SELECT_MUTATION
  );

  const loadOptions = async (inputValue) => {
    const {
      data: { AdminDeliveryStoreAsyncSelect },
    } = await AdminDeliveryStoreAsyncSelectMutation({
      variables: {
        take: 20,
        skip: 0,
        keyword: inputValue,
      },
    });

    if (AdminDeliveryStoreAsyncSelect.count > 0) {
      return AdminDeliveryStoreAsyncSelect?.deliveryStores.map((item) => ({
        value: item.id,
        label: `${item.store.name} > ${item.user.name}`,
      }));
    }
  };

  return (
    <AsyncSelect
      placeholder="사용자(이름), 가맹점(이름)을 검색해주세요."
      noOptionsMessage={() => {
        return "사용자(이름), 가맹점(이름)을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onChange={onChange}
      defaultValue={value ? value : null}
      cacheOptions
    />
  );
};

export default DeliveryStoreAsyncSelect;
