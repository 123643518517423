import React, { forwardRef, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { DISCOUNT_WRITE_QUERY } from "./Queries";
import { useForm } from "react-hook-form";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "../../../Containers/SelectedPeriod/DatePicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

import WriteStore from "./WriteStore";

export default withRouter(({ history, match, location }) => {
  const [discountWriteMutation] = useMutation(DISCOUNT_WRITE_QUERY);
  const { register, handleSubmit, errors } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [store, setStore] = useState("");
  const [discountCoverImage, setDiscountCoverImage] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const [discountDetailImage, setDiscountDetailImage] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const handleDiscountCoverImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setDiscountCoverImage(reader.result);
    };
  };
  const handleDiscountDetailImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setDiscountDetailImage(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImage").click();
  };
  const onClick_detail = () => {
    document.getElementById("detailImage").click();
  };

  const onSubmit = async (data) => {
    if (store === "") {
      toast.error("가맹점명을 입력해주세요.");
      return;
    }

    try {
      const {
        data: { AdminDiscountWrite },
      } = await discountWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          startDate,
          endDate,
          day: data.day,
          coverImage: data.coverImage[0],
          detailImage: data.detailImage[0],
          title: data.title,
          contents: data.contents,
          store: store && store.value,
        },
      });
      if (AdminDiscountWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/discounts${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="btn btn-white" onClick={onClick}>
      {value}
      <i className="fa fa-calendar fa-fw text-black-transparent-5 m-l-5"></i>
    </button>
  ));

  return (
    <>
      <Helmet>
        <title>오늘의 할인 관리 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/stores">가맹점</Link>
            </li>
            <li className="breadcrumb-item active">오늘의 할인</li>
          </ol>
          <h1 className="page-header">오늘의 할인</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/discounts${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader>커버 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={discountCoverImage}
                            alt="이미지 업로드"
                            className="height-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImage"
                          name="coverImage"
                          className="hide"
                          onChange={handleDiscountCoverImage}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          커버 이미지 업로드
                        </button>
                      </div>
                      {errors.coverImage && (
                        <span className="text-danger">
                          {errors.coverImage.message}
                        </span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b>커버 사이즈 : 넓이 600px X 높이 600px </b>
                        <br />
                        이미지는 뒤에 배경이없는 <b>.png</b>으로 업로드해주세요.
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
              <Panel>
                <PanelHeader>상세 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={discountDetailImage}
                            alt="이미지 업로드"
                            className="width-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="detailImage"
                          name="detailImage"
                          className="hide"
                          onChange={handleDiscountDetailImage}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_detail}
                        >
                          상세 이미지 업로드
                        </button>
                      </div>
                      {errors.detailImage && (
                        <span className="text-danger">
                          {errors.detailImage.message}
                        </span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <b>상세 이미지 사이즈 : 넓이 600px X 높이 600px </b>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-9 col-sm-12">
              <Panel>
                <PanelHeader>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-10">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            className="form-control"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            className="form-control"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div>{errors.commonStatus.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">기간</label>
                      <div className="col-md-10">
                        <div className="input-group">
                          <DatePicker
                            name="startDate"
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day text-white">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={Date.parse(startDate)}
                            onChange={(date) => setStartDate(date)}
                          />
                          <span className="custom-react-datepicker__split-span">
                            ~
                          </span>
                          <DatePicker
                            name="endDate"
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                              date,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                              decreaseMonth,
                              increaseMonth,
                            }) => (
                              <div className="custom-react-datepicker__select-wrapper">
                                <button
                                  type="button"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-left fa-lg"></i>
                                </button>
                                <div className="month-day text-white">
                                  {getYear(date)}년 {getMonth(date) + 1}월
                                </div>
                                <button
                                  type="button"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <i className="fa fa-angle-right fa-lg"></i>
                                </button>
                              </div>
                            )}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            selected={Date.parse(endDate)}
                            onChange={(date) => setEndDate(date)}
                          />
                        </div>
                        {errors.startDate && (
                          <div>{errors.startDate.message}</div>
                        )}
                        {errors.endDate && <div>{errors.endDate.message}</div>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">요일</label>
                      <div className="col-md-10">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day7"
                            name="day"
                            className="form-control"
                            value="7"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day7`}>항상</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day0"
                            name="day"
                            className="form-control"
                            value="0"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day0`}>일요일</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day1"
                            name="day"
                            className="form-control"
                            value="1"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day1`}>월요일</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day2"
                            name="day"
                            className="form-control"
                            value="2"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day2`}>화요일</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day3"
                            name="day"
                            className="form-control"
                            value="3"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day3`}>수요일</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day4"
                            name="day"
                            className="form-control"
                            value="4"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day4`}>목요일</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day5"
                            name="day"
                            className="form-control"
                            value="5"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day5`}>금요일</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="day6"
                            name="day"
                            className="form-control"
                            value="6"
                            ref={register({
                              required: "할인요일를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`day6`}>토요일</label>
                        </div>
                        {errors.day && <div>{errors.day.message}</div>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">가맹점</label>
                      <div className="col-md-10">
                        <WriteStore
                          history={history}
                          match={match}
                          location={location}
                          store={store}
                          setStore={setStore}
                        />
                        {errors.store && <span>{errors.store.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">
                        <input
                          name="title"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <span>{errors.title.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">내용</label>
                      <div className="col-xs-10">
                        <textarea
                          type="text"
                          name="contents"
                          className="form-control m-b-5"
                          placeholder="내용입력"
                          ref={register({
                            required: "내용을 입력해주세요.",
                          })}
                          rows="20"
                        ></textarea>
                        {errors.contents && (
                          <span className="text-danger">
                            {errors.contents.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/discounts${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
