import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_CATEGORY_LIST_QUERY } from "../../Queries";
import qs from "qs";

export default ({
  history,
  match,
  location,
  startSearchDate,
  endSearchDate,
  keyword,
}) => {
  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword };

  const { data, loading } = useQuery(STORE_CATEGORY_LIST_QUERY, {
    fetchPolicy: "network-only",
  });

  return (
    <div className="">
      <Link
        className={`btn btn-white m-b-5 ${!queryString.category ? "active" : ""}
        `}
        to={`/stores?${qs.stringify({ ...query })}`}
      >
        <i className={"fas fa-th"} /> 전체
      </Link>

      {!loading &&
        data.AdminStoreCategoryList.count > 0 &&
        data.AdminStoreCategoryList.storeCategories.map((category) => {
          const ledgersUrl =
            "?" +
            (category.id ? "category=" + category.id + "&" : "") +
            (startSearchDate
              ? "startSearchDate=" + startSearchDate + "&"
              : "") +
            (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "") +
            (keyword ? "keyword=" + keyword + "&" : "");
          return (
            <Link
              key={category.id}
              className={`btn btn-white m-l-5 m-b-5 ${
                category.id === queryString.category ? "active" : ""
              }
              `}
              to={`/stores${ledgersUrl}`}
            >
              <i className={category.pcIcon} />
              {" " + category.name}({category.storesCount})
            </Link>
          );
        })}

      <Link
        className={"btn btn-white m-l-5 m-b-5 "}
        to={`/stores/categories${location.search}`}
      >
        <i className={"fas fa-cog"} />
      </Link>
    </div>
  );
};
