import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  STORE_CATEGORY_LIST_QUERY,
  STORE_CATEGORY_PREVMOVE_QUERY,
  STORE_CATEGORY_NEXTMOVE_QUERY,
  STORE_CATEGORY_DELETE_QUERY,
} from "../../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data, loading, refetch } = useQuery(STORE_CATEGORY_LIST_QUERY, {
    fetchPolicy: "network-only",
  });

  const [storeCategoryPrevMoveMutation] = useMutation(
    STORE_CATEGORY_PREVMOVE_QUERY,
    {
      refetchQueries: () => [
        {
          query: STORE_CATEGORY_LIST_QUERY,
        },
      ],
    }
  );
  const [storeCategoryNextMoveMutation] = useMutation(
    STORE_CATEGORY_NEXTMOVE_QUERY,
    {
      refetchQueries: () => [
        {
          query: STORE_CATEGORY_LIST_QUERY,
        },
      ],
    }
  );

  const [storeCategoryDeleteMutation] = useMutation(
    STORE_CATEGORY_DELETE_QUERY
  );

  const onDelete = async (data) => {
    try {
      const {
        data: { AdminStoreCategoryDelete },
      } = await storeCategoryDeleteMutation({
        variables: {
          id: data.id,
        },
      });
      if (AdminStoreCategoryDelete) {
        refetch();
        toast.success("처리 되었습니다.");
        history.replace(`/stores/categories${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const prevMove = async (id) => {
    try {
      const {
        data: { AdminStoreCategoryPrevMove },
      } = await storeCategoryPrevMoveMutation({
        variables: {
          id,
        },
      });
      if (AdminStoreCategoryPrevMove) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/categories${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  const nextMove = async (id) => {
    try {
      const {
        data: { AdminStoreCategoryNextMove },
      } = await storeCategoryNextMoveMutation({
        variables: {
          id,
        },
      });
      if (AdminStoreCategoryNextMove) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/categories${location.search}`);
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    // refetch();
  });

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      data={data}
      loading={loading}
      onDelete={onDelete}
      prevMove={prevMove}
      nextMove={nextMove}
    ></Presenter>
  );
});
