import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { PUSH_LIST_QUERY } from "../Queries";
import useInput from "../../../../Hooks/useInput";
import Presenter from "./Presenter";
import qs from "qs";
import Moment from "moment";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const pushType = queryString.pushType;
  var page = queryString.page ? queryString.page : 1;
  const startSearchDate = queryString.startSearchDate
    ? queryString.startSearchDate
    : "2020-09-01";
  const endSearchDate = queryString.endSearchDate
    ? queryString.endSearchDate
    : Moment().format("YYYY-MM-DD");
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);

  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" +
    (pushType ? "pushType=" + pushType + "&" : "") +
    (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading } = useQuery(PUSH_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      pushType,
      startSearchDate,
      endSearchDate,
      keyword,
      skip,
      first,
    },
  });

  const onStartDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (pushType ? "pushType=" + pushType + "&" : "") +
      (date ? "startSearchDate=" + date + "&" : "") +
      (endSearchDate ? "endSearchDate=" + endSearchDate + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onEndDateSelect = (date) => {
    var date = Moment(date).format("YYYY-MM-DD");

    const uri =
      "?" +
      (pushType ? "pushType=" + pushType + "&" : "") +
      (startSearchDate ? "startSearchDate=" + startSearchDate + "&" : "") +
      (date ? "endSearchDate=" + date + "&" : "");

    history.push(`${uri}page=1`);
  };
  const onPeriodConfig = (e) => {
    var startDate = null;
    var endDate = null;
    if (e.target.value === "ALL") {
    }
    if (e.target.value === "1W") {
      startDate = Moment().subtract(1, "w").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "1M") {
      startDate = Moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "3M") {
      startDate = Moment().subtract(3, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }
    if (e.target.value === "6M") {
      startDate = Moment().subtract(6, "M").format("YYYY-MM-DD");
      endDate = Moment().format("YYYY-MM-DD");
    }

    const uri =
      "?" +
      (pushType ? "pushType=" + pushType + "&" : "") +
      (startDate ? "startSearchDate=" + startDate + "&" : "") +
      (endDate ? "endSearchDate=" + endDate + "&" : "");

    history.push(`${uri}page=1`);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push("/pushes" + baseUrl + "page=1");
  };

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      pushType={pushType}
      startSearchDate={startSearchDate}
      endSearchDate={endSearchDate}
      keyword={keyword}
      keywordInput={keywordInput}
      page={page}
      blockSize={blockSize}
      first={first}
      baseUrl={baseUrl}
      data={data}
      loading={loading}
      onStartDateSelect={onStartDateSelect}
      onEndDateSelect={onEndDateSelect}
      onPeriodConfig={onPeriodConfig}
      handleSearch={handleSearch}
    ></Presenter>
  );
});
