import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_CATEGORY_LIST_QUERY } from "../../../Queries";
import { InputGroup, InputGroupAddon } from "reactstrap";
import qs from "qs";

export default ({ history, match, location, config }) => {
  const productCommonStatus = match.params.commonStatus;

  const queryString = qs.parse(location.search.substr(1));
  const keyword = queryString.keyword ? queryString.keyword : "";
  const category = queryString.category ? queryString.category : "";

  const { data, loading } = useQuery(TICKET_CATEGORY_LIST_QUERY, {
    variables: {
      ticketConfig: config.id,
      parentId: "null",
    },
  });

  return (
    <InputGroup>
      {!loading && (
        <select
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            const baseUrl =
              "?" +
              (keyword ? "keyword=" + keyword + "&" : "") +
              (e.target.value ? "category=" + e.target.value + "&" : "");

            history.push(
              `/ticketConfigs/${config.id}/products/${productCommonStatus}${baseUrl}page=1`
            );
          }}
          defaultValue={category}
        >
          <option value={""}>전체분류</option>
          {data.AdminTicketCategoryList.ticketCategories.map(
            (depth1, index1) => {
              return (
                <Fragment key={index1}>
                  {depth1.childrenCount === 0 && (
                    <option value={depth1.id}>{depth1.name}</option>
                  )}
                  {depth1.childrenCount > 0 &&
                    depth1.children.map((depth2, index2) => {
                      return (
                        <Fragment key={index2}>
                          {depth2.childrenCount === 0 && (
                            <option value={depth2.id}>
                              {depth1.name} &gt; {depth2.name}
                            </option>
                          )}
                          {depth2.childrenCount > 0 &&
                            depth2.children.map((depth3, index3) => {
                              return (
                                depth3.childrenCount === 0 && (
                                  <option key={index3} value={depth3.id}>
                                    {depth1.name} &gt; {depth2.name} &gt;{" "}
                                    {depth3.name}
                                  </option>
                                )
                              );
                            })}
                        </Fragment>
                      );
                    })}
                </Fragment>
              );
            }
          )}
        </select>
      )}

      <InputGroupAddon addonType="append">
        <Link
          className={"btn btn-white"}
          to={`/ticketConfigs/${config.id}/categories${location.search}`}
        >
          <i className={"fas fa-cogs"} />
        </Link>
      </InputGroupAddon>
    </InputGroup>
  );
};
