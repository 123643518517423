import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import {
  TICKET_PRODUCT_LIST_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../TicketConfig/Queries";
import AsyncSelect from "react-select/async";

const DefaultValue = (ticketProduct) => {
  const { data, loading } = useQuery(TICKET_PRODUCT_DETAIL_QUERY, {
    variables: { id: ticketProduct },
  });
  if (ticketProduct) {
    if (!loading) {
      const {
        StoreTicketProductDetail: { id, name },
      } = data;
      return {
        value: id,
        label: name,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default withRouter(
  ({
    history,
    match,
    location,

    ticketProduct,
  }) => {
    const [keyword, setKeyword] = useState("");
    const { data, loading } = useQuery(TICKET_PRODUCT_LIST_QUERY, {
      variables: { keyword, skip: 0, first: 10 },
    });

    const loadOptions = (inputValue, callback) => {
      if (!loading) {
        var options = data.StoreTicketProductList.ticketProducts.map((item) => {
          return {
            value: item.id,
            label: `${item.name}`,
          };
        });

        callback(options);
      }
    };
    const handleInputChange = (value) => {
      setTimeout(() => {
        setKeyword(value);
        return value;
      }, 500);
    };
    return (
      <div className="col-xs-12 select">
        <AsyncSelect
          isClearable
          placeholder="상품명 입력"
          noOptionsMessage={() => {
            return "상품명을 검색해주세요.";
          }}
          loadOptions={loadOptions}
          onInputChange={handleInputChange}
          onChange={(options) => {
            const uri =
              "/ticket/ticketOrders?" +
              (options ? "ticketProduct=" + options.value + "&" : "");
            history.push(`${uri}`);
          }}
          defaultValue={DefaultValue(ticketProduct)}
        />
      </div>
    );
  }
);
