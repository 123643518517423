import React from "react";
import { useQuery } from "react-apollo-hooks";
import { ADMIN_DELIVERY_STORE_DONG_SELECT_QUERY } from "../Queries";
import Select from "react-select";

const DeliveryStoreDongMultiSelect = ({
  onChange,
  value,
  deliveryStore_id,
}) => {
  const skip = 0;
  const take = 100;

  const { data, error, loading } = useQuery(
    ADMIN_DELIVERY_STORE_DONG_SELECT_QUERY,
    { variables: { deliveryStore_id, skip, take } }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  const options = data.AdminDeliveryStoreDongSelect.deliveryStoreDongs.map(
    (item) => {
      return {
        value: item.id,
        label: `${item.sido} ${item.sigungu} ${item.upmyundong} ${item.ri}`,
      };
    }
  );

  return (
    <Select
      placeholder="배달가능 동을 선택해주세요."
      noOptionsMessage={() => {
        return "배달가능 동을 선택해주세요.";
      }}
      options={options}
      onChange={onChange}
      defaultValue={value !== undefined ? value : null}
      isMulti
      isClearable
      cacheOptions={false}
      closeMenuOnSelect={false}
    />
  );
};

export default DeliveryStoreDongMultiSelect;
