import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { EXCHANGEREQUEST_CONFIG_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(EXCHANGEREQUEST_CONFIG_QUERY);

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else {
    const {
      AdminExchangeRequestConfig: { id, name, manager }
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        name={name}
        manager={manager}
      ></Presenter>
    );
  }
});
