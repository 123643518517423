import { gql } from "apollo-boost";

/* 이벤트 설정 */
export const HELP_CONFIG_QUERY = gql`
  query AdminHelpConfig($id: String) {
    AdminHelpConfig(id: $id) {
      id
      name
      listCount
      pageCount
    }
  }
`;

export const HELP_CONFIG_WRITE_QUERY = gql`
  mutation AdminHelpConfigWrite(
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminHelpConfigWrite(
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

export const HELP_CONFIG_EDIT_QUERY = gql`
  mutation AdminHelpConfigEdit(
    $id: String
    $name: String!
    $listCount: String!
    $pageCount: String!
  ) {
    AdminHelpConfigEdit(
      id: $id
      name: $name
      listCount: $listCount
      pageCount: $pageCount
    )
  }
`;

/* 이벤트 분류 */
export const HELP_CATEGORY_LIST_QUERY = gql`
  query AdminHelpCategoryList(
    $helpConfig: String
    $commonApp: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminHelpCategoryList(
      helpConfig: $helpConfig
      commonApp: $commonApp
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      helpCategories {
        id
        commonApp
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const HELP_CATEGORY_DETAIL_QUERY = gql`
  query AdminHelpCategoryDetail($id: String!) {
    AdminHelpCategoryDetail(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const HELP_CATEGORY_WRITE_QUERY = gql`
  mutation AdminHelpCategoryWrite(
    $helpConfig: String!
    $name: String!
    $commonApp: String!
  ) {
    AdminHelpCategoryWrite(
      helpConfig: $helpConfig
      name: $name
      commonApp: $commonApp
    )
  }
`;

export const HELP_CATEGORY_EDIT_QUERY = gql`
  mutation AdminHelpCategoryEdit(
    $helpConfig: String
    $id: String!
    $name: String
  ) {
    AdminHelpCategoryEdit(helpConfig: $helpConfig, id: $id, name: $name)
  }
`;

export const HELP_CATEGORY_DELETE_QUERY = gql`
  mutation AdminHelpCategoryDelete($id: String!) {
    AdminHelpCategoryDelete(id: $id)
  }
`;

/* 이벤트 */
export const HELP_LIST_QUERY = gql`
  query AdminHelpList(
    $commonApp: String
    $helpConfig: String
    $helpCategory: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminHelpList(
      commonApp: $commonApp
      helpConfig: $helpConfig
      helpCategory: $helpCategory
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      helps {
        id
        helpCategory {
          id
          name
        }
        question
        answer
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const HELP_WRITE_QUERY = gql`
  mutation AdminHelpWrite(
    $commonApp: String
    $helpConfig: String
    $helpCategory: String
    $question: String
    $answer: String
  ) {
    AdminHelpWrite(
      commonApp: $commonApp
      helpConfig: $helpConfig
      helpCategory: $helpCategory
      question: $question
      answer: $answer
    )
  }
`;

export const HELP_DETAIL_QUERY = gql`
  query AdminHelpDetail($id: String!) {
    AdminHelpDetail(id: $id) {
      id
      question
      answer
      createdAt
      helpCategory {
        id
        name
      }
    }
  }
`;

export const HELP_EDIT_QUERY = gql`
  mutation AdminHelpEdit(
    $id: String
    $helpCategory: String
    $question: String
    $answer: String
  ) {
    AdminHelpEdit(
      id: $id
      helpCategory: $helpCategory
      question: $question
      answer: $answer
    )
  }
`;

export const HELP_DELETE_QUERY = gql`
  mutation AdminHelpDelete($id: String!) {
    AdminHelpDelete(id: $id)
  }
`;
