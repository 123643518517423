import { gql } from "apollo-boost";

export const TICKET_ORDERPRODUCT_LIST_QUERY = gql`
  query StoreTicketOrderProductList(
    $ticketProduct: String
    $ticketProductDate: String
    $ticketProductDateTime: String
    $status: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    StoreTicketOrderProductList(
      ticketProduct: $ticketProduct
      ticketProductDate: $ticketProductDate
      ticketProductDateTime: $ticketProductDateTime
      status: $status
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketOrderProducts {
        id
        commonStatus

        isReservation
        isMember
        user {
          id
          avatar
          userId
          name
        }
        name
        phone

        isDiscount
        discountPrice
        price
        count
        totalPrice

        ticketProduct {
          id
          commonStatus

          coverImage

          store {
            id
            name
          }

          managerName
          managerTel
          managerEmail

          minute
          limit
          isPay

          price
          isDiscount
          discountPrice
          totalPrice
          orderCount

          applyStartDateTime
          applyEndDateTime
          placeType

          placeZipcode
          placeAddress
          placeAddressDetail
          placeLongitude
          placeLatitude

          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }

          name
          contents
        }
        ticketOrder {
          id

          isDiscount
          discountPrice
          price
          count
          totalPrice

          message
          user {
            id
            role
            avatar
            userId
            name
          }
        }
        ticketOrderProductPeoples {
          id
          commonStatus
          name
          phone

          createdAt
          updatedAt
        }

        ticketProductDate {
          id
          commonStatus
          ticketDate
        }
        ticketProductDateTime {
          id
          commonStatus
          isSoldOut
          ticketDateTime
        }

        isRefund
        meRefund

        usedAt
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const TICKET_ORDERPRODUCT_DETAIL_QUERY = gql`
  query StoreTicketOrderProductDetail($id: String!) {
    StoreTicketOrderProductDetail(id: $id) {
      id
      commonStatus

      isReservation
      isMember
      user {
        id
        role
        avatar
        userId
        name
      }
      name
      phone

      isDiscount
      discountPrice
      price
      count
      totalPrice

      ticketProduct {
        id
        ticketConfig {
          id
        }
        commonStatus

        coverImage

        store {
          id
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice
        totalPrice
        orderCount

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }

        name
        contents
      }
      ticketOrder {
        id

        isDiscount
        discountPrice
        price
        count
        totalPrice

        message
        user {
          id
          role
          avatar
          userId
          name
        }
      }
      ticketOrderProductPeoples {
        id
        commonStatus
        name
        phone

        createdAt
        updatedAt
      }

      ticketProductDate {
        id
        commonStatus
        ticketDate
      }
      ticketProductDateTime {
        id
        commonStatus
        isSoldOut
        ticketDateTime
      }

      isRefund
      meRefund

      usedAt
      createdAt
      updatedAt
    }
  }
`;

export const TICKET_ORDERPRODUCT_EXTENSION_QUERY = gql`
  mutation StoreTicketOrderProductExtension($id: String!) {
    StoreTicketOrderProductExtension(id: $id)
  }
`;

export const TICKET_ORDERPRODUCT_REFUND_QUERY = gql`
  mutation StoreTicketOrderProductRefund($id: String!) {
    StoreTicketOrderProductRefund(id: $id)
  }
`;

export const TICKET_ORDERPRODUCT_USED_QUERY = gql`
  mutation StoreTicketOrderProductUsed($id: String!) {
    StoreTicketOrderProductUsed(id: $id)
  }
`;

export const USER_LIST_QUERY = gql`
  query StoreUserList(
    $role: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    StoreUserList(
      role: $role
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        avatar
        userId
        name
      }
      count
    }
  }
`;

export const TICKET_ORDERPRODUCT_SEND_QUERY = gql`
  mutation StoreTicketOrderProductSend($id: String!, $user: String!) {
    StoreTicketOrderProductSend(id: $id, user: $user)
  }
`;
