import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import DaumPostcode from "react-daum-postcode";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_ADDRESS_WRITE_MUTATION,
  STORE_DELIVERY_ADDRESS_LIST_QUERY,
} from "../../Queries";
import Swal from "sweetalert2";

const Write = withRouter(({ history, match, location, isModal }) => {
  const id = match.params.id;
  const baseUrl = `/delivery/deliveryBoxes/${id}`;
  const modalRef = React.createRef();

  const { control, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: "",
      zipcode: "",
      jibunAddress: "",
      roadAddress: "",
      addressDetail: "",
      longitude: "",
      latitude: "",
      dayOfWeek: "",
      sido: "",
      sigungu: "",
      upmyundong: "",
      li: "",
    },
  });

  const [
    StoreDeliveryAddressWriteMutation,
    { loading: StoreDeliveryAddressWriteLoading },
  ] = useMutation(STORE_DELIVERY_ADDRESS_WRITE_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_ADDRESS_LIST_QUERY,
        variables: { take: 100, skip: 0 },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (StoreDeliveryAddressWriteLoading) {
        return;
      }

      const {
        data: { StoreDeliveryAddressWrite },
      } = await StoreDeliveryAddressWriteMutation({
        variables: {
          name: data.name,
          zipcode: data.zipcode,
          jibunAddress: data.jibunAddress,
          roadAddress: data.roadAddress,
          addressDetail: data.addressDetail,
          longitude: data.longitude,
          latitude: data.latitude,
          dayOfWeek: data.dayOfWeek,
          sido: data.sido,
          sigungu: data.sigungu,
          upmyundong: data.upmyundong,
          li: data.li,
          deliveryBox_id: id,
        },
      });

      if (StoreDeliveryAddressWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}/addresses${location.search}`);
          }
        });
      }
    } catch (e) {
      var error = e.toString().replace("Error: GraphQL error:", "");
      console.log(error);

      Swal.fire({
        icon: "error",
        title: "오류",
        html: error,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const [zipcodeModal, setZipcodeModal] = useState(false);
  const handleZipcodeModal = () => {
    setZipcodeModal(!zipcodeModal);
  };
  const handleAddress = (data) => {
    const API_KEY = "7da7d093496c67033d3e14aa3102e838";
    const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&size=15`;
    fetch(url, {
      headers: {
        Authorization: `KakaoAK ${API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log({ data });
        console.log({ json });
        setValue("zipcode", data.zonecode);
        setValue("roadAddress", data.roadAddress);
        setValue("jibunAddress", data.jibunAddress);

        setValue("longitude", json.documents[0].x);
        setValue("latitude", json.documents[0].y);

        setValue("sido", json.documents[0].road_address.region_1depth_name);
        setValue("sigungu", json.documents[0].road_address.region_2depth_name);
        setValue(
          "upmyundong",
          json.documents[0].road_address.region_3depth_name
        );

        handleZipcodeModal();
      });
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`${baseUrl}${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`${baseUrl}${location.search}`);
            }}
          >
            주소 추가
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "배송지명을 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        배송지명
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="배송지명을 입력해주세요."
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ""}
                        />
                        {errors.name && (
                          <span className="text-red errorText">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="zipcode"
                  rules={{ required: "주소를 검색해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        우편번호
                      </label>
                      <div className="col-md-10">
                        <InputGroup>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="주소를 검색해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            readOnly
                            onClick={handleZipcodeModal}
                          />
                          <InputGroupAddon addonType="append">
                            <button
                              type="button"
                              className="btn btn-white mr-2 text-truncate m-b-5"
                              onClick={handleZipcodeModal}
                            >
                              <i className="fa fa-search fa-fw"></i>
                            </button>
                          </InputGroupAddon>
                        </InputGroup>
                        {errors.zipcode && (
                          <span className="text-red errorText">
                            {errors.zipcode.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="roadAddress"
                  rules={{ required: "주소를 검색해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        도로명주소
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="주소를 검색해주세요."
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ""}
                          readOnly
                          onClick={handleZipcodeModal}
                        />
                        {errors.roadAddress && (
                          <span className="text-red errorText">
                            {errors.roadAddress.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="jibunAddress"
                  rules={{ required: "주소를 검색해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        지번주소
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="주소를 검색해주세요."
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ""}
                          readOnly
                          onClick={handleZipcodeModal}
                        />
                        {errors.jibunAddress && (
                          <span className="text-red errorText">
                            {errors.jibunAddress.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />

                <div className="form-group row">
                  <Controller
                    control={control}
                    name="longitude"
                    rules={{ required: "주소를 검색해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <label className="col-form-label col-md-2">경도</label>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="주소를 검색해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            readOnly
                            onClick={handleZipcodeModal}
                          />
                          {errors.longitude && (
                            <span className="text-red errorText">
                              {errors.longitude.message}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    name="latitude"
                    rules={{ required: "주소를 검색해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <label className="col-form-label col-md-2">위도</label>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="주소를 검색해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            readOnly
                            onClick={handleZipcodeModal}
                          />
                          {errors.latitude && (
                            <span className="text-red errorText">
                              {errors.latitude.message}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  />
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-md-2">구역</label>
                  <Controller
                    control={control}
                    name="sido"
                    rules={{ required: "주소를 검색해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="주소를 검색해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            readOnly
                            onClick={handleZipcodeModal}
                          />
                          {errors.sido && (
                            <span className="text-red errorText">
                              {errors.sido.message}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    name="sigungu"
                    rules={{ required: "주소를 검색해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="주소를 검색해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            readOnly
                            onClick={handleZipcodeModal}
                          />
                          {errors.sigungu && (
                            <span className="text-red errorText">
                              {errors.sigungu.message}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    name="upmyundong"
                    rules={{ required: "주소를 검색해주세요." }}
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="주소를 검색해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value || ""}
                            readOnly
                            onClick={handleZipcodeModal}
                          />
                          {errors.upmyundong && (
                            <span className="text-red errorText">
                              {errors.upmyundong.message}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name="addressDetail"
                  rules={{ required: "상세주소를 입력해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        상세주소
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="addressDetail"
                          className="form-control"
                          placeholder="상세주소를 입력해주세요."
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ""}
                        />
                        {errors.addressDetail && (
                          <span className="text-red errorText">
                            {errors.addressDetail.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="dayOfWeek"
                  rules={{ required: "픽업요일를 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        픽업요일
                      </label>
                      <div className="col-md-10">
                        <div>
                          <div className="btn-group">
                            <button
                              type="button"
                              className={`btn btn-white ${
                                value === "1" && "active"
                              }`}
                              onClick={onChange}
                              value={"1"}
                            >
                              월요일
                            </button>
                            <button
                              type="button"
                              className={`btn btn-white ${
                                value === "2" && "active"
                              }`}
                              onClick={onChange}
                              value={"2"}
                            >
                              화요일
                            </button>
                            <button
                              type="button"
                              className={`btn btn-white ${
                                value === "3" && "active"
                              }`}
                              onClick={onChange}
                              value={"3"}
                            >
                              수요일
                            </button>
                            <button
                              type="button"
                              className={`btn btn-white ${
                                value === "4" && "active"
                              }`}
                              onClick={onChange}
                              value={"4"}
                            >
                              목요일
                            </button>
                            <button
                              type="button"
                              className={`btn btn-white ${
                                value === "5" && "active"
                              }`}
                              onClick={onChange}
                              value={"5"}
                            >
                              금요일
                            </button>
                          </div>
                        </div>
                        {errors.dayOfWeek && (
                          <span className="text-red errorText">
                            {errors.dayOfWeek.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
            <Link
              className="btn btn-white text-danger"
              to={`${baseUrl}/addresses${location.search}`}
            >
              취소
            </Link>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
        <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode height={700} onComplete={handleAddress} />
        </ModalBody>
      </Modal>
    </>
  );
});

export default Write;
