import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { PageSettings } from "../../Config/PageSettings.js";
import SidebarProfile from "./SidebarProfile";
import SidebarNav from "./SidebarNav";

const Sidebar = ({ userData }) => {
  return (
    <PageSettings.Consumer>
      {({
        toggleSidebarMinify,
        toggleMobileSidebar,
        pageSidebarTransparent,
      }) => (
        <React.Fragment>
          <div
            id="sidebar"
            className={
              "sidebar " + (pageSidebarTransparent ? "sidebar-transparent" : "")
            }
          >
            <PerfectScrollbar
              className="height-full"
              options={{ suppressScrollX: true }}
            >
              <SidebarProfile userData={userData} />
              <SidebarNav userData={userData} />
            </PerfectScrollbar>
          </div>
          <div className="sidebar-bg"></div>
          <div
            className="sidebar-mobile-dismiss"
            onClick={toggleMobileSidebar}
          ></div>
        </React.Fragment>
      )}
    </PageSettings.Consumer>
  );
};

export default Sidebar;
