import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import {
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DaumPostcode from "react-daum-postcode";
import AsyncSelect from "../../../../../Containers/DeliveryStore/AsyncSelect";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_PLACE_WRITE_MUTATION,
  STORE_DELIVERY_PLACE_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

export default withRouter(({ history, match, location }) => {
  const baseUrl = "/delivery/deliveryPlaces";

  const { control, handleSubmit, errors, setValue } = useForm({
    defalutValues: {
      name: "",
      managerName: "",
      managerPhone: "",
      zipcode: "",
      jibunAddress: "",
      roadAddress: "",
      addressDetail: "",
      longitude: "",
      latitude: "",
      dayOfWeek: "",
      deliveryStore: null,
    },
  });

  const [
    StoreDeliveryPlaceWriteMutation,
    { loading: StoreDeliveryPlaceWriteLoading },
  ] = useMutation(STORE_DELIVERY_PLACE_WRITE_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_PLACE_LIST_QUERY,
        variables: {
          take: 15,
          skip: 0,
          keyword: "",
        },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (StoreDeliveryPlaceWriteLoading) {
        return;
      }

      const {
        data: { StoreDeliveryPlaceWrite },
      } = await StoreDeliveryPlaceWriteMutation({
        variables: {
          name: data.name,
          managerName: data.managerName,
          managerPhone: data.managerPhone,
          zipcode: data.zipcode,
          jibunAddress: data.jibunAddress,
          roadAddress: data.roadAddress,
          addressDetail: data.addressDetail,
          longitude: data.longitude,
          latitude: data.latitude,
          dayOfWeek: data.dayOfWeek,
          deliveryStore_id: data.deliveryStore.value,
        },
      });
      if (StoreDeliveryPlaceWrite) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  const [zipcodeModal, setZipcodeModal] = useState(false);
  const handleZipcodeModal = () => {
    setZipcodeModal(!zipcodeModal);
  };
  const handleAddress = (data) => {
    const API_KEY = "7da7d093496c67033d3e14aa3102e838";
    const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&size=15`;
    fetch(url, {
      headers: {
        Authorization: `KakaoAK ${API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log({ json });
        setValue("zipcode", data.zonecode);
        setValue("roadAddress", data.roadAddress);
        setValue("jibunAddress", data.jibunAddress);

        setValue("longitude", json.documents[0].x);
        setValue("latitude", json.documents[0].y);

        handleZipcodeModal();
      });
  };

  return (
    <>
      <Helmet>
        <title>픽업장소 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/delivery/deliveryStores">로컬푸드 구독</Link>
            </li>
            <li className="breadcrumb-item active">픽업장소</li>
          </ol>
          <h1 className="page-header">픽업장소</h1>

          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Panel>
                <PanelHeader noButton>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <Controller
                      control={control}
                      name="name"
                      rules={{ required: "장소명을 입력해주세요." }}
                      render={({ onChange, onBlur, value }) => {
                        return (
                          <div className="form-group row">
                            <label className="col-form-label col-md-2">
                              장소명
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control m-b-5"
                                placeholder="장소명을 입력해주세요."
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                              />
                              {errors.name && (
                                <span className="text-red errorText">
                                  {errors.name.message}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name="zipcode"
                      rules={{ required: "주소를 검색해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            우편번호
                          </label>
                          <div className="col-md-10">
                            <InputGroup>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="주소를 검색해주세요."
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                                readOnly
                                onClick={handleZipcodeModal}
                              />
                              <InputGroupAddon addonType="append">
                                <button
                                  type="button"
                                  className="btn btn-white mr-2 text-truncate m-b-5"
                                  onClick={handleZipcodeModal}
                                >
                                  <i className="fa fa-search fa-fw"></i>
                                </button>
                              </InputGroupAddon>
                            </InputGroup>
                            {errors.zipcode && (
                              <span className="text-red errorText">
                                {errors.zipcode.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name="roadAddress"
                      rules={{ required: "주소를 검색해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            도로명주소
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="주소를 검색해주세요."
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value || ""}
                              readOnly
                              onClick={handleZipcodeModal}
                            />
                            {errors.roadAddress && (
                              <span className="text-red errorText">
                                {errors.roadAddress.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name="jibunAddress"
                      rules={{ required: "주소를 검색해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            지번주소
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="주소를 검색해주세요."
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value || ""}
                              readOnly
                              onClick={handleZipcodeModal}
                            />
                            {errors.jibunAddress && (
                              <span className="text-red errorText">
                                {errors.jibunAddress.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />

                    <div className="form-group row">
                      <Controller
                        control={control}
                        name="longitude"
                        rules={{ required: "주소를 검색해주세요." }}
                        render={({ onChange, onBlur, value }) => (
                          <>
                            <label className="col-form-label col-md-2">
                              경도
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="주소를 검색해주세요."
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                                readOnly
                                onClick={handleZipcodeModal}
                              />
                              {errors.longitude && (
                                <span className="text-red errorText">
                                  {errors.longitude.message}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      />
                      <Controller
                        control={control}
                        name="latitude"
                        rules={{ required: "주소를 검색해주세요." }}
                        render={({ onChange, onBlur, value }) => (
                          <>
                            <label className="col-form-label col-md-2">
                              위도
                            </label>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="주소를 검색해주세요."
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value || ""}
                                readOnly
                                onClick={handleZipcodeModal}
                              />
                              {errors.latitude && (
                                <span className="text-red errorText">
                                  {errors.latitude.message}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      />
                    </div>

                    <Controller
                      control={control}
                      name="addressDetail"
                      rules={{ required: "상세주소를 입력해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            상세주소
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              name="addressDetail"
                              className="form-control"
                              placeholder="상세주소를 입력해주세요."
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value || ""}
                            />
                            {errors.addressDetail && (
                              <span className="text-red errorText">
                                {errors.addressDetail.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />

                    <Controller
                      control={control}
                      name="managerName"
                      rules={{ required: "담당자 이름을 입력해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            담당자 이름
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control m-b-5"
                              placeholder="담당자 이름을 입력해주세요."
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value || ""}
                            />
                            {errors.managerName && (
                              <span className="text-red errorText">
                                {errors.managerName.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      control={control}
                      name="managerPhone"
                      rules={{ required: "담당자 연락처를 입력해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            담당자 연락처
                          </label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              className="form-control m-b-5"
                              placeholder="담당자 연락처를 입력해주세요."
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value || ""}
                            />
                            {errors.managerPhone && (
                              <span className="text-red errorText">
                                {errors.managerPhone.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />

                    <Controller
                      control={control}
                      name="deliveryStore"
                      rules={{ required: "매장명을 검색해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            매장명
                          </label>
                          <div className="col-md-10">
                            <AsyncSelect onChange={onChange} value={value} />
                            {errors.deliveryStore && (
                              <span className="text-red errorText">
                                {errors.deliveryStore.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />

                    <Controller
                      control={control}
                      name="dayOfWeek"
                      rules={{ required: "픽업요일를 선택해주세요." }}
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group row">
                          <label className="col-form-label col-md-2">
                            픽업요일
                          </label>
                          <div className="col-md-10">
                            <div>
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className={`btn btn-white ${
                                    value === "1" && "active"
                                  }`}
                                  onClick={onChange}
                                  value={"1"}
                                >
                                  월요일
                                </button>
                                <button
                                  type="button"
                                  className={`btn btn-white ${
                                    value === "2" && "active"
                                  }`}
                                  onClick={onChange}
                                  value={"2"}
                                >
                                  화요일
                                </button>
                                <button
                                  type="button"
                                  className={`btn btn-white ${
                                    value === "3" && "active"
                                  }`}
                                  onClick={onChange}
                                  value={"3"}
                                >
                                  수요일
                                </button>
                                <button
                                  type="button"
                                  className={`btn btn-white ${
                                    value === "4" && "active"
                                  }`}
                                  onClick={onChange}
                                  value={"4"}
                                >
                                  목요일
                                </button>
                                <button
                                  type="button"
                                  className={`btn btn-white ${
                                    value === "5" && "active"
                                  }`}
                                  onClick={onChange}
                                  value={"5"}
                                >
                                  금요일
                                </button>
                              </div>
                            </div>
                            {errors.dayOfWeek && (
                              <span className="text-red errorText">
                                {errors.dayOfWeek.message}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-4 text-left"></div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-success">
                확인
              </button>
              <Link
                to={`${baseUrl}${location.search}`}
                className="btn btn-white text-danger m-l-5"
              >
                취소
              </Link>
            </div>
            <div className="col-xs-4 text-right"></div>
          </div>
        </div>
      </form>
      <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
        <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode height={700} onComplete={handleAddress} />
        </ModalBody>
      </Modal>
    </>
  );
});
