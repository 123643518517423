import React, { useState } from "react"
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet"
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel"
import { withRouter, Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { BANNER_WRITE_QUERY, BANNER_LIST_QUERY } from "./Queries"
import { useMutation } from "react-apollo-hooks"
import { toast } from "react-toastify"

export default withRouter(({ history, match, location }) => {
  const [bannerWriteMutation] = useMutation(BANNER_WRITE_QUERY, {
    refetchQueries: () => [
      {
        query: BANNER_LIST_QUERY,
        variables: {
          keyword: "",
          skip: 0,
          first: 15,
        },
      },
    ],
  })
  const { register, handleSubmit, errors } = useForm()
  const [bannerCoverImage, setBannerCoverImage] = useState(
    "/assets/img/gallery/gallery-default.jpg"
  )
  const handleBannerCoverImage = async (e) => {
    e.preventDefault()

    const files = e.target.files
    const file = files[0]
    var reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = () => {
      setBannerCoverImage(reader.result)
    }
  }
  const onClick_cover = () => {
    document.getElementById("coverImage").click()
  }

  const timestamp = String(new Date().getTime())
  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminBannerWrite },
      } = await bannerWriteMutation({
        variables: {
          commonStatus: data.commonStatus,
          coverImage: data.coverImage[0],
          group: data.group,
          title: data.title,
          stack: data.stack,
          screen: data.screen,
          timestamp,
        },
      })
      if (AdminBannerWrite) {
        toast.success("처리 되었습니다.")
        history.push(`/banners${location.search}`)
      }
    } catch (e) {
      console.log(e)
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.")
    }
  }
  // console.log(errors);

  return (
    <>
      <Helmet>
        <title>배너관리 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item ">선물</li>
            <li className="breadcrumb-item active">배너</li>
          </ol>
          <h1 className="page-header">배너</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link to={`/banners${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader>이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={bannerCoverImage}
                            alt="이미지 업로드"
                            className="height-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImage"
                          name="coverImage"
                          className="hide"
                          onChange={handleBannerCoverImage}
                          ref={register({
                            required: "이미지를 선택해주세요.",
                          })}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          이미지 업로드
                        </button>
                      </div>
                      {errors.coverImage && (
                        <div className="text-danger">
                          {errors.coverImage.message}
                        </div>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <p>
                          <b>배너 사이즈 :</b> 넓이 325px X 높이 120px
                        </p>
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-9 col-sm-12">
              <Panel>
                <PanelHeader>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-4">
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusC"
                            name="commonStatus"
                            className="form-control"
                            value="C"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusC`}>공개</label>
                        </div>
                        <div className="radio radio-css radio-inline">
                          <input
                            type="radio"
                            id="commonStatusS"
                            name="commonStatus"
                            className="form-control"
                            value="S"
                            ref={register({
                              required: "상태를 선택해주세요.",
                            })}
                          />
                          <label htmlFor={`commonStatusS`}>비공개</label>
                        </div>
                        {errors.commonStatus && (
                          <div>{errors.commonStatus.message}</div>
                        )}
                      </div>
                      <label className="col-form-label col-md-2">그룹</label>
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="group"
                          className="form-control m-b-5"
                          placeholder="그룹입력"
                          ref={register({
                            required: "그룹을 입력해주세요.",
                          })}
                        />
                        {errors.group && <span>{errors.group.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">제목</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="title"
                          className="form-control m-b-5"
                          placeholder="제목입력"
                          ref={register({
                            required: "제목을 입력해주세요.",
                          })}
                        />
                        {errors.title && <span>{errors.title.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">Stack</label>
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="stack"
                          className="form-control m-b-5"
                          placeholder="Stack 입력"
                          ref={register({})}
                        />
                        {errors.stack && <span>{errors.stack.message}</span>}
                      </div>
                      <label className="col-form-label col-md-2">Screen</label>
                      <div className="col-md-4">
                        <input
                          type="text"
                          name="screen"
                          className="form-control m-b-5"
                          placeholder="Screen 입력"
                          ref={register({})}
                        />
                        {errors.screen && <span>{errors.screen.message}</span>}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link to={`/banners${location.search}`} className="btn btn-white">
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
})
