import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import {
  GIFT_PRODUCT_LIST_QUERY,
  GIFT_PRODUCT_DETAIL_QUERY,
} from "../../GiftConfig/Queries";
import AsyncSelect from "react-select/async";

const DefaultValue = (giftProduct) => {
  const { data, loading } = useQuery(GIFT_PRODUCT_DETAIL_QUERY, {
    variables: { id: giftProduct },
  });
  if (giftProduct) {
    if (!loading) {
      const {
        AdminGiftProductDetail: { id, name },
      } = data;
      return {
        value: id,
        label: name,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default withRouter(
  ({
    history,
    match,
    location,

    giftProduct,
  }) => {
    const [keyword, setKeyword] = useState("");
    const { data, loading } = useQuery(GIFT_PRODUCT_LIST_QUERY, {
      variables: { keyword, skip: 0, first: 10 },
    });

    const loadOptions = (inputValue, callback) => {
      if (!loading) {
        var options = data.AdminGiftProductList.giftProducts.map((item) => {
          return {
            value: item.id,
            label: `${item.name}`,
          };
        });

        callback(options);
      }
    };
    const handleInputChange = (value) => {
      setTimeout(() => {
        setKeyword(value);
        return value;
      }, 500);
    };
    return (
      <AsyncSelect
        isClearable
        placeholder="상품명 입력"
        noOptionsMessage={() => {
          return "상품명을 검색해주세요.";
        }}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={(options) => {
          const uri =
            "/giftOrderProducts?" +
            (options ? "giftProduct=" + options.value + "&" : "");
          history.push(`${uri}`);
        }}
        defaultValue={DefaultValue(giftProduct)}
      />
    );
  }
);
