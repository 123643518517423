import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  TICKET_CATEGORY_WRITE_QUERY,
  TICKET_CATEGORY_LIST_QUERY,
} from "../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { commonStatusName } from "../../../../Components/User/UserNames";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();
  const [iconImage, setIconImage] = useState(
    "/assets/img/category-default.png"
  );
  const handleIconImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setIconImage(reader.result);
    };
  };
  const onClickIcon = () => {
    document.getElementById("iconImage").click();
  };

  const { register, handleSubmit, errors } = useForm();
  const [ticketCategoryWriteMutation] = useMutation(
    TICKET_CATEGORY_WRITE_QUERY,
    {
      refetchQueries: [
        {
          query: TICKET_CATEGORY_LIST_QUERY,
          variables: {
            ticketConfig: match.params.config,
            parentId: "null",
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminTicketCategoryWrite },
      } = await ticketCategoryWriteMutation({
        variables: {
          ticketConfig: match.params.config,
          commonStatus: data.commonStatus,
          iconImage: data.iconImage[0],
          name: data.name,
          parentId: match.params.id,
        },
      });
      if (AdminTicketCategoryWrite) {
        toast.success("설정 되었습니다.");
        history.push(
          `/ticketConfigs/${match.params.config}/categories${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/ticketConfigs/${match.params.config}/categories${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/ticketConfigs/${match.params.config}/categories${location.search}`
              );
            }}
          >
            분류 등록
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">상태</label>
                  <div className="col-md-10">
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="commonStatus1"
                        name="commonStatus"
                        value={"S"}
                        ref={register({
                          required: "상태를 선택해주세요.",
                        })}
                      />
                      <label htmlFor="commonStatus1">
                        {commonStatusName("S")}
                      </label>
                    </div>
                    <div className="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="commonStatus2"
                        name="commonStatus"
                        value={"C"}
                        ref={register({
                          required: "상태를 선택해주세요.",
                        })}
                      />
                      <label htmlFor="commonStatus2">
                        {commonStatusName("C")}
                      </label>
                    </div>
                    {errors.commonStatus && (
                      <div>{errors.commonStatus.message}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">아이콘</label>
                  <div className="col-md-10">
                    <img
                      src={iconImage}
                      alt="이미지 업로드"
                      className="height-50"
                      style={{ borderRadius: 10, overflow: "hidden" }}
                      onClick={onClickIcon}
                    />
                    <div class="alert alert-primary fade show m-t-10 m-b-0">
                      이미지 클릭시 업로드가 가능합니다.
                      <br />
                      <b>이미지 사이즈 : </b> 넓이 300px X 높이 300px
                    </div>
                    <input
                      type="file"
                      id="iconImage"
                      name="iconImage"
                      className="hide"
                      onChange={handleIconImage}
                      ref={register({})}
                    />
                    {errors.iconImage && (
                      <span>{errors.iconImage.message}</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요.",
                      })}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
});
