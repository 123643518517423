import React, { useState, useEffect } from "react";
import {
  FEEHASUSER_WRITE_QUERY,
  FEEHASUSER_EXCLUSIVEUSER_LIST_QUERY,
  FEEHASUSER_LIST_QUERY,
} from "../../Queries";
import { useMutation, useQuery } from "react-apollo-hooks";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

const UserList = ({ history, match, location, keyword }) => {
  const id = match.params.id;

  const [AdminFeeHasUserWriteMutation] = useMutation(FEEHASUSER_WRITE_QUERY, {
    refetchQueries: [
      {
        query: FEEHASUSER_LIST_QUERY,
        variables: { feeId: id, skip: 0, first: 300 },
      },
    ],
  });

  const [checkItems, setCheckItems] = useState([]);

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: { feeDay: "", feeValue: "", etc_amount: "" },
  });

  const handleSelectCheck = (checked, item) => {
    if (checked) {
      setCheckItems([...checkItems, item]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== item));
    }
  };
  const handleAllCheck = (checked) => {
    if (checked) {
      let itemArray = [];
      data.AdminFeeHasUserExclusiveUserList.users.forEach((item) => {
        itemArray.push(item);
      });
      setCheckItems(itemArray);
    } else {
      setCheckItems([]);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (checkItems.length === 0) {
        toast.error("추가할 회원을 선택해주세요.");
        return;
      }
      const user_ids = checkItems.map((item) => {
        return item.id;
      });

      const feeValue =
        data.feeValue === "etc" ? data.etc_amount : data.feeValue;

      const {
        data: { AdminFeeHasUserWrite },
      } = await AdminFeeHasUserWriteMutation({
        variables: {
          user_ids,
          fee_id: id,
          feeDay: data.feeDay,
          feeValue,
        },
      });
      if (AdminFeeHasUserWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/fees/${id}/users${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const { data, loading, refetch } = useQuery(
    FEEHASUSER_EXCLUSIVEUSER_LIST_QUERY,
    {
      variables: {
        fee_id: id,
        keyword,
      },
    }
  );

  if (loading) {
    return (
      <ModalBody className="p-0">
        <div className="panel-loader">
          <span className="spinner-small"></span>
        </div>
      </ModalBody>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody className="p-0">
        <div className="vertical-box with-grid inbox ">
          <div className="vertical-box-inner-cell overflow-visible height-md">
            <PerfectScrollbar
              className="height-full p-15"
              options={{ suppressScrollX: true }}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-b-0">
                  <thead>
                    <tr>
                      <th>이름(아이디)</th>
                      <th>정기충전(CMS)</th>
                      <th style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          onChange={(e) => handleAllCheck(e.target.checked)}
                          checked={
                            checkItems.length ===
                            data.AdminFeeHasUserExclusiveUserList.users.length
                          }
                        />
                      </th>
                    </tr>
                  </thead>
                  {!loading &&
                  data.AdminFeeHasUserExclusiveUserList.count > 0 ? (
                    <tbody>
                      {data.AdminFeeHasUserExclusiveUserList.users.map(
                        (item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                {item.name}({item.userId})
                              </td>

                              <td>
                                {item.isCms
                                  ? `매월 ${item.cmsDay}일 ${String(
                                      item.cmsValue
                                    ).replace(
                                      /(.)(?=(\d{3})+$)/g,
                                      "$1,"
                                    )}원 정기결제`
                                  : "-"}
                              </td>
                              <td align={"center"}>
                                <input
                                  type="checkbox"
                                  name="users"
                                  value={item.id}
                                  onChange={(e) =>
                                    handleSelectCheck(e.target.checked, item)
                                  }
                                  checked={
                                    checkItems.includes(item) ? true : false
                                  }
                                  ref={register()}
                                />
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <p className="m-40 text-center">
                            등록된 사용자가 없습니다.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <InputGroup>
          <select
            className="form-control"
            name="feeDay"
            ref={register({
              required: "일수를 선택해주세요.",
            })}
          >
            <option value={""}>납부일 선택</option>
            <option value={"5"}>5일</option>
            <option value={"25"}>25일</option>
          </select>{" "}
          <select
            className="form-control"
            name="feeValue"
            ref={register({
              required: "금액을 선택해주세요.",
            })}
          >
            <option value={""}>금액 선택</option>
            <option value={"5000"}>5,000드림</option>
            <option value={"10000"}>10,000드림</option>
            <option value={"50000"}>50,000드림</option>
            <option value={"100000"}>100,000드림</option>
            <option value={"150000"}>150,000드림</option>
            <option value={"200000"}>200,000드림</option>
            <option value={"etc"}>기타</option>
          </select>
          {watch("feeValue") === "etc" && (
            <Input
              type="text"
              name="etc_amount"
              className="form-control width-200"
              placeholder="금액을 입력해주세요."
              style={{ display: "inline-block" }}
              innerRef={register({
                validate: (value) => {
                  if (watch("feeValue") === "etc" && value === "") {
                    return "금액을 입력해주세요.";
                  }
                },
              })}
            />
          )}
          <InputGroupAddon addonType="append">
            <Button type="submit" color="white">
              추가
            </Button>
          </InputGroupAddon>
        </InputGroup>

        {(errors.feeDay?.message ||
          errors.feeValue?.message ||
          errors.etc_amount?.message) && (
          <div>
            <span>
              {errors.feeDay?.message ||
                errors.feeValue?.message ||
                errors.etc_amount?.message}
            </span>
          </div>
        )}
      </ModalFooter>
    </form>
  );
};

const Presenter = ({
  history,
  match,
  location,

  isModal,
}) => {
  const modalRef = React.createRef();

  const id = match.params.id;

  const [keyword, setKeyword] = useState("");

  const { register, handleSubmit, watch } = useForm();

  const onSubmit = async (data) => {
    if (data.keyword.length < 2) {
      toast.error("검색어는 2글자 이상 입력해주세요.");
      return;
    }

    setKeyword(data.keyword);
  };

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/fees/${id}/users${location.search}`);
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`/fees/${id}/users${location.search}`);
          }}
        >
          회원 목록
        </ModalHeader>
        <div className="p-r-15 p-l-15 p-t-15">
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <Input
                type="text"
                name="keyword"
                className="input-white"
                placeholder="검색어(회원명, 회원아이디) 입력"
                innerRef={register()}
              />
              <InputGroupAddon addonType="append">
                <Button type="submit" color="white">
                  <i className="fa fa-search fa-fw"></i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
        </div>
        {keyword ? (
          <UserList
            history={history}
            match={match}
            location={location}
            keyword={keyword}
          />
        ) : (
          <ModalBody className="p-0">
            <div className="vertical-box with-grid inbox ">
              <div className="vertical-box-inner-cell overflow-visible height-md">
                <div className="vertical-box p-15 text-center ">
                  검색어를 입력해주세요.
                </div>
              </div>
            </div>
          </ModalBody>
        )}
      </Modal>
    </>
  );
};

export default Presenter;
