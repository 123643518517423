import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { GRU_WALLET_LIST_QUERY } from "./Queries";
import AsyncSelect from "react-select/async";

const WriteWallet = ({ history, match, location, wallet, setWallet }) => {
  const [keyword, setKeyword] = useState("");
  const { data, loading } = useQuery(GRU_WALLET_LIST_QUERY, {
    variables: { keyword, skip: 0, first: 10 },
  });

  const loadOptions = (inputValue, callback) => {
    if (!loading) {
      var options = data.AdminGRUWalletList.wallets.map((wallet) => {
        return {
          value: wallet.id,
          label: `${wallet.user === null ? "[탈퇴] " : ""}${wallet.name}(${
            wallet.userId
          })`,
        };
      });

      callback(options);
    }
  };
  const handleInputChange = (value) => {
    setTimeout(() => {
      setKeyword(value);
      return value;
    }, 500);
  };

  return (
    <AsyncSelect
      name="wallet"
      placeholder="사용자명 입력"
      noOptionsMessage={() => {
        return "사용자명을 검색해주세요.";
      }}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={({ label, value }) => {
        console.log({ label, value });
        setWallet(value);
      }}
      defaultValue=""
    />
  );
};

export default WriteWallet;
