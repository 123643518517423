import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { COUPON_LIST_QUERY } from "../../Queries";
import useInput from "../../../../../Hooks/useInput";
import ListPresenter from "./Presenter/List";
import qs from "qs";

export default withRouter(({ history, match, location, config }) => {
  const queryString = qs.parse(location.search.substr(1));
  var page = queryString.page ? queryString.page : 1;
  var keyword = queryString.keyword ? queryString.keyword : "";
  const keywordInput = useInput(keyword);
  const blockSize = 5;
  const first = 15;
  const skip = first * (page - 1);
  const baseUrl =
    "?" + (keywordInput.value ? "keyword=" + keywordInput.value + "&" : "");

  const { data, loading, refetch } = useQuery(COUPON_LIST_QUERY, {
    variables: {
      couponConfig: match.params.couponConfig,
      couponCategory: match.params.couponCategories
        ? match.params.couponCategories
        : "",
      keyword,
      skip,
      first,
    },
  });

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(
      `/couponConfigs/${config.AdminCouponConfig.id}/coupons${baseUrl}page=1`
    );
  };

  useEffect(() => {
    refetch();
  });

  return (
    <>
      <ListPresenter
        history={history}
        match={match}
        location={location}
        config={config}
        page={page}
        keyword={keyword}
        keywordInput={keywordInput}
        blockSize={blockSize}
        first={first}
        data={data}
        loading={loading}
        baseUrl={baseUrl}
        handleSearch={handleSearch}
      ></ListPresenter>
    </>
  );
});
