import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { FEEMONTHFILE_PREVIEW_QUERY } from "../Queries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import qs from "qs";

export default withRouter(({ history, match, location, isModal }) => {
  const modalRef = React.createRef();

  const id = match.params.id;
  const feeMonthFileId = match.params.feeMonthFileId;

  const queryString = qs.parse(location.search.substr(1));
  const { data, loading } = useQuery(FEEMONTHFILE_PREVIEW_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: match.params.feeMonthFileId,
      column1: queryString.column1,
      column2: queryString.column2,
      column3: queryString.column3,
      column4: queryString.column4,
      column5: queryString.column5,
      column6: queryString.column6,
      column7: queryString.column7,
      column8: queryString.column8,
      column9: queryString.column9,
      column10: queryString.column10,
      column11: queryString.column11,
      column12: queryString.column12,
      column13: queryString.column13,
      column14: queryString.column14,
      column15: queryString.column15,
    },
  });

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  let nameColumn = "";
  let phoneColumn = "";
  for (let i = 1; i <= 15; i++) {
    if (queryString[`column` + i] === "name") {
      nameColumn = `column${i}`;
    }
    if (queryString[`column` + i] === "phone") {
      phoneColumn = `column${i}`;
    }
  }

  return (
    <Modal
      isOpen={true}
      size={"lg"}
      ref={modalRef}
      toggle={() => {
        history.push(
          `/fees/${id}/monthFiles/${feeMonthFileId}${location.search}`
        );
      }}
    >
      <ModalHeader
        toggle={() => {
          history.push(
            `/fees/${id}/monthFiles/${feeMonthFileId}${location.search}`
          );
        }}
      >
        미리보기
      </ModalHeader>
      <ModalBody>
        {data.AdminFeeMonthFilePreview.feeMonthFileDetails.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered table-striped m-b-0">
              <thead>
                <tr>
                  <th>이름</th>
                  <th>전화번호</th>
                  <th>설명</th>
                  <th>출금일</th>
                  <th>출금금액</th>
                </tr>
              </thead>
              <tbody>
                {data.AdminFeeMonthFilePreview.feeMonthFileDetails.map(
                  (feeMonthFileDetail, index) => {
                    return (
                      <tr key={index}>
                        <td>{feeMonthFileDetail[nameColumn]}</td>
                        <td>{feeMonthFileDetail[phoneColumn]}</td>
                        <td>{feeMonthFileDetail.description}</td>
                        <td>
                          {feeMonthFileDetail.feeHasUser
                            ? feeMonthFileDetail.feeHasUser.feeDay
                            : "-"}
                        </td>
                        <td>
                          {feeMonthFileDetail.feeHasUser
                            ? feeMonthFileDetail.feeHasUser.feeValue
                            : "-"}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center">등록된 데이터가 없습니다.</p>
        )}
      </ModalBody>
    </Modal>
  );
});
