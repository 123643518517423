import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import {
  GIFT_CATEGORY_MOVE_QUERY,
  GIFT_CATEGORY_LIST_QUERY,
} from "../../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default ({
  history,
  match,
  location,
  isModal,
  id,
  giftConfig,
  giftCategories,
  commonStatus,
  name,
  childrenCount,
}) => {
  const modalRef = React.createRef();

  const [options, setOptions] = useState([]);
  const [selectDepth, setSelectDepth] = useState(0);

  const [giftCategoryMoveMutation] = useMutation(GIFT_CATEGORY_MOVE_QUERY, {
    refetchQueries: [
      {
        query: GIFT_CATEGORY_LIST_QUERY,
        variables: {
          giftConfig: match.params.config,
          parentId: "null",
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminGiftCategoryMove },
      } = await giftCategoryMoveMutation({
        variables: {
          id: id,
          target: data.target,
          position: data.position,
        },
      });
      if (AdminGiftCategoryMove) {
        toast.success("처리 되었습니다.");
        history.push(
          `/giftConfigs/${match.params.config}/categories${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  useEffect(() => {
    let optionsDepth = [];

    giftCategories.AdminGiftCategoryList.giftCategories.forEach((depth1) => {
      // if (depth1.id !== id) {
      //   setDepth(0);
      // }
      optionsDepth.push({
        id: depth1.id,
        name: depth1.name,
        childrenCount: depth1.childrenCount,
        depth: 0,
      });

      if (depth1.childrenCount > 0) {
        depth1.children.forEach((depth2) => {
          // if (depth2.id !== id) {
          //   setDepth(1);
          // }
          optionsDepth.push({
            id: depth2.id,
            name: `${depth1.name} > ${depth2.name}`,
            childrenCount: depth2.childrenCount,
            depth: 1,
          });

          if (depth2.childrenCount > 0) {
            depth2.children.forEach((depth3) => {
              // if (depth3.id !== id) {
              //   setDepth(2);
              // }
              optionsDepth.push({
                id: depth3.id,
                name: `${depth1.name} > ${depth2.name} > ${depth3.name}`,
                childrenCount: depth3.childrenCount,
                depth: 2,
              });
            });
          }
        });
      }
    });
    setOptions(optionsDepth);
  }, [giftCategories]);

  return (
    <Fragment>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/giftConfigs/${match.params.config}/categories${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/giftConfigs/${match.params.config}/categories${location.search}`
              );
            }}
          >
            {name} 분류 이동
          </ModalHeader>
          <ModalBody className="p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <Alert className="m-0" color="primary">
                  선택한 분류를 기준 분류의 선택 위치로 이동합니다.
                </Alert>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">기준</label>
                  <div className="col-md-10">
                    <select
                      className="form-control"
                      name="target"
                      ref={register({
                        required: "기준 분류를 선택해주세요.",
                      })}
                      onChange={(e) => {
                        const selectOption = options.find(
                          (item) => item.id === e.target.value
                        );
                        setSelectDepth(selectOption.depth);
                      }}
                    >
                      <option value={""}>분류선택</option>
                      {giftCategories.AdminGiftCategoryList.giftCategories.map(
                        (depth1, index1) => {
                          return (
                            depth1.id !== id && (
                              <Fragment key={index1}>
                                <option value={depth1.id}>{depth1.name}</option>
                                {depth1.childrenCount > 0 &&
                                  depth1.children.map((depth2, index2) => {
                                    return (
                                      depth2.id !== id && (
                                        <Fragment key={index2}>
                                          <option value={depth2.id}>
                                            {depth1.name} &gt; {depth2.name}
                                          </option>
                                          {depth2.childrenCount > 0 &&
                                            depth2.children.map(
                                              (depth3, index3) => {
                                                return (
                                                  depth3.id !== id && (
                                                    <option
                                                      key={index3}
                                                      value={depth3.id}
                                                    >
                                                      {depth1.name} &gt;{" "}
                                                      {depth2.name} &gt;{" "}
                                                      {depth3.name}
                                                    </option>
                                                  )
                                                );
                                              }
                                            )}
                                        </Fragment>
                                      )
                                    );
                                  })}
                              </Fragment>
                            )
                          );
                        }
                      )}
                    </select>
                    {/* move : {selectDepth} {childrenCount}
                    <br />
                    select : {selectDepth} {selectChildrenCount}
                    <br /> */}
                    {errors.target && <span>{errors.target.message}</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">위치</label>
                  <div className="col-md-10">
                    <div className="radio">
                      <input
                        type="radio"
                        id="position1"
                        name="position"
                        value={"up"}
                        ref={register({
                          required: "이동위치를 선택해주세요.",
                        })}
                      />{" "}
                      <label htmlFor="position1">
                        선택한 분류를 기준 분류 위로 옮깁니다.
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        id="position2"
                        name="position"
                        value={"down"}
                        ref={register({
                          required: "이동위치를 선택해주세요.",
                        })}
                      />{" "}
                      <label htmlFor="position2">
                        선택한 분류를 기준 분류 아래로 옮깁니다.
                      </label>
                    </div>
                    {selectDepth === 0 && childrenCount === 0 && (
                      <div className="radio">
                        <input
                          type="radio"
                          id="position3"
                          name="position"
                          value={"lower"}
                          ref={register({
                            required: "이동방법을 선택해주세요.",
                          })}
                        />{" "}
                        <label htmlFor="position3">
                          선택한 분류를 기준 분류 하위로 옮깁니다.
                        </label>
                      </div>
                    )}
                    {errors.position && <div>{errors.position.message}</div>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-white text-blue">
              확인
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};
