import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import { graphqlUri } from "../../../../Config/Env";

export default ({
  history,
  match,
  location,
  keyword,
  page,
  take,
  data,
  baseUrl,
}) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      keyword,
    },
  });

  const onSubmit = async (data) => {
    const baseUrl = "?" + (data.keyword ? "keyword=" + data.keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const defaultUrl = baseUrl + "?" + (keyword ? `keyword=${keyword}&` : ``);

  return (
    <>
      <Helmet>
        <title>배너 | 한밭페이 </title>
      </Helmet>

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/deliveryStores">로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">배너</li>
      </ol>
      <h1 className="page-header">배너</h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록({data.AdminDeliveryBannerList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row">
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="keyword"
                        render={({ onChange, onBlur, value }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어(제목)를 입력해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <Button type="submit" size="sm" color="white">
                          <i className="fa fa-search fa-fw"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </form>
                </div>
              </div>
            </Alert>

            {data.AdminDeliveryBannerList.count > 0 ? (
              <>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                      <colgroup>
                        <col width="15%" />
                        <col width="*" />
                        <col width="15%" />
                        <col width="10%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th className="text-nowrap text-center">이미지</th>
                          <th className="text-nowrap text-center">배너정보</th>
                          <th className="text-nowrap text-center">작성일</th>
                          <th className="text-nowrap text-center">기능</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.AdminDeliveryBannerList.deliveryBanners.map(
                          (item) => {
                            const ticketBannerCoverImage =
                              item.coverImage === ""
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${item.coverImage}`;
                            return (
                              <tr key={item.id}>
                                <td>
                                  <div
                                    className="product-img"
                                    style={{
                                      backgroundImage: `url(${ticketBannerCoverImage})`,
                                    }}
                                  >
                                    <img
                                      src={ticketBannerCoverImage}
                                      alt={item.name}
                                    />
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="widget-list-title m-t-5">
                                    상태 :{" "}
                                    {item.commonStatus === "S" && "비공개"}
                                    {item.commonStatus === "C" && "공개"}
                                  </div>
                                  <div className="widget-list-title m-t-5">
                                    게시기간 :{" "}
                                    {item.isPeriod === true && (
                                      <span>
                                        <ReactMoment format="YYYY-MM-DD">
                                          {item.startDate}
                                        </ReactMoment>
                                        ~
                                        <ReactMoment format="YYYY-MM-DD">
                                          {item.endDate}
                                        </ReactMoment>
                                      </span>
                                    )}
                                    {item.isPeriod === false && "미사용"}
                                  </div>
                                  <div className="widget-list-title m-t-5">
                                    링크 :{" "}
                                    {item.isLink === true && (
                                      <span>{item.link}</span>
                                    )}
                                    {item.isLink === false && "미사용"}
                                  </div>
                                  <div className="widget-list-title m-t-5">
                                    제목 : {item.title}
                                  </div>
                                </td>
                                <td className="text-nowrap text-center">
                                  <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                                    {item.createdAt}
                                  </ReactMoment>
                                </td>
                                <td className="text-nowrap text-center">
                                  <Link
                                    className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                    to={`/deliveryBanners/${item.id}/edit${location.search}`}
                                  >
                                    상세정보
                                  </Link>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.AdminDeliveryBannerList.count}
                        blockSize={5}
                        pageSize={take}
                        currentPage={page}
                        baseUrl={defaultUrl}
                      />
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </PanelFooter>
              </>
            ) : (
              <PanelBody>
                <p className="text-center">등록된 데이터가 없습니다.</p>
              </PanelBody>
            )}
          </Panel>
        </div>
      </div>

      <Link
        to={`${baseUrl}/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
