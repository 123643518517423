import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { EVENT_CONFIG_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match }) => {
  const { data, loading } = useQuery(EVENT_CONFIG_QUERY);

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminEventConfig) {
    const {
      AdminEventConfig: { id, name, listCount, pageCount }
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        id={id}
        name={name}
        listCount={listCount}
        pageCount={pageCount}
      ></Presenter>
    );
  }
});
