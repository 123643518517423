import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { DONATION_CATEGORY_EDIT_QUERY } from "../../Queries";
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

export default ({
  history,
  match,
  location,
  isModal,
  id,
  pcIcon,
  mobileIcon,
  name
}) => {
  const modalRef = React.createRef();

  const [donationCategoryEditMutation] = useMutation(
    DONATION_CATEGORY_EDIT_QUERY
  );

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      pcIcon: pcIcon,
      mobileIcon: mobileIcon,
      name: name
    }
  });
  const onSubmit = async data => {
    try {
      const {
        data: { AdminDonationCategoryEdit }
      } = await donationCategoryEditMutation({
        variables: {
          id: id,
          pcIcon: data.pcIcon,
          mobileIcon: data.mobileIcon,
          name: data.name
        }
      });
      if (AdminDonationCategoryEdit) {
        toast.success("처리 되었습니다.");
        history.push(`/donations/categories${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(`/donations/categories${location.search}`);
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(`/donations/categories${location.search}`);
            }}
          >
            분류 수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-horizontal form-bordered">
              <div className="bg-white rounded">
                <div className="form-group row">
                  <label className="col-form-label col-md-2">PC 아이콘</label>
                  <div className="col-md-4">
                    <input
                      type="text"
                      name="pcIcon"
                      className="form-control"
                      placeholder="PC 아이콘을 입력해주세요."
                      ref={register({
                        required: "PC아이콘을 입력해주세요."
                      })}
                      defaultValue={watch("pcIcon")}
                    />
                    {errors.pcIcon && <span>{errors.pcIcon.message}</span>}
                  </div>
                  <label className="col-form-label col-md-2">
                    Mobile 아이콘
                  </label>
                  <div className="col-md-4">
                    <input
                      type="text"
                      name="mobileIcon"
                      className="form-control"
                      placeholder="Mobile 아이콘을 입력해주세요."
                      ref={register({
                        required: "Mobile 아이콘을 입력해주세요."
                      })}
                      defaultValue={watch("mobileIcon")}
                    />
                    {errors.mobileIcon && (
                      <span>{errors.mobileIcon.message}</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-md-2">이름</label>
                  <div className="col-md-10">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="이름을 입력해주세요."
                      ref={register({
                        required: "이름을 입력해주세요."
                      })}
                      defaultValue={watch("name")}
                    />
                    {errors.name && <span>{errors.name.message}</span>}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-4 text-left">
              <Link
                to={`/donations/categories${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-4 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
            <div className="col-xs-4 text-right"></div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
