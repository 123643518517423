import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import {
  TICKET_CATEGORY_DETAIL_QUERY,
  TICKET_CATEGORY_LIST_QUERY,
} from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data: detail } = useQuery(TICKET_CATEGORY_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  const { data: ticketCategories } = useQuery(TICKET_CATEGORY_LIST_QUERY, {
    variables: {
      ticketConfig: match.params.config,
      parentId: "null",
    },
  });

  if (
    detail &&
    detail.AdminTicketCategoryDetail &&
    ticketCategories &&
    ticketCategories.AdminTicketCategoryList
  ) {
    const {
      AdminTicketCategoryDetail: {
        id,
        ticketConfig,
        commonStatus,
        name,
        childrenCount,
      },
    } = detail;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        ticketConfig={ticketConfig}
        commonStatus={commonStatus}
        name={name}
        childrenCount={childrenCount}
        ticketCategories={ticketCategories}
      ></Presenter>
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
