import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  DONATION_IMAGE_DETAIL_QUERY,
  DONATION_IMAGE_DELETE_QUERY,
  DONATION_IMAGE_LIST_QUERY,
} from "../../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(DONATION_IMAGE_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.donationImage },
  });

  const [donationImageDeleteMutation] = useMutation(
    DONATION_IMAGE_DELETE_QUERY,
    {
      refetchQueries: () => [
        {
          query: DONATION_IMAGE_LIST_QUERY,
          variables: { donation: match.params.id },
        },
      ],
    }
  );
  const onDelete = async (id) => {
    try {
      const {
        data: { AdminDonationImageDelete },
      } = await donationImageDeleteMutation({
        variables: {
          id: match.params.donationImage,
        },
      });
      if (AdminDonationImageDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/donations/${match.params.id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminDonationImageDetail) {
    const {
      AdminDonationImageDetail: { id, donationImage, name, description },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        donationImage={donationImage}
        name={name}
        description={description}
        onDelete={onDelete}
      />
    );
  }
});
