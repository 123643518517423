import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { dayOfWeekName } from "../../../../Components/User/UserNames"
import ReactToPrint from "react-to-print"
import ProductPrint from "../../../../Components/Print/ProductPrint"

export default ({
  isModal,
  history,
  match,
  location,
  deliveryStore_id,
  startDate,
  endDate,
  deliveryDeliver_id,
  deliveryBox_id,
  data,
  baseUrl,
}) => {
  const pageStyle = `@media print {@page{ size:a4}}`
  const modalRef = useRef()
  const printRef = useRef()

  useEffect(() => {
    const element = modalRef.current

    if (isModal) {
      disableBodyScroll(element)
    }

    return () => {
      enableBodyScroll(element)
    }
  })

  return (
    <>
      <Modal
        isOpen={true}
        size={"sm"}
        ref={modalRef}
        toggle={() => {
          history.push(`${baseUrl}${location.search}`)
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(`${baseUrl}${location.search}`)
          }}
        >
          배송상품 출력
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-xs-12">
              <div className="text-center">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-white">
                      <i className="fa fa-print"></i> 출력하기
                    </button>
                  )}
                  content={() => printRef.current}
                  documentTitle="운송장 출력"
                  pageStyle={pageStyle}
                />
                <div style={{ display: "none" }}>
                  <ProductPrint
                    ref={printRef}
                    startDate={startDate}
                    endDate={endDate}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
