import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TICKET_PRODUCT_DATE_EDIT_QUERY,
  TICKET_PRODUCT_DETAIL_QUERY,
} from "../../../Queries";
import { useMutation } from "react-apollo-hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import { commonStatusArray } from "../../../../../../../Components/User/UserNames";

import Moment from "moment";
import ReactMoment from "react-moment";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
registerLocale("ko", ko);

export default ({
  history,
  match,
  location,

  id,
  commonStatus,
  ticketedAt,

  isModal,
}) => {
  const modalRef = React.createRef();

  const [ticketProductDateEditMutation] = useMutation(
    TICKET_PRODUCT_DATE_EDIT_QUERY,
    {
      refetchQueries: () => [
        {
          query: TICKET_PRODUCT_DETAIL_QUERY,
          variables: { id: match.params.id },
        },
      ],
    }
  );

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      commonStatus: commonStatus,
    },
  });

  const [ticketDate, setTicketDate] = useState(new Date(ticketedAt));

  const onSubmit = async (data) => {
    try {
      const {
        data: { StoreTicketProductDateEdit },
      } = await ticketProductDateEditMutation({
        variables: {
          id: id,
          commonStatus: data.commonStatus,
          ticketDate: Moment(ticketDate).format("YYYY-MM-DD"),
        },
      });
      if (StoreTicketProductDateEdit) {
        toast.success("처리 되었습니다.");
        history.push(
          `/ticket/ticketConfigs/${match.params.config}/products/${match.params.id}${location.search}`
        );
      }
    } catch (e) {
      console.log(e);
      var error = e.toString();
      error = error.replace("Error: GraphQL error:", "");
      toast.error(`${error}`);
    }
  };
  // console.log(errors);

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <Fragment>
      <Modal
        isOpen={true}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/ticket/ticketConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
          );
        }}
      >
        <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            toggle={() => {
              history.push(
                `/ticket/ticketConfigs/${match.params.config}/products/${match.params.id}/${location.search}`
              );
            }}
          >
            티켓일자수정
          </ModalHeader>
          <ModalBody className=" p-0">
            <div className="form-group">
              <div className=" p-10 ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered tablebasic product_op_table">
                        <caption className="sr-only">옵션명</caption>
                        <thead>
                          <tr>
                            <th
                              abbr="일자"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              일자
                            </th>
                            <th
                              abbr="상태"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              상태
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <DatePicker
                                name="ticketDate"
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                selected={ticketDate}
                                onChange={(date) => setTicketDate(date)}
                                locale="ko"
                                minDate={new Date()}
                                renderCustomHeader={({
                                  date,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                  decreaseMonth,
                                  increaseMonth,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                                      aria-label="Previous Month"
                                      onClick={decreaseMonth}
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      Previous Month
                                    </button>
                                    <div className="month-day">
                                      {getYear(date)}년 {getMonth(date) + 1}월
                                    </div>
                                    <button
                                      type="button"
                                      className="react-datepicker__navigation react-datepicker__navigation--next"
                                      aria-label="Next Month"
                                      onClick={increaseMonth}
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      Next Month
                                    </button>
                                  </div>
                                )}
                              />
                            </td>
                            <td>
                              {Object.keys(commonStatusArray).map(
                                (key, index) => (
                                  <div
                                    key={index}
                                    className="radio radio-css radio-inline"
                                  >
                                    <input
                                      type="radio"
                                      id={`commonStatus${index}`}
                                      name="commonStatus"
                                      value={key}
                                      ref={register()}
                                    />
                                    <label htmlFor={`commonStatus${index}`}>
                                      {commonStatusArray[key]}
                                    </label>
                                  </div>
                                )
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {errors.name && (
                      <Fragment>
                        <span>{errors.name.message}</span>
                        <br />
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-xs-12 text-center">
              <button type="submit" className="btn btn-white text-blue">
                확인
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};
