import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, InputGroupAddon, Button, Alert } from "reactstrap";
import Select from "../../../../Containers/DeliveryStoreDong/Select";
import Pagination from "../../../../Components/Pagination/QSP";

const Presenter = ({
  history,
  match,
  location,
  deliveryStoreDong_id,
  keyword,
  page,
  take,
  loading,
  data,
  baseUrl,
}) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      keyword,
    },
  });

  const onSubmit = async (data) => {
    const baseUrl =
      "?" +
      (deliveryStoreDong_id
        ? "deliveryStoreDong_id=" + deliveryStoreDong_id + "&"
        : "") +
      (data.keyword ? "keyword=" + data.keyword + "&" : "");
    history.push(`${baseUrl}page=1`);
  };

  const onChange = (event) => {
    const value = event.target.value;
    const baseUrl =
      "?" +
      (value ? "deliveryStoreDong_id=" + value + "&" : "") +
      (keyword ? "keyword=" + keyword + "&" : "");

    console.log({ value, baseUrl });

    history.push(`${baseUrl}page=1`);
  };

  const defaultUrl =
    baseUrl +
    "?" +
    (deliveryStoreDong_id
      ? `deliveryStoreDong_id=${deliveryStoreDong_id}&`
      : ``) +
    (keyword ? "keyword=" + keyword + "&" : "");

  return (
    <div>
      <Helmet>
        <title>매장 | 한밭페이</title>
      </Helmet>

      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to={`${baseUrl}`}>로컬푸드 구독</Link>
        </li>
        <li className="breadcrumb-item active">매장</li>
      </ol>

      <h1 className="page-header">매장</h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader noButton={true}>
              목록 ({!loading && data.AdminDeliveryStoreList.count}건)
            </PanelHeader>
            <Alert className="bg-gray-transparent-1">
              <div className="row m-b-15">
                <div className="col-xs-12">
                  <InputGroup>
                    <Select onChange={onChange} value={deliveryStoreDong_id} />
                    <InputGroupAddon addonType="append">
                      <Link className={"btn btn-white"} to={`${baseUrl}/dongs`}>
                        <i className={"fas fa-cogs"} />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputGroup>
                      <Controller
                        control={control}
                        name="keyword"
                        rules={{ required: "검색어(매장명) 입력해주세요." }}
                        render={({ onChange, onBlur, value }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="검색어(매장명) 입력해주세요."
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      <InputGroupAddon addonType="append">
                        <Button type="submit" size="sm" color="white">
                          <i className="fa fa-search fa-fw"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    {errors.keyword && (
                      <span className="text-red errorText">
                        {errors.keyword.message}
                      </span>
                    )}
                  </form>
                </div>
              </div>
            </Alert>
            {!loading ? (
              <Fragment>
                {data.AdminDeliveryStoreList.count > 0 ? (
                  <>
                    <PanelBody>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                          <colgroup>
                            <col width="*" />
                            <col width="15%" />
                            <col width="10%" />
                            <col width="10%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="text-nowrap text-center">
                                매장정보
                              </th>
                              <th className="text-nowrap text-center">
                                배달가능한 동
                              </th>
                              <th className="text-nowrap text-center">
                                픽업장소
                              </th>
                              <th className="text-nowrap text-center">기능</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.AdminDeliveryStoreList.deliveryStores.map(
                              (item, index) => {
                                const deliveryStoreDongs =
                                  item.deliveryStoreDongs.map((item, index) => {
                                    return item.upmyundong;
                                  });
                                return (
                                  <tr key={index}>
                                    <td>
                                      {item.store.name} &gt; {item.user.name}
                                    </td>
                                    <td>{deliveryStoreDongs.join(", ")}</td>

                                    <td className="text-nowrap text-center">
                                      {item.deliveryPlacesCount}곳{" "}
                                    </td>
                                    <td className="text-nowrap text-center">
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`${baseUrl}/${item.id}/edit${location.search}`}
                                        >
                                          상세정보
                                        </Link>
                                      </div>
                                      <div>
                                        <Link
                                          className={`btn btn-white btn-xs text-green m-r-5 m-b-5`}
                                          to={`/deliveryPlaces?deliveryStore_id=${item.id}`}
                                        >
                                          픽업장소
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                    <PanelFooter>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.AdminDeliveryStoreList.count}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={defaultUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </PanelFooter>
                  </>
                ) : (
                  <PanelBody>
                    <p className="text-center">등록된 데이터가 없습니다.</p>
                  </PanelBody>
                )}
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Panel>
        </div>
      </div>
      <Link
        to={`${baseUrl}/write${location.search}`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </div>
  );
};

export default Presenter;
