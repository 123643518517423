import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import { Button, Alert } from "reactstrap";
import NumberFormat from "react-number-format";

export default ({
  history,
  match,
  location,
  year,
  selectYears,
  data,
  loading,
}) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    history.push(`/ticket/ticketExchanges/year/${data.year}`);
  };
  // console.log(errors);

  return (
    <>
      <Helmet>
        <title>정산내역 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">드림티켓</li>
          <li className="breadcrumb-item active">정산내역</li>
        </ol>
        <h1 className="page-header">정산내역</h1>
        <div className="row">
          <div className="col-xs-12">
            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.StoreTicketExchangeList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <form
                  className="margin-bottom-0"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group row m-b-0">
                    <div className="col-xs-12">
                      <div className="btn-group m-r-5">
                        <select
                          name="year"
                          className="form-control width-100"
                          ref={register()}
                          defaultValue={year}
                        >
                          {selectYears.map((selectYear, index) => {
                            return (
                              <option key={index} value={selectYear}>
                                {selectYear}년
                              </option>
                            );
                          })}
                        </select>
                        <Button type="submit" color="white">
                          <i className="fa fa-search fa-fw"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Alert>
              <PanelBody className="">
                {!loading && data.StoreTicketExchangeList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-th-valign-middle table-td-valign-middle m-b-0">
                        <colgroup>
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          {/* <col style={{ width: "20%" }} /> */}
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="text-center">기간</th>
                            <th className="text-center">가맹점(출납원)</th>
                            <th className="text-center">판매가</th>
                            <th className="text-center">정산가(97%)</th>
                            <th className="text-center">수수료(3%)</th>
                            {/* <th className="text-center">기능</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data.StoreTicketExchangeList.ticketExchanges.map(
                            (item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.date}</td>
                                  <td>
                                    {item.name}({item.cashier})
                                  </td>
                                  <td>
                                    <NumberFormat
                                      displayType={"text"}
                                      value={item.totalPrice}
                                      thousandSeparator={true}
                                      suffix={"드림"}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      displayType={"text"}
                                      value={item.price}
                                      thousandSeparator={true}
                                      suffix={"드림"}
                                    />
                                  </td>
                                  <td>
                                    <NumberFormat
                                      displayType={"text"}
                                      value={item.commission}
                                      thousandSeparator={true}
                                      suffix={"드림"}
                                    />
                                  </td>
                                  {/* <td className="text-center">
                                    <Link
                                      to={`/ticket/ticketExchanges/${item.id}${location.search}`}
                                      className="btn btn-white btn-xs text-primary"
                                    >
                                      상세정보
                                    </Link>
                                  </td> */}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
