import React, { Fragment, forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "./DatePicker.css";
import { pastPeriod } from "../../Components/User/UserNames";
import { Button } from "reactstrap";
import { ko } from "date-fns/locale";
import { getMonth, getYear } from "date-fns";

const CustomDatePicker = ({
  startDate,
  endDate,
  onStartDateSelect,
  onEndDateSelect,
  onPeriodConfig,
  period,
}) => {
  const _ = require("lodash");
  // 연도 선택 select box에 보여질 데이터 : range(시작 연도, 끝 연도, 연도 간격)
  const years = _.range(2020, getYear(new Date()) + 1, 1);

  // 월 선택 select box에 보여질 데이터
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-white" onClick={onClick} ref={ref}>
      {value}
      <i className="fa fa-calendar fa-fw text-black-transparent-5 m-l-5"></i>
    </button>
  ));

  return (
    <div className="btn-group m-b-15 m-r-5">
      <div className="m-r-5">
        <DatePicker
          customInput={<CustomInput />}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="custom-react-datepicker__select-wrapper">
              {/* 이전 월로 이동하는 버튼 */}
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <i className="fa fa-angle-left fa-lg"></i>
              </button>
              <div className="custom-react-datepicker__select-item">
                {/* 연도 선택 select box */}
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>년</span>
              </div>
              <div className="custom-react-datepicker__select-item">
                {/* 월 선택 select box */}
                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>월</span>
              </div>
              {/* 다음 월로 이동하는 버튼 */}
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <i className="fa fa-angle-right fa-lg"></i>
              </button>
            </div>
          )}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={onStartDateSelect}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <span className="custom-react-datepicker__split-span">~</span>
      <div className="m-r-5">
        <DatePicker
          customInput={<CustomInput />}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="custom-react-datepicker__select-wrapper">
              {/* 이전 월로 이동하는 버튼 */}
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <i className="fa fa-angle-left fa-lg"></i>
              </button>
              <div className="custom-react-datepicker__select-item">
                {/* 연도 선택 select box */}
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>년</span>
              </div>
              <div className="custom-react-datepicker__select-item">
                {/* 월 선택 select box */}
                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>월</span>
              </div>
              {/* 다음 월로 이동하는 버튼 */}
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <i className="fa fa-angle-right fa-lg"></i>
              </button>
            </div>
          )}
          locale={ko}
          dateFormat="yyyy-MM-dd"
          selected={endDate}
          onChange={onEndDateSelect}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div>
        {period.map((item, index) => {
          return (
            <Button
              key={index}
              className={`btn btn-white m-r-5`}
              value={item.id}
              onClick={onPeriodConfig}
            >
              {item.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default CustomDatePicker;
