import React from "react";
import { Route } from "react-router-dom";
import SidebarNavList from "./SidebarNavList";
import { AdministratorMenu, IssuerMenu, StoreMenu } from "./Menu";

class SidebarNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
    };
    this.menus =
      (props.userData.AdminInfo.role === "Administrator" &&
        AdministratorMenu) ||
      (props.userData.AdminInfo.role === "Issuer" && IssuerMenu) ||
      (props.userData.AdminInfo.role === "Store" && StoreMenu);
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState((state) => ({
        active: -1,
        clicked: 1,
      }));
    } else {
      this.setState((state) => ({
        active: this.state.active === i ? -1 : i,
        clicked: 1,
      }));
    }
  }

  render() {
    return (
      <ul className="nav">
        {this.menus.map((menu, i) => {
          return (
            <Route
              key={i}
              path={menu.path[0]}
              exact={menu.exact}
              children={({ match }) => {
                return (
                  <SidebarNavList
                    data={menu}
                    key={i}
                    expand={(e) => this.handleExpand(e, i, match)}
                    active={i === this.state.active}
                    clicked={this.state.clicked}
                  />
                );
              }}
            />
          );
        })}
      </ul>
    );
  }
}

export default SidebarNav;
