import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { TICKET_PRODUCT_DETAIL_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location, config }) => {
  const { data, loading } = useQuery(TICKET_PRODUCT_DETAIL_QUERY, {
    variables: { id: match.params.id },
  });

  if (!loading) {
    const {
      StoreTicketProductDetail: {
        id,

        coverImage,

        store,

        managerName,
        managerTel,
        managerEmail,

        minute,
        limit,
        isPay,

        price,
        isDiscount,
        discountPrice,

        applyStartDateTime,
        applyEndDateTime,
        placeType,

        placeZipcode,
        placeAddress,
        placeAddressDetail,
        placeLongitude,
        placeLatitude,

        ticketCategory,
        name,
        contents,

        isSoldOut,
        timestamp,

        ticketProductDatesCount,
      },
    } = data;

    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        config={config}
        id={id}
        coverImageSrc={coverImage}
        storeId={store.id}
        storeName={store.name}
        managerName={managerName}
        managerTel={managerTel}
        managerEmail={managerEmail}
        minute={minute}
        limit={limit}
        isPay={isPay}
        price={price}
        isDiscount={isDiscount}
        discountPrice={discountPrice}
        applyStartDateTimeAt={applyStartDateTime}
        applyEndDateTimeAt={applyEndDateTime}
        placeType={placeType}
        placeZipcode={placeZipcode}
        placeAddress={placeAddress}
        placeAddressDetail={placeAddressDetail}
        placeLongitude={placeLongitude}
        placeLatitude={placeLatitude}
        ticketCategoryId={ticketCategory.id}
        ticketCategoryName={
          ticketCategory.parentCategory
            ? `${ticketCategory.parentCategory.name} > ${ticketCategory.name}`
            : `${ticketCategory.name}`
        }
        name={name}
        contents={contents}
        isSoldOut={isSoldOut}
        timestamp={timestamp}
        ticketProductDatesCount={ticketProductDatesCount}
      />
    );
  } else {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }
});
