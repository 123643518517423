import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useMutation } from "react-apollo-hooks";
import { USER_WRITE_QUERY } from "./Queries";
import { useForm } from "react-hook-form";
import DaumPostcode from "react-daum-postcode";
import { Panel, PanelHeader, PanelBody } from "../../../Components/Panel/Panel";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { currencyArray } from "../../../Components/User/UserNames";

export default withRouter(({ history, match, location }) => {
  const [userWriteMutation] = useMutation(USER_WRITE_QUERY);
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [profileCoverImage, setProfileCoverImage] = useState(
    "/assets/img/user/user-default.jpg"
  );
  const handleProfileCoverImage = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setProfileCoverImage(reader.result);
    };
  };
  const onClick_cover = () => {
    document.getElementById("coverImage").click();
  };

  const [zipcodeModal, setZipcodeModal] = useState(false);
  const handleZipcodeModal = () => {
    setZipcodeModal(!zipcodeModal);
  };
  const handleAddress = (data) => {
    const API_KEY = "7da7d093496c67033d3e14aa3102e838";
    const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${data.roadAddress}&size=15`;
    fetch(url, {
      headers: {
        Authorization: `KakaoAK ${API_KEY}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setValue("zipcode", data.zonecode);
        setValue("roadAddress", data.roadAddress);
        setValue("jibunAddress", data.jibunAddress);

        setValue("longitude", json.documents[0].x);
        setValue("latitude", json.documents[0].y);

        handleZipcodeModal();
      });
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (!regex.test(e.target.value)) {
      setValue("authPassword", "");
    }
  };

  const onSubmit = async (data) => {
    try {
      const {
        data: { AdminUserWrite },
      } = await userWriteMutation({
        variables: {
          confimed: true,
          blocked: false,
          userId: data.userId,
          name: data.name,
          password: data.password,
          authPassword: data.authPassword,
          email: data.email,
          phone: data.phone,
          addCurrency: data.addCurrency,
          zipcode: data.zipcode,
          roadAddress: data.roadAddress,
          jibunAddress: data.jibunAddress,
          addressDetail: data.addressDetail,
          avatar: data.coverImage[0],
        },
      });
      if (AdminUserWrite) {
        toast.success("처리 되었습니다.");
        history.push(`/user/Issuer`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  return (
    <>
      <Helmet>
        <title>발행인 추가 | 한밭페이 </title>
      </Helmet>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/users/Issuer`}>발행인</Link>
            </li>
            <li className="breadcrumb-item active">발행인 추가</li>
          </ol>
          <h1 className="page-header">발행인 추가</h1>
          <div className="row m-b-15">
            <div className="col-xs-6 text-left">
              <Link
                to={`/users/Issuer${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>프로필 이미지</PanelHeader>
                <PanelBody>
                  <div className="form-group row m-b-0">
                    <div className="col-md-12">
                      <div className="vertical-box vertical-box-widget height-200 bg-gray-transparent-1 rounded">
                        <div className="vertical-box-column valign-middle text-center">
                          <img
                            src={profileCoverImage}
                            alt="이미지 업로드"
                            className="height-200"
                          />
                        </div>
                      </div>
                      <div className="m-t-10">
                        <input
                          type="file"
                          id="coverImage"
                          name="coverImage"
                          className="hide"
                          onChange={handleProfileCoverImage}
                          ref={register({})}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={onClick_cover}
                        >
                          프로필 이미지 업로드
                        </button>
                      </div>
                      {errors.coverImage && (
                        <span className="text-danger">
                          {errors.coverImage.message}
                        </span>
                      )}
                      <div className="alert alert-primary fade show m-t-10 m-b-0">
                        <span className="close" data-dismiss="alert">
                          ×
                        </span>
                        <b>프로필이미지 사이즈 : </b> 넓이 1200px X 높이 1024px
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
            <div className="col-md-9 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>작성</PanelHeader>
                <PanelBody className="panel-form">
                  <div className="form-horizontal form-bordered">
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">아이디</label>
                      <div className="col-md-10">
                        <input
                          name="userId"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="아이디 입력"
                          ref={register({
                            required: "아이디을 입력해주세요.",
                          })}
                        />
                        {errors.userId && <span>{errors.userId.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        관리 화폐
                      </label>
                      <div className="col-md-10">
                        {Object.entries(currencyArray)
                          .filter(([k, v]) => v.addCurrency === true)
                          .map(([k, v], index) => {
                            return (
                              <div
                                key={index}
                                className="radio radio-css radio-inline"
                              >
                                <input
                                  type="radio"
                                  id={`addCurrency${k}`}
                                  name="addCurrency"
                                  value={k}
                                  ref={register({
                                    required: "관리 화폐를 선택해주세요.",
                                  })}
                                />
                                <label htmlFor={`addCurrency${k}`}>
                                  {v.name}
                                </label>
                              </div>
                            );
                          })}

                        {errors.addCurrency && (
                          <div>{errors.addCurrency.message}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">이름</label>
                      <div className="col-md-10">
                        <input
                          name="name"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="이름 입력"
                          ref={register({
                            required: "이름을 입력해주세요.",
                          })}
                        />
                        {errors.name && <span>{errors.name.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        비밀번호
                      </label>
                      <div className="col-md-10">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          ref={register({
                            required: "비밀번호를 입력해주세요.",
                          })}
                        />
                        {errors.password && (
                          <span>{errors.password.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        비밀번호 확인
                      </label>
                      <div className="col-md-10">
                        <input
                          type="password"
                          name="passwordConfirm"
                          className="form-control"
                          ref={register({
                            required: "비밀번호 확인을 입력해주세요.",
                            validate: (value) => {
                              return value !== watch("password")
                                ? "비밀번호와 비밀번호확인이 일치하지 않습니다. 다시 입력해주세요."
                                : undefined;
                            },
                          })}
                        />
                        {errors.passwordConfirm && (
                          <span>{errors.passwordConfirm.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        승인비밀번호
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="authPassword"
                          className="form-control"
                          onChange={handleChange}
                          ref={register({
                            required: "승인비밀번호를 입력해주세요.",
                          })}
                          minLength={6}
                          maxLength={6}
                        />
                        {errors.authPassword && (
                          <span>{errors.authPassword.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        우편번호
                      </label>
                      <div className="col-md-10">
                        <InputGroup size="sm">
                          <input
                            type="text"
                            name="zipcode"
                            className="form-control"
                            placeholder="우편번호를 입력해주세요."
                            ref={register({})}
                            readOnly
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              size="sm"
                              color="primary"
                              onClick={handleZipcodeModal}
                            >
                              <i className="fa fa-search fa-fw"></i>
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                        {errors.zipcode && (
                          <span>{errors.zipcode.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        도로명주소
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="roadAddress"
                          className="form-control"
                          placeholder="도로명주소를 입력해주세요."
                          ref={register({})}
                          readOnly
                        />
                        {errors.roadAddress && (
                          <span>{errors.roadAddress.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        지번주소
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="jibunAddress"
                          className="form-control"
                          placeholder="지번주소를 입력해주세요."
                          ref={register({})}
                          readOnly
                        />
                        {errors.jibunAddress && (
                          <span>{errors.jibunAddress.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        상세주소
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          name="addressDetail"
                          className="form-control"
                          placeholder="상세주소를 입력해주세요."
                          ref={register({})}
                        />
                        {errors.addressDetail && (
                          <span>{errors.addressDetail.message}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">이메일</label>
                      <div className="col-md-10">
                        <input
                          name="email"
                          type="text"
                          className="form-control m-b-5"
                          placeholder="이메일 입력"
                          ref={register({})}
                        />
                        {errors.email && <span>{errors.email.message}</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        휴대전화
                      </label>
                      <div className="col-md-10">
                        <input
                          name="phone"
                          type="text"
                          onChange={handleChange}
                          maxLength={11}
                          className="form-control m-b-5"
                          placeholder="휴대전화 입력"
                          ref={register({})}
                        />
                        {errors.phone && <span>{errors.phone.message}</span>}
                      </div>
                    </div>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 text-left">
              <Link
                to={`/users/Issuer${location.search}`}
                className="btn btn-white"
              >
                목록
              </Link>
            </div>
            <div className="col-xs-6 text-right">
              <div className="btn-group">
                <button type="submit" className="btn btn-white text-sucess">
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal isOpen={zipcodeModal} toggle={handleZipcodeModal}>
        <ModalHeader toggle={handleZipcodeModal}>우편번호 검색</ModalHeader>
        <ModalBody>
          <DaumPostcode height={700} onComplete={handleAddress} />
        </ModalBody>
      </Modal>
    </>
  );
});
