import { gql } from "apollo-boost";

/* 상품 설정 */
export const TICKET_CONFIG_LIST_QUERY = gql`
  query AdminTicketConfigList($skip: Int, $first: Int) {
    AdminTicketConfigList(orderBy: "id_ASC", skip: $skip, first: $first) {
      ticketConfigs {
        id
        name
        createdAt
        updatedAt
        ticketProductsCount
      }
      count
    }
  }
`;
export const TICKET_CONFIG_QUERY = gql`
  query AdminTicketConfig($id: String) {
    AdminTicketConfig(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const TICKET_CONFIG_WRITE_QUERY = gql`
  mutation AdminTicketConfigWrite($name: String!) {
    AdminTicketConfigWrite(name: $name)
  }
`;
export const TICKET_CONFIG_EDIT_QUERY = gql`
  mutation AdminTicketConfigEdit($id: String!, $name: String!) {
    AdminTicketConfigEdit(id: $id, name: $name)
  }
`;

/* 상품 분류 */
export const TICKET_CATEGORY_LIST_QUERY = gql`
  query AdminTicketCategoryList(
    $ticketConfig: String
    $parentId: String
    $skip: Int
    $first: Int
  ) {
    AdminTicketCategoryList(
      ticketConfig: $ticketConfig
      parentId: $parentId
      orderBy: "order_ASC"
      skip: $skip
      first: $first
    ) {
      ticketCategories {
        id
        parentId
        ticketConfig {
          id
        }
        commonStatus
        iconImage
        name
        order
        createdAt
        updatedAt

        ticketProductsCount
        childrenCount
        children {
          id
          parentId
          ticketConfig {
            id
          }
          commonStatus
          iconImage
          name
          order
          createdAt
          updatedAt

          ticketProductsCount
          childrenCount
          children {
            id
            parentId
            ticketConfig {
              id
            }
            commonStatus
            iconImage
            name
            order
            createdAt
            updatedAt

            ticketProductsCount
            childrenCount
          }
        }
      }
      count
    }
  }
`;
export const TICKET_CATEGORY_WRITE_QUERY = gql`
  mutation AdminTicketCategoryWrite(
    $ticketConfig: String!
    $commonStatus: String!
    $iconImage: IconUpload
    $name: String!
    $parentId: String
  ) {
    AdminTicketCategoryWrite(
      ticketConfig: $ticketConfig
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
      parentId: $parentId
    )
  }
`;
export const TICKET_CATEGORY_DETAIL_QUERY = gql`
  query AdminTicketCategoryDetail($id: String!) {
    AdminTicketCategoryDetail(id: $id) {
      id
      ticketConfig {
        id
      }
      commonStatus
      iconImage
      name
      createdAt
      updatedAt

      ticketProductsCount
      childrenCount
    }
  }
`;
export const TICKET_CATEGORY_EDIT_QUERY = gql`
  mutation AdminTicketCategoryEdit(
    $id: String!
    $ticketConfig: String!
    $commonStatus: String!
    $iconImage: IconUpload
    $name: String!
  ) {
    AdminTicketCategoryEdit(
      id: $id
      ticketConfig: $ticketConfig
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
    )
  }
`;
export const TICKET_CATEGORY_MOVE_QUERY = gql`
  mutation AdminTicketCategoryMove(
    $id: String!
    $target: String!
    $position: String!
  ) {
    AdminTicketCategoryMove(id: $id, target: $target, position: $position)
  }
`;
export const TICKET_CATEGORY_DELETE_QUERY = gql`
  mutation AdminTicketCategoryDelete($id: String!) {
    AdminTicketCategoryDelete(id: $id)
  }
`;

/* 상품 */
export const TICKET_PRODUCT_LIST_QUERY = gql`
  query AdminTicketProductList(
    $ticketConfig: String
    $ticketCategory: String
    $commonStatus: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    AdminTicketProductList(
      ticketConfig: $ticketConfig
      ticketCategory: $ticketCategory
      commonStatus: $commonStatus
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketProducts {
        id
        ticketConfig {
          id
          name
        }
        commonStatus

        coverImage

        store {
          id
          name
        }

        managerName
        managerTel
        managerEmail

        minute
        limit
        isPay

        price
        isDiscount
        discountPrice

        applyStartDateTime
        applyEndDateTime
        placeType

        placeZipcode
        placeAddress
        placeAddressDetail
        placeLongitude
        placeLatitude

        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        name
        contents

        isSoldOut
        timestamp

        createdAt
        updatedAt

        ticketProductDates {
          id
          commonStatus
          ticketDate
          ticketProductDateTimes {
            id
            commonStatus
            isSoldOut
            ticketDateTime
            # ticketOrdersCount

            ticketOrderProductPeoplesCount
          }
          # ticketOrdersCount
          ticketOrderProductPeoplesCount
        }
      }
      count
    }
  }
`;
export const TICKET_PRODUCT_WRITE_QUERY = gql`
  mutation AdminTicketProductWrite(
    $ticketConfig: String!
    $coverImage: FileUpload!
    $store: String!
    $managerName: String
    $managerTel: String
    $managerEmail: String
    $minute: String!
    $limit: String!
    $isPay: Boolean!
    $price: String
    $isDiscount: Boolean
    $discountPrice: String
    $applyStartDateTime: String!
    $applyEndDateTime: String!
    $placeType: String!
    $placeZipcode: String
    $placeAddress: String
    $placeAddressDetail: String
    $placeLongitude: String
    $placeLatitude: String
    $ticketCategory: String!
    $name: String!
    $contents: String
    $timestamp: String!
  ) {
    AdminTicketProductWrite(
      ticketConfig: $ticketConfig

      coverImage: $coverImage

      store: $store

      managerName: $managerName
      managerTel: $managerTel
      managerEmail: $managerEmail

      minute: $minute
      limit: $limit
      isPay: $isPay

      price: $price
      isDiscount: $isDiscount
      discountPrice: $discountPrice

      applyStartDateTime: $applyStartDateTime
      applyEndDateTime: $applyEndDateTime
      placeType: $placeType

      placeZipcode: $placeZipcode
      placeAddress: $placeAddress
      placeAddressDetail: $placeAddressDetail
      placeLongitude: $placeLongitude
      placeLatitude: $placeLatitude

      ticketCategory: $ticketCategory
      name: $name
      contents: $contents

      timestamp: $timestamp
    )
  }
`;
export const STORE_LIST_QUERY = gql`
  query AdminStoreList($keyword: String, $skip: Int, $first: Int) {
    AdminStoreList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      stores {
        id
        name
        storeCEO
      }
      count
    }
  }
`;
export const TICKET_PRODUCT_DETAIL_QUERY = gql`
  query AdminTicketProductDetail($id: String!) {
    AdminTicketProductDetail(id: $id) {
      id
      ticketConfig {
        id
        name
      }
      commonStatus

      coverImage

      store {
        id
        name
      }

      managerName
      managerTel
      managerEmail

      minute
      limit
      isPay

      price
      isDiscount
      discountPrice

      applyStartDateTime
      applyEndDateTime
      placeType

      placeZipcode
      placeAddress
      placeAddressDetail
      placeLongitude
      placeLatitude

      ticketCategory {
        id
        name
        parentCategory {
          id
          name
        }
      }
      name
      contents

      isSoldOut
      timestamp

      createdAt
      updatedAt

      ticketProductDates {
        id
        commonStatus
        ticketDate
        ticketProductDateTimes {
          id
          commonStatus
          isSoldOut
          ticketDateTime
          # ticketOrdersCount

          ticketOrderProductPeoplesCount
        }
        # ticketOrdersCount
        ticketOrderProductPeoplesCount
      }
      ticketProductDatesCount
      ticketProductDateTimesCount

      ticketProductReviews {
        id
        commonStatus
        # orderProduct {
        #   id
        # }
        contents
        createdAt
        updatedAt
      }
      ticketProductInquiries {
        id
        commonStatus
        isSecret
        question
        answer
        createdAt
        updatedAt
      }
    }
  }
`;
export const TICKET_PRODUCT_EDIT_QUERY = gql`
  mutation AdminTicketProductEdit(
    $id: String!
    $coverImage: FileUpload
    $commonStatus: String!
    $isSoldOut: Boolean
    $store: String!
    $managerName: String
    $managerTel: String
    $managerEmail: String
    $minute: String!
    $limit: String!
    $isPay: Boolean!
    $price: String
    $isDiscount: Boolean
    $discountPrice: String
    $applyStartDateTime: String!
    $applyEndDateTime: String!
    $placeType: String!
    $placeZipcode: String
    $placeAddress: String
    $placeAddressDetail: String
    $placeLongitude: String
    $placeLatitude: String
    $ticketCategory: String!
    $name: String!
    $contents: String
  ) {
    AdminTicketProductEdit(
      id: $id

      coverImage: $coverImage

      commonStatus: $commonStatus
      isSoldOut: $isSoldOut

      store: $store

      managerName: $managerName
      managerTel: $managerTel
      managerEmail: $managerEmail

      minute: $minute
      limit: $limit
      isPay: $isPay

      price: $price
      isDiscount: $isDiscount
      discountPrice: $discountPrice

      applyStartDateTime: $applyStartDateTime
      applyEndDateTime: $applyEndDateTime
      placeType: $placeType

      placeZipcode: $placeZipcode
      placeAddress: $placeAddress
      placeAddressDetail: $placeAddressDetail
      placeLongitude: $placeLongitude
      placeLatitude: $placeLatitude

      ticketCategory: $ticketCategory
      name: $name
      contents: $contents
    )
  }
`;
export const TICKET_PRODUCT_EDIT_COVER_QUERY = gql`
  mutation AdminTicketProductCoverImageEdit($id: String!, $file: FileUpload!) {
    AdminTicketProductCoverImageEdit(id: $id, file: $file) {
      id
      coverImage
    }
  }
`;
export const TICKET_PRODUCT_DELETE_COVER_QUERY = gql`
  mutation AdminTicketProductCoverImageDelete($id: String!) {
    AdminTicketProductCoverImageDelete(id: $id)
  }
`;
export const TICKET_PRODUCT_DELETE_QUERY = gql`
  mutation AdminTicketProductDelete($id: String!) {
    AdminTicketProductDelete(id: $id)
  }
`;

/* 티켓 일정 */
export const TICKET_PRODUCT_DATE_WRITE_QUERY = gql`
  mutation AdminTicketProductDateWrite(
    $ticketProduct: String!
    $commonStatus: String!
    $ticketDate: String!
    $ticketDateTimes: [WriteTicketDateTimeInput!]
  ) {
    AdminTicketProductDateWrite(
      ticketProduct: $ticketProduct
      commonStatus: $commonStatus
      ticketDate: $ticketDate
      ticketDateTimes: $ticketDateTimes
    )
  }
`;
export const TICKET_PRODUCT_DATE_REPETITION_WRITE_QUERY = gql`
  mutation AdminTicketProductDateRepetitionWrite(
    $ticketProduct: String!
    $commonStatus: String!
    $startDate: String!
    $endDate: String!
    $period: String!
    $term: String!
    $dayOption: [String!]
    $monthOption: String!
    $ticketDateTimes: [WriteTicketDateTimeInput!]
  ) {
    AdminTicketProductDateRepetitionWrite(
      ticketProduct: $ticketProduct
      commonStatus: $commonStatus
      startDate: $startDate
      endDate: $endDate
      period: $period
      term: $term
      dayOption: $dayOption
      monthOption: $monthOption
      ticketDateTimes: $ticketDateTimes
    )
  }
`;
export const TICKET_PRODUCT_DATE_DETAIL_QUERY = gql`
  query AdminTicketProductDateDetail($id: String!) {
    AdminTicketProductDateDetail(id: $id) {
      id
      commonStatus
      ticketDate

      createdAt
      updatedAt
    }
  }
`;
export const TICKET_PRODUCT_DATE_EDIT_QUERY = gql`
  mutation AdminTicketProductDateEdit(
    $id: String!
    $commonStatus: String!
    $ticketDate: String!
  ) {
    AdminTicketProductDateEdit(
      id: $id
      commonStatus: $commonStatus
      ticketDate: $ticketDate
    )
  }
`;
export const TICKET_PRODUCT_DATE_DELETE_QUERY = gql`
  mutation AdminTicketProductDateDelete($id: String!) {
    AdminTicketProductDateDelete(id: $id)
  }
`;

/* 티켓 상세 일정 */
export const TICKET_PRODUCT_DATETIME_WRITE_QUERY = gql`
  mutation AdminTicketProductDateTimeWrite(
    $ticketProductId: String!
    $ticketProductDateId: String!
    $commonStatus: String!
    $ticketDateTime: String!
  ) {
    AdminTicketProductDateTimeWrite(
      ticketProductId: $ticketProductId
      ticketProductDateId: $ticketProductDateId
      commonStatus: $commonStatus
      ticketDateTime: $ticketDateTime
    )
  }
`;
export const TICKET_PRODUCT_DATETIME_DETAIL_QUERY = gql`
  query AdminTicketProductDateTimeDetail($id: String!) {
    AdminTicketProductDateTimeDetail(id: $id) {
      id
      commonStatus
      isSoldOut
      ticketDateTime
      createdAt
      updatedAt
    }
  }
`;
export const TICKET_PRODUCT_DATETIME_EDIT_QUERY = gql`
  mutation AdminTicketProductDateTimeEdit(
    $id: String!
    $commonStatus: String!
    $isSoldOut: Boolean
    $ticketDateTime: String!
  ) {
    AdminTicketProductDateTimeEdit(
      id: $id
      commonStatus: $commonStatus
      isSoldOut: $isSoldOut
      ticketDateTime: $ticketDateTime
    )
  }
`;
export const TICKET_PRODUCT_DATETIME_DELETE_QUERY = gql`
  mutation AdminTicketProductDateTimeDelete($id: String!) {
    AdminTicketProductDateTimeDelete(id: $id)
  }
`;

/* 상품 이미지 */
export const COMMONFILE_WRITE_QUERY = gql`
  mutation AdminCommonFileWrite(
    $moduleId: String!
    $targetType: String!
    $timestamp: String!
    $commonFile: CommonFileUpload!
  ) {
    AdminCommonFileWrite(
      moduleType: "ticketConfig"
      moduleId: $moduleId
      moduleTable: "ticketProduct"
      targetType: $targetType
      timestamp: $timestamp
      commonFile: $commonFile
    ) {
      id
      moduleType
      moduleId
      moduleTable
      targetType
      timestamp

      filepath
      filename
      mimetype
      encoding

      createdAt
      updatedAt
    }
  }
`;
