import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactMoment from "react-moment";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
  transactionTypeName,
  currencyName,
} from "../../../../../Components/User/UserNames";
import NumberFormat from "react-number-format";
// import { graphqlUri } from "../../../../Config/Env";
// import { toast } from "react-toastify";
import qs from "qs";

export default ({
  history,
  match,
  location,
  user,
  trustline,
  data,
  year,
  month,
  prevYear,
  prevMonth,
  nextYear,
  nextMonth,

  isModal,
}) => {
  const queryString = qs.parse(location.search.substr(1));
  const query = { keyword: queryString.keyword, page: queryString.page };

  const modalRef = React.createRef();

  const {
    AdminLedgerList: { ledgers, count },
  } = data;

  useEffect(() => {
    const element = modalRef.current;

    if (isModal) {
      disableBodyScroll(element);
    }

    return () => {
      enableBodyScroll(element);
    };
  });

  return (
    <>
      <Modal
        isOpen={true}
        size={"lg"}
        ref={modalRef}
        toggle={() => {
          history.push(
            `/users/${match.params.role}/${match.params.id}?${qs.stringify({
              ...query,
            })}`
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            history.push(
              `/users/${match.params.role}/${match.params.id}?${qs.stringify({
                ...query,
              })}`
            );
          }}
        >
          {user && user.AdminUserDetail.name}님{" "}
          {trustline && currencyName(trustline.AdminTrustlineDetail.currency)}{" "}
          목록
        </ModalHeader>
        <ModalBody>
          <div className="widget widget-stats bg-green">
            <div className="stats-icon">
              <i className="fa fa-money-bill-alt"></i>
            </div>
            <div className="stats-info">
              <h4>합계</h4>
              <p>
                {trustline && (
                  <NumberFormat
                    value={trustline.AdminTrustlineDetail.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                )}
                {trustline &&
                  currencyName(trustline.AdminTrustlineDetail.currency)}
              </p>
            </div>
          </div>

          <div className="row m-b-15">
            <div className="col-xs-4 text-right">
              <Link
                to={`/users/${match.params.role}/${
                  match.params.id
                }/trustlines/${match.params.trustline}?${qs.stringify({
                  year: prevYear,
                  month: prevMonth,
                  ...query,
                })}`}
                className="btn btn-white btn-xs text-green"
              >
                <i className="fa fa-angle-left" />{" "}
                {prevMonth === 0
                  ? `${prevYear - 1}년 ${12}월`
                  : `${prevYear}년 ${prevMonth}월`}
              </Link>
            </div>
            <div className="col-xs-4 text-center">
              {year}년 {month}월
            </div>
            <div className="col-xs-4 text-left">
              <Link
                to={`/users/${match.params.role}/${
                  match.params.id
                }/trustlines/${match.params.trustline}?${qs.stringify({
                  year: nextYear,
                  month: nextMonth,
                  ...query,
                })}`}
                className="btn btn-white btn-xs text-green"
              >
                {nextMonth === 0
                  ? `${nextYear - 1}년 ${12}월`
                  : `${nextYear}년 ${nextMonth}월`}{" "}
                <i className="fa fa-angle-right" />
              </Link>
            </div>
          </div>

          {count > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-b-0">
                  <thead>
                    <tr>
                      {/* <th>id</th> */}
                      <th>형태</th>
                      <th>대상자</th>
                      <th>내용</th>
                      <th>일자</th>
                      <th>금액</th>
                      <th>합계</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledgers.map((ledger) => {
                      return (
                        <tr key={ledger.id}>
                          {/* <td>{ledger.id}</td> */}
                          <td>{transactionTypeName(ledger.transactionType)}</td>
                          <td>{ledger.destinationWallet.userId}</td>
                          <td>{ledger.title}</td>
                          <td>
                            <ReactMoment format="YYYY-MM-DD HH:mm:ss">
                              {ledger.createdAt}
                            </ReactMoment>
                          </td>
                          <td align="right">
                            {ledger.wallet.id === ledger.destinationWallet.id &&
                            ledger.destinationWallet.id
                              ? ""
                              : "-"}
                            <NumberFormat
                              value={ledger.value}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            {currencyName(ledger.currency)}
                          </td>
                          <td align="right">
                            <NumberFormat
                              value={ledger.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                            {currencyName(ledger.currency)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p className="text-center">
              {year}년 {month}월 등록된 데이터가 없습니다.
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Link
            to={`/users/${match.params.role}/${match.params.id}/trustlines/${
              match.params.trustline
            }/remittance?${qs.stringify({
              year: nextYear,
              month: nextMonth,
              ...query,
            })}`}
            className="btn btn-white"
          >
            송금
          </Link>
        </ModalFooter>
      </Modal>
    </>
  );
};
