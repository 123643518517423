import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { withRouter, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../../../Components/Panel/Panel";
import { Alert } from "reactstrap";
import DeliveryEditor from "../../../../../Components/Editor/DeliveryEditor";
import { commonStatusName } from "../../../../../Components/User/UserNames";
import CoverImage from "../../../../../Containers/DeliverySet/CoverImage";
import StoreProducts from "../../../../../Containers/DeliverySet/StoreProducts";

import { useMutation } from "react-apollo-hooks";
import {
  STORE_DELIVERY_SET_EDIT_MUTATION,
  STORE_DELIVERY_SET_LIST_QUERY,
} from "../Queries";
import Swal from "sweetalert2";

const Edit = ({ history, match, location, data, baseUrl }) => {
  const {
    StoreDeliverySetDetail: {
      id,
      createdAt,
      updatedAt,

      commonStatus,
      coverImage,
      timestamp,
      totalPrice,
      name,
      description,

      deliverySetProducts,
    },
  } = data;

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      commonStatus,
      name,
      description,
    },
  });

  const [
    StoreDeliverySetEditMutation,
    { loading: StoreDeliverySetEditLoading },
  ] = useMutation(STORE_DELIVERY_SET_EDIT_MUTATION, {
    refetchQueries: [
      {
        query: STORE_DELIVERY_SET_LIST_QUERY,
        variables: { take: 15, skip: 0 },
      },
    ],
  });

  const onSubmit = async (data) => {
    try {
      if (StoreDeliverySetEditLoading) {
        return;
      }

      const {
        data: { StoreDeliverySetEdit },
      } = await StoreDeliverySetEditMutation({
        variables: {
          id,
          commonStatus: data.commonStatus,
          name: data.name,
          description: data.description,
        },
      });

      if (StoreDeliverySetEdit) {
        Swal.fire({
          icon: "info",
          title: "안내",
          text: "처리 되었습니다.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "확인",
          focusConfirm: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`${baseUrl}${location.search}`);
          }
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "오류",
        text: "에러가 발생했습니다. 다시 시도해 주세요.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "확인",
        focusConfirm: true,
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Fragment>
      <div className="row m-b-15">
        <div className="col-xs-4 text-left">
          <Link to={`${baseUrl}${location.search}`} className="btn btn-white">
            목록
          </Link>
        </div>
        <div className="col-xs-4 text-center"></div>
        <div className="col-xs-4 text-right">
          {/* <Link
            to={`${baseUrl}/${id}/logs${location.search}`}
            className="btn btn-white"
          >
            수정이력
          </Link> */}
        </div>
      </div>

      <Alert color="primary" className="m-b-15">
        <strong className="f-s-15">※ 주의사항(필독)</strong>
        <br /> * 커버 이미지 : 이미지 편집시 실시간으로 처리됩니다. 삭제에
        주의하세요.
      </Alert>

      <div className="row m-b-15">
        <div className="col-sm-3">
          <CoverImage id={id} coverImage={coverImage} baseUrl={baseUrl} />
        </div>
        <div className="col-sm-9">
          <form className="margin-bottom-0" onSubmit={handleSubmit(onSubmit)}>
            <Panel>
              <PanelHeader noButton>기본정보</PanelHeader>
              <PanelBody className="panel-form form-horizontal form-bordered">
                <Controller
                  control={control}
                  name="commonStatus"
                  rules={{ required: "상태를 선택해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상태</label>
                      <div className="col-md-10">
                        <div>
                          <div className="radio radio-css radio-inline">
                            <input
                              type="radio"
                              id="commonStatus1"
                              name="commonStatus"
                              onChange={onChange}
                              onBlur={onBlur}
                              value={"S"}
                              checked={value === "S"}
                            />
                            <label htmlFor="commonStatus1">
                              {commonStatusName("S")}
                            </label>
                          </div>
                          <div className="radio radio-css radio-inline">
                            <input
                              type="radio"
                              id="commonStatus2"
                              name="commonStatus"
                              onChange={onChange}
                              onBlur={onBlur}
                              value={"C"}
                              checked={value === "C"}
                            />
                            <label htmlFor="commonStatus2">
                              {commonStatusName("C")}
                            </label>
                          </div>
                          <div className="radio radio-css radio-inline">
                            <input
                              type="radio"
                              id="commonStatus3"
                              onChange={onChange}
                              onBlur={onBlur}
                              value={"D"}
                              checked={value === "D"}
                            />
                            <label htmlFor="commonStatus3">
                              {commonStatusName("D")}
                            </label>
                          </div>
                        </div>
                        {errors.commonStatus && (
                          <span className="text-red errorText">
                            {errors.commonStatus.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "상품명을 입력해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">상품명</label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="상품명을 입력해주세요."
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value || ""}
                        />
                        {errors.name && (
                          <span className="text-red errorText">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  name="description"
                  rules={{ required: "상세정보를 입력해주세요." }}
                  render={({ onChange, onBlur, value }) => (
                    <div className="form-group row">
                      <label className="col-form-label col-md-2">
                        상세정보
                      </label>
                      <div className="col-md-10">
                        <DeliveryEditor
                          timestamp={timestamp}
                          onChange={onChange}
                          value={value || ""}
                        />
                        {errors.description && (
                          <span className="text-red errorText">
                            {errors.description.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
              </PanelBody>
            </Panel>

            <div className="row">
              <div className="col-xs-4 text-left"></div>
              <div className="col-xs-4 text-center">
                <div className="btn-group">
                  <button type="submit" className="btn btn-white text-blue ">
                    확인
                  </button>
                </div>
              </div>
              <div className="col-xs-4 text-right"></div>
            </div>
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <StoreProducts
            deliverySetProducts={deliverySetProducts}
            totalPrice={totalPrice}
          />
        </div>
      </div>
    </Fragment>
  );
};

const Presenter = ({ history, match, location, loading, data, baseUrl }) => {
  return (
    <div>
      <Helmet>
        <title>묶음 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to={`/dashboard`}>Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={`/delivery/deliveryStores`}>로컬푸드 구독</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={`/delivery/deliveryProducts`}>상품</Link>
          </li>
          <li className="breadcrumb-item active">묶음</li>
        </ol>
        <h1 className="page-header">묶음</h1>

        {!loading ? (
          <Edit
            history={history}
            match={match}
            location={location}
            data={data}
            baseUrl={baseUrl}
          />
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </div>
  );
};

export default Presenter;
