import { gql } from "apollo-boost";

export const TICKET_EXCHANGE_LIST_QUERY = gql`
  query StoreTicketExchangeList($year: Int!) {
    StoreTicketExchangeList(year: $year) {
      ticketExchanges {
        id
        date
        name
        cashier
        totalPrice
        price
        commission
      }
      count
    }
  }
`;

export const TICKET_ORDER_LIST_QUERY = gql`
  query StoreTicketOrderList(
    $ticketProduct: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int!
    $first: Int!
  ) {
    StoreTicketOrderList(
      ticketProduct: $ticketProduct
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      ticketOrders {
        id
        ticketOrderProducts {
          id
          commonStatus
          user {
            id
            avatar
            userId
            name
          }

          totalPrice

          startDate
          endDate
          extensionCount

          isRefund
          meRefund

          createdAt
          updatedAt
        }

        commonStatus
        user {
          id
          userId
          name
        }
        receiver {
          id
          userId
          name
        }
        ticketProduct {
          id
          ticketCategory {
            id
            name
            parentCategory {
              id
              name
            }
          }
          store {
            id
            name
          }
          coverImage
          name
        }
        isDiscount
        discountPrice
        price

        ticketProductOptionDetail1 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        ticketProductOptionDetailPrice1
        ticketProductOptionDetail2 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        ticketProductOptionDetailPrice2
        ticketProductOptionDetail3 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        ticketProductOptionDetailPrice3
        ticketProductOptionDetail4 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        ticketProductOptionDetailPrice4
        ticketProductOptionDetail5 {
          id
          commonStatus
          isSoldOut
          name
          price
        }
        ticketProductOptionDetailPrice5

        count

        totalCount
        totalPrice

        message

        isRefund
        refundReceiver {
          id
        }
        refundTotalCount
        refundTotalPrice

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const TICKET_ORDER_DETAIL_QUERY = gql`
  query StoreTicketOrderDetail($id: String!) {
    StoreTicketOrderDetail(id: $id) {
      id
      ticketOrderProducts {
        id
        commonStatus
        user {
          id
          role
          avatar
          userId
          name
        }

        totalPrice

        startDate
        endDate
        extensionCount

        isRefund
        meRefund

        createdAt
        updatedAt
      }

      commonStatus
      user {
        id
        role
        userId
        name
      }
      receiver {
        id
        role
        userId
        name
      }
      ticketProduct {
        id
        ticketConfig {
          id
        }
        ticketCategory {
          id
          name
          parentCategory {
            id
            name
          }
        }
        store {
          id
          name
        }
        coverImage
        name
      }
      isDiscount
      discountPrice
      price

      ticketProductOptionDetail1 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      ticketProductOptionDetailPrice1
      ticketProductOptionDetail2 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      ticketProductOptionDetailPrice2
      ticketProductOptionDetail3 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      ticketProductOptionDetailPrice3
      ticketProductOptionDetail4 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      ticketProductOptionDetailPrice4
      ticketProductOptionDetail5 {
        id
        commonStatus
        isSoldOut
        name
        price
      }
      ticketProductOptionDetailPrice5

      count

      totalCount
      totalPrice

      message

      isRefund
      refundReceiver {
        id
      }
      refundTotalCount
      refundTotalPrice

      createdAt
      updatedAt
    }
  }
`;

export const TICKET_ORDER_REFUND_QUERY = gql`
  mutation StoreTicketOrderRefund(
    $id: String!
    $ticketOrderProductId: String!
  ) {
    StoreTicketOrderRefund(id: $id, ticketOrderProductId: $ticketOrderProductId)
  }
`;
