import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../../../../Components/Panel/Panel";
import { Button } from "reactstrap";
import { graphqlUri } from "../../../../../Config/Env";

export default ({ history, match, location, data, loading, onDelete }) => {
  const RenderItem = ({ depth, display, children }) => {
    depth++;
    return (
      <Fragment>
        {children.map((item, index) => {
          const childDisplay =
            display && item.commonStatus === "C" ? true : false;
          const iconImage =
            item.iconImage === "" || item.iconImage === null
              ? "/assets/img/category-default.png"
              : `${graphqlUri}${item.iconImage}`;
          return (
            <Fragment key={index}>
              <div
                className={`widget-list-item`}
                style={{ paddingLeft: `${30 * depth}px` }}
              >
                <div className="widget-list-media">
                  <img
                    src={iconImage}
                    className="height-50"
                    style={{ borderRadius: 10, overflow: "hidden" }}
                    alt={item.name}
                  />
                  {/* <i
                    className={`${item.pcIcon ? item.pcIcon : `fas fa-th`} ${
                      childDisplay
                        ? "bg-inverse text-white"
                        : "bg-grey text-inverse"
                    }`}
                  ></i> */}
                </div>
                <div className="widget-list-content">
                  <h4 className="widget-list-title">
                    {item.commonStatus === "S" ? "[대기]" : ""}
                    {item.name}
                    {/* {item.order} */}
                  </h4>
                  <p className="widget-list-desc">
                    {item.giftProductsCount > 0 ? item.giftProductsCount : 0}건
                  </p>
                </div>
                <div className="widget-list-action" style={{ width: "50%" }}>
                  <div className="pull-right">
                    {depth === 0 && (
                      <Link
                        className="btn btn-white btn-xs m-l-5"
                        to={`/giftConfigs/${match.params.config}/categories/${item.id}/write`}
                      >
                        추가
                      </Link>
                    )}
                    <Link
                      className="btn btn-white btn-xs m-l-5"
                      to={`/giftConfigs/${match.params.config}/categories/${item.id}/move`}
                    >
                      이동
                    </Link>
                    <Link
                      className="btn btn-white btn-xs m-l-5"
                      to={`/giftConfigs/${match.params.config}/categories/${item.id}/edit`}
                    >
                      수정
                    </Link>
                    <Button
                      className={`btn btn-white btn-xs m-l-5`}
                      disabled={item.childrenCount > 0 ? true : false}
                      onClick={() => {
                        if (window.confirm("삭제하시겠습니까?")) {
                          onDelete(item.id);
                        }
                      }}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              </div>
              {item.childrenCount > 0 && (
                <RenderItem
                  depth={depth}
                  display={childDisplay}
                  children={item.children}
                />
              )}
            </Fragment>
          );
        })}
      </Fragment>
    );
  };
  return (
    <Fragment>
      <Helmet>
        <title>상품관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">선물</li>
          <li className="breadcrumb-item">상품</li>
          <li className="breadcrumb-item active">분류설정</li>
        </ol>
        <h1 className="page-header">분류설정</h1>

        <div className="row p-b-15">
          <div className="col-xs-4 text-left">
            <Link
              to={`/giftConfigs/${match.params.config}/products${location.search}`}
              className="btn btn-white"
            >
              목록
            </Link>
          </div>
          <div className="col-xs-4 text-center"></div>
          <div className="col-xs-4 text-right"></div>
        </div>

        <p className="m-b-15">
          선물하기 상품의 분류를 설정 합니다. 분류는 2차 분류로 설정할 수
          있습니다.
        </p>

        <div className="row">
          <div className="col-xs-12">
            {!loading && data.AdminGiftCategoryList.count > 0 ? (
              <div className={"widget-list widget-list-rounded m-b-15"}>
                {data.AdminGiftCategoryList.giftCategories.map(
                  (item, index) => {
                    const depth = 0;
                    const display = item.commonStatus === "C" ? true : false;
                    const iconImage =
                      item.iconImage === "" || item.iconImage === null
                        ? "/assets/img/category-default.png"
                        : `${graphqlUri}${item.iconImage}`;
                    return (
                      <Fragment key={index}>
                        <div className="widget-list-item">
                          <div className="widget-list-media">
                            <img
                              src={iconImage}
                              className="height-50"
                              style={{ borderRadius: 10, overflow: "hidden" }}
                              alt={item.name}
                            />
                            {/* <i
                              className={`${
                                item.pcIcon ? item.pcIcon : `fas fa-th`
                              } ${
                                display
                                  ? "bg-inverse text-white"
                                  : "bg-grey text-inverse"
                              }`}
                            ></i> */}
                          </div>
                          <div className="widget-list-content">
                            <h4 className="widget-list-title">
                              {item.commonStatus === "S" ? "[대기]" : ""}
                              {item.name}
                              {/* {item.order} */}
                            </h4>
                            <p className="widget-list-desc">
                              {item.giftProductsCount}건
                            </p>
                          </div>
                          <div
                            className="widget-list-action"
                            style={{ width: "50%" }}
                          >
                            <div className="pull-right">
                              <Link
                                className="btn btn-white btn-xs m-l-5"
                                to={`/giftConfigs/${match.params.config}/categories/${item.id}/write`}
                              >
                                추가
                              </Link>
                              <Link
                                className="btn btn-white btn-xs m-l-5"
                                to={`/giftConfigs/${match.params.config}/categories/${item.id}/move`}
                              >
                                이동
                              </Link>
                              <Link
                                className="btn btn-white btn-xs m-l-5"
                                to={`/giftConfigs/${match.params.config}/categories/${item.id}/edit`}
                              >
                                수정
                              </Link>
                              <Button
                                className={`btn btn-white btn-xs m-l-5`}
                                disabled={item.childrenCount > 0 ? true : false}
                                onClick={() => {
                                  if (window.confirm("삭제하시겠습니까?")) {
                                    onDelete(item.id);
                                  }
                                }}
                              >
                                삭제
                              </Button>
                            </div>
                          </div>
                        </div>
                        {item.childrenCount > 0 && (
                          <RenderItem
                            depth={depth}
                            display={display}
                            children={item.children}
                          />
                        )}
                      </Fragment>
                    );
                  }
                )}
              </div>
            ) : (
              <Panel>
                <PanelBody>
                  <p className="text-center">등록된 데이터가 없습니다.</p>
                </PanelBody>
              </Panel>
            )}

            <Link
              to={`/giftConfigs/${match.params.config}/categories/write`}
              className="btn btn-default btn-block  m-b-15"
            >
              <i className="fas fa-plus"></i> 추가
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
