import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_DETAIL_QUERY } from "../Queries";
import Presenter from "./Presenter";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(STORE_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.id },
  });

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminStoreDetail) {
    const {
      AdminStoreDetail: {
        id,
        commonStatus,
        storeCategory,
        storeCategories,
        name,
        productInfo,
        storeInfo,

        storeNumber,
        storeOperationHour,
        storePersonalDay,
        storeTel,
        storeCEO,
        email,
        phone,

        storeZipcode,
        storeRoadAddress,
        storeJibunAddress,
        storeAddressDetail,
        longitude,
        latitude,

        bank_name,
        bank_num,
        bank_account_holder,

        isGift,
        giftedAt,

        drmPercent,
        gruPercent,
      },
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        commonStatus={commonStatus}
        storeCategory={storeCategory}
        storeCategories={storeCategories}
        name={name}
        productInfo={productInfo}
        storeNumber={storeNumber}
        storeInfo={storeInfo}
        storeOperationHour={storeOperationHour}
        storePersonalDay={storePersonalDay}
        storeTel={storeTel}
        storeCEO={storeCEO}
        email={email}
        phone={phone}
        storeZipcode={storeZipcode}
        storeRoadAddress={storeRoadAddress}
        storeJibunAddress={storeJibunAddress}
        storeAddressDetail={storeAddressDetail}
        longitude={longitude}
        latitude={latitude}
        bank_name={bank_name}
        bank_num={bank_num}
        bank_account_holder={bank_account_holder}
        isGift={isGift}
        giftedAt={giftedAt}
        drmPercent={drmPercent}
        gruPercent={gruPercent}
      />
    );
  }
});
