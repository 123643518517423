import { gql } from "apollo-boost";

export const GRU_USER_LIST_QUERY = gql`
  query AdminGRUUserList($keyword: String, $skip: Int, $first: Int) {
    AdminGRUUserList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      users {
        id
        userId
        name
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const CHARGEREQUESTGRU_APPROVAL_QUERY = gql`
  mutation AdminChargeRequestGRUApproval($id: String!) {
    AdminChargeRequestGRUApproval(id: $id)
  }
`;
export const CHARGEREQUESTGRU_CANCEL_QUERY = gql`
  mutation AdminChargeRequestGRUCancel($id: String!) {
    AdminChargeRequestGRUCancel(id: $id)
  }
`;
export const GRU_CHARGE_QUERY = gql`
  mutation AdminGRUCharge($user: String!, $value: Int!, $title: String!) {
    AdminCharge(user: $user, value: $value, title: $title)
  }
`;

export const CHARGEREQUESTGRU_LIST_QUERY = gql`
  query AdminChargeRequestGRUList(
    $commonStatus: String
    $startSearchDate: String
    $endSearchDate: String
    $keyword: String
    $skip: Int
    $first: Int
  ) {
    AdminChargeRequestGRUList(
      commonStatus: $commonStatus
      startSearchDate: $startSearchDate
      endSearchDate: $endSearchDate
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      chargeRequestGRUs {
        id
        commonStatus
        user {
          id
          role
          userId
          name
        }
        wallet {
          id
        }

        currency
        name
        amount

        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const CHARGEREQUESTGRU_WRITE_QUERY = gql`
  mutation AdminChargeRequestGRUWrite(
    $wallet: String!
    $currency: String!
    $amount: String!
  ) {
    AdminChargeRequestGRUWrite(
      wallet: $wallet
      currency: $currency
      amount: $amount
    )
  }
`;

export const GRU_WALLET_LIST_QUERY = gql`
  query AdminGRUWalletList($keyword: String, $skip: Int, $first: Int) {
    AdminGRUWalletList(
      keyword: $keyword
      orderBy: "id_DESC"
      skip: $skip
      first: $first
    ) {
      wallets {
        id
        user {
          id
          commonStatus
        }
        type
        userId
        name
        address
        secret
        trustlines {
          id
        }
        memo
        createdAt
        updatedAt
      }
      count
    }
  }
`;
