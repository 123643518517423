import React from "react";
import { withRouter } from "react-router-dom";
import Presenter from "./Presenter";
import qs from "qs";

import { useQuery } from "react-apollo-hooks";
import { STORE_DELIVERY_BOX_DAYOFWEEK_QUERY } from "../../DeliveryBox/Queries";

export default withRouter(({ history, match, location }) => {
  const queryString = qs.parse(location.search.substr(1));

  const baseUrl = "/delivery/deliveryBoxesDayOfWeek";

  const { data, loading, error } = useQuery(
    STORE_DELIVERY_BOX_DAYOFWEEK_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <Presenter
      history={history}
      match={match}
      location={location}
      loading={loading}
      data={data}
      baseUrl={baseUrl}
    />
  );
});
