import React from "react";
import { useQuery } from "react-apollo-hooks";
import { CHARGEREQUEST_CONFIG_QUERY } from "../Queries";
import InitPresenter from "./Presenter/Init";
import Container from "./Container";

const Index = () => {
  const { data, loading } = useQuery(CHARGEREQUEST_CONFIG_QUERY);

  if (loading) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  }

  if (!loading && data.AdminChargeRequestConfig) {
    return (
      <>
        <Container config={data}></Container>
      </>
    );
  }

  if (!loading && !data.AdminChargeRequestConfig) {
    return (
      <>
        <InitPresenter></InitPresenter>
      </>
    );
  }
};

export default Index;
