import { gql } from "apollo-boost";

/* 상품 분류 시작 */
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_LIST_QUERY = gql`
  query AdminDeliveryProductCategoryList($skip: Int!, $take: Int) {
    AdminDeliveryProductCategoryList(skip: $skip, take: $take) {
      deliveryProductCategories {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order

        deliveryProductsCount
      }
      count
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_WRITE_MUTATION = gql`
  mutation AdminDeliveryProductCategoryWrite(
    $commonStatus: String!
    $iconImage: FileUpload
    $name: String!
  ) {
    AdminDeliveryProductCategoryWrite(
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_DETAIL_QUERY = gql`
  query AdminDeliveryProductCategoryDetail($id: String!) {
    AdminDeliveryProductCategoryDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      iconImage
      name
      order

      deliveryProductsCount
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_EDIT_MUTATION = gql`
  mutation AdminDeliveryProductCategoryEdit(
    $id: String!
    $commonStatus: String!
    $iconImage: FileUpload
    $name: String!
  ) {
    AdminDeliveryProductCategoryEdit(
      id: $id
      commonStatus: $commonStatus
      iconImage: $iconImage
      name: $name
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_MOVE_MUTATION = gql`
  mutation AdminDeliveryProductCategoryMove(
    $id: String!
    $target: String!
    $position: String!
  ) {
    AdminDeliveryProductCategoryMove(
      id: $id
      target: $target
      position: $position
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_CATEGORY_DELETE_MUTATION = gql`
  mutation AdminDeliveryProductCategoryDelete($id: String!) {
    AdminDeliveryProductCategoryDelete(id: $id)
  }
`;
/* 상품 분류 끝 */

/* 상품 시작 */
export const ADMIN_DELIVERY_PRODUCT_LIST_QUERY = gql`
  query AdminDeliveryProductList(
    $skip: Int!
    $take: Int
    $keyword: String
    $deliveryProductCategory_id: String
    $commonStatus: String
  ) {
    AdminDeliveryProductList(
      skip: $skip
      take: $take
      keyword: $keyword
      deliveryProductCategory_id: $deliveryProductCategory_id
      commonStatus: $commonStatus
    ) {
      deliveryProducts {
        id
        createdAt
        updatedAt

        commonStatus
        coverImage
        timestamp
        price
        name
        description

        deliveryProductCategory {
          id
          createdAt
          updatedAt

          commonStatus
          iconImage
          name
          order
        }
        deliveryProductOptions {
          id
          createdAt
          updatedAt

          commonStatus
          name
          deliveryProductOptionDetails {
            id
            createdAt
            updatedAt

            commonStatus
            price
            name
          }
        }
      }
      count
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_WRITE_MUTATION = gql`
  mutation AdminDeliveryProductWrite(
    $commonStatus: String!
    $coverImage: FileUpload
    $timestamp: String
    $price: String
    $name: String
    $description: String
    $deliveryProductCategory_id: String
  ) {
    AdminDeliveryProductWrite(
      commonStatus: $commonStatus
      coverImage: $coverImage
      timestamp: $timestamp
      price: $price
      name: $name
      description: $description
      deliveryProductCategory_id: $deliveryProductCategory_id
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_DETAIL_QUERY = gql`
  query AdminDeliveryProductDetail($id: String!) {
    AdminDeliveryProductDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      coverImage
      timestamp
      price
      name
      description

      deliveryProductCategory {
        id
        createdAt
        updatedAt

        commonStatus
        iconImage
        name
        order
      }
      deliveryProductOptions {
        id
        createdAt
        updatedAt

        commonStatus
        name
        deliveryProductOptionDetails {
          id
          createdAt
          updatedAt

          commonStatus
          price
          name
        }
      }
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_EDIT_MUTATION = gql`
  mutation AdminDeliveryProductEdit(
    $id: String!
    $commonStatus: String!
    $price: String
    $name: String
    $description: String
    $deliveryProductCategory_id: String
  ) {
    AdminDeliveryProductEdit(
      id: $id
      commonStatus: $commonStatus
      price: $price
      name: $name
      description: $description
      deliveryProductCategory_id: $deliveryProductCategory_id
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_COVER_UPLOAD_MUTATION = gql`
  mutation AdminDeliveryProductCoverImageUpload(
    $id: String!
    $coverImage: FileUpload!
  ) {
    AdminDeliveryProductCoverImageUpload(id: $id, coverImage: $coverImage) {
      id
      coverImage
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_COVER_DELETE_MUTATION = gql`
  mutation AdminDeliveryProductCoverImageDelete($id: String!) {
    AdminDeliveryProductCoverImageDelete(id: $id)
  }
`;
export const ADMIN_DELIVERY_PRODUCT_DELETE_MUTATION = gql`
  mutation AdminDeliveryProductCoverImageDelete($id: String!) {
    AdminDeliveryProductCoverImageDelete(id: $id)
  }
`;
/* 상품 끝 */

/* 상품 옵션 시작 */
export const ADMIN_DELIVERY_PRODUCT_OPTION_WRITE_MUTATION = gql`
  mutation AdminDeliveryProductOptionWrite(
    $deliveryProduct_id: String!
    $commonStatus: String!
    $name: String!
    $detailCommonStatus: String!
    $detailPrice: String!
    $detailName: String!
  ) {
    AdminDeliveryProductOptionWrite(
      deliveryProduct_id: $deliveryProduct_id
      commonStatus: $commonStatus
      name: $name
      detailCommonStatus: $detailCommonStatus
      detailPrice: $detailPrice
      detailName: $detailName
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_OPTION_DETAIL_QUERY = gql`
  query AdminDeliveryProductOptionDetail($id: String!) {
    AdminDeliveryProductOptionDetail(id: $id) {
      id
      createdAt
      updatedAt

      commonStatus
      name
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_OPTION_EDIT_MUTATION = gql`
  mutation AdminDeliveryProductOptionEdit(
    $id: String!
    $commonStatus: String!
    $name: String!
  ) {
    AdminDeliveryProductOptionEdit(
      id: $id
      commonStatus: $commonStatus
      name: $name
    )
  }
`;
/* 상품 옵션 끝 */

/* 상품 상세 옵션 시작 */
export const ADMIN_DELIVERY_PRODUCT_OPTION_DETAIL_WRITE_MUTATION = gql`
  mutation AdminDeliveryProductOptionDetailWrite(
    $deliveryProduct_id: String!
    $deliveryProductOption_id: String!
    $commonStatus: String!
    $price: String!
    $name: String!
  ) {
    AdminDeliveryProductOptionDetailWrite(
      deliveryProduct_id: $deliveryProduct_id
      deliveryProductOption_id: $deliveryProductOption_id
      commonStatus: $commonStatus
      price: $price
      name: $name
    )
  }
`;
export const ADMIN_DELIVERY_PRODUCT_OPTION_DETAIL_DETAIL_QUERY = gql`
  query AdminDeliveryProductOptionDetailDetail($id: String!) {
    AdminDeliveryProductOptionDetailDetail(id: $id) {
      id
      commonStatus
      price
      name
      createdAt
      updatedAt
    }
  }
`;
export const ADMIN_DELIVERY_PRODUCT_OPTION_DETAIL_EDIT_MUTATION = gql`
  mutation AdminDeliveryProductOptionDetailEdit(
    $id: String!
    $commonStatus: String!
    $price: String!
    $name: String!
  ) {
    AdminDeliveryProductOptionDetailEdit(
      id: $id
      commonStatus: $commonStatus
      price: $price
      name: $name
    )
  }
`;
/* 상품 상세 옵션 끝 */
