import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { STORE_LIST_QUERY } from "../../Queries";
import Presenter from "./Presenter";

export default withRouter(
  ({ history, match, location, isVisible, toggleModal, stores, setStores }) => {
    const [page] = useState(1);
    const [keyword, setKeyword] = useState("");

    const first = 15;
    const skip = first * (page - 1);

    const { data, loading, refetch } = useQuery(STORE_LIST_QUERY, {
      variables: {
        keyword,
      },
    });

    const handleSearch = () => {
      console.log({
        keyword,
        skip,
        first,
      });
      refetch();
    };

    if (!loading) {
      return (
        <Presenter
          history={history}
          match={match}
          location={location}
          isVisible={isVisible}
          toggleModal={toggleModal}
          keyword={keyword}
          setKeyword={setKeyword}
          handleSearch={handleSearch}
          stores={stores}
          setStores={setStores}
          data={data}
          loading={loading}
        ></Presenter>
      );
    } else {
      return (
        <div className="panel-loader">
          <span className="spinner-small"></span>
        </div>
      );
    }
  }
);
