import React, { useState } from "react";
import { gql } from "apollo-boost";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useMutation } from "react-apollo-hooks";
import { graphqlUri } from "../../../Config/Env";

export const LOG_OUT = gql`
  mutation logUserOut {
    logUserOut @client
  }
`;

const Profile = ({ userData }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [logOut] = useMutation(LOG_OUT);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const {
    AdminInfo: { avatar, name },
  } = userData;

  const avatarImage =
    avatar === ""
      ? "/assets/img/user/user-default.jpg"
      : `${graphqlUri}${avatar}`;

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className="dropdown navbar-user"
      tag="li"
    >
      <DropdownToggle tag="a">
        <img src={avatarImage} alt="" />
        <span className="d-none d-md-inline-block">{name}</span>{" "}
        <b className="caret"></b>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
        <DropdownItem tag="a">
          <div onClick={logOut}>Log Out</div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Profile;
