import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo-hooks";
import {
  STORE_PRODUCT_DETAIL_QUERY,
  STORE_PRODUCT_DELETE_QUERY,
  STORE_PRODUCT_LIST_QUERY
} from "../../Queries";
import Presenter from "./Presenter";
import { toast } from "react-toastify";

export default withRouter(({ history, match, location }) => {
  const { data, loading } = useQuery(STORE_PRODUCT_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: { id: match.params.storeProduct }
  });

  const [storeProductDeleteMutation] = useMutation(STORE_PRODUCT_DELETE_QUERY, {
    refetchQueries: () => [
      { query: STORE_PRODUCT_LIST_QUERY, variables: { store: match.params.id } }
    ]
  });
  const onDelete = async id => {
    try {
      const {
        data: { AdminStoreProductDelete }
      } = await storeProductDeleteMutation({
        variables: {
          id: match.params.storeProduct
        }
      });
      if (AdminStoreProductDelete) {
        toast.success("처리 되었습니다.");
        history.push(`/stores/${match.params.id}${location.search}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("에러가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  if (loading === true) {
    return (
      <div className="panel-loader">
        <span className="spinner-small"></span>
      </div>
    );
  } else if (!loading && data && data.AdminStoreProductDetail) {
    const {
      AdminStoreProductDetail: {
        id,
        storeProductImage,
        name,
        description,
        price
      }
    } = data;
    return (
      <Presenter
        history={history}
        match={match}
        location={location}
        id={id}
        storeProductImage={storeProductImage}
        name={name}
        description={description}
        price={price}
        onDelete={onDelete}
      />
    );
  }
});
