import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "../../../../Components/Panel/Panel";
import { InputGroup, Input, InputGroupAddon, Button, Alert } from "reactstrap";
import Pagination from "../../../../Components/Pagination/QSP";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../Config/Env";
import {
  roleName,
  currencyArray,
  currencyName,
} from "../../../../Components/User/UserNames";

export default ({
  history,
  match,
  location,
  userData,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,
  handleApproval,
}) => {
  return (
    <>
      <Helmet>
        <title>{roleName(match.params.role)} 관리 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/users/${match.params.role}`}>사용자</Link>
          </li>
          <li className="breadcrumb-item active">
            {roleName(match.params.role)}
          </li>
        </ol>
        <h1 className="page-header">{roleName(match.params.role)}</h1>
        <div className="row">
          <div className="col-xl-12">
            {loading && (
              <div className="panel-loader">
                <span className="spinner-small"></span>
              </div>
            )}

            <Panel>
              <PanelHeader noButton={true}>
                목록({!loading && data.IssuerUserList.count}건)
              </PanelHeader>
              <Alert className="bg-gray-transparent-1">
                <div className="row">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(아이디, 이름, 이메일, 휴대전화) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
              </Alert>
              <PanelBody>
                {!loading && data.IssuerUserList.count > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped m-b-0">
                        <thead>
                          <tr>
                            <th>상태</th>
                            <th>이미지</th>
                            <th>
                              {match.params.role === "Store" ? (
                                <>상호명 - 이름(아이디)</>
                              ) : (
                                <>이름(아이디)</>
                              )}
                            </th>
                            {match.params.role === "User" && (
                              <>
                                <th>이메일 </th>
                                <th>휴대전화</th>
                              </>
                            )}
                            <th>잔액</th>
                            <th>가입일자</th>
                            <th>기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.IssuerUserList.users.map((user) => {
                            const trustline = user.trustlines.find((item) => {
                              return (
                                item.currency === userData.AdminInfo.addCurrency
                              );
                            });
                            const avatarImage =
                              user.avatar === "" || user.avatar === null
                                ? "/assets/img/user/user-default.jpg"
                                : `${graphqlUri}${user.avatar}`;
                            return (
                              <tr key={user.id}>
                                <td>
                                  {trustline.commonStatus === "C"
                                    ? "승인"
                                    : "대기"}
                                </td>
                                <td className="with-img text-center">
                                  <img
                                    src={avatarImage}
                                    className="img-rounded width-30 height-30"
                                    alt={user.name}
                                  />
                                </td>
                                <td>
                                  {user.name}({user.userId})
                                  {user.blocked ? (
                                    <i className="fa fa-lock f-s-7 fa-fw m-r-10 m-t-5" />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                {match.params.role === "User" && (
                                  <>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                  </>
                                )}
                                <td align="right">
                                  {
                                    <span key={trustline.id}>
                                      {currencyName(trustline.currency)} :{" "}
                                      <NumberFormat
                                        value={
                                          trustline.amount -
                                          currencyArray[trustline.currency]
                                            .startAmount
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <br />
                                    </span>
                                  }
                                </td>
                                <td>
                                  <Moment format="YYYY-MM-DD HH:mm:ss">
                                    {user.createdAt}
                                  </Moment>
                                </td>
                                <td align="center">
                                  <Link
                                    to={`/users/${match.params.role}/${user.id}${location.search}`}
                                    className="btn btn-white btn-xs text-green"
                                  >
                                    관리
                                  </Link>
                                  {trustline.commonStatus === "C" ? (
                                    <button className="btn btn-white btn-xs text-green disabled">
                                      완료
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-white btn-xs text-red"
                                      onClick={(e) =>
                                        handleApproval(e, trustline)
                                      }
                                    >
                                      승인
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className="vertical-box p-15 text-center ">
                    등록된 데이터가 없습니다.
                  </div>
                )}
              </PanelBody>
              {!loading && data.IssuerUserList.count > 0 && (
                <PanelFooter>
                  <div className="row">
                    <div className="col-md-6">
                      <Pagination
                        totalRecord={data.IssuerUserList.count}
                        blockSize={blockSize}
                        pageSize={first}
                        currentPage={page}
                        baseUrl={baseUrl}
                      />
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};
