import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  CardImg,
} from "reactstrap";
import Pagination from "../../../../../../../Components/Pagination/QSP";
import ReactMoment from "react-moment";
import NumberFormat from "react-number-format";
import { graphqlUri } from "../../../../../../../Config/Env";
import { commonStatusName } from "../../../../../../../Components/User/UserNames";
import Category from "./ListCategory";

export default ({
  history,
  match,
  location,
  configs,
  config,
  page,
  keyword,
  keywordInput,
  blockSize,
  first,
  data,
  loading,
  baseUrl,
  handleSearch,
}) => {
  return (
    <>
      <Helmet>
        <title>드림티켓 | 한밭페이</title>
      </Helmet>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link to="/ticket/ticketConfigs">드림티켓</Link>
          </li>
          <li className="breadcrumb-item active">티켓</li>
        </ol>
        <h1 className="page-header">티켓</h1>
        <div className="row">
          <div className="col-xs-12">
            <ul className="nav nav-tabs nav-tabs-inverse">
              {configs.map((item) => (
                <li key={item.id} className={`nav-item`}>
                  <Link
                    to={`/ticket/ticketConfigs/${item.id}/products`}
                    className={`nav-link ${item.id === config.id && "active"}`}
                  >
                    <span>
                      {item.name}({item.ticketProductsCount}건)
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="row m-b-15">
                  <div className="col-xs-12">
                    <Category
                      history={history}
                      match={match}
                      location={location}
                      config={config}
                    />
                  </div>
                </div>
                <div className="row m-b-15">
                  <div className="col-xs-12">
                    <form onSubmit={handleSearch}>
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-white"
                          placeholder="검색어(상품명) 입력"
                          value={keywordInput.value}
                          onChange={keywordInput.onChange}
                        />
                        <InputGroupAddon addonType="append">
                          <Button size="sm" color="white">
                            <i className="fa fa-search fa-fw"></i>
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </form>
                  </div>
                </div>
                {!loading && data.StoreTicketProductList.count > 0 ? (
                  <>
                    <div>
                      <div className="product-list-wrap">
                        <ul>
                          {data.StoreTicketProductList.ticketProducts.map(
                            (item, index) => {
                              const Cover =
                                item.coverImage === "" ||
                                item.coverImage === null
                                  ? "/assets/img/product/product-default.jpg"
                                  : `${graphqlUri}${item.coverImage}`;
                              return (
                                <li key={index} className="product-list">
                                  <Link
                                    to={`/ticket/ticketConfigs/${config.id}/products/${item.id}${location.search}`}
                                  >
                                    <div
                                      className="img ticket"
                                      style={{
                                        backgroundImage: `url(${Cover})`,
                                      }}
                                    >
                                      <img src={Cover} alt={item.name} />
                                      <div className="category">
                                        <span className="label label-yellow">
                                          {commonStatusName(item.commonStatus)}
                                        </span>
                                        {item.isNew && (
                                          <span className="label label-primary">
                                            New
                                          </span>
                                        )}
                                        {item.isBest && (
                                          <span className="label label-success">
                                            Best
                                          </span>
                                        )}
                                        {item.isSoldOut && (
                                          <span className="label label-danger">
                                            품절
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="product-list-cont">
                                      <div className="desc">
                                        {item.store.name}
                                      </div>
                                      <h4 className="tit">
                                        [
                                        {item.ticketCategory.parentCategory
                                          ? `${item.ticketCategory.parentCategory.name} &gt; ${item.ticketCategory.name}`
                                          : `${item.ticketCategory.name}`}
                                        ] {item.name}
                                      </h4>
                                      <div className="desc">
                                        <p>{item.description}</p>
                                        <p>{item.limit}명</p>
                                        <p>
                                          {item.placeType === "OffLine" &&
                                            "오프라인"}
                                          {item.placeType === "OnLine" &&
                                            "온라인"}
                                        </p>
                                      </div>
                                      <div className="price-info">
                                        {item.isPay ? (
                                          <>
                                            <span className="price">
                                              <NumberFormat
                                                value={
                                                  item.price -
                                                  item.discountPrice
                                                }
                                                prefix={""}
                                                suffix={"원"}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                              />
                                            </span>
                                          </>
                                        ) : (
                                          <>무료</>
                                        )}
                                        <span className="date">
                                          <ReactMoment format="YYYY-MM-DD">
                                            {item.applyStartDateTime}
                                          </ReactMoment>{" "}
                                          ~{" "}
                                          <ReactMoment format="YYYY-MM-DD">
                                            {item.applyEndDateTime}
                                          </ReactMoment>
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <Pagination
                            totalRecord={data.StoreTicketProductList.count}
                            blockSize={blockSize}
                            pageSize={first}
                            currentPage={page}
                            baseUrl={baseUrl}
                          />
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-xs-12 p-40">
                      <p className="text-center">등록된 데이터가 없습니다.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        to={`/ticket/ticketConfigs/${config.id}/products/write`}
        className="btn btn-icon btn-circle btn-lg btn-primary btn-scroll-to-top"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>
    </>
  );
};
